/** @format */
const AT_KEYEXCHANGE = 1; //	Использование ключа для шифрования.
const AT_SIGNATURE = 2; // Использование ключа только для подписывания.

export const keyUsageTypes = {
  signature: AT_SIGNATURE,
  keyExchange: AT_KEYEXCHANGE,
  both: AT_SIGNATURE | AT_KEYEXCHANGE,
};

export const buildKeyLengthList = ({ minSize, maxSize }) => {
  const result = [];
  let current = Math.max(minSize, 512);
  while (current <= maxSize) {
    result.push(current);
    current *= 2;
  }

  return result;
};

const getErrorText = errorCode => {
  const errorNumber = errorCode + 0x100000000;
  return errorMessages[errorNumber] ?? 'Неизвестная ошибка';
};

export const getErrorObject = error => {
  return {
    isError: true,
    errors: [
      {
        errorCode: 'E_ERROR',
        errorMessage: isNaN(error) ? error : getErrorText(error),
        isError: true,
      },
    ],
  };
};

const L_SugCauseBadCSP_ErrorMessage =
  'Выбранный CSP не смог обработать запрос. Попробуйте использовать другой CSP.';
const L_SugCauseBadSetting2_ErrorMessage =
  'Выбранный CSP не поддерживает некоторые из выбранных вами параметров. Попробуйте использовать другие параметры или другой CSP.';
const L_SugCauseBadKeyContainer_ErrorMessage =
  "Либо указанный вами контейнер не существует, либо выбранный вами CSP не смог обработать запрос. Введите имя существующего контейнера, выберите 'Создать новый набор ключей' или используйте другой CSP.";
const L_SugCauseExistKeyContainer_ErrorMessage =
  'Названный контейнер уже существует. При создании нового ключа следует использовать новое имя контейнера.';
const L_SugCauseBadChar_ErrorMessage =
  'Введен недопустимый символ. Сообщите об ошибке, поскольку это должно было быть обнаружено при проверке.';
const L_SugCauseBadFileName_ErrorMessage = 'Введено недопустимое имя файла.';
const L_SugCauseBadHash_ErrorMessage =
  'Выбранный алгоритм хеширования не может использоваться для подписи. Выберите другой алгоритм хеширования.';
const L_SugCauseNoFileName_ErrorMessage = 'Не указано имя файла.';
const L_SugCauseNotAdmin_ErrorMessage =
  'Нужно иметь права администратора для генерации ключа в локальном хранилище компьютера.';

const errorMessages = {
  0x80090008: L_SugCauseBadSetting2_ErrorMessage,
  0x80090016: L_SugCauseBadKeyContainer_ErrorMessage,
  0x80090019: L_SugCauseBadCSP_ErrorMessage,
  0x80090020: L_SugCauseBadCSP_ErrorMessage,
  0x80090009: L_SugCauseBadSetting2_ErrorMessage,
  0x8009000f: L_SugCauseExistKeyContainer_ErrorMessage,
  0x80092002: '',
  0x80092022: L_SugCauseBadChar_ErrorMessage,
  0x80092023: L_SugCauseBadChar_ErrorMessage,
  0x80070003: L_SugCauseBadFileName_ErrorMessage,
  0x80070103: L_SugCauseBadHash_ErrorMessage,
  0xffffffff: L_SugCauseNoFileName_ErrorMessage,
  0x8000ffff: '',
  0x80090010: L_SugCauseNotAdmin_ErrorMessage,
}