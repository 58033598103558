import React from "react";
import {useCircleProgressData} from "./hooks/useCircleProgressData";
import {ReactComponent as IconOk} from "assets/img/commonVer2/ic_check.svg";

export default React.memo(function CircleProgressBar({isCompleted, uploadsMetric, onClick = () => {}, disabled = true}) {
    const {
        isError,
        radius,
        dashArray,
        dashOffset,
        count
    } = useCircleProgressData(uploadsMetric);

    return (
        <>
            <svg
                className="circle-progressbar-trigger"
                onClick={disabled ? () => {} : onClick}
                width={"28"}
                height={"28"}
                viewBox={"0 0 28 28"}>
                <circle
                    className={`${disabled ? 'circle-progressbar-trigger__background--disabled' : ''} circle-progressbar-trigger__background`}
                    cx={"14"}
                    cy={"14"}
                    r={radius}
                    strokeWidth={"2px"}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }}
                    transform={`rotate(-90 14 14)`}
                />
                <circle
                    className={`circle-progressbar-trigger__line--disabled circle-progressbar-trigger__line`}
                    cx={"14"}
                    cy={"14"}
                    r={radius}
                    strokeWidth={"2px"}
                />
                {
                    isError
                        ? <text
                            className="circle-progressbar-trigger__text circle-progressbar-trigger__text--error"
                            x="50%"
                            y="50%"
                            dy=".3em"
                            textAnchor="middle">
                            !
                        </text>
                        : <text
                            className="circle-progressbar-trigger__text"
                            x="50%"
                            y="50%"
                            dy=".3em"
                            textAnchor="middle">
                            {(disabled || (count === 0)) ? null : count}
                        </text>
                }

            </svg>
            {
                (isCompleted && (count === 0))
                && <IconOk className={'upload-list__icon upload-list__icon--metric-ok'}/>
            }
        </>
    );
},);
