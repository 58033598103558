import React from 'react'
import UploadItem from 'app/components/uploadItem'
import { formatDate } from 'app/core/utility/date'
import { ReactComponent as SignImg }  from 'assets/img/commonVer2/ic_signature.svg'
import { ReactComponent as AlertImg }  from 'assets/img/commonVer2/ic_alert_triangle.svg'
import { ReactComponent as OkImg }  from 'assets/img/commonVer2/ic_check.svg'
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC'

const [Sign, Ok, Failed] = [SignImg, OkImg, AlertImg].map(x => withTooltip(x));

const eventType = {
  ReplicateToOki: 'ReplicateToOki',
  ReplicateToOkz: 'ReplicateToOkz',
  BulkEvent: 'BulkEvent',
  File: 'File'
}

export const NotificationTypeMapping = {
  [eventType.ReplicateToOki]: 'Распространение экземпляров',
  [eventType.ReplicateToOkz]: 'Распространение эталона',
  [eventType.BulkEvent]: 'Массовая операция'
}

export const statuses = {
  done: 'Done',
  doneWithErrors: 'DoneWithErrors',
  started: 'Started',
  inProgress: 'InProgress',
  failed: 'Failed',
}

const Progress = (props) => {
  const { progress } = props
  return <div className="infografic-progress">{`${progress}%`}</div>
}

const Infografic = (data) => {
  const { 
    status,
    progress,
    noProgressOperation
  } = data
  const { id } = status || {}
  
  switch (id) {
    case statuses.doneWithErrors:
      return <Sign tooltipPosition={Position.Left} tooltip={'Подтвердить'} className='sing-info--error' />
    case statuses.inProgress:
      return noProgressOperation ? null : <Progress progress={progress}/>
    case statuses.done:
      return <Ok tooltipPosition={Position.Left} tooltip={'Успешно'} className='infografic-ok'/>
    case statuses.failed:
      return <Failed tooltipPosition={Position.Left} tooltip={'Ошибка'} className='sing-info--error' />
    default: 
      return null
  }
} 

const Info = (props) => {
  const { 
    resultSummary, 
    errorsSummary,
    status, 
    code, 
    entitiesAffected,
    entitiesEstimated,
    uid
  } = props
  const { id } = status || {}

  if (code === eventType.File) {
    switch (id) {
      case statuses.done:
        return <a href={`/api/v1/AsyncBizTask/${uid}/result`} download>Скачать</a>
      case statuses.failed:
        return errorsSummary
      case statuses.inProgress:
        return 'формирование файла...'
      default:
        return ''
    }
  }

  const infoText = id === statuses.doneWithErrors
                    ? `Требуется подтверждение!`
                    : `${resultSummary}: ${entitiesAffected}/${entitiesEstimated}` 

  const className = id === statuses.doneWithErrors
                    ? 'info--error'
                    : ''

  return <span className={className}>{infoText}</span>
}

const getResultText = (data) => {
  const { 
    entitiesAffected,
    entitiesEstimated,
    status,
    errorsReceived
  } = data
  const { id } = status || {}

  switch (id) {
    case statuses.inProgress:
      return  !entitiesAffected && !entitiesEstimated 
              ? ''
              : `(${entitiesAffected} из ${entitiesEstimated})`
    case statuses.doneWithErrors:
      return `Ошибок: ${errorsReceived}`
    default: 
      return ''
  }
}

const getTitleText = ({code, description}) => {
  const descriptionCodes = [eventType.BulkEvent, eventType.File]
  return descriptionCodes.includes(code)
          ? description
          : NotificationTypeMapping[code]
}

export default function NotificationItem(props) {

  const {
    code,
    uid,
    createDate,
    progress,
    status,
    errorsReceived,
    onClickElement,
    description,
    entityDiscriminator,
  } = props

  const { id } = status || {}

  const resultText = getResultText(props)
  const title = getTitleText({code, description})
  const isError = id === statuses.doneWithErrors
                  || id === statuses.failed
  const onClickHandler = code !== eventType.File ? () => {onClickElement({uid, code, status, entityDiscriminator})} : null
          
  return (
    <div className='notification-item' onClick={onClickHandler}>
      <UploadItem
        key={uid}
        id={uid}
        title={title}
        date={formatDate(new Date(createDate), 'dd.mm.yyyy')}
        description={<Info {...props}/>}
        isError={isError}
        progress={`${progress}%`}
        onClickHandler={() => {}}
        noProgress={code === eventType.File}
      >
        <span className={`${id === statuses.doneWithErrors ? 'result--error' : ''}`}>
          {resultText}
        </span>
      </UploadItem>
      <div className="notification-item__infografic">
        <Infografic {...props} noProgressOperation={code === eventType.File}/>
      </div>
    </div>
  )
}
