
export const ActionType =  {
    SetAvailableLicense: 'SetAvailableLicense',
};

export const ActionCreator = {
    setAvailableLicense: (data) => ({
        type: ActionType.SetAvailableLicense,
        payload: data,
    }),
};
