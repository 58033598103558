/** @format */

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader';
import Tabs from 'app/components/ui/tabs/tabs';
import {
  breadcrumbsPushAction,
  breadcrumbsPopAction,
} from 'redux/actions/common/breadcrumbsAction';
import { useLocation, useParams } from 'react-router-dom';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import RequisitesForm from './requisitesForm';
import styles from './card.module.scss';
import ManagementPanel from './managementPanel';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Overlay from 'app/components/ui/overlay';
import service from 'app/services/service';
import { formatDate } from 'app/core/utility/date';

const Card = () => {
  const { requestId } = useParams();
  const profile = useSelector(({ profile }) => profile);
  const [activeTab, setActiveTab] = useState('Информация');
  const [cardData, setCardData] = useState();
  const [inProgress, setInProgress] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const initCardData = async () => {
    setInProgress(true);
    const response = await service('certificateAuthorityService', 'getRequest', {
      requestId,
    });
    const { data, isError } = response;
    !isError && setCardData({ ...data });
    setInProgress(false);
  };

  useEffect(() => {
    initCardData();
  }, []);

  useEffect(() => {
    if (!cardData) {
      return;
    }

    const { creationDate, userName } = cardData || {};
    const title = `Запрос на сертификат от ${formatDate(
      creationDate,
      'dd.mm.yyyy hh:MM:ss'
    )} (${userName})`;
    dispatch(breadcrumbsPushAction(location.pathname, title, true));
    return () => {
      dispatch(breadcrumbsPopAction());
    };
  }, [cardData]);

  return (
    <>
      <BreadcrumbsHeader />
      {inProgress ? <Overlay /> : null}
      <Tabs activeTab={activeTab} onTabClick={title => setActiveTab(title)}>
        <section tabTitle={'Информация'} className={styles.сardData}>
          <ExpandingBlock renderHeader={() => 'Реквизиты'} initialState={true}>
            <ManagementPanel
              rights={getResourceRights(profile, RESOURCES.certificateAuthorityCertificate)}
              afterAction={initCardData}
              requestData={cardData}
            />
            <RequisitesForm
              serialNumber={cardData?.certificateSerialNumber}
              creationDate={cardData?.creationDate}
              status={cardData?.status}
              type={cardData?.type}
              validityDate={cardData?.approvalDate}
              user={cardData?.userName}
              organiation={cardData?.organizationName}
              subject={cardData?.certificateSubject}
              template={cardData?.certificateTemplateName}
              reviewedBy={cardData?.acceptorName}
              userComment={cardData?.userComment}
              adminComment={cardData?.managerComment}
            />
          </ExpandingBlock>
        </section>
      </Tabs>
    </>
  );
};

export default Card;

