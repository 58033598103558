import React, {useEffect, useRef, useState} from 'react';
import Autocomplete from "app/components/ui/autocomplete/autocomplete";

export default function KeyCarrierControl({onChangeHandler, disabledState = false}) {
    const [value, setValueState] = useState(null);

    useEffect(() => {
        onChangeHandler(value);
    }, [value]);

    return <>
        <Autocomplete
            className='card-field key-carrier-card__keyCarrierTypeId'
            label='Тип'
            value={value}
            readOnly={false}
            serviceType={'keyCarrierTypeService'}
            onSelect={setValueState}
        />
    </>;
}
