/** @format */

const getFullSkziName = skzi => {
  const {
    skzi: { name: skziName },
    version: { name: versionName },
    build: { name: buildName },
    skziClass: { name: skziClassName },
  } = { skzi: {}, version: {}, build: {}, skziClass: {}, ...skzi };
  return [skziName, versionName, buildName, skziClassName].filter(p => p && p.length).join(' ');
};

export { getFullSkziName };
