import { decapitalize } from 'app/core/utility/common'

export default function useBusinessEventForm({
  viewModel
}) {
  const { id, model } = viewModel || {}

  const initialState = model.reduce((acc, cur) => {
    acc[decapitalize(cur.key)] = cur.defaultValue || null
    return acc
  }, {})

  return {
    formData: {
      validationRules: {},
      defaultState: {
        ...initialState,
        eventTemplateId: id
      }
    },
  }
}