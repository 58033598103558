import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  eskziCardCreateActionFormAction,
  eskziCardServiceOnAddAction,
  eskziCardServiceResetAction
} from "redux/actions/skziInstance/eskziCardAction";
import {setErrorByKey} from "redux/actions/common";
import {capitalize, serverlikeErrors} from "app/core/utility/common";
import {formatDate} from "app/core/utility/date";
import {getResourceRights} from "app/core/auth/auth";
import {RESOURCES} from "app/core/auth/resourceByPage";

export default function useCreateControl(){
  const accessRights = useSelector(({profile}) => profile)
  const rights = getResourceRights( accessRights, RESOURCES.maintenanceRecords );
  const dispatch = useDispatch()
  const {eskziCard, error} = useSelector(({eskziCard, error}) => ({eskziCard, error}))
  const { serviceData } = eskziCard || {}
  const { addActionDialog } = serviceData || {}
  const { initData: addDefaultFormData, formData: addFormData } = addActionDialog || {}
  const { fieldsError, errorByFields } = error || {}

  function onValidation (errors) {
    dispatch(setErrorByKey(serverlikeErrors(errors)))
  }

  function onCreateActionChange(key, value) {
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    dispatch(eskziCardCreateActionFormAction({ ...addActionDialog, formData: {
        ...addFormData, [key]: value
      }}))
    dispatch(setErrorByKey(filteredErrors))
  }

  function getActionKey(item) {
    const { name, comments, performer, actionDate } = item

    return `${name}${comments}${formatDate(actionDate, 'yyyyddmm')}${performer}`
  }

  function onCancel(data){
    dispatch(eskziCardServiceResetAction(data))
  }
  const {userInfo} = useSelector(({profile})=> profile)
  function onCreateClick() {
   dispatch(eskziCardServiceOnAddAction());
   dispatch(eskziCardCreateActionFormAction({ ...addActionDialog, formData: {
     ...addFormData,
     performerId: userInfo
    }}))
  }

  return {
    rights,
    addFormData,
    fieldsError,
    addActionDialog,
    addDefaultFormData,
    onCreateClick,
    onCancel,
    getActionKey,
    onValidation,
    onCreateActionChange,
  }
}