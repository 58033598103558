import Button from 'app/components/ui/button/button'
import React from 'react'

export default function ResultButtons(props) {
  const { callback, needSign, onCancel } = props

  return (
    <div className={`card-material__edit-card-management ${!needSign ? 'edit-card-management--center' : ''}`}>
    {needSign ? (
      <Button 
        className='card-management__item'
        type='text'
        onClick={callback}
      >
        <span className='button-title'>Подтвердить</span>
      </Button>
    ): null}
      <Button
        className='card-management__item'
        type='text'
        onClick={onCancel}
      >
        <span className='button-title'>{needSign ? 'Отмена' : 'Закрыть'}</span>
      </Button>
    </div>
  )
}
