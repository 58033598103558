import React, { useCallback, useEffect, useState } from 'react'
import service from 'app/services/service'
import { useHistory } from "react-router-dom";
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/ic_link_off.svg'
import { ReactComponent as AddImg } from 'assets/img/commonVer2/ic_link_add.svg'
import Overlay from 'app/components/ui/overlay'
import SimpleSearch from 'app/components/ui/search/SimpleSearch';
import Button from 'app/components/ui/button/button';
import withTooltip from 'app/components/HOC/TooltipHOC';
import ExpandingBlock from 'app/components/ui/expandingBlock'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'app/components/list/Table/Table'
import Picker,  { SelectType, Services } from 'app/components/ui/picker/picker';
import withModal from 'app/components/HOC/ObsoleteModalHOC';
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { getResourceRights } from 'app/core/auth/auth'
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog/';
import useLinkedOrg from 'app/components/okziScheme/card/useLinkedOrg';
import { okzTableConfig } from 'app/components/okziScheme/card/servedOrganizations';

const ModalPicker = withModal(Picker)
const WithTooltipButton = withTooltip(Button)


const deleteSelected = async (data) => {
  return await service('organizationService', 'deleteServiceRange', data)
}

const add = async (data) => {
  return await service('organizationService', 'addService', data)
}

const clearError = (dispatch) => {
  dispatch({type: 'CLEAR.ERROR'})
}

export default function ServiceOkz({
  id,
  list: propsList,
  onUpdate
}) {
  const globalDispatch = useDispatch()
  const history = useHistory()
  const profile = useSelector(({profile}) => profile)
  const schemeRights = getResourceRights(profile, RESOURCES.organization)

  const {
    onAdd,
    onDelete,
    setSearch,
    search,
    showPicker, 
    setShowPicker,
    searchResult, 
    setSearchResult,
    errorToShow,
    sortListAction,
    sortListSetAction,
    setSelectedAction,
    inProgress,
    sorting,
    selectedItems,
    list=[],
    paginator,
  } = useLinkedOrg({
    id,
    list: propsList, 
    add, 
    deleteSelected,
    onUpdate
  })
     
  const pickerService = {
    serviceName: 'okzTreeService',
    serviceMethod: 'servicePicker',
    data: {}
  }
  const total = search 
                ? searchResult 
                : list
                  ? list.length
                  : 0

  const removeLinkDisabled = !!inProgress 
                              || !selectedItems.length
                              || selectedItems.some(i => i.bizLocked)

  return (
    <section className="organization-card__service-okz">
      {inProgress ? <Overlay /> : null}
      <ExpandingBlock
        renderHeader= {() => 'Координирующие ОКЗ' }
        initialState={true}
      >
        <div className="manage-panel">
          <div className="manage-panel__buttons">
            {schemeRights.UPDATE && 
              <Button
                className='create-button'
                type='primary'
                disabled={!!inProgress}
                onClick={() => setShowPicker(true)}
              >
                <AddImg className='button-image button-image--left'></AddImg>
                <span className='button-title'>Добавить</span>
              </Button>
            }
            {schemeRights.UPDATE &&
              <div className="buttons-element">
                <WithTooltipButton 
                  className='delete-button'
                  tooltip='Отвязать'
                  type='image'
                  onClick={onDelete}
                  disabled={removeLinkDisabled}
                >
                  <DeleteImg className='button-image'></DeleteImg>
                </WithTooltipButton>
              </div>
            }
          </div>
          <div className="manage-panel__filter">
            <SimpleSearch
              className='filter-item'
              placeholder=''
              label='Наименование'
              value={search}
              onChange={setSearch}
              disabled={inProgress}
            />
          </div>
        </div>
        {/* {search && <div className="manage-panel">
          Результаты поиска: {searchResult}
        </div>} */}
        <Table
          totalCount={total}
          search={search}
          searchAlias={'name'}
          setSearchResult={setSearchResult}
          sortListAction={sortListAction} 
          sortListSetAction={sortListSetAction}
          paginator={paginator}
          sorting={sorting}
          selectedItems={selectedItems}
          setSelectedAction={setSelectedAction} 
          inProgress={inProgress}
          list={list}
          fieldsConfig = { okzTableConfig.fieldsConfig }
          itemsSelectable = { true }
          itemsSelectableRadio = { okzTableConfig.pageConfig.itemsSelectableRadio }
          defaultSortByAlias = { okzTableConfig.pageConfig.defaultSortByAlias } 
          changedFieldsVisibility = { okzTableConfig.pageConfig.changedFieldsVisibility === true }
          onItemClick = { item => { return `/catalog/okzScheme/card/${item.id}`} }
          headerInfo = { okzTableConfig.pageConfig.headerInfo === true }
        />  
      </ExpandingBlock>
      {showPicker &&
        <ModalPicker
          serviceType={Services.okzTree}
          disabledItems={list}
          excludeItems={[{ id:id}]}
          notStandardService={pickerService}
          selectType={SelectType.multiple}
          onCancel={()=>setShowPicker(false)} 
          onSelect={onAdd}
          renderHeader="Добавление курирующих ОКЗ"
        />
      }
    {errorToShow ? 
      <ConfirmDialog
        needIcon={true}
        type={dialogType.warning}
        error={errorToShow.error || errorToShow}
        onCancel={() => clearError(globalDispatch)}
      /> : null
    }
    </section>
  )
}
