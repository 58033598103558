import React from 'react'
import Button from 'app/components/ui/button/button'
import { ReactComponent as BreakChainImg } from 'assets/img/commonVer2/ic_link_off.svg'
import withTooltip from 'app/components/HOC/TooltipHOC'
const TooltipButton = withTooltip(Button)

export default function BreakLinkButton(props) {
  const { 
    disabled, 
    className,
    keyDocumentId,
    onSubmitSignEnhance,
    selectedKeyCarrier
  } = props 

  const _onClick = async () => {
    if (!selectedKeyCarrier) {
      return 
    }

    const { id } = selectedKeyCarrier || {}
    const requestData = {
      keyCarrierId : id,
      keyInformation : [ parseInt( keyDocumentId ) ],
      signatureRequestType: 'keyCarrierRemove'
    };

    const result = await onSubmitSignEnhance(requestData)
  }

  return (
    <TooltipButton 
      className = {className || ''}
      type='image'
      tooltip='Удалить связь'
      onClick = { _onClick }
      disabled = { disabled }
    >
      <BreakChainImg className='button-image'/>
    </TooltipButton>
  )
}
