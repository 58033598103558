export const getProcessingUploadState = (state) => {
    return state.processingUpload.isProcessing;
};

export const getProcessingUploadData = (state) => {
    return state.processingUpload.processingData;
};

export const getParsedData = (state) => {
    return state.processingUpload.parsedData;
};
export const getParsedFileInfo = (state) => {
    return state.processingUpload.parsedFileInfo;
};
export const getLastUploads = (state) => {
    return state.processingUpload.lastUploads;
};
export const getCurrentProcessingFiles = (state) => {
    return state.processingUpload.currentProcessingFiles;
};
export const getMetric = (state) => {
    return state.processingUpload.metric;
};
export const getWSUploads = (state) => {
    return state.processingUpload.wsUploads;
};
export const getUserHistory = (state) => {
    return state.processingUpload.userHistory;
};
export const getCreateUploadState = (state) => {
    return state.processingUpload.createUploadState;
};
export const getFileSingleParsingState = (state) => {
    return state.processingUpload.singleParsingFileState;
};
export const getFileSingleParsingID = (state) => {
    return state.processingUpload.singleParsingFileID;
};
export const getUploadModalState = (state) => {
    return state.processingUpload.importsUploadModal;
};
export const getUploadModalFileTypeState = (state) => {
    return state.processingUpload.importsUploadModalFileType;
};
export const getExternalIdState = (state) => {
    return state.processingUpload.externalId;
};
export const getProcessingLongPolling = (state) => {
    return state.processingUpload.isProcessingLongPolling;
};
