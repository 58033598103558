import { useState, useEffect } from "react";
import Autocomplete, { Services, renderItem } from "app/components/ui/autocomplete/autocomplete";
import SimpleSearch from "app/components/ui/search/SimpleSearch";
import { DateRangePicker } from "app/components/ui/date";
import Checkbox, { checkboxType } from "app/components/ui/checkbox";
import { formatFIO, isEmptyObject } from "app/core/utility/common";
import { formatDate } from "app/core/utility/date";
import { InputType } from "../ui/Input/Input";
import DropDown from "../ui/DropDown";
import SignerWithSignState from "./signerWithSignState/SignerWithSignState";
import AutocompletePicker from "app/components/ui/picker/autocompletePicker/autocompletePicker";
import { SelectType } from "../ui/picker/picker";
import SelfServiceStatus from "./selfServiceStatus/SelfServiceStatus";
import DatePicker from "../ui/date/DatePicker";
import MultiSelect from "../ui/autocomplete/MultiSelect";

export const FilterControlType = {
  dateRangePicker: "DateRangePicker",
  autocomplete: "Autocomplete",
  multiselect: "Multiselect",
  unselectAllMultiselect: "UnselectAllMultiselect",
  checkbox: "Checkbox",
  simpleSearch: "SimpleSearch",
  dropDown: "DropDown",
  autocompletePicker: 'autocompletePicker',
  signerWithSignState: "signerWithSignState",
  selfServiceStatus: 'selfServiceStatus',
  datePicker: 'DatePicker'
};

const isAutosearch = (type) => {
  switch (type) {
    case FilterControlType.autocomplete:
    case FilterControlType.autocompletePicker:
    case FilterControlType.signerWithSignState:
    case FilterControlType.checkbox:
    case FilterControlType.datePicker:
    case FilterControlType.dateRangePicker:
      return true;

    default:
      return false;
  }
};

export default function FilterElement({
  autosearch, //  true/false
  type, //
  alias, //
  label, //
  labelTo,
  labelFrom,
  placeholder,
  requestFormatIsArray,
  requestEnumFormat, // когда сервер ожидает в теле запроса весь объект, а не только id, как в большинстве случаев
  selectedItem = {}, //
  serviceType,
  selectType = SelectType.single,
  filterConfig,
  disabled,
  onSearch,
  onChange,
  isExtendedSearchBlock,
  className,
  notStandartService = null,
  options,
  pattern,
  dependencies = {},
  elementDependencies = [],
  requestValueMapper,  
  unselectAllData  // для компонента MultiSelect
}) {
  let render = null;
  if (type === FilterControlType.autocomplete) {
    if (serviceType === "skziBuild") {
      render = renderItem.skziBuild;
    } else if (serviceType === "skziUser" || serviceType === "user") {
      render = renderItem.eskziUser;
    } else {
      render = renderItem[serviceType];
    }
  }

  const _onChangeSelect = (item) => {
    const obj = {
      ...item,
      valueForRequest: null,
      valueFormattedString: "",
    };

    if (item === null) {
      _onChange(obj);
      return;
    }

    if (requestEnumFormat) {
      obj.valueForRequest = item;
      obj.valueFormattedString = item.name;
    } else { 
      switch (serviceType) {
        case 'skziUser':
        case 'user':
          obj.valueForRequest = item.id;
          obj.valueFormattedString = formatFIO(item);
          break;      
        case 'skziVersion':
          obj.valueForRequest = item.version.id;
          obj.valueFormattedString = `${item.skzi.name} ${item.version.name}`;
          break;
        case 'skziBuild':
          obj.valueForRequest = item.build.id;
          obj.valueFormattedString = item.build.name;
          break;
        case 'organizations':
          obj.valueForRequest = item.id;
          obj.valueFormattedString = item.shortName || item.name;
          break;        
        case 'activeStates':
          obj.valueForRequest = { id: item.id, name: item.name };
          obj.valueFormattedString = item.name;
          break;      
        case 'isActiv':
        case 'keyDocument':
        case 'producers':
        case 'organizationServed':
          obj.valueForRequest = `${item.id}`;
          obj.valueFormattedString = item.name;
          break;
        case 'skzi':
        case 'instanceStatuses':
        case 'keyCarrierType':
        case 'eskziUserStatus':
        default:
          obj.valueForRequest = item.id;
          obj.valueFormattedString = item.name;
      }
    }

    _onChange(obj);
  };

  const _onChangeMultiselect = items => {
    const safeItems = items || []
    const requestValue = requestEnumFormat 
                          ? safeItems
                          : safeItems.map(i => i.id)

    _onChange({
      valueForRequest: safeItems && safeItems.length ? requestValue : null,
      value: safeItems
    })
  }

  const _onChangeUnselectAllMultiselect = data => {
    const { items, unselectAllValue } = data
    const safeItems = items || []
    const requestValue = requestEnumFormat 
                          ? safeItems
                          : safeItems.map(i => i.id)

    _onChange({
      valueForRequest: unselectAllValue?.value || items.length ?   requestValueMapper({
        items: requestValue,
        unselectAllValue
      }) : null,
      value: data
    })
  }

  const _onChangeDate = (value) => {
    _onChange({
      valueForRequest: formatDate(value, "yyyy-mm-dd")
    });
  };

  const _onChangeDateRange = (value) => {
    let obj = {};

    if (!value.from && !value.to) {
      _onChange({
        valueForRequest: null,
        valueFormattedString: "",
      });
      return;
    }

    const valueForRequest = {
      start: formatDate(value.from, "yyyy-mm-dd"),
      end: formatDate(value.to, "yyyy-mm-dd"),
    };

    const valueFormattedString = `${label} с ${formatDate(value.from, "dd.mm.yyyy") || "-"} по ${
      formatDate(value.to, "dd.mm.yyyy") || "-"
    }`;

    obj = {
      valueForRequest,
      valueFormattedString,
    };

    _onChange(obj);
  };

  const _onChangeCheckbox = (value) => {
    _onChange({
      valueForRequest: value,
      valueFormattedString: value ? label : "",
    });
  };

  const _onChangeSimpleSearch = (value) => {
    _onChange({
      valueForRequest: value,
      valueFormattedString: value ? label : "",
    });
  };

  const _onChangeDropDown = (item) => {
    _onChange({
      ...item,
      valueForRequest: item ? item.id : null,
    });
  };

  const _onChangeSignerWithSignState = (item) => {
    const { signer, hasSign } = item || {};

    _onChange({
      ...item,
      valueForRequest: signer
        ? {
            signerId: signer.id,
            isSignerCompleteSign: hasSign ? hasSign.id : null,
          }
        : null,
    });
  };

  const _onChangeSelfServiceStatus = (item) => {
    const { status, skziVersionId } = item || {};
    const { id: statusId } = status || {}
    const { version } = skziVersionId || {}
    const { id: versionId} = version || {}

    _onChange({
      ...item,
      valueForRequest: status ? 
        {
          status: statusId || null,
          skziVersionId: versionId || null,
        } : null,
    });
  };

  const _onChange = (item) => {
    item.alias = alias;
    item.requestFormatIsArray = requestFormatIsArray;
    item.isExtendedSearchBlock = isExtendedSearchBlock;

    if (isAutosearch(type) || autosearch) {
      _onSearch(item);
    } else {
      onChange(item);
    }
  };

  const _onSearch = (item) => {
    onSearch(item);
  };  
  
  const independentPropValuesMap = {
    [FilterControlType.checkbox]: () => ({
      onChange: e => _onChangeCheckbox(!selectedItem.valueForRequest),
      checked: selectedItem.valueForRequest,
      type: checkboxType.yesNo,
      disabled,
      children: label,
    }),
    [FilterControlType.simpleSearch]: () => ({
      className: `${className}`,
      key: alias,
      alias,
      placeholder,
      label,
      pattern,
      value: selectedItem.valueForRequest,
      onSearch: _onSearch,
      onChange: _onChangeSimpleSearch,
      disabled,
      noHint: true,
    }),
    [FilterControlType.autocomplete]: () => ({
      notStandardService: notStandartService,
      className: `${className}`,
      label,
      onEmptySearch: true,
      serviceType: Services[serviceType],
      renderItem: render,
      renderInputValue: data => {
        return data && data.valueFormattedString;
      },
      onSelect: _onChangeSelect,
      value: selectedItem,
      disabled,
      noHint: true,
    }),
    [FilterControlType.dateRangePicker]: () => {
      const { start = "", end = "" } = selectedItem.valueForRequest || {};
      return {
        className: `${className}`,
        label,
        labelFrom: labelFrom,
        labelTo: labelTo,
        isClearable: false,
        fromDate: start,
        toDate: end,
        onChangeFrom: _onChangeDateRange,
        onChangeTo: _onChangeDateRange,
        disabled,
        noHint: true,
      };
    },
    [FilterControlType.datePicker]: () => ({
      className: `${className}`,
      label,
      value: selectedItem.valueForRequest,
      onChange: _onChangeDate,
      disabled,
      noHint: true,
    }),
    [FilterControlType.multiselect]: () => ({
      notStandardService: notStandartService,
      className: `${className}`,
      label,
      type: InputType.multiSelect,
      serviceType: Services[serviceType],
      renderItem: render,
      onSelect: _onChangeMultiselect,
      value: isEmptyObject(selectedItem) ? [] : selectedItem,
      disabled,
      noHint: true,
    }),
    [FilterControlType.unselectAllMultiselect]: () => ({
      notStandardService: notStandartService,
      className: `${className}`,
      label,
      serviceType: Services[serviceType],
      renderItem: render,
      onSelect: _onChangeUnselectAllMultiselect,
      value: selectedItem, 
      unselectAllData,
      disabled,
      noHint: true,
    }),
    [FilterControlType.dropDown]: () => ({
      className: `${className}`,
      label,
      items: options,
      active: selectedItem,
      onSelect: _onChangeDropDown,
      noHint: true,
    }),
    [FilterControlType.autocompletePicker]: () => ({
      notStandardService: notStandartService,
      className: `${className}`,
      label,
      onSelectValue: _onChangeSelect,
      filterConfig,
      serviceType: Services[serviceType],
      selectType,
      value: selectedItem,
      noHint: true,
    }),    
    [FilterControlType.signerWithSignState]: () => ({
      className: `${className}`,
      onChange: _onChangeSignerWithSignState,
      serviceType: Services[serviceType],
      value: selectedItem,
      noHint: true,
    }),    
    [FilterControlType.selfServiceStatus]: () => ({
      className: `${className}`,
      onChange: _onChangeSelfServiceStatus,
      serviceType: Services[serviceType],
      value: selectedItem,
      noHint: true,
    }),
  };
  const independentProps = independentPropValuesMap[type]?.();
  const [dependentProps, setDependentProps] = useState({});
  
  useEffect(() => {
    const createTargetPropEntriesMapper = (alias, targetProps) => prop => {
      const updatedPropValue = targetProps[prop](dependencies[alias], independentProps[prop]);
      return [prop, updatedPropValue];
    };
    const updatedDependentPropEntriesCollector = ({ alias, targetProps }) => Object.keys(targetProps).map(createTargetPropEntriesMapper(alias, targetProps));
    const updatedDependentPropEntries = elementDependencies.flatMap(updatedDependentPropEntriesCollector);
    const updatedDependentProps = Object.fromEntries(updatedDependentPropEntries);
    setDependentProps(updatedDependentProps);
  }, [dependencies]);
  
  const typeToComponentMap = {
    [FilterControlType.checkbox]: Checkbox,
    [FilterControlType.simpleSearch]: SimpleSearch,
    [FilterControlType.autocomplete]: Autocomplete,
    [FilterControlType.dateRangePicker]: DateRangePicker,
    [FilterControlType.datePicker]: DatePicker,
    [FilterControlType.multiselect]: Autocomplete,
    [FilterControlType.unselectAllMultiselect]: MultiSelect,
    [FilterControlType.dropDown]: DropDown,
    [FilterControlType.autocompletePicker]: AutocompletePicker,
    [FilterControlType.signerWithSignState]: SignerWithSignState,
    [FilterControlType.selfServiceStatus]: SelfServiceStatus,
  };
  
  const MappedComponent = typeToComponentMap[type];
  switch (type) {
    case FilterControlType.checkbox:
      return (<div className={`${className}`}>
        <MappedComponent {...{ ...independentProps, ...dependentProps }} />
      </div>);
    default:
      return MappedComponent
        ? (<MappedComponent {...{ ...independentProps, ...dependentProps }} />)
        :<div></div>;
  }  
};

