/** @format */

import React from 'react';
import { ORG_CARD_TYPE, OKI_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatKeyDoc } from 'app/components/list/Table/TableBody';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterElement';
import {
  eventLetter,
  eventDate,
  eventPerformer,
  destructionTeam,
  destructionAct,
  deliveryData,
  eventAddress,
  eventAddressId,
  eventAddressOkz,
} from 'app/components/list/Table/TableBody';

export const OkiConfig = {
  fieldsConfig: [
    {
      title: '№',
      titleInfo: '№',
      alias: 'id',
      format: item => item.number,
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 50,
    },
    {
      title: 'Наименование',
      titleInfo: 'Наименование СКЗИ, эксплуатационной и технической документации к ним, ключевых документов ',
      alias: 'name',
      format: item => formatKeyDoc(item.keyDocument),
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 400,
    },
    {
      title: 'Серийный №',
      alias: 'serialNumber',
      titleInfo:
        'Серийные номера СКЗИ, эксплуатационной и технической документации к ним, номера серий ключевых документов',
      format: item => item.keyDocument && item.keyDocument.serialNumber,
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 400,
    },
    {
      title: '№ экземпляра',
      titleInfo: 'Номера экземпляров (криптографические номера) ключевых документов',
      alias: 'identificationNumber',
      format: item => {
        const { keyDocument } = item;
        const { identificationNumber } = keyDocument || {};
        return identificationNumber || '';
      },
      visibleOnStart: true,
      useSorting: true,
      width: 300,
    },
    {
      title: 'От кого получены',
      titleInfo:
        'От кого получены или Ф.И.О сотрудника органа криптографической защиты, изготовившего ключевые документы',
      alias: 'receivedFrom',
      format: item => item.receive && item.receive.data.receivedFrom,
      visibleOnStart: true,
      useSorting: false,
      width: 350,
    },
    {
      title: 'Документ о получении',
      titleInfo: 'Дата и номер сопроводительного письма о получении',
      alias: 'receiveLetter',
      format: item => eventLetter(item.receive, '№'),
      visibleOnStart: false,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Выдано',
      titleInfo: 'Ф.И.О. пользователя СКЗИ, получившего СКЗИ',
      alias: 'delivery',
      format: item => {
        const name = formatFIO(item.delivery && item.delivery.data.eskziUser);
        const { id } = (item.delivery && item.delivery.data && item.delivery.data.eskziUser) || {};

        return name && id ? <ListLink text={name} targetType={USER_ACCOUNT_CARD_TYPE} id={id} /> : name || '';
      },
      visibleOnStart: true,
      useSorting: false,
      width: 350,
    },
    {
      title: 'Дата получения',
      titleInfo: 'Дата и расписка в получении пользователем СКЗИ',
      alias: 'sendOkLetter',
      format: item => deliveryData(item),
      visibleOnStart: true,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Подключение',
      titleInfo:
        'Ф.И.О. сотрудников органа криптографической защиты, пользователя СКЗИ, произведших подключение (установку)',
      alias: 'installation',
      format: item => eventPerformer(item.installation),
      visibleOnStart: false,
      useSorting: false,
      width: 350,
    },
    {
      title: 'Дата подключения',
      titleInfo: 'Дата подключения (установки) и подписи лиц, произведших подключение (установку) ',
      alias: 'installationDate',
      format: item => eventDate(item.installation),
      visibleOnStart: true,
      useSorting: false,
      width: 350,
    },
    {
      title: 'Номера аппаратных средств',
      titleInfo: 'Номера аппаратных средств, в которые установлены или к которым подключены СКЗИ ',
      alias: 'installationModels',
      format: item => {
        if (!item.installation) return '';
        const { data } = item.installation;
        const { hardwareNumber, hardware } = data || {};
        const { serialNumber } = hardware || {};
        return hardwareNumber || serialNumber || '';
      },
      visibleOnStart: false,
      useSorting: false,
      width: 350,
    },
    {
      title: 'Дата уничтожения',
      titleInfo: 'Дата изъятия (уничтожения)',
      alias: 'destructionActionDate',
      format: item => eventDate(item.withdrawal, item.destruction),
      visibleOnStart: true,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Произведшие изъятие',
      titleInfo:
        'Ф.И.О. сотрудников органа криптографической защиты, пользователя СКЗИ, произведших изъятие (уничтожение)',
      alias: 'destructionUser',
      format: item => destructionTeam(item.destruction, item.withdrawal),
      visibleOnStart: false,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Акт об уничтожении',
      titleInfo: 'Номер акта или расписка об уничтожении',
      alias: 'destructionDate',
      format: item => destructionAct(item),
      visibleOnStart: true,
      useSorting: false,
      width: 350,
    },
    {
      title: 'Примечание',
      alias: 'comments',
      type: 'extendData',
      tdText: item => 'Подробнее',
      format: item => item.comments,
      visibleOnStart: false,
    },
  ],
  tablePageFiltersConfig: [
    {
      type: FilterControlType.simpleSearch,
      alias: 'id',
      label: '№',
      pattern: '^[0-9]*$',
      extended: false,
      order: 2,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'serialNumbers',
      label: 'Серийный №',
      extended: false,
      order: 3,
      requestFormatIsArray: true,
    },
    {
      type: FilterControlType.multiselect,
      alias: 'statuses',
      label: 'Статус',
      serviceType: 'instanceStatuses',
      extended: false,
      order: 4,
      autosearch: true,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'certState',
      label: 'Состояние',
      serviceType: 'keyDocCertStates',
      extended: true,
      order: 5,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'receivedFrom',
      label: 'Получено от',
      extended: true,
      order: 6,
    },
    {
      type: FilterControlType.autocompletePicker,
      alias: 'eskziUserId',
      label: 'Выдано',
      serviceType: 'skziUser',
      multiSelect: false,
      extended: true,
      order: 7,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: 'expirationDate',
      label: 'Срок действия',
      extended: true,
      order: 8,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: 'registerDate',
      label: 'Зарегистрирован',
      extended: true,
      order: 9,
    },
    {
      type: FilterControlType.dropDown,
      alias: 'isRecorded',
      label: 'Связь с носителем',
      extended: true,
      order: 10,
      options: [
        { id: 'true', title: 'Записан на носитель' },
        { id: 'false', title: 'Без носителя' },
      ],
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'names',
      label: 'Наименование',
      extended: true,
      requestFormatIsArray: true,
      order: 11,
    },
    {
      type: FilterControlType.checkbox,
      alias: 'isHardwareInstalled',
      label: 'Введен в аппаратный СКЗИ',
      extended: true,
      order: 12,
    },
  ],
};

export const OkzConfig = {
  fieldsConfig: [
    {
      title: '№',
      titleInfo: '№',
      alias: 'id',
      format: item => item.number,
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 50,
    },
    {
      title: 'Наименование',
      titleInfo: 'Наименование СКЗИ, эксплуатационной и технической документации к ним, ключевых документов',
      alias: 'name',
      format: item => formatKeyDoc(item.keyDocument),
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 400,
    },
    {
      title: 'Серийный №',
      titleInfo:
        'Серийные номера СКЗИ, эксплуатационной и технической документации к ним, номера серий ключевых документов',
      alias: 'serialNumber',
      format: item => item.keyDocument && item.keyDocument.serialNumber,
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 400,
    },
    {
      title: '№ экземпляра',
      titleInfo: 'Номера экземпляров (криптографические номера) ключевых документов',
      alias: 'identificationNumber',
      format: item => {
        const { keyDocument } = item;
        const { identificationNumber } = keyDocument || {};
        return identificationNumber || '';
      },
      visibleOnStart: true,
      useSorting: true,
      width: 300,
    },
    {
      title: 'Получено от',
      titleInfo:
        'От кого получены или Ф.И.О сотрудника органа криптографической защиты, изготовившего ключевые документы',
      alias: 'receivedFrom',
      format: item => (item.receive && item.receive.data.receivedFrom) || '',
      visibleOnStart: true,
      useSorting: true,
      width: 500,
    },
    {
      title: 'Документ о получении',
      titleInfo:
        'Дата и номер сопроводительного письма о получении или дата изготовления ключевых документов и расписка в изготовлении',
      alias: 'receiveLetter',
      format: item => eventLetter(item.receive, '№'),
      visibleOnStart: false,
      useSorting: false,
      width: 500,
    },
    {
      title: 'Кому разосланы (переданы)',
      alias: 'sendAddress',
      format: item => {
        const name = eventAddress(item.send);
        const id = eventAddressId(item.send);
        const okz = eventAddressOkz(item.send);

        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE;
        return name && id ? <ListLink text={name} targetType={targetType} id={id} /> : name || '';
      },
      visibleOnStart: true,
      useSorting: false,
      width: 350,
    },
    {
      title: 'Документ о передаче',
      titleInfo: 'Дата и номер сопроводительного письма о рассылке (передаче) ',
      alias: 'sendLetter',
      format: item => eventLetter(item.send, '№'),
      visibleOnStart: false,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Подтверждение передачи',
      titleInfo: 'Дата и номер подтверждения или расписка в получении при рассылке (передаче)',
      alias: 'sendOkLetter',
      format: item => eventLetter(item.sendOk, '№'),
      visibleOnStart: true,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Письмо о возврате',
      titleInfo: 'Дата и номер сопроводительного письма о возврате',
      alias: 'returnLetter',
      format: item => eventLetter(item.return, '№'),
      visibleOnStart: true,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Подтверждение возврата',
      titleInfo: 'Дата и номер подтверждения возврата',
      alias: 'returnOkLetter',
      format: item => eventLetter(item.returnOk, '№'),
      visibleOnStart: true,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Дата ввода в действие',
      alias: 'deliveryDate',
      format: item => {
        return formatDate(item.delivery && item.delivery.data.transmittalLetterDate, 'dd.mm.yyyy');
      },
      visibleOnStart: false,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Дата вывода из действия',
      alias: 'withdrawalLetter',
      format: item => eventDate(item.withdrawal),
      visibleOnStart: false,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Дата уничтожения',
      alias: 'destruction',
      format: item => eventDate(item.destruction),
      visibleOnStart: false,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Номер акта или расписка об уничтожении ',
      alias: 'destructionOk',
      format: item => eventLetter(item.destruction, '№', true),
      visibleOnStart: false,
      useSorting: false,
      width: 300,
    },
    {
      title: 'Примечание',
      alias: 'comments',
      type: 'extendData',
      tdText: item => 'Подробнее',
      format: item => item.comments,
      visibleOnStart: false,
    },
  ],
  tablePageFiltersConfig: [
    {
      type: FilterControlType.simpleSearch,
      alias: 'id',
      label: '№',
      pattern: '^[0-9]*$',
      extended: false,
      order: 2,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'serialNumbers',
      label: 'Серийный №',
      extended: false,
      order: 3,
      requestFormatIsArray: true,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'certState',
      label: 'Состояние',
      serviceType: 'keyDocCertStates',
      extended: true,
      order: 5,
    },
    {
      type: FilterControlType.multiselect,
      alias: 'statuses',
      label: 'Статус',
      serviceType: 'instanceStatuses',
      extended: false,
      order: 4,
      autosearch: true,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'receivedFrom',
      label: 'Получено от',
      extended: true,
      order: 6,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'sentTo',
      label: 'Передано в',
      serviceType: 'organizations',
      multiSelect: false,
      extended: true,
      order: 7,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: 'expirationDate',
      label: 'Срок действия',
      extended: true,
      order: 8,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: 'registerDate',
      label: 'Зарегистрирован',
      extended: true,
      order: 9,
    },
    {
      type: FilterControlType.dropDown,
      alias: 'isRecorded',
      label: 'Связь с носителем',
      extended: true,
      order: 10,
      options: [
        { id: 'true', title: 'Записан на носитель' },
        { id: 'false', title: 'Без носителя' },
      ],
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'names',
      label: 'Наименование',
      extended: true,
      requestFormatIsArray: true,
      order: 11,
    },
    {
      type: FilterControlType.checkbox,
      alias: 'isHardwareInstalled',
      label: 'Введен в аппаратный СКЗИ',
      extended: true,
      order: 12,
    },
  ],
};
