import React from 'react'
import CardActionComponent from './cardMaterialAction'
import { itemActions } from 'app/components/ui/constants.js'
import withTooltip from 'app/components/HOC/TooltipHOC'
import { notEmptyCollection } from 'app/core/utility/validation'

const CardAction = withTooltip(CardActionComponent)

const CardActions = (props) => {
  const { 
    actions = [],
    onActionClick,
    customActionProps={},
    dowloadUrls = {},
    disabled
  } = props

  // TODO remove after styleguide 2.0
  if (!Array.isArray(actions[0])) {
    return null
  }

  return (
    <div className={`card-material__actions`}>
      { 
        actions.filter(item => notEmptyCollection(item).valid).map((group, i) => {
          return (
            <div key={i} className='card-material__group'>
              {group.map(key => {
                return itemActions[key]
                  ? (
                    <CardAction
                      key={key}
                      disabled={disabled}
                      actionKey={key}
                      dowloadUrl={dowloadUrls[key] || ''}
                      onClick={onActionClick}
                      customActionProps={customActionProps[key]}
                      {...itemActions[key]}
                      tooltip={customActionProps[key]?.tooltip ?? itemActions[key]?.tooltip}
                    />
                  ) : null
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default CardActions