import { useState } from 'react'
import { connect } from 'react-redux'

import MainCard, { CardButtons, cardMode, objectKey } from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import SignInfo from 'app/components/signature/signatureInfo/signInfoTable'
import { cardLines } from './cardLines'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { destructionCertObject } from './createCard'

import * as mainCardAction from 'redux/actions/destructionCertificate/allActions'
import DestructionObjectSet from './destructionObjectSet'
import History from 'app/components/history'
import { useParams } from 'react-router-dom'

export const cardConfig = ({
    update,
    initCard,
    signAction,
    certId
  }) => ({
  cardLines: cardLines,
  buttons: {
    [CardButtons.sign]: signAction,
    [CardButtons.download]: ''
  },
  restrictEditByProp: (data) => !data['isSigned'],
  getKey: data => objectKey(data, destructionCertObject),
  resource: RESOURCES.destructionCertificate,
  xlsDownloadUrl: `/api/v1/EskziDestructionCertificate/${certId}/download/xlsx`,
  signatureHeaderText: 'Изменение акта уничтожения',
  signatureServiceName: 'destructionCertificateUpdate',
  oldSignatureService: false,
  signatureEntity: 'EskziDestructionCertificates',
  updateAction: update,
  cardInitAction: initCard, 
  getSignatureData: destructionCertObject,
  pageBreadcrumbTitle: action => {
    const { payload } = action
    const { id } = payload || {}    
    return `Акт уничтожения № ${id}`;
  }
})

const Card = (props) => {
  const { 
    initCard,
    update,
    signAction,
    commonDataForm
  } = props
  const params = useParams();
  const { id } = params
  const { signers, eskzis, keyCarriers, keyDocuments } = commonDataForm || {}
  const [activeTab, setActiveTab] = useState('Информация')

  return (
    <section className='main-card destruction-certificate-card'>
      
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={setActiveTab}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              itemId={id}
              signers={signers}
              config={cardConfig({
                update,
                signAction,
                initCard,
                certId: id
              })}
              cardMode={cardMode.edit}
            > 
            </MainCard>
            <SignInfo signers={signers}/> 
            
          </ExpandingBlock>
          <DestructionObjectSet
            organizationId={id}
            eskzis={eskzis} 
            keyCarriers={keyCarriers} 
            keyDocuments={keyDocuments}            
          />
        </section>
        <div
          tabTitle={'История'}
        >
          <History
            tabTitle={'История'}
            serviceName={'destructionCertificateService'}
            itemId={id}
          />
        </div>
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card}),
  { 
    ...mainCardAction,
  })(Card)