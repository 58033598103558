import React from "react";

import { getClassName } from "app/core/utility/jsx";

export default function UploadItem({
                                       title,
                                       date,
                                       description,
                                       stage,
                                       result,
                                       progress, 
                                       isError,
                                       onClickHandler,
                                       children,
                                       noProgress
                                   }) {
    return <>
        <div
            className={getClassName(['upload-list__item', { 'upload-list__item--no-stage': !stage }])}
            onClick={() => onClickHandler()}
        >
            {stage && <div className="upload-list__item-stage">
                {stage}
            </div>}
            <div className="upload-list__item-main">
                <div className="upload-list__item-title">
                    {title}
                </div>
                <div className="upload-list__item-date">
                    {date}
                </div>
                {!noProgress && <div className="upload-list__item-progressbar">
                    <div
                        className={getClassName([
                            'upload-list__item-bar',
                            {
                                'infinity-bar': progress === 'infinity',
                                'upload-list__item-bar--error': isError
                            }
                        ])}
                        style={{width: progress === 'infinity' ? '80px' : progress}}
                    />
                </div>}
                <div className="upload-list__item-state">
                    {description}
                </div>
                <div className="upload-list__item-count">
                    {children}
                </div>
            </div>
            
            <div className="upload-list__item-progress">
                {result}
            </div>
        </div>
    </>;
}