/** @format */
import { getClassName } from 'app/core/utility/jsx';
import styles from './style.module.scss';

export const ChipType = {
  normal: 'normal',
  error: 'error',
  success: 'success',
  accent: 'accent',
};

function ColoredChipText({ text, type = ChipType.normal }) {
  return <div className={getClassName([styles.container, styles[type]])}>{text}</div>;
}

export default ColoredChipText;