/** @format */

import React from 'react';
import styles from './dialog.module.scss';

export default function Message({ title, error, messages }) {
  const { errorMessage } = error || {};

  if (errorMessage) {
    return errorMessage;
  }

  if (title) {
    return title;
  }

  if (messages) {
    return (
      <>
        {messages.map(text => (
          <span className={styles.message}>{text}</span>
        ))}
      </>
    );
  }

  return '';
}
