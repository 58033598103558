import config from 'app/core/config';

const service = async (
  serviceName,
  methodName,
  methodParams,
  setCancelCallback,
  needMock = false,
  executeMethod = true
) => {
  try {
    const isMock =
      needMock ||
      config.mockEnabled ||
      (process &&
        process.env &&
        process.env.NODE_ENV === 'development' &&
        window.AOKZMockEnabled);
    console.log(`./${serviceName}${isMock ? 'Mock' : ''}`);
    const importedService = await import(
      `./${serviceName}${isMock ? 'Mock' : ''}`
    );
    if (executeMethod) {
      return importedService.default
        ? importedService.default[methodName]
          ? importedService.default[methodName](methodParams)
          : Promise.resolve(null)
        : importedService[methodName]
        ? importedService[methodName](methodParams)
        : Promise.resolve(null);
    } else {
      return importedService.default
        ? importedService.default[methodName]
        : importedService[methodName];
    }
  } catch (err) {
    console.error('Service error:', err);
    throw new Error(
      'Ошибка в приложении. Не найден метод API, обратитесь к администратору'
    );
  }
};

/**
 * Uses API method
 * @param {string} method API method as serviceName/methodName
 * @param {*} params Method params
 * @param {*} options.setCancelCallback Reserved
 * @param {boolean} options.useMock Use mock implementation
 * @param {boolean} options.executeMethod Execute method (returns method if set to false)
 * @returns {*} Method or method call result
 */
const api = (method, params, options) => {
  const [serviceName, methodName] = method.split('/');
  const {
    setCancelCallback,
    useMock: needMock,
    executeMethod,
  } = { useMock: false, executeMethod: true, ...options };
  return service(
    `${serviceName}Service`,
    methodName,
    params,
    setCancelCallback,
    needMock,
    executeMethod
  );
};

export default service;
export { api };
