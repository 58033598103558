import React from 'react'
import Button from 'app/components/ui/button/button'
import { ReactComponent as BreakChainImg } from 'assets/img/commonVer2/ic_link_off.svg'
import withTooltip from 'app/components/HOC/TooltipHOC'
const TooltipButton = withTooltip(Button)

export default function BreakLinkButton(props) {
  const { 
    disabled, 
    className,
    eskziId,
    selectedLicenses,
    onSubmitSignEnhance,
  } = props 

  const _onClick = async () => {
    const requestData = {
      eskziId: eskziId,
      skziLicenses: [ ...selectedLicenses.map(i => i.id) ],
      signatureRequestType: 'licenseDelete'
    };

    const result = await onSubmitSignEnhance(requestData)
  }

  return (
    <TooltipButton 
      className = {className || ''}
      type='image'
      tooltip='Удалить связь'
      onClick = { _onClick }
      disabled = { disabled }
    >
      <BreakChainImg className='button-image'/>
    </TooltipButton>
  )
}
