import service from '../../../services/service'
import { defaultState } from 'redux/reducers/user/userCardReducer'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { BREADCRUMBSPUSHITEM } from '../../../../redux/actions/actionTypes'
import { getPageBreadcrumb } from 'app/navigation/breadcrumbs'
import { defaultUserFields } from '../../../../redux/reducers/user/userCardReducer'
import { addToast } from '../../../../redux/actions/toast'
import { validationRules } from '../userCard/userData'

export default function useCreateUserBlockData() {

  const location = useLocation()
  const history = useHistory()
  const { pathname } = location
  const dispatch = useDispatch()
  dispatch({
    type: BREADCRUMBSPUSHITEM,
    payload: {
      ...getPageBreadcrumb(pathname),
      forceUpdate: true
    }
  })

  const createCallback = (response) => {
    const {data, errors, isError} = response
    if (data && !errors && !isError) {
      history.push((`/accessManagement/users/card/${data.id}`))
      dispatch(addToast('Запись успешно добавлена'))
    }
    return response
  }

  const formData = {
    validationRules: validationRules,
    defaultState: defaultState
  }

  const get = () => Promise.resolve({data: defaultUserFields})

  const update = (data) => service('userService', 'createUser', {
    ...data,
    organizationId: data.organization ? data.organization.id : null
  })

  return {
    formData,
    get,
    update,
    createCallback,
  }
}
