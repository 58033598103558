import React from 'react'
import { withRouter } from "react-router";
import HeaderUserInfo from "./headerUserInfo";
import FileProgressDialog from 'app/components/ui/file/fileProgressComponent/fileProgressComponent'

import Logo from '../navigation/applicationLogo'
import { ReactComponent as BurgerImg } from 'assets/img/menu/ic_burger.svg';
import Button from 'app/components/ui/button/button'
import { useWebSockets } from 'app/hooks/useWebSockets';
import Notification from 'app/components/Notification';
import {MassImportWrap} from "app/components/MassImports/MassImportWrap";

const Header = props => {
  const { onLogout, onMenuToggle, profile, history } = props

  ///TODO
  // SignalR пока не работает, поэтому выключили
  // в след релизе (текущий 1.5.0) надо доработать механизм опроса
  // сервера - добавить свой graceful degradation, последняя стадия
  // которого опрос сервера с интервалом в 3-5 секунд.
  // useWebSockets("/api/v1/ws/notification")

  return (
    <div className="main-header">
      <Button
        className='main-menu-toggle'
        type = 'image'
        onClick={onMenuToggle}
      >
        <BurgerImg 
          title='Главное меню'
          className='button-image'
        />
      </Button>
      <Logo history={history}/>
      <div className='current-window-info' />
      <FileProgressDialog/>
      {/* <UploadMenuControl /> */}
      <Notification
        ///TODO
        // запуск опроса уведомлений сервера
        // когда не работает SignalR
        // надо инкапсулировать всю логику в одном месте, например
        // тут - src\app\hooks\useWebSockets.js
        noSignalR
      />
      <MassImportWrap />
      <HeaderUserInfo onLogout={onLogout} {...profile} />
    </div>
  )
}

export default withRouter(Header)