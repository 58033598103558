import { useEffect, useState } from 'react'
import SimpleSearch from 'app/components/ui/search/SimpleSearch'
import { Table as TableComponent } from 'app/components/list/Table/Table';
import Tag from 'app/components/ui/tag'
import Button from 'app/components/ui/button/button'
import service from 'app/services/service'
import { deleteArrayItemById, intersectByItemId } from 'app/core/utility/common'
import { defaultSettings as organizationsSettings } from './entities/organizations'
import { defaultSettings as courseSettings } from './entities/trainingCourses'
import { defaultSettings as objectTypeSettings } from './entities/objectTypes'
import { defaultSettings as skziUserSettings } from './entities/skziUsers'
import { defaultSettings as userAccount } from './entities/userAccount'
import { defaultSettings as skziLicense } from './entities/skziLicense'
import { defaultSettings as eskzi } from './entities/eskzi'
import { defaultSettings as keyCarrier } from './entities/keyCarrier'
import { defaultSettings as keyDocument } from './entities/keyDocument'
import { defaultSettings as okz } from './entities/okzTree'
import { defaultSettings } from './entities/default'
import withInfiniteScroll from 'app/components/HOC/InfiniteScrollHOC'
import useListFilter from 'app/components/list/Filters/useListFilter'
import Filter from 'app/components/filter';
import './picker.scss';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';

const Table = withInfiniteScroll(TableComponent)

export const Services = {
  instanceStatuses: 'instanceStatusesService',
  objectTypes: 'objectTypesService',
  skziTypes: 'skziTypesService',
  skziInstance: 'skziInstanceService',
  organizations: 'organizationService',
  skzi: 'skziService',
  skziBuild: 'skziBuildService',
  user: 'userService',
  skziUser: 'skziUserService',
  skziClass: 'skziClassService',
  keyCarrierType: 'keyCarrierTypeService',
  keyCarrier: 'keyCarrierService',
  keyDocument: 'keyDocumentService',
  userRoles: 'userRolesService',
  trainingCourses: 'courseService',
  skziLicenses: 'licenseService',
  okzTree: 'okzTreeService',
  signer: 'signerService',
}

export const SelectType = {
  single: 'singleSelect',
  multiple: 'multipleSelect'
}

export const getDefaultSettings = (serviceType, tableColumns) => {

  if (tableColumns) {
    return tableColumns
  }

  switch (serviceType) {
    case Services.organizations:
      return organizationsSettings
    case Services.objectTypes:
      return objectTypeSettings
    case Services.trainingCourses:
      return courseSettings
    case Services.skziUser:
    case Services.signer:
      return userAccount
    case Services.user:
      return skziUserSettings
    case Services.skziLicenses:
      return skziLicense
    case Services.skziInstance:
      return eskzi
    case Services.keyCarrier:
      return keyCarrier
    case Services.keyDocument:
      return keyDocument
    case Services.okzTree:
      return okz
    default:
      return defaultSettings
  }
}

const applyBtnEnabled = (alreadySelected, selectedItems) => {
  let enabled = true

  if (!(alreadySelected && alreadySelected.length)) {
    enabled = selectedItems ? selectedItems.length : false
  } else {
    const intersection = intersectByItemId(selectedItems,alreadySelected)
    enabled = !(selectedItems.length === alreadySelected.length
                && intersection.length === alreadySelected.length)
  }

  return enabled
}

export default function Picker (props) {

  const { 
    className,
    filterClassName,
    error, 
    isModal, 
    onCancel, 
    selectType,
    serviceType,
    disabledItems, 
    disableLogic,
    needBasket = true, 
    renderTagName,
    filterConfig: filtersConfig,
    tableColumns,
    onSelect,
    notStandardService,
    excludeItems,
    alreadySelected,
    onSelectedChanged,
    searchPlaceholder = 'Поиск',
    disableSelectAll
  } = props

  const {
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    filterState,
    filterForRequest,
  } = useListFilter()

  const { columns, sort: defaultSort } = getDefaultSettings(serviceType, tableColumns)
  const [ search, setSearch ] = useState('')
  const [ actualSearch, setActualSearch ] = useState('')

  const {
    inProgress,
    sorting,
    selectedItems,
    selectedObjects,

    setSelectedAction,
    setSelectedObjects,
    sortListAction,
    onInProgress,
  } = useTableExtensions({
    defaultSort: defaultSort,
  });

  useEffect(() => {
    if (alreadySelected && alreadySelected.length) {
      setSelectedAction(alreadySelected.map(i => i.id))
      setSelectedObjects(alreadySelected)
    }
  }, [])

  useEffect(() => {
    onSelectedChanged && onSelectedChanged(selectedObjects)
  }, [selectedObjects])

  const removeBasketItem = (name, item) => {
    const newSelected = deleteArrayItemById(selectedObjects, item)
    
    setSelectedAction(newSelected.map(i => i.id))
    setSelectedObjects(newSelected)
  }

  const onApply = () => {
    onSelect(selectedObjects)
  }
  
  const { serviceName, serviceMethod, data = {}} = notStandardService || {}
  const dataService = notStandardService
                      ? serviceName && serviceMethod
                        ? ({page, pageSize}) => service(serviceName,  serviceMethod, { term: search, page, pageSize, ...sorting, ...data, filterData:filterForRequest})
                        : notStandardService
                      : ({page, pageSize}) => service(serviceType, 'picker', { term: search, page, pageSize, ...sorting}) 

  return (
    <div className={`picker`}>
      {filtersConfig ? (
        <Filter
          {...{
            inProgress,
            filtersConfig,
            filterState,
            setFilterStateAction,
            setFilterForRequestAction,
            setFullFilterStateAction,
            className: filterClassName,
            isModalFilter: true,
          }}
        />
      ) : (<SimpleSearch
        placeholder={searchPlaceholder}
        value={search}
        onSearch={setActualSearch}
        onChange={setSearch}
        error={error}
        disabled={inProgress}
      />)}
      <Table
        loadData = {dataService}
        dependencies = {[sorting, filterForRequest, actualSearch]}
        excludeItems = {excludeItems}
        sortListAction = {sortListAction}
        sorting = {sorting} 
        selectedItems = {selectedItems} 
        selectedObjects = {selectedObjects}
        setSelectedAction = {setSelectedAction}
        setSelectedObjects = {setSelectedObjects}
        disabledItems = {disabledItems}
        disableSelectAll = {disableSelectAll}
        disableLogic = {disableLogic}
        onInProgress = {onInProgress}
        inProgress = {inProgress}
        fieldsConfig = { columns }
        itemsSelectable = { selectType === SelectType.multiple }
        itemsSelectableRadio = { selectType === SelectType.single }
      />
      {selectType === SelectType.multiple && needBasket ? (
        <div className='picker__basket'>
          {selectedObjects.map((item, i) => <Tag key={i} text={renderTagName ? renderTagName(item) : item.shortName || item.name} item={item} onDelete={removeBasketItem}/>)}
        </div>
      ) : null}
      {isModal ? (
        <div className="dialog__footer">
          <Button
            className='footer__item'
            type = 'primary'
            onClick = {onApply}
            disabled={!applyBtnEnabled(alreadySelected, selectedObjects)}
          >
            <span className='button-title'>Выбрать</span>
          </Button>
          <Button
            className='footer__item'
            type = 'secondary'
            onClick = {onCancel}
          >
            <span className='button-title'>Отменить</span>
          </Button>
        </div>
      ) : null}
    </div>
  )
}