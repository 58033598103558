import {
  CARDUPDATE,
  CARDINIT,
  CARDPREINIT,
  CARDPREINITUPDATE,
  CARDRESET,
  CARDINPROGRESS,
  CARDSEREVERUPDATE,
  CARDAFTERCREATE,
  CARD_SET_WARNING,
  DISABLEACTIONPANEL,
  CLEAR_CARD
} from '../actionTypes'

import { formatDate } from 'app/core/utility/date'
import service from 'app/services/service'
import { dispatchLogic } from '../common'

  // export const cardInitAction = payload => dispatch => {
  //   dispatch({
  //     type: CARDINIT,
  //     payload: payload
  //   })
  // }  

  export const disableEventPanelAction = () => dispatch => {
    dispatch({ type: DISABLEACTIONPANEL });  
  }

  export const cardPreInitAction = payload => dispatch => {
    dispatch({
      type: CARDPREINITUPDATE,
      payload: payload
    })
  }  

  export const clearWarningAction = () => dispatch => {
    dispatch({
      type: CARD_SET_WARNING,
      payload: null
    })
  }

  export const setWarningAction = (warningObject) => dispatch => {

    dispatch({
      type: CARD_SET_WARNING,
      payload: warningObject
    })
  }

  export const cardUpdateAction = payload => dispatch => {
    dispatch({
      type: CARDUPDATE,
      payload: payload
    })
  }  

  export const cardResetAction = () => dispatch => {
    dispatch({
      type: CARDRESET,
      payload: null
    })
  }  

  export const cardClearAction = () => dispatch => {
    dispatch({
      type: CLEAR_CARD,
      payload: null
    })
  }  
  

  export const cardInProgressAction = payload => dispatch => {
    dispatch({
      type: CARDINPROGRESS,
      payload: payload
    })
  }  

  export const registerKeyCarrier = data => dispatch => {

    return service('keyCarrierService', 'createKeyCarrier', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
      })
  }

  export const updateKeyCarrier = data => dispatch => {

    return service('keyCarrierService', 'updateKeyCarrier', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
      })
  }

  export const initKeyCarrierCard = (itemId) => dispatch => {

    return service('keyCarrierService', 'getKeyCarrier', itemId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }
  
  
  export const initKeyCarrierCreate = () => dispatch => {
    dispatch({
      type: CARDINIT,
      payload: {
        id: 'Черновик',
        single: false,
        serialNumber: '',
        keyCarrierTypeId: null,
        factoryNumber: '',
        comment: '',
      }
    })
  }

  export const registerKeyDocument = data => dispatch => {

    return service('keyDocumentService', 'createKeyDocument', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
      })
  }

  export const updateKeyDocument = data => dispatch => {

    return service('keyDocumentService', 'updateKeyDocument', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
      })
  }

  export const initKeyDocumentCopyCard = (itemId) => dispatch => {

    return service('keyDocumentService', 'getKeyDocumentCopyData', itemId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDPREINIT, data, isError, errors)
      })
  }

  export const initKeyDocumentCard = (itemId) => dispatch => {

    return service('keyDocumentService', 'getKeyDocument', itemId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }

  export const initKeyDocumentCreate = (id, props) => dispatch => {
    const { location } = props
    const { search } = location
    const searchParams = new URLSearchParams(search);
    const type = searchParams.get('type')
    
    dispatch({
      type: CARDINIT,
      payload: {
        id: 'Черновик',
        organizationType: type === 'okz' ? 'Okz' : 'Oki'
      }
    })
  }

  export const initOkzSchemeCreate = () => dispatch => {
    dispatch({
      type: CARDINIT,
      payload: {
        id: 'Черновик',
        updateDate: new Date(),
        createDate: new Date(),
        serviceOkz: null,
        name: '',
        shortName: '',
        ogrn: '',
        inn: '',
        kpp: '',
        code: '',
        okz: true,
        description: ''
      }
    })
  }

  export const registerOkzSchemeOrganization = data => dispatch => {

    return service('okzTreeService', 'registerOkz', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
      })
  }

  export const updateOkzSchemeCard = data => dispatch => {

    return service('okzTreeService', 'updateOkz', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
      })
  }

  export const initOkzSchemeCard = data => dispatch => {

    return service('okzTreeService', 'initOkz', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }

  export const deleteOkzCard = data => dispatch => {

    return service('okzTreeService', 'deleteOkz', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CLEAR_CARD, data, isError, errors)
      })
  }

  // Ponomarev Vadim @v.ponomarev 
  // Developer

  // Мне кажется, нужно отказываться неассинхронные экшены писать в сигнатуре ассинхронных action = (data) => (dispatch) => {disptach(anotherAction)}. ПО факту, то что тут написано это ActionCreator только сразу с вызовом созданного экшена. Должно быть , скорее всего, так: initCreateEskziCardActionCreator = (id, props) => return CardInitAction А потом в компоненте dispatch(initCreateEskziCardActionCreator(id, props))
  export const initCreateEskziCard = (id, props) => dispatch => {
    const { location } = props
    const { search } = location
    const searchParams = new URLSearchParams(search);
    const type = searchParams.get('type')

    dispatch({
      type: CARDINIT,
      payload: {
        id: 'Черновик',
        updateDate: new Date(),
        createDate: new Date(),
        expirationDate: new Date(),
        isStandard: false,
        organizationType: type === 'okz' ? 'Okz' : 'Oki',
        receivedFrom: '',
        serialNumbers: [],
        status: { name:'Создание' },
        skziVersionId: null,
        transmittalLetterDate: null,
        transmittalLetterNumber: ''
      }
    })
  }

  export const initEskziCardAction = data => dispatch => {

    return service('skziService', 'get', data)
      .then(response => {
        const { data, errors, isError } = response;
        const { skzi, serialNumbers = [] } = data || {}
        const snObject = serialNumbers.reduce((acc,cur) => {
          acc[cur.id] = cur.number
          return acc
        }, {})

        return dispatchLogic(
          dispatch, 
          CARDINIT, 
          {
            ...data, 
            skziVersionId: skzi,
            serialNumbers: serialNumbers.sort((a,b) => {return a.id-b.id}),
            ...snObject
          }, 
          isError, errors)
      })
  }

  export const updateEskziCardAction = data => dispatch => {

    return service('skziService', 'updateEskzi', data)
      .then(response => {
        const { data, errors, isError } = response;
        const { skzi, serialNumbers = [] } = data || {}
        const snObject = serialNumbers.reduce((acc,cur) => {
          acc[cur.id] = cur.number
          return acc
        }, {})
        
        return dispatchLogic(
          dispatch, 
          CARDSEREVERUPDATE, 
          {
            ...data, 
            skziVersionId: skzi,
            serialNumbers: serialNumbers.sort((a,b) => {return a.id-b.id}),
            ...snObject
          }, 
          isError, errors)
      })
  }

  export const updateExternalOrgEskziAction = (data) => dispatch => {
    return service('skziService', 'updateExternalOrgEskzi', data)
      .then(response => {
        const { res, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDSEREVERUPDATE, res, isError, errors)
      })
  }

  export const getExternalUserEskziAction = (id) => dispatch => {
    return service('skziService', 'getExternalOrgEskzi', id)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }

  export const registerEskzi = data => dispatch => {
    return service('skziService', 'registerEskzi', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
      })
  }
  
  export const registerLicense = data => dispatch => {
    return service('licenseService', 'createLicense', data)
      .then(response => {
        const { data, errors, isError, isWarn } = response;
        
        return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors, isWarn)
      })
  }

  export const initLicenseCreate = () => dispatch => {
    dispatch({
      type: CARDINIT,
      payload: {
        skziVersionId: '',
        key: '',
        amount: '',
        expirationDate: null,
        type: null,
        comment: ''
      }
    })
  }

  export const initOrganizationCreate = () => dispatch => {
    dispatch({
      type: CARDINIT,
      payload: {
        id: 'Черновик',
        updateDate: new Date(),
        createDate: new Date(),
        name: '',
        shortName: '',
        ogrn: '',
        inn: '',
        kpp: '',
        code: '',
        okz: false,
        description: ''
      }
    })
  }

  export const initOrganizationCard = data => dispatch => {

    return service('organizationService', 'getOrganization', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }

  export const updateOrganizationCard = data => dispatch => {
    service('organizationService', 'updateOrganization', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
      })
  }

  export const deleteOrganizationCard = data => dispatch => {

    return service('organizationService', 'delete', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CLEAR_CARD, data, isError, errors)
      })
  }

  export const createOrganizationAction = data => dispatch => {
    return service('organizationService' ,'createOrganization', data)
     .then(response => {
       const { data, errors, isError } = response;
       
       return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
     })
  }

  export const initUserAccountCard = (itemId) => dispatch => {

    return service('userAccountService', 'getUA', itemId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }

  export const updateUserAccount = updateData => dispatch => {
    const { data } = updateData
    return service('userAccountService', 'updateUserAccount', {...data, ...updateData})
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
      })
  }
  
  export const initHostCard = itemId => async dispatch => {
    const response = await service('hostsService', 'getHost', itemId);
    const { data, errors, isError } = response;
    return dispatchLogic(dispatch, CARDINIT, data, isError, errors);
  }

  export const updateHost = updateData => async dispatch => {
    const { data } = updateData;
    const response = await service('hostsService', 'updateHost', { ...data, ...updateData });
    const { data: responseData, errors, isError } = response;
    return dispatchLogic(dispatch, CARDSEREVERUPDATE, responseData, isError, errors);
  }

  export const registerUserAccountAction = createData => dispatch => {
    const { data } = createData
    return service('userAccountService', 'createUserAccount', {...data, ...createData})
      .then(response => {
        const { data, errors, isError } = response;
        return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
      })
  }

  export const initUserAccountCreate = (itemId, props) => dispatch => {
    const { profile } = props || {}
    const { currentUser } = profile || {}
    const { organization } = currentUser || {}

    dispatch({
      type: CARDINIT,
      payload: {
        id: 'Черновик',
        accountCreateDate: new Date(),
        organization: organization,
      }
    })
  }