import { 
  ERROR,
  SIGNATUREREADYTOSIGN,
  LICENSEUPLOADSETRESULT,
  LICENSEUPLOADPROGRESS,
  LICENSEUPLOADCOMPLETE,
  LICENSEUPLOADSETFORMDATA,
  LICENSEUPLOADRESET 
} from '../../actions/actionTypes'

const defaultState = {
  formData : null,
  uploadResult: null,
  
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { formData } = state
    
  switch (action.type) {

    case LICENSEUPLOADSETRESULT:
      const [ data ] = payload || []
      const { axiosResult } = data || {}
      const { data: dt = null } = axiosResult || {}

      return {
          ...state,
          uploadResult: dt
      }

    case LICENSEUPLOADSETFORMDATA:
      return {
          ...state,
          formData: {
            ...formData,
            ...payload
          }
      }

    case LICENSEUPLOADPROGRESS:
      return {
          ...state,
          inProgress: payload
      }

    case SIGNATUREREADYTOSIGN:
    case ERROR:
    case LICENSEUPLOADCOMPLETE:
      return {
        ...state,
        inProgress: false
      }

    case LICENSEUPLOADRESET:
      return {
          ...defaultState
      }
      

    default: 
      return state
  }
}