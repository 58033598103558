/** @format */
import React from 'react';

import Input, { InputType } from 'app/components/ui/Input';
import 'react-datepicker/dist/react-datepicker.css';

export default function DatePicker(props) {
  const { value } = props;
  return <Input {...props} inputType={InputType.date} value={value ? new Date(value) : null} />;
}
