import React, {useEffect, useRef, useState} from 'react'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import Overlay from 'app/components/ui/overlay'
import { formatDate } from 'app/core/utility/date'

import FunctionalCard from '../../ui/card/FunctionalCard'
import useFunctionalCard from '../../ui/card/FunctionalCard/useFunctionalCard'
import AuthenticationForm from '../forms/AuthenticationForm'
import useAuthenticationBlock from '../hooks/useAuthenticationBlock'
import { itemActions } from '../../ui/constants'
import { notEmpty } from '../../../core/utility/validation'

export default function AuthenticationBlock({authenticationDataValid, setAuthenticationDataValid}) {

  const {
    editStateBlock,
    testConnectionInfo,
    formData,
    setTestConnectionInfo,
    setEditStateBlock,
    get,
    update,
    checkValidResponse,
    testConnection
  } = useAuthenticationBlock(setAuthenticationDataValid)

  const {
    cardState,
    editState,
    setState,
    inProgress,
    fieldsError,
    commonDataForm,
    onSubmit,
    submitEnabled,
    onCancelForm,
    onFormChange,
  } = useFunctionalCard({formData, get, update, updateCallback: checkValidResponse, parentEditState: editStateBlock})

  useEffect(()=> {
    setEditStateBlock(editState)
  }, [editState])

  const [isDisabledTestConnection, setIsDisabledTestConnection] = useState(true)

  useEffect(()=>{
    if(notEmpty(commonDataForm.serverAddress).valid
      && notEmpty(commonDataForm.serverPort).valid
      && notEmpty(commonDataForm.serverAccount).valid
      && notEmpty(commonDataForm.serverPassword).valid
      && notEmpty(commonDataForm.searchBase).valid
      && !testConnectionInfo.inProgress
      ) {
      setIsDisabledTestConnection(false)
    } else {
      setIsDisabledTestConnection(true)
    }
  }, [commonDataForm])
  
  const onActionClick = (action) => {
    switch (action){
      case itemActions.testConnection.key:
        if(!testConnectionInfo.inProgress){
          setIsDisabledTestConnection(true)
          setTestConnectionInfo({
            inProgress: true,
            text: "Проверка подключения",
            status: null
          })
          testConnection(commonDataForm).then(e => {
            setIsDisabledTestConnection(false)
            const date = formatDate(new Date(), 'hh:MM:ss dd.mm.yyyy')
            const [kod, message] = e && e.data && e.data.split('.') || []
            const text = (kod === 'Код 0') ? `Успешное подключение: ${date}` : `Ошибка подключения: ${date}`
            const status = !!(kod === 'Код 0')
            setTestConnectionInfo({
              inProgress: false,
              text,
              status
            })
          })
        }
        break
      default:
        break
    }
  }

  return (
    <ExpandingBlock
      renderHeader= {() => 'Аутентификационные данные'}
      initialState={!authenticationDataValid}
    >
      {inProgress ? <Overlay /> : null}
      <FunctionalCard
        showButtons={true}
        cardState={cardState}
        canEdit={true}
        editOnly={false}
        onSubmit={onSubmit}
        submitEnabled={submitEnabled}
        submitTitle="Сохранить"
        onCancelForm={onCancelForm}
        setState={setState}
        className="ad-settings-data-card"
        actions={[[isDisabledTestConnection ? 'disabledTestConnection' : 'testConnection'], ['connectionStatus']]}
        customActionProps={{
          connectionStatus: testConnectionInfo
        }}
        onActionClick={onActionClick}
        editState={editState}
        cancelEnabled={authenticationDataValid}
      >
        <div className="card-material__content">
          <AuthenticationForm
            cardState={cardState}
            formData={commonDataForm}
            serverFormViewModel={null}
            onChange={onFormChange}
            errorByFields={fieldsError}
          />
        </div>
      </FunctionalCard>
    </ExpandingBlock>
  )
}