import React from 'react'
import { connect } from 'react-redux'
import trialEnd from 'assets/img/errorPages/authentication-error-page.svg'

export const pageType = {
  serverError: 'serverError',
  connectionError: 'connectionError',
  notFound: 'notFound',
  unauthorized: 'unauthorized',
  trialEnd: 'trialEnd',
  licenceEnd: 'licenceEnd',
  unexpectedError: 'unexpectedError'
}

const titles = {
  serverError: 'Ошибка на сервере',
  connectionError: 'Ошибка соединения с сервером',
  notFound: 'Страница не найдена',
  unauthorized: 'Доступ запрещен',
  trialEnd: 'Доступ запрещен',
  licenceEnd: 'Доступ запрещен',
  unexpectedError: 'Ошибка'
}

export const texts = {
  serverError: 'Произошла ошибка на сервере, обратитесь к администратору',
  connectionError: 'Ошибка соединения с сервером, обратитесь к администратору',
  notFound: 'Запрашиваемая страница не найдена либо указанный объект не существует в базе данных',
  unauthorized: 'Нет прав на просмотр данной страницы или пользователь не авторизован в системе',
  trialEnd: 'Срок действия пробной версии АОКЗ завершен',
  licenceEnd: 'Срок действия лицензии истек',
  unexpectedError: 'Произошла непредвиденная ошибка. Обратитесь к администратору или повторите запрос позже'
}

const images = {
  serverError: trialEnd,
  connectionError: trialEnd,
  notFound: trialEnd,
  unauthorized: trialEnd,
  trialEnd: trialEnd,
  licenceEnd: trialEnd,
  unexpectedError: trialEnd
}

const errorPage = (props) => {
  const { type, error } = props
  const { lastErrorMessage = '' } = error || {}

  return  (
    <div className='info-page trial-page'>
      <img className = 'trial-page__img' src={images[type]} />
      <div className="trial-page__info">
        <div className='info__header'>{titles[type]}</div>
        <div className='info__text'>{texts[type]}</div>
        <br/>
        {lastErrorMessage ? (
          <>
            <span>Сообщение об ошибке:</span>
            <br/><br/>
            <div className='info__text'>{lastErrorMessage}</div>
          </>
        ) : null }
      </div>
    </div>
  )
}

const mapStateToProps = state => { 
  
  return { ...state }
}

export default connect(
  mapStateToProps,
  {}
  )(errorPage)