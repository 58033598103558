import React, { Component  } from 'react';

import dblArr from 'assets/img/common/arrow.svg'

export default class Slider extends Component {
    constructor(props){
      super(props)
  
      this.wrapper = React.createRef();
    }

    componentDidMount() {
      const { current } = this.wrapper;

      current.addEventListener('wheel', event => {
        const { disabled } = this.props;

        if (disabled) {
            return;
        }

        event.preventDefault();
        event.stopImmediatePropagation();

        if (event.deltaY < 0) {
            this._requestAnimationFrame(() => this._handlerClickLeft(50))
        } else {
            this._requestAnimationFrame(() => this._handlerClickRight(50));
        }
      });
    }

    _handlerClickLeft = (shift = 50) => {
      const { current } = this.wrapper;

      current.scrollTo({
        left: current.scrollLeft - shift,
        behavior: 'smooth'
      });
    }

    _handlerClickRight = (shift = 50) => {
      const { current } = this.wrapper;

      current.scrollTo({
        left: current.scrollLeft + shift,
        behavior: 'smooth'
      });
    }

    _requestAnimationFrame = (callback) => {
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(callback) {
            window.setTimeout(callback, 1000 / 60)
        }

      requestAnimationFrame(callback);
    }

    _requestAnimationFrameLeft = () => {
      this._requestAnimationFrame(() => this._handlerClickLeft(235))
    }

    _requestAnimationFrameRight = () => {
      this._requestAnimationFrame(() => this._handlerClickRight(235))
    }

    _isOverflown = ({ clientWidth, scrollWidth }) => {
      return scrollWidth > clientWidth;
    }

    render() {
      const { disabled, children, forTabs } = this.props;
      const { current } = this.wrapper;
      
      return (
        <div className={`slider ${disabled ? 'slider--disabled' : ''}`}>
          {this._isOverflown(current || {}) ? 
            <div 
              className="slider-arrow slider-arrow__left"
              onClick={this._requestAnimationFrameLeft}
              title='Прокрутить влево'
            >
              <img src={dblArr} ></img>
            </div>: null
          }
          <div 
            className={`slider-wrapper ${forTabs ? 'slider-wrapper--tabs' : ''}`} 
            ref={this.wrapper}
          >
            {children}
          </div>
          {this._isOverflown(current || {}) ? 
            <div 
              className="slider-arrow slider-arrow__right" 
              onClick={this._requestAnimationFrameRight} 
              title='Прокрутить вправо'
            >
              <img src={dblArr} ></img>
            </div> : null
          }
        </div>
      );
    }
}
