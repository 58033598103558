import React, { useEffect, useState } from 'react'
import Button from '../ui/button/button'
import Img from '../ui/Img'
import Image from 'app/components/ui/Img/imgMap'
import VolumeNameModal from '../list/Filters/VolumesFilter/components/VolumeNameModal'

export default function VolumeSave({
  filterState,
  globalOrgId,
  gridFilterUid,
  isDisabledSave,
  selectedVolume,
  setSelectedVolume
}) {
  const [isOpenNameModal, setIsOpenNameModal] = useState(false)
  const [initName, setInitName] = useState('')

  function updateVolume(val){
    setInitName(val && val.name ? val.name : '' )
    setSelectedVolume(val)
  }

  useEffect(() => {
    setInitName(selectedVolume && selectedVolume.name ? selectedVolume.name : '')
  }, selectedVolume)
  
  return (
    <>
      {isOpenNameModal && (
        <VolumeNameModal
          filterState = {filterState}
          orgId = {globalOrgId}
          gridFilterUid={gridFilterUid}
          initName={initName}
          setSelectedVolume={updateVolume}
          onCancel={() => setIsOpenNameModal(false)}
        />
      )}
      <Button disabled={isDisabledSave} type = 'secondary' onClick = {() => setIsOpenNameModal(true)}>
        <Img img={Image.Plus} className='button-image'/>
        <span className='button-title'>Сохранить фильтр как том журнала...</span>
      </Button>
    </>
  )
}
