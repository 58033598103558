import {ADD_TOAST, REMOVE_ALL_TOASTS, REMOVE_TOAST} from "../actionTypes";

// TODO: нормально проверять закрываемость ошибки, либо самому метки ставить, либо сервер передаёт
function isErrorClosable(message){
  return (
    message.startsWith('Аккаунт временно заблокирован.')
    || message.startsWith('Аккаунт заблокирован')
    || message.startsWith('Пароль не соответствует')
  );
}

export function addToastAction(toast){
  return {
    type: ADD_TOAST,
    payload: toast
  }
}

export function removeToastAction(toast){
  return {
    type: REMOVE_TOAST,
    payload: toast
  }
}

export function removeAllToastsAction(){
  return{
    type: REMOVE_ALL_TOASTS
  }
}

export const addToast = message => dispatch => {
  const closable = isErrorClosable(message)
  dispatch(addToastAction({
    id: Math.floor((Math.random() * 1000) + 1),
    message,
    closable
  }))
}
