import React from 'react'

import Button from 'app/components/ui/button/button'

import {ReactComponent as DiskImg} from 'assets/img/commonVer2/download.svg'
import {ReactComponent as ErrorImg} from 'assets/img/commonVer2/alert.svg'
import {ReactComponent as CompleteImg} from 'assets/img/commonVer2/main_circle.svg'

export default function fileProgressNotify(props) {
  const { onClick, inProgress, anyErrors } = props

  return (
    <Button
      className='file-progress-notify'
      type = 'text'
      onClick={onClick}
    >
      {inProgress ? (
      <DiskImg className='file-progress-notify__icon-progress'></DiskImg>
        ) : (
          anyErrors ? (
            <ErrorImg 
              className='file-progress-notify__icon-error'
            />
          ) : (
            <CompleteImg 
              className='file-progress-notify__icon-complete'
            />)
        )}
      <span className="file-progress-notify__title">{inProgress ? 'Загрузка файлов...' : 'Загрузка завершена'}</span>
    </Button>
  )
}
