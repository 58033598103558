import React, { useState } from 'react'
import withSignatureHOC from 'app/components/HOC/withSignatureHOC';
import LinkKeyDocButton from './LinkKeyCarrierButton';
import service from 'app/services/service';
import { useDispatch } from 'react-redux';
import { dispatchLogic } from 'redux/actions/common';

const LinkButton = withSignatureHOC(LinkKeyDocButton)

const isDisabled = items => {
  if( !items || !items.length || items.some(i => !i.availableActions) || items.some(i => i && i.bizLocked)) {
    return true;
  }
  const hasNotValid = items.some(cur => {
    const { availableActions = [] } = cur
    const recordAction = availableActions.find( action => action.code === "KeyInfoRecord")
    if (recordAction) {
      const temp = recordAction.model.some( el => el.key === 'KeyCarrier' && el.required);
      return !temp
    }

    return true
  })

  return !!hasNotValid
}

export default function RecordKDButton({
  items,
  afterAction,
  onInProgress,
}) {
  const disabled = isDisabled(items)
  const dispatch = useDispatch()
  const linkAction = async (requestData) => {
    onInProgress(true)
    const response = await service('keyCarrierService', 'keyCarrierRecord', { requestData })
    const { isWarn, isError, errors, data } = response || {}
    if (!isWarn && !isError) {
      afterAction()
    }
    dispatchLogic(dispatch, 'MOCK', data, isError, errors)
    onInProgress(false)
    return response
  }  

  return (
    <LinkButton
      items={items}
      disabled={disabled}
      actionCallback={linkAction}
      signType='KeyCarrierRecording'
      signHeader='Запись на ключевой носитель'
    />
  )
}
