import React from 'react'

import Button from 'app/components/ui/button/button'

const getButtonText = (selfServiceState) => {
  switch (selfServiceState) {
    case 'Unknown':
      return 'Получить допуск'
    case 'CourcesAssigned':
      return 'Записаться на экзамен'
    default:
      return 'Записаться на экзамен'
  }
}

const isDisabled = (selfServiceState) => {
  switch (selfServiceState) {
    case 'Unknown':
      return false
    case 'CourcesAssigned':
      return false
    default:
      return true
  }
}

export default (props) => {
  const { selfServiceState, onCourceAssign, onExamRequest, versionId } = props
  const _onClick = selfServiceState === 'Unknown'
                    ? () => onCourceAssign(versionId)
                    : () => onExamRequest(versionId)

  return (
    <Button
      className='create-button'
      type='primary'
      onClick={_onClick}
      disabled={isDisabled(selfServiceState)}
    >
      <span className='button-title'>{getButtonText(selfServiceState)}</span>
    </Button>
  )
}