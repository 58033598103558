export const ActionType =  {
    SetProcessing: 'SET_PROCESSING',
    SetProcessingData: 'SET_PROCESSING_DATA',
    SetParsedData: 'SET_PARSED_DATA',
    SetParsedFileInfo: 'SET_PARSED_FILE_INFO',
    SetLastUploads: 'SET_LAST_UPLOADS',
    SetCurrentProcessingFiles: 'SET_CURRENT_PROCESSING_FILES',
    SetMetric: 'SET_METRIC',
    SetWSUploads: 'SET_WS_UPLOADS',
    setUserHistory: 'SET_USER_HISTORY',
    SetCreateUploadState: 'SET_UPLOAD_STATE',
    SetSingleFileParsing: 'SET_SINGLE_FILE_PARSING',
    SetSingleFileParsingId: 'SET_SINGLE_FILE_PARSING_ID',
    SetExternalId: 'SET_EXTERNAL_ID',
    SetProcessingLongPolling: 'SET_PROCESSING_LONG_POLLING',
    SetUploadModalState: 'SET_UPLOAD_MODAL_STATE',
    SetUploadModalFileTypeState: 'SET_UPLOAD_MODAL_FILE_TYPE_STATE',
};
export const ActionCreator = {
    setProcessingLongPolling: (state) => ({
        type: ActionType.SetProcessingLongPolling,
        payload: state,
    }),
    setExternalIdState: (id) => ({
        type: ActionType.SetExternalId,
        payload: id,
    }),
    setUploadModalFileTypeState: (state) => ({
        type: ActionType.SetUploadModalFileTypeState,
        payload: state,
    }),
    setUploadModalState: (state) => ({
        type: ActionType.SetUploadModalState,
        payload: state,
    }),
    setCreateUploadState: (isCreateUploadState) => ({
        type: ActionType.SetCreateUploadState,
        payload: isCreateUploadState,
    }),
    setUploadProcessing: (isProcessing) => ({
        type: ActionType.SetProcessing,
        payload: isProcessing,
    }),
    setUploadProcessingData: (data) => ({
        type: ActionType.SetProcessingData,
        payload: data,
    }),
    setParsedData: (data) => ({
        type: ActionType.SetParsedData,
        payload: data,
    }),
    setParsedFileInfo: (data) => ({
        type: ActionType.SetParsedFileInfo,
        payload: data,
    }),
    setLastUploads: (data) => ({
        type: ActionType.SetLastUploads,
        payload: data,
    }),
    setCurrentProcessingFiles: (data) => ({
        type: ActionType.SetCurrentProcessingFiles,
        payload: data,
    }),
    setMetric: (data) => ({
        type: ActionType.SetMetric,
        payload: data,
    }),
    setWSUploads: (data) => ({
        type: ActionType.SetWSUploads,
        payload: data,
    }),
    setUserHistory: (data) => ({
        type: ActionType.setUserHistory,
        payload: data,
    }),
    setFileSingleParsing: (data) => ({
        type: ActionType.SetSingleFileParsing,
        payload: data,
    }),
    setFileSingleParsingId: (data) => ({
        type: ActionType.SetSingleFileParsingId,
        payload: data,
    }),
};
