import React from 'react'
import { ORG_CARD_TYPE, OKI_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterElement';
import {
  eventDate,
  eventAddress,
  eventAddressId,
  eventAddressOkz
} from 'app/components/list/Table/TableBody'

export const OkiConfig = {
  fieldsConfig : [
    { 
      title : '№',
      alias : 'id',
      format : item => item.number,
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true, 
      width : 50
    },
    { 
      title : 'Тип',
      alias : 'keyCarrierType',
      format : item => {
        const { keyCarrier, keyCarrierType } = item
        const { keyCarrierType: innerType } = keyCarrier || {}
        const { name } = keyCarrierType || innerType || {}
        return name
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 200 
    },        
    { 
      title : 'Серийный №',
      alias : 'serialNumber',
      format : item => {
        const { keyCarrier } = item
        const { serialNumber } = keyCarrier || {}
        return serialNumber
      },
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true, 
      width : 250
    },      
    { 
      title : 'Заводской №',
      alias : 'factoryNumber',
      format : item => {
        const { keyCarrier } = item
        const { factoryNumber } = keyCarrier || {}
        return factoryNumber
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 250
    },      
    { 
      title : 'Разовый',
      alias : 'single',
      format : item => {
        const { keyCarrier } = item
        const { single } = keyCarrier || {}
        return single ? 'Да' : 'Нет'
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 200 
    },     
    { 
      title : 'Дата регистрации',
      alias : 'createDate',
      format : item => {
        const { keyCarrier } = item
        const { createDate } = keyCarrier || {}
        return formatDate( createDate, 'dd.mm.yyyy')
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 200
    },  
    { 
      title : 'Пользователь СКЗИ',
      alias : 'eskziUser',
      format : item => {
        if( !item.delivery || !item.delivery.data ) return '';
        const user = item.delivery.data.eskziUser;
        const { id } = user || {}
        const name = formatFIO( user )

        return name && id ? <ListLink
          text={name}
          targetType={USER_ACCOUNT_CARD_TYPE}
          id={id}
        /> : name || ''          
      },
      visibleOnStart : true, 
      useSorting : true,
    },    
    { 
      title : 'Дата выдачи', 
      alias : 'deliveryDate', 
      format : item => {
        return formatDate( item.delivery && item.delivery.data.transmittalLetterDate, 'dd.mm.yyyy' );
      },
      visibleOnStart : false, 
      useSorting : false, 
      width : 200
    },
    { 
      title : 'Дата изъятия',
      alias : 'withdrawalLetterDate', 
      format : item => eventDate( item.withdrawal ),
      visibleOnStart : false, 
      useSorting : false, 
      width : 200
    },   
    { 
      title : 'Дата уничтожения', 
      alias : 'destructionDate', 
      format : item => eventDate( item.destruction ),
      visibleOnStart : false, 
      useSorting : false, 
      width : 200
    },     
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      pattern: '^[0-9]*$',
      label : '№',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'serialNumbers', 
      label : 'Серийный №',
      extended : false,
      order : 3,
      requestFormatIsArray : true,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'keyCarrierType', 
      label : 'Тип', 
      serviceType : 'keyCarrierType',  
      extended : false,
      order : 4,
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'factoryNumbers', 
      label : 'Заводской №',
      extended : true,
      order : 6,
      requestFormatIsArray : true,
    },  
    {
      type : FilterControlType.dropDown, 
      alias : 'hasKeyDocuments', 
      label : 'Ключевая информация', 
      extended : true,
      order : 7,
      options: [
        { id: 'true', title: 'Записана'},
        { id: 'false', title: 'Не записана'}
      ]
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'single', 
      label : 'Разовый', 
      extended : true,
      order : 8,
      options: [
        { id: 'true', title: 'Да'},
        { id: 'false', title: 'Нет'}
      ]
    },
    {
      type : FilterControlType.autocompletePicker, 
      alias : 'eskziUserId', 
      label : 'Выдано', 
      serviceType : 'skziUser',  
      extended : true,
      order : 9,
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'statuses', 
      label : 'Статус', 
      serviceType : 'instanceStatuses',
      extended : true,
      order : 10
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'registerDate', 
      label : 'Зарегистрирован',
      extended : true,
      order : 11,
    },
  ]
}

export const OkzConfig = {
  fieldsConfig : [
    { 
      title : '№',
      alias : 'id',
      format : item => item.number,
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true, 
      width : 50
    },
    { 
      title : 'Тип',
      alias : 'keyCarrierType',
      format : item => {
        const { keyCarrier, keyCarrierType } = item
        const { keyCarrierType: innerType } = keyCarrier || {}
        const { name } = keyCarrierType || innerType || {}
        return name
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 200 
    },      
    { 
      title : 'Серийный №',
      alias : 'serialNumber',
      format : item => {
        const { keyCarrier } = item
        const { serialNumber } = keyCarrier || {}
        return serialNumber
      },
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true, 
      width : 200 
    },      
    { 
      title : 'Заводской №',
      alias : 'factoryNumber',
      format : item => {
        const { keyCarrier } = item
        const { factoryNumber } = keyCarrier || {}
        return factoryNumber
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 200 
    },      
    { 
      title : 'Разовый',
      alias : 'single',
      format : item =>  {
        const { keyCarrier } = item
        const { single } = keyCarrier || {}
        return single ? 'Да' : 'Нет'
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 200 
    },      
    { 
      title : 'Дата регистрации',
      alias : 'createDate',
      format : item => {
        const { keyCarrier } = item
        const { createDate } = keyCarrier || {}
        return formatDate( createDate, 'dd.mm.yyyy')
      },
      visibleOnStart : true, 
      useSorting : true, 
      // width : 200 
    }, 
    { 
      title : 'Передано в',
      alias : 'sentTo',
      format : item => {
        const name = eventAddress( item.send  )
        const id = eventAddressId( item.send  )
        const okz = eventAddressOkz(item.send)
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
        return name && id ? <ListLink
          text={name}
          targetType={targetType}
          id={id}
        /> : name || ''
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 200 
    },  
    { 
      title : 'Дата передачи',
      alias : 'sendDate',
      format : item => eventDate( item.send ),
      visibleOnStart : true, 
      useSorting : true, 
      // width : 200 
    },    
    { 
      title : 'Дата возврата',
      alias : 'returnDate',
      format : item => eventDate( item.return ),
      visibleOnStart : true, 
      useSorting : true, 
      // width : 200 
    },    
    { 
      title : 'Дата уничтожения', 
      alias : 'destructionDate', 
      format : item => eventDate( item.destruction ),
      visibleOnStart : false, 
      useSorting : false, 
      width : 300 
    },   
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      pattern: '^[0-9]*$',
      label : '№',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'serialNumbers', 
      label : 'Серийный №',
      extended : false,
      order : 3,
      requestFormatIsArray : true,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'keyCarrierType', 
      label : 'Тип', 
      serviceType : 'keyCarrierType',  
      extended : false,
      order : 4,
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'factoryNumbers', 
      label : 'Заводской №',
      extended : true,
      order : 6,
      requestFormatIsArray : true,
    },  
    {
      type : FilterControlType.dropDown, 
      alias : 'hasKeyDocuments', 
      label : 'Ключевая информация', 
      extended : true,
      order : 7,
      options: [
        { id: 'true', title: 'Записана'},
        { id: 'false', title: 'Не записана'}
      ]
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'single', 
      label : 'Разовый', 
      extended : true,
      order : 8,
      options: [
        { id: 'true', title: 'Да'},
        { id: 'false', title: 'Нет'}
      ]
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'sentTo',
      label: 'Передано в',
      serviceType: 'organizationServed',
      multiSelect: false,
      extended: true,
      order: 9,
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'statuses', 
      label : 'Статус', 
      serviceType : 'instanceStatuses',
      extended : true,
      order : 10
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'registerDate', 
      label : 'Зарегистрирован',
      extended : true,
      order : 11,
    },
  ]
}