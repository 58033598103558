import { 
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  CLEARERROR,
  LICENSECARDGET,
  LICENSECARDUPDATE,
  LICENSECARDUPDATEFORMDATA,
  LICENSECARDINPROGRESS,
  LICENSECARDRESETACTION,
  LICENSECARDGETESKZI,
  SIGNATUREREADYTOSIGN,
  } from '../../actions/actionTypes'

  const defaultState = {
    commonDataForm : {
      id: 0,
      updateDate: null,
      key: '',
      skziVersion: null,
      amount: 0,
      usedAmount: 0,
      vacantAmount: 0,
      expirationDate: null,
      type: null,
      activeState: null,
      comment: '',
      receivedFrom: null,
      sentTo: null,
    },
    viewData: {
      id: 0,
      updateDate: null,
      key: '',
      skziVersion: null,
      amount: 0,
      usedAmount: 0,
      vacantAmount: 0,
      expirationDate: null,
      type: null,
      activeState: null,
      comment: '',
      receivedFrom: null,
      sentTo: null,
    },
    licenseData: {},
    eskzi: [],
    confirmObject: null,
    addFilesDialog: false,
    deleteTypeConfirm: null,
    currentlyDeletingFiles: {},
    inProgress: false
  }
  
  export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { licenseData, viewData, selected, currentlyDeletingFiles } = state
    const { content } = licenseData
    const { performer, eskzi } = payload || {}

    switch (action.type) {

      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case LICENSECARDUPDATE: 
      case LICENSECARDGET:

        const responseData = {
          ...payload, 
          eskziId: eskzi,
          performerId: performer
        }

        return {
          ...state,
          commonDataForm:responseData,
          viewData: responseData,
          licenseData: responseData,
          inProgress: false
        }


      case CLEARERROR:
        return {
          ...state,
          confirmObject: null,
          deleteTypeConfirm: null,
          inProgress: false
        }
      
      case SIGNATUREREADYTOSIGN:
      case FIELDSERROR:
      case ERROR:
        return {
          ...state,
          inProgress: false
        }

      case LICENSECARDUPDATEFORMDATA:

        return {
          ...state,
          commonDataForm: {...payload},
        }

      case LICENSECARDINPROGRESS:
        return {...state, inProgress: payload}
  
      case LICENSECARDRESETACTION: 
        return { 
          ...defaultState,
          viewData: viewData,
          selected: selected,
          licenseData: licenseData,
          commonDataForm: viewData,
        }
  
      case LICENSECARDGETESKZI:
        return {
          ...state, 
          eskzi: payload,
          inProgress: false
        }

      default: 
        return state
    }
  }