import React from 'react'

export default function LoginFooter({visibleMailRecovery, onMailRecoveryClick}){
  return (
    visibleMailRecovery ? (
      <div className="login-form__footer">
        <div className='password-recovery'>
          Забыли пароль? Нажмите &nbsp;
          <span
            className='link-text-small'
            onClick={onMailRecoveryClick}
          >
            Восстановить пароль
          </span>
        </div>
      </div>
    ) : null
  )
}