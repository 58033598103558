import { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import { cardLines } from './maintenanceData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import SignatureDialog, { isDigitalSignNeeded } from 'app/components/signature/SignatureDialog'

import { capitalize, serverlikeErrors } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import { itemActions } from 'app/components/ui/constants'

import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'

import withAuth from 'app/components/HOC/AuthHOC'

import { breadcrumbsClearAction } from 'redux/actions/common/breadcrumbsAction'
import * as errorAction from 'redux/actions/common'
import * as maintenanceCardAction from 'redux/actions/maintenance/maintenanceCardAction'
import * as signatureActions from 'redux/actions/common/signatureAction'
import SimpleFilesTable from 'app/components/fileTable/simpleFilesTable/simpleFilesTable'

class MaintenanceCard extends PureComponent {
  constructor(props){
    super(props)

    this.state = { 
      tabTitle: 'Информация',
    }
  }

  componentDidMount() {
    const { 
      maintenanceCard,
      getMaintenanceAction,
      breadcrumbsClearAction,
      maintenanceCardInProgress, 
      match = {}, 
      location
    } = this.props
    const { pathname } = location
    const { params = {} } = match
    const { maintenanceId } = params
    const { inProgress } = maintenanceCard

    if (inProgress) {
      return
    }
   
    breadcrumbsClearAction()
    maintenanceCardInProgress(true)
    getMaintenanceAction({ maintenanceId, pathname})
  }

  componentWillUnmount () {
    const { 
      maintenanceCardResetAction,
      clearErrorAction,
      maintenanceCardOnSelect
    } = this.props

    maintenanceCardOnSelect()
    maintenanceCardResetAction()
    clearErrorAction()
  }

  _onSubmit = async () => {
    const { profile, maintenanceCard } = this.props
    const { commonDataForm } = maintenanceCard

    if (isDigitalSignNeeded(profile, 'EskziUsageCertificates')) {
      this._onSignDialog(commonDataForm)
    } else {
      this._registerWithoutSign(commonDataForm)
    }
  }
  
  _registerWithoutSign = async (data) => {
    const { 
      maintenanceCardInProgress,
      maintenanceCardUpdateAction,
      maintenanceCard
    } = this.props
    const { maintenanceData } = maintenanceCard
    const { id: maintenanceId } = maintenanceData
    const requestData = {
      maintenanceId,
      data: this._getSignatureRequestData(data),
      signature: null
    }

    maintenanceCardInProgress(true)
    maintenanceCardUpdateAction(requestData)
  }

  _onSignDialog = (data) => {
    const { 
      setRequestData, 
      generateText, 
      maintenanceCardInProgress,
    } = this.props
  
    setRequestData({...data});
    maintenanceCardInProgress(true)
    generateText(this._getSignatureRequestData(data), 'maintenanceUpdate');
  }

  _getSignatureRequestData = (data) => {
    const { 
      maintenanceCard
    } = this.props
    const { 
      eskziId,
      number,
      hardwareNumber,
      printNumber,
      distributiveRemovingDate,
      performerId,
      issueDate
    } = data
    const { maintenanceData } = maintenanceCard
    const { id } = maintenanceData
    const { id: skziId } = eskziId || {}
    const { id: userId } = performerId || {}
    
    return { 
      id,
      number,
      hardwareNumber,
      printNumber,
      performerId: userId,
      eskziId: skziId,
      issueDate: formatDate(issueDate, 'yyyy-mm-dd'),
      distributiveRemovingDate: formatDate(distributiveRemovingDate, 'yyyy-mm-dd'),
    }
  }

  _onSignatureCancel = () => {
    const { resetSignature } = this.props

    resetSignature()
  }
  
  _afterSign = () => {
    const { 
      signature,
      maintenanceCardInProgress,
      maintenanceCardUpdateAction,
      maintenanceCard
    } = this.props
    const { commonDataForm, maintenanceData } = maintenanceCard
    const { id: maintenanceId } = maintenanceData
    const { textForSignature, signatureResult } = signature
    
    const signatureToServer = {
      maintenanceId,
      data: this._getSignatureRequestData(commonDataForm),
      signature: { data: textForSignature, signedData: signatureResult }
    }

    maintenanceCardInProgress(true)
    return maintenanceCardUpdateAction(signatureToServer)
  }

  _onCancel = () => {
    const { clearErrorAction, maintenanceCardResetAction } = this.props
    maintenanceCardResetAction()
    clearErrorAction()
  }

  _applyRightsToActions = () => {
    const { profile } = this.props
    const maintenanceRights = getResourceRights(profile, RESOURCES.maintenance)

    const firstGroup = []
    const secondGroup = []
    maintenanceRights.VIEW_CARD && secondGroup.push(itemActions.xlsDownload.key)

    return [firstGroup, secondGroup]
  }

  _getCardLinks = () => {
    const { maintenanceCard } = this.props
    const { maintenanceData } = maintenanceCard
    const { id } = maintenanceData

    return {
      [itemActions.xlsDownload.key]: `api/v1/EskziUsageCertificate/${id}/download/xlsx`
    }
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { maintenanceCard, maintenanceCardUpdateCommonForm, setErrorByKey, error } = this.props
    const { commonDataForm } = maintenanceCard
    const { errorByFields }  = error || {}
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    const newData = key !== 'eskziId' 
                    ? { ...commonDataForm, [key]: value }
                    : { ...commonDataForm, [key]: value, performerId: null}

    setErrorByKey(filteredErrors)
    maintenanceCardUpdateCommonForm(newData)
  }

  _renderData = () => {
    const { maintenanceCard, profile, error, match} = this.props
    const { fieldsError } = error
    const { viewData, commonDataForm } = maintenanceCard
    const fileRights = getResourceRights(profile, RESOURCES.maintenanceFiles)
    const maintenanceRights = getResourceRights(profile, RESOURCES.maintenance)
    const { params = {} } = match
    const { maintenanceId } = params
    
    return (
      <section className='maintenance-common-data'>
        <ExpandingBlock
          className='maintenance-common-data__requisites'
          renderHeader= {() => 'Реквизиты'}
          initialState={true}
        >
          <Card
            className='maintenance-data-card'
            profile={profile}
            formLines={cardLines}
            actions={this._applyRightsToActions()}
            dowloadUrls={this._getCardLinks()}
            canEdit={maintenanceRights.UPDATE}
            key={this._getMaintenanceKey(viewData)}
            data={viewData}
            formData={commonDataForm}
            onValidationError={this._onValidation}
            errorByFields={fieldsError}
            onChange={this._onFormChange}
            onSubmit={this._onSubmit}
            onCancel={this._onCancel}
            getObjectKeyFunc={this._getMaintenanceKey}
          />
        </ExpandingBlock>

        <ExpandingBlock
          className='maintenance-types'
          renderHeader= {() => 'Вложения'}
          initialState={true}
        >
          {fileRights && <SimpleFilesTable
            entityId={maintenanceId}
            downloadFilesUrl = {`EskziUsageCertificate/${maintenanceId}/content`}
            urlPartUpload={`EskziUsageCertificate/${maintenanceId}/content/add`}
            fileRights = {fileRights}
            serviceName = {'maintenanceService'}
            deleteMethod = {'deleteMaintenanceFileScroll'}
            getMethod = {'getAllMaintenanceFilesScroll'}
          />}
        </ExpandingBlock>
      </section>
    )
  }

  _getMaintenanceKey = (data) => {
    const { 
      eskziId,
      number,
      hardwareNumber,
      printNumber,
      distributiveRemovingDate,
      performerId,
      issueDate
    } = data
    const { id: skziId } = eskziId || {}
    const { id: userId } = performerId || {}

    return `${skziId}${userId}${number}${hardwareNumber}${printNumber}${formatDate(distributiveRemovingDate, 'yyyy-mm-dd')}${formatDate(issueDate, 'yyyy-mm-dd')}`
  }

  _renderModal = () => {
    const { 
      maintenanceCard, 
      maintenanceCardResetAction,
      signature,
      profile,
    } = this.props
    const { confirmObject } = maintenanceCard
    const { textForSignature } = signature || {}

    return (
      <Fragment>
        {textForSignature ? (
          <SignatureDialog
            className={'signature-form'}
            compact
            profile={profile}
            onCancel={this._onSignatureCancel}
            afterSignCallback={this._afterSign}
            renderHeader = {() => {
              return (
                <div className='sign__header'>
                  {'Изменение заключения об эксплуатации'}
                </div>
              )
            }}
        />) :null}
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onCancel={maintenanceCardResetAction}
          /> : null
        }
      </Fragment>
    )
  }

  render() {
    const { maintenanceCard } = this.props
    const { inProgress } = maintenanceCard

    return (
      <section className='maintenance-card'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <BreadcrumbsHeader />     
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...maintenanceCardAction,
    ...signatureActions,
    breadcrumbsClearAction
  })(withAuth(MaintenanceCard))