import React from 'react';
import {formatDate} from 'app/core/utility/date';
import isEqual from 'lodash.isequal';
import UploadItem from "app/components/uploadItem";
import ProcessingProgress from "app/components/ui/processingProgress/processingProgress";
import {ReactComponent as IconOk} from "assets/img/commonVer2/ic_check.svg";
import {ReactComponent as IconUploadComplete} from "assets/img/commonVer2/ic_upload_complete.svg";
import Img, { Image } from 'app/components/ui/Img';
import {Status} from "app/components/MassImports/enums";
import {IMPORT_DATE_FORMAT} from "app/components/MassImports/consts";
import {IMPORT_LIMIT_COUNT} from "app/components/MassImports/consts";

const areEqual = (prevProps, nextProps) => {
    return isEqual(prevProps.uploads, nextProps.uploads);
};

export default React.memo(function AsyncOperationUpload({uploads, isFull, onClickElementHandler}) {
    const setProgress = (fileStatus, progress) => {
        switch (fileStatus) {
            case Status.LOADING_END:
                return '100%';
            case Status.CREATING_END:
                return '100%';
            default:
                return progress;
        }
    };
    const setStage = (fileStatus) => {
        switch (fileStatus) {
            case Status.LOADING:
            case Status.LOADING_END:
                return <Img img={Image.UploadCircle} className="upload-list__stage-icon" />;
            case Status.CREATING:
            case Status.CREATING_END:
                return <Img img={Image.Save} className="upload-list__stage-icon" />;
            default:
                return <Img img={Image.AlertTriangle} className={'upload-list__stage-icon'}/>;
        }
    };
    
    const setResult = (fileStatus, progress, processingState, processedCount, totalCount) => {
        switch (fileStatus) {
            case Status.LOADING_END:
                return <Img img={Image.Upload} className="upload-list__icon upload-list__icon--ok" />;
            case Status.CREATING_END:
                return <Img img={Image.Check} className={'upload-list__icon upload-list__icon--ok'}/>;
            case Status.ERROR:
                return <Img img={Image.AlertTriangle} className={'upload-list__icon upload-list__icon--error'}/>;
            default:
                return ProcessingProgress({
                    progress,
                    processingState,
                    processedCount,
                    totalCount
                });
        }
    };
    const setCount = (fileStatus, processedCount, totalCount, errorsCount) => {
        switch (fileStatus) {
            case Status.LOADING:
                return `Строк: ${processedCount} из ${totalCount}`;
            case Status.LOADING_END:
                return `Строк: ${totalCount}`;
            case Status.ERROR:
                return `Ошибки: ${errorsCount}`;
            default:
                return `(${processedCount} из ${totalCount})`;
        }
    };

    return <div className={`upload-list ${isFull ? 'upload-list--full' : ''}`}>
        <div className={'upload-list__list'}>
            {
                uploads
                    .slice(0, isFull ? Infinity : IMPORT_LIMIT_COUNT)
                    .map((item) => {
                        const {
                            file,
                            processedCount,
                            totalCount,
                            progress,
                            isFinished,
                            isError,
                            errorsCount,
                            requiredLicenses,
                        } = item;
                        const {
                            createDate,
                            fileName,
                            id,
                            type,
                            status
                        } = file;
                        
                        const shortOfLicenses = typeof requiredLicenses !== 'undefined';

                        return <UploadItem
                            key={id}
                            id={id}
                            title={fileName}
                            date={formatDate(new Date(createDate), IMPORT_DATE_FORMAT)}
                            description={`${type.name}: ${status}`}
                            isError={isError}
                            progress={setProgress(status, progress)}
                            stage={setStage(status)}
                            result={setResult(status, progress, {isFinished, isError}, processedCount, totalCount)}
                            onClickHandler={() => onClickElementHandler(id, status, type.name, shortOfLicenses)}
                        >
                            {
                                setCount(status, processedCount, totalCount, errorsCount)
                            }
                        </UploadItem>
                    })
            }
        </div>
    </div>;
}, areEqual);