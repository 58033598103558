import React from 'react'
import PasswordField from './PasswordField'
import SubmitButton from './SubmitButton'
import LoginField from './LoginField'
import LoginFooter from './LoginFooter'

export default function LoginPasswordSubmit(props) {
  const {
    login,
    password,
    loginError,
    passwordError,
    onLoginChange,
    onPasswordChange,
    onSubmit,
    visibleMailRecovery,
    onMailRecoveryClick,
    id = '',
    loginHint = '',
  } = props

  return (
    <>
      <LoginField
        id={id + 'login'}
        name={id + 'login'}
        login={login}
        error={loginError}
        labelText="Логин"
        hint={loginHint}
        onChange={onLoginChange}
      />
      <PasswordField
        id={id + 'password'}
        name={id + 'password'}
        value={password}
        error={passwordError}
        labelText='Пароль'
        onChange={onPasswordChange}
        onSubmit={onSubmit}
      />
      <LoginFooter
        visibleMailRecovery={visibleMailRecovery}
        onMailRecoveryClick={onMailRecoveryClick}
      />
      <SubmitButton
        label='Войти'
        onSubmit={onSubmit}
      />
    </>
  )
}