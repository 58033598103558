import Group from 'app/components/ui/managePanel/group';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { EventCode, EndpointType, ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import {DownloadButton} from "app/components/list/TopPanel/DownloadButton";
import {Delete} from "app/components/list/TopPanel/Delete";
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';
import CreateCardButton from 'app/components/skzi/EskziList/createCardButton'
import SendButton from 'app/components/skzi/EskziList/businessEventPanel/sendButton'
import { useHistory } from 'react-router-dom';
import { allHasChangeUser } from 'app/components/skzi/EskziList/businessEventPanel/OkiPanel';
import { Image } from 'app/components/ui/Img';
import { Type as MassImportType } from 'app/components/MassImports/enums';
import MassImportButton from 'app/components/MassImports/MassImportButton';
import Entity from "app/core/types/entity";

const sendBusinessEvents = [
  {name: "Передача в Орган криптографической защиты"
  , eventCode: 'OkzReceiving'},
  {name: "Передача Обладателю конфиденциальной информации", eventCode: 'OkiReceiving'}
]

export default function BusinessEventPanel(props) {
  const { 
    globalOrgId,
    items,
    afterAction,
    filterForRequest,
    sorting,
    fieldsVisible,
    setSelectedAction,
    onInProgress,
    inProgress,
    rights,
    massImportRights,
    licenseState,
  } = props

  const history = useHistory();
  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service('keyCarrierService', 'deleteRange', items.map(i => i.id));
    onInProgress(false);
    return response;
  };

  return (
    <>
      {rights.CREATE ? (
        <Group>
          <CreateCardButton
            inProgress={inProgress}
            title={"Зарегистрировать"}
            entity={Entity.keyCarrier}
            licenseState={licenseState}
            onClick={() => {
              history.push("/keyDocument/keyCarrier/create");
            }}
          />
        </Group>
      ) : null}

      <Group>
        <SendButton
          items={items}
          className='send-button'
          title='Передать'
          actionList={sendBusinessEvents}
          disabled={false}
          endpointType={EndpointType.keyCarrier}
          entityType ={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturn]}
          tooltip='Вернуть'
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiSendingOk, EventCode.okzSendingOk]}
          tooltip='Подтвердить получение'
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturnOk]}
          tooltip='Подтвердить возврат'
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiWithdrawal]}
          tooltip='Изъять'
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.SoftReturn}
        />
        {!allHasChangeUser(items) ? (
          <BusinessEventTrigger
            items={items}
            eventCode={[EventCode.okiDelivery]}
            tooltip='Выдать'
            endpointType={EndpointType.keyCarrier}
            entityType={ENTITY_TYPE.keyCarrier}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        ) : (
          <BusinessEventTrigger
            items={items}
            eventCode={[EventCode.changeUser]}
            tooltip='Выдать другому пользователю'
            endpointType={EndpointType.keyCarrier}
            entityType={ENTITY_TYPE.keyCarrier}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        )}
      </Group>
      <Group>
        { massImportRights.IMPORT && <MassImportButton type={MassImportType.KEY_CARRIER} tooltip="Загрузка ключевых носителей из файла" /> }
        <DownloadButton
          id={globalOrgId}
          listFilterForRequest={filterForRequest}
          sorting={sorting}
          listFieldsVisibility={fieldsVisible}
          selected={items.map(i=>i.id)}
          titul="Выгрузить в Excel"
          serviceName='keyCarrierService'
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzDestruction, EventCode.okiDestruction]}
          tooltip='Уничтожение'
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.Extermination}
        />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction = {afterAction}
          setSelectedAction = {setSelectedAction}
          dialog ={data => {
              const arr = ['выбранный носитель', 'выбранные носители', 'выбранные носители']
              return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`
          }}
        />
      </Group>
    </>
  )
}