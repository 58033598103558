import React from 'react'

import { ReactComponent as RadioOn } from 'assets/img/commonVer2/radio_on.svg'
import { ReactComponent as RadioOff } from 'assets/img/commonVer2/radio_off.svg'

// to do
// delete after styleguide 2.0
export const RADIOTYPES = {
  square: 'square',
  circle: 'circle'
}

const radio = (props) => {
  const { className, value, onChange, checkedValue, children, type = RADIOTYPES.circle, disabled = false } = props

  return (
    <label 
      className={`radio-label ${className || ''} ${disabled ? 'radio-label--disabled' : ''} `}
    >
      <input 
        className='native-input'
        type='radio'
        onChange={onChange}
        checked={value === checkedValue}
        value = {value}
      ></input>
      <div className={`radio-visual `}>
        {value === checkedValue ? (
            <RadioOn></RadioOn>
          ) : (
            <RadioOff></RadioOff>
        )}
      </div>
      {children ? <div className="radio__caption">{children}</div> : null}
    </label>
  )
}

export default radio