/** @format */

import { getClassName } from 'app/core/utility/jsx';
import React from 'react';
import styles from './typography.module.scss';

export const CIRCLE_COLOR = {
  available: styles.availableColor,
  active: styles.activeColor,
  issued: styles.issuedColor,
  installed: styles.installedColor,
  moved: styles.movedColor,
  used: styles.usedColor,
  primary: styles.primaryColor,
  secondary: styles.secondaryColor,
};

export const CIRCLE_SIZE = {
  small: styles.smallCircle,
  regular: styles.regularCircle,
};

export function Header({ children }) {
  return <span className={getClassName(styles.header)}>{children}</span>;
}

export function NormalText({ className, children }) {
  return <span className={getClassName([className || '', styles.normalText])}>{children}</span>;
}

export function AccentText({ children }) {
  return <span className={getClassName([styles.accentText])}>{children}</span>;
}

export function IconWidgetData({ children }) {
  return <span className={getClassName([styles.iconWidgetData])}>{children}</span>;
}

export function ColorCircle({ color, size }) {
  return <div className={getClassName([color, size, styles.infoCircle])}></div>;
}

export function StyledNumber({ children }) {
  return (
    <div className={getClassName(styles.styledNumber)}>
      <AccentText>{children}</AccentText>
    </div>
  );
}

export function AccentDataHeader({ children }) {
  return <span className={getClassName(styles.dataheader)}>{children}</span>;
}
