import React from 'react'
import styles from "./filterDash.module.scss";

export default function FilterDash() {
  return (
    <div className={styles.dashContainer}>
      <div className={styles.dash}></div>
    </div>
  )
}
