/** @format */
import { sortDirection } from 'app/components/ui/constants';
import { getEskziName } from '../../../skzi/constants';

export const defaultSettings = {
  columns: [
    {
      title: '№',
      alias: 'id',
      useSorting: true,
      format: item => {
        return item.id;
      },
    },
    {
      title: 'СКЗИ',
      alias: 'skzi',
      useSorting: true,
      width: 500,
      format: item => {
        return item && getEskziName(item.skzi);
      },
    },
    {
      title: 'Серийный №',
      alias: 'serialNumber',
      useSorting: true,
      width: 500,
      format: item => {
        return item.serialNumber;
      },
    },
    {
      title: '№ апп. ср-ва',
      alias: 'hardwareNumber',
      useSorting: true,
      width: 500,
      format: item => {
        return item.hardwareNumber;
      },
    },
  ],
  sort: {
    column: 'id',
    direction: sortDirection.desc,
  },
};
