import React from 'react'
import Input from 'app/components/ui/Input'

export default function LoginField(props) {
  const { login, error, onChange, labelText, id, name, hint, onSubmit } = props

  return (
    <div className='input-element'>
      <Input
        id={id}
        name={name}
        className='input-element__input'
        placeholder={login}
        value={login}
        error={error}
        onChange={onChange}
        label={labelText}
        hint={hint}
        onSubmit={onSubmit}
      />
    </div>
  )
}