import Input from 'app/components/ui/Input'
import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import { formatFIO } from 'app/core/utility/common'
import { notEmpty } from 'app/core/utility/validation'
import DropDown, { YesNo } from 'app/components/ui/DropDown'

import { CardState } from 'app/components/ui/card/cardMaterial'
import {USER_ACCOUNT_CARD_TYPE, ORG_CARD_TYPE, OKI_CARD_TYPE} from '../../ui/constants'

export const cardLines = {
  id: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field key-carrier-card__id'
          label = '№'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  keyCarrierType: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Autocomplete 
          className='card-field key-carrier-card__keyCarrierTypeId'
          label='Тип'
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          serviceType={Services.keyCarrierType} 
          onSelect={value => onChange && onChange('keyCarrierType', value)} 
          isRequired
        />)
      },
  },
  status: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props
      const { name } = editValue || {}


      return (
        <Input 
          className='card-field key-carrier-card__status'
          label = 'Статус'
          value={name}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  updateDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <DatePicker
          className='card-field key-carrier-card__updateDate'
          label = 'Дата изменения'
          value={editValue} 
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  serialNumber: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field key-carrier-card__serialNumber'
          label = 'Серийный №'
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          onChange={value => onChange('serialNumber', value)}
          isRequired
        />
      )
    },
  },
  factoryNumber: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field key-carrier-card__factoryNumber'
          label = 'Заводской №'
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          onChange={value => onChange('factoryNumber', value)}
        />
      )
    },
  },
  okz: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, commonDataForm } = props
      const { okz, oki } = commonDataForm
      const { shortName: shortOkz, name: nameOkz, id: okzId } = okz || {}
      const { shortName: shortOki, name: nameOki, id: okiId } = oki || {}
      const value = nameOki 
                    ? shortOki || nameOki
                    : shortOkz || nameOkz

      const id = okiId || okzId
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE

      return (
        <Input 
          className='card-field key-carrier-card__okz'
          label = 'Организация'
          linkTarget={cardState === CardState.view && id && {targetType, id}}
          value={value}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  eskziUser: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field key-carrier-card__eskziUser'
          label = 'Пользователь СКЗИ'
          linkTarget={cardState === CardState.view && editValue && editValue.id && {targetType: USER_ACCOUNT_CARD_TYPE, id: editValue.id}}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
          value={formatFIO(editValue)}
        />
      )
    },
  },
  single: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DropDown
          className='card-field key-carrier-card__single'
          items={YesNo}
          error={error}
          active={editValue ? YesNo[0] : YesNo[1]}
          readOnly={cardState === CardState.view}
          onSelect={value => onChange('single', value.id === YesNo[0].id)}
          label='Разовый'
        />
      )
    },
  },  
  keyDocuments: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props
      
      return (
        <Input 
          className='card-field key-carrier-card__keyDocuments'
          label = 'Ключевые документы'
          value={editValue ? editValue.length : '0'}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  comment: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error} = props

      return (
        <Input 
          className='card-field key-carrier-card__comments'
          label = 'Описание'
          readOnly={cardState === CardState.view}
          onChange={value => onChange('comment', value)}
          value={editValue}
          error={error}
        />
      )
    },
  },
}