import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  ADMITTANCECREATE,
  SIGNATUREREADYTOSIGN,
  ADMITTANCECREATEUPDATEFORMDATA,
  ADMITTANCECREATEINPROGRESS    ,
  ADMITTANCECREATERESETACTION   ,
  ADMITTANCECREATESETERRORBYKEY,
  ADMITTANCECREATECLEARFORM,
  ADMITTANCECREATEINITFORMDATA
} from '../../actions/actionTypes'

const defaultState = {
  formData : {
    name: '',
    issueDate: null,
    skziInfo: null,
    userId: '',
    signers: [],
    status: 'Создание',
    id: 'Черновик',
    createDate: new Date(),
    changeDate: new Date(),
  },
  errorByFields: {},
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { formData } = state
    
  switch (action.type) {

    case ADMITTANCECREATE:
      return {
          ...state,
          inProgress: false
      }
        
    case SIGNATUREREADYTOSIGN:
    case FIELDSERROR:
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData
      }

    case ADMITTANCECREATEINITFORMDATA: 

      return {
        ...state,
        formData: {...formData, ...payload }
      }

    case ADMITTANCECREATEUPDATEFORMDATA: 

      return {
        ...state,
        formData: {...formData, ...payload }
      }

    case ADMITTANCECREATECLEARFORM: 
      return {
        ...defaultState
      }

    case ADMITTANCECREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case ADMITTANCECREATESETERRORBYKEY:
      const { errorByFields } = state

      return {
        ...state,
        errorByFields: { ...errorByFields, ...payload }
      }

    case ADMITTANCECREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        error: null
      }

    default: 
      return state
  }
}