import React, { Component } from 'react'

import FileUploadControl from 'app/components/ui/file/fileUploadControl'
import { fileListAPI } from 'app/core/utility/file'
import Button from 'app/components/ui/button/button'

export default class AddFileDialog extends Component {
  constructor(props){
    super(props)

    const { itemId } = props

    this.state = { 
      allFilesProgressComplete: false,
      files: [],
      itemId: itemId
    }

    this.fileControl = React.createRef();
  }

  _onAdd = async () => {
    const {  onCancel } = this.props
    const { current } = this.fileControl

    current._upload()
    onCancel()
  }

  _onCancel = async () => {
    const { onCancel } = this.props

    onCancel()
  }

  _onFileChange = (files) => {
    this.setState({files: files})
  }

  _uploadFinished = (result) => {
    const { onFileUploaded } = this.props
    
    onFileUploaded(result)
  }

  _allFilesProgressComplete = () => {
    this.setState({ allFilesProgressComplete: true })
  }

  render() {
    const { 
      urlPart,  
    } = this.props
    const { files } = this.state
    const cancelButtonText = 'Отмена'
    const headerText = 'Добавление вложения'
    const fileApi = fileListAPI( null, urlPart )

    return (
      <div className="add-type-dialog">
        <div className="add-type-dialog__header">{headerText}</div>
        <FileUploadControl
          ref={this.fileControl}
          className='add-type-dialog__file-area'
          title='Добавить вложение'
          fileApi={fileApi}
          onFileChanged={this._onFileChange}
          onUploadFinish={this._uploadFinished}
          onAllFilesProgressComplete={this._allFilesProgressComplete}
        />
        <div className="add-type-dialog__buttons-panel">
          <Button 
            type='text'
            onClick={this._onAdd}
            disabled={!files.length}
          >
            <span className='button-title'>Добавить</span>
          </Button>
          <Button 
            type='text'
            onClick={this._onCancel}
          >
            <span className='button-title'>{cancelButtonText}</span>
          </Button>
        </div>
      </div>
    )
  }
}
