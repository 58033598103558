import {useEffect, useState} from "react";
import service from "../services/service";
import {useDispatch, useSelector} from "react-redux";
import {getProcessingLongPolling, getExternalIdState} from "../../redux/actions/uploadProcessing/selectors";
import {ActionCreator} from "../../redux/actions/uploadProcessing/uploadProcessing";
import {Config} from "../components/MassImports/configs/";

export const useConnectionByType = (type = 'longPolling', method) => {
    const dispatch = useDispatch();
    const [data, setDataState] = useState(null);
    const externalId = useSelector(getExternalIdState);
    const isProcessingLongPolling = useSelector(getProcessingLongPolling);
    const connection = useSelector(({notification}) => notification ? notification.wsConnection : null);

    const longPolling = (payload = Config.PAYLOAD.LAST_FIVE_IMPORTS_FROM_CACHE) => {
        setTimeout(async () => {
            const response = await service(
                'ImportProgress',
                'fileHistory',
                payload
            );
            if (response && response.errors) {
                if (response.errors[0].detail.status !== 401) {
                    setDataState(null);
                    longPolling();
                }
            } else {
                setDataState(response);
            }

            dispatch(ActionCreator.setProcessingLongPolling(true));
        }, 5000);
    };

    useEffect(() => {
        switch (type) {
            case 'longPolling':
                longPolling(Config.PAYLOAD.IMPORTS_HISTORY);
                break;
            case 'WS':
                if (connection) {
                    connection.on(method, (data) => {
                        setDataState(JSON.parse(data));
                    });
                }
        }
    }, []);
    useEffect(() => {
        if (type === 'longPolling') {
            if (data && data.subject && data.subject.importProgress) {
                if ((data.subject.importProgress.length > 0)) {
                    longPolling();
                } else {
                    dispatch(ActionCreator.setProcessingLongPolling(false));
                }
            }
        }
    }, [data]);
    useEffect(() => {
        if (type === 'longPolling') {
            if (data && data.subject && data.subject.importProgress) {
                if (externalId && !isProcessingLongPolling) {
                    longPolling();
                }
                if (data.subject.importProgress.some(item => item.file.externalId === externalId)) {
                    dispatch(ActionCreator.setExternalIdState(null));
                }
            }
        }
    }, [externalId]);

    return {data};
};
