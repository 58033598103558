/** @format */

import React from 'react';
import styles from './info.module.scss';
import { AccentText, CIRCLE_SIZE, ColorCircle } from '../typography';

export default function InfoCircle({color, percent, size = CIRCLE_SIZE.small}) {
  return (
    <div className={styles.infoCircle}>
      <ColorCircle color={color} size={size} />
      {percent ? (
        <AccentText>{`${percent}%`}</AccentText>
      ): null}
    </div>
  )
}