import React from 'react'

export default function TotalCount({totalCount, totalCountLabel = 'всего элементов'}) {
  if (totalCount === undefined) {
    return null
  }

  const stringValue = `${totalCount}`
  const addSpace = (str) => {
    if (!str) {
      return ''
    }

    if (str.length <= 3) {
      return str 
    }

    let result = []
    for (let i = stringValue.length - 3; i >= -2; i-=3) {
      result.push(stringValue.slice(Math.max(0, i), Math.max(0, i+3)))
    }
    result.reverse()
    return result.join(' ')
  }

  return (
    <div className="table-total-count">
      {`${totalCountLabel}: ${addSpace(stringValue)}`}
    </div>
  )
}
