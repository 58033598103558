import {useDispatch, useSelector} from "react-redux";
import {isDigitalSignNeeded} from "../../signature/SignatureDialog";
import {useEffect, useReducer, useRef, useState} from "react";
import {errorAction, clearErrorAction, clearErrorFormAction, errorListAction} from "redux/actions/common";
import signatureReducer, { defaultState } from "redux/reducers/signatureReducer";
import { signActions } from "redux/actions/actionTypes";
import service from "app/services/service";

export default function useFullSign(props){
  const {
    signType,
    signHeader,
    actionCallback,
    afterAction,
  } = props

  const {
    textForSignature,
    isNeedDigitalSignByAccess,
    signatureResultRef,
    isNeedSignDialog,
    setIsNeedSignDialog,
    isProgress,
    setIsProgress,
    signatureActions,
    signatureState
  } = useSign(signType)
  const { clearSign, readyToSign } = signatureActions
  const [ generateTextErrors, setGenerateTextErrors ] = useState([])
  const [ actualData, setActualData ] = useState(null)
  const [ warningObject, setWarningObject ] = useState()
  const { errorObject } = useSelector(({error}) => error)

  const dispatch = useDispatch()

  function onCancelSign() {
    clearSign()
    dispatch(clearErrorAction())
  }

  function onSupressWarning() {
    setWarningObject(null)
    dispatch(clearErrorAction())
    onSubmitSignEnhance({ ...actualData, suppressWarn: true })
  }

  function onWarningCancel() {
    setWarningObject(null)
    clearSign()
    dispatch(clearErrorAction())
  } 

  function onErrorCancel() {
    dispatch(clearErrorAction())
  } 

  function afterChooseSign() {
    const { suppressWarn } = actualData || {}

    return actionCallback({
      data: actualData,
      signature: { data: textForSignature, signedData: signatureResultRef.current },
      suppressWarn
    }).then(response => {
      const {isError, isWarn} = response || {}
      if (!isError && !isWarn) {
        setIsNeedSignDialog(false)
        afterAction && afterAction(response)
        clearSign()
      } else {
        handleResponse(response)
      }
      return response
    })
  }

  useEffect(() => {
    return () => {
      dispatch(clearErrorFormAction())
    }
  }, [])

  const handleResponse = (response, action) => {
    const { errors, isWarn, isError } = response;
    if (isError && errors && errors[0]) {
      const {field} = errors[0]

      if (field) {
        const withoutPrefix = errors.map(i => ({
          ...i,
          field: i.field?.replace('Data.', '')
        }))
        setGenerateTextErrors(withoutPrefix)
        dispatch(errorListAction(errors))
      } else {
        dispatch(errorAction(response))
      }
    } else if (isWarn && errors && errors[0]) {
      
      setWarningObject({ error: errors[0] })
    } else {
      const { data } = response
      action && action(data)
    }
  }

  async function onSubmitSignEnhance (data){
    const dataForRequest = data
    // для всех старых апи одно - signature/generateText,
    // для всех новых апи персональное, название метода добавляется в данные для подписи - signatureRequestType
    const { signatureRequestType, suppressWarn } = data || {}
    setActualData(dataForRequest)

    if (isNeedDigitalSignByAccess) {
      setIsNeedSignDialog(true)
      setIsProgress(true);
      const signTextResponse = await service('signatureService', signatureRequestType || 'generateText', {...dataForRequest, signatureRequestType: signType})
      handleResponse(signTextResponse, readyToSign)
      setIsProgress(false)
      return signTextResponse
    } else {
      return actionCallback({
        data: dataForRequest,
        suppressWarn: suppressWarn
      }).then(response => {
        handleResponse(response, () => afterAction && afterAction(response))
        return response
      })
    }
  }


  return {
    isProgressSign: isProgress,
    isNeedSignDialog: textForSignature && isNeedSignDialog,
    generateTextErrors,
    signHeader,
    onCancelSign,
    afterChooseSign,
    onSubmitSignEnhance,
    onWarningCancel,
    onSupressWarning,
    warningObject,
    onErrorCancel,
    errorObject,
    signatureActions,
    signatureState
  }
}

function useSign(signType){
  const [signatureState, dispatch] = useReducer(signatureReducer, defaultState)
  const profile = useSelector(({profile}) => profile)
  const isNeedDigitalSignByAccess = isDigitalSignNeeded(profile, signType)
  const { textForSignature, signatureResult } = signatureState || {}
  const [isNeedSignDialog, setIsNeedSignDialog] = useState(false)
  const [isProgress, setIsProgress] = useState(false)
  const actualSignatureResultRef = useRef('')
  actualSignatureResultRef.current = signatureResult

  const setSignData = payload => {
    dispatch({ type: signActions.setSignData, payload });  
  }
  const clearSign = payload => {
    dispatch({ type: signActions.clearSign, payload });  
  }
  const readyToSign = payload => {
    dispatch({ type: signActions.readyToSign, payload });  
  }
  const setSignResult = payload => {
    dispatch({ type: signActions.setSignResult, payload });  
  }

  return {
    textForSignature,
    isNeedDigitalSignByAccess,
    signatureResultRef: actualSignatureResultRef,
    isNeedSignDialog,
    setIsNeedSignDialog,
    isProgress,
    setIsProgress,
    signatureActions: {
      setSignData,
      clearSign,
      readyToSign,
      setSignResult 
    },
    signatureState 
  }
}
