import React from "react";
import {useDispatch, useSelector} from "react-redux";
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import { clearErrorAction } from 'redux/actions/common'

export default function ErrorModal() {
  const errorObject = useSelector(({error}) => error.errorObject )
  const { title: errorTitle, type: errorType, error } = errorObject || {}
  const dispatch = useDispatch()

  return (
    <>
      { errorObject ?
        <ConfirmDialog
          header='Ошибка'
          title={errorTitle}
          type={errorType}
          error={error}
          onCancel={() => dispatch(clearErrorAction())}
        /> : null}
    </>
  )
}