import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  USERCREATE,
  USERCREATEUPDATEFORMDATA,
  USERCREATEINPROGRESS    ,
  USERCREATERESETACTION   ,
  USERCREATECLEARFORM 
 } from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { formatFIO } from 'app/core/utility/common'

const defaultState = {
  formData : {
    lastName: '',
    firstName: '',
    middleName: '',
    snils: '',
    login: '',
    email: '',
    organizationId: null,
  },
  registeredInfoObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { formData } = state
    
  switch (action.type) {

    case USERCREATE:
        return {
            ...state,
            registeredInfoObject: {
              type: dialogType.navigation,
              title: `Учетная запись «${formatFIO(payload)}» успешно зарегистрирована`
            },
            inProgress: false
        }
    
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case FIELDSERROR: 
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData,
        inProgress: false
      }

    case USERCREATEUPDATEFORMDATA: 
      const { key, value } = payload

      return {
        ...state,
        formData: {...formData, [key]: value }
      }

    case USERCREATECLEARFORM: 
      return {
        ...defaultState
      }

    case USERCREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case USERCREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        registeredInfoObject: null,
        error: null
      }

    default: 
      return state
  }
}