import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  SKZICREATE,
  SIGNATUREREADYTOSIGN,
  SKZICREATEUPDATEFORMDATA,
  SKZICREATEINPROGRESS    ,
  SKZICREATERESETACTION   ,
  SKZICREATESETERRORBYKEY,
  SKZICREATECLEARFORM  } from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'

const defaultState = {
  formData : {
    skziTypeId: '',
    description: '',
    producerId: '',
    name: '',
    // updateDate: null,
    shortDescription: ''
  },
  registeredInfoObject: null,
  errorByFields: {},
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { formData } = state
    
  switch (action.type) {

    case SKZICREATE:
        return {
            ...state,
            registeredInfoObject: {
              type: dialogType.navigation,
              title: `СКЗИ успешно зарегистрировано`
            },
            inProgress: false
        }
        
    case SIGNATUREREADYTOSIGN:
    case FIELDSERROR:
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData
      }

    case SKZICREATEUPDATEFORMDATA: 
      const { key, value } = payload

      return {
        ...state,
        formData: {...formData, [key]: value }
      }

    case SKZICREATECLEARFORM: 
      return {
        ...defaultState
      }

    case SKZICREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case SKZICREATESETERRORBYKEY:
      const { errorByFields } = state

      return {
        ...state,
        errorByFields: { ...errorByFields, ...payload }
      }

    case SKZICREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        registeredInfoObject: null,
        error: null
      }

    default: 
      return state
  }
}