import { 
  ORGANIZATIONCARDCLIENTSGETALL,
  ORGANIZATIONCARDCLIENTSDELETE,
  ORGANIZATIONCARDCLIENTSINPROGRESS, 
  ORGANIZATIONCARDCLIENTSRESETACTION, 
  ORGANIZATIONCARDCLIENTSONDELETE, 
  ORGANIZATIONCARDCLIENTSGETPICKERALL, 
  ORGANIZATIONCARDCLIENTSPICKERPICKED,
  ORGANIZATIONCARDCLIENTSPICKERCLOSE,
  ORGANIZATIONCARDCLIENTSADD,
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR
} from 'redux/actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { stringPlural } from 'app/core/utility/common'
import { excludeByItemId } from 'app/core/utility/common'

const defaultState = {
  items: [],
  allItems: [],
  pickerObject: {
    show: false,
  },
  onDeleteObject: null,
  confirmObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { 
      items,
      pageItems,
      pagination,
      pickerObject,
      allItems: stateAllItems   
  } = state
  const { pickerObject: defaultPickerState } = defaultState

  try {
    switch (action.type) {
      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case ORGANIZATIONCARDCLIENTSGETALL:
        const { data, isAllServedItems } = payload

        return {
          ...state,
          items: data,
          allItems: isAllServedItems ? data : stateAllItems,
          inProgress: false
        }

      case ORGANIZATIONCARDCLIENTSGETPICKERALL:

        return {
          ...state,
          pickerObject: {
            show: true,
          },
        }

      case ORGANIZATIONCARDCLIENTSADD: 
        return {
          ...state,
          pickerObject: {
            ...defaultPickerState,
          },
          items: payload,
          allItems: [...stateAllItems, ...payload],
          inProgress: false
        }

      case ORGANIZATIONCARDCLIENTSPICKERPICKED:
        return {
          ...state,
          pickerObject: {
            ...pickerObject,
            checkedItems: payload,
          },
          inProgress: false
        }

      case ORGANIZATIONCARDCLIENTSPICKERCLOSE:
        return {
          ...state,
          pickerObject: {
            show: false
          }
        }

      case ORGANIZATIONCARDCLIENTSONDELETE:
        const name = payload.length ? payload.map(ua => ua.name).join(', ') : payload.name
        
        return {
          ...state,
          onDeleteObject: {
            item: payload,
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить ${stringPlural(payload.length || 1, ['организацию', 'организации', 'организации'] )} «${name}» из списка клиентов?`
          }
        }

      case ORGANIZATIONCARDCLIENTSDELETE:
        const index = items.findIndex(item => item.id === payload)

        return {
          ...state,
          items: [
            ...items.slice(0, index),
            ...items.slice(index + 1),
          ],
          allItems: excludeByItemId(stateAllItems, [ {id:payload }]),
          onDeleteObject: null,
          inProgress: false,
        }

        case FIELDSERROR:
      case ERROR:
        return {
          ...defaultState,
          items,
          pageItems,
          pagination  
        }

      case ORGANIZATIONCARDCLIENTSINPROGRESS:
        const { inProgress } = payload

        return {...state, inProgress}

      case ORGANIZATIONCARDCLIENTSRESETACTION: 
        return {
          ...defaultState,
          items,
          pageItems,
          pagination  
        }

      default: 
        return state
    }
    // in case of no response
    // to do - think about proper error handling
  } catch (error) {
    return state
  }
}