import React, { Component } from 'react'

export default class cardAction extends Component {
  render() {
    const { image, actionName, onClick } = this.props

    return (
      <div
        className='actions-item'
        onClick={onClick}
      >
        <img className='actions-item__image' src={image}></img>
      </div>
    )
  }
}
