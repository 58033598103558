import UserAccountCardTab from '../UserAccountCardTab';
import UserAccountCardTabToolbar from '../UserAccountCardTabToolbar';

import toolbarConfig from './toolbarConfig';
import tableSchema from './tableSchema';

const Toolbar = UserAccountCardTabToolbar(toolbarConfig);

const organizationTypeLinkPart = (organizationType) => {
  if (organizationType === 'All') {
    return 'oki'
  }
  const type = organizationType || 'oki'
  return type.toLowerCase()
}

const KeyDocumentsTab = ({ profile, userId, userAccountOrgId, setInProgress, issueDisabled }) =>
  UserAccountCardTab({
    header: 'Ключевые документы',
    Toolbar,
    tableSchema,
    tableServiceName: 'keyDocumentService',
    tableMethodName: 'getOkiAll',
    setInProgress,
    issueDisabled,
    itemCardCallback: item => `/keyDocument/${organizationTypeLinkPart(item.organizationType)}/card/${item.id}`,
    profile,
    userId,
    userAccountOrgId
  });

export default KeyDocumentsTab;