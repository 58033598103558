import { 
    AUTHORIZE, 
    UPDATEPROFILE,
    AUTHORIZEERROR,
    LOGOUT,
    TRIALHIDE 
} from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'
//import config from 'app/core/config'

export const authorizeAction = (data) => async dispatch => {
    return service('authorizeService', data ? 'login' : 'getUser', data)
        .then(response => {
            // to do - data
            const { data, errors, isError } = response;

            return dispatchLogic(dispatch, isError ? AUTHORIZEERROR :AUTHORIZE, isError ? null : { ...data }, isError, errors)
        })
}


export const authorizeDomainAction = (data) => async dispatch => {
  return service('authorizeService', 'loginInDomain', data)
    .then(response => {
      // to do - data
      const { data, errors, isError } = response;

      return dispatchLogic(dispatch, isError ? AUTHORIZEERROR :AUTHORIZE, isError ? null : { ...data }, isError, errors)
    })
}

export const updateProfileAction = () => async dispatch => {
    return service('authorizeService', 'getUser')
        .then(response => {
            const { data, errors, isError } = response;

            return dispatchLogic(dispatch, UPDATEPROFILE, data, isError, errors)
        })
}

export const logoutAction = (url, authType) => dispatch => {
    return service('authorizeService', 'logout', {url, authType})
        .then(response => {
            return dispatch({
                type: LOGOUT,
                payload: response
            })
        }
    )
}

export const hideTrialAction = () => dispatch => {
    dispatch({
        type: TRIALHIDE,
        payload: null
    })
}