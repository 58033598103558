/** @format */

import { useEffect, useCallback, useRef, useState } from 'react';
import Portal from "app/components/ui/portal/portal";
import styles from "./popupPositioned.module.scss";
import { getClassName } from 'app/core/utility/jsx';

export default function PopupPositioned({ children, parent, clickOutside, onScroll, staticWidth, rightSide, className }) {
  const [position, setPosition] = useState();
  const popRef = useRef(null);

  const handleScroll = useCallback(e => {
    if (popRef.current && !popRef.current.contains(e.target)) {
      onScroll && onScroll();
    }
  }, []);

  const handleClickOutside = useCallback(e => {
    if (popRef.current && !popRef.current.contains(e.target)) {
      clickOutside && clickOutside();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      window.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  const getLeftPosition = (left, right, popupWidth, documentWidth, width) => {
    if (rightSide) {
      return `calc(${right}px - ${popupWidth}px)`;
    } else if (left + popupWidth < documentWidth) {
      return left + 'px';
    }
    return left - popupWidth + width + 'px';
  };

  useEffect(() => {
    const { current } = parent || {};
    const parentRect = current.getBoundingClientRect();
    const { left, right, top, width, height } = parentRect;
    const documentHeight = document.documentElement.clientHeight;
    const documentWidth = document.documentElement.clientWidth;
    const bottomSpace = documentHeight - top - height;
    const bottomPercent = (bottomSpace / documentHeight) * 100;
    const popupWidth = staticWidth || width;
    const leftPosition = getLeftPosition(left, right, popupWidth, documentWidth, width);
    if (bottomPercent >= 30) {
      setPosition({
        width: `${popupWidth}px`,
        top: `${top + height}px`,
        left: `${leftPosition}`,
      });
    } else {
      setPosition({
        width: `${popupWidth}px`,
        bottom: `${documentHeight - top}px`,
        left: `${leftPosition}`,
      });
    }
  }, []);
  return (
    <Portal>
      <div ref={popRef} className={getClassName([styles.popup, className])} style={position}>
        {children}
      </div>
    </Portal>
  );
}
