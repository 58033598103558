import React from 'react'

import Input from 'app/components/ui/Input'
import Button from 'app/components/ui/button/button'
import { formatDate } from 'app/core/utility/date'
import { copyToClipboard } from 'app/core/utility/common'
import { ReactComponent as CopyImg } from 'assets/img/commonVer2/ic_copy.svg'

// to do
// refactor - move  { mapSignature } here after stop using 
// src\app\components\signature\SignatureInfo.jsx
import { mapSignature } from 'app/components/dialog/oneClickActionFields'
import withTooltip from 'app/components/HOC/TooltipHOC'

const TooltipButton = withTooltip(Button)

const labels = {
  certificateFio: 'ФИО',
  signatureDate: 'Дата подписания',
  status: 'Сертификат на момент подписания',
  email: 'Email',
  organization: 'Организация',
  serialNumber: 'Серийный номер',
  validThrought: 'Срок действия',
  keyIdentifier: 'Идентификатор ключа субъекта',
  authorityKeyIdentifier: 'Идентификатор ключа центра сертификатов',
  issuer: 'Издатель сертификата'
}

// to do
// remove after { mapSignature } from 'app/components/dialog/oneClickActionFields' will be fixed
const values = {
  certificateFio: value => value || ' ',
  signatureDate: value => formatDate(value, 'dd.mm.yyyy'),
  status: () => 'Валидный',
  email: value => value || ' ',
  organization: value => value || ' ',
  serialNumber: value => value || ' ',//
  validThrought: value => {
    const { from, to } = value || {}
    
    return `c ${formatDate(from, 'dd.mm.yyyy')} по ${formatDate(to, 'dd.mm.yyyy')}`
  },
  keyIdentifier: value => value || ' ',//
  authorityKeyIdentifier: value => value || ' ',//
  issuer: value => value || ' '
}

const onPanelClick = (e) => {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
}

const SignatureData = ({ data, onCancel }) => {
  const signData = mapSignature(data)
  
  // to do
  // remove after { mapSignature } from 'app/components/dialog/oneClickActionFields' will be fixed
  delete signData['signFio']
  //-------
  const {
    serialNumber,
    keyIdentifier,
    authorityKeyIdentifier
  } = signData

  const withCopy = {
    serialNumber: serialNumber,
    keyIdentifier: keyIdentifier,
    authorityKeyIdentifier: authorityKeyIdentifier
  }

  delete signData['serialNumber'] 
  delete signData['keyIdentifier']
  delete signData['authorityKeyIdentifier']
  return (
    <>
      <aside className='signature-info' onClick = {onPanelClick}>
        {Object.keys(signData).map(key => {
          return (
            <Input 
              className={`signature-field__${key}`}
              readOnly={true}
              label={labels[key]}
              value={values[key](signData[key])}
              noHint
            />
          )
        })}
        {Object.keys(withCopy).map(key => {
          return (
            <div className={`signature-field signature-field__${key}`}>
              <Input 
                className="signature-serial-number"
                readOnly={true}
                label={labels[key]}
                value={values[key](withCopy[key])}
                noHint
              />
              <TooltipButton 
                className='signature-data-copy'
                type='image'
                tooltip='Копировать'
                onClick={() => copyToClipboard(withCopy[key])}
              >
                <CopyImg className='button-image'></CopyImg>
              </TooltipButton>
            </div>
          )
        })}
      </aside>
      <div className='dialog__footer'>
        <Button 
          type={'text'}
          onClick={onCancel}
        >
          <span className='button-title'>Закрыть</span>
        </Button>
      </div>
    </>
  )
}

export default SignatureData