import React from "react";
import SingleParsingFileModal from "app/components/MassImports/SingleParsingFileModal/SingleParsingFileModal";
import {useSelector} from "react-redux";
import {getFileSingleParsingState, getParsedFileInfo, getUploadModalState} from "redux/actions/uploadProcessing/selectors";
import {Status} from "app/components/MassImports/enums";
import CreationResult from "app/components/MassImports/CreationResult/CreationResult";
import LoadResult from "app/components/MassImports/LoadResult/LoadResult.jsx";
import UploadModal from "app/components/MassImports/UploadModal";
import {getUploadModalFileTypeState} from "redux/actions/uploadProcessing/selectors";

export const MassImports = () => {
    const parseFileInfo = useSelector(getParsedFileInfo);
    const fileType = useSelector(getUploadModalFileTypeState);
    const isShowUploadModal = useSelector(getUploadModalState);
    const fileSingleParsingState = useSelector(getFileSingleParsingState);
    return <>
        {
            fileSingleParsingState
            && <SingleParsingFileModal />
        }
        {
            isShowUploadModal
            && fileType
            && <UploadModal fileType={fileType}/>
        }
        {
            parseFileInfo && (parseFileInfo.fileStatus === Status.CREATING_END)
            && <CreationResult parseFileInfo={parseFileInfo} />
        }
        {
            parseFileInfo && (parseFileInfo.fileStatus === Status.LOADING_END)
            && <LoadResult parseFileInfo={parseFileInfo} />
        }
    </>;
};
