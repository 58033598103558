import React from 'react'
import Img from '../list/TopPanel/Img'
import Button from 'app/components/ui/button/button'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { linkTargets } from './constants'

const ListLink = ({text, targetType, id}) => {
  const history = useHistory()
  const profile = useSelector(({profile}) => profile)
  const rights = getResourceRights(profile, RESOURCES[targetType])

  return (
    <div className="link-list">
      { text }
      { rights.VIEW_CARD && id ? (
        <Button
          type='image'
          onClick={(e) => {
            e.preventDefault()
            history.push(`${linkTargets[targetType]}${id}`)
          }}
          className="button-image"
        >
          <Img type="NewWindowImg"/>
        </Button>
      ) : ''}
    </div>
  )
}

export default ListLink