/** @format */

import React, { useState } from 'react';
import ButtonMenu from 'app/components/ui/buttonMenu';
import styles from './mainChart.module.scss';
import { buttonTypes } from 'app/components/ui/button/button';
import Paper from 'app/components/ui/paper';
import ChartLegend from '../chartLegend';
import EskziLegend from '../chartLegend/EskziLegend';

const chartTypes = {
  eskzi: 'eskzi',
  keyDoc: 'keyDoc'
}
export const chartPickerValues = {
  [chartTypes.eskzi]: 'Экземпляры СКЗИ',
  [chartTypes.keyDoc]: 'Ключевые документы',
};

export default function MainChartWidget({ slotEskzi, slotKeyDoc, slotEskziLegend, slotKeyDocLegend }) {
  const [currentChart, setCurrentChart] = useState(chartTypes.eskzi);

  return (
    <Paper className="main-chart-widget">
      <div className={styles.mainChart}>
        <ButtonMenu
          className={styles.chartPicker}
          title={chartPickerValues[currentChart]}
          type={buttonTypes.text}
        >
          {Object.keys(chartPickerValues).map(key => (
            <div className="business-event-item" onClick={() => setCurrentChart(key)}>
              {chartPickerValues[key]}
            </div>
          ))}
        </ButtonMenu>
        <div className={styles.chartBlock}>
          <div className={styles.chartLeft}>
            {currentChart === chartTypes.eskzi && slotEskzi}
            {currentChart === chartTypes.keyDoc && slotKeyDoc}
          </div>
          <div className={styles.legendRight}>
            {currentChart === chartTypes.eskzi && slotEskziLegend}
            {currentChart === chartTypes.keyDoc && slotKeyDocLegend}
          </div>
        </div>
      </div>
    </Paper>
  );
}
