import { useCallback } from 'react';

import service from 'app/services/service';
import { useDispatch } from 'react-redux';
import { dispatchLogic } from 'redux/actions/common';

export function useListActionPanel({
  currentUA,
  onItemChanged,
  onInProgress
}){

  const dispatch = useDispatch()  

  const approveUA = useCallback(async () => {  
    onInProgress(true)
    const result = await service('userAccountService', 'approveUserAccount', currentUA.id)
    const { data, errors, isError } = result;

    isError && dispatchLogic(dispatch, 'MOCK', data, isError, errors)
    !isError && onItemChanged(data)
    onInProgress(false)
  }, [currentUA])

  const reverseApprovingUA = useCallback(async () => {
    onInProgress(true)
    const result = await service('userAccountService', 'reverseApprovingUserAccount', currentUA.id)
    const { data, errors, isError } = result;

    isError && dispatchLogic(dispatch, 'MOCK', data, isError, errors)
    !isError && onItemChanged(data)
    onInProgress(false)
  }, [currentUA])
  
  return {
    approveUA,
    reverseApprovingUA,
  }
}