import React from "react";
import Checkbox from "app/components/ui/checkbox";
import DownloadStateItem from "../downloadStateItem/DownloadStateItem";

export default function DownloadState({downloadsList, isGroupDuplicates, groupingHandler, isDisabled}) {
    return <div className={`dl-state`}>        
        <div className={`dl-state__list`}>
            {
                downloadsList.map((item, index) => {
                    const { type } = item;
                    return <DownloadStateItem
                        key={type + index}
                        { ...item }
                    />;
                })
            }
        </div>
        <div className={`dl-state__filter`}>
            <Checkbox onChange={groupingHandler} checked={isGroupDuplicates} disabled={isDisabled}>Группировать дубли</Checkbox>
        </div>
    </div>
}