/** @format */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import ConfirmDialog, { dialogMode, dialogType } from 'app/components/dialog/confirmDialog';
import Overlay from 'app/components/ui/overlay';
import { dispatchLogic } from 'redux/actions/common';
import service from 'app/services/service';
import useToast from 'app/components/ui/toast/useToast';
import withTooltip from 'app/components/HOC/TooltipHOC';

const Button = withTooltip(ButtonComponent);

export default function CertificateRenewal({
  afterActionCallback,
  item,
  disabled,
  CAUser = false,
}) {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [inProgress, setInProgress] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const onDialogToggle = () => {
    setDialogOpened(!dialogOpened);
  };

  const afterAction = () => {
    setDialogOpened(false);
    addToast('Запрос на возобновление создан');
    afterActionCallback && afterActionCallback();
  };

  const renewCert = async () => {
    setInProgress(true);
    const response = await service(
      'certificateAuthorityCertificateService',
      CAUser ? 'caRenewal' : 'renewal',
      {
        certificateSn: item?.id,
      }
    );
    const { isError, errors } = response || {};
    !isError && afterAction();
    isError && dispatchLogic(dispatch, 'MOCK', null, isError, errors);
    setInProgress(false);
  };

  return (
    <>
      {inProgress && <Overlay />}
      <Button
        type={buttonTypes.image}
        onClick={onDialogToggle}
        disabled={disabled || item?.status?.id !== 'Hold'}
        tooltip="Возобновить"
      >
        <Img img={Image.Play} />
      </Button>
      {dialogOpened && (
        <ConfirmDialog
          mode={dialogMode.small}
          header={`Возобновление сертификата`}
          type={dialogType.confirm}
          submitText="Возобновить"
          onSubmit={renewCert}
          onCancel={onDialogToggle}
          messages={['Возобновить сертификат?']}
        ></ConfirmDialog>
      )}
    </>
  );
}

