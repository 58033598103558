import { 
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  CLEARERROR,
  FILESTABLEUPDATEFORMDATA,
  FILESTABLEINPROGRESS,
  FILESTABLERESETACTION,
  FILESTABLEGETTYPES ,    
  FILESTABLEONSELECT,
  FILESTABLEONTYPEADD,
  FILESTABLEONFILEADD,
  FILESTABLEONTYPEDELETE,
  FILESTABLETYPEDELETE,
  FILESTABLETYPEADD,
  FILESTABLEONFILEDELETE,
  FILESTABLEFILEDELETE,
  FILESTABLEONUNMOUNT
  } from '../../actions/actionTypes'
  
  import { dialogType } from 'app/components/dialog/confirmDialog/'
  import { excludeByItemId, arrayToIdHashMap } from 'app/core/utility/common'
  
  const defaultState = {
    selected: {
      items: [],
      selectedHashMap: {}
    },
    content: [],
    confirmObject: null,
    addFilesDialog: false,
    deleteTypeConfirm: null,
    currentlyDeletingFiles: {},
    inProgress: false
  }
  
  export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { content, selected, currentlyDeletingFiles } = state


    switch (action.type) {
      case FILESTABLEONSELECT: 
        return {
          ...state,
          selected: payload || defaultState.selected
        }

      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }

      case FILESTABLETYPEADD:
        return {
          ...state,
          content: [...content, ...payload],
          inProgress: false
        }

      case FILESTABLEONFILEDELETE:
        const newDeleting = payload.reduce((acc, cur) => {
          acc[cur.guid] = cur.guid
          return acc
        }, {})

        return {
          ...state,
          currentlyDeletingFiles: {
            ...currentlyDeletingFiles,
            ...newDeleting
          },
          deleteTypeConfirm: null
        }

      case FILESTABLEFILEDELETE:
        return {
          ...state,
          content: excludeByItemId(content, payload.map(item => ({id: item}))),
          inProgress: false,
          deleteTypeConfirm: null
        }

      case FILESTABLEONFILEADD:
        return {
          ...state,
          addFilesDialog: true,
        }

      case CLEARERROR:
        return {
          ...state,
          confirmObject: null,
          deleteTypeConfirm: null,
          inProgress: false
        }
      
      case FIELDSERROR:
      case ERROR:
        return {
          ...state,
          inProgress: false
        }

      case FILESTABLEGETTYPES:

        return {
          ...state,
          content: payload,
          inProgress: false
        }
   
      case FILESTABLEONTYPEDELETE:

        return {
          ...state,
          deleteTypeConfirm: {
            item: payload,
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить элементы: «${payload.map(item => item.name).join(', ')}»?`
          },
          inProgress: false
        }

      case FILESTABLETYPEDELETE:

        const { items: selectedItems } = selected
        let confirmDialog = null
        const afterDeleteSelected = excludeByItemId(selectedItems, payload.map(item => ({id: item}))) 
  
        if (errors && errors.length) {
          confirmDialog = {
            header: 'Удаление невозможно',
            type: dialogType.warning,
            messages: errors.map(item => item.errorMessage)
          }
        }
  
        return {
          ...state,
          selected: {
            items: afterDeleteSelected,
            selectedHashMap: arrayToIdHashMap(afterDeleteSelected)
          },
          content: excludeByItemId(content, payload.map(item => ({id: item}))),
          deleteTypeConfirm: null,
          confirmObject: confirmDialog,
          inProgress: false
        }

      case FILESTABLEUPDATEFORMDATA:

        return {
          ...state,
          commonDataForm: {...payload},
        }

      case FILESTABLEINPROGRESS:
        return {...state, inProgress: payload}
  
      case FILESTABLERESETACTION: 
        return { 
          ...defaultState,
          selected: selected,
          content: content,
        }
  
      case FILESTABLEONUNMOUNT: 
        return { 
          ...defaultState,
        }
        
      default: 
        return state
    }
  }