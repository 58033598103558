import { Image } from 'app/components/ui/Img';
import { GroupStatuses } from "app/components/MassImports/enums";

export const IMPORTS_CHIPS = [    
    {name: 'Все записи', isDisabled: false, type: 'all', isActive: true},
    {name: 'Загрузка', isDisabled: false, type: GroupStatuses.UPLOADING, isActive: false},
    {name: 'Создание', isDisabled: false, type: GroupStatuses.CREATING, isActive: false},
    {name: 'Завершено', isDisabled: false, type: GroupStatuses.COMPLETE, isActive: false},
    {name: 'В процессе', isDisabled: false, type: GroupStatuses.IN_PROGRESS, isActive: false},
    {name: 'Ошибки', isDisabled: false, type: GroupStatuses.ERROR, isActive: false},
];
