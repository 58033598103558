/** @format */

import Group from 'app/components/ui/managePanel/group';
import ReplicateButton from 'app/components/skzi/Eskzi/replication/replicationButton';
import { EventCode, EndpointType, ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import { Image } from 'app/components/ui/Img';
import {
  BusinessEventButton,
  BusinessEventModal,
  useBusinessEvent,
} from 'app/components/ui/managePanel/businessEventTrigger';
import { ComplectationButton, ComplectationModal, useComplectation } from 'app/components/list/TopPanel/Complectation';
import { DeleteButton, DeleteModal, useDelete } from 'app/components/list/TopPanel/Delete';
import JournalDownload from 'app/components/skzi/EskziList/JournalDownload';
import SendButton from 'app/components/skzi/EskziList/businessEventPanel/sendButton';
import service from 'app/services/service';
import { useHistory } from 'react-router-dom';
import { getEskziName } from 'app/components/skzi/constants';
import DestructionFormBody from '../../DestructionFormBody';
import ButtonMenu from 'app/components/ui/buttonMenu';
import { buttonTypes } from 'app/components/ui/button/button';
import { ReactComponent as DotsVert } from 'assets/img/commonVer2/ic_dots_vert.svg';

const sendBusinessEvents = [
  {
    name: 'Передача в Орган криптографической защиты',
    eventCode: 'OkzReceiving',
  },
  {
    name: 'Передача Обладателю конфиденциальной информации',
    eventCode: 'OkiReceiving',
  },
];

export default function BusinessEventPanel(props) {
  const { items, onInProgress, eskziId, afterAction, isExternalUser, eskziRights, isOkz } = props;

  const history = useHistory();
  const [selectedItem] = items || [];
  const { isStandard, replicationAllowed } = selectedItem || {};
  const disabled = items.length !== 1 || items[0].bizLocked || !(isStandard && replicationAllowed);

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service(
      'skziAccountingService',
      'deleteRange',
      items.map(i => i.id)
    );
    onInProgress(false);
    return response;
  };

  const afterDeleteAction = () => {
    history.goBack();
  };

  const allHasChangeUser =
    items.length &&
    items.every(item => {
      const { availableActions = [] } = item;
      return availableActions.some(i => i.code === 'ChangeUser');
    });

  function afterActionCallback() {
    afterAction(eskziId);
  }

  const commonBusinessEventProps = {
    items,
    isExternalUser,
    endpointType: EndpointType.skziAccounting,
    entityType: ENTITY_TYPE.eskzi,
    afterAction: afterActionCallback,
  };

  const { businessEventTriggerLogic: returnTriggerLogic, businessEventLogic: returnLogic } = useBusinessEvent({
    eventCode: isExternalUser ? EventCode.returnGeneric : [EventCode.okzReturn],
    ...commonBusinessEventProps,
  });

  const { businessEventTriggerLogic: deliveryOkTriggerLogic, businessEventLogic: deliveryOkLogic } = useBusinessEvent({
    eventCode: [EventCode.okiDeliveryOk],
    ...commonBusinessEventProps,
  });

  const { businessEventTriggerLogic: sendingOkTriggerLogic, businessEventLogic: sendingOkLogic } = useBusinessEvent({
    eventCode: [EventCode.okiSendingOk, EventCode.okzSendingOk],
    ...commonBusinessEventProps,
  });

  const { businessEventTriggerLogic: withdrawalTriggerLogic, businessEventLogic: withdrawalLogic } = useBusinessEvent({
    eventCode: [EventCode.okiWithdrawal, EventCode.okiWithdrawalKD],
    ...commonBusinessEventProps,
  });

  const { businessEventTriggerLogic: returnOkTriggerLogic, businessEventLogic: returnOkLogic } = useBusinessEvent({
    eventCode: [EventCode.okzReturnOk],
    ...commonBusinessEventProps,
  });

  const { businessEventTriggerLogic: deliveryTriggerLogic, businessEventLogic: deliveryLogic } = useBusinessEvent({
    eventCode: [EventCode.okiDelivery],
    ...commonBusinessEventProps,
  });

  const { businessEventTriggerLogic: changeUserTriggerLogic, businessEventLogic: changeUserLogic } = useBusinessEvent({
    eventCode: [EventCode.changeUser],
    ...commonBusinessEventProps,
  });

  const { businessEventTriggerLogic: installationTriggerLogic, businessEventLogic: installationLogic } =
    useBusinessEvent({
      eventCode: isExternalUser
        ? [EventCode.okiInstallation, EventCode.externalOkiInstallation]
        : [EventCode.okiInstallation],
      ...commonBusinessEventProps,
    });

  const { businessEventTriggerLogic: destructionTriggerLogic, businessEventLogic: destructionLogic } = useBusinessEvent(
    {
      eventCode: [EventCode.okiDestruction, EventCode.okzDestruction],
      ...commonBusinessEventProps,
    }
  );

  const { complectationTriggerLogic, complectationLogic } = useComplectation({ items, isExternalUser });

  const { deleteTriggerLogic, deleteLogic } = useDelete({
    items,
    confirmContent: items => `Вы уверены, что хотите удалить экземпляр СКЗИ «${getEskziName(items[0].skzi)}»?`,
    deleteItemsAction: deleteActionCallback,
    afterAction: afterDeleteAction,
    onInProgress,
  });

  return isExternalUser ? (
    <>
      <Group>
        <BusinessEventButton tooltip="Подтвердить получение" img={Image.Acceptation} logic={sendingOkTriggerLogic} />
      </Group>
      <Group>
        <BusinessEventButton tooltip="Вернуть" img={Image.ArReturn} logic={returnTriggerLogic} />
      </Group>
      <Group>
        <BusinessEventButton tooltip="Установить" img={Image.DownloadCircle} logic={installationTriggerLogic} />
      </Group>
      <Group>
        <BusinessEventButton tooltip="Изъять" img={Image.SoftReturn} logic={withdrawalTriggerLogic} />
      </Group>
      <Group>
        <ComplectationButton tooltip="Комплектация" logic={complectationTriggerLogic} />
      </Group>
    </>
  ) : (
    <>
      {eskziRights.CREATE && isOkz ? (
        <Group>
          <ReplicateButton initialState={selectedItem} disabled={disabled} afterAction={afterAction} />
        </Group>
      ) : null}
      <Group>
        <SendButton
          items={items}
          className="send-button"
          title="Передать"
          actionList={sendBusinessEvents}
          disabled={false}
          entityType={ENTITY_TYPE.eskzi}
          endpointType={EndpointType.skziAccounting}
          afterAction={afterActionCallback}
        />
        <BusinessEventButton tooltip="Вернуть" img={Image.ArReturn} logic={returnTriggerLogic} />
      </Group>
      <Group>
        <BusinessEventButton tooltip="Получить экземпляр" img={Image.Delivery} logic={deliveryOkTriggerLogic} />
        <BusinessEventButton tooltip="Подтвердить получение" img={Image.Acceptation} logic={sendingOkTriggerLogic} />
        <BusinessEventButton
          tooltip="Подтвердить возврат"
          img={Image.AcceptationOfReturn}
          logic={returnOkTriggerLogic}
        />
      </Group>
      <Group>
        <BusinessEventButton tooltip="Изъять" img={Image.SoftReturn} logic={withdrawalTriggerLogic} />
        {!allHasChangeUser ? (
          <BusinessEventButton tooltip="Выдать" img={Image.SoftSending} logic={deliveryTriggerLogic} />
        ) : (
          <BusinessEventButton
            tooltip="Выдать другому пользователю"
            img={Image.SoftSending}
            logic={changeUserTriggerLogic}
          />
        )}
      </Group>
      <Group>
        <BusinessEventButton tooltip="Установить" img={Image.DownloadCircle} logic={installationTriggerLogic} />
      </Group>
      <Group mediaHidden>
        <BusinessEventButton tooltip="Уничтожить" img={Image.Extermination} logic={destructionTriggerLogic} />
      </Group>
      <Group mediaHidden>
        <ComplectationButton tooltip="Комплектация" logic={complectationTriggerLogic} />
      </Group>
      <Group mediaHidden>
        <JournalDownload items={items} />
      </Group>
      <Group mediaHidden>
        <DeleteButton tooltip="Удалить" logic={deleteTriggerLogic} />
      </Group>
      <Group mediaShown>
        <ButtonMenu
          tooltipPosition={'bottomLeft'}
          type={buttonTypes.image}
          LeftImage={DotsVert}
          hideChevron
          className="table-column-select"
        >
          <BusinessEventButton buttonText="Уничтожить" img={Image.Extermination} logic={destructionTriggerLogic} />
          <ComplectationButton buttonText="Комплектация" logic={complectationTriggerLogic} />
          <JournalDownload buttonText items={items} />
          <DeleteButton buttonText="Удалить" logic={deleteTriggerLogic} />
        </ButtonMenu>
      </Group>

      <BusinessEventModal
        logic={
          [
            returnLogic,
            deliveryOkLogic,
            sendingOkLogic,
            withdrawalLogic,
            returnOkLogic,
            deliveryLogic,
            changeUserLogic,
            installationLogic,
          ].find(({ modalVisible }) => modalVisible) ?? returnLogic
        }
      />
      <BusinessEventModal
        modalAlert="При уничтожении объектов будут уничтожены все связанные элементы."
        modalBody={DestructionFormBody}
        logic={destructionLogic}
      />
      <ComplectationModal logic={complectationLogic} />
      <DeleteModal logic={deleteLogic} />
    </>
  );
}
