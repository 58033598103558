import React, {useEffect} from 'react'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import Overlay from 'app/components/ui/overlay'
import FunctionalCard from '../../ui/card/FunctionalCard'
import useFunctionalCard from '../../ui/card/FunctionalCard/useFunctionalCard'
import useUserBlockData from '../hooks/useUserBlockData'
import UserForm from './UserForm'
import UserCardModals from './UserCardModals'
import useUserBlockControl from '../hooks/useUserBlockControl'

export default function UserRequisitionBlock({fio, setFio, isADUser, setIsADUser}){

  const {
    formData,
    id,
    editStateBlock,
    setEditStateBlock,
    get,
    update,
    updateCallback,
  } = useUserBlockData({setIsADUser})

  const {
    cardState,
    editState,
    setState,
    inProgress,
    fieldsError,
    commonDataForm,
    viewData,
    dispatchStore,
    onSubmit,
    submitEnabled,
    onCancelForm,
    onFormChange,
  } = useFunctionalCard({formData, get, update, updateCallback, needUpdateState: true, parentEditState: editStateBlock})

  useEffect(()=> {
    setEditStateBlock(editState)
  }, [editState])

  const {
    modalState,
    actions,
    rights,
    customActionProps,
    onConfirmDelete,
    userCardReset,
    onBack,
    onActionClick,
  } = useUserBlockControl({id, viewData, dispatchStore, commonDataForm, onFormChange, isADUser, fio, setFio})

  return (
    <ExpandingBlock
      renderHeader= {() => 'Реквизиты'}
      initialState={true}
    >
      {inProgress ? <Overlay /> : null}
      <FunctionalCard
        showButtons={true}
        actions={actions}
        customActionProps={customActionProps}
        onActionClick={onActionClick}
        cardState={cardState}
        canEdit={rights.UPDATE}
        editOnly={false}
        onSubmit={onSubmit}
        submitEnabled={submitEnabled}
        submitTitle="Сохранить"
        onCancelForm={onCancelForm}
        setState={setState}
        className="ad-settings-data-card"
        editState={editState}
        cancelEnabled={true}
      >
        <div className="card-material__content">
          <UserForm
            isADUser={isADUser}
            cardState={cardState}
            formData={commonDataForm}
            serverFormViewModel={null}
            onChange={onFormChange}
            errorByFields={fieldsError}
          />
        </div>
      </FunctionalCard>
      <UserCardModals
        modalState={modalState}
        onConfirmDelete={onConfirmDelete}
        userCardReset={userCardReset}
        onBack={onBack}
      />
    </ExpandingBlock>
  )
}