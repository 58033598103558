import { 
  ORGANIZATIONCARDSERVICEGETALL,
  ORGANIZATIONCARDSERVICEDELETE,
  ORGANIZATIONCARDSERVICEINPROGRESS, 
  ORGANIZATIONCARDSERVICERESETACTION, 
  ORGANIZATIONCARDSERVICEONDELETE, 
  ORGANIZATIONCARDSERVICEGETPICKERALL,
  ORGANIZATIONCARDSERVICEPICKERPICKED,
  ORGANIZATIONCARDSERVICEPICKERCLOSE ,
  ORGANIZATIONCARDSERVICEADD         ,
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR
} from 'redux/actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { getPageData } from 'app/components/ui/pagination/pagination'
import { stringPlural } from 'app/core/utility/common'
import { excludeByItemId } from 'app/core/utility/common'

import config from 'app/core/config'
const { paginationPerPage } = config

const defaultState = {
  items: [],
  pickerObject: {
    show: false,
  },
  pageItems: [],
  pagination: { activePage: 1, itemsPerPage: paginationPerPage },
  onDeleteObject: null,
  confirmObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { 
      items,
      pageItems,
      pagination,
      pickerObject,
      allItems: stateAllItems      
  } = state
  const { pickerObject: defaultPickerState } = defaultState
  const { activePage: stateActivePage, itemsPerPage: statePerPage } = pagination

  try {
    switch (action.type) {
      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case ORGANIZATIONCARDSERVICEGETALL:
        const { isAllServiceItems, data } = payload

        return {
          ...state,
          items: data,
          pagination: { activePage: 1, itemsPerPage: statePerPage },
          pageItems: getPageData({ items: data, activePage: 1, itemsPerPage: statePerPage }),
          allItems: isAllServiceItems ? data : stateAllItems,
          inProgress: false
        }

      case ORGANIZATIONCARDSERVICEGETPICKERALL:
        return {
          ...state,
          pickerObject: {
            show: true
          },
        }

      case ORGANIZATIONCARDSERVICEADD: 
        return {
          ...state,
          pickerObject: {
            ...defaultPickerState,
          },
          items: payload,
          allItems: [...stateAllItems, ...payload],
          inProgress: false
        }

      case ORGANIZATIONCARDSERVICEPICKERPICKED:
        return {
          ...state,
          pickerObject: {
            ...pickerObject,
            checkedItems: payload,
          },
          inProgress: false
        }

      case ORGANIZATIONCARDSERVICEPICKERCLOSE:
        return {
          ...state,
          pickerObject: {
            show: false
          }
        }
        
      case ORGANIZATIONCARDSERVICEONDELETE:
        const name = payload.length ? payload.map(ua => ua.name).join(', ') : payload.name
        
        return {
          ...state,
          onDeleteObject: {
            item: payload,
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить ${stringPlural(payload.length || 1, ['организацию', 'организации', 'организации'] )} «${name}» из списка обслуживающих организаций?`
          }
        }

      case ORGANIZATIONCARDSERVICEDELETE:
        const index = items.findIndex(item => item.id === payload)

        return {
          ...state,
          items: [
            ...items.slice(0, index),
            ...items.slice(index + 1),
          ],
          allItems: excludeByItemId(stateAllItems, [ {id:payload }]),
          onDeleteObject: null,
          inProgress: false,
        }

        case FIELDSERROR:
      case ERROR:
        return {
          ...defaultState,
          items,
          pageItems,
          pagination  
        }

      case ORGANIZATIONCARDSERVICEINPROGRESS:
        const { inProgress } = payload

        return {...state, inProgress}

      case ORGANIZATIONCARDSERVICERESETACTION: 
        return {
          ...defaultState,
          items,
          pageItems,
          pagination  
        }

      default: 
        return state
    }
    // in case of no response
    // to do - think about proper error handling
  } catch (err) {
    return state
  }
}