import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import AutocompleteFilter, { Services }  from 'app/components/ui/autocomplete/autocomplete';
import {parseOrganizationControlValue} from "app/components/MassImports/adapters";

export const Scope = {
    All: 'all',
    Okz: 'okz',
    Oki: 'oki',
};

const OrganizationImportControl = ({ scope = Scope.All, onChangeHandler, error }) => {
    const { userOrganization } = useSelector(state => ({
        userOrganization : state.profile && state.profile.userInfo && state.profile.userInfo.organization
    }));
    const [value, setValue] = useState(parseOrganizationControlValue(userOrganization));

    const onSelectHandler = (value) => {
        setValue(parseOrganizationControlValue(value));
    };
    
    useEffect(() => {
        onChangeHandler(value);
    }, [value]);
    
    const services = {
        serviceType: scope === Scope.All ? Services['organizations'] : null,
        notStandardService: scope === Scope.All ? null : {
            serviceName: 'organizationService',
            serviceMethod: scope === Scope.Oki ? 'autocompleteOki' : 'autocompleteOkz',
            data: {},
        }
    }

    return <AutocompleteFilter
        onEmptySearch
        className={'organizationId filter-item'}
        label={'Организация'}
        serviceType={services.serviceType}        
        notStandardService={services.notStandardService}
        value={value}
        error={error}
        onSelect = {onSelectHandler}
        renderInputValue = {( data ) => { return data && data.valueFormattedString}}
    />;
};

export default OrganizationImportControl;