import { useState } from 'react';
import ConfirmDialog from 'app/components/dialog/confirmDialog/';
import withTooltip from 'app/components/HOC/TooltipHOC';
import Button from 'app/components/ui/button/button'
import { excludeByItemId } from 'app/core/utility/common';
import Img from '../Img';

const ButtonWithTooltip = withTooltip(Button);

export const Delete = ({
  img,
  items,
  tooltip,
  dialog,
  deleteItemsAction,
  setSelectedAction = () => {},
  afterAction,
  customConfirm = false,
  parentDisabled = false,
  useCanBeRemoved,
  onInProgress,
  buttonText,
}) => {  
  const [confirm, setConfirm] = useState({});

  const _onClick = () => {
    const message = dialog(items);
    const confirmObject = {
      title: message,
      type: 'confirm',
    };
    setConfirm(confirmObject);
  };
  
  const removeConfirm = () => {
    const noConfirmObject = { title: '', type: '' };
    setConfirm(noConfirmObject);
  };

  const invokeCustomConfirm = () => {
    removeConfirm();
    customConfirm && customConfirm();
  };

  const invokeConfirm = async () => {
    const ids = items.map(el => el.id || el);
    removeConfirm();
    onInProgress && onInProgress(true);
    const deleteItemsResponse = await deleteItemsAction(ids);
    const { data: deletedArray } = { data: [], ...deleteItemsResponse };
    const remainingSelectedItemIds = excludeByItemId(
      items.map(i => (i.id ? i : { id: i })),
      deletedArray.map(i => ({ id: i }))
    ).map(i => i.id);
    setSelectedAction(remainingSelectedItemIds);
    removeConfirm();
    if (deleteItemsResponse.isError === true) {
      const { errors } = deleteItemsResponse;

      const message = errors.map(el => {
        if (!el.errorMessage && el.detail && el.detail.response) {
          return el.detail.response.errors[0].errorMessage;
        }
        
        return el.errorMessage || '';
      });

      const confirmObject = { title: message, type: 'warning' };
      setConfirm(confirmObject);
    }
    
    onInProgress && onInProgress(false);
    deletedArray.length && afterAction && afterAction(deletedArray);
    return deletedArray;
  };
  
  const hasBizLockedItems = () => items.some(i => i && i.bizLocked);
  const hasNonRemovableItems = () => useCanBeRemoved && items.some(i => i && !i.canBeRemoved);
  const isDisabled = parentDisabled || !items.length || hasBizLockedItems() || hasNonRemovableItems();

  const onSubmit = customConfirm ? invokeCustomConfirm : invokeConfirm;
  
  return (
    <>
      {(confirm.type === 'confirm' || confirm.type === 'warning') && (
        <ConfirmDialog
          {...confirm}
          onSubmit={onSubmit}
          onCancel={removeConfirm}
        />
      )}
      <ButtonWithTooltip 
        type='image'   
        onClick={_onClick}
        tooltip={!buttonText ? tooltip || 'Удалить' : ''}
        disabled={isDisabled}
        caption={buttonText}
      >
        <Img type = { img || 'DeleteImg' }  />
      </ButtonWithTooltip>
    </>
  );
};

export default Delete;

export { default as DeleteButton } from './DeleteButton';
export { default as DeleteModal } from './DeleteModal';
export { default as useDelete } from './useDelete';