import { 
  FILESUPLOADSTART,
  FILESUPLOADPROGRESS,
  FILESUPLOADFILERESULT,
  FILESUPLOADONDIALOGTOGGLE
} from '../actions/actionTypes'

const defaultState = {
  files: [],
  progress: {},
  showFileProgressDialog: false,
  // uploadResult: {}  
}

export default (state = defaultState, action) => {
  const { payload }  = action
  const { axiosResult, fileKey } = payload || {}
  const { data, errors } = axiosResult || {}
  const { progress, files } = state

  switch (action.type) {

    case FILESUPLOADSTART:
      return {
        ...state,
        files: [
          ...files,
          ...payload
        ]
      }

    case FILESUPLOADONDIALOGTOGGLE:
      const allFilesUploaded = files.every(file => file.result)

      if (allFilesUploaded && !payload) {
        return {
          ...state,
          files: [],
          showFileProgressDialog: payload
        }
      }

      return {
        ...state,
        showFileProgressDialog: payload
      }
      
    case FILESUPLOADPROGRESS:
      return {
        ...state,
        progress: {
          ...progress,
          ...payload
        }
      }

    case FILESUPLOADFILERESULT:

      const fileIndex = files.findIndex(file => file.fileKey === fileKey)
      if (fileIndex >= 0) {
        const updatedFiles = [
          ...files.slice(0, fileIndex),
          {...files[fileIndex], result: axiosResult},
          ...files.slice(fileIndex+1)
        ]

        return {
          ...state,
          files: updatedFiles
        }
      }

      return state
      
    default: 
      return state
  }
}