import { formatDate } from "app/core/utility/date"
import service from "app/services/service"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { dispatchLogic } from "redux/actions/common"

export function useBusinessEventTrigger({onCancel, endpointType, entityType}){
  const [ inProgress, setProgress ] = useState(false)
  const dispatch = useDispatch()

  const getSignRequestData = formData => {
    const { 
      isExternal,
      journalObjects, 
      item, 
      eventTemplateId, 
      hardwareNumber = '', 
      receivedFrom = '', 
      eskziUserId: eskziUser, 
      organizationId: organization, 
      transmittalLetterDate = null, 
      transmittalLetterNumber = '', 
      performerId: performer,
      hardwareId: hardware,
      printNumber = '',
      distributiveRemovingDate,
      useDestructionCertificate,
      signers = [],
    } = formData

    const { id: eskziId } = item || {}
    const { id: eskziUserId = null } = eskziUser || {}
    const { id: organizationId = null } = organization || {}
    const { id: hardwareId = null} = hardware || {}
    const { id: performerId } = performer || {}
    const signersIds = signers ? signers.map(item => item.id) : []
    return {
      type: endpointType,
      journalObjects: journalObjects ? journalObjects.map(i => i.id) : null,
      entityType,
      eskziUserId,
      organizationId,
      hardwareId,
      hardwareNumber,
      receivedFrom,
      performerId,
      transmittalLetterDate: formatDate(transmittalLetterDate, 'yyyy-mm-dd'),
      distributiveRemovingDate: formatDate(distributiveRemovingDate, 'yyyy-mm-dd'),
      transmittalLetterNumber,
      eventTemplateId,
      eskziId,
      printNumber,
      isExternal,
      useDestructionCertificate,
      signers: signersIds
    }
  }

  const afterSignCallback = async (signedData) => {
    const { data } = signedData
    const { isExternal } = data || {}
    const method = isExternal
                    ? 'runEventListExternalOrganization'
                    : 'runEvent'

    setProgress(true)
    const response = await service(`${entityType}Service`, method, signedData)
      
    const {isError, isWarn, errors, data: respData} = response || {}

    if (!isError && !isWarn) {
      onCancel()
    } else {
      dispatchLogic(dispatch, 'MOCK', respData, isError, errors)
    }

    setProgress(false)
    return response
  }

  return {
    afterSignCallback,
    getSignRequestData,
    inProgress
  }
}