import React from 'react'
// ActionButton
import { ReactComponent as AcceptationImg }  from 'assets/img/actionPanel/ic_acceptation.svg';
import { ReactComponent as OfreturnImg }  from 'assets/img/actionPanel/ic_acceptation-ofreturn.svg';
import { ReactComponent as ArReturnImg }  from 'assets/img/actionPanel/ic_ar_return.svg';
import { ReactComponent as ComplectationImg }  from 'assets/img/actionPanel/ic_complectation.svg';
import { ReactComponent as DownloadCircleImg }  from 'assets/img/actionPanel/ic_download_circle.svg';
import { ReactComponent as ExterminationImg }  from 'assets/img/actionPanel/ic_extermination.svg';
import { ReactComponent as JournalImg }  from 'assets/img/actionPanel/ic_journal.svg';
import { ReactComponent as SoftReturnImg }  from 'assets/img/actionPanel/ic_soft_return.svg';
import { ReactComponent as SoftSendingImg }  from 'assets/img/actionPanel/ic_soft_sending.svg';
import { ReactComponent as EtalonSqReplicationImg }  from 'assets/img/actionPanel/ic_etalon_sq_replication.svg';
import { ReactComponent as ArSendingImg }  from 'assets/img/actionPanel/ic_ar_sending.svg';
import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg';
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg';
import { ReactComponent as DownloadToXlsImg } from 'assets/img/commonVer2/excel.svg';
import { ReactComponent as SearchImg } from 'assets/img/commonVer2/search.svg';
import { ReactComponent as ApparatSKZI } from 'assets/img/actionPanel/ic_apparat_SKZI.svg';
import { ReactComponent as CloseImg } from 'assets/img/actionPanel/ic_close.svg'
import { ReactComponent as NewWindowImg }  from 'assets/img/commonVer2/new_window.svg'
import { ReactComponent as LinkImg } from 'assets/img/actionPanel/ic_link.svg'
import { ReactComponent as UploadCircle } from 'assets/img/actionPanel/ic_upload_circle.svg'
import { ReactComponent as EditImg } from 'assets/img/commonVer2/edit_pen.svg'
import { ReactComponent as BreakChainImg } from 'assets/img/commonVer2/ic_link_off.svg'

// Titul
import { ReactComponent as UserAccountsImg }  from 'assets/img/menu/ic_userslist.svg';
import { ReactComponent as AdmittanceImg }  from 'assets/img/menu/ic_admittance.svg';
import { ReactComponent as OkzReport }  from 'assets/img/menu/ic_catalog.svg';
import { ReactComponent as License }  from 'assets/img/menu/ic_key.svg';
import { ReactComponent as ConclusionImg }  from 'assets/img/menu/ic_conclusion.svg';
import { ReactComponent as KeyCarrierImg }  from 'assets/img/menu/ic_key_carrier.svg';
import { ReactComponent as KeyDocumentImg }  from 'assets/img/menu/ic_keydocument.svg';
import {ReactComponent as OKZimg }  from 'assets/img/menu/ic_directory.svg';
import { ReactComponent as DeliveryImg }  from 'assets/img/commonVer2/ic_confirm_delivery.svg';

const Img = ({ type, className }) => {
  if ( type === 'ExterminationImg' ) return <ExterminationImg className = { className } />
  else if ( type === 'DownloadCircleImg' ) return <DownloadCircleImg className = { className } />
  else if ( type === 'SearchImg' ) return <SearchImg className = { className } />
  else if ( type === 'ComplectationImg' ) return <ComplectationImg className = { className } />
  else if ( type === 'ArReturnImg' ) return <ArReturnImg className = { className } />
  else if ( type === 'OfreturnImg' ) return <OfreturnImg className = { className } />
  else if ( type === 'AcceptationImg' ) return <AcceptationImg className = { className } />
  else if ( type === 'PlusImg' ) return <PlusImg className = { className } />
  else if ( type === 'EditImg' ) return <EditImg className = { className } />
  else if ( type === 'DeleteImg' ) return <DeleteImg className = { className } />
  else if ( type === 'ArSendingImg' ) return <ArSendingImg className = { className } />
  else if ( type === 'EtalonSqReplicationImg' ) return <EtalonSqReplicationImg className = { className } />
  else if ( type === 'SoftSendingImg' ) return <SoftSendingImg className = { className } />
  else if ( type === 'SoftReturnImg' ) return <SoftReturnImg className = { className } />
  else if ( type === 'JournalImg' ) return <JournalImg className = { className } />
  else if ( type === 'DownloadToXlsImg' ) return <DownloadToXlsImg className = { className } />
  else if ( type === 'ApparatSKZI' ) return <ApparatSKZI className = { className } />
  else if ( type === 'CloseImg' ) return <CloseImg className = { className } />
  else if ( type === 'LinkImg' ) return <LinkImg className = { className } />
  else if ( type === 'UploadCircle' ) return <UploadCircle className = { className } />
  else if ( type === 'NewWindowImg' ) return <NewWindowImg className = { className } />
  // Title
  else if( type === 'AdmittanceImg' ) return <AdmittanceImg className = { className } />
  else if( type === 'OkzReport' ) return <OkzReport className = { className } />
  else if( type === 'License' ) return <License className = { className } />
  else if( type === 'ConclusionImg' ) return <ConclusionImg className = { className } />
  else if( type === 'KeyCarrierImg' ) return <KeyCarrierImg className = { className } />
  else if( type === 'userAccount') return <UserAccountsImg className = { className } />
  else if( type === 'organizationImg') return <OKZimg className = { className } />
  else if( type === 'KeyDocumentImg') return <KeyDocumentImg className='header-image' />
  else if ( type === 'BreakChainImg' ) return <BreakChainImg className = { className } />
  else if ( type === 'DeliveryImg' ) return <DeliveryImg className = { className } />
  else { return null; }

}

export default Img;