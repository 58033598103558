import { getResourceRights } from 'app/core/auth/auth';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { RESOURCES } from 'app/core/auth/resourceByPage.js';

const StartPage = ({ profile }) => {
  const history = useHistory();
  const externalOrgRights = getResourceRights(profile, RESOURCES.externalOrgEskzi)
  const dashboardRights = getResourceRights(profile, RESOURCES.dashboard)
  const okzEskziRights = getResourceRights(profile, RESOURCES.okzEskzi)

  if( dashboardRights.VIEW_CARD ){
    history.push( `/dashboard` );
  } else if (externalOrgRights.VIEW_LIST) {
    history.push( `/extorgeskzi` ); 
  } else if (okzEskziRights.VIEW_LIST) {
    history.push( `/skziAccounting` ); 
  } else {
    history.push( `/skziOrganization` ); 
  }

  return null;
}

const mapStateToProps = state => {
	return {
    profile: state.profile
  };
}

const mapDispatchToProps = {}

export default connect( mapStateToProps, mapDispatchToProps )( StartPage );