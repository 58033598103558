import { combineReducers } from 'redux'
import useReducerWithThunk from 'redux/store/useReducerWithThunk';
import listFilterState from 'redux/reducers/list/listFilterStateReducer'
import listFilterForRequest from 'redux/reducers/list/listFilterForRequestReducer'
import listFieldsVisible from 'redux/reducers/list/listFieldsVisibleReducer';
import { useMemo, useState } from 'react';

export default (props) => {

  const {defaultFilterState, defaultFilterForRequest} = props || {}

  const [store, dispatch] = useReducerWithThunk(
    combineReducers({ 
      filterState: listFilterState,
      filterForRequest: listFilterForRequest,
      fieldsVisible : listFieldsVisible,
    }), 
    {
      filterState : defaultFilterState || [],
      filterForRequest: defaultFilterForRequest || {},
      fieldsVisible: []
    }
  ) 

  const {
    filterState,
    filterForRequest,
    fieldsVisible
  } = store

  const [ selectedVolume, setVolume ] = useState()

  const filterFilled = useMemo(() => filterState.length, [filterState])

  const dropFiltersAction = () => {
    dispatch({ type: 'LIST_FILTER_DROP' });
  }
  
  const setFilterStateAction = data => {
    dispatch({ type: 'LIST_FILTER_STATE', payload: data  });
  }
  
  const setFullFilterStateAction = data => {
    dispatch({ type: 'LIST_FULL_FILTER_STATE', payload: data  })
  }
  
  const setFilterForRequestAction = data => {
    return dispatch({ type: 'LIST_FILTER_REQUEST', payload: data  });
  }
  
  const setVisibleFieldsAction = arr => {
    dispatch({ type : 'LIST_SET_VISIBLE_FIELDS', payload : arr  });
  }
  
  const toggleVisibleFieldAction = alias => {
    dispatch({ type : 'LIST_TOGGLE_VISIBLE_FIELD', payload : alias  });
  }

  // нужно для выставления всего состояния фильтра - 
  // для контрола (filterState) и для апи (filterForRequest)
  // в остальных случаях эти состояния выставляются независимо
  const setCompleteFilterState = data => {
    setFullFilterStateAction(data)
    setFilterForRequestAction(mapToValueForRequest(data))
  }

  const restoreFilterFromLocalStorage = (state) => {
    const { filterState, filterForRequest, volume } = state
    setFullFilterStateAction(filterState)
    setFilterForRequestAction(filterForRequest)
    setVolume(volume)
  }

  return {
    setCompleteFilterState,
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    dropFiltersAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    restoreFilterFromLocalStorage,
    setVolume,
    selectedVolume,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled
  }
}

export const getUrlFilterState = (filter) => {
  if (!filter) {
    return null;
  }

  const data = JSON.parse(filter);

  return Object.keys(data).reduce((acc, cur) => {
    const filterField = {
      alias: cur,
      valueForRequest: data[cur].id,
      valueFormattedString: data[cur].name,
    };

    return [filterField, ...acc];
  }, []);
};

export const mapToValueForRequest = state => {
  return state.reduce((acc, el) => {
    acc[el.alias] = el.requestFormatIsArray 
                    ? [el.valueForRequest]
                    : el.valueForRequest;
    return acc;
  }, {});
} 