/** @format */

import { useState } from 'react';

// ВНИМАНИЕ! Непродуманное добавление зависимостей может привести к зацикливанию

const useDependencies = () => {
  const [dependencies, setDependencies] = useState({});
  const [handlers, setHandlers] = useState({});

  const addDependencyHandler = (alias, handler) => {
    const newHandlers = [...(handlers[alias] ?? []), handler];
    setHandlers({ ...handlers, [alias]: newHandlers });
  };

  const removeDependencyHandler = (alias, handler) => {
    const newHandlers = handlers[alias].filter(h => h !== handler);
    setHandlers({ ...handlers, [alias]: newHandlers });
  };

  const setDependencyValue = (alias, value, cb) => {
    setDependencies({ ...dependencies, [alias]: value });
    cb?.(value);
  };

  return { dependencies, addDependencyHandler, removeDependencyHandler, setDependencyValue };
};

export default useDependencies;
