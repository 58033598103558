import React, { useState } from 'react';

import { DownloadButton } from 'app/components/list/TopPanel/DownloadButton';
import DownloadButtonGet from 'app/components/ui/button/downloadXlsButton'
import ButtonComponent, { buttonTypes } from '../ui/button/button';
import withTooltip from '../HOC/TooltipHOC';
import { Delete } from '../list/TopPanel/Delete';
import { stringPlural } from 'app/core/utility/common';
import { useListActionPanel } from './useListActionPanel';
import {getResourceRights} from "../../core/auth/auth";
import {useDispatch, useSelector} from "react-redux";
import {ActionCreator} from "../../../redux/actions/uploadProcessing/uploadProcessing";
import {Type} from "../MassImports/enums";
import { Group } from "app/components/ui/managePanel";
import ImgMap from 'app/components/ui/Img/imgMap';
import CloseUA from './CloseUA/CloseUA';

const Button = withTooltip(ButtonComponent)
const WithTooltipButton = withTooltip(ButtonComponent);

export const ListActionPanel = ({
  rights,
  onCreate,
  inProgress,
  onInProgress,
  selectedItems,
  onRemoveValues,
  deleteItems,
  setSelectedAction,
  filterForRequest,
  sorting,
  fieldsVisible,
  onItemChanged,
  currentUA,
  selectedUA,
  globalOrgId
}) => {
  const profile = useSelector(({profile}) => profile);
  const dispatch = useDispatch();
  const isUserHaveImportOrganizationRights = getResourceRights(profile, 'MassImport/EskziUser')['VIEW_LIST'];
  const [showCloseUADialog, setShowCloseUADialog] = useState(false);
  const {
    approveUA,
    reverseApprovingUA,
  } = useListActionPanel({
    onItemChanged,
    onInProgress,
    currentUA,
    selectedUA
  });

  const onClickUploadFileHandler = () => {
    dispatch(ActionCreator.setUploadModalFileTypeState(Type.USER_ACCOUNT));
    dispatch(ActionCreator.setUploadModalState(true));
  };

  return (
    <>
      {rights.CREATE ? (
        <Group>
          <Button
            type={buttonTypes.primary}
            disabled={inProgress}
            onClick={ onCreate }
          >
            <ImgMap.Plus className='button-image button-image--left'></ImgMap.Plus>
            <span className='button-title'>Создать</span>
          </Button>
        </Group>
      ) : null}
      {isUserHaveImportOrganizationRights ? (
        <Group>
          <WithTooltipButton
              type={buttonTypes.image}
              disabled={!isUserHaveImportOrganizationRights}
              onClick={onClickUploadFileHandler}
              tooltip="Загрузка из файла"
          >
            <ImgMap.FileUpload />
          </WithTooltipButton>
        </Group>
      ) : null}
      <Group>
        <DownloadButtonGet
          tooltip={'Скачать отчет по лицевому счету пользователя'}
          items={selectedItems}
          url={'eskziuser/reports/download'}
          Img={ImgMap.AccountDownload}
        />
      </Group>
      <Group>
        <Button
          type={buttonTypes.image}
          onClick={() => setShowCloseUADialog(true)}
          disabled={!(rights.UPDATE && currentUA && currentUA.canBeClosed)}
          tooltip='Закрыть лицевой счет'
        >
          <ImgMap.Close />
        </Button>
        <Button
          type={buttonTypes.image}
          onClick={approveUA}
          disabled={!(rights.UPDATE && currentUA && currentUA.canBeApproved)}
          tooltip='Подтвердить'
        >
          <ImgMap.Acceptation />
        </Button>
        <Button
          type={buttonTypes.image}
          onClick={reverseApprovingUA}
          disabled={!(rights.UPDATE && currentUA && currentUA.canBeReversed)}
          tooltip='Отменить'
        >
          <ImgMap.AcceptationOfReturn />
        </Button>
      </Group>
      <Group>
        <DownloadButton
          listFilterForRequest = {{...filterForRequest, organizationId: globalOrgId}} 
          sorting = {sorting}
          listFieldsVisibility ={fieldsVisible}
          selected = {selectedItems}
          titul="Выгрузить в Excel"
          type="DownloadButton"
          inProgress = { inProgress }
          serviceName = 'userAccountService'
        />
      </Group>
      <Group>
        <Delete
          items = {selectedItems} 
          dialog ={data => {
            const arr = ['выбранный лицевой счет', 'выбранные лицевые счета', 'выбранные лицевые счета']
            return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`
          }}
          afterAction = { onRemoveValues }
          deleteItemsAction = {deleteItems}
          setSelectedAction = {setSelectedAction}
          onInProgress = { onInProgress }
        />
      </Group>
      {showCloseUADialog && (
        <CloseUA
          currentUAId={currentUA.id}
          onInProgress={onInProgress}
          onItemChanged={onItemChanged}
          onDone={() => setShowCloseUADialog(false)}
          onCancel={() => setShowCloseUADialog(false)}
        />
      )}
    </>
  )
}

