/** @format */
import { formatDate } from 'app/core/utility/date';
import { formatFIO } from 'app/core/utility/common';

export const columns = [
  {
    title: 'Наименование',
    alias: 'eskzi',
    useSorting: true,
    format: item => {
      const { skzi: skziData } = item;
      const { version, skzi } = skziData || {};
      const { name: skziName } = skzi || {};
      const { name: versionName } = version || {};

      return `${skziName} ${versionName}`;
    },
  },
  {
    title: 'Регистрационный номер',
    alias: 'serialNumber',
    useSorting: true,
    width: 240,
  },
  {
    title: 'Организация',
    alias: 'okz',
    useSorting: true,
    format: item => {
      const { okz, oki } = item;
      const { name, shortName } = oki || okz || {};

      return shortName || name;
    },
  },
  {
    title: 'Статус',
    alias: 'status',
    format: item => {
      const { status } = item;
      const { name } = status || {};

      return name || '';
    },
    useSorting: true,
    width: 240,
  },
  {
    title: 'Выдано',
    alias: 'eskziUser',
    format: item => {
      const { eskziUser } = item;

      return formatFIO(eskziUser);
    },
    useSorting: true,
  },
  {
    title: 'Дата изменения',
    alias: 'updateDate',
    format: item => {
      const { createDate } = item;

      return formatDate(createDate, 'dd.mm.yyyy');
    },
    useSorting: true,
    width: 240,
  },
];
