export const THEME = {
  default: {
    name: 'default',
    colorBrand:  '53,97,236',
    colorOnBrand: '255,255,255',
    colorPrimary: '0, 0, 0',
    colorOnPrimary: '255,255,255',
    colorSecondary: ' 255, 255, 255',
    colorOnSecondary: '0,0,0',
    colorShadow: '53,97,236',
    colorBrandDashboard: '53,97,236'
  },
  dark: {
    name: 'dark',
    colorBrand: '255,255,0',
    colorOnBrand: '0,128,0',
    colorPrimary: '255,255,255',
    colorOnPrimary: '0,0,0',
    colorSecondary: '0,0,0',
    colorOnSecondary: '255,255,255',
    colorShadow: '255,255,255',
    colorBrandDashboard: '53,97,236'
  },
  toxic: {
    name: 'toxic',
    colorBrand: '255,0,0',
    colorOnBrand: '0,0,255',
    colorPrimary: '128,0,128',
    colorOnPrimary: '255,255,255',
    colorSecondary: '0,255,255',
    colorOnSecondary: '0,0,0',
    colorShadow: '53,97,236',
    colorBrandDashboard: '53,97,236'
  },
  darkToxic: {
    name: 'darkToxic',
    colorBrand: '68,173,255',// '#272C54',
    colorOnBrand: '255,255,255',
    colorPrimary: '46,255,10',
    colorOnPrimary: '255,255,255',
    colorSecondary: '111,54,58',//'76,82,109',
    colorOnSecondary: '0,0,0',
    colorShadow: '255,255,255',
    colorBrandDashboard: '53,97,236'
  },
  custom: {
    name: 'custom',
    colorBrand: '126,62,133',
    colorOnBrand: '255,255,255',
    colorPrimary: '0,0,0',
    colorOnPrimary: '255,255,255',
    colorSecondary: '255,255,255',
    colorOnSecondary: '0,0,0',
    colorShadow: '53,97,236',
    colorBrandDashboard: '53,97,236'
  }
}
