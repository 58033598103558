/** @format */

import { getClassName } from 'app/core/utility/jsx';
import React from 'react'
import styles from './paper.module.scss';

export default function Paper({className, children}) {
  return (
    <div className={getClassName([className || '', styles.paper])}>{children}</div>
  )
}
