import React from 'react';
import withTooltip from 'app/components/HOC/TooltipHOC';
import {acceptFileExtension, ONE_HUNDRED_MB_IN_BYTES} from "app/components/MassImports/consts";
import {checkFilesExtension, getFilesExtensions, getFilesSizes} from "app/core/utility/file";
import {Icon, Type, FileValidateState} from "app/components/MassImports/enums";

export const getIconByState = (el) => {
    const IconComponent = Icon[el.status].svg;
    const WithTooltip = withTooltip(IconComponent);

    return <WithTooltip className={Icon[el.status].classes} tooltip={Icon[el.status].tooltip}>
        <IconComponent />
    </WithTooltip>;
};
export const getEnumTypeByString = (type) => Object.keys(Type).find(el => Type[el] === type);

export const validateFile = file => [
        {
            isError: () => !checkFilesExtension(acceptFileExtension, getFilesExtensions([file])),
            error: FileValidateState.MISS_EXTENSION,
        },
        {
            isError: () => getFilesSizes([file]).some(item => item > ONE_HUNDRED_MB_IN_BYTES),
            error: FileValidateState.OVER_SIZE,
        },
    ]
    .filter(item => item.isError())
    .map(item => item.error);

export const validateFiles = (files) =>
    [
        {
            isError: () => getFilesSizes(files).some(item => item > ONE_HUNDRED_MB_IN_BYTES),
            error: FileValidateState.OVER_SIZE,
        },
        {
            isError: () => !checkFilesExtension(acceptFileExtension, getFilesExtensions(files)),
            error: FileValidateState.MISS_EXTENSION,
        },
    ]
    .filter(item => item.isError())
    .map(item => item.error);
