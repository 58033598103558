import { 
  PASSWORDPOLICYGET,
  PASSWORDPOLICYUPDATE,
  PASSWORDPOLICYRESET,
  PASSWORDPOLICYINPROGRESS,
  PASSWORDPOLICYUPDATEFORMDATA,
  PASSWORDPOLICYRESETFORMDATA
} from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const getPasswordPolicyAction = () => dispatch => {
  service('passwordPolicyService', 'getPolicy')
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, PASSWORDPOLICYGET, data, isError, errors)
    })
}

export const updatePolicyAction = (data) => dispatch => {
  service('passwordPolicyService', 'updatePolicy', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, PASSWORDPOLICYUPDATE, data, isError, errors)
    })
}

export const passwordPolicyResetAction = () => dispatch => {
  dispatch({
    type: PASSWORDPOLICYRESET,
    payload: null
  })
}

export const passwordPolicyProgressAction = (inProgress) => dispatch => {
  dispatch({
    type: PASSWORDPOLICYINPROGRESS,
    payload: inProgress
  })
}

export const passwordPolicyUpdateFormAction = ({name, value}) => dispatch => {
  dispatch({
    type: PASSWORDPOLICYUPDATEFORMDATA,
    payload: {name, value}
  })
}

export const passwordPolicyResetFormAction = () => dispatch => {
  dispatch({
    type: PASSWORDPOLICYRESETFORMDATA,
    payload: null
  })
}