import { useMemo } from 'react'
import ExemplarReplicationResult from 'app/components/skzi/Eskzi/replication/exemplar/exemplarReplicationResult'
import EtalonReplicationResult from 'app/components/skzi/Eskzi/replication/etalon/etalonReplictionResult'
import { statuses } from '../notificationItem'
import withSignatureHOC from 'app/components/HOC/withSignatureHOC'
import Overlay from 'app/components/ui/overlay'
import Alert from 'app/components/ui/Alert';
import Severity from 'app/core/types/severity';
import { useReplicationResult } from './useReplicationResult'
import MassOperationResult from './bulkOperationDetail'
import withModal from 'app/components/HOC/ObsoleteModalHOC'
import { compose } from 'redux'

import styles from './replicationResult.module.scss';

export const ReplicationErrorInfo = ({messages, status}) => {  
  const {id: statusId} = status || {}
  return <Alert severity={Severity.Error} showIcon content={<div className={styles.errorSummary}>    
    {statusId === statuses.doneWithErrors && (<>
      {messages.length === 1
        ? <>{messages[0]} </>
        : <><ul className={`error-list ${styles.errorList} ${styles.primary}`}>
              {messages.map(message => <li className={`error-list-item ${styles.errorListItem}`}>{message}</li>)}
            </ul>
            <br />
          </>
      }
      Пожалуйста, подтвердите итоги операции.
    </>)}
    {statusId === statuses.failed && (<>
      Операция не может быть выполнена:
      {messages.length === 1
        ? (messages[0])
        : <ul className={`error-list ${styles.errorList} ${styles.secondary}`}>
            {messages.map(message => <li className={`error-list-item ${styles.errorListItem}`}>{message}</li>)}
          </ul>}      
    </>)}
  </div>} /> 
}

export const ErrorInfo = ({messages, status}) => {  
  const { id: statusId } = status || {}
  return <Alert severity={Severity.Error} showIcon content={<div className={styles.errorSummary}>    
    {statusId === statuses.doneWithErrors && (<>
      Операция не может быть выполнена полностью по причине:
    </>)}
    {statusId === statuses.failed && (<>
      Операция не может быть выполнена по причине:
    </>)}
    <ul className={`error-list ${styles.errorList}`}>
      {messages.map(message => <li className={`error-list-item ${styles.errorListItem}`}>{message}</li>)}
    </ul>    
    {statusId === statuses.doneWithErrors && (<>
      Пожалуйста, подтвердите итоги операции.
    </>)}
  </div>} /> 
}

const ExemplarReplication = withSignatureHOC(ExemplarReplicationResult)
const EtalonReplication = withSignatureHOC(EtalonReplicationResult)
const OperationResult = compose(withModal, withSignatureHOC)(MassOperationResult)

// не только распространение но и остальные операции тут
export default function ReplicationResult(props) {
  const { data, onCancel } = props
  const { uid, code, status = {}, entityDiscriminator } = data

  const {
    getExemplarSignRequestData,
    getEtalonSignRequestData,
    resignExemplarCallback,
    resignEtalonCallback,
    resignActionCallback,
    getActionSignRequestData,
    inProgress,
    eventData
  } = useReplicationResult({
    onCancel,
    id: uid,
    eventType: code,
    entityDiscriminator
  })
  
  const errorData = useMemo(() => {    
    const isReplication = ['ReplicateToOki', 'ReplicateToOkz'].includes(code);
    if (isReplication && status.id === statuses.doneWithErrors) {
      const getLicensesCount = ri => ri.licenses.length;
      const getSuccessfulLicensesCount = ri => ri.licenses.filter(lic => lic.isSuccessful).length;
      const hasOrganizationError = ri => ri.copiesNumberFact === 0 && getSuccessfulLicensesCount(ri) === 0;
      const hasEskzisError = ri => !hasOrganizationError(ri) && (ri.copiesNumber > ri.copiesNumberFact);
      const hasLicensesError = ri => !hasOrganizationError(ri) && (getLicensesCount(ri) > getSuccessfulLicensesCount(ri));
      const hasOrganizationErrors = eventData?.replicationItems.some(hasOrganizationError) ?? false;
      const hasEskzisErrors = eventData?.replicationItems.some(hasEskzisError) ?? false;
      const hasLicensesErrors = eventData?.replicationItems.some(hasLicensesError) ?? false;
      const errorMessages = [
        ...hasOrganizationErrors ? ['Не удалось передать в указанные организации.'] : [],
        ...hasEskzisErrors ? ['Не удалось передать все экземпляры.'] : [],
        ...hasLicensesErrors ? ['Не удалось передать все лицензии.'] : [],
      ];
      
      return errorMessages;
    }
    
    const { error } = eventData || {};
    return error && error.length
      ? error.split('/n')
      : [];
  }, [eventData])
  
  const showError = Boolean(errorData 
                    && errorData.length
                    && (status.id === statuses.doneWithErrors
                        || status.id === statuses.failed))
  const { eventTemplate } = eventData || {}
  const { name } = eventTemplate || {}

  return (
    <>
      {inProgress ? <Overlay /> : null}
      <div className={`replication-result ${styles.replicationResult}`}>
        {eventData && code === 'ReplicateToOki' ? (
          <ExemplarReplication
            {...eventData}
            uid={uid}
            status={status}
            onCancel={onCancel}
            className='replicate-exemplar-component'
            actionCallback={resignExemplarCallback}
            getSignRequestData={getExemplarSignRequestData}
            // afterAction={afterAction}
            signType='Replication'
            signHeader='Подтвердить выполнение операции'
            renderHeader = {() => {
              return (
                <div className={`modal__header ${styles.modalHeader}`}>
                  {'Результат распространения экземпляров в организации'}
                  {showError && (
                    <ReplicationErrorInfo
                        messages={errorData}
                        status={status}
                      />
                  )}
                </div>
              )
            }}
          />
        ) : null}
        {eventData && code === 'ReplicateToOkz' ? (
          <EtalonReplication
            {...eventData}
            uid={uid}
            status={status}
            onCancel={onCancel}
            className='replicate-etalon-component'
            actionCallback={resignEtalonCallback}
            getSignRequestData={getEtalonSignRequestData}
            // afterAction={afterAction}
            signType='Replication'
            signHeader='Подтвердить выполнение операции'
            renderHeader = {() => {
              return (
                <div className={`modal__header ${styles.modalHeader}`}>
                  {'Результат распространения эталона'}
                  {showError && (
                    <ReplicationErrorInfo
                        messages={errorData}
                        status={status}
                      />
                  )}
                </div>
              )
            }}
          />
        ) : null}
        {eventData && code === 'BulkEvent' ? (
          <OperationResult
            eventData = {eventData}
            uid={uid}
            status={status}
            onCancel={onCancel}
            type={entityDiscriminator}
            actionCallback={resignActionCallback}
            getSignRequestData={getActionSignRequestData}
            // afterAction={afterAction}
            signType='FinishOneClickAction'
            signHeader='Подтвердить выполнение операции'
            renderHeader = {() => {
              return (
                <div className={`modal__header ${styles.modalHeader}`}>
                  {`Результат операции ${name}`}
                  {showError && (
                    <ErrorInfo
                        messages={errorData}
                        status={status}
                      />
                  )}
                </div>
              )
            }}
          />
        ) : null}
      </div>
    </>
  )
}

