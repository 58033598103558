/** @format */

import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import React from 'react';
import styles from './history.module.scss';

export default function HistoryItem({ author, recordDate, summary }) {
  return (
    <div className={styles.item}>
      <div className={styles.headerBlock}>{formatDate(recordDate, 'dd.mm.yyyy, hh:MM')}</div>
      <div className={styles.contentBlock}>
        <div className={styles.summary}>{summary}</div>
        {author && <div className={styles.performer}>{`Исполнитель: ${formatFIO(author)}`}</div>}
      </div>
    </div>
  );
}
