import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  KEYDOCUMENTCREATE,
  SIGNATUREREADYTOSIGN,
  KEYDOCUMENTCREATEUPDATEFORMDATA,
  KEYDOCUMENTCREATEINPROGRESS    ,
  KEYDOCUMENTCREATERESETACTION   ,
  KEYDOCUMENTCREATECLEARFORM 
} from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'

const defaultState = {
  formData : {
    name: '',
    keyCarrierSerialNumber: '',
    keyCarrierTypeId: null,
    expirationDate: {
      timeless: false,
      date: new Date()
    },
    serialNumber: '',
    organizationType: 'Okz',
    identificationNumber: '',
    receivedFrom: '',
    transmittalLetterNumber: '',
    transmittalLetterDate: new Date(),
    single: false
  },
  registeredInfoObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { formData } = state
    
  switch (action.type) {

    case KEYDOCUMENTCREATE:
      const { name } = payload
      
      return {
          ...state,
          registeredInfoObject: {
            type: dialogType.navigation,
            title: `Ключевой документ «${name}» успешно зарегистрирован`
          },
          inProgress: false
      }

    case SIGNATUREREADYTOSIGN:
    case FIELDSERROR:
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData,
        inProgress: false
      }

    case KEYDOCUMENTCREATEUPDATEFORMDATA: 
      const { key, value } = payload

      return {
        ...state,
        formData: {...formData, [key]: value }
      }

    case KEYDOCUMENTCREATECLEARFORM: 
      return {
        ...defaultState
      }

    case KEYDOCUMENTCREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case KEYDOCUMENTCREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        registeredInfoObject: null,
        error: null
      }

    default: 
      return state
  }
}