import { 
  BREADCRUMBSPUSHITEM,
  SKZIINSTANCEGETBUILDDATA,
  SKZIINSTANCEGETALL, 
  SKZIINSTANCEADD, 
  SKZIINSTANCEEDIT, 
  SKZIINSTANCEDELETE, 
  SKZIINSTANCERUNEVENT, 
  SKZIINSTANCECLEARERROR, 
  SKZIINSTANCEINPROGRESS, 
  SKZIINSTANCEONEDIT, 
  SKZIINSTANCEONCREATE, 
  SKZIINSTANCERESETACTION, 
  SKZIINSTANCEEDITERROR, 
  SKZIINSTANCECREATEERROR, 
  SKZIINSTANCEONDELETE, 
  SKZIINSTANCEPAGINATIONGET, 
  SKZIINSTANCEONOKZREPORT, 
  SKZIINSTANCEONOKIREPORT, 
  SKZIINSTANCEONRUNEVENT, 
  SKZIINSTANCEEVENTFORMCHANGED 
} from '../actionTypes'
import service from 'app/services/service'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/navigation/breadcrumbs'

export const skziInstanceGetAction = ({ filterData: requestBody, column, direction }, path, setCancelCallback) => dispatch => { 
  return service('skziInstanceService', 'getAll', { requestBody, column, direction, setCancelCallback })
    .then(response => {
      const { data, errors, isError } = response || {};

      if (response) {
        return dispatchLogic(dispatch, SKZIINSTANCEGETALL, data, isError, errors)
      } else {
        dispatch({
          type: SKZIINSTANCEINPROGRESS,
          payload: { inProgress: false }
        })
      }
    })
    .then(() => {
      dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: { 
          ...getPageBreadcrumb(path),
          forceUpdate: true
        }
      })
    })
}

export const skziInstancePaginationAction = ({ activePage = 1, itemsPerPage = 10 }) => dispatch => {

  dispatch(
    {
      type: SKZIINSTANCEPAGINATIONGET,
      payload: { activePage, itemsPerPage }
    }
  )
}

export const skziInstanceCreateAction = name => dispatch => {
  service('skziInstanceService', 'add', name)
    .then(response => {
      const { data, errors, isError } = response;

      isError && dispatch({
        type: SKZIINSTANCECREATEERROR,
        errors: errors,
        payload: data
      })

      !isError && dispatch({
        type: SKZIINSTANCEADD, 
        payload: data
      })
    })
}

export const skziInstanceDeleteAction = data => dispatch => {
  const { id: eskziId } = data || {}
  const ids = data.length ? data.map(item => item.id) : eskziId

  service('skziInstanceService', data.length ? 'deleteRange' : 'delete', ids)
    .then(response => {
      const { errors, isError } = response
      
      dispatchLogic(dispatch, SKZIINSTANCEDELETE, data, isError, errors)
    })
}

export const skziInstanceOnDeleteAction = data => dispatch => {
  const { serialNumber } = data 
  const name = data.length ? data.map(skzi => skzi.serialNumber).join(', ') : serialNumber

  dispatch({
    type: SKZIINSTANCEONDELETE,
    payload: { 
      confirmObject: {
        item: data,
        type: dialogType.confirm,
        title: `Вы уверены, что хотите удалить экземпляр${data.length && data.length > 1 ? 'ы' : ''} СКЗИ «${name}»?`
      }
    }
  })
}

export const skziInstanceOnOKZReport = (data) => dispatch => {
  dispatch({
    type: SKZIINSTANCEONOKZREPORT,
    payload: { 
      ...data,
    }
  })
}

export const skziInstanceOnOKIReport = (data) => dispatch => {
  dispatch({
    type: SKZIINSTANCEONOKIREPORT,
    payload: { 
      ...data,
    }
  })
}

export const skziInstanceEditAction = newtype => dispatch => {
  service('skziInstanceService', 'edit', newtype)
    .then(response => {
      const { data, errors, isError } = response;

      isError && dispatch({
        type: SKZIINSTANCEEDITERROR,
        errors: errors,
        payload: data,
        errObject: newtype
      })

      !isError && dispatch({
        type: SKZIINSTANCEEDIT, 
        payload: data
      })
    })
}

export const skziOnRunEventAction = (eskzi, action) => dispatch => {
  dispatch({
    type: SKZIINSTANCEONRUNEVENT,
    payload: {eskzi, action}
  })
}

export const skziGetSkziBuildDataAction = (eskzi) => dispatch => {
  const { skzi } = eskzi || {}
  const { build } = skzi || {}

  service('skziBuildService', 'getBuild', build.id)
  .then(response => {
    const { data, errors, isError } = response;

    dispatchLogic(dispatch, SKZIINSTANCEGETBUILDDATA, { ...skzi, build: {...data} }, isError, errors)
  })
}

export const skziRunEventAction = (data) => dispatch => {
  service('skziInstanceService', 'runEvent', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, SKZIINSTANCERUNEVENT, data, isError, errors)
    })
}

export const skziInstanceClearErrorAction = () => dispatch => {
  dispatch({
    type: SKZIINSTANCECLEARERROR,
    payload: null
  })
}

export const skziInstanceInProgressAction = (value = true) => dispatch => {
  return dispatch({
    type: SKZIINSTANCEINPROGRESS,
    payload: { inProgress: value }
  })
}

export const skziInstanceOnEditAction = item => dispatch => {
  const { name } = item

  dispatch({
    type: SKZIINSTANCEONEDIT,
    payload: { 
      editObject: {
        item: item,
        name: name,
        title: 'Изменить экземпляр СКЗИ'
      }
    }
  })
}

export const skziInstanceOnCreateAction = () => dispatch => {
  dispatch({
    type: SKZIINSTANCEONCREATE,
    payload: { 
      createObject: {
        name: '',
        title: 'Создать экземпляр СКЗИ'
      }
    }
  })
}

export const skziInstanceEventFormChanged = (data) => dispatch => {
  dispatch({
    type: SKZIINSTANCEEVENTFORMCHANGED,
    payload: data
  })
}

export const skziInstanceResetAction = () => dispatch => {
  return dispatch({
    type: SKZIINSTANCERESETACTION,
    payload: null
  })
}