/** @format */
import { useState } from 'react';
import Group from 'app/components/ui/managePanel/group';
import DownloadFileButton from 'app/components/downloadFileButton/';
import { getResourceRights } from 'app/core/auth/auth';
import { useSelector } from 'react-redux';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import CertificateSuspension from 'app/components/certificateAuthority/certificates/suspension';
import CertificateRenewal from 'app/components/certificateAuthority/certificates/renewal';
import CertificateRevocation from 'app/components/certificateAuthority/certificates/revocation';
import Button, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import withTooltip from 'app/components/HOC/TooltipHOC';
import ImportCertificateWizard from '../../import/importWizard';
import ImportCertificateButton from '../../import/importButton';
import ConfirmDialog, { dialogMode, dialogType } from 'app/components/dialog/confirmDialog';
import { CertificateSource } from '../certificateData/const';
import service from 'app/services/service';

const TooltippedButton = withTooltip(Button);

export default function BusinessEventPanel({ certificate, CAUser = false, keyDocumentId, onRefresh, onProgress }) {
  const profile = useSelector(({ profile }) => profile);
  const managementRights = getResourceRights(
    profile,
    CAUser ? RESOURCES.certificateAuthorityCertificate : RESOURCES.certRequest
  );
  const manualImportRights = getResourceRights(profile, RESOURCES.keyDocumentCertificates);

  const [uploadWizardEnabled, setUploadWizardEnabled] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const toggleDeleteDialog = () => {
    setDeleteDialogVisible(!deleteDialogVisible);
  };
  const toggleCertificateWizard = () => {
    setUploadWizardEnabled(!uploadWizardEnabled);
  };

  const handleDelete = async () => {
    setDeleteDialogVisible(false);
    onProgress(true);
    await service('certificateService', 'deleteKeyDocumentCertificate', { keyDocumentId });
    await onRefresh();
    onProgress(false);
  };

  const downloadVisible = certificate && certificate.lastSource?.id !== CertificateSource.Agent;
  // const actionsVisible =
  //   certificate?.lastSource?.id === CertificateSource.CertificateAuthority && managementRights.UPDATE;
  // в рамках AOKZ-9559 Загрузка сертификата пользователем СКЗИ и сотрудником ОКЗ actionsVisible = false всегда. позже будем доделывать
  const actionsVisible = false;
  const deleteVisible = certificate?.lastSource?.id === CertificateSource.Manual && manualImportRights.DELETE;
  const importVisible = !certificate || certificate.lastSource?.id === CertificateSource.Manual;

  const downloadUrl = keyDocumentId
    ? `/api/v1/KeyDocument/${keyDocumentId}/certificate/files/download`
    : `/api/v1/cryptopro/${CAUser ? 'acceptorcertificates' : 'certificates'}/download?sn=${certificate?.id}`;

  return (
    <>
      {downloadVisible && (
        <Group>
          <DownloadFileButton url={downloadUrl} title="Скачать" type={buttonTypes.primary} />
        </Group>
      )}
      {actionsVisible && (
        <>
          <Group>
            <CertificateRevocation item={certificate} CAUser={CAUser} />
          </Group>
          <Group>
            <CertificateSuspension item={certificate} CAUser={CAUser} />
          </Group>
          <Group>
            <CertificateRenewal item={certificate} CAUser={CAUser} />
          </Group>
        </>
      )}
      {deleteVisible && (
        <Group>
          <TooltippedButton type={buttonTypes.image} tooltip={'Удалить сертификат'} onClick={toggleDeleteDialog}>
            <Img img={Image.Delete} />
          </TooltippedButton>
        </Group>
      )}

      {importVisible && (
        <Group>
          <ImportCertificateButton
            isPrimary={!certificate}
            onClick={toggleCertificateWizard}
            disabled={!manualImportRights.IMPORT}
          />
        </Group>
      )}
      {uploadWizardEnabled && (
        <ImportCertificateWizard
          keyDocumentId={keyDocumentId}
          onCancel={toggleCertificateWizard}
          onImportComplete={onRefresh}
        />
      )}
      {deleteDialogVisible && (
        <ConfirmDialog
          mode={dialogMode.small}
          header={`Удалить сертификат?`}
          type={dialogType.danger}
          submitText="Удалить"
          onSubmit={handleDelete}
          onCancel={toggleDeleteDialog}
          messages={[
            'Сертификат будет безвозвратно удален из системы.',
            'При удалении всех вложений будут также удалены все реквизиты сертификата.',
          ]}
        ></ConfirmDialog>
      )}
    </>
  );
}
