import React from 'react'
import PasswordField from './PasswordField'
import SubmitButton from './SubmitButton'

export default function ExpiredPassword(props) {
  const {
    password,
    confirmPassword,
    expiredPassword,
    expiredPasswordError,
    passwordError,
    confirmPasswordError,
    onSubmit,
    onPasswordChange,
    onExpiredPasswordChange,
    onConfirmPasswordChange
  } = props

  return (
    <>
      <PasswordField
        value={expiredPassword}
        error={expiredPasswordError}
        isExpiredPassword={true}
        onChange={onExpiredPasswordChange}
        labelText='Старый пароль'
      />
      <PasswordField
        value={password}
        error={passwordError}
        onChange={onPasswordChange}
        autocomplete={'new-password'}
        labelText='Новый пароль'
      />
      <PasswordField
        isConfirm={true}
        value={confirmPassword}
        error={confirmPasswordError}
        onChange={onConfirmPasswordChange}
        autocomplete={'new-password'}
        labelText='Подтвердить пароль'
        onSubmit={onSubmit}
      />
      <SubmitButton
        label='Сохранить'
        onSubmit={onSubmit}
      />
    </>
  )
}