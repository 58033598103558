const cardLinesKeys = [
  'name', 'skziTypeId', 'producerId', 'description', 'shortDescription', 'updateDate'
]

export const mapDataToLines = ( data ) => {
  return cardLinesKeys.reduce( (acc, key) => {
    let value;
    switch (key) {
      case 'name':
        const { name } = data
        value = name 
        break;
      case 'skziTypeId':
        const { type } = data 
        value = type
        break;                
      case 'producerId':
        const { producer } = data 
        value = producer
        break;  
      case 'description':
        const { description } = data
        value = description
        break;  
      case 'shortDescription':
        const { shortDescription } = data 
        value = shortDescription
        break;  
      case 'updateDate':
        const { updateDate } = data
        value = updateDate
        break;                
    }
    
    return { ...acc, [key]: value }
  }, {})
}
