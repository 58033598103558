import { SIGNATUREREADYTOSIGN, SIGNATURESETREQUESTDATA, SIGNEDSUCCESSFULLY,SIGNATURERESET} from '../actionTypes'
import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const getSignatureTextAction = data => (dispatch) => {
  return service('signatureService', 'generateText', data)
}

  export const generateText = (data, serviceMethod) => (dispatch) => {
    return service('signatureService', serviceMethod || 'generateText', data)
      .then(response => {
        const { data, errors, isError, isWarn } = response;
        dispatchLogic(dispatch, SIGNATUREREADYTOSIGN, data, isError, errors, isWarn)
        return response
      })
  }

  export const setRequestData = (data) => (dispatch) => {
    dispatch({
      type: SIGNATURESETREQUESTDATA,
      payload: data
    })
  }

  export const setSignatureResult = (data) => (dispatch) => {
    return dispatch({
      type: SIGNEDSUCCESSFULLY,
      payload: data
    })
  }

  export const resetSignature = () => (dispatch) => {
    dispatch({
      type: SIGNATURERESET,
      payload: null
    })
  }