/** @format */
import useCardCertificationBlock from './useCardCertificationBlock';
import { Table as TableComponent } from 'app/components/list/Table/Table';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import service from 'app/services/service';
import withInfiniteScroll from 'app/components/HOC/InfiniteScrollHOC'
const Table = withInfiniteScroll(TableComponent);

export default function CardCertificationBlock({ routeParams = {} }) {
  const { sorting, sortListAction, buildColumns } = useCardCertificationBlock(routeParams);
  const { skziId, versionId, buildId } = routeParams || {};
  return (
    <>
      <ExpandingBlock
        className="card__skzi-build-certificates"
        renderHeader={() => 'Сертификаты соответствия'}
        initialState={true}
      >
        {skziId && (
          <Table
            loadData={({ page, pageSize }) =>
              service('versionService', 'getAllCertifications', {
                skziId,
                versionId,
                buildId,
                page,
                pageSize,
                ...sorting,
              })
            }
            dependencies={[sorting]}
            sortListAction={sortListAction}
            sorting={sorting}
            fieldsConfig={buildColumns}
          />
        )}
      </ExpandingBlock>
    </>
  );
}
