/** @format */

import React from 'react';
import IconWidget from '../iconWidget';
import { Header, NormalText } from '../typography';
import styles from './org.module.scss';
import { Image } from 'app/components/ui/Img';
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC';

export default function OrganizationWidget({ name, isOkz }) {
  return (
    <IconWidget className={'dashboard-header-organization'} img={Image.Geolocation}>
      <div className={styles.currentOrg}>
        <Header>{isOkz ? 'ОКЗ' : 'Организация'}</Header>
        <div className={styles.nameContainer}>
          <OgrName 
            className={styles.currentOrgName} 
            tooltip={name}
            tooltipPosition={Position.BottomLeft}
          >
            {name}
          </OgrName>
        </div>
      </div>
    </IconWidget>
  );
}

const OgrName = withTooltip(NormalText);
