import React from 'react'
import { connect } from 'react-redux'

import ProgressDialog from './fileProgressDialog'
import ProgressNotify from './fileProgressNotify'

import * as fileActions from 'redux/actions/common/fileActions'

const fileProgressComponent = (props) => {
  const { fileOnDialogToggleAction, files, showFileProgressDialog, progress } = props
  const anyErrors = files.find(file => file.result && file.result.errors && file.result.errors.length)
  const inProgress = files.find(file => !file.result)
  const showFileNotification = files && files.length

  return (
    <>
      {showFileProgressDialog ? (
        <ProgressDialog
          onCancel={() => fileOnDialogToggleAction(false)}
          files={files}
          progressData={progress}
        />
      ) : null}
      {showFileNotification ? (
        <ProgressNotify
          onClick={() => fileOnDialogToggleAction(true)}
          inProgress={inProgress}
          anyErrors={anyErrors}
        />
      ) : null }
    </>
  )
}
const mapStateToProps = state => {
  return { ...state.fileUpload }
}

export default connect(
  mapStateToProps,
  { 
    ...fileActions
  },
  )(fileProgressComponent)