import React, {useCallback, useEffect, useState} from "react"
import useToast from "./useToast"

export default function Toast({toast}){

  const {message, closable} = toast

  const [hide, setHide] = useState(false)

  const { removeToast } = useToast()

  useEffect(() => {
    let hideTimeout
    let timeout
    if(!closable){
      hideTimeout = setTimeout(() => {
        setHide(true)
      }, 6740);
      timeout = setTimeout(() => {
          removeToast(toast)
      }, 7000);
    }
    return () => {
      clearTimeout(timeout)
      clearTimeout(hideTimeout)
    }
  }, [])

  return (
    <div
      className={`notification toast top ${hide ? 'fade-out' : ''}`}
    >
      <div>
        <div className="notification-message">{message}</div>
      </div>
      {closable && (
        // TODO переписать на button
        <div
          className="close-button"
          onClick={() => {
            setHide(true)
            setTimeout(() => {
              removeToast(toast)
            }, 260);
          }}>
            закрыть
        </div>
      )}
    </div>
  )
}