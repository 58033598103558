import {
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  CLEARERROR,
  SKZIVERSIONCARDGET,
  SKZIVERSIONCARDUPDATEFORMDATA,
  SKZIVERSIONCARDINPROGRESS,
  SKZIVERSIONCARDRESETACTION,
  SKZIVERSIONCARDUPDATE,
  SKZIVERSIONCARDUPDATEADDBUILDFORMDATA,
  SKZIVERSIONCARDVERSIONBUILDADD,
  SKZIVERSIONCARDBUILDONDELETE,
  SKZIVERSIONCARDBUILDDELETE,
  SKZIVERSIONCARDONCANCEL,
  SKZIVERSIONCARDONSELECT,
  SKZIVERSIONCARDVERSIONONBUILDADD,
  SKZIVERSIONCARDGETVERSIONBUILDS,
  SKZIVERSIONCARDONCOURSESELECT,
  SKZIVERSIONCARDCOURSEONDELETE,
  SKZIVERSIONCARDCOURSEDELETE,
  SKZIVERSIONCARDCOURSEADD,
  SKZIVERSIONCARDGETVERSIONCOURSES,
  SKZI_VERSION_CARD_ADD,
  SKZI_VERSION_CARD_CLEAR_FORM
} from '../../actions/actionTypes'
  
  import { dialogType } from 'app/components/dialog/confirmDialog/'
  import { excludeByItemId, arrayToIdHashMap } from 'app/core/utility/common'
  
  const defaultState = {
    selectedBuilds: {
      items: [],
      selectedHashMap: {}
    },
    selectedCourses: {
      items: [],
      selectedHashMap: {}
    },
    commonDataForm : {
      description: '',
      name: '',
      createDate: null,
    },
    viewData: {
      description: '',
      name: '',
      createDate: null,
    },
    createBuildForm : {
      name: '',
      classId: null,
      certificate: '',
      certificateExpirationDate: null,
      description: ''
    },
    skziVersionData: {},
    confirmObject: null,
    deleteBuildConfirm: null,
    deleteCourseConfirm: null,
    addBuildDialog: null,
    inProgress: false
  }
  
  export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { skziVersionData, viewData, selectedBuilds, selectedCourses } = state
    const { builds, courses } = skziVersionData
      
    switch (action.type) {
      case SKZIVERSIONCARDONSELECT: 
        return {
          ...state,
          selectedBuilds: payload || defaultState.selectedBuilds
        }

      case SKZIVERSIONCARDONCOURSESELECT: 
        return {
          ...state,
          selectedCourses: payload || defaultState.selectedBuilds
        }
        
      case SKZIVERSIONCARDVERSIONONBUILDADD:
        return {
          ...state,
          addBuildDialog: {}
        }

      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case SKZIVERSIONCARDUPDATE: 
      case SKZIVERSIONCARDGET:
      case SKZI_VERSION_CARD_ADD:
        return {
          ...state,
          commonDataForm: payload,
          viewData: payload,
          skziVersionData: {...payload},
          inProgress: false
        }
  
      case CLEARERROR:
        return {
          ...state,
          confirmObject: null,
          deleteBuildConfirm: null,
          deleteCourseConfirm: null,
          inProgress: false
        }
      
      case FIELDSERROR:
      case ERROR:
        return {
          ...state,
          inProgress: false
        }
   
      case SKZIVERSIONCARDBUILDONDELETE:

        return {
          ...state, 
          deleteBuildConfirm: {
            item: payload,
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить cборку: «${payload.map(item => item.name).join(', ')}»?`
          },
          inProgress: false
        }

      case SKZIVERSIONCARDCOURSEONDELETE:

        return {
          ...state,
          deleteCourseConfirm: {
            item: payload,
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить курсы: «${payload.map(item => item.name).join(', ')}»?`
          },
          inProgress: false
        }
  
      case SKZIVERSIONCARDVERSIONBUILDADD:
        return {
          ...state,
          skziVersionData: {
            ...skziVersionData,
            builds: [
              payload,
              ...builds
            ]
          },
          createBuildForm: defaultState.createBuildForm,
          addBuildDialog: null,
          inProgress: false,
        }

      case SKZIVERSIONCARDCOURSEADD:
        const { courses: payloadCourses } = payload
        const addedCourses = excludeByItemId(payloadCourses, courses)

        return {
          ...state,
          skziVersionData: {
            ...skziVersionData,
            courses: [
              ...addedCourses,
              ...courses
            ]
          },
          inProgress: false,
        }
      case SKZI_VERSION_CARD_CLEAR_FORM:
        return {
          ...defaultState,
          commonDataForm: {
            ...defaultState.commonDataForm,
            createDate: new Date()
          }
        }
      case SKZIVERSIONCARDGETVERSIONCOURSES:
        return {
          ...state,
          skziVersionData: {
            ...skziVersionData,
            courses: payload
          },
          inProgress: false,
        }
        
      case SKZIVERSIONCARDBUILDDELETE:
        const { items: selectedItems } = selectedBuilds
        let confirmDialog = null
        const afterDeleteSelected = excludeByItemId(selectedItems, payload.map(item => ({id: item}))) 
  
        if (errors && errors.length) {
          confirmDialog = {
            header: 'Удаление невозможно',
            type: dialogType.warning,
            messages: errors.map(item => item.errorMessage)
          }
        }
  
        return {
          ...state,
          selectedBuilds: {
            items: afterDeleteSelected,
            selectedHashMap: arrayToIdHashMap(afterDeleteSelected)
          },
          skziVersionData: {
            ...skziVersionData,
            builds: excludeByItemId(builds, payload.map(item => ({id: item})))
          },
          deleteBuildConfirm: null,
          confirmObject: confirmDialog,
          inProgress: false
        }

      case SKZIVERSIONCARDCOURSEDELETE:
        const { items: selectedCourseItems } = selectedCourses
        let confirmCourseDialog = null
        const afterDeleteSelectedCourses = excludeByItemId(selectedCourseItems, payload.map(item => ({id: item}))) 
  
        if (errors && errors.length) {
          confirmCourseDialog = {
            header: 'Удаление невозможно',
            type: dialogType.warning,
            messages: errors.map(item => item.errorMessage)
          }
        }
  
        return {
          ...state,
          selectedCourses: {
            items: afterDeleteSelectedCourses,
            selectedHashMap: arrayToIdHashMap(afterDeleteSelectedCourses)
          },
          skziVersionData: {
            ...skziVersionData,
            courses: excludeByItemId(courses, payload.map(item => ({id: item})))
          },
          deleteCourseConfirm: null,
          confirmObject: confirmCourseDialog,
          inProgress: false
        } 


      case SKZIVERSIONCARDGETVERSIONBUILDS:
        return {
          ...state,
          skziVersionData: {
            ...skziVersionData,
            builds: payload
          },
          inProgress: false
        }
  
      case SKZIVERSIONCARDUPDATEFORMDATA: 
        return {
          ...state,
          commonDataForm: {...payload }
        }
    
      case SKZIVERSIONCARDUPDATEADDBUILDFORMDATA: 
        const { createBuildForm: cbf } = state
        
        return {
          ...state,
          createBuildForm: { ...cbf, ...payload }
        }
   
      case SKZIVERSIONCARDINPROGRESS:
        return {...state, inProgress: payload}
  
      case SKZIVERSIONCARDONCANCEL:
        return { 
          ...state,
          commonDataForm: {...viewData}
        }
   
      case SKZIVERSIONCARDRESETACTION: 
  
        return { 
          ...defaultState,
          viewData: viewData,
          selectedBuilds: selectedBuilds,
          selectedCourses: selectedCourses,
          skziVersionData: skziVersionData,
          commonDataForm: viewData,
        }
  
      default: 
        return state
    }
  }