import {Type} from './enums';

export const LOAD_RESULT = 'LOAD_RESULT';
export const CREATION_RESULT = 'CREATION_RESULT';
export const ONE_HUNDRED_MB_IN_BYTES = 104857600;
export const acceptFileExtension = ['xls', 'xlsx'];
export const ImportDateFormat = 'dd.mm.yyyy';
export const acceptedFileTypesForSignature = [
    Type.LICENSE,
    Type.KEY_CARRIER,
    Type.OKZ_KEY_DOCUMENT,
    Type.OKI_KEY_DOCUMENT,
    Type.OKZ_KEY_ESKZI,
    Type.OKI_KEY_ESKZI,
];
export const IMPORT_DATE_FORMAT = 'dd.mm.yyyy';
export const IMPORT_LIMIT_COUNT = 5;
export const OPTIONAL_FIELDS = ['ExpirationDate', 'ProducerId'];
