/** @format */

import { useEffect, useState } from 'react';

import Button, { buttonTypes } from 'app/components/ui/button/button';
import Alert from 'app/components/ui/Alert';
import { Table } from 'app/components/list/Table/Table';
import SpinnerLocal from 'app/components/ui/SpinnerLocal';
import withModal from 'app/components/HOC/ObsoleteModalHOC';
import ConfirmDialog from 'app/components/dialog/confirmDialog/';

import Severity from 'app/core/types/severity';

import tableSchema from './tableSchema';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { sortDirection } from 'app/components/ui/constants';
import { api } from 'app/services/service';

import styles from './deleteDialog.module.scss';

const DeleteOrganizations = props => {
  const fetchLinkedItems = async ids => {
    const itemsResult = await api('organization/getOrganizationsEntitiesInfo', { ids: ids });
    const { data, isError, errors } = itemsResult;
    if (isError) {
      const message = errors.map(el => {
        if (!el.errorMessage && el.detail && el.detail.response) {
          return el.detail.response.errors[0]?.errorMessage;
        }
        return el.errorMessage ?? '';
      });

      const confirmObject = {
        title: message,
        type: 'warning',
      };

      setConfirm(() => confirmObject);
    }
    return data || [];
  };

  const { onCancel, ids, onSubmitSignEnhance } = props;

  const [inProgress, setInProgress] = useState();
  const [linkedItems, setLinkedItems] = useState([]);
  const [confirm, setConfirm] = useState({});

  useEffect(() => {
    const fetchLinked = async () => {
      setInProgress(true);
      const result = await fetchLinkedItems(ids);

      setLinkedItems(result);
      setInProgress(false);
    };

    fetchLinked();
  }, [ids]);

  const { sorting, sortListAction, sortListSetAction, syncFrontListSortAction, paginator } = useTableExtensions({
    defaultSort: {
      column: 'organizationId',
      direction: sortDirection.desc,
    },
  });

  useEffect(() => {
    syncFrontListSortAction(sorting, tableSchema, linkedItems);
  }, [sorting]);

  const handleDeleteOrganizationsConfirm = async () => {
    onSubmitSignEnhance({
      ids: ids,
      OperationType: 'DELETE',
      signatureRequestType: 'organizationsUpdate',
    });
  };

  return (
    <>
      {confirm.type === 'warning' && <ConfirmDialog {...confirm} onCancel={onCancel} />}
      <div className={`${styles.deleteDialog}`}>
        <div className="modal__header">Удаление организации</div>

        <div className={`modal__content ${styles.content}`}>
          <Alert
            severity={Severity.Warning}
            showIcon
            content="Внимание! При удалении организации будут удалены все связанные объекты учета."
          />
          <div className={styles.data}>
            <Table
              sortListAction={sortListAction}
              sortListSetAction={sortListSetAction}
              sorting={sorting}
              totalCount={linkedItems.length}
              totalCountLabel="всего результатов"
              list={linkedItems}
              fieldsConfig={tableSchema}
              strictWidth
              paginator={paginator}
            />
            {inProgress && <SpinnerLocal className={styles.spinner} />}
            {!inProgress && !linkedItems.length && (
              <div className={styles.noData}>Связанные объекты учета отсутствуют</div>
            )}
          </div>
        </div>

        <div className={`modal__footer ${styles.footer}`}>
          <Button
            type={buttonTypes.warning}
            disabled={inProgress}
            className="btn-confirm"
            onClick={handleDeleteOrganizationsConfirm}
          >
            <span className="button-title">Удалить</span>
          </Button>
          <Button type={buttonTypes.text} className="btn-cancel" onClick={onCancel}>
            <span className="button-title">Отмена</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default withModal(DeleteOrganizations);
