import {useEffect, useRef} from "react";

export const debounce = (func, delay) => {
  let timeout;
  
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout( 
      () => { func(...args);},
      delay);
  }
}

export function stringPlural(number, titles) {
  let cases = [2, 0, 1, 1, 1, 2];

  number = Math.abs(number);

  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

export const updateCollectionByItemId = (collection, item) => {
  const { id } = item || {}
  const index = collection.findIndex(item => item.id === id)

  return index > -1 
    ? [
      ...collection.slice(0, index),
      {...item, index: item.index || index + 1},
      ...collection.slice(index + 1),
    ]
    : collection
}

export const updateCollectionByAnotherCollection = (collectionToChange, anotherCollection ) => {
  const anotherCollectionObj = arrayToIdHashMap(anotherCollection)
  return collectionToChange.map(i => {
    return anotherCollectionObj[i.id] || i
  })
}

// при совпадении id элементы первого перезаписываются элементами второго
export const mergeArraysById = (first, second) => {
  if (!first || !first.length) {
    return  (!second || !second.length) ? [] : second
  }

  if (!second || !second.length) {
    return  (!first || !first.length) ? [] : first
  }

  const result = [...excludeByItemId(first, second), ...second]
  return result
}

export const arrayToIdHashMap = (array, field = 'id') => {
  if (!array || !array.length) {
    return {}
  }

  return array.reduce((acc, cur) => {
    acc[cur[field]] = cur
    return acc
  }, {})
}

export const treeToIdHashmap = (tree) => {
  let result = {}

  const traverse = (items) => {
    if(!items || !items.length) {
      return
    }

    items.forEach(item => {
      result[item.id] = item
      traverse(item.children)
    })
  }

  traverse(tree)
  return result
}

export const excludeByItemId = (from, what) => {
  if (!from || !from.length || !what || !what.length) {
    return from
  }

  const fromHashMap = arrayToIdHashMap(from)
  const whatHashMap = arrayToIdHashMap(what)
  const result = []

  from.forEach(item => {
    if (!whatHashMap[item.id]) {
      result.push(fromHashMap[item.id])
    }
  })

  return result
}

export const intersectByItemId = (first, second) => {
  if (!first || !first.length || !second || !second.length) {
    return []
  }

  const firstHashMap = arrayToIdHashMap(first)
  const secondHashMap = arrayToIdHashMap(second)
  const result = []

  Object.keys(firstHashMap).forEach(key => {
    if (secondHashMap[key]) {
      result.push(firstHashMap[key])
    }
  })

  return result
}

export const deleteArrayItemById = (collection, item) => {
  const { id } = item || {}
  const index = collection.findIndex(item => item.id === id)

  return index > -1 
    ? [
      ...collection.slice(0, index),
      ...collection.slice(index + 1),
      ].map((item, i) => ({...item, index: i+1}))
    : collection
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  });
  return ref.current
}

export const formatFIO = user => {
  const { lastName, firstName, middleName } = user || {}

  return `${lastName || ''}${firstName ? ' ' + firstName : ''}${middleName ? ' ' + middleName : ''}`
}

export const formatFIOInitials = user => {
  const { lastName, firstName, middleName } = user || {}

  return `${lastName || ''}${firstName && firstName.length ? ' ' + `${firstName[0].toUpperCase()}.` : ''}${middleName && middleName.length ? ' ' + `${middleName[0].toUpperCase()}.` : ''}`
}

export const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const decapitalize = str => {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

export const getCertificateEntry = (from, what) => {
  let entry = '';
  const begin = from.indexOf(what);

  if (begin >= 0) {
    var end = from.indexOf(', ', begin);
    entry = end < 0 ? from.substr(begin) : from.substr(begin, end - begin);
  }

  return entry.replace(what, '');
}

export const serverlikeErrors = (errorsObject) => {
  return Object.keys(errorsObject).reduce((acc, key) => {
    // check if changed fieldset, different logic
    if(Array.isArray(errorsObject[key])){
      const errorValues = errorsObject[key].reduce((accErr, el, index) => {
        return [
          ...accErr,
          ...Object.keys(el).reduce((accum, keyEl) => {
            return [...accum, {
              "field": `${capitalize(key)}[${index}].${capitalize(keyEl)}`,
              ...el[keyEl]
            },]
          }, [])
        ]
      }, [])
      return [...acc,
        ...errorValues
      ]
    }
    return [...acc, {
      "field": capitalize(key),
      ...errorsObject[key]
    },]
  }, [])
} 

export const flatten = (arr) => {
  if (!arr) {
    return arr
  }

  return arr.reduce((acc, cur) => {
    return Array.isArray(cur) ? [...acc, ...flatten(cur)] : [...acc, cur]
  }, [])
}

export const hexToRgb = hex =>
  hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
             ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))

export const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => parseInt(x).toString(16).padStart(2, '0')).join('')

export const copyToClipboard = (text) => {

  return new Promise((res, rej)=> {
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("id", "dummy_id");
    document.getElementById("dummy_id").value=text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    res()
  })
}

export const zeroLeft = (value, length = 3) => {
  return `${value}`.length >= length
          ? value
          : zeroLeft(`0${value}`, length)
}

/**
 * Сравнение значений поля формы (до изменений и после)
 * Если изменения с точки зрения функции есть, значит 
 * можно дать пользователю сохранить эти изменения.
 * @returns {Boolean}
 */
export function formFieldEquality(before, after) {
  if (before === after) {
    return true;
  } else if (Array.isArray(before) && Array.isArray(after)) {
    return before.length === after.length && excludeByItemId(before, after).length === 0
  } else if (before instanceof Date && after instanceof Date) {
    return before.valueOf() === after.valueOf()
  } else if ((typeof before == "object" && before != null) && (typeof after == "object" && after != null)) {
    return before.id === after.id;
  }  else
    return false;
}

export function dataFormEqual(viewData, commonData){
  return Object.keys(viewData).reduce((acc, prop) => {
    return acc && formFieldEquality(viewData[prop], commonData[prop])
  }, true)
}

export function resetDependsFilters(filtersConfig, setFilterStateAction, item){
  let deepDependElement = null
  filtersConfig.forEach(el => {
    if (el.dependsOn === item.alias && el.hardDepends){
      deepDependElement = el
      setFilterStateAction({
          alias: el.alias,
          isExtendedSearchBlock: false,
          requestFormatIsString: null,
          valueFormattedString: null,
          valueForRequest: null
        }
      )
    }
  })
  if(deepDependElement) {
    resetDependsFilters(filtersConfig, setFilterStateAction, deepDependElement)
  }
}

export const isEmptyObject = (obj) => {
  return  obj // 👈 null and undefined check
          && Object.keys(obj).length === 0
          && Object.getPrototypeOf(obj) === Object.prototype
}

export const eventStop = (e) => {
  e && e.stopPropagation()
  e && e.preventDefault()
}

export function LinkedListNode (val) {
  this.val = val
  this.next = null
}

export const percentage = (total, part) => {
  if (part === 0 || total === 0) {
    return 0
  }

  return Math.round((part*100)/total)
}