/** @format */

import React from 'react';

import PageHeader from 'app/components/PageHeader/pageHeader';
import Images from 'app/components/ui/Img/imgMap';
import { ManagePanel, Border, Group } from 'app/components/ui/managePanel';
import { sortDirection } from 'app/components/ui/constants';
import MainListContainer from 'app/components/ui/MainListContainer/MainListContainer';
import BaseButton from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import Filter from 'app/components/filter';
import { Table } from 'app/components/list/Table/Table';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import Overlay from 'app/components/ui/overlay';

import { RESOURCES } from 'app/core/auth/resourceByPage';
import { useJournal } from 'app/components/journals/useJournal';
import BusinessEventPanel from './BusinessEventPanel';
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC';

import fieldsConfig from './tableSchema';
import filtersConfig from './filtersConfig';

import styles from './listForm.module.scss';

const Button = withTooltip(BaseButton);

export default function ListForm({}) {
  const {
    baseTableLogic,
    itemSelectionLogic,
    paginationLogic,
    sortingLogic,
    filterLogic,

    profile,
    rights,
    inProgress,
    onInProgress,
    setSelectedPagination,
    selectedObjects,
    setSelectedAction,
    onRemoveValues,
    fieldsVisible,
    filterState,
    onClearFilter,
  } = useJournal({
    defaultSort: {
      column: 'updateDate',
      direction: sortDirection.desc,
    },
    serviceName: 'skziService',
    resource: RESOURCES.skzi,
  });

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title="Реестр СКЗИ: Каталог продуктов" Img={Images.Registry} />
      <ManagePanel borders={Border.All}>
        <BusinessEventPanel
          {...{
            rights,
            setSelectedAction,
            onInProgress,
          }}
          items={selectedObjects}
          afterAction={onRemoveValues}
        />
        <Group separate>
          <Button
            type="image"
            onClick={onClearFilter}
            disabled={!filterState.length}
            tooltip="Очистить фильтр"
            tooltipPosition={Position.BottomLeft}
          >
            <Img img={Image.Broom} className="button-image" />
          </Button>
        </Group>
      </ManagePanel>
      <Filter
        {...{
          ...filterLogic,
          profile,
          inProgress,
          filtersConfig,
          className: styles.skziFilter,
        }}
      />
      <Table
        {...{
          ...baseTableLogic,
          ...itemSelectionLogic,
          ...sortingLogic,
          setSelectedPagination,
          inProgress,
          fieldsConfig,
          itemsSelectable: true,
          headerInfo: true,
          onItemClick: item => `/skziRegistry/accounting/skziCard/${item.id}`,
        }}
      />
      <Pagination {...paginationLogic} />
    </MainListContainer>
  );
}