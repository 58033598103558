import React from 'react'

export default function tagsError(props) {
  
  const { error, hint } = props
  const { errorMessage } = error || {}

  // to do
  // remove after styleguide 2.0
  if (!error && !hint) {
    return null
  }
  // -------

  return (
    <div className='input-hint'>
      {error ? (
        <span className='input-hint__error'>{errorMessage}</span>
      ) : null}
      {!error && hint ? (
        <span className='input-hint__hint'>{hint}</span>
      ) : null}
    </div>
  )
}
