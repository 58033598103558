import {ReactComponent as PlusIcon} from "assets/img/commonVer2/plus.svg";
import {useHistory} from "react-router-dom";
import Button, { buttonTypes } from 'app/components/ui/button/button';
import {DownloadButton} from "../../list/TopPanel/DownloadButton";
import { useSelector } from "react-redux";
import {getResourceRights} from "../../../core/auth/auth";
import {Type} from "../../MassImports/enums";
import { RESOURCES } from "app/core/auth/resourceByPage";
import MassImportButton from "app/components/MassImports/MassImportButton";
import DeleteOrganizations from '../DeleteOrganizations';

export default function ActionPanel({
                                        sorting,
                                        fieldsVisible,
                                        filterForRequest,
                                        selectedItems,
                                        inProgress,
                                        onInProgress,
                                        deleteActionCallback,
                                        fileUploadCallBack,
                                        onRemoveValues,
                                        setSelectedAction
                                    }) {
    const history = useHistory();
    const profile = useSelector(({profile}) => profile);
    const canUserCreateOrganization = getResourceRights(profile, RESOURCES.organizationOki)['CREATE'];
    const massImportRights = getResourceRights(profile, RESOURCES.massImportOrganization);

    return (
        <>
            {canUserCreateOrganization && <div className="buttons-element">
                <Button
                    disabled={inProgress}
                    type={buttonTypes.primary}
                    onClick={() => history.push('/catalog/organization/create')}
                >
                    <PlusIcon />
                    Создать
                </Button>
            </div>}
            { massImportRights.IMPORT && <div className="buttons-element">
                <MassImportButton type={Type.ORGANIZATION} fileUploadCallBack={fileUploadCallBack} tooltip="Загрузка организаций из файла" />
            </div> }
            
            <div className={'buttons-element'}>
                <DownloadButton
                    listFilterForRequest={filterForRequest}
                    sorting={sorting}
                    listFieldsVisibility={fieldsVisible}
                    selected={selectedItems}
                    titul="Выгрузить в Excel"
                    type="DownloadButton"
                    inProgress={inProgress}
                    serviceName='organizationService'
                />
            </div>
            <div className="buttons-element">
                <DeleteOrganizations
                    ids={selectedItems}
                    onInProgress={onInProgress}
                    afterAction={onRemoveValues}
                />
            </div>
        </>
    );
}
