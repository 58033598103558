import payloadConfigConsts from "app/core/config";
import {sortDirection} from "app/components/ui/constants";
import { FileImportRowStatus, ImportsPageStatus} from "app/components/MassImports/enums";

export const PAYLOAD = {
    LOAD_RESULT: {
        conditions: {
            statuses: [
                FileImportRowStatus.RECOGNIZED,
                FileImportRowStatus.DUPLICATE,
                FileImportRowStatus.ERROR
            ],
            groupDuplicates: false,
            names: [""],
        },
        page: 1,
        pageSize: payloadConfigConsts.paginationPerPage,
        sortBy: 'lineId',
        sortDirection: sortDirection.asc
    },
    CREATION_RESULT: {
        conditions: {
            statuses: [
                FileImportRowStatus.NOT_RECOGNIZED,
                FileImportRowStatus.IMPORTED,
                FileImportRowStatus.DUPLICATE,
                FileImportRowStatus.ERROR
            ],
            groupDuplicates: false,
            names: [""],
        },
        page: 1,
        pageSize: payloadConfigConsts.paginationPerPage,
        sortBy: 'lineId',
        sortDirection: sortDirection.asc
    },
    IMPORTS_PAGE: {
        startDate: '',
        endDate: '',
        names: [''],
        pageSize: payloadConfigConsts.paginationPerPage,
        page: 1,
        groupStatuses: [0, 1, 2, 3, 4],
    },
    LAST_FIVE_IMPORTS_FROM_CACHE: {
        page: 1,
        pageSize: 5,
        onlyCache: true,
        statuses: [
            ImportsPageStatus.UPLOADING,
            ImportsPageStatus.COMPLETE,
            ImportsPageStatus.IN_PROGRESS,
            ImportsPageStatus.UPLOAD_COMPLETE,
            ImportsPageStatus.ERROR,
        ],
    },
    IMPORTS_HISTORY: {
        page: 1,
        pageSize: 25,
        onlyCache: false,
        statuses: [
            ImportsPageStatus.UPLOADING,
            ImportsPageStatus.COMPLETE,
            ImportsPageStatus.IN_PROGRESS,
            ImportsPageStatus.UPLOAD_COMPLETE,
            ImportsPageStatus.ERROR,
        ],
    },
};
