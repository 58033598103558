import React, { Component } from 'react'
import ReactDOM from 'react-dom'

const withDocumentClick = PassedComponent =>
  class WithDocumentClick extends Component {
    constructor(props){
      super(props)
  
    }
  
    componentDidMount () {
      this.domNode = ReactDOM.findDOMNode(this.refs.passedComponent)
      
      document.addEventListener('click', this._globalHandler)
    }
  
    componentWillUnmount () {
      document.removeEventListener('click', this._globalHandler)
    }

    _globalHandler = e => {
      const { toggleStatus, toggle, documentClickAction } = this.props


      if (toggleStatus && !this.domNode.contains(e.target)) {
        // documentClickAction && documentClickAction()
        toggle && toggle()
      }
    }

    render() {
      return (
        <PassedComponent
          ref='passedComponent'
          {...this.props}
        />
      )
    }
  }
  
export default withDocumentClick