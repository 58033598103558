/** @format */

import React, { Children } from 'react';
import styles from './dialog.module.scss';
import { getClassName } from 'app/core/utility/jsx';

export default function ButtonsPanel(props) {
  const { children } = props
  const positionStyle = Children.count(children) === 1 ? styles.center : styles.right;

  return <div className={getClassName([styles.buttonPanel, positionStyle])}>{children}</div>;
}
