
import { THEME } from './themeConfig'

export const setThemeVariables = (currentTheme) => {
  const style = document.body.style
  const currentThemeSettings = currentTheme || THEME.default
  
  style.setProperty("--var-color-brand",  currentThemeSettings.colorBrand);
  style.setProperty("--var-color-on-brand",  currentThemeSettings.colorOnBrand);
  style.setProperty("--var-color-primary",  currentThemeSettings.colorPrimary);
  style.setProperty("--var-color-on-primary",  currentThemeSettings.colorOnPrimary);
  style.setProperty("--var-color-secondary",  currentThemeSettings.colorSecondary);
  style.setProperty("--var-color-on-secondary",  currentThemeSettings.colorOnSecondary);
  style.setProperty("--var-shadow-color",  currentThemeSettings.colorShadow);
  style.setProperty("--var-color-dashboard-brand",  currentThemeSettings.colorBrandDashboard);
}

export default THEME;