/** @format */

import { InputType } from '../Input/Input';
import Autocomplete from './autocomplete';
import Checkbox from '../checkbox';
import styles from './autocomplete.module.scss';

export default function MultiSelect(props) {
  const { onSelect, value, renderItem, unselectAllData } = props;
  const { unselectAllValue, items = [] } = value || {};
  const { value: isChecked = false } = unselectAllValue || {};

  const onSelectItem = selectedItems => {
    onSelect({
      items: selectedItems.filter(i => i.id !== unselectAllData.id),
      unselectAllValue: {
        ...unselectAllData,
        value: false,
      },
    });
  };

  const onUnselectAll = (e) => {
    e.stopPropagation()
    onSelect({
      items: isChecked ? [] : [{ ...unselectAllData }],
      unselectAllValue: {
        ...unselectAllData,
        value: !isChecked,
      },
    });
  };

  return (
    <>
      {unselectAllData ? (
        <Autocomplete {...props} type={InputType.multiSelect} onSelect={onSelectItem} value={items}>
          <div className={styles.unselectAll} key="unselectAll">
            <Checkbox checked={isChecked} onChange={onUnselectAll}>
              {renderItem ? renderItem(unselectAllData) : unselectAllData.name}
            </Checkbox>
          </div>
        </Autocomplete>
      ) : (
        <Autocomplete {...props} type={InputType.multiSelect} />
      )}
    </>
  );
}
