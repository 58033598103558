import { useState } from 'react'
import { connect } from 'react-redux'

import MainCard, { CardButtons, objectKey} from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import Eskzi from './eskzi'
import {cardLines} from './licenseData'
import { RESOURCES } from 'app/core/auth/resourceByPage'


import * as mainCardAction from 'redux/actions/card/cardActions'
import * as licenseCardAction from 'redux/actions/license/licenseCardAction'
import { formatDate } from 'app/core/utility/date'
import { getResourceRights } from 'app/core/auth/auth'
import { getWarningObject } from './create'
import SimpleFilesTable from 'app/components/fileTable/simpleFilesTable/simpleFilesTable'
import { useHistory, useParams } from 'react-router-dom'


export const getLicenseKey =  (data) => {
  const { 
    skziVersion,
    expirationDate,
    amount,
    type
  } = data
  const { version } = skziVersion || {}
  const { id } = version || {}
  const { id: typeId } = type || {}

  return { 
    ...data,
    typeId: typeId,
    amount: amount || null,
    skziVersionId: id,
    expirationDate: formatDate(expirationDate, 'yyyy-mm-dd'),
  }
}

export const cardConfig = ({ 
    history,
    licenseCardUpdateAction,
    getLicenseAction,
    getExternalUserLicenseAction,
    deleteLicenseAction,
    itemId,
    isExternalUser
  }) => ({
  buttons: isExternalUser 
            ? {
              // [CardButtons.delete]: null
            } : {
              [CardButtons.download]: null,
              [CardButtons.delete]: deleteLicenseAction
            },
  cardLines: cardLines,
  resource: isExternalUser 
            ? RESOURCES.extOrgSkziLicense
            : RESOURCES.skziLicense,
  restrictEditByProp: () => !isExternalUser,
  getKey: data => objectKey(data, getLicenseKey),
  signatureHeaderText: 'Изменение лицензии',
  signatureServiceName: 'licenseUpdate',
  signatureEntity: 'SkziLicense',
  xlsDownloadUrl: isExternalUser 
                  ? `/api/v1/external-oki/license/${itemId}/download/xls`
                  : `/api/v1/skzi/license/${itemId}/download/xls`,
  updateAction: licenseCardUpdateAction,
  cardInitAction: isExternalUser 
                    ? getExternalUserLicenseAction
                    : getLicenseAction,
  getSignatureData: getLicenseKey,
  getDeleteMessage: data => `Вы уверены, что хотите удалить лицензию ${data.id}`,
  afterDelete: () => {
    history.push('/license')
  },
  getServerWarning: getWarningObject,
  pageBreadcrumbTitle: data => {
    const { payload } = data || {}
    const { name, id } = payload || {}
    return `Лицензия № ${name || id}`
  },
})

const LicenseCard = (props) => {
  const { 
    isExternalUser,
    licenseCardUpdateAction,
    getLicenseAction,
    getExternalUserLicenseAction,
    deleteLicenseAction,
    profile,
  } = props
  const { licenseId } = useParams();
  const [ activeTab, setActiveTab ] = useState('Информация');
  const fileApi = isExternalUser 
                  ? 'external-oki/SkziLicense'
                  : 'SkziLicense'
  const fileRights = getResourceRights(profile, RESOURCES.skziLicenseFiles)
  const history = useHistory();

  return (
    <section className='license-card'>
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={(title) => setActiveTab(title)}
      >
        <section tabTitle={'Информация'} className="license-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              itemId={licenseId}
              className={`license-data-card license-data-card${isExternalUser ? '--external': ''}`}
              config={cardConfig({
                history,
                licenseCardUpdateAction,
                getLicenseAction,
                getExternalUserLicenseAction,
                isExternalUser,
                deleteLicenseAction,
                itemId: licenseId
              })}
            >
            </MainCard>
          </ExpandingBlock>
          <ExpandingBlock
            className='license-types'
            renderHeader= {() => 'Вложения'}
            initialState={true}
          >
            {fileRights && <SimpleFilesTable
              entityId={licenseId}
              downloadFilesUrl = {`${fileApi}/${licenseId}/content`}
              urlPartUpload={`${fileApi}/${licenseId}/content/add`}
              fileRights = {fileRights}
              serviceName = {'licenseService'}
              deleteMethod = {'deleteSkziLicenseFiles'}
              getMethod = {isExternalUser 
                            ? 'extOrgGetAllSkziLicenseFiles' 
                            : 'getAllSkziLicenseFiles'}
            />}
          </ExpandingBlock>
        </section>
        <Eskzi
          {...props}
          isExternalUser={isExternalUser}
          licenseId={licenseId}
          tabTitle={'Экземпляры СКЗИ'}
        />
      
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card, profile: state.profile}),
  { 
    ...mainCardAction,
    ...licenseCardAction,
  })(LicenseCard)
