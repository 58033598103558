/** @format */

import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { dispatchLogic } from 'redux/actions/common';
import service from 'app/services/service';
import LicenseType from 'app/core/types/licenseType';

const APILicenseType = {
  eskzi: 'eskziLicenses',
  keyDocument: 'keyDocumentLicenses',
  otherSkzi: 'otherSkziLicenses',
};

const APILicenseTypeToLicenseTypeMap = {
  [APILicenseType.eskzi]: LicenseType.eskzi,
  [APILicenseType.keyDocument]: LicenseType.keyDocument,
  [APILicenseType.otherSkzi]: LicenseType.otherSkzi,
};

const convertLicenseData = apiLicenseData =>
  Object.fromEntries(Object.keys(apiLicenseData).map(k => [APILicenseTypeToLicenseTypeMap[k], apiLicenseData[k]]));

const useLicenseCheck = ({ onInProgress = () => {} }) => {
  const dispatch = useDispatch();
  const [licenseState, setLicenseState] = useState({});

  const checkLicenses = useCallback(async () => {
    setLicenseState({});
    onInProgress(true);
    const response = await service('licensingService', 'checkAvailableLicense');
    const { data, isError, errors } = response || {};
    const convertedLicenseData = convertLicenseData(data ?? {});
    setLicenseState(convertedLicenseData);
    onInProgress(false);
    dispatchLogic(dispatch, 'MOCK', data, isError, errors);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { licenseState, checkLicenses };
};

export default useLicenseCheck;