import {
  SKZICREATE,              
  SKZICREATEUPDATEFORMDATA,
  SKZICREATEINPROGRESS    ,
  SKZICREATERESETACTION   ,
  SKZICREATECLEARFORM 
  } from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const registerSkzi = data => dispatch => {
  return service('skziService', 'registerSkzi', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZICREATE, data, isError, errors)
    })
}

export const createSkziUpdateFormData = ({ key, value }) => dispatch => {
  dispatch({
    type: SKZICREATEUPDATEFORMDATA,
    payload: { 
      key, value
    }
  })
}

export const createSkziInProgressAction = value => dispatch => {
  dispatch({
    type: SKZICREATEINPROGRESS,
    payload: { inProgress: value }
  })
}

export const createSkziResetAction = () => dispatch => {
  dispatch({
    type: SKZICREATERESETACTION,
    payload: null
  })
}

export const skziCreateClearForm = () => dispatch => {
  dispatch({
    type: SKZICREATECLEARFORM,
    payload: { 
    }
  })
}