import {useEffect, useState} from "react";
import {fileListAPI} from 'app/core/utility/file';
import {ServiceName, Url} from "app/components/MassImports/enums";
import {Title} from "app/components/MassImports/enums";

export const useUploadInit = (fileType) => {
    const [fileApi, setFileApi] = useState(null);
    const [urlPart, setUrlPart] = useState(null);
    const [title, setTitle] = useState(null);
    const [serviceMethod, setServiceMethod] = useState(null);

    useEffect(() => {
        setTitle(Title.UPLOAD_MODAL[fileType]);
        setServiceMethod(ServiceName[fileType]);
        setUrlPart(`${Url[fileType]}/upload`);
    }, []);
    useEffect(() => {
        if (urlPart) {
            setFileApi(fileListAPI(null, urlPart));
        }
    }, [urlPart]);

    return {
        title,
        serviceMethod,
        fileApi
    };
};
