import { useState, useEffect } from 'react'
import { notEmpty } from 'app/core/utility/validation'
import useToast from '../../components/ui/toast/useToast'
import { api } from 'app/services/service'

const defaultLocalState = {
  login: '',
  password: '',
  loginError: null,
  passwordError: null,
  inProgress: false,
}

export default function useDomainLogin(props){

  const {
    onDomainSubmit,
    setInProgress
  } = props

  const [localState, setLocalState] = useState(defaultLocalState)
  const [loginHint, setLoginHint] = useState();
  
  useEffect(() => {    
    const fetchLoginHint = async () => {
      const result = await api('activeDirectorySettings/getDomainPrompt');
      const {
        data,
      } = result;
      
      const defaultLoginHint = 'Например, user@example.ru или exampledomain\\user';
      setLoginHint(data ? data : defaultLoginHint);
    }
    
    fetchLoginHint();
  }, []);

  const {
    login,
    password,
    loginError,
    passwordError,
    inProgress
  } = localState

  const {
    addToast,
    removeAllToasts
  } = useToast()

  let headerTitle = 'Вход по доменной записи'

  const onPasswordChange = value => setLocalState({...localState, password: value, passwordError: null})
  const onLoginChange= value => setLocalState({...localState, login: value, loginError: null})

  const onLoginSubmit = () => {
    const loginError = notEmpty(login).error
    const passError =  notEmpty(password).error

    setLocalState({
      ...localState,
      loginError: loginError,
      passwordError: loginError ? null : passError,
    })
    setInProgress(!loginError && !passError)
    if (!loginError && !passError) {
      onDomainSubmit({login, password})
        .then(res => {
          setInProgress(false)
          if (res.type === "GLOBAL.ERROR") {
            const passwordExpired = res.payload.detail.response.errors[0].errorCode === 'E_PASSWORD_EXPIRED'
            setLocalState({
              ...localState,
              password: passwordExpired ? '' : password,
              passwordExpired: passwordExpired,
            })
            addToast(res.payload.detail.response.errors[0].errorMessage)
          } else if (res.type === "ERROR") {
            setLocalState({
              ...localState,
            })
            setTimeout(() => {
              setLocalState({
                ...localState,
              })
            }, 5000)
            if(res.errors[0].errorCode !== 'E_UNAUTHORIZED'){
              addToast(res.errors[0].errorMessage)
            }
          }
          else {
            removeAllToasts()
          }
        })
    }
  }

  return {
    headerTitle,
    login,
    loginHint,
    passwordError,
    loginError,
    password,
    inProgress,
    onLoginSubmit,
    onLoginChange,
    onPasswordChange,
  }
}