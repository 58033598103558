/** @format */

import React from 'react';
import ImgMap from 'app/components/ui/Img/imgMap';

import styles from './deleteDialog.module.scss';

const tableSchema = [
  {
    title: '№',
    alias: 'organizationId',
    sortByValue: item => Number(item.organizationId),
    visibleOnStart: true,
    useSorting: true,
    width: 96,
  },
  {
    title: 'Название организации',
    alias: 'organizationName',
    sortByValue: item => item.organizationName,
    visibleOnStart: true,
    useSorting: true,
  },
  {
    title: () => <ImgMap.OkzReport title="СКЗИ" className={`${styles.tableTitle}`} />,
    alias: 'eskziCount',
    visibleOnStart: true,
    useSorting: false,
    width: 96,
    format: item => item.eskziCount || '0',
  },
  {
    title: () => <ImgMap.KeyDocument title="Ключевые документы" className={`${styles.tableTitle}`} />,
    alias: 'keyDocumentCount',
    visibleOnStart: true,
    useSorting: false,
    width: 96,
    format: item => item.keyDocumentCount || '0',
  },
  {
    title: () => <ImgMap.KeyCarrier title="Ключевые носители" className={`${styles.tableTitle}`} />,
    alias: 'keyCarrierCount',
    visibleOnStart: true,
    useSorting: false,
    width: 96,
    format: item => item.keyCarrierCount || '0',
  },
  {
    title: () => <ImgMap.License title="Учетные записи" className={`${styles.tableTitle}`} />,
    alias: 'userCount',
    visibleOnStart: true,
    useSorting: false,
    width: 96,
    format: item => item.userCount || '0',
  },
  {
    title: () => <ImgMap.UserAccounts title="Лицевые счета" className={`${styles.tableTitle}`} />,
    alias: 'eskziUserCount',
    visibleOnStart: true,
    useSorting: false,
    width: 96,
    format: item => item.eskziUserCount || '0',
  },
  {
    title: () => <ImgMap.License title="Лицензии" className={`${styles.tableTitle}`} />,
    alias: 'skziLicenseCount',
    visibleOnStart: true,
    useSorting: false,
    width: 96,
    format: item => item.skziLicenseCount || '0',
  },
  {
    title: () => <ImgMap.Admittance title="Допуск к СКЗИ" className={`${styles.tableTitle}`} />,
    alias: 'admittanceCount',
    visibleOnStart: true,
    useSorting: false,
    width: 96,
    format: item => item.admittanceCount || '0',
  },
];

export default tableSchema;
