import { useEffect, useState } from 'react'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import { Table as TableComponent } from 'app/components/list/Table/Table';
import TextArea from 'app/components/ui/textArea'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import TrainingButton from './trainingButton'
import DownloadButton from './downoadBuildButton'
import { sortDirection } from 'app/components/ui/constants';
import withInfiniteScrollHOC from 'app/components/HOC/InfiniteScrollHOC'
import service from 'app/services/service'
import { courseColumns, buildColumns } from './tableConfig';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';

const Table = withInfiniteScrollHOC(TableComponent)

const downloadButtonVisible = (props) => {
  const { selfServiceState, lastBuild } = props

  if (!lastBuild) {
    return false
  }

  switch (selfServiceState) {
    case 'HasAdmittance':
    case 'HasEskzi':
      return true
    default:
      return false
  }
}

export default (props) => {
  const { 
    profile,
    skziId,
    versionId,
    name,
    selfServiceState, 
    skzi, 
    builds, 
    onCourceAssign,
    onExamRequest,
    inProgressAction,
  } = props
  
  const { name: skziName, description } = skzi || {}
  const [changed, setChanged] = useState(null)
  const [downloadCount, setDownloadCount] = useState(0)
  const [downloadText, setDownloadText] = useState('')
  const [lastBuild, setLastBuild] = useState(null)
  const {
    sorting: buildSort,
    sortListAction: buildSortAction,
  } = useTableExtensions({
    defaultSort: {column: 'id', direction: sortDirection.desc },
  });

  const {
    sorting: coursesSort,
    sortListAction:  courseSortAction,
  } = useTableExtensions({
    defaultSort: {column: 'changeDate', direction: sortDirection.desc },
  });

  // логика обновления данных после скачивания\установки
  useEffect(() => {
    const localFunc = async () => { 
      if (downloadCount !== 0) {
        // актуализируем список билдов
        lastBuild && setChanged({deleted: [lastBuild.id]})
  
        // обновляем последний билд
        inProgressAction(true)
        const response = await service(
                          'versionService', 
                          'getSelfServiceBuilds', 
                          {
                            skziId,
                            versionId,
                            column: 'id',
                            direction: sortDirection.desc,
                            page: 1,
                            pageSize: 1
                          })
        const {data, isError} = response || {}
        const [responseLastBuild] = data || []
        
        if (!isError) {
          setLastBuild(responseLastBuild)
          setDownloadText(responseLastBuild ? `Скачать ${skziName} ${name} (${responseLastBuild.name})` : '')
        } else {
          setLastBuild(null)
          setDownloadText('')
        }
  
        inProgressAction(false)
      } else {
        const last = builds.slice().sort((a, b) => b.id - a.id)[0]
        setLastBuild(last)
        setDownloadText(last ? `Скачать ${skziName} ${name} (${last.name})` : '')
      }
    }
    localFunc()
  }, [downloadCount])

  return (
    <ExpandingBlock
      className='skzi-version-list__skzi-version'
      renderHeader= {() => `${skziName} ${name}`}
    >
      <div className="skzi-version manage-panel">
        <TrainingButton
          selfServiceState={selfServiceState}
          versionId={versionId}
          onCourceAssign={onCourceAssign}
          onExamRequest={onExamRequest}
        />
        {downloadButtonVisible({selfServiceState, lastBuild})? (
          <DownloadButton
            lastBuild={lastBuild}
            versionId={versionId}
            downloadText={downloadText}
            inProgressAction={inProgressAction}
            afterDowloadAction={() => setDownloadCount(downloadCount + 1)}
          />) : null}
      </div>
      <TextArea 
        className='skzi-version-description'
        value={description} 
        label={'Описание'}
        readOnly={true}
      />
      <ExpandingBlock
        className='skzi-version-buils'
        renderHeader= {() => `Обучающие курсы`}
      >
        <Table
          loadData = {({page, pageSize}) => service('courseService', 'getVersionCourses', {skziId,versionId, ...coursesSort, page, pageSize})}
          dependencies = {[coursesSort]}
          sortListAction = {courseSortAction}
          sorting = {coursesSort} 
          fieldsConfig = { courseColumns }
          onItemClick = {(item) => {
            const courseRights = getResourceRights(profile, RESOURCES.course)
            return courseRights.VIEW_CARD ? `/catalog/trainingCourses/card/${item.id}` : '';
          }}
        />
      </ExpandingBlock>
      <ExpandingBlock
        className='skzi-version-buils'
        renderHeader= {() => `Сборки`}
      >
        <Table
          loadData = {({page, pageSize}) => service('versionService', 'getSelfServiceBuilds', {skziId, versionId, ...buildSort, page, pageSize})}
          dependencies = {[buildSort]}
          changedRows = {changed}
          sortListAction = {buildSortAction}
          sorting = {buildSort} 
          fieldsConfig = { buildColumns }
          onItemClick = {(item) => {
            const buildRights = getResourceRights(profile, RESOURCES.build)
            return buildRights.VIEW_CARD
            ? `/skziRegistry/selfService/skzi/${skziId}/version/${versionId}/build/${item.id}?skziName=${encodeURI(skziName)}`
            : ''
          }}
        />
      </ExpandingBlock>
    </ExpandingBlock>
  )
}
