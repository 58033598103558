import { 
  ERROR, 
  CLEARERROR,
  FIELDSERROR, 
  ERRORPAGEERROR,
  ESKZICOMMONDATARESETSTATE, 
  ESKZICARDGET,
  ESKZICOMMONDATAINPROGRESS, 
  ESKZICOMMONDATASETERRORBYKEY, 
  ESKZICOMMONDATADELETE,
  ESKZICOMMONDATAUPDATEFORM, 
  ESKZICOMMONDATAONDELETE,
  ESKZICOMMONDATAUPDATE,
  ESKZICOMMONDATAONCANCEL,
  ESKZICARDRUNEVENT,
  ESKZICOMMONDATAGETBUILDDATA
} from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { mapDataToLines }  from 'app/components/skzi/Eskzi/instanceData/commonData/constants'

const defaultState = {
  formData:{
    expirationDate: null,
    isStandard: false,
    serialNumbers: [],
    skziBuildId: null,
    status: ''
  },
  data: {

  },
  confirmObject: null,
  errorObject: null,
  successObject: null,
  inProgress: false,
  errorByFields: {},
  fileData: null
}

export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { data, formData } = state
    const { serialNumbers } = formData
    const { formData: defaultFormData } = defaultState

    switch (action.type) {
      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case ESKZICOMMONDATAUPDATE:
      case ESKZICARDGET:
        return {
          ...state,
          formData: mapDataToLines(payload),
          data: mapDataToLines(payload)
        }

      case ESKZICOMMONDATADELETE:
        return {
          ...state,
          successObject: {
            type: dialogType.success,
            title: `Экземпляр успешно удален`
          },
          inProgress: false
        }

      case ESKZICOMMONDATAONDELETE:
          return {
            ...state,
            confirmObject: {
              type: dialogType.confirm,
              title: `Вы уверены, что хотите удалить экземпляр?`
            },
            inProgress: false
          }

      case ESKZICOMMONDATASETERRORBYKEY:
        const { errorByFields } = state

        return {
          ...state,
          errorByFields: { ...errorByFields, ...payload }
        }

      case ESKZICOMMONDATAUPDATEFORM: 
        const { key, value } = payload

        if (key === 'serialNumber') {
          return {
            ...state,
            formData: {
              ...formData, 
              serialNumbers: [...serialNumbers.filter(item => item.id !== value.id), { ...serialNumbers.find(item => item.id === value.id), number: value.value}],
              [value.id]: value.value
            }
          }
        }

        if (key === 'skziBuildId') {
          const { content = []} = value || {}
          return {
            ...state,
            formData: {
              ...defaultFormData, 
              serialNumbers:content.map(item => ({...item, value: ''})),
              [key]: value
            }
          }
        }
        
        return {
          ...state,
          formData: {
            ...formData, 
            [key]: value
          }
        }

      case ESKZICOMMONDATAGETBUILDDATA:
        return {
          ...state,
          fileData: payload,
          inProgress: false
        }

      case ESKZICOMMONDATAINPROGRESS: 
        return {
          ...state,
          inProgress: payload
        }

      case ESKZICOMMONDATARESETSTATE: 
        return {
          ...defaultState,
          data: data,
          formData: formData
        }

      case ESKZICOMMONDATAONCANCEL:
        return {
          ...state,
          data: data,
          formData: { ...data }
        }

      case ESKZICARDRUNEVENT:
        return {
          ...state,
          formData: mapDataToLines(payload),
          data: mapDataToLines(payload),
          inProgress: false
        }
        
      case FIELDSERROR:
        const errorByField = errors.reduce((acc, item) => {
          const { field } = item
          
          return { ...acc, [field]: item }
        }, {})

        return {
          ...state,
          errorByFields: errorByField,
          inProgress: false
        }

      case ERROR:
        return {
          ...state,
          errorObject: {
            error: errors[0],
            type: dialogType.warning,
          },
          inProgress: false
        }

      case CLEARERROR: 
        return {
          ...state,
          confirmObject: null,
          errorObject: null,
          successObject: null,
          inProgress: false,
          errorByFields: {}
        }

      default: 
          return state
    }
}