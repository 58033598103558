import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';
import * as serviceWorker from './serviceWorker';
import "style/main.scss";

// HACK Небольшой костыль, предотвращающий крах приложения при запуске
// после одного из обновлений react-scripts:
// см. https://github.com/facebook/create-react-app/issues/12212
// Вероятно, в будущем можно будет удалить.
if (!('process' in window)) {
  // @ts-ignore
  window.process = {}
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
