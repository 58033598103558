/** @format */

import Overlay from 'app/components/ui/overlay';
import BusinessEventForm from './businessEventForm';
import { compose } from 'redux';
import withSignature from 'app/components/HOC/withSignatureHOC';
import withModal from 'app/components/HOC/ObsoleteModalHOC';

const BusinessEventFormWithSign = compose(withSignature, withModal)(BusinessEventForm);

function BusinessEventModal(props) {
  const { modalAlert, modalBody, className, logic } = props;

  const { items, viewModel, modalVisible, inProgress, afterSignCallback } = logic;
  const { code, name } = viewModel || {};

  return (
    <div className={`business-event-modal business-event-modal--${code} ${className || ''}`}>
      {inProgress ? <Overlay /> : null}
      {modalVisible ? (
        <BusinessEventFormWithSign
          {...logic}
          items={items || []}
          signType="FinishOneClickAction"
          signHeader={name}
          formBody={modalBody}
          formAlert={modalAlert}
          className={`business-event-modal__form business-event-modal__form--${code}`}
          renderHeader={() => <div className="modal__header">{name}</div>}
          actionCallback={afterSignCallback}
        />
      ) : null}
    </div>
  );
}

export default BusinessEventModal;
