import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  ORGANIZATIONCREATE,
  ORGANIZATIONCREATEUPDATEFORMDATA,
  ORGANIZATIONCREATEINPROGRESS    ,
  ORGANIZATIONCREATERESETACTION   ,
  ORGANIZATIONCREATECLEARFORM 
} from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'

const defaultState = {
  formData : {
    name: '',
    shortName: '',
    ogrn: '',
    inn: '',
    kpp: '',
    code: '',
    okz: false,
    description: ''
  },
  registeredInfoObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { formData } = state
    
  switch (action.type) {

    case ORGANIZATIONCREATE:
      const { name, shortName } = payload
      
      return {
          ...state,
          registeredInfoObject: {
            type: dialogType.navigation,
            title: `Организация «${shortName || name}» успешно создана`
          },
          inProgress: false
      }

    case FIELDSERROR:
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData,
        inProgress: false
      }

    case ORGANIZATIONCREATEUPDATEFORMDATA: 
      const { key, value } = payload

      return {
        ...state,
        formData: {...formData, [key]: value }
      }

    case ORGANIZATIONCREATECLEARFORM: 
      return {
        ...defaultState
      }

    case ORGANIZATIONCREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case ORGANIZATIONCREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        registeredInfoObject: null,
        error: null
      }

    default: 
      return state
  }
}