import useToast from "app/components/ui/toast/useToast"
import { formatDate } from "app/core/utility/date"
import service from "app/services/service"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { dispatchLogic } from "redux/actions/common"
import { setUpdatedEvent } from "redux/actions/notification"

const getServiceMethod = (eventType, entityDiscriminator) => {
  switch (eventType) {
    case 'BulkEvent':
      switch (entityDiscriminator) {
        case 'KeyDocument':
          return 'getKeyDocumentMassData'
        case 'Eskzi':
          return 'getEskziMassData'
        case 'KeyCarrier':
          return 'getKeyCarrierMassData'
        default:
          return null
      }
    case 'ReplicateToOkz':
      return 'getOKZReplicationData'
    case 'ReplicateToOki':
      return 'getOKIReplicationData'
    default:
      return null
  }
}

const getOkiEventData = (data) => {
  const {
    transmittalLetterNumber,
    transmittalLetterDate,
    eskzi,
    replicationItems,
    error,
  } = data || {}
  return {
    etalon: eskzi || {},
    transmittalLetterDate: transmittalLetterDate || null,
    transmittalLetterNumber: transmittalLetterNumber || '',
    replicationItems,
    error,
  }
}

const getOkzEventData = data => data

const getMassOperationData = data => data

export function useReplicationResult(props){
  const { 
    id,
    onCancel,
    eventType,
    entityDiscriminator
  } = props

  const {addToast} = useToast()
  const [ inProgress, setProgress ] = useState(false)
  const dispatch = useDispatch()
  const [ eventData, setEventData ] = useState(null)

  const getExemplarSignRequestData = formData => {
    const {
      etalon,
      organization,
      transmittalLetterDate,
      transmittalLetterNumber,
      replicationItems,
      uid
    } = formData || {}
    const { id } = organization || {}
    const { id: eskziId } = etalon || {}

    return {
      organizationId: id,
      transmittalLetterNumber: transmittalLetterNumber,
      transmittalLetterDate: formatDate(transmittalLetterDate, 'yyyy-mm-dd'),
      replicationItems: replicationItems.map(({ organization, copiesNumberFact, licenses }) =>
        ({
          organizationId: organization?.entity?.id,
          copiesNumber: copiesNumberFact,
          licenses: licenses.filter(lic => lic.isSuccessful).map(lic => lic?.entity?.id)
        })),
      eskziId: eskziId,
      uid,
      signatureRequestType: 'resignOKI' // нужно для выбора апи подписи
    }
  }

  const getEtalonSignRequestData = formData => {
    const {
      replicationItems,
      eskzi,
      uid
    } = formData || {}
    
    const { id: eskziId } = eskzi || {}

    return {
      replicationItems: replicationItems.map(item => {
        const { organization, licenses } = item
        const { entity } = organization || {}
        const { id } = entity || {}

        return {
          organizationId: id,
          licenses: licenses
                    .filter(lic => lic.isSuccessful)
                    .map(lic => lic.entity.id)
        }
      }),
      eskziId: eskziId,
      uid,
      signatureRequestType: 'resignOKZ' // нужно для выбора апи подписи
    }
  }

  const getActionSignRequestData = ({data, successEntities, uid, type, eventTemplateId}) => {
    const values = Object.keys(data).reduce((acc, cur) => {
      acc[cur] = data[cur].value
      return acc
    }, {})

    return {
      ...values,
      eventTemplateId,
      uid,
      journalObjects: successEntities,
      signatureRequestType: `resign${type}`// нужно для выбора апи подписи в src\app\services\signatureService.ts
    }
  }

  const resignData = async (signedData, serviceName, methodName) => {
    setProgress(true)
    const response = await service(serviceName, methodName, {uid: id, ...signedData}) 
    
    const {isError, isWarn, errors, data} = response || {}

    if (!isError && !isWarn) {
      addToast('Успешно')
      onCancel()
      dispatch(setUpdatedEvent(data))
    } else {
      dispatchLogic(dispatch, 'MOCK', data, isError, errors)
    }

    setProgress(false)
    return response
  } 

  const resignEtalonCallback = async (signedData) => {
    resignData(signedData, 'replicationService', 'resignReplicateOKZ')
  }
  
  const resignExemplarCallback = async (signedData) => {
    resignData(signedData, 'replicationService', 'resignReplicateOKI')
  }

  const resignActionCallback = async (signedData) => {
    resignData({type: entityDiscriminator, ...signedData}, 'oneClickActionService', 'resignMassOperation')
  }

  const serviceMethod = getServiceMethod(eventType, entityDiscriminator)

  useEffect(() => {
    const local = async () => {
      setProgress(true)
      const result = await service('notificationService', serviceMethod, id)
      const { data, isError, errors } = result
      const [ error ] = errors || []
      const { errorMessage } = error || {}

      const getDataFunc = eventType === 'BulkEvent'
                          ? getMassOperationData
                          : eventType === 'ReplicateToOki'
                            ? getOkiEventData
                            : getOkzEventData

      !isError && setEventData(getDataFunc(data))
      if (isError) {
        addToast(errorMessage || 'Ошибка получения данных')
        onCancel()
      }
      setProgress(false)
    }

    local()
  }, [])

  return {
    resignExemplarCallback,
    getExemplarSignRequestData,
    getEtalonSignRequestData,
    getActionSignRequestData,
    resignEtalonCallback,
    resignActionCallback,
    inProgress,
    eventData
  }
}