import React from 'react'

import Input from 'app/components/ui/Input'
import TextArea from 'app/components/ui/textArea'
import { notEmpty } from 'app/core/utility/validation'

export const cardLines = {
  name: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { editValue, onChange, error } = props

      return (
        <Input
          className='version-name'
          label = 'Наименование'
          value={editValue}
          onChange={value => onChange('name', value)}
          error={error}
          isRequired
        />
      )
    },
  },
  description: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { editValue, onChange, error } = props

      return (
        <TextArea 
          className='version-description'
          label='Описание'
          value={editValue} 
          error={error}
          onChange={value => onChange('description', value)}
        />
      )
    },
  },
}