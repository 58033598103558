/** @format */

import { useState, useEffect } from 'react';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import CompoundForm from './compoundForm';
import RequisitesForm from './requisitesForm';
import BusinessEventPanel from '../businessEventPanel/CertificatePanel';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import styles from './certificate.module.scss';
import service from 'app/services/service';
import { Border } from 'app/components/ui/managePanel';
import Overlay from 'app/components/ui/overlay';

export default function CertificateData(props) {
  const { certificateId, keyDocumentId, CAUser = false } = props;
  const [cardData, setCardData] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const initCertificateCardData = async () => {
    setInProgress(true);
    // TODO: keyDocumentId - временный костыль, нужный до объединения arm и УЦ, в дальнейшем должен остаться 1 api
    let response;
    if (keyDocumentId) {
      response = await service('certificateService', 'getArmCertificate', {
        keyDocumentId: keyDocumentId,
      });
    } else {
      response = await service('certificateService', CAUser ? 'getCACertificate' : 'getCertificate', {
        certificateId,
      });
    }
    setInProgress(false);
    const { data, isError } = response;
    setCardData(isError ? null : { ...data });
  };

  useEffect(() => {
    initCertificateCardData();
  }, []);

  // TODO: Проверка на certificateId временный костыль, нужный до объединения arm и УЦ, в дальнейшем должен остаться 1 api
  return (
    <>
      {inProgress && <Overlay />}
      <ExpandingBlock renderHeader={() => 'Реквизиты'} initialState={true} className={styles.expand}>
        <ManagePanel borders={Border.Bottom}>
          <BusinessEventPanel
            certificate={cardData}
            CAUser={CAUser}
            keyDocumentId={keyDocumentId}
            onRefresh={initCertificateCardData}
            onProgress={setInProgress}
          />
        </ManagePanel>
        {cardData && (
          <RequisitesForm
            {...cardData}
            keyDocumentId={keyDocumentId}
            onFileDelete={initCertificateCardData}
            onProgress={setInProgress}
          />
        )}
      </ExpandingBlock>
      {certificateId && (
        <ExpandingBlock renderHeader={() => 'Состав'} initialState={true} className={styles.expand}>
          <CompoundForm {...cardData} />
        </ExpandingBlock>
      )}
    </>
  );
}
