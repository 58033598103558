import React from 'react'
import withTooltip from '../HOC/TooltipHOC'
import ButtonComponent from 'app/components/ui/button/button'
import Img from '../ui/Img'
import styles from './filter.module.scss'
import Image from 'app/components/ui/Img/imgMap'
import { getClassName } from 'app/core/utility/jsx'
const Button = withTooltip(ButtonComponent)

export default function FilterTriggerPanel({
  toggle,
  disabledClear,
  disabledToggle,
  onClearFilter,
  filterFilled,
}) {
  return (
    <div className={styles.filterToggle}>
      <Button
        type = 'image'
        onClick = {toggle}
        disabled={disabledToggle}
        tooltip='Расширенный поиск'
        className={getClassName([
          {[styles.filterHasValues]: filterFilled}
          ])}
      >
        <Img img={Image.Search} className='button-image'/>
      </Button>
      <Button 
        type = 'image'
        onClick = {onClearFilter}
        disabled={disabledClear}
        tooltip='Очистить фильтр'
      >
        <Img img={Image.Broom} className='button-image'/>
      </Button>
    </div>
  )
}
