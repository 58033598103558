import Group from 'app/components/ui/managePanel/group';
import ReplicateButton from 'app/components/skzi/Eskzi/replication/replicationButton';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { EventCode, EndpointType, ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import { Image } from 'app/components/ui/Img';
import JournalDownload from 'app/components/skzi/EskziList/JournalDownload';
import SendButton from './sendButton';
import Complectation from 'app/components/list/TopPanel/Complectation';
import {Delete} from "app/components/list/TopPanel/Delete";
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';
import DestructionFormBody from '../../DestructionFormBody';
import { Type as MassImportType } from 'app/components/MassImports/enums';
import MassImportButton from 'app/components/MassImports/MassImportButton';
import ExportToExcelButton from 'app/components/exportToExcelButton';
import LicenseType from 'app/core/types/licenseType';

const sendBusinessEvents = [
  {name: "Передача в Орган криптографической защиты"
  , eventCode: 'OkzReceiving'},
  {name: "Передача Обладателю конфиденциальной информации", eventCode: 'OkiReceiving'}
]

export default function BusinessEventPanel(props) {
  const { 
    globalOrgId,
    items,
    afterAction,
    filterForRequest,
    sorting,
    fieldsVisible,
    setSelectedAction,
    onInProgress,
    rights,
    massImportRights,
    licenseState={},
  } = props

  const [ selectedItem ] = items || []
  const { isStandard, replicationAllowed } = selectedItem || {}
  const disabled = items.length !== 1
                    || items[0].bizLocked
                    || !( isStandard && replicationAllowed )

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service('skziAccountingService', 'deleteRange', items.map(i => i.id));
    onInProgress(false);
    return response;
  };

  return (
    <>
      {rights.CREATE ? 
        <Group>
          <ReplicateButton
            initialState={selectedItem}
            disabled={disabled || !licenseState[LicenseType.eskzi]}
            afterAction={afterAction}
          />
        </Group> : null
      }
      <Group>
        <SendButton
          items={items}
          className='send-button'
          title='Передать'
          actionList={sendBusinessEvents}
          disabled={false}
          endpointType={EndpointType.skziAccounting}
          entityType ={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturn]}
          tooltip='Вернуть'
          endpointType={EndpointType.skziAccounting}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.sendingOk}
          tooltip='Подтвердить получение'
          endpointType={EndpointType.skziAccounting}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.returnOk}
          tooltip='Подтвердить возврат'
          endpointType={EndpointType.skziAccounting}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.destruction}
          tooltip='Уничтожение'
          endpointType={EndpointType.skziAccounting}
          formBody={DestructionFormBody}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.Extermination}
          formAlert={'При уничтожении объектов будут уничтожены все связанные элементы.'}
        />
      </Group>
      <Group>
        <Complectation            
          titul='Комплектация'
          items = { items }  
        />    
      </Group>
      <Group>
        <JournalDownload
          items ={items}
        />
      </Group>
      <Group>
        { massImportRights.IMPORT && <MassImportButton type={MassImportType.OKZ_KEY_ESKZI} tooltip="Загрузка экземпляров СКЗИ из файла" /> }
        <ExportToExcelButton
          id={globalOrgId}
          listFilterForRequest={filterForRequest}
          sorting={sorting}
          listFieldsVisibility={fieldsVisible}
          selected={items.map(i=>i.id)}
          serviceName='skziAccountingService'
          onStart={() => onInProgress(true)}
          onFinish={() => onInProgress(false)}
        />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction = {afterAction}
          setSelectedAction = {setSelectedAction}
          dialog ={data => {
              const arr = ['выбранный экземпляр', 'выбранные экземпляры', 'выбранные экземпляры']
              return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)} СКЗИ?`
          }}
        />
      </Group>
    </>
  )
}