/** @format */

import Input from "app/components/ui/Input";
import DatePicker from "app/components/ui/date";
import TextArea from "app/components/ui/textArea";

import styles from "./certificate.module.scss";

export default function CompoundForm(props) {
  const {
    version = null,
    validFrom = null,
    validFor = null,
    serialNumber = null,
    subject = null,
    issuerName = null,
    signatureAlgorithm = null,
    hashAlgorithm = null,
    keyUsage = null,
    templateInfo = null,
    subjectKeyId = null,
    authorityKeyId = null,
    publicKey = null,
    enhancedKey = null,
    usagePolicy = null,
    keyUsagePeriod = null,
    crlDistributionPoints = null,
    authorityInfoAccess = null,
    thumbprintAlgorithm = null,
    thumbprint = null,
  } = props;

  return (
    <div className={styles.compound}>
      <Input
        className="card-field certificate-data__version"
        label="Версия"
        value={version}
        readOnly
      />
      <DatePicker
        className="card-field certificate-data__from"
        label="Действителен с"
        readOnly
        value={!!validFrom ? new Date(validFrom) : null}
      />
      <DatePicker
        className="card-field certificate-data__to"
        label="Действителен по"
        readOnly
        value={!!validFor ? new Date(validFor) : null}
      />
      <Input
        className="card-field certificate-data__serialNumber"
        label="Серийный номер"
        value={serialNumber}
        readOnly
      />
      <Input
        className="card-field certificate-data__subject"
        label="Субъект"
        value={subject}
        readOnly
      />
      <TextArea
        className="card-field certificate-data__publisher"
        label="Издатель"
        value={issuerName}
        readOnly
      />
      <Input
        className="card-field certificate-data__signatureAlgorithm"
        label="Алгоритм подписи"
        value={signatureAlgorithm}
        readOnly
      />
      <Input
        className="card-field certificate-data__signatureHashingAlgorithm"
        label="Алгоритм хэширования подписи"
        value={hashAlgorithm}
        readOnly
      />
      <Input
        className="card-field certificate-data__keyUsage"
        label="Использование ключа"
        value={keyUsage}
        readOnly
      />
      <Input
        className="card-field certificate-data__templateInfo"
        label="Сведения о шаблоне"
        value={templateInfo}
        readOnly
      />
      <Input
        className="card-field certificate-data__subjectKeyId"
        label="Идентификатор ключа субъекта"
        value={subjectKeyId}
        readOnly
      />
      <TextArea
        className="card-field certificate-data__certCenterKeyId"
        label="Идентификатор ключа центра сертификатов"
        value={authorityKeyId}
        readOnly
      />
      <TextArea
        className="card-field certificate-data__openKey"
        label="Открытый ключ"
        value={publicKey}
        readOnly
      />
      <Input
        className="card-field certificate-data__improvedKey"
        label="Улучшенный ключ"
        value={enhancedKey}
        readOnly
      />
      <TextArea
        className="card-field certificate-data__applicationPolicy"
        label="Политики применения"
        value={usagePolicy}
        readOnly
      />
      <Input
        className="card-field certificate-data__privateKeyPeriod"
        label="Период использования закрытого ключа"
        value={keyUsagePeriod}
        readOnly
      />
      <TextArea
        className="card-field certificate-data__crl"
        label="Точки распространения списков отзыва (CRL)"
        value={crlDistributionPoints}
        readOnly
      />
      <TextArea
        className="card-field certificate-data__certCenters"
        label="Доступ к информации в центрах сертификации"
        value={authorityInfoAccess}
        readOnly
      />
      <Input
        className="card-field certificate-data__printAlgorithm"
        label="Алгоритм отпечатка"
        value={thumbprintAlgorithm}
        readOnly
      />
      <Input
        className="card-field certificate-data__print"
        label="Отпечаток"
        value={thumbprint}
        readOnly
      />
    </div>
  );
}
