import Input from 'app/components/ui/Input'
import DatePicker from 'app/components/ui/date'
import DropDown, {YesNo} from 'app/components/ui/DropDown'
import { CardState } from 'app/components/ui/card/cardMaterial'

import { notEmpty } from 'app/core/utility/validation'

export const cardLines = {
  name: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field card-name'
          label = 'Наименование'
          readOnly={cardState === CardState.view}
          value={editValue}
          onChange={value => onChange && onChange('name', value)}
          error={error}
          isRequired
        />
      )
    },
  },
  isActive: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange } = props

      const objectValue = editValue ? YesNo[0] :  YesNo[1]
      return (
        <DropDown
          className='card-field card-is-active'
          readOnly={cardState === CardState.view}
          items={YesNo}
          active={objectValue}
          label='Активен'
          onSelect={value => onChange('isActive', value.id === YesNo[0].id)}
        />
      )
    },
  },
  changeDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <DatePicker
          className='card-field card-change-date'
          label='Дата изменения' 
          readOnly={cardState === CardState.view}
          error={error}
          value={editValue} 
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },    
}