import React from 'react'
import NotificationItem from './notificationItem'

export default function NotificationList(props) {
  const { list = [], onClickElementHandler } = props

  return (
    <div className='notification-list'>
      {list.map(item => {
        return <NotificationItem {...item} onClickElement={onClickElementHandler}/>
      })}
    </div>
  )
}