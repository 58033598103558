/** @format */
import { useEffect } from 'react';
import { Table as TableComponent } from 'app/components/list/Table/Table';
import withInfiniteScroll from 'app/components/HOC/InfiniteScrollHOC'
import useTableExtensions from '../useTableExtensions';
import service from 'app/services/service';
import { SelectType } from 'app/components/ui/picker/picker';
const InfiniteScrollTable = withInfiniteScroll(TableComponent);

/**
 * Таблица с логикой (бесконечный скролл, выбор элементов, сортировка и т.д.)
 * Нужен для рефакторинга - использования таблицы в классовых компонентах 
 */
export default function TableWithLogic({
  list,
  apiPayload = {},
  serviceName,
  methodName,
  onItemClick,
  defaultSort,
  columns,
  changedRows,
  dependencies,
  setSelected,
  setSorting,
  selectType,
  mapResultFunc,
  onListChanged
}) {
  const { sorting, selectedItems, selectedObjects, setSelectedAction, setSelectedObjects, sortListAction } =
    useTableExtensions({
      defaultSort: defaultSort,
    });

  const Table = serviceName && methodName ? InfiniteScrollTable : TableComponent;

  useEffect(() => {
    setSelected && setSelected(selectedObjects);
  }, [selectedObjects.length]);
  
  useEffect(() => {
    setSorting && setSorting(sorting);
  }, [sorting]);

  return (
    <Table
      list={list}
      loadData={({ page, pageSize }) => service(serviceName, methodName, { ...apiPayload, ...sorting, page, pageSize })}
      mapResultFunc={mapResultFunc}
      sortListAction={sortListAction}
      sorting={sorting}
      selectedItems={selectedItems}
      selectedObjects={selectedObjects}
      setSelectedAction={setSelectedAction}
      setSelectedObjects={setSelectedObjects}
      dependencies={dependencies ? [sorting, ...dependencies] : [sorting]}
      changedRows={changedRows}
      fieldsConfig={columns}
      onItemClick={onItemClick}
      onListChanged={onListChanged}
      itemsSelectable={selectType === SelectType.multiple}
      itemsSelectableRadio={selectType === SelectType.single}
    />
  );
}
