/** @format */
import { Table } from 'app/components/list/Table/Table';
import Overlay from 'app/components/ui/overlay';
import { sortDirection } from 'app/components/ui/constants';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import { useJournal } from 'app/components/journals/useJournal';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from 'app/components/ui/managePanel';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import MainListContainer from 'app/components/ui/MainListContainer/MainListContainer';
import Filter from 'app/components/filter';
import { fieldsConfig, filtersConfig } from './pageConfig';
import CreateRequest from './createRequest';
import styles from './list.module.scss';

export default function UserList() {
  const {
    baseTableLogic,
    itemSelectionLogic,
    paginationLogic,
    sortingLogic,
    filterLogic,
    profile,
    rights,
    inProgress,
    setSelectedPagination,
    refreshPage,
  } = useJournal({
    defaultSort: {
      column: 'creationDate',
      direction: sortDirection.desc,
    },
    serviceName: 'certificateRequestService',
    resource: RESOURCES.certRequest,
  });

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title="Ключевые документы : Запросы на сертификат" Img={imgMap.KeyDocument} />
      <ManagePanel borders={Border.All}>
        {rights.CREATE && (
          <Group>
            <CreateRequest afterCreationCallback={refreshPage} />
          </Group>
        )}
      </ManagePanel>
      <Filter
        compactClassName={styles.filter}
        {...{
          ...filterLogic,
          profile,
          inProgress,
          filtersConfig,
        }}
      />
      <Table
        {...{
          ...baseTableLogic,
          ...itemSelectionLogic,
          ...sortingLogic,
          setSelectedPagination,
          inProgress,
          fieldsConfig,
          itemsSelectable: true,
          headerInfo: true,
          onItemClick: null,
        }}
      />
      <Pagination {...paginationLogic} />
    </MainListContainer>
  );
}

