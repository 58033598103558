/** @format */
import { useState } from 'react';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import { Table as TableComponent } from 'app/components/list/Table/Table';
import Overlay from 'app/components/ui/overlay';
import ActionPanel from './ActionPanel';
import useCertificationBlock from './useCertificationBlock';
import service from 'app/services/service';
import { useParams } from 'react-router-dom';
import withInfiniteScroll from 'app/components/HOC/InfiniteScrollHOC'
const Table = withInfiniteScroll(TableComponent);

export default function CertificationBlock({ inProgress: inProgressParent }) {
  const [changed, setChanged] = useState(null);

  const { tableLogic, inProgress, setInProgress, buildColumns, certificationsTypes, onFileUploaded } =
    useCertificationBlock({ setChanged });
  const { skziId, versionId, buildId } = useParams();
  const { sorting, selectedObjects, setSelectedObjects } = tableLogic;

  return (
    <>
      {inProgress && !inProgressParent && <Overlay />}
      <ExpandingBlock
        className="skzi-build-certificates"
        renderHeader={() => 'Сертификаты соответствия'}
        initialState={true}
      >
        <ActionPanel
          certificationsTypes={certificationsTypes}
          selected={selectedObjects}
          setInProgress={setInProgress}
          onFileUploaded={onFileUploaded}
          setChanged={setChanged}
          setSelected={setSelectedObjects}
        />
        {skziId && (
          <Table
            {...tableLogic}
            loadData={({ page, pageSize }) =>
              service('versionService', 'getAllCertifications', {
                skziId,
                versionId,
                buildId,
                page,
                pageSize,
                ...sorting,
              })
            }
            dependencies={[sorting]}
            changedRows={changed}
            fieldsConfig={buildColumns}
            itemsSelectable
          />
        )}
      </ExpandingBlock>
    </>
  );
}
