
const getLocalStorageKey = ({
  profile,
  url
}) => {
  const { userInfo } = profile
  const { id } = userInfo || {}

  return `listPageState|${id}|${url}`
}

export const loadPageState = (props) => {
  const data = JSON.parse(localStorage.getItem(getLocalStorageKey(props)))

  return data || null
}

export const savePageState = (value, props) => {
  const newValue = value || null

  localStorage.setItem(getLocalStorageKey(props), JSON.stringify(newValue))
  return newValue
}