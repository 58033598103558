import React, { useState } from 'react';

import CloseUA from 'app/components/userAccount/CloseUA/CloseUA';
import { DownloadButton } from 'app/components/list/TopPanel/DownloadButton';
import DownloadButtonGet from 'app/components/ui/button/downloadXlsButton'
import ButtonComponent, { buttonTypes } from '../../../ui/button/button';
import withTooltip from '../../../HOC/TooltipHOC';
import { Delete } from '../../../list/TopPanel/Delete';
import { stringPlural } from 'app/core/utility/common';
import { useListActionPanel } from './../../useListActionPanel';
import { Group } from "app/components/ui/managePanel";
import ImgMap from 'app/components/ui/Img/imgMap';
import service from 'app/services/service';
import { useHistory } from "react-router-dom";

const Button = withTooltip(ButtonComponent)
const WithTooltipButton = withTooltip(ButtonComponent);

export const BusinessEventPanel = ({
  rights,
  inProgress,
  onInProgress,
  selectedItems,
  fieldsVisible,
  afterAction,
  currentUA,
  selectedUA,
}) => {
  const onItemChanged = (item) => {
    return afterAction(item.id)
  }
  const {
    approveUA,
    reverseApprovingUA,
  } = useListActionPanel({
    onItemChanged,
    onInProgress,
    currentUA,
    selectedUA
  });

  const history = useHistory();
  const [showCloseUADialog, setShowCloseUADialog] = useState(false);

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service('userAccountService', 'deleteRange', selectedItems);
    onInProgress(false);
    return response;
  };

  function afterDeleteActionCallback() {
    history.push( '/userAccounts' ) 
  }

  const sorting = {column: "id", direction: -1}

  return (
    <>
      <Group>
        <DownloadButtonGet
          tooltip={'Скачать отчет по лицевому счету пользователя'}
          items={selectedItems}
          url={'eskziuser/reports/download'}
          Img={ImgMap.AccountDownload}
        />
      </Group>
      <Group>
        <Button
          type={buttonTypes.image}
          onClick={() => setShowCloseUADialog(true)}
          disabled={!(rights.UPDATE && currentUA && currentUA.canBeClosed)}
          tooltip='Закрыть'
        >
          <ImgMap.Close />
        </Button>
        <Button
          type="image"
          onClick={approveUA}
          disabled={!(rights.UPDATE && currentUA && currentUA.canBeApproved)}
          tooltip='Подтвердить'
        >
          <ImgMap.Acceptation />
        </Button>
        <Button
          type="image"
          onClick={reverseApprovingUA}
          disabled={!(rights.UPDATE && currentUA && currentUA.canBeReversed)}
          tooltip='Отменить'
        >
          <ImgMap.AcceptationOfReturn />
        </Button>
      </Group>
      <Group>
        <DownloadButton
          listFieldsVisibility ={fieldsVisible}
          sorting={sorting}
          selected = {selectedItems}
          titul="Выгрузить в Excel"
          type="DownloadButton"
          inProgress = { inProgress }
          serviceName = 'userAccountService'
        />
      </Group>
      <Group>
        <Delete
          items = {selectedItems} 
          dialog ={data => {
            const arr = ['выбранный лицевой счет', 'выбранные лицевые счета', 'выбранные лицевые счета']
            return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`
          }}
          afterAction = { afterDeleteActionCallback }
          deleteItemsAction = {deleteActionCallback}
          setSelectedAction = {()=>{}}
          onInProgress = { onInProgress }
        />
      </Group>
      {showCloseUADialog && (
        <CloseUA
          currentUAId={currentUA.id}
          onInProgress={onInProgress}
          onItemChanged={onItemChanged}
          onDone={() => setShowCloseUADialog(false)}
          onCancel={() => setShowCloseUADialog(false)}
        />
      )}
    </>
  )
}

