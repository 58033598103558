import {
  CARD_UPDATE_FORM_DATA,
  CARD_ON_CANCEL,
  CARD_IN_PROGRESS,
  CARD_GET,
  CARD_UPDATE,
  CARD_RESET,
  CARD_ADD_FIELDSET,
  CARD_REMOVE_FIELDSET, CARD_UPDATE_VIEW_DATA, CARD_UPDATE_FORM_FIELD
} from '../actionTypes'

import {dispatchLogic} from '../common'


export const initCardAction = data => dispatch => {
  dispatch({
    type: CARD_GET,
    payload: data
  })
}


export const getCardAction = request => dispatch => {
  request
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, CARD_GET, data, isError, errors)
    })
}


export const updateCardAction = (request, callback = e => e) => dispatch => {
  request
    .then(response => {
      const { data, errors, isError, payload } = response;
      dispatchLogic(dispatch, CARD_UPDATE, data, isError, errors)
      if (payload && !errors && !isError) {
        dispatch(updateViewDataAction(payload))
      }
      return response
    }).then(callback)
}

export const cardResetAction = () => dispatch => {
  dispatch({
    type: CARD_RESET,
    payload: {}
  })
}

export const updateViewDataAction = (payload) => dispatch => {
  dispatch({
    type: CARD_UPDATE_VIEW_DATA,
    payload: payload
  })
}

export const cardInProgressAction = isInProgress => dispatch => {
  dispatch({
    type: CARD_IN_PROGRESS,
    payload: isInProgress
  })
}

export const cardOnCancelAction = () => dispatch => {
  dispatch({
    type: CARD_ON_CANCEL,
    payload: null
  })
}

export const cardRemoveFieldsetAction = (fieldsetName, index) => dispatch => {
    dispatch({
        type: CARD_REMOVE_FIELDSET,
        payload: {fieldsetName, index}
    })
}

export const cardAddFieldsetAction = (fieldsetName, value) => dispatch => {
    dispatch({
        type: CARD_ADD_FIELDSET,
        payload: {fieldsetName, value}
    })
}

export const updateCommonFormCardAction = (formData) => dispatch => {
  dispatch({
    type: CARD_UPDATE_FORM_DATA,
    payload: {
      ...formData
    }
  })
}

export const updateCommonFormFieldCardAction = (formData) => dispatch => {
  dispatch({
    type: CARD_UPDATE_FORM_FIELD,
    payload: formData
  })
}