import React, { useState, useEffect } from 'react'
import Toast from "./Toast";

export default function ToastList(props){

  const { toastList } = props;

  return(
    <div className="notification-container top">
      {
        toastList.map((toast) => (
          <Toast
            key={toast.id}
            toast={toast}
          />
        ))
      }
    </div>
  )
}