import React, { Component } from 'react'

import FileUploadControl from 'app/components/ui/file/fileUploadControl'
import { fileListAPI } from 'app/core/utility/file'
import DropDown from 'app/components/ui/DropDown'
import Button from 'app/components/ui/button/button'
import { SKZIBUILDCARDTYPEADD } from 'redux/actions/actionTypes'
import { fileControlState } from 'app/components/ui/file/fileUploadControl'
import { ContentComponentType } from '../enums'
import Autocomplete, { Services } from 'app/components/ui/autocomplete/autocomplete'

export const Mode = {
  Add: 0,
  Edit: 1,
};

const cancelText = {
  [fileControlState.preUpload] : 'Отмена',
  [fileControlState.uploading] : 'Закрыть',
  [fileControlState.uploadFinished] : 'Завершить'
}

const getHeaderText = (mode, state) => {
  const headers = {
    [fileControlState.preUpload] : {
      [Mode.Add]: 'Добавление элемента комплекта',
      [Mode.Edit]: 'Элемент комплекта',
    },
    [fileControlState.uploading] : 'Загрузка файлов' ,
    [fileControlState.uploadFinished] : 'Загрузка файлов' 
  };
  
  return typeof headers[state] === 'string' ? headers[state] : headers[state][mode];
}

export default class addTypeDialog extends Component {
  constructor(props){
    super(props)
    
    const { typeToEdit } = props;
    const { objectType } = typeToEdit || {};

    this.state = { 
      currentType: objectType ? { ...objectType, title: objectType.name } : null,
      dialogState: fileControlState.preUpload,
      allFilesProgressComplete: false,
      files: []
    }
    this.fileControl = React.createRef();
  }

  _onTypeSave = async () => {
    const { mode, onTypeAdd, onCancel } = this.props
    const { currentType, files } = this.state
    const { current: fileControl } = this.fileControl
    
    const getTypeId = mode === Mode.Add
      ? async () => {
        const result = await onTypeAdd(currentType, files.length)
        const { data, isError } = result
        const [ addedType ] = data || []
        const { objectType } = addedType || {}
        const { id } = objectType || {}
        return { typeId: id, isError }
      }
      : () => {
        return { typeId: currentType.id, isError: false }
      }
      
    const { typeId, isError } = await getTypeId();

    if (!isError && fileControl) {
      this.setState({ typeId, dialogState: fileControlState.uploading }, () => {
        fileControl._delete()
        fileControl._update()
        fileControl._upload()
        onCancel()
      })
    }
  }

  _onCancel = async () => {
    const { onCancel } = this.props
    const { dialogState } = this.state
    const { current } = this.fileControl
    onCancel()
  }

  _onFileChange = (files) => {
    this.setState({files: files})
  }

  _uploadFinished = (result) => {
    const { onTypeAddFinish } = this.props
    const { typeId } = this.state

    this.setState({ dialogState: fileControlState.uploadFinished })
    onTypeAddFinish({id: typeId, result})
  }
  
  _updateFinished = (result) => {
    const { onFileUpdateFinish } = this.props;
    const { typeId } = this.state;    
    onFileUpdateFinish && onFileUpdateFinish({id: typeId, result});
  }
  
  _deleteFinished = (result) => {
    const { onFileDeleteFinish } = this.props;
    const { typeId } = this.state;
    onFileDeleteFinish && onFileDeleteFinish({id: typeId, result});
  }

  _allFilesProgressComplete = () => {
    this.setState({ allFilesProgressComplete: true })
  }

  _renderTypeData = () => {
    const { 
      types = [], 
      isFirstType,
      mode,
      currentTypes
    } = this.props
    const { currentType } = this.state

    return (
      <>
        <Autocomplete
          label='Тип элемента'
          serviceType={Services.objectTypes}
          onSelect={value => this.setState({currentType: value})}
          value={currentType}
          excludeItems={currentTypes}
          disabled={mode === Mode.Edit}
        />
        {isFirstType ? (
          <span className='add-type-dialog__is-main'>Первый элемент, добавленный в комплектацию, автоматически становится "Основным" (серийный номер этого элемента будет номером экземпляра). Чтобы выбрать другой элемент в качестве основного, выберите его в списке элементов и нажмите на кнопку "Сделать основным".</span>
        ) : null}
      </>
    )
  }

  render() {
    const { 
      buildParams,
      mode,
      typeToEdit,
    } = this.props
    const { currentType, dialogState, allFilesProgressComplete } = this.state
    const { id } = currentType || {}
    const { skziId, versionId, buildId } = buildParams
    const urlPart = `skzi/${skziId}/version/${versionId}/build/${buildId}/file`
    const cancelButtonText = allFilesProgressComplete && dialogState == fileControlState.uploading ? 'Завершение...' : cancelText[dialogState]

    const fileApi = fileListAPI(
      {
        objectTypeId: id
      },
      urlPart
    )

    return (
      <div className="add-type-dialog">
        <div className="add-type-dialog__header">
          { getHeaderText(mode, dialogState) }
        </div>
        {dialogState === fileControlState.preUpload ? (
          this._renderTypeData()
        ) : null}
        <FileUploadControl
          ref={this.fileControl}
          className='add-type-dialog__file-area'
          title='Добавить вложение'
          dialogState={dialogState}
          uploadedFiles={typeToEdit ? typeToEdit.files : []}
          fileApi={fileApi}
          disabled={!currentType}
          withHashSums={currentType && (currentType.name === ContentComponentType.InstallationPackage)}
          onFileChanged={this._onFileChange}
          // onUploadFinish={this._uploadFinished}
          onFileUploaded={this._uploadFinished}
          onFileUpdated={this._updateFinished}
          onFileDeleted={this._deleteFinished}
          onAllFilesProgressComplete={this._allFilesProgressComplete}
        />
        <div className="add-type-dialog__buttons-panel">
          {dialogState === fileControlState.preUpload ? (
            <Button 
              type='text'
              onClick={this._onTypeSave}
              disabled={!currentType}
            >
              <span className='button-title'>{ mode === Mode.Add ? 'Добавить' : 'Сохранить' }</span>
            </Button>
          ) : null}
          <Button 
            type='text'
            onClick={this._onCancel}
            disabled={allFilesProgressComplete && dialogState == fileControlState.uploading}
          >
            <span className='button-title'>{cancelButtonText}</span>
          </Button>
        </div>
      </div>
    )
  }
}
