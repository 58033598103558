/** @format */
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import React, { useState } from 'react';
import Filter from 'app/components/filter';
import NotificationList from './notificationList';
import useNotification from './useNotification';
import { ReactComponent as BellImg } from 'assets/img/commonVer2/ic_notify.svg';
import Overlay from 'app/components/ui/overlay';
import ReplicationResult from './notificationDetail/bulkOperationResult';
import MainListContainer from 'app/components/ui/MainListContainer/MainListContainer';
import PageHeader from 'app/components/PageHeader/pageHeader';
import TotalCount from 'app/components/list/Table/TotalCount';
import filtersConfig from './filtersConfig';
import styles from './notification.module.scss';

export default function AllNotifications(props) {
  const [selectedItem, setSelectedItem] = useState(null);
  const { inProgress, list, totalCount, pagination, filterLogic, profile } = useNotification();

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title="Список уведомлений" Img={BellImg} className={styles.header} />
      <Filter
        compactClassName={styles.filter}
        {...{
          ...filterLogic,
          profile,
          inProgress,
          filtersConfig,
        }}
      />
      <TotalCount totalCount={totalCount} />
      <NotificationList list={list} onClickElementHandler={setSelectedItem} />
      <Pagination {...pagination} />
      {selectedItem && (
        <ReplicationResult data={selectedItem} onCancel={() => setSelectedItem(null)} />
      )}
    </MainListContainer>
  );
}
