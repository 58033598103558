import { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import {cardLines} from '../card/maintenanceData'
import SignatureDialog, { isDigitalSignNeeded } from 'app/components/signature/SignatureDialog'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import Card from 'app/components/ui/card/cardMaterial'
import { formatDate } from 'app/core/utility/date'

import withAuth from 'app/components/HOC/AuthHOC'
import breadcrumbsHOC from 'app/components/HOC/BreadcrumbsHOC'
import { capitalize, serverlikeErrors } from 'app/core/utility/common'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import * as createMaintenanceActions from 'redux/actions/maintenance/createMaintenanceAction'
import * as errorActions from 'redux/actions/common'
import * as signatureActions from 'redux/actions/common/signatureAction'

import { MAINTENANCECREATE } from 'redux/actions/actionTypes'

class createMaintenance extends Component {

  UNSAFE_componentWillMount () {
    const {
      createMaintenanceInitFormData,
      profile
    } = this.props
    const { userInfo } = profile

    createMaintenanceInitFormData({
      performerId: userInfo,
      issueDate: new Date()
    })
  }

  componentWillUnmount () {
    this._onClearForm()
  }

  _onCancel = () => {
    const { history } = this.props
    this._onClearForm()

    history.push('/acts/maintenance')
  }

  _onSubmit = async () => {
    const { profile, createMaintenance } = this.props
    const { formData } = createMaintenance

    if (isDigitalSignNeeded(profile, 'EskziUsageCertificates')) {
      this._onSignDialog(formData)
    } else {
      this._registerWithoutSign(formData)
    }
  }

  _onSignDialog = (data) => {
    const { 
      setRequestData, 
      generateText, 
      createMaintenanceInProgressAction
    } = this.props

    setRequestData({...data});
    createMaintenanceInProgressAction(true)
    generateText(this._getSignatureRequestData(data), 'maintenanceCreate');
  }

  _registerWithoutSign = async (data) => {
    const { 
      createMaintenanceInProgressAction,
      registerMaintenance,
    } = this.props
    const requestData = {
      data: this._getSignatureRequestData(data),
      signature: null
    }

    createMaintenanceInProgressAction(true)
    const createResult = await registerMaintenance(requestData)
    this._finishCreation(createResult)
  }

  _afterSign = async () => {
    const { 
      signature,
      createMaintenanceInProgressAction,
      registerMaintenance,
      createMaintenance
    } = this.props
    const { formData } = createMaintenance
    const { textForSignature, signatureResult } = signature
    
    const signatureToServer = {
      data: this._getSignatureRequestData(formData),
      signature: { data: textForSignature, signedData: signatureResult }
    }

    createMaintenanceInProgressAction(true)
    const createResult = await registerMaintenance(signatureToServer)
    this._finishCreation(createResult)
  }

  _getSignatureRequestData = (data) => {
    const { 
      eskziId,
      number,
      hardwareNumber,
      printNumber,
      distributiveRemovingDate,
      performerId,
      issueDate
    } = data
    const { id: skziId } = eskziId || {}
    const { id: userId } = performerId || {}
    
    return { 
      number,
      hardwareNumber,
      printNumber,
      issueDate: formatDate(issueDate, 'yyyy-mm-dd'),
      distributiveRemovingDate: formatDate(distributiveRemovingDate , 'yyyy-mm-dd'),
      performerId: userId,
      eskziId: skziId
    }
  }

  _finishCreation = (createResult) => {
    const { 
      breadcrumbsPopAction, 
      history 
    } = this.props
    const { payload, type } = createResult || {}
    const { id } = payload || {}

    if (type === MAINTENANCECREATE) {
      breadcrumbsPopAction()  
      history.push(`/acts/maintenance/card/${id}`)
    }
  }

  _updateFormData = (key, value) => {
    const { createMaintenanceUpdateFormData, setErrorByKey, error: { errorByFields } } = this.props
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))

    createMaintenanceUpdateFormData({key, value})
    setErrorByKey(filteredErrors)
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey && setErrorByKey(serverlikeErrors(errors))
  }

  _onClearForm = () => {
    const { maintenanceCreateClearForm } = this.props

    maintenanceCreateClearForm()
  }

  _renderModal = () => {
    const { 
      error, 
      clearErrorAction,
      resetSignature,
      signature,
      profile
    } = this.props
    const { textForSignature } = signature || {}
    const { errorObject } = error || {}
    const { error: err, title: errorTitle, type: errorType } = errorObject || {}

    return (
      <Fragment>
        {textForSignature ? (
          <SignatureDialog
            className={'signature-form'}
            compact
            profile={profile}
            onCancel={resetSignature}
            afterSignCallback={this._afterSign}
            renderHeader = {() => {
              return (
                <div className='sign__header'>
                  {'Добавление заключения об эксплуатации'}
                </div>
              )
            }}
        />) :null}
        {errorObject ?
          <ConfirmDialog
            title={errorTitle}
            type={errorType}
            error={err}
            onCancel={clearErrorAction}
          /> : null
        }
      </Fragment>
    )
  }

  _renderData = () => {
    const { createMaintenance, profile, error } = this.props
    const { formData } = createMaintenance
    const { fieldsError } = error || {}

    return (
      <section className='maintenance-common-data'>
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты'}
          initialState={true}
        >
          <Card
            className='maintenance-data-card'
            profile={profile}
            formLines={cardLines}
            editOnOpen={true}
            formData={formData}
            errorByFields={fieldsError}
            onValidationError={this._onValidation}
            onChange={this._updateFormData}
            onSubmit={this._onSubmit}
            onCancel={this._onCancel}
          />
        </ExpandingBlock>
      </section>
    )
  }

  render() {
    const { createMaintenance } = this.props
    const { inProgress } = createMaintenance

    return (
      <section className='create-maintenance'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <BreadcrumbsHeader />
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...breadcrumbsActions,
    ...createMaintenanceActions,
    ...errorActions,
    ...signatureActions
  })(withAuth(breadcrumbsHOC(createMaintenance)))