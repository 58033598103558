import React from 'react'
import LoginField from './LoginField'
import SubmitButton from './SubmitButton'

export default function PasswordRecovery(props) {
  const { login, mailSuccess, loginError, onRecoveryPasswordSubmit, onLoginChange } = props

  return mailSuccess ? (
    <span className='mail-success'>
      Вам на почту отправлено письмо со ссылкой для восстановления пароля
    </span>
  ) : (
    <>
      <span className='mail-success'>
        Введите логин, и мы отправим письмо для восстановления пароля на Вашу электронную почту.
      </span>
      <LoginField
        onChange={onLoginChange}
        login={login}
        error={loginError}
        labelText="Логин"
        onSubmit={onRecoveryPasswordSubmit}
      />
      <SubmitButton
        label='Отправить'
        onSubmit={onRecoveryPasswordSubmit}
      />
    </>
  )
}