import React from 'react'

const Tab = props =>  {
  const { tabTitle, activeTab, children } = props

  return (tabTitle === activeTab ? (
    <div key={tabTitle} className='tab-container'>
      {children}
    </div>
    ) : null
  )
}

export default Tab