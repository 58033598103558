import { ReactComponent as DownloadImg } from 'assets/img/commonVer2/excel.svg';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button'
import { downloadFilePOST } from 'app/core/serverRequest/request';
import { downloadFile } from 'app/core/utility/file';
import withTooltip from 'app/components/HOC/TooltipHOC';

const Button = withTooltip(ButtonComponent)

export default function downloadXlsButton(props) {
  const { 
    url, 
    items,
    itemIdMapper = x => x?.id ?? x,
    data, 
    isPostDownload = false, 
    inProgressAction,
    tooltip = '',
    Img,
    disabled,
    buttonText
  } = props  

  if (isPostDownload) {
    return (
      <Button
        tooltip={!buttonText && tooltip}
        type={buttonTypes.image}
        onMouseDown={async () => {
          const { blob, fileName } = await downloadFilePOST(url, data)
          await downloadFile({blob, fileName})
          inProgressAction && inProgressAction(false)
        }}
        caption={buttonText}
      >
        <DownloadImg className='button-image'></DownloadImg>
      </Button>
    )
  }

  const canDownload = !disabled && items.length
  const dowloadUrl = `api/v1/${url}?id=${items.map(itemIdMapper).join('&id=')}`

  return (
      <Button
        tooltip={!buttonText && tooltip}
        type={buttonTypes.image}
        disabled={!canDownload}
        href={canDownload ? dowloadUrl : ''}
        caption={buttonText}
      >
        {Img && <Img className='button-image'></Img>}
        {!Img && <DownloadImg className='button-image'></DownloadImg>}
      </Button>
  )
}