import React, {useState} from 'react'
import Input, { InputType } from 'app/components/ui/Input'
import { ReactComponent as CrossedEye} from '../../../assets/img/commonVer2/crossed-eye-v2.svg'
import { ReactComponent as Eye} from '../../../assets/img/commonVer2/eye-v2.svg'


export default function PasswordField(props) {
  const {
    labelText,
    value,
    error,
    onChange,
    onSubmit,
    id,
    name
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const crosseEye = () => {
    return (
      <CrossedEye
        id={id}
        className='password-eye'
        onClick={() => setShowPassword(false)}
      />
    )
  }

  const eye = () => {
    return (
      <Eye
        id={id}
        className='password-eye'
        onClick={() => setShowPassword(true)}
      />
    )
  }

  return (
    <div className='input-element'>
      <Input
        id={ id || 'password' }
        name={ name || 'password' }
        className='input-element__input'
        placeholder={value}
        value={value}
        error={error}
        onChange={onChange}
        label={labelText}
        onSubmit={onSubmit}
        inputType={showPassword ? InputType.text : InputType.password}
        CustomImage={showPassword ? crosseEye : eye}
      />
    </div>
  )
}
