
// license & authorization
export const AUTHORIZE = 'AUTHORIZE';
export const UPDATEPROFILE = 'UPDATEPROFILE';

export const AUTHORIZEERROR = 'AUTHORIZEERROR';
export const RUNAUTHORIZE = 'RUNAUTHORIZE';
export const FINISHAUTHORIZE = 'FINISHAUTHORIZE';
export const LOGOUT = 'LOGOUT';
export const TRIALHIDE = 'TRIALHIDE';

// breadcrumbs
export const BREADCRUMBS = 'BREADCRUMBS'
export const BREADCRUMBSPUSHITEM = 'BREADCRUMBS.PUSH.ITEM'
export const BREADCRUMBSPOPITEM = 'BREADCRUMBS.POP.ITEM'
export const BREADCRUMBSCLICK = 'BREADCRUMBS.CLICK'
export const BREADCRUMBS_CLEAR = 'BREADCRUMBS.CLEAR'

// files
export const FILESUPLOADSTART = 'FILES.UPLOAD.START'
export const FILESUPLOADPROGRESS = 'FILES.UPLOAD.PROGRESS'
export const FILESUPLOADCOMPLETE  = 'FILES.UPLOAD.COMPLETE'
export const FILESUPLOADFILERESULT  = 'FILES.UPLOAD.FILE.RESULT'
export const FILESUPLOADONDIALOGTOGGLE  = 'FILES.UPLOAD.ON.DIALOG.TOGGLE'

// license upload
export const LICENSEUPLOADSETRESULT = 'LICENSE.UPLOAD.SET.RESULT'
export const LICENSEUPLOADPROGRESS = 'LICENSE.UPLOAD.PROGRESS'
export const LICENSEUPLOADCOMPLETE  = 'LICENSE.UPLOAD.COMPLETE'
export const LICENSEUPLOADSETFORMDATA  = 'LICENSE.UPLOAD.SET.FORM.DATA'
export const LICENSEUPLOADRESET  = 'LICENSE.UPLOAD.RESET'


// errors
export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const CLEARERROR = 'CLEAR.ERROR';
export const FIELDSERROR = 'FIELDSERROR';
export const ERRORLIST = 'ERROR.LIST';

// error page 
export const ERRORPAGEERROR = 'GLOBAL.ERROR';
export const ERRORPAGECLEARERROR = 'GLOBAL.ERROR.CLEAR.ERROR';

// skziInstance 
export const SKZIINSTANCEGETALL = 'MAIN.SKZIINSTANCEGETALL';
export const SKZIINSTANCEPAGINATIONGET = 'MAIN.SKZIINSTANCEPAGINATIONGET';
export const SKZIINSTANCEADD = 'MAIN.SKZIINSTANCEADD';
export const SKZIINSTANCEDELETE = 'MAIN.SKZIINSTANCEDELETE';
export const SKZIINSTANCEONDELETE = 'MAIN.SKZIINSTANCEONDELETE';
export const SKZIINSTANCEEDIT = 'MAIN.SKZIINSTANCEEDIT';
export const SKZIINSTANCEONEDIT = 'MAIN.SKZIINSTANCEONEDIT';
export const SKZIINSTANCEONCREATE = 'MAIN.SKZIINSTANCEONCREATE';
export const SKZIINSTANCEEDITERROR = 'MAIN.SKZIINSTANCEEDITERROR';
export const SKZIINSTANCECREATEERROR = 'MAIN.SKZIINSTANCECREATEERROR';
export const SKZIINSTANCEERROR = 'MAIN.SKZIINSTANCEERROR';
export const SKZIINSTANCECLEARERROR = 'MAIN.SKZIINSTANCECLEARERROR';
export const SKZIINSTANCEINPROGRESS = 'MAIN.SKZIINSTANCEINPROGRESS';
export const SKZIINSTANCERESETACTION = 'MAIN.SKZIINSTANCERESETACTION';
export const SKZIINSTANCEONOKZREPORT = 'MAIN.SKZIINSTANCEONOKZREPORT';
export const SKZIINSTANCEONOKIREPORT = 'MAIN.SKZIINSTANCEONOKIREPORT';
export const SKZIINSTANCERUNEVENT = 'MAIN.SKZIINSTANCE.RUN.EVENT';
export const SKZIINSTANCEONRUNEVENT = 'MAIN.SKZIINSTANCE.ON.RUN.EVENT';
export const SKZIINSTANCEEVENTFORMCHANGED = 'MAIN.SKZIINSTANCE.EVENT.FORM.CHANGED';
export const SKZIINSTANCEGETBUILDDATA = 'MAIN.SKZIINSTANCE.GET.BUILD.DATA';

// eskziCard
export const ESKZICARDGET = 'CARD.ESKZICARD.GET';
export const ESKZICARDUPDATED = 'CARD.ESKZICARD.UPDATED';
export const ESKZICARDTABCLICK = 'CARD.ESKZICARD.TAB.CLICK';
export const ESKZICARDINPROGRESS = 'CARD.ESKZICARD.IN.PROGRESS';
export const ESKZICARDSETERRORBYKEY = 'CARD.ESKZICARD.SET.ERROR.BY.KEY';
export const ESKZICARDESKZICARDGETEVENTS = 'CARD.ESKZICARD.GET.EVENTS';
export const ESKZICARDONRUNEVENT = 'CARD.ESKZICARD.ON.RUN.EVENT';
export const ESKZICARDRESETSTATE = 'CARD.ESKZICARD.RESET.STATE';
export const ESKZICARDEVENTFORMCHANGED = 'CARD.ESKZICARD.EVENT.FORM.CHANGED';
export const ESKZICARDRUNEVENT = 'CARD.ESKZICARD.RUN.EVENT';
  // service tab
  export const ESKZICARDSERVICEGETACTIONS   = 'CARD.ESKZICARD.SERVICE.GETACTIONS';
  export const ESKZICARDGETLICENSES   = 'CARD.ESKZICARD.GET.LICENSES';
  export const ESKZICARDADDLICENSES   = 'CARD.ESKZICARD.ADD.LICENSES';
  export const ESKZICARDDELETELICENSES   = 'CARD.ESKZICARD.DELETE.LICENSES';
  export const ESKZICARDSERVICEUPDATEACTION = 'CARD.ESKZICARD.SERVICE.UPDATE.ACTION';
  export const ESKZICARDSERVICEADDACTION    = 'CARD.ESKZICARD.SERVICE.ADD.ACTION';
  export const ESKZICARDSERVICEONUPDATEACTION = 'CARD.ESKZICARD.SERVICE.ON.UPDATE.ACTION';
  export const ESKZICARDSERVICEONADDACTION    = 'CARD.ESKZICARD.SERVICE.ON.ADD.ACTION';
  export const ESKZICARDSERVICEDELETEACTION = 'CARD.ESKZICARD.SERVICE.DELETE.ACTION';
  export const ESKZICARDSERVICEONDELETEACTION = 'CARD.ESKZICARD.SERVICE.ON.DELETE.ACTION';
  export const ESKZICARDSERVICERESETACTION = 'CARD.ESKZICARD.SERVICE.RESET.ACTION';
  export const ESKZICARDSERVICEONADDFORMCHANGEDACTION = 'CARD.ESKZICARD.SERVICE.ON.ADD.FORM.CHANGED.ACTION';
  export const ESKZICARDSERVICEONUPDATEFORMCHANGEDACTION = 'CARD.ESKZICARD.SERVICE.ON.UPDATE.FORM.CHANGED.ACTION';
  
//eskzi events
export const ESKZIEVENTSUPDATETASK = 'CARD.ESKZIEVENTS.UPDATE.TASK';
export const KEYDOCUMENTSUPDATETASK = 'CARD.KEYDOCUMENTS.UPDATE.TASK';

// eskzi registration
export const ESKZIREGISTRATION = 'CARD.ESKZI.REGISTRATION';
export const ESKZIUPDATEFORMDATA = 'CARD.ESKZI.UPDATE.FORM.DATA';
export const ESKZIREGISTRATIONRESETSTATE = 'CARD.ESKZI.REGISTRATION.RESET.STATE';
export const ESKZIREGISTRATIONINPROGRESS = 'CARD.ESKZI.REGISTRATION.IN.PROGRESS';
export const ESKZIREGISTRATIONSETERRORBYKEY = 'CARD.ESKZI.REGISTRATION.SET.ERROR.BY.KEY';
export const ESKZIREGISTRATIONCLEARFORM = 'CARD.ESKZI.REGISTRATION.CLEAR.FORM';

//eskzi commonData

export const ESKZICOMMONDATAGET = 'CARD.ESKZI.COMMONDATA.GET';
export const ESKZICOMMONDATAGETINFO = 'CARD.ESKZI.COMMONDATA.GET.INFO';
export const ESKZICOMMONDATAUPDATE = 'CARD.ESKZI.COMMONDATA.UPDATE';
export const ESKZICOMMONDATARESETSTATE = 'CARD.ESKZI.COMMONDATA.RESET.STATE';
export const ESKZICOMMONDATAONCANCEL = 'CARD.ESKZI.COMMONDATA.ON.CANCEL';
export const ESKZICOMMONDATAINPROGRESS = 'CARD.ESKZI.COMMONDATA.IN.PROGRESS';
export const ESKZICOMMONDATASETERRORBYKEY = 'CARD.ESKZI.COMMONDATA.SET.ERROR.BY.KEY';
export const ESKZICOMMONDATADELETE = 'CARD.ESKZI.COMMONDATA.DELETE';
export const ESKZICOMMONDATAONDELETE = 'CARD.ESKZI.COMMONDATA.ON.DELETE';
export const ESKZICOMMONDATAUPDATEFORM = 'CARD.ESKZI.COMMONDATA.UPDATE.FORM';
export const ESKZICOMMONDATAGETBUILDDATA = 'CARD.ESKZI.COMMONDATA.GET.BUILD.DATA';

// skzi registry list form
export const  SKZIREGISTRYGETALL         =  'MAIN.SKZIREGISTRY.GETALL'
export const  SKZIREGISTRYGETSKZIDATA    =  'MAIN.SKZIREGISTRY.GET.SKZI.DATA'
export const  SKZIREGISTRYDELETE         =  'MAIN.SKZIREGISTRY.DELETE'
export const  SKZIREGISTRYINPROGRESS     =  'MAIN.SKZIREGISTRY.INPROGRESS'
export const  SKZIREGISTRYRESETACTION    =  'MAIN.SKZIREGISTRY.RESETACTION'
export const  SKZIREGISTRYCLEARSTOREACTION    =  'MAIN.SKZIREGISTRY.CLEAR.STORE.ACTION'
export const  SKZIREGISTRYONSELECT       =  'MAIN.SKZIREGISTRY.ON.SELECT'
export const  SKZIREGISTRYONDELETE       =  'MAIN.SKZIREGISTRY.ONDELETE'
export const  SKZIREGISTRYPAGINATIONGET  =  'MAIN.SKZIREGISTRY.PAGINATIONGET'
export const  SKZIREGISTRYADDBUILDOBJECTTYPE  =  'MAIN.SKZIREGISTRY.ADD.BUILD.OBJECT.TYPE' 
export const  SKZIREGISTRYDELETEBUILDOBJECTTYPE  =  'MAIN.SKZIREGISTRY.DELETE.BUILD.OBJECT.TYPE' 
export const  SKZIREGISTRYSETMAINBUILDOBJECTTYPE  =  'MAIN.SKZIREGISTRY.SET.MAIN.BUILD.OBJECT.TYPE' 


// skzi registry create form
export const SKZICREATE                 = 'CREATE.SKZI.CREATE'
export const SKZICREATEUPDATEFORMDATA   = 'CREATE.SKZI.UPDATEFORMDATA'
export const SKZICREATEINPROGRESS       = 'CREATE.SKZI.INPROGRESS'
export const SKZICREATERESETACTION      = 'CREATE.SKZI.RESETACTION'
export const SKZICREATESETERRORBYKEY    = 'CREATE.SKZI.SETERRORBYKEY'
export const SKZICREATECLEARFORM        = 'CREATE.SKZI.CLEARFORM'

// skzi card
export const SKZICARDGET              = 'CARD.SKZI.GET'
export const SKZICARDUPDATE           = 'CARD.SKZI.UPDATE'
export const SKZICARDUPDATEFORMDATA   = 'CARD.SKZI.UPDATEFORMDATA'
export const SKZICARDINPROGRESS       = 'CARD.SKZI.INPROGRESS'
export const SKZICARDSETERRORBYKEY    = 'CARD.SKZI.SETERRORBYKEY'
export const SKZICARDRESETACTION      = 'CARD.SKZI.RESETACTION'

export const SKZICARDGETVERSIONS      = 'CARD.SKZI.GET.VERSIONS'
export const SKZICARDONSELECT      = 'CARD.SKZI.ON.SELECT'

// to do
// remove all files/builds related actionsfrom skzi card
export const SKZICARDCLOSEFILESPICKER   = 'CARD.SKZI.CLOSE.FILES.PICKER'
export const SKZICARDSUBMITFILESPICKER  = 'CARD.SKZI.AFTER.SUBMIT.CLOSE.FILES.PICKER'
export const SKZICARDUPDATED            = 'CARD.SKZI.UPDATED'
export const SKZICARDDELETE             = 'CARD.SKZI.DELETE'
export const SKZICARDONDELETE           = 'CARD.SKZI.ON.DELETE'
export const SKZICARDONADDVERSION       = 'CARD.SKZI.ON.ADD.VERSION'
export const SKZICARDUPDATEVERSIONDATA    = 'CARD.SKZI.UPDATE.VERSION.DATA'
export const SKZICARDUPDATECREATEFORMDATA   = 'CARD.SKZI.UPDATE.CREATE.FORM.DATA'
export const SKZICARDUPDATEEDITVERSIONFORMDATA   = 'CARD.SKZI.UPDATE.EDIT.VERSION.FORM.DATA'
export const SKZICARDVERSIONADD         = 'CARD.SKZI.VERSION.ADD'
export const SKZICARDVERSIONDELETE         = 'CARD.SKZI.VERSION.DELETE'
export const SKZICARDVERSIONONDELETE         = 'CARD.SKZI.VERSION.ON.DELETE'
export const SKZICARDVERSIONONBUILDADD         = 'CARD.SKZI.VERSION.ON.BUILD.ADD'
export const SKZICARDVERSIONBUILDADD         = 'CARD.SKZI.VERSION.BUILD.ADD'
export const SKZICARDUPDATEADDBUILDFORMDATA         = 'CARD.SKZI.UPDATE.ADD.BUILD.FORM.DATA'
export const SKZICARDUPDATEEDITBUILDFORMDATA         = 'CARD.SKZI.UPDATE.EDIT.BUILD.FORM.DATA'
export const SKZICARDBUILDONDELETE         = 'CARD.SKZI.BUILD.ON.DELETE'
export const SKZICARDBUILDONEDIT         = 'CARD.SKZI.BUILD.ON.EDIT'
export const SKZICARDBUILDEDIT         = 'CARD.SKZI.BUILD.EDIT'
export const SKZICARDBUILDDELETE         = 'CARD.SKZI.BUILD.DELETE'
export const SKZICARDBUILDONEDITFILES    = 'CARD.SKZI.BUILD.ON.EDIT.FILES'
export const SKZICARDBUILDONFILESUPDATED    = 'CARD.SKZI.BUILD.ON.FILES.UPDATED'
export const SKZICARDONVERSIONEDIT    = 'CARD.SKZI.ON.VERSION.EDIT'
export const SKZICARDONCANCEL    = 'CARD.SKZI.ON.CANCEL'


// skzi version card
export const SKZIVERSIONCARDGET              = 'CARD.SKZI.VERSION.GET'
export const SKZIVERSIONCARDUPDATE           = 'CARD.SKZI.VERSION.UPDATE'
export const SKZIVERSIONCARDUPDATEFORMDATA   = 'CARD.SKZI.VERSION.UPDATEFORMDATA'
export const SKZIVERSIONCARDINPROGRESS       = 'CARD.SKZI.VERSION.INPROGRESS'
export const SKZIVERSIONCARDSETERRORBYKEY    = 'CARD.SKZI.VERSION.SETERRORBYKEY'
export const SKZIVERSIONCARDRESETACTION      = 'CARD.SKZI.VERSION.RESETACTION'
export const SKZIVERSIONCARDGETVERSIONS      = 'CARD.SKZI.VERSION.GET.VERSIONS'
export const SKZIVERSIONCARDONSELECT         = 'CARD.SKZI.VERSION.ON.SELECT'
export const SKZIVERSIONCARDONCOURSESELECT         = 'CARD.SKZI.VERSION.ON.COURSE.SELECT'

export const SKZIVERSIONCARDUPDATED          = 'CARD.SKZI.VERSION.UPDATED'
export const SKZIVERSIONCARDUPDATEVERSIONDATA    = 'CARD.SKZI.VERSION.UPDATE.VERSION.DATA'
export const SKZIVERSIONCARDUPDATEEDITVERSIONFORMDATA   = 'CARD.SKZI.VERSION.UPDATE.EDIT.VERSION.FORM.DATA'
export const SKZIVERSIONCARDVERSIONONBUILDADD         = 'CARD.SKZI.VERSION.VERSION.ON.BUILD.ADD'
export const SKZIVERSIONCARDVERSIONBUILDADD         = 'CARD.SKZI.VERSION.VERSION.BUILD.ADD'
export const SKZIVERSIONCARDCOURSEADD         = 'CARD.SKZI.VERSION.COURSE.ADD'
export const SKZIVERSIONCARDUPDATEADDBUILDFORMDATA         = 'CARD.SKZI.VERSION.UPDATE.ADD.BUILD.FORM.DATA'
export const SKZIVERSIONCARDBUILDONDELETE         = 'CARD.SKZI.VERSION.BUILD.ON.DELETE'
export const SKZIVERSIONCARDCOURSEONDELETE         = 'CARD.SKZI.VERSION.COURSE.ON.DELETE'
export const SKZIVERSIONCARDBUILDDELETE         = 'CARD.SKZI.VERSION.BUILD.DELETE'
export const SKZIVERSIONCARDCOURSEDELETE         = 'CARD.SKZI.VERSION.COURSE.DELETE'
export const SKZIVERSIONCARDONCANCEL    = 'CARD.SKZI.VERSION.ON.CANCEL'
export const SKZIVERSIONCARDGETVERSIONBUILDS    = 'CARD.SKZI.VERSION.GET.VERSION.BUILDS'
export const SKZIVERSIONCARDGETVERSIONCOURSES    = 'CARD.SKZI.VERSION.GET.VERSION.COURSES'
export const SKZI_VERSION_CARD_ADD = 'SKZI_VERSION_CARD_ADD'
export const SKZI_VERSION_CARD_CLEAR_FORM       = 'SKZI_VERSION_CARD_CLEAR_FORM'


// skzi build card
export const SKZIBUILDCARDGET              = 'CARD.SKZI.BUILD.GET'
export const SKZISELFSERVICEBUILDCARDGET              = 'CARD.SKZI.SELFSERVICE.BUILD.GET'
export const SKZIBUILDCARDUPDATE           = 'CARD.SKZI.BUILD.UPDATE'
export const SKZI_BUILD_CARD_ADD = 'SKZI_BUILD_CARD_ADD'
export const SKZI_BUILD_CARD_CLEAR_FORM       = 'SKZI_BUILD_CARD_CLEAR_FORM'
export const SKZIBUILDCARDUPDATEFORMDATA   = 'CARD.SKZI.BUILD.UPDATEFORMDATA'
export const SKZIBUILDCARDINPROGRESS       = 'CARD.SKZI.BUILD.INPROGRESS'
export const SKZIBUILDCARDSETERRORBYKEY    = 'CARD.SKZI.BUILD.SETERRORBYKEY'
export const SKZIBUILDCARDRESETACTION      = 'CARD.SKZI.BUILD.RESETACTION'
export const SKZIBUILDCARDGETTYPES      = 'CARD.SKZI.BUILD.GET.TYPES'
export const SKZIBUILDCARDSETMAINTYPE      = 'CARD.SKZI.BUILD.SET.MAIN.TYPE'
export const SKZIBUILDCARDONSELECT         = 'CARD.SKZI.BUILD.ON.SELECT'
export const SKZIBUILDCARDUPDATED          = 'CARD.SKZI.BUILD.UPDATED'
export const SKZIBUILDCARDUPDATEBUILDDATA    = 'CARD.SKZI.BUILD.UPDATE.BUILD.DATA'
export const SKZIBUILDCARDUPDATEEDITBUILDFORMDATA   = 'CARD.SKZI.BUILD.UPDATE.EDIT.BUILD.FORM.DATA'
export const SKZIBUILDCARDONTYPEDELETE   = 'CARD.SKZI.BUILD.ON.TYPE.DELETE'
export const SKZIBUILDCARDTYPEDELETE   = 'CARD.SKZI.BUILD.TYPE.DELETE'
export const SKZIBUILDCARDONTYPEADD   = 'CARD.SKZI.BUILD.ON.TYPE.ADD'
export const SKZIBUILDCARDONFILEADD   = 'CARD.SKZI.BUILD.ON.FILE.ADD'
export const SKZIBUILDCARDTYPEADD   = 'CARD.SKZI.BUILD.TYPE.ADD'
export const SKZIBUILDCARDFILEDELETE   = 'CARD.SKZI.BUILD.FILE.DELETE'
export const SKZIBUILDCARDONFILEDELETE   = 'CARD.SKZI.BUILD.ON.FILE.DELETE'

// user account list form
export const  USERACCOUNTGETALL         =  'MAIN.USERACCOUNT.GETALL'
export const  USERACCOUNTDELETE         =  'MAIN.USERACCOUNT.DELETE'
export const  USERACCOUNTINPROGRESS     =  'MAIN.USERACCOUNT.INPROGRESS'
export const  USERACCOUNTRESETACTION    =  'MAIN.USERACCOUNT.RESETACTION'
export const  USERACCOUNTONDELETE       =  'MAIN.USERACCOUNT.ONDELETE'
export const  USERACCOUNTPAGINATIONGET  =  'MAIN.USERACCOUNT.PAGINATIONGET'
export const  USERACCOUNTONREPORTDIALOG  =  'MAIN.USERACCOUNT.ON.REPORT.DIALOG'
export const  USERACCOUNTONCLOSEDIALOG  =  'MAIN.USERACCOUNT.ON.CLOSE.DIALOG'
export const  USERACCOUNTONCLOSE  =  'MAIN.USERACCOUNT.ON.CLOSE'
export const  USERACCOUNTONAPPROVE  =  'MAIN.USERACCOUNT.ON.APPROVE'
export const  USERACCOUNTONREVERSEAPPROVE  =  'MAIN.USERACCOUNT.ON.REVERSEAPPROVE'

// user account create form
export const USERACCOUNTCREATE                 = 'CREATE.USERACCOUNT.CREATE'
export const USERACCOUNTCREATEUPDATEFORMDATA   = 'CREATE.USERACCOUNT.UPDATEFORMDATA'
export const USERACCOUNTCREATEINPROGRESS       = 'CREATE.USERACCOUNT.INPROGRESS'
export const USERACCOUNTCREATERESETACTION      = 'CREATE.USERACCOUNT.RESETACTION'
export const USERACCOUNTCREATECLEARFORM        = 'CREATE.USERACCOUNT.CLEARFORM'

// user account card
export const  USERACCOUNTCARDGET =                    'USERACCOUNT.CARD.GET'

// user create form
export const USERCREATE                 = 'CREATE.USER.CREATE'
export const USERCREATEUPDATEFORMDATA   = 'CREATE.USER.UPDATEFORMDATA'
export const USERCREATEINPROGRESS       = 'CREATE.USER.INPROGRESS'
export const USERCREATERESETACTION      = 'CREATE.USER.RESETACTION'
export const USERCREATECLEARFORM        = 'CREATE.USER.CLEARFORM'

// user card
export const  USERCARDGET =                    'USER.CARD.GET'           
export const  USERCARDUPDATEFORMDATA =         'USER.CARD.UPDATEFORMDATA'
export const  USERCARDINPROGRESS =             'USER.CARD.INPROGRESS'          
export const  USERCARDRESETACTION =            'USER.CARD.RESETACTION'      
export const  USERCARDGETUSERROLESACTION =            'USER.CARD.GET.USER.ROLES.ACTION'       
export const  USERCARDUPDATE =                 'USER.CARD.UPDATE'
export const  USERCARDDELETE =                 'USER.CARD.DELETE'
export const  USERCARDONDELETE =               'USER.CARD.ONDELETE'
export const  USERCARDRESETPASSWORD =          'USER.CARD.RESET.PASSWORD'  
export const  USERCARDBLOCKUSER =             'USER.CARD.BLOCK.USER'  
export const  USERCARDUNBLOCKUSER =          'USER.CARD.UNBLOCK.USER' 
export const  USERCARDROLEUPDATE =             'USER.CARD.ROLE.UPDATE'
export const  USERCARDROLEDELETE =             'USER.CARD.ROLE.DELETE' 
export const  USERCARDROLEADD =                'USER.CARD.ROLE.ADD' 
export const  USERCARDTABCLICK =               'USER.CARD.TABCLICK'
export const  USERCARDONCANCEL =               'USER.CARD.ON.CANCEL'


// key document list form
export const  KEYDOCUMENTGETALL         =  'MAIN.KEYDOCUMENT.GETALL'
export const  KEYDOCUMENTDELETE         =  'MAIN.KEYDOCUMENT.DELETE'
export const  KEYDOCUMENTINPROGRESS     =  'MAIN.KEYDOCUMENT.INPROGRESS'
export const  KEYDOCUMENTRESETACTION    =  'MAIN.KEYDOCUMENT.RESETACTION'
export const  KEYDOCUMENTONDELETE       =  'MAIN.KEYDOCUMENT.ONDELETE'
export const  KEYDOCUMENTPAGINATIONGET  =  'MAIN.KEYDOCUMENT.PAGINATIONGET'
export const  KEYDOCUMENTONREPORTDIALOG  =  'MAIN.KEYDOCUMENT.ON.REPORT.DIALOG'
export const  KEYDOCUMENTONCLOSEDIALOG  =  'MAIN.KEYDOCUMENT.ON.CLOSE.DIALOG'
export const  KEYDOCUMENTONCLOSE  =  'MAIN.KEYDOCUMENT.ON.CLOSE'
export const  KEYDOCUMENTONOKZREPORT  =  'MAIN.KEYDOCUMENT.ON.OKZ.REPORT'
export const  KEYDOCUMENTONOKIREPORT  =  'MAIN.KEYDOCUMENT.ON.OKI.REPORT'
export const  KEYDOCUMENTRUNEVENT  =  'MAIN.KEYDOCUMENT.RUN.EVENT'


// key document create form
export const KEYDOCUMENTCREATE                 = 'CREATE.KEYDOCUMENT.CREATE'
export const KEYDOCUMENTCREATEUPDATEFORMDATA   = 'CREATE.KEYDOCUMENT.UPDATEFORMDATA'
export const KEYDOCUMENTCREATEINPROGRESS       = 'CREATE.KEYDOCUMENT.INPROGRESS'
export const KEYDOCUMENTCREATERESETACTION      = 'CREATE.KEYDOCUMENT.RESETACTION'
export const KEYDOCUMENTCREATESETERRORBYKEY    = 'CREATE.KEYDOCUMENT.SETERRORBYKEY'
export const KEYDOCUMENTCREATECLEARFORM        = 'CREATE.KEYDOCUMENT.CLEARFORM'

// key document  card
export const  KEYDOCUMENTCARDGET =                    'KEYDOCUMENT.CARD.GET'                  
export const  KEYDOCUMENTCARDUPDATEFORMDATA =         'KEYDOCUMENT.CARD.UPDATEFORMDATA'       
export const  KEYDOCUMENTCARDINPROGRESS =             'KEYDOCUMENT.CARD.INPROGRESS'          
export const  KEYDOCUMENTCARDRESETACTION =            'KEYDOCUMENT.CARD.RESETACTION'         
export const  KEYDOCUMENTCARDUPDATE =                 'KEYDOCUMENT.CARD.UPDATE'               
export const  KEYDOCUMENTCARDDELETE =                 'KEYDOCUMENT.CARD.DELETE'               
export const  KEYDOCUMENTCARDONDELETE =               'KEYDOCUMENT.CARD.ONDELETE' 
export const  KEYDOCUMENTCARDTABCLICK =               'KEYDOCUMENT.CARD.TAB.CLICK' 
export const  KEYDOCUMENTCARDGETEVENTS =              'KEYDOCUMENT.CARD.GET.EVENTS';
export const  KEYDOCUMENTCARDONCANCEL =              'KEYDOCUMENT.CARD.ON.CANCEL';
export const  KEYDOCUMENTCARDONRUNACTION =              'KEYDOCUMENT.CARD.ON.RUN.ACTION';
export const  KEYDOCUMENTCARDEVENTFORMCHANGED =              'KEYDOCUMENT.CARD.EVENT.FORM.CHANGED';
export const  KEYDOCUMENTCARDRUNACTION =             'KEYDOCUMENT.CARD.RUN.ACTION'  

//signature
export const SIGNATURESETREQUESTDATA = 'SIGNATURE.SETREQUESTDATA'
export const SIGNATUREREADYTOSIGN = 'SIGNATURE.READYTOSIGN'
export const SIGNEDSUCCESSFULLY = 'SIGNATURE.SUCCESSFULLY'
export const SIGNATURERESET = 'SIGNATURE.RESET'

export const signActions = {
  setSignData : SIGNATURESETREQUESTDATA,
  readyToSign : SIGNATUREREADYTOSIGN,
  setSignResult : SIGNEDSUCCESSFULLY,
  clearSign : SIGNATURERESET,
}

// organization create form
export const ORGANIZATIONCREATE                 = 'CREATE.ORGANIZATION.CREATE'
export const ORGANIZATIONCREATEUPDATEFORMDATA   = 'CREATE.ORGANIZATION.UPDATEFORMDATA'
export const ORGANIZATIONCREATEINPROGRESS       = 'CREATE.ORGANIZATION.INPROGRESS'
export const ORGANIZATIONCREATERESETACTION      = 'CREATE.ORGANIZATION.RESETACTION'
export const ORGANIZATIONCREATESETERRORBYKEY    = 'CREATE.ORGANIZATION.SETERRORBYKEY'
export const ORGANIZATIONCREATECLEARFORM        = 'CREATE.ORGANIZATION.CLEARFORM'

// organization card
export const  ORGANIZATIONCARDGET =                    'ORGANIZATION.CARD.GET'                  
export const  ORGANIZATIONCARDUPDATEFORMDATA =         'ORGANIZATION.CARD.UPDATEFORMDATA'       
export const  ORGANIZATIONCARDINPROGRESS =             'ORGANIZATION.CARD.INPROGRESS'          
export const  ORGANIZATIONCARDRESETACTION =            'ORGANIZATION.CARD.RESETACTION'         
export const  ORGANIZATIONCARDUPDATE =                 'ORGANIZATION.CARD.UPDATE'               
export const  ORGANIZATIONCARDDELETE =                 'ORGANIZATION.CARD.DELETE'               
export const  ORGANIZATIONCARDONDELETE =               'ORGANIZATION.CARD.ONDELETE' 
export const  ORGANIZATIONCARDTABCLICK =               'ORGANIZATION.CARD.TAB.CLICK' 
export const  ORGANIZATIONCARDGETEVENTS =              'ORGANIZATION.CARD.GET.EVENTS';
export const  ORGANIZATIONCARDONCANCEL =               'ORGANIZATION.CARD.ON.CANCEL';

// organization card
  // service organization tab
export const  ORGANIZATIONCARDSERVICEGETALL         =  'ORGANIZATIONCARD.SERVICE.GETALL'
export const  ORGANIZATIONCARDSERVICEDELETE         =  'ORGANIZATIONCARD.SERVICE.DELETE'
export const  ORGANIZATIONCARDSERVICEINPROGRESS     =  'ORGANIZATIONCARD.SERVICE.INPROGRESS'
export const  ORGANIZATIONCARDSERVICERESETACTION    =  'ORGANIZATIONCARD.SERVICE.RESETACTION'
export const  ORGANIZATIONCARDSERVICEONDELETE       =  'ORGANIZATIONCARD.SERVICE.ONDELETE'
// export const  ORGANIZATIONCARDSERVICEPAGINATIONGET  =  'ORGANIZATIONCARD.SERVICE.PAGINATIONGET'
export const  ORGANIZATIONCARDSERVICEONCLOSEDIALOG  =  'ORGANIZATIONCARD.SERVICE.ON.CLOSE.DIALOG'
export const  ORGANIZATIONCARDSERVICEONCLOSE  =  'ORGANIZATIONCARD.SERVICE.ON.CLOSE'

export const  ORGANIZATIONCARDSERVICEGETPICKERALL   =  'ORGANIZATIONCARD.SERVICE.GET.PICKER.ALL'
export const  ORGANIZATIONCARDSERVICEPICKERPICKED   =  'ORGANIZATIONCARD.SERVICE.PICKER.PICKED'
export const  ORGANIZATIONCARDSERVICEPICKERCLOSE  =  'ORGANIZATIONCARD.SERVICE.PICKER.CLOSE'
export const  ORGANIZATIONCARDSERVICEADD          =  'ORGANIZATIONCARD.SERVICE.ADD'

// organization card
  // clients organization tab
  export const  ORGANIZATIONCARDCLIENTSGETALL         =  'ORGANIZATIONCARD.CLIENTS.GETALL'
  export const  ORGANIZATIONCARDCLIENTSGETPICKERALL         =  'ORGANIZATIONCARD.CLIENTS.GET.PICKER.ALL'
  export const  ORGANIZATIONCARDCLIENTSPICKERPICKED         =  'ORGANIZATIONCARD.CLIENTS.PICKER.PICKED'
  export const  ORGANIZATIONCARDCLIENTSPICKERCLOSE         =  'ORGANIZATIONCARD.CLIENTS.PICKER.CLOSE'
  export const  ORGANIZATIONCARDCLIENTSDELETE         =  'ORGANIZATIONCARD.CLIENTS.DELETE'
  export const  ORGANIZATIONCARDCLIENTSINPROGRESS     =  'ORGANIZATIONCARD.CLIENTS.INPROGRESS'
  export const  ORGANIZATIONCARDCLIENTSRESETACTION    =  'ORGANIZATIONCARD.CLIENTS.RESETACTION'
  export const  ORGANIZATIONCARDCLIENTSONDELETE       =  'ORGANIZATIONCARD.CLIENTS.ONDELETE'
  // export const  ORGANIZATIONCARDCLIENTSPAGINATIONGET  =  'ORGANIZATIONCARD.CLIENTS.PAGINATIONGET'
  export const  ORGANIZATIONCARDCLIENTSONCLOSEDIALOG  =  'ORGANIZATIONCARD.CLIENTS.ON.CLOSE.DIALOG'
  export const  ORGANIZATIONCARDCLIENTSADD  =  'ORGANIZATIONCARD.CLIENTS.ADD'

// organization list form
export const  ORGANIZATIONGETALL         =  'MAIN.ORGANIZATION.GETALL'
export const  ORGANIZATIONDELETE         =  'MAIN.ORGANIZATION.DELETE'
export const  ORGANIZATIONINPROGRESS     =  'MAIN.ORGANIZATION.INPROGRESS'
export const  ORGANIZATIONRESETACTION    =  'MAIN.ORGANIZATION.RESETACTION'
export const  ORGANIZATIONONDELETE       =  'MAIN.ORGANIZATION.ONDELETE'
export const  ORGANIZATIONPAGINATIONGET  =  'MAIN.ORGANIZATION.PAGINATIONGET'
export const  ORGANIZATIONONCLOSEDIALOG  =  'MAIN.ORGANIZATION.ON.CLOSE.DIALOG'
export const  ORGANIZATIONONCLOSE  =  'MAIN.ORGANIZATION.ON.CLOSE'

// user list form
export const  USERGETALL         =  'MAIN.USER.GETALL'
export const  USERDELETE         =  'MAIN.USER.DELETE'
export const  USERRESETPASSWORD  =  'MAIN.USER.RESET.PASSWORD'
export const  USERBLOCKUSER  =    'MAIN.USER.BLOCK.USER'
export const  USERUNBLOCKUSER  =  'MAIN.USER.UNBLOCK.USER'
export const  USERINPROGRESS     =  'MAIN.USER.INPROGRESS'
export const  USERRESETACTION    =  'MAIN.USER.RESETACTION'
export const  USERONDELETE       =  'MAIN.USER.ONDELETE'
export const  USERPAGINATIONGET  =  'MAIN.USER.PAGINATIONGET'

// password policy
export const PASSWORDPOLICYGET = 'MAIN.PASSWORD.POLICY.GET'
export const PASSWORDPOLICYUPDATE = 'MAIN.PASSWORD.POLICY.UPDATE'
export const PASSWORDPOLICYUPDATEFORMDATA = 'MAIN.PASSWORD.POLICY.UPDATE.FORMDATA'
export const PASSWORDPOLICYRESETFORMDATA = 'MAIN.PASSWORD.POLICY.RESET.FORMDATA'
export const PASSWORDPOLICYRESET = 'MAIN.PASSWORD.POLICY.RESET'
export const PASSWORDPOLICYINPROGRESS = 'MAIN.PASSWORD.POLICY.IN.PROGRESS'


// training courses list form
export const  TRAININGCOURSESGETALL         =  'MAIN.TRAININGCOURSES.GETALL'
export const  TRAININGCOURSESDELETE         =  'MAIN.TRAININGCOURSES.DELETE'
export const  TRAININGCOURSESINPROGRESS     =  'MAIN.TRAININGCOURSES.INPROGRESS'
export const  TRAININGCOURSESRESETACTION    =  'MAIN.TRAININGCOURSES.RESETACTION'
export const  TRAININGCOURSESCLEARSTOREACTION    =  'MAIN.TRAININGCOURSES.CLEAR.STORE.ACTION'
export const  TRAININGCOURSESONSELECT       =  'MAIN.TRAININGCOURSES.ON.SELECT'
export const  TRAININGCOURSESONDELETE       =  'MAIN.TRAININGCOURSES.ONDELETE'
export const  TRAININGCOURSESPAGINATIONGET  =  'MAIN.TRAININGCOURSES.PAGINATIONGET'

// training courses create form
export const TRAININGCOURSESCREATE                 = 'CREATE.TRAININGCOURSES.CREATE'
export const TRAININGCOURSESCREATEUPDATEFORMDATA   = 'CREATE.TRAININGCOURSES.UPDATEFORMDATA'
export const TRAININGCOURSESCREATEINPROGRESS       = 'CREATE.TRAININGCOURSES.INPROGRESS'
export const TRAININGCOURSESCREATERESETACTION      = 'CREATE.TRAININGCOURSES.RESETACTION'
export const TRAININGCOURSESCREATECLEARFORM        = 'CREATE.TRAININGCOURSES.CLEARFORM'

// training course card
export const TRAININGCOURSECARDGET              = 'CARD.TRAINING.COURSE.GET'
export const TRAININGCOURSECARDUPDATE           = 'CARD.TRAINING.COURSE.UPDATE'
export const TRAININGCOURSECARDUPDATEFORMDATA   = 'CARD.TRAINING.COURSE.UPDATEFORMDATA'
export const TRAININGCOURSECARDINPROGRESS       = 'CARD.TRAINING.COURSE.INPROGRESS'
export const TRAININGCOURSECARDRESETACTION      = 'CARD.TRAINING.COURSE.RESETACTION'
export const TRAININGCOURSECARDGETTYPES      = 'CARD.TRAINING.COURSE.GET.TYPES'
export const TRAININGCOURSECARDSETMAINTYPE      = 'CARD.TRAINING.COURSE.SET.MAIN.TYPE'
export const TRAININGCOURSECARDONSELECT         = 'CARD.TRAINING.COURSE.ON.SELECT'
export const TRAININGCOURSECARDUPDATED          = 'CARD.TRAINING.COURSE.UPDATED'
export const TRAININGCOURSECARDUPDATEBUILDDATA    = 'CARD.TRAINING.COURSE.UPDATE.BUILD.DATA'
export const TRAININGCOURSECARDUPDATEEDITBUILDFORMDATA   = 'CARD.TRAINING.COURSE.UPDATE.EDIT.BUILD.FORM.DATA'
export const TRAININGCOURSECARDONTYPEDELETE   = 'CARD.TRAINING.COURSE.ON.TYPE.DELETE'
export const TRAININGCOURSECARDTYPEDELETE   = 'CARD.TRAINING.COURSE.TYPE.DELETE'
export const TRAININGCOURSECARDONTYPEADD   = 'CARD.TRAINING.COURSE.ON.TYPE.ADD'
export const TRAININGCOURSECARDONFILEADD   = 'CARD.TRAINING.COURSE.ON.FILE.ADD'
export const TRAININGCOURSECARDTYPEADD   = 'CARD.TRAINING.COURSE.TYPE.ADD'
export const TRAININGCOURSECARDFILEDELETE   = 'CARD.TRAINING.COURSE.FILE.DELETE'
export const TRAININGCOURSECARDONFILEDELETE   = 'CARD.TRAINING.COURSE.ON.FILE.DELETE'



// user skzi admittance
export const USERSKZIADMITTANCEGETALLDATA   = 'USER.SKZI.ADMITTANCE.GET.ALL.DATA'
export const USERSKZIADMITTANCESETALLDATA   = 'USER.SKZI.ADMITTANCE.SET.ALL.DATA'
export const USERSKZIADMITTANCEGETADMITTANCE   = 'USER.SKZI.ADMITTANCE.GET.ADMITTANCE'
export const USERSKZIADMITTANCEGETTRAINING     = 'USER.SKZI.ADMITTANCE.GET.TRAINING'
export const USERSKZIADMITTANCEINPROGRESS      = 'USER.SKZI.ADMITTANCE.INPROGRESS'
export const USERSKZIADMITTANCERESETACTION     = 'USER.SKZI.ADMITTANCE.RESETACTION'
export const USERSKZIADMITTANCEONADMITTANCESELECT = 'USER.SKZI.ADMITTANCE.ON.ADMITTANCE.SELECT'
export const USERSKZIADMITTANCEONTRAININGSELECT   = 'USER.SKZI.ADMITTANCE.ON.TRAINING.SELECT'
export const USERSKZIADMITTANCEADDADMITANCE   = 'USER.SKZI.ADMITTANCE.ADD.ADMITANCE'
export const USERSKZIADMITTANCEUPDATEADMITANCE   = 'USER.SKZI.ADMITTANCE.UPDATE.ADMITANCE'
export const USERSKZIADMITTANCEDELETEADMITANCE   = 'USER.SKZI.ADMITTANCE.DELETE.ADMITANCE'
export const USERSKZIADMITTANCEDELETETRAINING   = 'USER.SKZI.ADMITTANCE.DELETE.TRAINING'
export const USERSKZIADMITTANCEADDTRAINING   = 'USER.SKZI.ADMITTANCE.ADD.TRAINING'


// admittance card
export const ADMITTANCECARDGET              = 'CARD.ADMITTANCE.GET'
export const ADMITTANCECARDUPDATE           = 'CARD.ADMITTANCE.UPDATE'
export const ADMITTANCECARDUPDATEFORMDATA   = 'CARD.ADMITTANCE.UPDATEFORMDATA'
export const ADMITTANCECARDINPROGRESS       = 'CARD.ADMITTANCE.INPROGRESS'
export const ADMITTANCECARDSETERRORBYKEY    = 'CARD.ADMITTANCE.SETERRORBYKEY'
export const ADMITTANCECARDRESETACTION      = 'CARD.ADMITTANCE.RESETACTION'
export const ADMITTANCECARDGETTYPES         = 'CARD.ADMITTANCE.GET.TYPES'
export const ADMITTANCECARDSETMAINTYPE      = 'CARD.ADMITTANCE.SET.MAIN.TYPE'
export const ADMITTANCECARDONSELECT         = 'CARD.ADMITTANCE.ON.SELECT'
export const ADMITTANCECARDUPDATED          = 'CARD.ADMITTANCE.UPDATED'
export const ADMITTANCECARDUPDATEBUILDDATA    = 'CARD.ADMITTANCE.UPDATE.BUILD.DATA'
export const ADMITTANCECARDUPDATEEDITBUILDFORMDATA   = 'CARD.ADMITTANCE.UPDATE.EDIT.BUILD.FORM.DATA'
export const ADMITTANCECARDONTYPEDELETE   = 'CARD.ADMITTANCE.ON.TYPE.DELETE'
export const ADMITTANCECARDTYPEDELETE   = 'CARD.ADMITTANCE.TYPE.DELETE'
export const ADMITTANCECARDONTYPEADD   = 'CARD.ADMITTANCE.ON.TYPE.ADD'
export const ADMITTANCECARDONFILEADD   = 'CARD.ADMITTANCE.ON.FILE.ADD'
export const ADMITTANCECARDTYPEADD   = 'CARD.ADMITTANCE.TYPE.ADD'
export const ADMITTANCECARDFILEDELETE   = 'CARD.ADMITTANCE.FILE.DELETE'
export const ADMITTANCECARDONFILEDELETE   = 'CARD.ADMITTANCE.ON.FILE.DELETE'

// admittance create 
export const ADMITTANCECREATE                 = 'CREATE.ADMITTANCE.CREATE'
export const ADMITTANCECREATEUPDATEFORMDATA   = 'CREATE.ADMITTANCE.UPDATEFORMDATA'
export const ADMITTANCECREATEINITFORMDATA   = 'CREATE.ADMITTANCE.INITFORMDATA'
export const ADMITTANCECREATEINPROGRESS       = 'CREATE.ADMITTANCE.INPROGRESS'
export const ADMITTANCECREATERESETACTION      = 'CREATE.ADMITTANCE.RESETACTION'
export const ADMITTANCECREATESETERRORBYKEY    = 'CREATE.ADMITTANCE.SETERRORBYKEY'
export const ADMITTANCECREATECLEARFORM        = 'CREATE.ADMITTANCE.CLEARFORM'


// self service registry list form
export const  SELFSERVICEREGISTRYGETALL         =  'MAIN.SELFSERVICEREGISTRY.GETALL'
export const  SELFSERVICEREGISTRYINPROGRESS     =  'MAIN.SELFSERVICEREGISTRY.INPROGRESS'
export const  SELFSERVICEREGISTRYRESETACTION    =  'MAIN.SELFSERVICEREGISTRY.RESETACTION'
export const  SELFSERVICEREGISTRYSETFILTERACTION    =  'MAIN.SELFSERVICEREGISTRY.SETFILTER.ACTION'
export const  SELFSERVICEREGISTRYCLEARSTOREACTION    =  'MAIN.SELFSERVICEREGISTRY.CLEAR.STORE.ACTION'
export const  SELFSERVICEREGISTRYPAGINATIONGET  =  'MAIN.SELFSERVICEREGISTRY.PAGINATIONGET'
export const  SELFSERVICEREGISTRYONBUILDSSORT  =  'MAIN.SELFSERVICEREGISTRY.ON.BUILDS.SORT'
export const  SELFSERVICEREGISTRYONCOURSESSORT  =  'MAIN.SELFSERVICEREGISTRY.ON.COURSES.SORT'
export const  SELFSERVICEREGISTRYGETBUILDSETDATA  =  'MAIN.SELFSERVICEREGISTRY.GET.BUILD.SET.DATA'
export const  SELFSERVICEREGISTRYINSTALLSKZI  =  'MAIN.SELFSERVICEREGISTRY.INSTALL.SKZI'
export const  SELFSERVICEREGISTRYCREATECONCLUSION  =  'MAIN.SELFSERVICEREGISTRY.CREATE.CONCLUSION'
export const  SELFSERVICEREGISTRYONBUILDSUPDATE  =  'MAIN.SELFSERVICEREGISTRY.ON.BUILDS.UPDATE'
export const  SELFSERVICEREGISTRYONCOURSEASSIGN  =  'MAIN.SELFSERVICEREGISTRY.ON.COURSE.ASSIGN'
export const  SELFSERVICEREGISTRYCOURSEASSIGN  =  'MAIN.SELFSERVICEREGISTRY.COURSE.ASSIGN'
export const  SELFSERVICEREGISTRYEXAMREQUEST  =  'MAIN.SELFSERVICEREGISTRY.ON.EXAM.REQUEST'


// license card
export const LICENSECARDGET              = 'CARD.LICENSE.GET'
export const LICENSECARDUPDATE           = 'CARD.LICENSE.UPDATE'
export const LICENSECARDUPDATEFORMDATA   = 'CARD.LICENSE.UPDATEFORMDATA'
export const LICENSECARDINPROGRESS       = 'CARD.LICENSE.INPROGRESS'
export const LICENSECARDRESETACTION      = 'CARD.LICENSE.RESETACTION'
export const LICENSECARDUPDATED          = 'CARD.LICENSE.UPDATED'
export const LICENSECARDGETESKZI          = 'CARD.LICENSE.GET.ESKZI'


// license create 
export const LICENSECREATE                 = 'CREATE.LICENSE.CREATE'
export const LICENSECREATEUPDATEFORMDATA   = 'CREATE.LICENSE.UPDATEFORMDATA'
export const LICENSECREATEINITFORMDATA   = 'CREATE.LICENSE.INITFORMDATA'
export const LICENSECREATEINPROGRESS       = 'CREATE.LICENSE.INPROGRESS'
export const LICENSECREATERESETACTION      = 'CREATE.LICENSE.RESETACTION'
export const LICENSECREATECLEARFORM        = 'CREATE.LICENSE.CLEARFORM'


// files table
export const FILESTABLEGET              = 'FILES.TABLE.GET'
export const FILESTABLEUPDATE           = 'FILES.TABLE.UPDATE'
export const FILESTABLEUPDATEFORMDATA   = 'FILES.TABLE.UPDATEFORMDATA'
export const FILESTABLEINPROGRESS       = 'FILES.TABLE.INPROGRESS'
export const FILESTABLESETERRORBYKEY    = 'FILES.TABLE.SETERRORBYKEY'
export const FILESTABLERESETACTION      = 'FILES.TABLE.RESETACTION'
export const FILESTABLEGETTYPES         = 'FILES.TABLE.GET.TYPES'
export const FILESTABLEONSELECT         = 'FILES.TABLE.ON.SELECT'
export const FILESTABLEONUNMOUNT        = 'FILES.TABLE.ON.UNMOUNT'
export const FILESTABLEUPDATED          = 'FILES.TABLE.UPDATED'
export const FILESTABLEUPDATEBUILDDATA    = 'FILES.TABLE.UPDATE.BUILD.DATA'
export const FILESTABLEUPDATEEDITBUILDFORMDATA   = 'FILES.TABLE.UPDATE.EDIT.BUILD.FORM.DATA'
export const FILESTABLEONTYPEDELETE   = 'FILES.TABLE.ON.TYPE.DELETE'
export const FILESTABLETYPEDELETE   = 'FILES.TABLE.TYPE.DELETE'
export const FILESTABLEONTYPEADD   = 'FILES.TABLE.ON.TYPE.ADD'
export const FILESTABLEONFILEADD   = 'FILES.TABLE.ON.FILE.ADD'
export const FILESTABLETYPEADD   = 'FILES.TABLE.TYPE.ADD'
export const FILESTABLEFILEDELETE   = 'FILES.TABLE.FILE.DELETE'
export const FILESTABLEONFILEDELETE   = 'FILES.TABLE.ON.FILE.DELETE'
// maintenance card
export const MAINTENANCECARDGET              = 'CARD.MAINTENANCE.GET'
export const MAINTENANCECARDUPDATE           = 'CARD.MAINTENANCE.UPDATE'
export const MAINTENANCECARDUPDATEFORMDATA   = 'CARD.MAINTENANCE.UPDATEFORMDATA'
export const MAINTENANCECARDINPROGRESS       = 'CARD.MAINTENANCE.INPROGRESS'
export const MAINTENANCECARDSETERRORBYKEY    = 'CARD.MAINTENANCE.SETERRORBYKEY'
export const MAINTENANCECARDRESETACTION      = 'CARD.MAINTENANCE.RESETACTION'
export const MAINTENANCECARDGETTYPES         = 'CARD.MAINTENANCE.GET.TYPES'
export const MAINTENANCECARDSETMAINTYPE      = 'CARD.MAINTENANCE.SET.MAIN.TYPE'
export const MAINTENANCECARDONSELECT         = 'CARD.MAINTENANCE.ON.SELECT'
export const MAINTENANCECARDUPDATED          = 'CARD.MAINTENANCE.UPDATED'
export const MAINTENANCECARDUPDATEBUILDDATA    = 'CARD.MAINTENANCE.UPDATE.BUILD.DATA'
export const MAINTENANCECARDUPDATEEDITBUILDFORMDATA   = 'CARD.MAINTENANCE.UPDATE.EDIT.BUILD.FORM.DATA'
export const MAINTENANCECARDONTYPEDELETE   = 'CARD.MAINTENANCE.ON.TYPE.DELETE'
export const MAINTENANCECARDTYPEDELETE   = 'CARD.MAINTENANCE.TYPE.DELETE'
export const MAINTENANCECARDONTYPEADD   = 'CARD.MAINTENANCE.ON.TYPE.ADD'
export const MAINTENANCECARDONFILEADD   = 'CARD.MAINTENANCE.ON.FILE.ADD'
export const MAINTENANCECARDTYPEADD   = 'CARD.MAINTENANCE.TYPE.ADD'
export const MAINTENANCECARDFILEDELETE   = 'CARD.MAINTENANCE.FILE.DELETE'
export const MAINTENANCECARDONFILEDELETE   = 'CARD.MAINTENANCE.ON.FILE.DELETE'

// maintenance create 
export const MAINTENANCECREATE                 = 'CREATE.MAINTENANCE.CREATE'
export const MAINTENANCECREATEUPDATEFORMDATA   = 'CREATE.MAINTENANCE.UPDATEFORMDATA'
export const MAINTENANCECREATEINITFORMDATA   = 'CREATE.MAINTENANCE.INITFORMDATA'
export const MAINTENANCECREATEINPROGRESS       = 'CREATE.MAINTENANCE.INPROGRESS'
export const MAINTENANCECREATERESETACTION      = 'CREATE.MAINTENANCE.RESETACTION'
export const MAINTENANCECREATECLEARFORM        = 'CREATE.MAINTENANCE.CLEARFORM'

// Functional card types
export const CARD_GET              = 'CARD_GET'
export const CARD_UPDATE           = 'CARD_UPDATE'
export const CARD_UPDATE_FORM_DATA   = 'CARD_UPDATE_FORM_DATA'
export const CARD_UPDATE_FORM_FIELD   = 'CARD_UPDATE_FORM_FIELD'
export const CARD_UPDATE_VIEW_DATA   = 'CARD_UPDATE_VIEW_DATA'
export const CARD_IN_PROGRESS       = 'CARD_IN_PROGRESS'
export const CARD_SET_ERROR_BY_KEY    = 'CARD_SET_ERROR_BY_KEY'
export const CARD_ON_CANCEL      = 'CARD_CANCEL'
export const CARD_RESET      = 'CARD_RESET'
export const CARD_REMOVE_FIELDSET = 'CARD_REMOVE_FIELDSET'
export const CARD_ADD_FIELDSET = 'CARD_ADD_FIELDSET'

// card
export const CARDUPDATE = 'CARD.UPDATE'
export const CARDAFTERCREATE = 'CARD.AFTER.CREATE'
export const CARDSEREVERUPDATE = 'CARD.SEREVER.UPDATE'
export const CARDINIT = 'CARD.INIT'
export const CARDPREINIT = 'CARD.PRE.INIT'
export const CARDPREINITUPDATE = 'CARD.PRE.INIT.UPDATE'
export const CARDRESET = 'CARD.RESET'
export const CLEAR_CARD = 'CARD.CLEAR'
export const CARDINPROGRESS = 'CARD.IN.PROGRESS'
export const CARD_SET_WARNING = 'CARD.SET.WARNING'
export const DISABLEACTIONPANEL = 'DISABLE.ACTION.PANEL'

// toasts
export const ADD_TOAST = 'ADD.TOAST'
export const REMOVE_TOAST = 'REMOVE.TOAST'
export const REMOVE_ALL_TOASTS = 'REMOVE.ALL.TOASTS'



