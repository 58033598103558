import React from 'react';

import UserAccountCardTab from '../UserAccountCardTab';
import UserAccountCardTabToolbar from '../UserAccountCardTabToolbar';

import toolbarConfig from './toolbarConfig';
import tableSchema from './tableSchema';

const Toolbar = UserAccountCardTabToolbar(toolbarConfig);

const KeyCarriersTab = ({ profile, userId, setInProgress, issueDisabled }) => UserAccountCardTab({
  header: 'Ключевые носители',
  Toolbar,
  tableSchema,
  tableServiceName: 'keyCarrierService',
  setInProgress,
  issueDisabled,
  itemCardCallback: item => `/keyDocument/keyCarrier/card/${item.id}`,
  profile,
  userId,
});

export default KeyCarriersTab;