/** @format */

export const ContentComponentType = {
  AdminManual: 'Руководство администратора',
  ConformityCertificate: 'Сертификат соответствия',
  Hardware: 'Оборудование',
  InstallationPackage: 'Дистрибутив',
  Manual: 'Инструкция',
  Passport: 'Паспорт',
  RecordSheet: 'Формуляр',
  TermsOfUse: 'Правила пользования',
};

export const HashStatus = {
  Ok: 'Контрольная сумма соответствует дистрибутиву',
  Error: 'Контрольная сумма не соответствует дистрибутиву',
  NotSet: 'Контрольная сумма не указана',
};
