import { 
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  SKZIINSTANCEGETBUILDDATA,
  SKZIINSTANCEGETALL,
  SKZIINSTANCEADD,
  SKZIINSTANCEEDIT,
  SKZIINSTANCEDELETE, 
  SKZIINSTANCEERROR, 
  SKZIINSTANCECLEARERROR, 
  SKZIINSTANCEINPROGRESS, 
  SKZIINSTANCEONEDIT, 
  SKZIINSTANCEONCREATE, 
  SKZIINSTANCERESETACTION, 
  SKZIINSTANCEEDITERROR, 
  SKZIINSTANCECREATEERROR, 
  SKZIINSTANCEONDELETE, 
  SKZIINSTANCEPAGINATIONGET, 
  SKZIINSTANCEONOKZREPORT, 
  SKZIINSTANCEONOKIREPORT, 
  SKZIINSTANCERUNEVENT,
  SKZIINSTANCEONRUNEVENT,
  SKZIINSTANCEEVENTFORMCHANGED,
  SIGNATUREREADYTOSIGN,
  KEYDOCUMENTRUNEVENT
 } from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { getPageData } from 'app/components/ui/pagination/pagination'
import { updateCollectionByItemId } from 'app/core/utility/common'
import config from 'app/core/config'

const { paginationPerPage } = config

const defaultState = {
  items: [],
  pageItems: [],
  pagination: { activePage: 1, itemsPerPage: paginationPerPage },
  OKZfilter: null,
  OKIfilter: null,
  editObject: null,
  createObject: null,
  eventRunObject: null,
  confirmObject: null,
  fileData: null,
  inProgress: false
}

const mapToTableItem = (item) => {
  const { user = {}, okz = {}, oki = {}, skzi = {} } = item
  const { name: okzName, shortName: okzShortName } = okz
  const { name: okiName, shortName: okiShortName } = oki
  const { lastName, firstName, middleName } = user

  return {
    ...item,
    skzi: skzi,
    status: item.status && item.status.name,
    statusBack: {...item.status},
    user: `${lastName || ''} ${firstName || ''} ${middleName || ''}`,
    okz: okzShortName || okzName,
    oki: okiShortName || okiName,
    okzBack: {...okz},
    organizationBack: {...okz}
  }
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { 
      items,
      pagination, 
      pageItems,
      editObject: stateEditObject, 
      createObject: stateCreateObject,
      errorObject: stateErrorObject 
  } = state
  const { activePage: stateActivePage, itemsPerPage: statePerPage } = pagination
  const activePageFirstItemIndex = stateActivePage === 1 ? 0 : (stateActivePage - 1) * statePerPage

  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case SKZIINSTANCEGETALL:
      const storeItems = payload.map((item, index) => ({ ...mapToTableItem(item), index: index + 1}))

      return {
        ...state,
        items: storeItems,
        pagination: {activePage: 1, itemsPerPage: statePerPage},
        pageItems: getPageData({ items: storeItems, activePage: 1, itemsPerPage: statePerPage }),
        inProgress: false
      }

    case SKZIINSTANCEPAGINATIONGET:
      const { activePage, itemsPerPage } = payload

      return {
        ...state,
        pagination: { activePage, itemsPerPage },
        pageItems: getPageData({ items, activePage, itemsPerPage }),
        inProgress: false
      }

    case SKZIINSTANCEADD:
      const { name } = payload
      const afterAddItems = [
        ...items.slice(0, activePageFirstItemIndex),
        payload, 
        ...items.slice(activePageFirstItemIndex)
      ].map((el, index) => ({...el, index: index + 1}))

      return {
        ...state,
        items: afterAddItems,
        pageItems: getPageData({ 
          items: afterAddItems, 
          activePage: stateActivePage,
          itemsPerPage: statePerPage }),
        inProgress: false,
        confirmObject: {
          type: dialogType.success,
          title: `Создан экземпляр «${name}».`
        },
        createObject: null
      }

    case SKZIINSTANCEONEDIT:
      const { editObject } = payload
      return {
        ...state,
        editObject
      }

    case SKZIINSTANCEONDELETE:
      const { confirmObject } = payload
      return {
        ...state,
        confirmObject
      }

    case SKZIINSTANCEONCREATE:
      const { createObject } = payload
      return {
        ...state,
        createObject
      }
        
    case SKZIINSTANCEEDIT:
      const { id } = payload
      const index = items.findIndex( item => item.id === id)
      
      if (index >= 0){
        const afterEditItems = [
          ...items.slice(0, index),
          payload, 
          ...items.slice(index + 1)
        ].map((el, index) => ({...el, index: index + 1}))
        return {
          ...state,
          items: afterEditItems,
          pageItems: getPageData({
              items: afterEditItems,
              activePage: stateActivePage,
              itemsPerPage : statePerPage
          }),
          editObject: null,
          inProgress: false
        }
      }

      return state
      
    case SKZIINSTANCEDELETE:
      const { id: deletedId } = payload
    
      if (payload.length) {
        return {
          ...state,
          inProgress: false,
          confirmObject: {
              type: dialogType.success,
              title: `Экземпляр${payload.length > 1 ? 'ы' : ''} успешно удален${payload.length > 1 ? 'ы' : ''}`
          }
        }
      }

      const deletedIndex = items.findIndex( item => item.id === deletedId)

      if (deletedIndex >= 0){
        const afterDeleteItems = [
            ...items.slice(0, deletedIndex),
            ...items.slice(deletedIndex + 1)
        ].map((el, index) => ({...el, index: index + 1}))

        return {
          ...state,
          items: afterDeleteItems,
          pageItems: getPageData({
              items: afterDeleteItems,
              activePage: stateActivePage,
              itemsPerPage : statePerPage
          }),
          inProgress: false,
          confirmObject: {
              type: dialogType.success,
              title: 'Экземпляр успешно удален'
          }
        }
      }

      return state

    case SKZIINSTANCEONRUNEVENT:
      const { eskzi, action: eventTemplate } = payload
      const { model } = eventTemplate
      console.log( model )
      const temp ={
        ...state,
        eventRunObject: {
          action: eventTemplate,
          item: eskzi,
          formData: model.reduce((acc, cur) => {
            acc[cur.key] = cur.defaultValue || null

            return acc
          }, {})
        },
      };
      console.log(temp)
      return {
        ...state,
        eventRunObject: {
          action: eventTemplate,
          item: eskzi,
          formData: model.reduce((acc, cur) => {
            acc[cur.key] = cur.defaultValue || null

            return acc
          }, {})
        },
      }

    case SKZIINSTANCEEDITERROR: 
      return {
        ...state,
        editObject: {
            ...stateEditObject,
            error: errors[0]
        },
        inProgress: false
      }

    case SKZIINSTANCECREATEERROR: 
      return {
        ...state,
        createObject: {
            ...stateCreateObject,
            error: errors[0]
        },
        inProgress: false
      }

    case SKZIINSTANCEERROR:
      return {
        ...state,
        errorObject: {
            ...stateErrorObject,
            error: errors[0],
            type: dialogType.warning,
        },
        createObject: null,
        editObject: null,
        eventRunObject: null,
        confirmObject: null,
        inProgress: false
      }

    case SKZIINSTANCEONOKZREPORT:
      return {...state, OKZfilter: payload }

    case SKZIINSTANCEONOKIREPORT:
      return {...state, OKIfilter: payload }      

    case SKZIINSTANCEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case SIGNATUREREADYTOSIGN: 
    case FIELDSERROR:
      return {...state, inProgress: false}

    case SKZIINSTANCECLEARERROR:
      const { editObject: currentEdit, createObject: currentCreate } = state

      return {
          ...state,
          editObject: currentEdit ? {...currentEdit, error: null} : null,
          createObject: currentCreate ? {...currentCreate, error: null} : null,
          error: null
      }

    case SKZIINSTANCEGETBUILDDATA: 
      return {
        ...state,
        fileData: payload,
        inProgress: false
      }

    case SKZIINSTANCERUNEVENT:
      const updatedEskziCollection = updateCollectionByItemId(items, mapToTableItem(payload))

      return {
        ...state,
        items: updatedEskziCollection,
        pageItems: getPageData({ 
          items: updatedEskziCollection, 
          activePage: stateActivePage,
          itemsPerPage: statePerPage }),
        inProgress: false,
        eventRunObject: null
      }

    case SKZIINSTANCEEVENTFORMCHANGED:
      const { eventRunObject } = state
      const { formData } = eventRunObject

      return {
        ...state,
        eventRunObject: {
          ...eventRunObject,
          formData: {...formData, ...payload}
        }
      }

    // to do 
    // move this to separate component 
    case KEYDOCUMENTRUNEVENT: 
      return { 
        ...state, 
        inProgress: false, 
        eventRunObject: null 
      } 

    case SKZIINSTANCERESETACTION: 
      return { 
        ...defaultState,
        items,
        pagination, 
        pageItems,
      }
      
    case ERROR:
      return { ...state, inProgress: false}

    default: 
      return state
  }
}