import React from 'react'
import { formatDate } from 'app/core/utility/date';
import { formatKeyDoc } from 'app/components/list/Table/TableBody';

export default [
  {
    title: '№',
    titleInfo: '№',
    sortByValue: item => item.id,
    alias: 'id',
    visibleOnStart: true,
    useSorting: true,
    width: 50,
  },
  {
    title: 'Наименование',
    alias: 'name',
    format: item => formatKeyDoc(item),
    visibleOnStart: true,
    visibleAlways: true,
    useSorting: true,
    width: 400,
  },
  {
    title: 'Серийный №',
    alias: 'serialNumber',
    format: item => item.serialNumber,
    visibleOnStart: true,
    visibleAlways: true,
    useSorting: true,
    width: 400,
  },
  {
    title: '№ экземпляра',
    alias: 'identificationNumber',
    format: item => item.identificationNumber || '',
    visibleOnStart: true,
    useSorting: true,
    width: 300,
  },
  {
    title: 'Статус',
    alias: 'status',
    format: item => item.status && item.status.name,
    visibleOnStart: true,
    useSorting: true,
    width: 350,
  },
  {
    title: 'Дата регистрации',
    alias: 'createDate',
    format: item => formatDate(item.createDate, 'dd.mm.yyyy'),
    visibleOnStart: true,
    useSorting: true,
    width: 300,
  },
  {
    title: 'Срок действия',
    alias: 'expirationDate',
    format: item => formatDate(item.expirationDate, 'dd.mm.yyyy'),
    visibleOnStart: true,
    useSorting: true,
    width: 500,
  },
  {
    title: 'Ключевой носитель',
    alias: 'keyCarrier',
    format: item => {
      const { keyCarrier } = item
      const { keyCarrierType, serialNumber = ''} = keyCarrier || {}
      const { name = '' } = keyCarrierType || {}
      return (
        <div className="key-carrier">
          <span>{`${name}`}</span>
          <br/>
          <span>{`${serialNumber}`}</span>
        </div>
      )
    },
    visibleOnStart: true,
    useSorting: true,
  },
];
