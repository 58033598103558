/** @format */
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterElement';
import styles from './list.module.scss';
import DownloadFileButton from '../downloadFileButton';

export const fieldsConfig = [
  {
    title: 'Серийный №',
    alias: 'serialNumber',
    format: item => {
      const { id, serialNumber } = item ?? {};
      return (
        <div className={styles.serialNumber}>
          {serialNumber}
          <DownloadFileButton url={`api/v1/cryptopro/certificates/download?sn=${id}`} />
        </div>
      );
    },
    visibleOnStart: true,
    visibleAlways: true,
    useSorting: true,
    width: 400,
  },
  {
    title: 'Состояние',
    alias: 'status',
    format: item => {
      const { status } = item ?? {};
      const { name } = status ?? {};
      return name;
    },
    visibleOnStart: true,
    visibleAlways: true,
    useSorting: true,
  },
  {
    title: 'Дата изготовления',
    alias: 'creationDate',
    format: item => formatDate(item.creationDate, 'dd.mm.yyyy hh:MM:ss'),
    visibleOnStart: true,
    useSorting: true,
    width: 250,
  },
  {
    title: 'Дата окончания действия',
    alias: 'validityDate',
    format: item => formatDate(item.validityDate, 'dd.mm.yyyy hh:MM:ss'),
    visibleOnStart: true,
    useSorting: true,
    width: 250,
  },
];

export const filtersConfig = [
  {
    type: FilterControlType.simpleSearch,
    alias: 'serialNumber',
    label: 'Серийный №',
    extended: false,
    order: 2,
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'status',
    label: 'Состояние',
    serviceType: 'certificateStatus',
    extended: false,
    order: 3,
  },
  {
    type: FilterControlType.dateRangePicker,
    alias: 'creationDate',
    label: 'Дата изготовления',
    extended: false,
    order: 4,
  },
  {
    type: FilterControlType.dateRangePicker,
    alias: 'validityDate',
    label: 'Дата окончания действия',
    extended: true,
    order: 5,
  },
];
