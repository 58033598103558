/** @format */

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader';
import Tabs from 'app/components/ui/tabs/tabs';
import {
  breadcrumbsPushAction,
  breadcrumbsPopAction,
} from 'redux/actions/common/breadcrumbsAction';
import { useLocation, useParams } from 'react-router-dom';
import Overlay from 'app/components/ui/overlay';
import service from 'app/services/service';
import Requisites from './requisites/requisites';
import Certificates from './certificates/certificates';
import Requests from './requests/requests';

const Card = () => {
  const { userId } = useParams();
  const profile = useSelector(({ profile }) => profile);
  const [activeTab, setActiveTab] = useState('Информация');
  const [cardData, setCardData] = useState();
  const [inProgress, setInProgress] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const initCardData = async () => {
    setInProgress(true);
    const response = await service('certificateAuthorityUserService', 'getUser', {
      userId,
    });
    const { data, isError } = response;
    !isError && setCardData({ ...data });
    setInProgress(false);
  };

  useEffect(() => {
    initCardData();
  }, []);

  useEffect(() => {
    if (!cardData) {
      return;
    }

    const { userName } = cardData || {};
    dispatch(breadcrumbsPushAction(location.pathname, userName, true));
    return () => {
      dispatch(breadcrumbsPopAction());
    };
  }, [cardData]);

  return (
    <>
      <BreadcrumbsHeader />
      {inProgress ? <Overlay /> : null}
      <Tabs activeTab={activeTab} onTabClick={title => setActiveTab(title)}>
        <Requisites
          tabTitle={'Информация'}
          data={cardData}
          initCardData={initCardData}
          profile={profile}
        />
        <Requests tabTitle={'Запросы'} />
        <Certificates tabTitle={'Сертификаты'} />
      </Tabs>
    </>
  );
};

export default Card;

