import { sortDirection } from "app/components/ui/constants";

export default (state = { column : '', direction : sortDirection.desc }, action, prefix = '') => {

  if( action.type === prefix + 'LIST_LOAD_LIST_SORTING' ){
    return action.payload;
  }

  // deprecated
  if( action.type === prefix + 'LIST_SORT_BY' ){
    const newState = {
      column : action.payload, 
      direction : action.payload === state.column 
                  ? state.direction === sortDirection.desc
                    ? sortDirection.asc 
                    : sortDirection.desc
                  : state.direction
    }

    return newState;
  }

  if( action.type === prefix + 'LIST_SORT_BY_SET' ){
    const newState = {
      column : action.payload.column, 
      direction : action.payload.direction,
    }
    return newState;
  }

  if( action.type === prefix + 'LIST_DROP' ){
    return { column : '', direction : true };
  }

  return state;
}