import Container from "app/components/MassImports/Container";
import React, {useEffect, useState} from "react";
import withModal from "app/components/HOC/ObsoleteModalHOC";
import Button from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import {useDispatch, useSelector} from "react-redux";
import {ActionCreator} from "redux/actions/uploadProcessing/uploadProcessing";
import Overlay from "app/components/ui/overlay";
import {useInitResultFile} from "app/components/MassImports/hooks/useInitResultFile";
import {getEnumTypeByString} from "app/components/MassImports/utils";
import {RequestType} from "app/components/MassImports/enums";
import {acceptedFileTypesForSignature} from "app/components/MassImports/consts";
import {Footer} from "app/components/MassImports/LoadResult/Footer";
import {Filter} from "app/components/MassImports/LoadResult/Filter";
import {ImportTable} from "app/components/MassImports/LoadResult/Table";
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC';
import { cssModuleClassNameGetter } from "app/core/utility/jsx";

import commonModalStyles from '../modalCommon.module.scss';
import styles from './LoadResult.module.scss';

const getClass = cssModuleClassNameGetter(styles);

const WithModal = withModal(Container);
const TooltippedButton = withTooltip(Button);

export default function LoadResult({parseFileInfo}) {
    const {fileType, fileStatus} = parseFileInfo;
    const dispatch = useDispatch();
    const profile = useSelector(({profile}) => profile);
    const [isSignatureRequired, setSignatureRequiredState] = useState(null);
    const [records, setRecords] = useState([]);
    const [importDups, setImportDups] = useState(false);
    const [totalCountDownloads, setTotalCountDownloads] = useState(0);
    const [inProgress, setInProgressState] = useState(true);
    
    // Backend returns aggregate data only when the first page gets requested
    // (leaky abstraction for the sake of optimization),
    // this state setter wrapper keeps the aggregate data intact if the backend omits them
    const setRecordsOptimized = newData => setRecords({ ...records, ...newData });

    const {
        config,
        duplicateConfig,
        importsPayload,
        setImportsPayload,
        title,
    } = useInitResultFile(getEnumTypeByString(fileType), fileStatus);

    const onCloseClickButtonHandler = async () => dispatch(ActionCreator.setParsedFileInfo(null));

    useEffect(() => {
        const isAcceptedFileTypesForSignature = acceptedFileTypesForSignature.some(item => item === fileType);
        const apiSettings = profile && profile.signatureSettings && profile.signatureSettings.apiSettings;

        if (apiSettings && isAcceptedFileTypesForSignature) {
            const currentRequestType = RequestType[getEnumTypeByString(fileType)];
            const currentRequestSettings = apiSettings.find(item => item.requestType === currentRequestType);
            setSignatureRequiredState(currentRequestSettings ? currentRequestSettings.signatureRequired : true);
        }
    }, [profile]);
    
    useEffect(() => {
        const { duplicates = 0, canImportCount = 0 } = records;
        setTotalCountDownloads(canImportCount + (importDups ? duplicates : 0));
    }, [records, importDups]);
    
    const { licensesLeft } = records;
    const shortOfLicenses = typeof licensesLeft !== 'undefined';

    return <WithModal
        onCancel={onCloseClickButtonHandler}
        renderHeader={() => <div className={commonModalStyles.header}>
            {title}
            <TooltippedButton 
                type="image"
                notSubmit
                className="btn-close"
                tooltip="Закрыть окно"
                tooltipPosition={Position.BottomLeft}
                onClick={onCloseClickButtonHandler}
            >
                <Img img={Image.CloseBorderless} />
            </TooltippedButton>
        </div>}
        isGlobalOverlay
        className={getClass(['import-load-result-modal', commonModalStyles.modal], 'modal')}
    >
        {inProgress && importsPayload && duplicateConfig && <Overlay/>}
        
        <Filter
            importsPayload={importsPayload}
            setImportsPayload={setImportsPayload}
            records={records}
            totalCountDownloads={totalCountDownloads}
            setTotalCountDownloads={setTotalCountDownloads}
        />
        {
            config
            && duplicateConfig
            && importsPayload
            && <ImportTable
                config={config}
                parseFileInfo={parseFileInfo}
                duplicateConfig={duplicateConfig}
                importsPayload={importsPayload}
                setRecordsState={setRecordsOptimized}
                setInProgressState={setInProgressState}
            />
        }
        <Footer
            parseFileInfo={parseFileInfo}
            acceptedFileTypesForSignature={acceptedFileTypesForSignature}
            isSignatureRequired={isSignatureRequired}
            totalCountDownloads={totalCountDownloads}
            shortOfLicenses={shortOfLicenses}
            importDups={importDups}
            setImportDups={setImportDups}
            onClose={onCloseClickButtonHandler}
        />
    </WithModal>
}
