import { 
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  CLEARERROR,
  MAINTENANCECARDGET,
  MAINTENANCECARDUPDATE,
  MAINTENANCECARDUPDATEFORMDATA,
  MAINTENANCECARDINPROGRESS,
  MAINTENANCECARDRESETACTION,
  MAINTENANCECARDGETTYPES ,    
  MAINTENANCECARDONSELECT,
  MAINTENANCECARDONTYPEADD,
  MAINTENANCECARDONFILEADD,
  MAINTENANCECARDONTYPEDELETE,
  MAINTENANCECARDTYPEDELETE,
  MAINTENANCECARDTYPEADD,
  MAINTENANCECARDSETMAINTYPE,
  MAINTENANCECARDONFILEDELETE,
  MAINTENANCECARDFILEDELETE,
  SIGNATUREREADYTOSIGN,
  } from '../../actions/actionTypes'
  
  import { dialogType } from 'app/components/dialog/confirmDialog/'
  import { excludeByItemId, arrayToIdHashMap } from 'app/core/utility/common'
  
  const defaultState = {
    selected: {
      items: [],
      selectedHashMap: {}
    },
    commonDataForm : {
      number: '',
      performerId: null,
      hardwareNumber: '',
      printNumber: '',
      eskziId: null,
      distributiveRemovingDate: null,
      createDate: new Date(),
      updateDate: new Date(),
    },
    viewData: {
      number: '',
      performerId: null,
      hardwareNumber: '',
      printNumber: '',
      eskziId: null,
      distributiveRemovingDate: null,
      createDate: new Date(),
      updateDate: new Date(),
    },
    maintenanceData: {},
    confirmObject: null,
    addFilesDialog: false,
    deleteTypeConfirm: null,
    currentlyDeletingFiles: {},
    inProgress: false
  }
  
  export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { maintenanceData, viewData, selected, currentlyDeletingFiles } = state
    const { content } = maintenanceData
    const { performer, eskzi } = payload || {}

    switch (action.type) {
      case MAINTENANCECARDONSELECT: 
        return {
          ...state,
          selected: payload || defaultState.selected
        }

      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case MAINTENANCECARDUPDATE: 
      case MAINTENANCECARDGET:

        const responseData = {
          ...payload, 
          eskziId: eskzi,
          performerId: performer
        }

        return {
          ...state,
          commonDataForm:responseData,
          viewData: responseData,
          maintenanceData: responseData,
          inProgress: false
        }

      case MAINTENANCECARDSETMAINTYPE:
        const updatedContent = content.map(doc => ({
          ...doc,
          main: doc.id === payload.id
        }))

        return {
          ...state,
          maintenanceData: {
            ...maintenanceData,
            content: updatedContent
          },
          inProgress: false
        }

      case MAINTENANCECARDTYPEADD:
        return {
          ...state,
          maintenanceData: payload,
          inProgress: false
        }

      case MAINTENANCECARDONFILEDELETE:
        const newDeleting = payload.reduce((acc, cur) => {
          acc[cur.guid] = cur.guid
          return acc
        }, {})

        return {
          ...state,
          currentlyDeletingFiles: {
            ...currentlyDeletingFiles,
            ...newDeleting
          }
        }

      case MAINTENANCECARDFILEDELETE:
        return {
          ...state,
          maintenanceData: {
            ...maintenanceData,
            content: excludeByItemId(content, payload.map(item => ({id: item})))
          },
          inProgress: false,
          deleteTypeConfirm: null
        }

      case MAINTENANCECARDONFILEADD:
        return {
          ...state,
          addFilesDialog: true,
        }

      case CLEARERROR:
        return {
          ...state,
          confirmObject: null,
          deleteTypeConfirm: null,
          inProgress: false
        }
      
      case SIGNATUREREADYTOSIGN:
      case FIELDSERROR:
      case ERROR:
        return {
          ...state,
          inProgress: false
        }

      case MAINTENANCECARDGETTYPES:
        return {
          ...state,
          maintenanceData: {
            ...maintenanceData,
            content: payload
          },
          inProgress: false
        }
   
      case MAINTENANCECARDONTYPEDELETE:

        return {
          ...state,
          deleteTypeConfirm: {
            item: payload,
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить элементы: «${payload.map(item => item.name).join(', ')}»?`
          },
          inProgress: false
        }

      case MAINTENANCECARDTYPEDELETE:

        const { items: selectedItems } = selected
        let confirmDialog = null
        const afterDeleteSelected = excludeByItemId(selectedItems, payload.map(item => ({id: item}))) 
  
        if (errors && errors.length) {
          confirmDialog = {
            header: 'Удаление невозможно',
            type: dialogType.warning,
            messages: errors.map(item => item.errorMessage)
          }
        }
  
        return {
          ...state,
          selected: {
            items: afterDeleteSelected,
            selectedHashMap: arrayToIdHashMap(afterDeleteSelected)
          },
          maintenanceData: {
            ...maintenanceData,
            content: excludeByItemId(content, payload.map(item => ({id: item})))
          },
          deleteTypeConfirm: null,
          confirmObject: confirmDialog,
          inProgress: false
        }

      case MAINTENANCECARDUPDATEFORMDATA:

        return {
          ...state,
          commonDataForm: {...payload},
        }

      case MAINTENANCECARDINPROGRESS:
        return {...state, inProgress: payload}
  
      case MAINTENANCECARDRESETACTION: 
        return { 
          ...defaultState,
          viewData: viewData,
          selected: selected,
          maintenanceData: maintenanceData,
          commonDataForm: viewData,
        }
  
      default: 
        return state
    }
  }