import { formatDate } from 'app/core/utility/date';
import { organizationName } from 'app/components/list/Table/TableBody';

export default [
  {
    title: '№',
    alias: 'id',
    format: item => item.id,
    visibleOnStart: true,
    useSorting: true,
    width: 50,
  },
  {
    title: 'Серийный №',
    alias: 'serialNumber',
    format: item => item.serialNumber,
    visibleOnStart: true,
    useSorting: true,
    width: 200,
  },
  {
    title: 'Заводской №',
    alias: 'factoryNumber',
    format: item => item.factoryNumber,
    visibleOnStart: true,
    useSorting: true,
    width: 200,
  },
  {
    title: 'Тип',
    alias: 'keyCarrierType',
    format: item => item.keyCarrierType && item.keyCarrierType.name,
    visibleOnStart: true,
    useSorting: true,
    width: 200,
  },
  {
    title: 'Разовый',
    alias: 'single',
    format: item => (item.single ? 'Да' : 'Нет'),
    visibleOnStart: true,
    useSorting: true,
    width: 200,
  },
  {
    title: 'Организация',
    alias: 'organizationName',
    format: item => organizationName(item.okz),
    visibleOnStart: true,
    useSorting: true,
    width: 200,
  },
  // {
  //   title : 'Пользователь СКЗИ',
  //   alias : 'eskziUser',
  //   format : item => formatFIO( item.eskziUser ),
  //   visibleOnStart : true,
  //   useSorting : true,
  //   width : 200
  // },
  {
    title: 'Статус',
    alias: 'status',
    format: item => item.status && item.status.name,
    visibleOnStart: true,
    useSorting: true,
    // width : 200
  },
  {
    title: 'Дата изменения',
    alias: 'updateDate',
    format: item => formatDate(item.updateDate, 'dd.mm.yyyy'),
    visibleOnStart: true,
    useSorting: true,
    // width : 200
  },
];
