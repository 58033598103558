import React from "react"
import {useParams} from "react-router-dom"
import service from 'app/services/service'
import Input from 'app/components/ui/Input'
import DatePicker from 'app/components/ui/date'
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker'
import Autocomplete, { renderItem, renderInputValue }  from 'app/components/ui/autocomplete/autocomplete'
import {formatFIO} from "app/core/utility/common";
import {ORG_CARD_TYPE} from "app/components/ui/constants";
import {defaultSettings as userAccountColumnSetting} from "app/components/ui/picker/entities/userAccount";
import {SelectType} from "app/components/ui/picker/picker";
import {defaultSettings as hardwareColumnSettings} from "app/components/ui/picker/entities/hardware";
import {defaultSettings as keyDocumentColumnSettings} from "app/components/ui/picker/entities/keyDocument";


// TODO доработать для isKeyDocument isKeyCarrier
export default function ActionFieldComponent({value, onChange, error, type, title, readOnly, id, organizationType, formData, fixedValues = {}}) {
  const { id: eskziId } = useParams()
  const {hardwareId = {}, keyDocumentCustom = {}} = formData
  const realHardwareId = hardwareId && hardwareId.id
  const realKeyDocumentId = keyDocumentCustom && keyDocumentCustom.id
  const isFixedValue = !!fixedValues[type]
  switch (type){
    case 'eskziUserId':
      const userService = {
        serviceName: 'skziUserService', 
        serviceMethod: 'eskziAutocomplete', 
        data: {
          eskziId
        }
      }

      return (
        <Autocomplete
          id={id}
          className="card-field card-user"
          onEmptySearch = {true}
          notStandardService={userService}
          label={title || 'ФИО пользователя СКЗИ'}
          onSelect={onChange}
          error={error}
          value={value}
          renderItem={item => formatFIO(item)}
          renderInputValue={item => formatFIO(item)}
          readOnly={readOnly}
        />
      )
    case 'organizationId':

      const userOrgService = {
        serviceName: 'organizationService', 
        serviceMethod: 'eskziAutocomplete', 
        data: {
          organizationType, eskziId
        }
      }

      return (
        <Autocomplete
          id={id}
          className="card-field card-organization"
          onEmptySearch = {true}
          notStandardService={userOrgService}
          label={title || 'Кому передано'}
          onSelect={onChange}
          error={error}
          value={value}
          linkTarget={readOnly && {targetType: ORG_CARD_TYPE, id: value && value.id}}
          renderItem={renderItem.organizations}
          renderInputValue={value => {
            const { shortName, name } = value || {}
            return shortName || name
          }}
          readOnly={readOnly}
        />
      )
    case 'hardwareId': {
      const hardwareAutocomplete = realKeyDocumentId 
        ? {
          serviceName: 'skziService', 
          serviceMethod: 'hardwareAutocomplete', 
          data: {
            eskziId: realKeyDocumentId
          }
        }
        : {
          serviceName: 'skziService', 
          serviceMethod: 'hardwareAutocompleteWithoutKD', 
          data: {}
        }

      const hardwareAutocompletePicker = {
        serviceName: 'skziService',
        serviceMethod: realKeyDocumentId 
                        ? 'hardwareAutocompletePicker' 
                        : 'hardwareAutocompleteWithoutKDPicker',
        data: {eskziId: realKeyDocumentId}
      }

      return (
        <AutocompletePicker
          id={id}
          className="card-field card-hardware"
          needParentClass={true}
          onEmptySearch={true}
          notStandardService={hardwareAutocomplete}
          label={title || 'Серийный номер аппаратного СКЗИ'}
          notStandardPickerService={hardwareAutocompletePicker}
          tableColumns={hardwareColumnSettings}
          selectType={SelectType.single}
          searchPlaceholder="Серийный №"
          onSelectValue={onChange}
          error={error}
          value={value}
          renderItem={item => item.serialNumber}
          renderInputValue={item => item ? item.serialNumber : ''}
          readOnly={readOnly}
          disabled={isFixedValue}
        />
      )
    }
    case 'keyDocumentCustom': {
      const keyDocumentAutocomplete = realHardwareId 
      ? {
        serviceName: 'keyDocumentService', 
        serviceMethod: 'keyDocumentAutocomplete', 
        data: {
          eskziId: realHardwareId
        }
      }
      : {
        serviceName: 'keyDocumentService', 
        serviceMethod: 'keyDocumentAutocompleteWithoutEskzi', 
        data: {}
      }

      const keyDocumentAutocompletePicker = {
        serviceName: 'keyDocumentService',
        serviceMethod: realHardwareId 
                        ? 'keyDocumentAutocompletePicker' 
                        : 'keyDocumentAutocompleteWithoutEskziPicker',
        data: {eskziId: realHardwareId}
      }

      return (
        <AutocompletePicker
          id={id}
          className="card-field card-keyDocumentCustom"
          needParentClass
          onEmptySearch={true}
          notStandardService={keyDocumentAutocomplete}
          label={title || 'Ключевой документ'}
          notStandardPickerService={keyDocumentAutocompletePicker}
          tableColumns={keyDocumentColumnSettings}
          selectType={SelectType.single}
          searchPlaceholder="Серийный №"
          onSelectValue={onChange}
          error={error}
          value={value}
          renderItem={item => item.serialNumber}
          renderInputValue={item => item ? item.serialNumber : ''}
          readOnly={readOnly}
        />
      )
    }
    case 'receivedFrom':
      return (
        <Input
          id={id}
          className="card-field card-received-from"
          label={title || 'Получено от'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
          placeholder='Введите наименование'
        />
      )
    case 'performerId': {
      const pickerService = {
        serviceName: 'skziUserService',
        serviceMethod: 'keyDocPerformersPicker',
        data: {eskziId: realKeyDocumentId}
      }

      const autocompleteService = {
        serviceName: 'skziUserService',
        serviceMethod: 'keyDocPerformers',
        data: {eskziId: realKeyDocumentId}
      }

      return (
        <AutocompletePicker
          id={id}
          className="card-field card-performerId"
          onEmptySearch={true}
          notStandardService={autocompleteService}
          label={title || 'Исполнитель'}
          notStandardPickerService={pickerService}
          tableColumns={userAccountColumnSetting}
          selectType={SelectType.single}
          searchPlaceholder="Поиск пользователей"
          onSelectValue={onChange}
          error={error}
          value={value}
          renderItem={item => renderItem.eskziUser(item)}
          renderInputValue={item => renderInputValue.eskziUser(item)}
          readOnly={!realKeyDocumentId}
          disabled={!realKeyDocumentId}
          hint={!realKeyDocumentId && 'Выберите ключевой документ'}
        />
      )
    }
    case 'hardwareNumber':
      return (
        <Input
          id={id}
          className="card-field card-hardwareNumber"
          label={title || 'Номер аппаратного средства'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
          placeholder='Введите номер'
        />
      )
    case 'distributiveRemovingDate':
      return (
        <DatePicker
          id={id}
          className="card-field card-distributiveRemovingDate"
          label={title || 'Дата удаления дистрибутива'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
        />
      )
    case 'transmittalLetterDate':
      return (
        <DatePicker
          id={id}
          className="card-field card-transmittalLetterDate"
          label={'Дата записи'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
        />
      )
    case 'transmittalLetterNumber':
      return (
        <Input
          id={id}
          className="card-field card-transmittalLetterNumber"
          label={title || 'Номер подтверждающего документа'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
        />
      )
    case 'printNumber':
      return (
        <DatePicker
          id={id}
          className="card-field card-printNumber"
          label={title || ''}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
          placeholder = 'Введите номер'
        />
      )
    default:
      return (
        <Input
          id={id}
          className="card-field card-def"
          label={title}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
        />
      )
  }
}