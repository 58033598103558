/** @format */

import React from 'react';
import styles from './info.module.scss';
import { getClassName } from 'app/core/utility/jsx';
import { AccentText, Header, NormalText } from '../typography';
import Paper from 'app/components/ui/paper';
import InfoCircle from './infoCircle';

export default function InfoWithChart({ className, headerText, dataRows, children }) {
  return (
    <Paper className={className}>
      <div className={styles.widgetContainer}>
        <div className={getClassName(['dashboard-info-chart', styles.info])}>
          <Header>{headerText}</Header>
          {dataRows.map(item => <CircleInfoElement key={item.title} {...item} />)}
        </div>
        {children}
      </div>
    </Paper>
  );
}

export function CircleInfoElement({ className, title, data, reference, circle }) {
  return (
    <a href={reference} className={getClassName([className, styles.infoElement])}>
      <InfoCircle {...circle} /> 
      <NormalText>{title}</NormalText>
      <AccentText>{data}</AccentText>
    </a>
  );
}