import React from "react";
import { formatFIO } from "app/core/utility/common";
import { formatDate } from "app/core/utility/date";
import { OKI_CARD_TYPE, ORG_CARD_TYPE } from "app/components/ui/constants";
import ListLink from "app/components/ui/ListLink";
import { FilterControlType } from "../../filter/FilterElement";

const config = {
  fieldsConfig: [
    {
      title: "№",
      alias: "id",
      useSorting: true,
      visibleOnStart: true,
      width: 100,
    },
    {
      title: 'ФИО пользователя',
      alias: "name",
      format: (item) => formatFIO(item),
      useSorting: true,
      visibleOnStart: true,
      visibleAlways: true,
    },
    {
      title: 'Логин',
      alias: 'login',
      useSorting: true,
      visibleOnStart: true,
      visibleAlways: true,
      width: 200,
    },
    {
      title: 'Организация',
      alias: 'organization',
      format: item => {
        const { organization } = item
        const { shortName , id, name = '', okz } = organization || {}
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE

        return (shortName || name) && id ? <ListLink
          text={shortName || name}
          targetType={targetType}
          id={id}
        /> : shortName || name
      },
      useSorting: true,
      visibleOnStart: true,
      visibleAlways: false,
      width: 250,
    },
    {
      title: 'Роли',
      alias: 'roles',
      format: item => {
        const { roles } = item
        const todayWithoutTime = new Date(formatDate(new Date(), 'yyyy-mm-dd'))

        const activeRights = roles ? roles.filter(role => {
          const dateWithoutTime = role.dateTill 
                                    ? new Date(role.dateTill.slice(0,10))
                                    : new Date('2200-01-01')
          return dateWithoutTime.valueOf() >= todayWithoutTime.valueOf()
        }) : []

        const uniqRole = activeRights.reduce((acc,cur) => {
          return acc[cur.role.id] 
                  ? acc 
                  : { ...acc, [cur.role.id]: cur.role }
        }, {})

        return Object.keys(uniqRole).map(item => uniqRole[item].name).join(', ')
      },
      useSorting: true,
      visibleOnStart: true,
      visibleAlways: false,
      width: 300,
    },
    {
      title: "Статус",
      alias: "status",
      useSorting: true,
      visibleOnStart: true,
      width: 200,
    },

    {
      title: "Дата регистрации",
      alias: "createDate",
      format: (item) => formatDate(item.createDate, "dd.mm.yyyy"),
      useSorting: true,
      visibleOnStart: true,
      width: 200,
    },
  ],
  tablePageFiltersConfig: [
    {
      type: FilterControlType.simpleSearch,
      alias: "names",
      label: "ФИО",
      extended: false,
      requestFormatIsArray: true,
      order: 2,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: "registerDate",
      label: "Дата регистрации",
      extended: false,
      order: 3,
    },
    {
      type: FilterControlType.autocomplete,
      alias: "organizationId",
      label: "Организация",
      serviceType: 'organizations',
      extended: false,
      order: 4,
    },
    {
      type: FilterControlType.autocomplete,
      alias: "status",
      label: "Статус",
      serviceType: "userStatus",
      extended: true,
      order: 5,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'userRoleId',
      label: "Роль",
      serviceType: "userRoles",
      extended: true,
      order: 6,
    },
  ],
};

export default config;
