import React, {useEffect} from 'react'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import { Table } from '../../list/Table/Table'
import useTableExtensions from '../../list/Table/useTableExtensions';
import { setSelectedAction } from '../../../../redux/actions/list/listActions'
import { useDataTable } from '../../list/Table/useDataTable'
import { ReactComponent as SyncImg } from 'assets/img/commonVer2/ic_base_sync.svg'
import Button from 'app/components/ui/button/button'
import useSynchronizationBlock, { syncListConfig as config } from '../hooks/useSynchronizationBlock'
import { sortDirection } from 'app/components/ui/constants'

export default function SynchronizationBlock({available}){
  const {
    inProgress,
    sorting,
    selectedItems,
    list,
    paginator,
    onAddValues,
    setInProgressOn,
    dispatch,
    sortListAction,
  } = useTableExtensions({
    defaultSort: {
      column: 'id',
      direction: sortDirection.desc,
    },
  });

  const type = 'activeDirectorySettings'

  const { getList } = useDataTable({
    sorting,
    type,
    dispatch
  });

 const {
    onSyncClick,
 } = useSynchronizationBlock({ onAddValues, setInProgressOn, getList, dispatch });

  useEffect(() => {
    if (list
        && (list.length > 0)
        && list.some(item => item.status === 'InProcess')) {
      getList();
    }
  }, [list]);

  return (
    <ExpandingBlock
      renderHeader= {() => 'Синхронизация данных'}
      initialState={available}
      disabled={!available}
      className="sync-block"
    >
      <div className = { type + "__buttons action_panel manage_panel list__manage-panel" }>
        <div>
          <div className="manage-panel__item">
            <Button className='sync-button' type='primary' disabled={ inProgress } onClick={onSyncClick}>
              <SyncImg className='button-image' />
              <span className='button-title'>Синхронизировать</span>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Table
          sortListAction={sortListAction}
          paginator={paginator}
          sorting={sorting}
          selectedItems={selectedItems}
          setSelectedAction={params => dispatch(setSelectedAction(params))}
          inProgress={inProgress}
          list={list}
          fieldsConfig={ config.fieldsConfig }
          itemsSelectableRadio={ config.pageConfig.itemsSelectableRadio }
          defaultSortByAlias={ config.pageConfig.defaultSortByAlias }
          defaultSortDirection={ config.pageConfig.defaultSortDirection }
          changedFieldsVisibility={ config.pageConfig.changedFieldsVisibility === true }
          onItemClick={ config.pageConfig.onItemClick }
          headerInfo={ config.pageConfig.headerInfo === true }
        />
      </div>
    </ExpandingBlock>
  )
}