/** @format */
import withTooltip from 'app/components/HOC/TooltipHOC';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button';
import Img from 'app/components/ui/Img/imgMap';

const Button = withTooltip(ButtonComponent);

export default function ImportCertificateButton({ isPrimary, disabled, onClick, massImport }) {
  return (
    <Button
      type={isPrimary ? buttonTypes.primary : buttonTypes.image}
      tooltip={isPrimary ? '' : `Загрузить сертификат${massImport ? 'ы' : ''}`}
      caption={isPrimary ? `Загрузить сертификат${massImport ? 'ы' : ''}` : ''}
      disabled={disabled}
      onClick={onClick}
    >
      <Img.CertificateAdd />
    </Button>
  );
}
