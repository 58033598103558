import React, { useState } from 'react'

import Input, { InputType } from 'app/components/ui/Input'
import { ReactComponent as Eye}  from 'assets/img/commonVer2/eye.svg'
import { ReactComponent as CrossedEye} from 'assets/img/commonVer2/crossed-eye.svg'

const CrossedEyeIcon = (props) => {
  const {changeState} = props
  return (
    <CrossedEye
      className='password-eye'
      onClick={() => changeState(false)}
    />
  )
}

const EyeIcon = (props) => {
  const {changeState} = props
  return (
    <Eye
      className='password-eye'
      onClick={() => changeState(true)}
    />
  )
}


export default function LicenseInput(props) {
  const { id, name, value, onChange, readOnly } = props

  const [ state, setState ] = useState(false)
  const image = state 
                ? () => <CrossedEyeIcon changeState={setState} /> 
                : () => <EyeIcon changeState={setState} />
  const visibleText = state 
                      ? value 
                      : value 
                        ? `** ${value.slice(-4)}` 
                        : ''
  const caretPosition = value === visibleText ? value.length : undefined

  return (
    <Input
      {...props}
      id={ id || 'license' }
      name={ name || 'license' }
      inputType={InputType.text}
      CustomImage={image}
      value={visibleText}
      caretPosition={caretPosition}
      readOnly={false}
      onChange={(newValue) => {
        !readOnly && onChange && onChange(state ? newValue : value)
        !readOnly && setState(true)
        }}
    />
  )
}
