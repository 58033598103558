import withModal from "app/components/HOC/ObsoleteModalHOC";
import withTooltip, { Position } from "app/components/HOC/TooltipHOC";
import Container from "app/components/MassImports/Container";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button, { buttonTypes } from "app/components/ui/button/button";
import Img, { Image } from 'app/components/ui/Img';
import {ActionCreator} from "redux/actions/uploadProcessing/uploadProcessing";
import {
    getFileSingleParsingID,
    getLastUploads, getUserHistory,
    getWSUploads
} from "redux/actions/uploadProcessing/selectors";
import BigUploadItem from "app/components/ui/BigUploadItem/BigUploadItem";
import service from "app/services/service";
import {Url, Status} from "app/components/MassImports/enums";
import {getEnumTypeByString} from "app/components/MassImports/utils";
import { cssModuleClassNameGetter } from "app/core/utility/jsx";

import commonModalStyles from '../modalCommon.module.scss';
import styles from './SingleParsingFileModal.module.scss';

const getClass = cssModuleClassNameGetter(commonModalStyles);

const WithModal = withModal(Container);
const TooltippedButton = withTooltip(Button);

const SingleParsingFileModal = () => {
    const dispatch = useDispatch();
    const id = useSelector(getFileSingleParsingID);
    const WSUploads = useSelector(getWSUploads);
    const [file, setFileState] = useState(null);
    const lastUploads = useSelector(getLastUploads);
    const userHistory = useSelector(getUserHistory);

    const onCloseHandler = () => {
        dispatch(ActionCreator.setFileSingleParsing(false));
        dispatch(ActionCreator.setFileSingleParsingId(null));
    };
    const onDiscardHandler = async () => {
        await service(
            'MassImports',
            'discardScannedFile',
            {
                importType: Url[getEnumTypeByString(file.file.type.name)],
                fileId: file.fileId
            }
        );
        onCloseHandler();
        dispatch(ActionCreator.setWSUploads(WSUploads.filter(item => item.fileId !== file.fileId)));
        dispatch(ActionCreator.setUserHistory(userHistory.filter(item => item.fileId !== file.fileId)));
        dispatch(ActionCreator.setLastUploads(lastUploads.filter(item => item.fileId !== file.fileId)));
        dispatch(ActionCreator.setParsedFileInfo(null));
    };

    useEffect(() => {
        setFileState(WSUploads.find(item => item.file.id === id));
    }, [WSUploads]);
    useEffect(() => {
        const shortOfLicenses = file && typeof file.requiredLicenses !== 'undefined';
        if (file && ((file.file.status === Status.LOADING_END) || (file.file.status === Status.CREATING_END) || shortOfLicenses)) {
            dispatch(ActionCreator.setParsedFileInfo({
                fileStatus: shortOfLicenses ? Status.LOADING_END : file.file.status,
                fileId: file.fileId,
                fileType: file.file.type.name
            }));
            onCloseHandler();
        }
    }, [file]);

    return <WithModal
        onCancel={onCloseHandler}
        renderHeader={() => (
            <div className={commonModalStyles.header}>
                Загрузка файла
                
                <TooltippedButton
                    type={buttonTypes.image}
                    tooltip="Свернуть окно"
                    tooltipPosition={Position.BottomLeft}
                    className="btn-close"
                    onClick={onCloseHandler}>
                    <Img img={Image.Minimize} />
                </TooltippedButton>                
            </div>
        )}
        className={getClass(['single-parsing-file-modal', styles.modal], 'modal')}
        isGlobalOverlay
    >
        
        <div className={'modal-container__description'}>
            По окончании загрузки будет отображен результат предварительной обработки файла.<br/>
            Вы можете закрыть это окно, чтобы позднее открыть результаты обработки файла из меню загрузки.
        </div>
        {
            file &&
            <BigUploadItem name={file.file.fileName} progress={file.progress} size={file.file.size}/>
        }
        <div className={`${commonModalStyles.footer} ${styles.footer}`}>
            <Button
                className={'modal-container__footer-create-btn'}
                type={buttonTypes.text}
                disabled={!(file && file.file)}
                onClick={onDiscardHandler}
                notSubmit
            >
                Отменить загрузку
            </Button>
            <Button
                type={buttonTypes.primary}
                disabled={false}
                onClick={onCloseHandler}
                notSubmit
            >
                Свернуть окно
            </Button>
        </div>
    </WithModal>;
};

export default SingleParsingFileModal;
