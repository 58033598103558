import Input from 'app/components/ui/Input'
import TextArea from 'app/components/ui/textArea'
import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import { CardState } from 'app/components/ui/card/cardMaterial'

import { notEmpty } from 'app/core/utility/validation'
import {OKI_CARD_TYPE, ORG_CARD_TYPE} from '../../ui/constants'

export const cardLines = {
  name: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field card-name'
          label = 'Наименование'
          readOnly={cardState === CardState.view}
          value={editValue}
          onChange={value => onChange && onChange('name', value)}
          error={error}
          isRequired
        />
      )
    },
  },
  producerId: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props
      const {id, okz} = editValue || {}
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
      return (
        <Autocomplete 
          className='card-field card-producer'
          label = 'Производитель'
          readOnly={cardState === CardState.view}
          onEmptySearch = {true} 
          serviceType={ Services.organizations } 
          onSelect={value => onChange && onChange('producerId', value)}
          linkTarget={cardState === CardState.view && editValue && id && {targetType, id}}
          error={error}
          value={editValue}
          isRequired
        />
      )
    }
  },
  shortDescription: {
    isRequired: () => false,
    renderEdit: (props) => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field card-short-description'
          label='Краткое описание'
          value={editValue} 
          readOnly={cardState === CardState.view}
          error={error}
          onChange={value => onChange && onChange('shortDescription', value)} 
        />
      )
    },
  }, 
  skziTypeId: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
          <Autocomplete 
            className='card-field card-type'
            label = 'Тип'
            readOnly={cardState === CardState.view}
            onEmptySearch = {true} 
            serviceType={Services.skziTypes} 
            onSelect={value => onChange && onChange('skziTypeId', value)}
            error={error}
            value={editValue}
            isRequired
          />
        )
      },
  },
  updateDate: {
    // isRequired: () => true,
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <DatePicker
          className='card-field card-change-date'
          label='Дата изменения' 
          readOnly={cardState === CardState.view}
          error={error}
          value={editValue}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },  
  description: {
    isRequired: () => false,
    renderEdit: (props) => {
      const { cardState, editValue, onChange, error } = props

      return (
        <TextArea 
          className='card-field card-description'
          label='Описание продукта'
          readOnly={cardState === CardState.view}
          value={editValue} 
          error={error}
          onChange={value => onChange && onChange('description', value)}
        />
      )
    },
  },   

}