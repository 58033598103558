import {ActionType} from "../../actions/uploadProcessing/uploadProcessing";

const initialState = {
    isProcessing: false,
    createUploadState: false,
    processingData: [],
    parsedData: null,
    parsedFileInfo: null,

    lastUploads: [],
    currentProcessingFiles: [],
    metric: {},
    wsUploads: [],
    userHistory: [],
    singleParsingFileState: false,
    singleParsingFileID: null,
    importsUploadModal: false,
    importsUploadModalFileType: null,
    externalId: null,
    isProcessingLongPolling: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SetProcessingLongPolling:
            return {...state, isProcessingLongPolling: action.payload};
        case ActionType.SetExternalId:
            return {...state, externalId: action.payload};
        case ActionType.SetUploadModalState:
            return {...state, importsUploadModal: action.payload};
        case ActionType.SetUploadModalFileTypeState:
            return {...state, importsUploadModalFileType: action.payload};
        case ActionType.SetProcessing:
            return {...state, isProcessing: action.payload};
        case ActionType.SetProcessingData:
            return {...state, processingData: action.payload};
        case ActionType.SetParsedData:
            return {...state, parsedData: action.payload};
        case ActionType.SetParsedFileInfo:
            return {...state, parsedFileInfo: action.payload};
        case ActionType.SetLastUploads:
            return {...state, lastUploads: action.payload};
        case ActionType.SetCurrentProcessingFiles:
            return {...state, currentProcessingFiles: action.payload};
        case ActionType.SetMetric:
            return {...state, metric: action.payload};
        case ActionType.SetWSUploads:
            return {...state, wsUploads: action.payload};
        case ActionType.setUserHistory:
            return {...state, userHistory: action.payload};
        case ActionType.SetCreateUploadState:
            return {...state, createUploadState: action.payload};
        case ActionType.SetSingleFileParsing:
            return {...state, singleParsingFileState: action.payload};
        case ActionType.SetSingleFileParsingId:
            return {...state, singleParsingFileID: action.payload};
        default:
            return state;
    }
};

export {reducer};
