/** @format */

import Input from 'app/components/ui/Input';
import { formatDate } from 'app/core/utility/date';
import styles from './certificate.module.scss';
import FileItem from 'app/components/ui/file/fileItem';
import service from 'app/services/service';
import { CertificateSource } from './const';
import ConfirmDialog, { dialogMode, dialogType } from 'app/components/dialog/confirmDialog';
import { useState } from 'react';
import { formatFIO } from 'app/core/utility/common';

export default function RequisitesForm(props) {
  const {
    serialNumber = null,
    notValidBefore = null,
    validityDate = null,
    status = null,
    recipientName = null,
    issuedBy = null,
    intendedFor = null,
    lastSource = null,
    createDate = null,
    author = null,
    files,
    onFileDelete,
    keyDocumentId,
    onProgress,
  } = props;

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState(null);
  const deleteFile = async fileId => {
    await service('certificateService', 'deleteCertificateFile', { keyDocumentId, fileId });
  };

  const fileDeleteHandler = async fileId => {
    setDeleteDialogVisible(true);
    setDeleteFileId(fileId);
  };

  const runFileDeletion = async () => {
    onProgress(true);
    await deleteFile(deleteFileId);
    resetFileDeletion();
    onProgress(false);
    onFileDelete && (await onFileDelete());
  };

  const resetFileDeletion = () => {
    setDeleteDialogVisible(false);
    setDeleteFileId(null);
  };

  return (
    <>
      {deleteDialogVisible && (
        <ConfirmDialog
          mode={dialogMode.small}
          header={`Удалить сертификат?`}
          type={dialogType.danger}
          submitText="Удалить"
          onSubmit={runFileDeletion}
          onCancel={resetFileDeletion}
          messages={[
            'Сертификат будет безвозвратно удален из системы.',
            'При удалении всех вложений будут также удалены все реквизиты сертификата.',
          ]}
        ></ConfirmDialog>
      )}
      <div className={styles.requisites}>
        <Input className="card-field certificate-card__certificate" label="Сертификат" value={serialNumber} readOnly />
        <Input
          className="card-field certificate-card__createDate"
          label="Дата начала действия"
          value={formatDate(notValidBefore, 'dd.mm.yyyy hh:MM:ss')}
          readOnly
        />
        <Input
          className="card-field certificate-card__endDate"
          label="Дата окончания действия"
          value={formatDate(validityDate, 'dd.mm.yyyy hh:MM:ss')}
          readOnly
        />
        <Input className="card-field certificate-card__status" label="Статус" value={status?.name} readOnly />
        <Input className="card-field certificate-card__eskziUser" label="Кому выдан" readOnly value={recipientName} />
        <Input className="card-field certificate-card__receivedFrom" label="Кем выдан" value={issuedBy} readOnly />
        <Input
          className="card-field certificate-card__createdFor"
          label="Предназначен для"
          value={intendedFor}
          readOnly
        />
        <Input
          className="card-field certificate-card__source"
          label="Источник"
          value={lastSource?.name ?? ''}
          readOnly
        />
        {lastSource?.id === CertificateSource.Manual && (
          <>
            <Input
              className="card-field certificate-card__uploadAuthor"
              label="Автор загрузки"
              value={formatFIO(author)}
              readOnly
            />
            <Input
              className="card-field certificate-card__uploadDate"
              label="Дата загрузки"
              value={formatDate(createDate, 'dd.mm.yyyy hh:MM:ss')}
              readOnly
            />
          </>
        )}
      </div>
      {files && files.length ? (
        <div className={styles.files}>
          {files.map(file => {
            return (
              <FileItem
                file={{ fileKey: file.id, blob: { name: file.fileName, size: file.size } }}
                href={`/api/v1/KeyDocument/${keyDocumentId}/certificate/files/${file.id}/download`}
                onCancel={fileDeleteHandler}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}
