import React from 'react'
import { useHistory } from 'react-router-dom';
import Group from 'app/components/ui/managePanel/group';
import {Delete} from "app/components/list/TopPanel/Delete";
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';
import Button from 'app/components/ui/button/button'
import Img, { Image } from 'app/components/ui/Img';
import { Type as MassImportType } from 'app/components/MassImports/enums';
import MassImportButton from 'app/components/MassImports/MassImportButton';
import ExportToExcelButton from '../exportToExcelButton';

export default function BusinessEventPanel(props) {
  const { 
    globalOrgId,
    items,
    afterAction,
    filterForRequest,
    sorting,
    fieldsVisible,
    setSelectedAction,
    onInProgress,
    rights,
    massImportRights,
    isExternalUser
  } = props

  const history = useHistory();

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service('licenseService', 'deleteRange', items.map(i => i.id));
    onInProgress(false);
    return response;
  };

  return (
    <>
      {rights.CREATE ? (
        <Group>
          <Button
            type='primary'
            onClick={ () => { history.push('/license/create') }  }
          >
            <Img img={Image.Plus} className='button-image' />
            <span className='button-title'>Добавить</span>
          </Button>
        </Group>
      ) : null}
      <Group>
        { massImportRights.IMPORT && <MassImportButton type={MassImportType.LICENSE} tooltip="Загрузка лицензий из файла" /> }
        <ExportToExcelButton
          id={globalOrgId}
          listFilterForRequest={filterForRequest}
          sorting={sorting}
          listFieldsVisibility={fieldsVisible}
          selected={items.map(i=>i.id)}
          serviceName={isExternalUser ? 'SkziLicenseExternalOkiService' : 'licenseService'}
          onStart={() => onInProgress(true)}
          onFinish={() => onInProgress(false)}
        />
      </Group>
      {rights.DELETE ? (
        <Group>
          <Delete
            items={items.map(i=>i.id)}
            deleteItemsAction={deleteActionCallback}
            afterAction = {afterAction}
            setSelectedAction = {setSelectedAction}
            dialog ={data => {
                const arr = ['выбранную лицензию', 'выбранные лицензии', 'выбранные лицензии']
                return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`
            }}
          />
        </Group>
      ) : null}
    </>
  )
}