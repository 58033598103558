/** @format */

import Button, { buttonTypes } from 'app/components/ui/button/button';
import withTooltip from 'app/components/HOC/TooltipHOC';
import Img from '../Img';

const ButtonWithTooltip = withTooltip(Button);

export default function ComplectationButton({ logic, tooltip, buttonText }) {
  const { disabled, onAct } = logic;

  return (
    <ButtonWithTooltip
      type={buttonTypes.image}
      onMouseDown={onAct}
      tooltip={!buttonText ? tooltip : ''}
      disabled={disabled}
      caption={buttonText}
    >
      <Img type={'ComplectationImg'} />
    </ButtonWithTooltip>
  );
}
