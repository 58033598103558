import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers/root'
import thunk from 'redux-thunk'

const initialState = {}
const middleware = [thunk]

const composeSetup = process.env.NODE_ENV === 'development' && typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(rootReducer, initialState, composeSetup(
  applyMiddleware( ...middleware )
))

export default store

















// import { createStore, applyMiddleware, compose } from 'redux'
// import { connectRouter, routerMiddleware } from 'connected-react-router'
// import thunk from 'redux-thunk'
// import createHistory from 'history/createBrowserHistory'
// import rootReducer from './modules'

// export const history = createHistory()

// const initialState = {}
// const enhancers = []
// const middleware = [
//   thunk,
//   routerMiddleware(history)
// ]

// if (process.env.NODE_ENV === 'development') {
//   const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

//   if (typeof devToolsExtension === 'function') {
//     enhancers.push(devToolsExtension())
//   }
// }

// const composedEnhancers = compose(
//   applyMiddleware(...middleware),
//   ...enhancers
// )

// const store = createStore(
//   connectRouter(history)(rootReducer),
//   initialState,
//   composedEnhancers
// )

// export default store

