/** @format */

import {
  BREADCRUMBS,
  BREADCRUMBSCLICK,
  BREADCRUMBSPUSHITEM,
  BREADCRUMBSPOPITEM,
  BREADCRUMBS_CLEAR,
} from '../actionTypes';

import { getPageBreadcrumb } from 'app/navigation/breadcrumbs';

export const breadcrumbsAction =
  ({ componentPart }) =>
  dispatch => {
    dispatch({
      type: BREADCRUMBS,
      payload: {
        componentPart: componentPart,
      },
    });
  };

export const breadcrumbsClickAction = item => dispatch => {
  dispatch({
    type: BREADCRUMBSCLICK,
    payload: item,
  });
};

export const breadcrumbsPushAction =
  (path, title, forceUpdate = false, virtualBreadcrumbs = []) =>
  dispatch => {
    dispatch({
      type: BREADCRUMBSPUSHITEM,
      payload: {
        ...getPageBreadcrumb(path, title, virtualBreadcrumbs),
        forceUpdate,
      },
    });
  };

export const breadcrumbsPopAction = () => dispatch => {
  dispatch({
    type: BREADCRUMBSPOPITEM,
    payload: null,
  });
};

export const breadcrumbsClearAction = () => dispatch => {
  return dispatch({
    type: BREADCRUMBS_CLEAR,
    payload: null,
  });
};
