/** @format */
import { humanTextFileSize } from 'app/core/utility/file';
import { ReactComponent as CancelImg } from 'assets/img/commonVer2/close.svg';
import Button from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC';
import styles from './fileUploadControl.module.scss';

const InfoMessage = withTooltip(Img);

const FileData = ({ icon, name, size, fileSize, href }) => {
  return (
    <>
      {icon}
      <div className={styles.fileItemName}>{name}</div>
      <div className={styles.fileItemSize}>{size ? `(${humanTextFileSize(size)})` : fileSize ? fileSize : ''}</div>
      {href && <Img img={Image.Download} className={styles.downloadImg}/>}
    </>
  );
};

export default function FileItem({ className, file, progress, icon, error, onCancel, hideCancel, href }) {
  const { blob: fileObject, fileKey } = file;
  const { name, size, fileSize } = fileObject;
  const { errorMessage } = error || {};

  return (
    <div className={`${styles.fileItem} ${className || ''}`}>
      {href ? (
        <a className={`${styles.fileItemData} ${styles.download}`} href={href} download>
          <FileData icon={icon} name={name} size={size} fileSize={fileSize} href={href}/>
        </a>
      ) : (
        <div className={`${styles.fileItemData}`}>
          <FileData icon={icon} name={name} size={size} fileSize={fileSize} />
        </div>
      )}
      {error && (
        <InfoMessage
          className={styles.fileItemErrorIcon}
          img={Image.AlertTriangle}
          tooltip={errorMessage}
          tooltipPosition={Position.Left}
        />
      )}
      <>
        {progress ? (
          <div className={styles.fileItemProgress}>
            <div className={styles.progressBackground}>
              <div className={styles.currentProgress} style={{ width: `${Math.floor(progress * 100)}%` }}></div>
            </div>
          </div>
        ) : null}
        {!hideCancel && (
          <div className="file-item__cancel">
            {onCancel && progress !== 1 ? (
              <Button className="file-item__cancel" type="image" onClick={() => onCancel(fileKey)}>
                <CancelImg className="button-image" />
              </Button>
            ) : null}
          </div>
        )}
      </>
    </div>
  );
}
