import { useCallback, useEffect, useState } from 'react'
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { useDispatch, useSelector } from 'react-redux'
import { excludeByItemId } from 'app/core/utility/common';
import { dispatchLogic } from 'redux/actions/common'
import { okzTableConfig } from './servedOrganizations';

export default function useLinkedOrg ({
  id,
  list: propsList, 
  add,
  deleteSelected,
  onUpdate,
  isOkz
}) {

  const {
    onUpdateValues,
    syncFrontListSortAction,
    sortListAction,
    sortListSetAction,
    setSelectedAction,
    onRemoveSelected,
    onInProgress,
    inProgress,
    sorting,
    selectedItems,
    list,
    paginator,
  } = useTableExtensions();

  const globalDispatch = useDispatch()
  const [showPicker, setShowPicker] = useState(false)
  const [searchResult, setSearchResult] = useState(0)
  const [search, setSearch] = useState('')  
  const [totalCount, setTotal] = useState(propsList ? propsList.length : 0)
  const error = useSelector(({error}) => error )
  const { errorObject, fieldsError } = error || {}
  const errorToShow = errorObject 
                      ? errorObject.error || {}
                      : fieldsError.OrganizationsIds

  useEffect(() => {
    syncFrontListSortAction( sorting, okzTableConfig.fieldsConfig, list )
  }, [sorting])

  useEffect(() => {
    if (Array.isArray(propsList)) {
      onUpdateValues(propsList)
    }
  }, [propsList])

  const onDelete = useCallback(
    async () => {
      onInProgress(true)
      const response = await deleteSelected({ id, ids: selectedItems}, isOkz)
      const { errors, data } = response;
      const deleted = (data ? data : selectedItems).map(item => ({id: item}))
      const updated = excludeByItemId(list, deleted)
      const isError = errors && errors.length

      if (!isError) {   
        setTotal(updated.length)
        onUpdateValues(updated)
        onUpdate && onUpdate(updated)
        onRemoveSelected(data ? data : selectedItems)
      }

      isError && dispatchLogic(globalDispatch, '', null, true, errors )
      onInProgress(false)
    },
    [selectedItems, list, isOkz, id],
  )

  const onAdd = useCallback(
    async (items) => {
      onInProgress(true)
      setShowPicker(false)

      const response = await add({id, ids:items.map(item => item.id)}, isOkz)
      const { errors, data } = response;
      const isError = errors && errors.length
      const updatedList = [...items, ...list]

      if (!isError) {   
        setTotal(updatedList.length)
        onUpdateValues(updatedList)
        onUpdate && onUpdate(updatedList)
      }
      
      isError && dispatchLogic(globalDispatch, '', null, true, errors )
      onInProgress(false)
    },[list, id, isOkz])

  return {
    searchResult,
    onAdd,
    onDelete,
    setSearch,
    search,
    showPicker, 
    setShowPicker,
    setSearchResult,
    errorToShow,
    sortListAction,
    sortListSetAction,
    setSelectedAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    paginator,
    totalCount
  }
}