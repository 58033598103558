import React, { PureComponent } from 'react'

import TabsHeader from './tabsHeader'
import Tab from './tab'
import Slider from '../slider'

export const TABSIZE = {
  small: 'small',
  medium: 'medium'
}

class Tabs extends PureComponent {
  render() {
    const { children, activeTab } = this.props
    const tabsChildren = children && children.length 
                            ? children 
                            : children 
                              ? [children]
                              : []
    const validChildren = tabsChildren.filter(tab => !!tab)
    const displayTab = validChildren.find(item => item.props.tabTitle === activeTab)

    return (
      <div className='tabs-container'>
        <Slider
          forTabs={true}
        >
          <TabsHeader
            {...this.props}
            tabs={validChildren} 
          />
        </Slider>
        {displayTab ? (
          <Tab
            key={displayTab.props.tabTitle}
            tabTitle={displayTab.props.tabTitle}
            activeTab={activeTab}
          >
            {displayTab}
          </Tab>
        ) : null}
      </div>
    )
  }
}

export default Tabs