import React from 'react'

export default function VisibleTab (props){
  const { tabTitle, visibility, children } = props

  return (
      <div key={tabTitle} style={visibility} className='tab-container'>
        {children}
      </div>
    )

}
