import React, {useEffect, useState} from 'react'

import ExpandingBlock from 'app/components/ui/expandingBlock'
import { Table} from "../list/Table/Table";
import useTableExtensions from '../list/Table/useTableExtensions';
import { setSelectedAction, sortListAction, sortListSetAction } from '../../../redux/actions/list/listActions'
import { useDataTable } from '../list/Table/useDataTable'
import { ReactComponent as UploadImg } from 'assets/img/commonVer2/ic_file_upload.svg'
import { ReactComponent as OrderImg } from 'assets/img/commonVer2/plus.svg'
import Button from 'app/components/ui/button/button'
import LicensingFileModal from "./LicensingFileModal";
import OrderModal from "./OrderModal";
import useToast from "../ui/toast/useToast";
import {CARDINPROGRESS} from "../../../redux/actions/actionTypes";
import {useDispatch, useSelector} from "react-redux";
import service from "../../services/service";
import {dispatchLogic} from "../../../redux/actions/common";
import Overlay from 'app/components/ui/overlay'
import { sortDirection } from '../ui/constants';

const pageConfig = {
  itemsPerPage : 9999999,
  defaultSortByAlias : 'type',
  defaultSortDirection : sortDirection.desc,
  filterMultiSelect : false,
  changedFieldsVisibility : false,
  itemsSelectable : true,
  itemsSelectableRadio : false,
}

const types = {
  Hardware: "Аппаратные СКЗИ",
  Software: "Программные СКЗИ",
  Documents: "Ключевые документы",
  OtherSkzi: "Другие"
}

function replaceInfinity(value){
  return value === "Infinity" ? "Бесконечно" : value
}

const config = {
  pageConfig : pageConfig,
  fieldsConfig : [
    {
      title : 'Тип лицензий',
      titleInfo : 'Тип лицензий',
      alias : 'type',
      format : item => types[item.type],
      visibleOnStart : true,
    },
    {
      title : 'Всего лицензий',
      titleInfo : 'Всего лицензий',
      alias : 'count',
      format : item => replaceInfinity(item.count) || '0',
      visibleOnStart : true,
    },
    {
      title : 'Используемые лицензии',
      titleInfo : 'Используемые лицензий',
      alias : 'using',
      format : item => replaceInfinity(item.using) || '0',
      visibleOnStart : true,
    },
    {
      title : 'Свободные лицензии',
      titleInfo : 'Свободные лицензий',
      alias : 'available',
      format : item => replaceInfinity(item.available) || '0',
      visibleOnStart : true,
    },
  ],
}


export default function LicensingPage(){

  const { inProgress, sorting, selectedItems, list, paginator, dispatch } =
    useTableExtensions({
      defaultSort: {
        column: 'type',
        direction: sortDirection.desc,
      },
    });

  const type = 'licensing'

  const { getList } = useDataTable({
    sorting,
    type,
    dispatch
  })

  const {addToast} = useToast()

  const [isUploadModalOpen, setUploadModalOpen] = useState(false)
  const [isOrderModalOpen, setOrderModalOpen] = useState(false)
  const [emailTo, setEmailTo] = useState('')
  const [subject, setSubject] = useState('')
  const [productName, setProductName] = useState('')

  const dispatchStore = useDispatch()
  const inProgressSubject = useSelector(({card}) => card.cardInProgress)

  useEffect( () => {
    const getLicenseManagerEmail = async () => {
      dispatchStore({type: CARDINPROGRESS, payload: true})
      const response = await service('licensingService', 'licenseManagerEmail')
      dispatchStore({type: CARDINPROGRESS, payload: false})
      const {data, isError, errors} = response || {}
      if(response && data){
        setEmailTo(data && data.managerEmail ? data.managerEmail : '')
        setProductName(data && data.product ? data.product : '')
        setSubject(data && data.emailSubject ? data.emailSubject : '')
      }
      dispatchLogic(dispatch, 'MOCK', data, isError, errors )
    }
    getLicenseManagerEmail();
  }, [])

  return (
    <div className="licensing-page">
      {inProgressSubject && <Overlay />}
      <ExpandingBlock
        renderHeader= {() => 'Активные лицензии'}
        initialState={true}
        className="licensing-block"
      >
        <div className = { type + "__buttons action_panel manage_panel list__manage-panel" }>
            <div>
              <div className="manage-panel__item">
                <Button
                  className='sync-button'
                  type='secondary'
                  onClick={() => setUploadModalOpen(true)}
                  disabled={ inProgress }
                >
                  <UploadImg className='button-image' />
                  <span className='button-title'>Загрузить лицензии</span>
                </Button>
              </div>
              <div className="manage-panel__item">
                <Button
                  className='sync-button'
                  type='secondary'
                  onClick={() => setOrderModalOpen(true)}
                  disabled={ inProgress }
                >
                  <OrderImg className='button-image' />
                  <span className='button-title'>Заказать лицензии</span>
                </Button>
              </div>
            </div>
          {
            isUploadModalOpen &&
            <LicensingFileModal
              onCancel={() => setUploadModalOpen(false)}
              onFileUploaded={(response) => {
                const {errors, isError} = response.length && response[0] && response[0].axiosResult || {}
                if (!errors && !isError) {
                  addToast('Файл успешно добавлен. Страница будет перезагружена.')
                  setUploadModalOpen(false)
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000)
                } else {
                  addToast('Произошла ошибка')
                }
              }}
            />
          }
          {
            isOrderModalOpen &&
            <OrderModal
              emailTo={emailTo}
              subject={subject}
              productName={productName}
              onCancel={() => setOrderModalOpen(false)}
            />
          }
          </div>
            <Table
              sortListAction={params => dispatch(sortListAction(params))}
              sortListSetAction={params => dispatch(sortListSetAction(params))}
              paginator={paginator}
              sorting={sorting}
              selectedItems={selectedItems}
              setSelectedAction={params => dispatch(setSelectedAction(params))}
              inProgress={inProgress}
              noBorders={false}
              list={list}
              fieldsConfig={ config.fieldsConfig }
              itemsSelectableRadio={ config.pageConfig.itemsSelectableRadio }
              defaultSortByAlias={ config.pageConfig.defaultSortByAlias }
              defaultSortDirection={ config.pageConfig.defaultSortDirection }
              changedFieldsVisibility={ config.pageConfig.changedFieldsVisibility === true }
              headerInfo={ config.pageConfig.headerInfo === true }
            />
      </ExpandingBlock>
    </div>
  )
}
