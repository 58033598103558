import React, {Component, useEffect} from 'react'
import { useSelector, connect } from 'react-redux'
import { compose } from 'redux'
import withRoutingProps from 'app/components/HOC/RoutingPropsHOC'

import Navigation from '../navigation/navContainer'
import { AUTHTYPE } from 'app/core/auth/auth'
import Header from '../header/header'
import WorkArea from '../workArea/workArea'
import Login from '../login'
import { loadLocalState } from 'app/core/localStorage/currentTheme'
import { setThemeVariables } from './theme'
import { authorizeAction, logoutAction, hideTrialAction, authorizeDomainAction } from 'redux/actions/authorize/authorizeAction'
import { addToast } from 'redux/actions/toast'
import { cleanErrorPageError, clearErrorAction } from 'redux/actions/common'
import withAuth from '../components/HOC/AuthHOC'
import service from '../services/service'
import ToastList from '../components/ui/toast/ToastList'
import {texts} from "../components/infoPages/ErrorPage";
import {ActionCreator} from "../../redux/actions/uploadProcessing/uploadProcessing";
import {MassImports} from "app/components/MassImports/MassImports";
import ErrorModal from 'app/components/ui/ErrorModal'

export default function ToastedApp(){

  const {list} = useSelector(state => state.toast)

  return (
    <>
      <ToastList toastList={ list }/>
      <ConnectedAppContainer />
      <MassImports />
      <ErrorModal />
    </>
  )
}

class AppContainer extends Component {
  constructor(props){
    super(props)

    this.state = this._initState()
    setThemeVariables(this.state.currentTheme)
  }

  _initState = () => {

    return {
      menuOpened: false,
      currentTheme: loadLocalState(),
      loading: false,
      authMethods: [],
    }
  }

  UNSAFE_componentWillMount () {
    const { addToast } = this.props
    this.setState({...this.state, loading: true})
    service('authorizeService', 'getSettingsAuth').then(e => {
      if(e && e.data){
        this.setState({...this.state, authMethods: e.data, loading: false})
      } else {
        this.setState({loading: false})
        addToast(texts.serverError)
      }
    }).catch(() => {
      this.setState({loading: false})
      addToast(texts.serverError)
    })
    // тут будет попытка скозной атворизации если доступен authMethod === AD
    // service('authorizeService', 'domainLogin')
    const { cleanErrorPageError } = this.props
    this.unlisten = this.props.history.listen((location, action) => {

      // to do - refactor/think about
      //
      // add fetching data from server by this event, not by componentDidMount
      // e.g. when url === /organizations execute getOrganizationData action and so on
      // On the other hand, when component mounts user sees some layout without data - user-friendly interface :)
      // But component is fetching data, it's bad, it's violates mvc pattern, component should be plain and stupid...
      cleanErrorPageError()
    });

    this._checkGlobalErrors()
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidUpdate () {
    const { profile } = this.props
    const { isAuthorised, isLoggedOut, userType } = profile || {}

    // in case of poib after logout we have to reload current page
    if (userType === AUTHTYPE.poib && isLoggedOut) {
      window.location.reload(true)
      return
    }

    // no need to redirect if we are already on login page, show all errors there
    if (!isAuthorised) {
      return
    }

    this._checkGlobalErrors()
  }

  _checkGlobalErrors = () => {
    const { history, error, addToast, cleanErrorPageError } = this.props
    const { location } = history
    const { pathname } = location
    const { errorPageError } = error
    const { status } = errorPageError || {}

    if (status === 0) {
      pathname !== '/connectionError' && history.push('/connectionError')
    } else if ( status && status >= 401 && status < 600) {
      
      switch (status) {
        case 406:
          // forbiden - business errors
          // need to show modal error message
          break
        case 401:
          // we can get this only if use our auth
          // - login page should be shown, see ERRORPAGEERROR on authorizeReducer
          break
        case 413:
          // too heavy operation, performing in background asynchroniously
          break
        case 428:
          // warning
          // if user choose YES resent request data
          break
        case 403:
          pathname !== '/unauthorized' && history.push('/unauthorized')
          break
        case 404:
          pathname !== '/notFound' && history.push('/notFound')
          break
        case 500:
          if(pathname !== '/serverError'){
            addToast(texts.serverError)
            cleanErrorPageError()
          }
          break
        default:
          pathname !== '/unexpectedError' && history.push('/unexpectedError')
          break
      }
    }
  }

  onMenuToggle = () => {
    const { menuOpened } = this.state;

    this.setState({menuOpened: !menuOpened})
  }

  _onLogout = () => {
    const { logoutAction, profile, history, setFileSingleParsing, setFileSingleParsingId } = this.props
    const { logoutUrl, userType = AUTHTYPE.poib } = profile

    logoutAction(logoutUrl, userType)
      .then(() => {
        setFileSingleParsing(false);
        setFileSingleParsingId(null);
        history.push('/')
      })
  }

  _renderLogin = () => {
    const {
      profile,
      authorizeAction,
      error,
      isPasswordRecovery,
      history,
      authorizeDomainAction
    } = this.props

    const { authMethods, loading } = this.state

    const { isAuthorised, inProgress, userType = AUTHTYPE.aokz } = profile
    const { errorPageError } = error || {}
    const { status } = errorPageError || {}
    const needToShow = (userType === AUTHTYPE.aokz || userType === AUTHTYPE.ad)
      && (status === 401
        || isPasswordRecovery
        || !isAuthorised)

    return needToShow ? (
      <Login
        loading={loading}
        authMethods={authMethods}
        inProgress={inProgress}
        onSubmit={authorizeAction}
        onDomainSubmit={authorizeDomainAction}
        history={history}
        isPasswordRecovery={isPasswordRecovery}
      />
    ) : null
  }

  _renderMainPage = () => {
    const { profile, hideTrialAction, isPasswordRecovery, error, history } = this.props
    const { errorPageError } = error || {}
    const { status } = errorPageError || {}

    if (status === 401 || isPasswordRecovery) {
      return null
    }

    const { isAuthorised } = profile
    const { menuOpened } = this.state

    return isAuthorised
      ? (
        <>

          <div className={`app-container`}>
            <Navigation
              history={history}
              profile={profile}
              menuOpened={menuOpened}
              onMenuToggle={this.onMenuToggle}
            />
            <Header
              onLogout= {this._onLogout}
              profile = {profile}
              history={history}
              onMenuToggle={this.onMenuToggle}
            />
            <WorkArea />
          </div>
        </>
      ) : null
  }

  render() {
    return (
      <>
        {this._renderMainPage()}
        {this._renderLogin()}
      </>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  error: state.error
})

const ConnectedAppContainer = connect(mapStateToProps, {
    authorizeAction,
    authorizeDomainAction,
    addToast,
    logoutAction,
    hideTrialAction,
    cleanErrorPageError,
    clearErrorAction,
    setFileSingleParsing: ActionCreator.setFileSingleParsing,
    setFileSingleParsingId: ActionCreator.setFileSingleParsingId
  }
)(compose(withRoutingProps, withAuth)(AppContainer))