import {IMPORTS_CHIPS} from 'app/components/MassImports/configs/importChips';
import {DUPLICATE_FIELD} from 'app/components/MassImports/configs/duplicateField';
import {PAYLOAD} from 'app/components/MassImports/configs/payload';
import {RESULTS} from 'app/components/MassImports/configs/results';

export const Config = {
    IMPORTS_CHIPS,
    DUPLICATE_FIELD,
    PAYLOAD,
    RESULTS,
};
