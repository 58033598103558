import {ActionType} from "../../actions/license/license";

const initialState = {
    availableLicense: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SetAvailableLicense:
            return {...state, availableLicense: action.payload};
        default:
            return state;
    }
};

export {reducer};
