import React, { useState } from 'react'
import { formatFIO } from 'app/core/utility/common'
import Checkbox from '../ui/checkbox'
import ExpandingBlock from '../ui/expandingBlock'
import { Services } from '../ui/picker/picker'
import DestructionObjects from './destructionObjects'
import {ReactComponent as SkziAccountingImg }  from 'assets/img/menu/ic_catalog.svg';
import {ReactComponent as KeyDocumentImg }  from 'assets/img/menu/ic_keydocument.svg';
import { ReactComponent as KeyCarrierImg }  from 'assets/img/menu/ic_key_carrier.svg';
import { sortDirection } from '../ui/constants'

export const formatSKZI = item => {
  const { skzi: skziData } = item
  const { version, skzi, build  } = skziData || {}
  const { name: skziName = '' } = skzi || {}
  const { name: versionName = ''} = version || {} 
  const { name: buildName = '' } = build || {}

  return `${skziName} ${versionName} ${buildName ? `(${buildName})` : ''}`
}

const pageConfig = { 
  itemsPerPage : 9999999,
  defaultSortByAlias : 'id',
  defaultSortDirection : sortDirection.desc,
  filterMultiSelect : false,
  changedFieldsVisibility : false,
  itemsSelectable : true,
  itemsSelectableRadio : false,      
}

const formatChildKeyDocument = ({item = {}, parentSelected }) => {
  const { id, name = '', serialNumber='', eskziUser, identificationNumber = '' } = item || {}

  return (
    <>
      <Checkbox
        onChange={ null }
        checked={ parentSelected }
        disabled={true}
      />
      <KeyDocumentImg className='child-info__icon'/>
      <span className="child-info">
        {`Связанный ключевой документ: ${name}, № ${id}, Серийный №: ${serialNumber}, № экземпляра: ${identificationNumber}, Пользователь: ${formatFIO(eskziUser)}`}
      </span>
    </>
  )
};

const kcConfig = {
  pageConfig : pageConfig,
  fieldsConfig : [
    {
      title : '',
      alias : '',
      selectAsParent: true,
      type : 'childrenData',
      openChildrenByDefault: true,
      tdText : () => '',
      format : formatChildKeyDocument,
      visibleOnStart : true,
      width : 68
    },
    { 
      title : '',
      alias : '', 
      format : () => <KeyCarrierImg className='item-icon'/>,
      visibleOnStart : true, 
      useSorting : false, 
      width : 50
    },
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      sortByValue : item => item.id,
      visibleOnStart : true, 
      useSorting : true, 
      width : 50
    },
    { 
      title : 'Серийный №',
      alias : 'serialNumber', 
      sortByValue : item => item.serialNumber,
      format : item => item.serialNumber,
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : 'Заводской №',
      alias : 'factoryNumber', 
      sortByValue : item => item.factoryNumber,
      format : item => item.factoryNumber,
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : 'Тип',
      alias : 'type', 
      sortByValue : item => item.keyCarrierType ? item.keyCarrierType.name : '',
      format : item => item && item.keyCarrierType && item.keyCarrierType.name || '',
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : 'Разовый',
      alias : 'single', 
      sortByValue : item => item.single,
      format : item => item && item.single ? 'Да' : 'Нет',
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : 'Пользователь',
      alias : 'eskziUser', 
      sortByValue : item => formatFIO(item.eskziUser),
      format : item => formatFIO(item.eskziUser),
      visibleOnStart : true, 
      useSorting : true, 
    }
  ],
}

const kdConfig = {
  pageConfig : pageConfig,
  fieldsConfig : [
    {
      title : '',
      alias : '',
      selectAsParent: true,
      type : 'childrenData',
      openChildrenByDefault: true,
      tdText : item => (''),
      format : ({item = {}, parentSelected }) => {
        const { id, keyCarrierType = '', serialNumber = '', eskziUser, factoryNumber = '', single } = item || {}
        const { name = '' } = keyCarrierType || {}
       
        return (
          <>
            <Checkbox
              onChange={ null }
              checked={ parentSelected }
              disabled={true}
            />
            <KeyCarrierImg className='child-info__icon'/>
            <span className="child-info__text">
              {`Связанный ключевой носитель ${single ? '(разовый)' : ''}: ${name}, № ${id}, Серийный №: ${serialNumber}, Заводской №: ${factoryNumber}, Пользователь: ${formatFIO(eskziUser)}`}
            </span>
          </>
        )
      },
      visibleOnStart : true,
      width : 68
    },
    { 
      title : '',
      alias : '', 
      format : () => <KeyDocumentImg className='item-icon'/>,
      visibleOnStart : true, 
      useSorting : false, 
      width : 50
    },
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      sortByValue : item => item.id,
      visibleOnStart : true, 
      useSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name', 
      format : item => item.name,
      sortByValue : item => item.name,
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : 'Серийный №',
      alias : 'serialNumber', 
      sortByValue : item => item.serialNumber,
      format : item => item.serialNumber,
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : '№ Экземпляра',
      alias : 'identificationNumber', 
      sortByValue : item => item.identificationNumber,
      format : item => item.identificationNumber,
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : 'Пользователь',
      alias : 'eskziUser', 
      sortByValue : item => formatFIO(item.eskziUser),
      format : item => formatFIO(item.eskziUser),
      visibleOnStart : true, 
      useSorting : true, 
    }
  ],
}

const eskziConfig = {
  pageConfig : pageConfig,
  fieldsConfig : [
    {
      title : '',
      alias : '',
      selectAsParent: true,
      type : 'childrenData',
      openChildrenByDefault: true,
      tdText : item => (''),
      format : formatChildKeyDocument,
      visibleOnStart : true,
      width : 68
    },
    { 
      title : '',
      alias : '', 
      format : () => <SkziAccountingImg className='item-icon'/>,
      visibleOnStart : true, 
      useSorting : false, 
      width : 50
    },
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      sortByValue : item => item.id,
      visibleOnStart : true, 
      useSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name', 
      sortByValue : formatSKZI,
      format : formatSKZI,
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : 'Регистрационный №',
      alias : 'serialNumber', 
      sortByValue : item => item.serialNumber,
      format : item => item.serialNumber,
      visibleOnStart : true, 
      useSorting : true, 
    },
    { 
      title : 'Пользователь',
      alias : 'eskziUser', 
      sortByValue : item => formatFIO(item.eskziUser),
      format : item => formatFIO(item.eskziUser),
      visibleOnStart : true, 
      useSorting : true, 
    }
  ],
}

export default function DestructionObjectSet({
  organizationId,
  onKdChanged,
  onKcChanged,
  onEskziChanged,
  eskzis,
  keyCarriers,
  keyDocuments,
}) {
  return (
    <>
      <ExpandingBlock
        className='destruction-items'
        renderHeader= {() => 'Ключевые документы'}
        initialState={true}
        disabled={!organizationId}
      >
        <DestructionObjects
          config={kdConfig}
          organizationId={organizationId}
          onListChanged={onKdChanged}
          parentData={keyDocuments}
          serviceType={Services.keyDocument}
        />
      </ExpandingBlock>
      <ExpandingBlock
        className='destruction-items'
        renderHeader= {() => 'Ключевые носители'}
        initialState={true}
        disabled={!organizationId}
      >
        <DestructionObjects
          config={kcConfig}
          parentData={keyCarriers}
          organizationId={organizationId}
          onListChanged={onKcChanged}
          serviceType={Services.keyCarrier}
        />
      </ExpandingBlock>
      <ExpandingBlock
        className='destruction-items'
        renderHeader= {() => 'Экземпляры СКЗИ'}
        initialState={true}
        disabled={!organizationId}
      >
        <DestructionObjects
          config={eskziConfig}
          parentData={eskzis}
          onListChanged={onEskziChanged}
          organizationId={organizationId}
          serviceType={Services.skziInstance}
        />
      </ExpandingBlock>
    </>   
  )
}
