import React from 'react'
import Img, { Image } from 'app/components/ui/Img';
import { getClassName } from 'app/core/utility/jsx';

export default function Tag({ 
  className,
  text,
  onDelete,
  item,
  link,
  icon,
  children,
  onClick
}) {
  return (
    <div className={getClassName(['tag-container', className])} onClick={onClick}>
      {icon}
      {
        link ? (
          <a href={link} className='tag-container__label-link' download>
            {text}
          </a>
        ) : (
          <span className='tag-container__label'>{text}</span>
        )
      }
      {onDelete && (
        <Img
          img={Image.CloseCircle}
          className='tag-container__delete' 
          title='Удалить'
          onClick={() => onDelete(text, item)}
        />
      )}
      {children}
    </div>
  )
}