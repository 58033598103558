import { 
  ESKZICARDGET, 
  ERROR, 
  WARNING,
  FIELDSERROR,
  ERRORPAGEERROR,
  ESKZICARDTABCLICK,
  ESKZICARDINPROGRESS,
  ESKZICOMMONDATADELETE,
  ESKZICARDEVENTFORMCHANGED, 
  ESKZICOMMONDATAUPDATE, 
  ESKZICARDUPDATED, 
  ESKZICARDESKZICARDGETEVENTS,
  ESKZIEVENTSUPDATETASK,
  SIGNATUREREADYTOSIGN,
  ESKZICARDONRUNEVENT,
  ESKZICARDRESETSTATE,
  ESKZICARDRUNEVENT,
  ESKZICOMMONDATAGETBUILDDATA,
  ESKZICARDSERVICEGETACTIONS  ,
  ESKZICARDSERVICEUPDATEACTION,
  ESKZICARDSERVICEADDACTION   ,
  ESKZICARDSERVICEDELETEACTION,
  ESKZICARDSERVICEONUPDATEACTION,
  ESKZICARDSERVICEONADDACTION,
  ESKZICARDSERVICERESETACTION,
  ESKZICARDSERVICEONADDFORMCHANGEDACTION,
  ESKZICARDSERVICEONUPDATEFORMCHANGEDACTION,
  ESKZICARDSERVICEONDELETEACTION,
  SIGNATURESETREQUESTDATA,
  ESKZICARDGETLICENSES,
  ESKZICARDADDLICENSES,
  ESKZICARDDELETELICENSES
} from '../../actions/actionTypes'
import { dialogType } from 'app/components/dialog/confirmDialog/'
import { updateCollectionByItemId } from 'app/core/utility/common'

const defaultState = {
  cardData: {},
  tabs: { primaryActive: 'Общие данные', secondaryActive: '' },
  serviceData: {
    pageItems: [],
    editActionDialog: null,
    addActionDialog: null,
    deleteActionObject: null,
    confirmObject: null
  },
  licenseData: {
    items: []
  },
  eventRunObject: null,
  inProgress: false,
}

export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { tabs: stateTabs, cardData: stateCardData, serviceData } = state
    const { pageItems: stateServiceItems } = serviceData

    switch (action.type) {
      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case ESKZICARDGETLICENSES:
        return {
            ...state,
            licenseData: {
              items : [...payload.map(item => ({...item.skziLicense, ...item}))]
            },
            inProgress: false
        }

      case ESKZICARDGET:
        return {
          ...state,
          cardData: {...payload},
          inProgress: false
        }
      case ESKZICOMMONDATAUPDATE:
        return {
            ...state,
            cardData: {...payload},
            eskziUpdated: true,
            inProgress: false
        }

      case ESKZICARDUPDATED:
        return {
            ...state,
            eskziUpdated: false,
        }

      case ESKZICARDONRUNEVENT:
        const { eskzi, action: eventTemplate } = payload
        const { model } = eventTemplate
  
        return {
          ...state,
          eventRunObject: {
            action: eventTemplate,
            item: eskzi,
            formData: model.reduce((acc, cur) => {
              acc[cur.key] = cur.defaultValue || null
  
              return acc
            }, {})
          },
        }

      case ESKZICARDTABCLICK: 
        const { tabs } = state

        return {
          ...state,
          tabs: {...tabs,...payload}
        }

      case ESKZICARDINPROGRESS: 
        return {
          ...state,
          inProgress: payload
        }

      case SIGNATUREREADYTOSIGN:
      case ESKZIEVENTSUPDATETASK:
      case ESKZICARDESKZICARDGETEVENTS: 
      case ESKZICOMMONDATAGETBUILDDATA:
      case ESKZICOMMONDATADELETE:
        return {
          ...state,
          inProgress: false
        }

      case ESKZICARDRESETSTATE:
        return {
          ...defaultState,
          cardData: {...stateCardData},
          tabs: {...stateTabs}
        }

      case ERROR:
        return {
          ...state,
          activityInfoObject: null,
          activityCancelObject: null,
          confirmObject: null,
          finishNoSignTaskObject: null,
          finishedTaskInfoObject: null,
          errorObject: {
            error: errors[0],
            type: dialogType.warning,
          },
          inProgress: false
        }
      
      case FIELDSERROR:
      case WARNING:
        return {
          ...state,
          inProgress: false
        }

      case ESKZICARDRUNEVENT:
        return {
          ...state,
          cardData: {...payload},
          eventRunObject: null,
          inProgress: false
        }

      case ESKZICARDEVENTFORMCHANGED:
        const { eventRunObject } = state
        const { formData } = eventRunObject
  
        return {
          ...state,
          eventRunObject: {
            ...eventRunObject,
            formData: {...formData, ...payload}
          }
        }

      case ESKZICARDSERVICEGETACTIONS:
        const storeItems = payload.map((item, index) => ({ ...item, index: index + 1}))

        return {
          ...state,
          inProgress: false,
          serviceData: {
            ...serviceData,
            pageItems: storeItems
          }
        }

      case ESKZICARDSERVICEADDACTION:
        return {
          ...state,
          serviceData: {
            ...serviceData,
            pageItems: [ payload, ...stateServiceItems ].map((item, i) => ({...item, index: i + 1})),
            addActionDialog: null
          },
          inProgress: false
        }

      case ESKZICARDSERVICEUPDATEACTION:
        return {
          ...state,
          serviceData: {
            ...serviceData,
            pageItems: updateCollectionByItemId(stateServiceItems, payload),
            editActionDialog: null
          },
          inProgress: false
        }

      case ESKZICARDSERVICEDELETEACTION:
        const { deleteActionObject } = serviceData
        const filtered = stateServiceItems.filter((item) => item.id !== deleteActionObject.id).map((item, i) => ({ ...item, index: i + 1}))

        return {
          ...state,
          serviceData: {
            ...serviceData,
            pageItems: filtered,
            deleteActionObject: null,
            confirmObject: null
          },
          inProgress: false
        }

      case  ESKZICARDSERVICEONADDACTION:
        return {
          ...state,
          serviceData: {
            ...serviceData,
            addActionDialog: {
              initData: {
                name: '',
                actionDate: null,
                comments: '',
                performer: ''
              },
              formData: {
                name: '',
                actionDate: null,
                comments: '',
                performer: ''
              }
            },
          }
        }

      case ESKZICARDSERVICEONUPDATEACTION:
        return {
          ...state,
          serviceData: {
            ...serviceData,
            editActionDialog: {
              initData: payload,
              formData: payload
            },
          }
        }

      case ESKZICARDSERVICERESETACTION: 
        return {
          ...state,
          serviceData: {
            ...serviceData,
            editActionDialog: null,
            addActionDialog: null,
            confirmObject: null,
            deleteActionObject: null
          }
        }
        
      case ESKZICARDSERVICEONADDFORMCHANGEDACTION:
        return {
          ...state,
          serviceData: {
            ...serviceData,
            addActionDialog: payload,
          }
        }

      case ESKZICARDSERVICEONUPDATEFORMCHANGEDACTION:
        return {
          ...state,
          serviceData: {
            ...serviceData,
            editActionDialog: payload,
          }
        }

      case ESKZICARDSERVICEONDELETEACTION:
        const { name: actionName } = payload

        return {
          ...state,
          serviceData: {
            ...serviceData,
            confirmObject: {
              type: dialogType.confirm,
              title: `Вы уверены, что хотите удалить действие «${actionName}»?`,
              item: payload
            },
            deleteActionObject: payload
          }
        }

      case SIGNATURESETREQUESTDATA:

        return {
          ...state,
          serviceData: {
            ...serviceData,
            confirmObject: null,
          }
        }

      default: 
          return state
    }
}