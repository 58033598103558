import React, { useEffect } from 'react'
import {
  mountAction,
  unmountAction,
  getListAction,
  inProgressOnAction,
  inProgressOffAction
} from  'redux/actions/list/listActions'
import {useDispatch} from 'react-redux'
import {dispatchLogicToasts} from '../../../../redux/actions/common'

// obsolete
// это видимо последствия какого-то рефакторинга,
// надо избавляться в пользу useJournal, либо его составляющих (useTableExtensions...)
// пока непонятно где это надо использовать, тем более что тут ненужный 
// код вроде mountAction, inProgressOnAction
export const useDataTable = (params) => {

  const dispatchRedux = useDispatch()

  const {
    sorting,
    type,
    dispatch
  } = params

  useEffect( () => {
    dispatch(mountAction( type))
    return function unmount(){
      dispatch(unmountAction( type ))
    };
  }, []);

  const getList = () => {
    if( sorting.column === '' ) return;
    const requestParams = {
      column : sorting.column,
      direction : sorting.direction || -1,
    };

    dispatch(inProgressOnAction())
    dispatch(getListAction( requestParams ))
      .then((res) => {
        const {data, errors, isError} = res
        dispatchLogicToasts(dispatchRedux, '', data, isError, errors)
      })
      .then(() => { dispatch(inProgressOffAction()) })
  }

  useEffect(() => { getList() }, [ sorting ]);

  return ({getList})
}
