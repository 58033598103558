import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {dispatchLogic} from "redux/actions/common";
import tableConfig from "./useTableConfig";

export default function useFileTable({deleteFileService, urlPart, setSelected, fileRights }){
  const [ changed, setChanged ] = useState(null)
  const [ currentlyDeletingFiles, setCurrentlyDeletingFiles ] = useState({})
  const columns = tableConfig({urlPart, currentlyDeletingFiles, onFileDelete, fileRights})
  const dispatch = useDispatch()

  function onFileDelete(guids){
    const guidsObj = guids.reduce((acc, cur) => {
      acc[cur.guid] = cur
      return acc
    }, {})
    setCurrentlyDeletingFiles({
      ...currentlyDeletingFiles,
      ...guidsObj
    })

    return deleteFileService(guids)
      .then(response => {
        const { isError, errors, data } = response || {}

        if (data && data.length) {
          setChanged({deleted: data})
          setSelected([])
        }
        if (isError) {
          dispatchLogic(dispatch, 'ERROR', null, true, errors)
        }

        setCurrentlyDeletingFiles(prevFiles => {
          const newState = {...prevFiles}
          guids.forEach(el => {
            delete newState[el.guid]
          });
          return newState
        })
      })
  }

  const fileUploaded = (result) => {
    const fileResults = result.map(fileRes => {
      const { axiosResult } = fileRes
      const { data: uploadFileGuidData, isError } = axiosResult || {}
      return { ...uploadFileGuidData, isError}
    }).filter(i => !i.isError)

    setChanged({added: fileResults})
  }

  return {
    columns,
    onFileUploaded: fileUploaded,
    changed, 
    setChanged,
    onFileDelete
  }
}