/** @format */

import React from 'react';
import { formatDate } from 'app/core/utility/date';
import { KEY_DOCUMENT_CARD, E_SKZI_REGISRTY_CARD } from 'app/components/ui/constants';
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo';
import { eventDate, formatSKZITechnicalJournal } from 'app/components/list/Table/TableBody';
import ListLink from 'app/components/ui/ListLink';
import { FilterControlType } from 'app/components/filter/FilterElement';

export const signerFormat = item => {
  const { signature, user } = item;
  const { certificateInfo, signer } = signature || {};
  const { name } = signer || {};

  return certificateInfo ? <SignatureInfo signData={{ ...signature, signer: user }} title={name} /> : null;
};

export const config = {
  fieldsConfig: [
    {
      title: '№',
      titleInfo: '№',
      alias: 'number',
      format: item => item.number,
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 50,
    },
    {
      title: 'Дата',
      titleInfo: 'Дата',
      alias: 'actionDate',
      format: ({ actionDate }) => formatDate(actionDate, 'dd.mm.yyyy'),
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 100,
    },
    {
      title: 'Тип и серийные номера СКЗИ',
      titleInfo: 'Тип и серийные номера СКЗИ',
      alias: 'eskzi',
      format: item => {
        const name = item.eskzi ? formatSKZITechnicalJournal(item.eskzi.skzi, item.eskzi.serialNumber) : '';
        const { id } = item.eskzi || {};

        return name && id ? <ListLink text={name} targetType={E_SKZI_REGISRTY_CARD} id={id} /> : name || '';
      },
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 400,
    },
    {
      title: 'Записи по обслуживанию',
      titleInfo: 'Записи по обслуживанию',
      alias: 'actionName',
      format: item => item.actionName,
      visibleOnStart: true,
      useSorting: true,
      width: 250,
    },
    {
      title: 'Тип ключевого документа',
      titleInfo: 'Тип ключевого документа',
      alias: 'keyDocumentType',
      format: item => {
        const { keyDocument, keyDocumentType } = item;
        const { keyCarrier } = keyDocument || {};
        const { keyCarrierType } = keyCarrier || {};
        const { name } = keyCarrierType || {};

        return name || keyDocumentType || '';
      },
      visibleOnStart: true,
      useSorting: true,
      width: 200,
    },
    {
      title: 'Серийный, криптографический номер и номер экземпляра ключевого документа',
      titleInfo: 'Серийный, криптографический номер и номер экземпляра ключевого документа',
      alias: 'keyDocument',
      format: item => {
        const name = item.keyDocument
          ? `${item.keyDocument.serialNumber} экз. № ${item.keyDocument.identificationNumber}`
          : '';
        const { id } = item.keyDocument || {};
        return name && id ? <ListLink text={name} targetType={KEY_DOCUMENT_CARD} id={id} /> : name || '';
      },
      visibleOnStart: true,
      useSorting: true,
      width: 375,
    },
    {
      title: 'Номер разового ключевого носителя или зоны СКЗИ, в которую введены криптоключи',
      titleInfo: 'Номер разового ключевого носителя или зоны СКЗИ, в которую введены криптоключи',
      alias: 'keyCarrierNumber',
      format: item => {
        const { keyDocument, keyCarrierNumber } = item;
        const { keyCarrier } = keyDocument || {};
        const { serialNumber, single } = keyCarrier || {};
        const carrierNumber = serialNumber || keyCarrierNumber || '';
        return single ? carrierNumber : '';
      },
      visibleOnStart: true,
      useSorting: true,
      width: 400,
    },
    {
      title: 'Дата отметки об уничтожении',
      titleInfo: 'Дата отметки об уничтожении',
      alias: 'withdrawalDate',
      format: item => eventDate(item.withdrawal, item.destruction) || '',
      visibleOnStart: true,
      useSorting: true,
      width: 200,
    },
    {
      title: 'Подпись пользователя СКЗИ',
      alias: 'Подпись пользователя СКЗИ',
      width: 200,
      visibleOnStart: true,
      format: signerFormat,
    },
    {
      title: 'Примечание',
      titleInfo: 'Примечание',
      alias: 'comments',
      width: 200,
      format: item => item.comments,
      visibleOnStart: false,
    },
  ],
  tablePageFiltersConfig: [
    {
      type: FilterControlType.simpleSearch,
      alias: 'id',
      label: '№',
      pattern: '^[0-9]*$',
      extended: false,
      order: 2,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'serialNumbers',
      label: 'Регистрационный N',
      extended: false,
      order: 3,
      requestFormatIsArray: true,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'keyDocumentId',
      label: 'Ключевой документ',
      serviceType: 'keyDocument',
      extended: false,
      order: 4,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'producerId',
      label: 'Производитель',
      serviceType: 'producers',
      extended: true,
      order: 6,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziId',
      label: 'Продукт',
      serviceType: 'skzi',
      dependsOn: 'producerId',
      hardDepends: true,
      extended: true,
      order: 7,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'receivedFrom',
      label: 'Получено от',
      extended: true,
      order: 8,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'sentTo',
      label: 'Передано в',
      serviceType: 'organizationServed',
      extended: true,
      order: 9,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziVersionId',
      label: 'Версия',
      serviceType: 'skziVersion',
      dependsOn: 'skziId',
      hardDepends: true,
      dependantDisabled: true,
      extended: true,
      order: 10,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziBuildId',
      label: 'Сборка / Модификация',
      serviceType: 'skziBuild',
      dependsOn: 'skziVersionId',
      hardDepends: true,
      dependantDisabled: true,
      extended: true,
      order: 11,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: 'registerDate',
      label: 'Зарегистрирован',
      extended: true,
      order: 12,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: 'expirationDate',
      label: 'Действует',
      extended: true,
      order: 13,
    },
  ],
};
