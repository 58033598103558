import Button from "app/components/ui/button/button";
import Checkbox from 'app/components/ui/checkbox';
import withSignatureHOC from "app/components/HOC/withSignatureHOC";
import CreateControl from "app/components/MassImports/CreateControl";
import { useDispatch, useSelector } from "react-redux";
import { getWSUploads } from "redux/actions/uploadProcessing/selectors";
import { getUserHistory } from "redux/actions/uploadProcessing/selectors";
import { Url, RequestType } from "app/components/MassImports/enums";
import { getEnumTypeByString } from "app/components/MassImports/utils";
import service from "app/services/service";
import { ActionCreator } from "redux/actions/uploadProcessing/uploadProcessing";
import { dispatchLogic } from "redux/actions/common";
import { Type } from "app/components/MassImports/enums";

import styles from './Footer.module.scss';

const CreateButtonWithSign = withSignatureHOC(CreateControl);

export const Footer = ({
    parseFileInfo,
    acceptedFileTypesForSignature,
    isSignatureRequired,
    totalCountDownloads,
    shortOfLicenses,
    importDups,
    setImportDups,
    onClose
}) => {
    const userHistory = useSelector(getUserHistory);
    const dispatch = useDispatch();
    const WSUploads = useSelector(getWSUploads);
    const {fileId, fileType} = parseFileInfo;

    const generateDataForRequest = () => ({ fileId, considerDuplicates: importDups });

    const onCreateButtonHandler = async (signatureData) => {
        const response = await (isSignatureRequired
            ? service('MassImports', 'postProcessWithSignature', { signatureData, importType: Url[getEnumTypeByString(fileType)], considerDuplicates: importDups })
            : service('MassImports', 'postProcess', {importType: Url[getEnumTypeByString(fileType)], fileId, considerDuplicates: importDups})
        );
        
        const {data, errors, isError} = response;
        dispatchLogic(dispatch, 'MOCK', data, isError, errors)

        dispatch(ActionCreator.setCreateUploadState(true));
        dispatch(ActionCreator.setParsedFileInfo(null));
        dispatch(ActionCreator.setExternalIdState(
            WSUploads
                .filter(item => item.fileId === fileId)[0]
                .file.externalId)
        );
        setTimeout(() => dispatch(ActionCreator.setCreateUploadState(false)), 3000);
    };
    const onCancelCreateButtonHandler = async () => {
        await service('MassImports', 'discardScannedFile', {importType: Url[getEnumTypeByString(fileType)], fileId});
        dispatch(ActionCreator.setWSUploads(WSUploads.filter(item => item.fileId !== fileId)));
        dispatch(ActionCreator.setUserHistory(userHistory.filter(item => item.fileId !== fileId)));
        dispatch(ActionCreator.setParsedFileInfo(null));
    };
    
    const importDisabled = totalCountDownloads === 0 || shortOfLicenses;

    return <div className={styles.footer}>
        <Button
                type={'text'}
                disabled={false}
                onClick={onCancelCreateButtonHandler}
                notSubmit
                className={`btn-cancel ${styles.btnCancel}`}
            >
                Отменить и удалить
        </Button>
        {[Type.LICENSE].includes(fileType) && <Checkbox
          onChange={() => setImportDups(!importDups)}
          checked={importDups}
          className={`cb-import-dups ${styles.cbImportDups}`}
        >
          Включая дубли
        </Checkbox>}
        {
            (acceptedFileTypesForSignature.some(item => item === fileType))
            && isSignatureRequired
                ? <CreateButtonWithSign
                    disabled={importDisabled}
                    generateDataForRequest={generateDataForRequest}
                    actionCallback={onCreateButtonHandler}
                    signType={RequestType[getEnumTypeByString(fileType)]}
                    signHeader='Создать записи'
                />
                : <Button
                    className={'modal-container__footer-create-btn'}
                    type={'primary'}
                    disabled={importDisabled}
                    onClick={onCreateButtonHandler}
                    notSubmit
                >
                    Создать записи
                </Button>
        }
        <Button
            type={'text'}
            onClick={onClose}
            notSubmit
        >
            Закрыть
        </Button>
    </div>;
};
