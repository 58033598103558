import React from 'react'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'

export default function UserCardModals(props){
  
  const {
    modalState,
    onConfirmDelete,
    userCardReset,
    onBack
  } = props

  const { confirmDeleteObject, deletedInfoObject, resetPasswordInfoObject, unblockInfoObject, blockInfoObject } = modalState
  const { title: confirmTitle, type: confirmType, item: confirmItem } = confirmDeleteObject || {}
  const { title: deletedInfoTitle, type: deletedInfoType, item: deletedInfoItem } = deletedInfoObject || {}
  const { title: resetTitle, type: resetType, item: resetItem } = resetPasswordInfoObject || {}
  const { title: unblockTitle, type: unblockType, item: unblockItem } = unblockInfoObject || {}
  const { title: blockTitle, type: blockType, item: blockItem } = blockInfoObject || {}

  return (
    <>
      {confirmDeleteObject ?
        <ConfirmDialog
          title={confirmTitle}
          type={confirmType}
          item={confirmItem}
          onSubmit={onConfirmDelete}
          onCancel={userCardReset}
        /> : null
      }
      {blockInfoObject ?
        <ConfirmDialog
          title={blockTitle}
          type={blockType}
          item={blockItem}
          onCancel={userCardReset}
        /> : null
      }
      {unblockInfoObject ?
        <ConfirmDialog
          title={unblockTitle}
          type={unblockType}
          item={unblockItem}
          onCancel={userCardReset}
        /> : null
      }
      {resetPasswordInfoObject ?
        <ConfirmDialog
          title={resetTitle}
          type={resetType}
          item={resetItem}
          onCancel={userCardReset}
        /> : null
      }
      {deletedInfoObject ?
        <ConfirmDialog
          title={deletedInfoTitle}
          type={deletedInfoType}
          item={deletedInfoItem}
          onCancel={onBack}
        /> : null
      }
    </>
  )
}