import { useIsVisible } from "app/components/HOC/hooks/useIsVisible";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./popupPanel.module.scss";

const PopupPanel = ({ className, onClose = null, fullWidth, children }) => {
  const ref = useRef(null);
  const leftEdge = useRef(null);
  const rightEdge = useRef(null);
  const isLeftOnScreen = useIsVisible({ ref: leftEdge, initial: true });
  const isRightOnScreen = useIsVisible({ ref: rightEdge, initial: true });
  const [cssClass, setCssClass] = useState(fullWidth ? styles.fullWidth : styles.leftPopup);

  const onClickOut = useCallback((e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      onClose && onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClickOut);

    return () => {
      document.removeEventListener("click", onClickOut);
    };
  }, []);

  useEffect(() => {
    if (!isRightOnScreen && !isLeftOnScreen) {
      onClose && onClose();
    }

    if (fullWidth) {
      setCssClass(styles.fullWidth)
      return;
    }

    if (isLeftOnScreen && cssClass === styles.rightPopup) {
      return;
    }

    setCssClass(isLeftOnScreen ? styles.leftPopup : styles.rightPopup);
  }, [isLeftOnScreen, isRightOnScreen]);

  return (
    <div ref={ref} className={`popup-panel ${styles.popupPanel} ${cssClass}`}>
      <div ref={leftEdge}></div>
      <div className={className || ""}>{children}</div>
      <div ref={rightEdge}></div>
    </div>
  );
};

export default PopupPanel;

