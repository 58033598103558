/** @format */

import { flattenedMenuContent } from './navigationContent';

const compileFirstBreadcrumbs = (path, virtualBreadcrumbs) => {
  const candidateSubpaths = path
    .split('/')
    .filter(item => !!item)
    .reduce((acc, cur) => {
      const prevPart = acc[acc.length - 1];
      return [...acc, `${prevPart ? prevPart : ''}/${cur}`];
    }, []);

  const virtualBreadcrumbsMap = Object.fromEntries(virtualBreadcrumbs?.map(vb => [vb.id, vb]) ?? []);

  const isValid = item => !!flattenedMenuContent[item] || !!virtualBreadcrumbsMap[item];

  return candidateSubpaths
    .filter(isValid)
    .map(item => ({ ...(flattenedMenuContent[item] ?? virtualBreadcrumbsMap[item]) }));
};

const getPageBreadcrumb = (path, title, virtualBreadcrumbs) => {
  const bcData = flattenedMenuContent[path];
  const { title: bcTitle, link, headerTitle } = bcData ?? {};
  const currentBreadcrumb = {
    title: bcTitle ?? title,
    link: link ?? path,
    headerTitle: headerTitle ?? title,
    disabled: false,
    breadcrumbsTitle: true,
  };

  const compiled = compileFirstBreadcrumbs(path, virtualBreadcrumbs);
  const alreadyIn = compiled.find(item => item.link === currentBreadcrumb.link);

  return {
    firstBreadcrumbs: alreadyIn || !title ? compiled : [...compiled, currentBreadcrumb],
    current: currentBreadcrumb,
  };
};

export { getPageBreadcrumb };
