import useToast from "app/components/ui/toast/useToast"
import service from "app/services/service"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { dispatchLogic } from "redux/actions/common";
import { setNeedRefreshByRest } from "redux/actions/notification";

export function useReplicateEtalon({onCancel, eskziId}){
  const {addToast} = useToast()
  const dispatch = useDispatch()
  const [ inProgress, setProgress ] = useState(false)
  const [ validLicensesCount, setValidLicensesCount ] = useState(0)

  const getSignRequestData = data => {
    return {
      replicationItems: data.map(i => ({
        organizationId: i.organizationId,
        licenses: i.licenses ? i.licenses.map(i=>i.id) : []
      })),
      eskziId: eskziId,
      signatureRequestType: 'replicateOKZ' // нужно для выбора апи подписи
    }
  }

  const replicateEtalonCallback = async (signedData) => {
    setProgress(true)
    const response = await service('replicationService', 'replicateOKZ', signedData) 
      
    const {isError, isWarn, errors, data} = response || {}

    if (!isError && !isWarn) {
      addToast('Распространение эталона успешно запущено')
      dispatch(setNeedRefreshByRest(true))
      onCancel()
    } else {
      dispatchLogic(dispatch, 'MOCK', data, isError, errors)
    }

    setProgress(false)
    return response
  }

  useEffect(() => {
    const updateLicenseData = async () => {
      setProgress(true)
      const response = await service('licenseService', 'getUnusedEskziLicense', {eskziId: eskziId }) 
        
      const {isError, errors, data, dataCount} = response || {}
  
      if (!isError) {
        setValidLicensesCount(dataCount)
      } else {
        dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      }
  
      setProgress(false)
    }
    updateLicenseData()
  }, [])

  return {
    replicateEtalonCallback,
    getSignRequestData,
    inProgress,
    validLicensesCount
  }
}