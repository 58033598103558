import React from 'react'

import Button from 'app/components/ui/button/button'
import { ReactComponent as SignImg } from 'assets/img/commonVer2/ic_sign.svg'

const SignatureTrigger = ({onClick, title, disabled}) => {
  return (
    <Button 
      type={`${title ? 'text': 'image'}`}
      className={`signature-trigger`}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      disabled={disabled}
    >
      {
        title ? <span className='button-title'>{title}</span> : null
      }
      <SignImg className={`button-image ${title ? 'button-image--right' : ''} `}></SignImg>
    </Button>
  )
}

export default SignatureTrigger