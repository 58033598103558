import { 
  LICENSEUPLOADCOMPLETE,
} from '../../actions/actionTypes'
import config from 'app/core/config'
import { updateCollectionByAnotherCollection, updateCollectionByItemId } from 'app/core/utility/common'


export const traverseTree = (items) => {
  if (!items || !items.length) {
    return null
  }

  return items.map((item) => {
    return {
      ...item,
      ...item.entity,
      children: traverseTree(item.children)
    }
  })
}


export default (state = [], action, prefix = '' ) => {
  
  if( action.type === prefix + 'LIST_GET'
      || action.type === 'LIST_UPDATE' ){
    if( !action.payload || !action.payload.length ) return [];
    action.payload.map( el => { 
      if( el.id === undefined && el.number !== undefined ) el.id = el.number;
      return el;
    })
    return [ ...action.payload ]; 
  }

  if (action.type === 'LIST_PAGINATION_GET'){
    if (!action.payload) {
      return state
    }

    return action.payload.map( el => { 
      if( el.id === undefined && el.number !== undefined ) el.id = el.number;
      return el;
    })
  }
 
  if ( action.type ===  prefix + LICENSEUPLOADCOMPLETE) {
    return [...action.payload, ...state]
  }

  if( action.type === prefix + 'LIST_GET_ITEM' ){
    if( !action.payload ) return state;
    return [...state].map( el => {
      if( el.id === action.payload.id ){
        return action.payload;
      }
      return el;
    })
  }

  if( action.type === prefix + 'LIST_UPDATE_BY_COLLECTION' ){
    let list = [ ...state ];
    return updateCollectionByAnotherCollection(list, action.payload);
  }

  if( action.type === prefix + 'LIST_CLIENT_SORTING' ){
    let list = [ ...state ];
    return list;
  }

  if( action.type === prefix + 'LIST_ADD_ITEMS' ){
    return [...action.payload, ...state]
  }

  if( action.type === prefix + 'LIST_TREE_SORT' ){
    return [...action.payload]
  }

  if( action.type === prefix + 'LIST_TREE_ADD_ITEMS' ){
    return [...action.payload, ...state]
  }

  if( action.type === 'LIST_ITEM_UPDATE' ){
    return updateCollectionByItemId(state, action.payload)
  }

  if( action.type === 'LIST_TREE_UPDATE'
    || action.type === 'LIST_TREE_GET'){
    return traverseTree(action.payload)
  }

  if( action.type === prefix + 'LIST_REMOVED' ){
    let arr = action.payload || [];
    let list = [ ...state ].filter(el => arr.indexOf(el.id) === -1 );
    return list;
  }

  if( action.type === prefix + 'LIST_REMOVED_SELECTED' ){
    let temp = [ ...state ];
    
    return temp.filter( el => action.payload.indexOf(el.id) === -1 );
  }

  if( action.type === prefix + 'LIST_DROP' ){
    return [];
  }

  return state;
}