import React, { Component } from 'react'

import { cssModuleClassNameGetter } from 'app/core/utility/jsx';

import DatePicker from '../DatePicker/DatePicker';
import styles from './DateRangePicker.module.scss';

const getClass = cssModuleClassNameGetter(styles);

class DateRangePicker extends Component {

  _onFromChanged = value => {
    const { onChangeFrom, toDate } = this.props

    onChangeFrom && onChangeFrom({ from: value, to: toDate })
  }

  _onToChanged = value => {
    const { onChangeFrom, fromDate } = this.props

    onChangeFrom && onChangeFrom({ from: fromDate, to: value })
  }

  render() {
    const { 
      fromDate, 
      toDate, 
      className,
      labelFrom,
      labelTo,
      label = ''
    } = this.props

    const actualLabelFrom = labelFrom || (label ? `${label} c` : 'Дата c')
    const actualLabelTo = labelTo || (label ? `${label} по` : 'Дата по')

    return (
      <div className={getClass([className, 'date-range-picker'], ['dateRangePicker'])}>
        <DatePicker 
          {...this.props}
          className='date-range-picker__date'
          maxDate={toDate ? new Date(toDate) : null}
          label = {actualLabelFrom}
          value={fromDate}
          onChange={this._onFromChanged}
        />
        <DatePicker 
          {...this.props}
          className='date-range-picker__date'
          minDate={fromDate ? new Date(fromDate) : null}
          label = {actualLabelTo}
          value={toDate}
          onChange={this._onToChanged}
        />
      </div>
    )
  }
}

export default DateRangePicker