import { 
  TRAININGCOURSESGETALL,
  TRAININGCOURSESONSELECT,
  TRAININGCOURSESDELETE,
  TRAININGCOURSESINPROGRESS, 
  TRAININGCOURSESRESETACTION, 
  TRAININGCOURSESCLEARSTOREACTION,
  TRAININGCOURSESONDELETE,
  TRAININGCOURSESPAGINATIONGET,
  ERROR,
  ERRORPAGEERROR
} from '../../actions/actionTypes'

import { getPageData } from 'app/components/ui/pagination/pagination'
import config from 'app/core/config'
import { dialogType } from 'app/components/dialog/confirmDialog/'
import { arrayToIdHashMap, updateCollectionByItemId, excludeByItemId, deleteArrayItemById } from 'app/core/utility/common'

const { paginationPerPage } = config

const defaultState = {
  items: [],
  selected: {
    items: [],
    selectedHashMap: {}
  },
  pageItems: [],
  pagination: { activePage: 1, itemsPerPage: paginationPerPage, dataCount: 0 },
  confirmObject: null,
  errorObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { 
      items,
      selected,
      pagination, 
  } = state
  const { activePage: stateActivePage, itemsPerPage: statePerPage } = pagination
  const {data, dataCount} = payload || {}

  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }

    case TRAININGCOURSESGETALL:
      return {
        ...state,
        items: data,
        pagination: {activePage: 1, itemsPerPage: statePerPage, dataCount: dataCount},
        inProgress: false
      }

    case TRAININGCOURSESPAGINATIONGET:
      const { activePage, itemsPerPage } = payload

      return {
        ...state,
        pagination: { activePage, itemsPerPage },
        pageItems: getPageData({ items, activePage, itemsPerPage }),
        inProgress: false
      }

    case TRAININGCOURSESONDELETE:
      const { confirmObject } = payload
      return {
        ...state,
        confirmObject
      }
 
    case TRAININGCOURSESONSELECT: 
      return {
        ...state,
        selected: payload
      }

    case TRAININGCOURSESDELETE:
      let confirmDialog = null
      const { items: selectedItems } = selected
      const afterDeleteItems = excludeByItemId(items, payload.map(item => ({id: item})))
      const afterDeleteSelected = excludeByItemId(selectedItems, payload.map(item => ({id: item}))) 

      if (errors && errors.length) {
        confirmDialog = {
          header: 'Удаление невозможно',
          type: dialogType.warning,
          messages: errors.map(item => item.errorMessage)
        }
      }
     
      return {
        ...state,
        selected: {
          items: afterDeleteSelected,
          selectedHashMap: arrayToIdHashMap(afterDeleteSelected)
        },
        items: afterDeleteItems,
        pageItems: getPageData({
            items: afterDeleteItems,
            activePage: stateActivePage,
            itemsPerPage : statePerPage
        }),
        inProgress: false,
        confirmObject: confirmDialog,
        errors
      }

    case ERROR:
      return {
        ...state,
        confirmObject: null,
        inProgress: false
      }

    case TRAININGCOURSESINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case TRAININGCOURSESRESETACTION: 
      return { 
        ...state, 
        confirmObject: null,
        error: null
      }

    case TRAININGCOURSESCLEARSTOREACTION:
      return {
        ...defaultState
      }

    default: 
      return state
  }
}