import React from 'react'

import FileItem from '../fileItem'
import Button from 'app/components/ui/button/button'
import withModal from 'app/components/HOC/ObsoleteModalHOC'

const fileProgressDialog = (props) => {
  const { files, progressData, onCancel } = props

  return (
    <div className='file-progress-dialog'>
      <div className='file-progress-dialog__header'>Загрузка файлов</div>
      <div className="file-progress-dialog__file-area">
        {files.map(file => {
          const { result } = file
          const { errors } = result || {}
          const [ error ] = errors || []

          return (
            <FileItem
              className='file-progress-dialog__file-item'
              progress={progressData[file.fileKey]}
              file={file}
              error={error}
            />)
        })}
      </div>
      <Button
        className='footer__item'
        type = 'secondary'
        onClick = {onCancel}
      >
        <span className='button-title'>Закрыть</span>
      </Button>
      
    </div>
  )
}

export default withModal(fileProgressDialog)