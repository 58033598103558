import {useReducer} from "react";

export default function useReducerWithThunk(reducer, initialState) {
  const [state, dispatch] = useReducer(reducer, initialState);
  let customDispatch = (action) => {
    if (typeof action === 'function') {
      return action(customDispatch);
    } else {
      return dispatch(action);
    }
  };
  return [state, customDispatch];
}
