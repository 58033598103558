/** @format */
import React, { Component } from 'react';

import Input from 'app/components/ui/Input';
import { debounce } from 'app/core/utility/common';
import Img, { Image } from 'app/components/ui/Img';

import { cssModuleClassNameGetter } from 'app/core/utility/jsx';

import styles from './SimpleSearch.module.scss';

const getClass = cssModuleClassNameGetter(styles);

export default class SimpleSearch extends Component {
  constructor(props) {
    super(props);
  }

  _search = debounce(value => {
    const { onSearch } = this.props;

    onSearch && onSearch(value);
  }, 400);

  _onValueChanged = value => {
    const { onChange } = this.props;

    // to do
    // make control disabled while search runs
    // otherwise there are many bugs
    // this._search(value)
    onChange(value);
  };

  _onRunSearch = () => {
    const { onSearch, disabled, value } = this.props;

    !disabled && onSearch && onSearch(value);
  };

  render() {
    const { value, error, placeholder, label, className, disabled, pattern, noHint } = this.props;

    return (
      <Input
        className={getClass([className, 'simple-search'], ['simpleSearch'])}
        placeholder={placeholder}
        label={label}
        value={value}
        pattern={pattern}
        onChange={this._onValueChanged}
        search={true}
        CustomImage={() => (
          <Img
            img={Image.Search}
            className={getClass(['search-trigger'], ['searchTrigger'])}
            title={'Поиск'}
            onClick={this._onRunSearch}
          />
        )}
        onSubmit={this._onRunSearch}
        error={error}
        disabled={disabled}
        noHint={noHint}
      />
    );
  }
}
