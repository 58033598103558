import React from 'react';
import { formatFIO, stringPlural } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import { USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { FilterControlType } from '../filter/FilterElement';
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo'
import {
  formatSKZI,
} from 'app/components/list/Table/TableBody'

const config = {
  fieldsConfig : [
    { title : '№ заключения', 
      alias : 'number',
      visibleOnStart : true, visibleAlways : true, useSorting : true, width : 180 }, 

    { title : 'Дата заключения', alias : 'issueDate', 
      format : item => formatDate( item.issueDate, 'dd.mm.yyyy' ),
      visibleOnStart : true, 
      useSorting : true, width : 150 },

    { title : 'СКЗИ', alias : 'skzi', 
      format : item => item.eskzi && formatSKZI( item.eskzi.skzi ),
      visibleOnStart : true, visibleAlways : true,
      useSorting : true 
    },
    { title : '№ экземпляра СКЗИ', alias : 'eskzi', 
      format :  item => {
        const { eskzi } = item
        const { serialNumber = ''} = eskzi
        return serialNumber
    },
      visibleOnStart : true,
      useSorting : true },
    {
      title : 'Пользователь СКЗИ',
      alias : 'eskziUser',
      format: item =>{
        return item.eskziUser
          ? <ListLink
            text={formatFIO(item.eskziUser)}
            targetType={USER_ACCOUNT_CARD_TYPE}
            id={item.eskziUser.id}
          />
          : formatFIO(item.eskziUser)
      },
      visibleOnStart : true,
      useSorting : true
    },
    {
      title : 'Автор',
      alias : 'author',
      format: item => {
        const { author } = item || {}
        const { signature, user } = author || {}
        const { certificateInfo } = signature || {}

        return certificateInfo ?
        <>
          {formatFIO(user)}
          <SignatureInfo 
            signData={{ ...signature, signer: user }}
          />
        </>
         : formatFIO(user)
      },
      visibleOnStart : true,
      useSorting : true
    },
    { title : 'Номер АРМ', alias : 'hardwareNumber', 
      format :  item => item.hardwareNumber,
      visibleOnStart : true,
      useSorting : true },

    { title : 'Номер печати или пломбира', alias : 'printNumber', 
      format :  item => item.printNumber,
      visibleOnStart : false,
      useSorting : true },

    { title : 'Дата удаления дистрибутива', alias : 'distributiveRemovingDate', 
      format : item => formatDate( item.distributiveRemovingDate, 'dd.mm.yyyy' ), 
      visibleOnStart : false,
      useSorting : true, width : 100
    },
    { 
      title : 'ФИО исполнителя', 
      alias : 'performer', 
      format : item => formatFIO( item.performer ),
      visibleOnStart : true, 
      useSorting : true, 
    },

    { title : 'Дата изменения', alias : 'updateDate', 
      format : item => formatDate( item.updateDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true,
      useSorting : true, width : 100
    },
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'number', 
      label : '№',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.autocompletePicker, 
      alias : 'eskziUserId', 
      label : 'Пользователь СКЗИ', 
      serviceType : 'skziUser',
      extended : false,
      order : 3
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'hardwareNumber', 
      label : 'Место установки', 
      extended : false,
      order : 4
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziVersionId', 
      label : 'СКЗИ', 
      serviceType : 'skziVersion',
      extended : true,
      order : 6
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'eskziNumber', 
      label : '№ экземпляра СКЗИ',
      extended : true,
      order : 7
    },
    {
      type : FilterControlType.dateRangePicker, 
      alias : 'createDate', 
      label : 'Дата',
      extended : true,
      order : 8,
    },
    {
      type : FilterControlType.autocompletePicker, 
      alias : 'performerId', 
      label : 'Исполнитель', 
      serviceType : 'user',
      extended : true,
      order : 9
    },
    {
      type : FilterControlType.autocompletePicker, 
      alias : 'authorId', 
      label : 'Автор', 
      serviceType : 'user',
      extended : true,
      order : 10
    }
  ]
}

export default config