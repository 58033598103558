export enum CryptoProviderType {
  CRYPTO_PRO = 'CRYPTO_PRO',
  JINN_CLIENT = 'JINN_CLIENT',
}

export interface ICertificate {
  hash: string;
  name: string;
  subject?: string;
  issuer?: string;
  from?: string;
  to?: string;
  serial?: string;
  version?: number;
}

export interface ICryptoProviders {
  CRYPTO_PRO?: {
    name?: string;
    version?: string;
    plugin?: string;
    certsList?: Array<ICertificate>;
    currentCert?: string;
  };
  JINN_CLIENT?: {
    name?: string;
    version?: string;
  };
}

export enum SignatureFormat {
  CADES_BES = 'CAdES-BES', // CP: CADESCOM_CADES_BES = 1, JINN: ?
  CADES_T = 'CAdES-T', // CP: CADESCOM_CADES_T = 0x5, JINN: ?
  CADES_C = 'CAdES-C', // JINN: ?
  CADES_X_TYPE_1 = 'CAdES-X Type 1',
  CADES_X_TYPE_2 = 'CAdES-X Type 2',
  CADES_X_LONG_TYPE_1 = 'CAdES-X Long Type 1', // CP: CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d
  CADES_X_LONG_TYPE_2 = 'CAdES-X Long Type 2',
  CADES_A = 'CAdES-A',
  CADES_A_V2 = 'CAdES-A v2',
  CADES_A_V3 = 'CAdES-A v3',
  PKCS7 = 'PKCS7', // CP: CADESCOM_PKCS7_TYPE = 0xffff
  XADES_BES = 'XAdES-BES', // CP: CADESCOM_XADES_BES = 0x00000020, JINN: ?
  XADES_T = 'XAdES-T', // CP: CADESCOM_XADES_T = 0x00000050, JINN: ?
  XADES_C = 'XAdES-C', // JINN: ?
  XADES_X_LONG_TYPE_1 = 'XAdES-X Long Type 1', // CP: CADESCOM_XADES_X_LONG_TYPE_1 = 0x000005d0
  XADES_A = 'XAdES-A', // CP: CADESCOM_XADES_A = 0x000007d0
  XMLDSIG = 'XML-Dsig', // CP: CADESCOM_XMLDSIG_TYPE = 0
}