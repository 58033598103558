import { useRef } from 'react';
import { getClassName } from 'app/core/utility/jsx';
import styles from './button.module.scss';

export const buttonTypes = {
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
  warning: 'warning',
  text: 'text',
  navigation: 'navigation',
  image: 'image',
  imageSmall: 'image image-small',
}

const getTypeCssClasses = type => (type || buttonTypes.primary).split(' ').map(t => `button--${t}`);

export default function Button(props) {
  const { className, type, disabled, children, onClick, onMouseDown, notSubmit, caption, href } = props
  const linkRef = useRef();
  
  const clickHandler = e => {
    e.stopPropagation()
    !disabled && onClick && onClick(e)
  }
  
  const mouseDownHandler = e => {
    e.stopPropagation()
    !disabled && onMouseDown && onMouseDown(e)
  }
  
  const touchHandler = e => {
    e.preventDefault();
    mouseDownHandler(e);
    clickHandler(e);
  }
  
  const cssClass = getClassName(
    [ 'button', className || '', ...getTypeCssClasses(type), { 'button--disabled': disabled } ],
    { linebtn: type === buttonTypes.image && caption },
    styles
  );
  
  const handlers = {
    onClick: clickHandler,
    onMouseDown: mouseDownHandler,
    onTouchStart: touchHandler,
  }

  return href ? (
    <a
      ref={linkRef}
      href={href} download
      className={cssClass}
      {...handlers}
    >
      {children}
      {caption && <span className={styles.caption}>{caption}</span>}
    </a>
  ) : (
    <button
      className={cssClass}
      disabled={disabled}
      type={notSubmit ? '' : 'submit'}
      {...handlers}
    >
      {type === buttonTypes.image && caption ? (
        <>
          <div className={styles.linebtnImg}>{children}</div>
          {caption && <span className={styles.caption}>{caption}</span>}
        </>
        ) : (<>
          {children}
          {caption && <span className={styles.caption}>{caption}</span>}
        </>)
      }
    </button>
  )
}
