/** @format */

import Button from 'app/components/ui/button/button';
import Images from 'app/components/ui/Img/imgMap';
import withTooltip from 'app/components/HOC/TooltipHOC';
import Entity from 'app/core/types/entity';
import LicenseType from 'app/core/types/licenseType';

const ButtonWithTooltip = withTooltip(Button);

const entityToLicenseTypeMap = {
  [Entity.eskzi]: LicenseType.eskzi,
  [Entity.keyDocument]: LicenseType.keyDocument,
};

const NoLicensesMessage = {
  [LicenseType.eskzi]: 'Недостаточно лицензий для регистрации нового экземпляра СКЗИ',
  [LicenseType.keyDocument]: 'Недостаточно лицензий для регистрации нового ключевого документа',
};

export default function CreateCardButton({ title, entity, licenseState = {}, inProgress, onClick }) {
  const requiredLicenseType = entityToLicenseTypeMap[entity] ?? null;
  const licensesOk = !requiredLicenseType || licenseState[requiredLicenseType];
  return (
    <ButtonWithTooltip
      className="create-button"
      type="primary"
      tooltip={licensesOk ? '' : NoLicensesMessage[requiredLicenseType]}
      disabled={inProgress || !licensesOk}
      onClick={onClick}
    >
      <Images.Plus className="button-image" />
      <span className="button-title">{title}</span>
    </ButtonWithTooltip>
  );
}
