import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  MAINTENANCECREATE,
  SIGNATUREREADYTOSIGN,
  MAINTENANCECREATEUPDATEFORMDATA,
  MAINTENANCECREATEINPROGRESS    ,
  MAINTENANCECREATERESETACTION   ,
  MAINTENANCECREATECLEARFORM,
  MAINTENANCECREATEINITFORMDATA
} from '../../actions/actionTypes'

const defaultState = {
  formData : {
    number: '',
    performerId: null,
    hardwareNumber: '',
    printNumber: '',
    eskziId: null,
    distributiveRemovingDate: null,
    createDate: new Date(),
    updateDate: new Date(),
  },
  errorByFields: {},
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { formData } = state
    
  switch (action.type) {

    case MAINTENANCECREATE:
      return {
          ...state,
          inProgress: false
      }
        
    case SIGNATUREREADYTOSIGN:
    case FIELDSERROR:
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData
      }

    case MAINTENANCECREATEINITFORMDATA: 

      return {
        ...state,
        formData: { ...formData, ...payload }
      }

    case MAINTENANCECREATEUPDATEFORMDATA: 
      const {key, value } = payload

      return {
        ...state,
        formData: {
          ...formData,  
          [key]: value
        }
      }

    case MAINTENANCECREATECLEARFORM: 
      return {
        ...defaultState
      }

    case MAINTENANCECREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case MAINTENANCECREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        error: null
      }

    default: 
      return state
  }
}