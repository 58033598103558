/** @format */

import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'app/components/list/Table/Table';
import Overlay from 'app/components/ui/overlay';
import service from 'app/services/service';
import ConfirmDialog from 'app/components/dialog/confirmDialog';
import { getResourceRights } from 'app/core/auth/auth';
import { useJournal } from 'app/components/journals/useJournal';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import PageHeader from 'app/components/PageHeader/pageHeader';
import ImgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from 'app/components/ui/managePanel';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import MainListContainer, {
  MainListLayoutType,
} from 'app/components/ui/MainListContainer/MainListContainer';
import Filter from 'app/components/filter';
import { Delete } from 'app/components/list/TopPanel/Delete';
import { getSettings } from './pageConfig';
import ButtonComponent from 'app/components/ui/button/button';
import { stringPlural } from 'app/core/utility/common';
import Image from 'app/components/ui/Img/imgMap'
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC';
import { sortDirection } from 'app/components/ui/constants';

const Button = withTooltip(ButtonComponent)

export default function Catalog({ readOnlyMode, type }) {
  const history = useHistory();
  const settings = getSettings(type);

  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    filterState,
    fieldsVisible,
    filterFilled,
    filterOpened,
    toggleFilterState,
    onClearFilter,

    // useTableExtensions
    onInProgress,
    sortListAction,
    onRemoveValues,
    onItemUpdate,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,

    // global
    errorObject,
    clearError,
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,
  } = useJournal({
    defaultSort: {
      column: 'id',
      direction: sortDirection.desc,
    },
    serviceName: settings.service,
  });

  const rights = getResourceRights(profile, settings.resource)
  const onCreate = () => history.push(settings.createLink);
  const deleteItems = useCallback(async () => {
    onInProgress(true);
    const response = await service(settings.service, 'deleteRange', selectedItems);
    onInProgress(false);
    return response;
  }, [selectedItems]);

  return (
    <MainListContainer layoutType={readOnlyMode ? MainListLayoutType.tableOnly : MainListLayoutType.full}>
      {inProgress && <Overlay />}
      <PageHeader title={settings.title} Img={ImgMap.Okz} />
      {!readOnlyMode ? (
        <>
          <ManagePanel borders={Border.All}>
            {rights.CREATE ? (
              <Group>
                <Button type="primary" disabled={inProgress} onClick={onCreate}>
                  <ImgMap.Plus />
                  <span>Добавить</span>
                </Button>
              </Group>
            ) : null}
            {rights.DELETE ? (
              <Group>
                <Delete
                  items={selectedItems}
                  deleteItemsAction={deleteItems}
                  afterAction={onRemoveValues}
                  setSelectedAction={setSelectedAction}
                  dialog={data => {
                    const arr = ['выбранный элемент', 'выбранные элементы', 'выбранные элементы'];
                    return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`;
                  }}
                />
              </Group>
            ) : null}
            <Group separate>
              <Button
                type="image"
                onClick={onClearFilter}
                disabled={!filterState.length}
                tooltip="Очистить фильтр"
                tooltipPosition={Position.BottomLeft}
              >
                <Image.Broom />
              </Button>
            </Group>
          </ManagePanel>
          <Filter
            inProgress={inProgress}
            setFilterStateAction={setFilterStateAction}
            setFilterForRequestAction={setFilterForRequestAction}
            setFullFilterStateAction={setFullFilterStateAction}
            filterState={filterState}
            filterFilled={filterFilled}
            profile={profile}
            filtersConfig={settings.config.tablePageFiltersConfig}
          />
        </>
      ) : null}
      <Table
        {...{
          totalCount,
          list,
          sortListAction,
          sorting,
          selectedItems,
          selectedObjects,
          setSelectedAction,
          setSelectedPagination,
          inProgress,
        }}
        listFieldsVisible={fieldsVisible}
        fieldsConfig={settings.config.fieldsConfig}
        itemsSelectable={true}
        onItemClick={settings.onRowClick}
      />
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} perPage={perPage} />
      {errorObject ? <ConfirmDialog {...errorObject} onCancel={clearError} /> : null}
    </MainListContainer>
  );
}

