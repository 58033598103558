/** @format */

import Button from 'app/components/ui/button/button';
import { buttonTypes } from 'app/components/ui/button/button';
import withTooltip from 'app/components/HOC/TooltipHOC';

const ButtonWithTooltip = withTooltip(Button);

function BusinessEventButton({ tooltip, img, buttonText, logic }) {
  const { disabled, onAct } = logic;
  const Img = img;

  return Img ? (
    <ButtonWithTooltip
      type={buttonTypes.image}
      tooltip={tooltip}
      disabled={disabled}
      caption={buttonText}
      onMouseDown={onAct}
    >
      <Img className="button-image"></Img>
    </ButtonWithTooltip>
  ) : null;
}

export default BusinessEventButton;
