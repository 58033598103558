import PopupPositioned from 'app/components/ui/popupPositioned/popupPositioned';

const popupPanelList = props => {
  const { items, toggle, closeOnMenuClick, parent } = props
  const afterItemPicked = () => {
    setTimeout(() => closeOnMenuClick && toggle(), 0)    
  }

  return (
    <PopupPositioned 
      parent={parent}
      onScroll={toggle}
      clickOutside={toggle}
      staticWidth={400}
    >
      <ul className={`drop-down__list`} onMouseUp={afterItemPicked} onMouseLeave={afterItemPicked}>
        {items.map((el, i) => {
          const { props } = el
          const { onClick, disabled } = props

          return (
            <li
              key={i} 
              className={`list-item ${disabled ? 'list-item--disabled' : '' }`}
              onClick={e => onClick && onClick(e)}
            >{el}</li>
          )
        })}
      </ul>
    </PopupPositioned>
    
  )
}


export default popupPanelList