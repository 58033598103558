import React from "react";
import CreateControl from "./CreateControl";
import EditControl from "./EditControl";
import { DownloadButton } from 'app/components/list/TopPanel/DownloadButton';
import withSignatureHOC from "app/components/HOC/withSignatureHOC";
import InstallationControl from "./InstallationControl";
import withTooltip from "app/components/HOC/TooltipHOC";
import ActionButtonImage from "app/components/list/TopPanel/ActionButtonImage";
import DeleteControl from "./DeleteControl";
import {
  useCreateControlCallback,
  useDeleteControlWithSign,
  useEditControlCallback,
  useInstallationControlCallback
} from "./hooks/useActionPanel";
import { Group } from "app/components/ui/managePanel";

const EditControlWithSign = withSignatureHOC(EditControl)
const CreateControlWithSign = withSignatureHOC(CreateControl)
const DeleteControlWithSign = withSignatureHOC(DeleteControl)
const InstallationControlWithSign = withSignatureHOC(InstallationControl)
const ButtonWithTooltip = withTooltip( ActionButtonImage );

export default function TechnicalJournalActionPanel({
  getList,
  filterForRequest,
  sorting,
  fieldsVisible,
  items,
  globalOrgId,
  setProgress,
  inProgress,
  setSelectedAction,
  setSelectedPagination,
  skzi
}){

  const {
    addActionCallback,
    generateAddDataForRequest
  } = useCreateControlCallback({setProgress, getList})

  const {
    editActionCallback,
    generateEditDataForRequest,
    editItem
  } = useEditControlCallback({
    setProgress, 
    getList, 
    items,
    setSelectedPagination
  })

  const {
    generateDeleteDataForRequest,
    deleteActionCallback,
  } = useDeleteControlWithSign({
    setProgress, 
    getList, 
    items, 
    setSelectedAction,
    setSelectedPagination
  })

  const {
    eventTemplate,
    installationActionCallback,
    isShowDialog,
    generateInstallationDataForRequest,
    setIsShowDialog,
  } = useInstallationControlCallback({setProgress, getList})

  return (
    <>
      <Group>
        <CreateControlWithSign
          title="Добавить"
          inProgress={inProgress}
          signType='EskziMaintenance'
          signHeader='Добавить запись'
          actionCallback={addActionCallback}
          generateDataForRequest={generateAddDataForRequest}
          skzi={skzi}
        />
      </Group>
      <Group>
        <DownloadButton
          listFilterForRequest = {filterForRequest} 
          sorting = {sorting}
          listFieldsVisibility ={fieldsVisible}
          selected = {items.map(i=>i.id)}
          id = {skzi && skzi.id ? skzi.id : globalOrgId}
          titul="Выгрузить в Excel"
          inProgress = { inProgress }
          serviceName = {skzi && skzi.id ? 'techJournalService':'technicalJournalReportService'}
        />
      </Group>
      <Group>
        <EditControlWithSign
          actionCallback={editActionCallback}
          generateDataForRequest={generateEditDataForRequest}
          signType='EskziMaintenance'
          signHeader='Изменить запись'
          inProgress={inProgress}
          title="Редактировать"
          item={editItem}
        />
      </Group>
      <Group>
        <DeleteControlWithSign
          actionCallback={deleteActionCallback}
          generateDataForRequest={generateDeleteDataForRequest}
          signType='EskziMaintenanceDeleted'
          signHeader='Удалить записи'
          inProgress = { inProgress }
          items = { items }
        />
      </Group>
      <Group>
        {
          eventTemplate ? (
            <InstallationControlWithSign
              isShowDialog={isShowDialog}
              setIsShowDialog={setIsShowDialog}
              title="Ввод в аппаратное СКЗИ"
              inProgress={inProgress}
              signType='FinishOneClickAction'
              signHeader='Ввод в аппаратное СКЗИ'
              actionCallback={installationActionCallback}
              generateDataForRequest={generateInstallationDataForRequest}
              skzi={skzi}
              item={eventTemplate}
            />
          ) : (
            <div  className="manage-panel__item">
              <ButtonWithTooltip
                img={'LinkImg'}
                disabled={true}
                tooltip="Ввод в аппаратное СКЗИ" />
            </div>
          )
        }
      </Group>
    </>
  )
}
