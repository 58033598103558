import React from 'react'

import { ReactComponent as CloseImg } from 'assets/img/commonVer2/close.svg'
import Button, { buttonTypes } from 'app/components/ui/button/button'
import Progress from 'app/components/ui/progress'

export default function fileLine(props) {
  const { onDelete, fileRights, file, urlPArt } = props
  const { guid, name, progress, cancelUpload } = file

  return (
    <div className='file-line'>
      <a href={`api/v1/${urlPArt}/${guid}`} download>
        <span className='file-line__title'>
          {name}
        </span>
      </a>
      {progress ? (
        <Progress
          className='file-line__progress'
          progress={Math.round(progress*100)}
        /> 
      ) : null}
      <div className = 'file-line__control-panel'>
        {fileRights.DELETE || progress ? (
          <Button
            className='delete'
            type = {buttonTypes.image}
            onClick = {() => {
              if (cancelUpload) {
                cancelUpload()
              } else {
                onDelete(guid)
              }
            }}
          >
            <CloseImg className='button-image' title='Удалить'/>
          </Button>
        ) : null}
      </div>
    </div>
  )
}