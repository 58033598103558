import React, { Component } from 'react'

import PropType from 'prop-types';
import { keyCodes } from './constants'

import { ReactComponent as ClearImg } from 'assets/img/commonVer2/close.svg';

export default class TextArea extends Component {
  static get propTypes() {
    return {
        placeHolder: PropType.string,
        onChange: PropType.func,
        onSubmit: PropType.func,
        value: PropType.string,
        error: PropType.object,
        autofocus: PropType.bool,
        simple: PropType.bool,
        type: PropType.string,
        readOnly: PropType.bool,
    };
  }
  
  constructor(props){
    super(props)

    this.state = { focused: false }
    this.input = React.createRef()
  }

  componentDidMount() {
    const { autofocus } = this.props
    const { current } = this.input 

    autofocus && current && current.focus()
  }

  _onCancel = (event) => {
    event.stopPropagation()

    const { current } = this.input
    const { onChange, disabled } = this.props
    const { focused } = this.state

    if (!disabled) {
      onChange && onChange('')
      !focused && current.focus()
    }
  }

  _onChange = () => {
    const { onChange, readOnly } = this.props
    const { current } = this.input

    !readOnly && onChange && onChange(current.value)
  }

  _onKeyDown = (event) => { 
    const { focused } = this.state
    const { onSubmit, value } = this.props

    switch (event.keyCode) {
      case keyCodes.EnterKeyCode:
        focused && onSubmit && onSubmit(value);
        break;
    }
  }

  _renderLabel = () => {
    const { 
      value, 
      placeholder, 
      label, 
    } = this.props

    if (placeholder && !label) {
      return (
        <>
          {!value ? (
            <span className='control__placeholder-text'>{placeholder}</span>
          ) : null}
        </>
      )
    } else if (label && !placeholder) {
      return (
        <>
          {!value ? (
            <span className='control__animation-label-text'>{label}</span>
          ) : (
            <span className='control__label-text'>{label}</span>
          )}
        </>
      )
    } else {
      return (
        <>
          <span className='control__label-text'>{label}</span>
          {!value ? (
            <span className='control__placeholder-text'>{placeholder || 'Введите текст'}</span>
          ) : null}
        </>
      )
    }
  }

  render() {
    const { 
      className, 
      placeholder, 
      error, 
      disabled, 
      value,
      readOnly,
      hint,
      rownum = 2
    } = this.props
    const { focused } = this.state
    const { isError, errorMessage } = error || {}
    const cssClass = []

    !placeholder && cssClass.push('text-area--labeled')
    disabled && cssClass.push('text-area--disabled')
    isError && cssClass.push('text-area--error')
    focused && cssClass.push('text-area--focused')
    readOnly && cssClass.push('text-area--read-only')
    rownum === 1 && cssClass.push('text-area--one-row')
    rownum === 2 && cssClass.push('text-area--two-row')

    return (
      <div className={`text-area ${className || ''} ${cssClass.join(' ')}`}>
        <label className='text-area__control'>      
          {this._renderLabel()}
          <textarea
            ref={this.input}
            className='control__input'
            disabled={disabled}
            readOnly={readOnly}
            onChange={this._onChange}
            onKeyDown={this._onKeyDown}
            onFocus={() => this.setState({ focused: true }) }
            onBlur={() => this.setState({ focused: false }) }
            spellCheck="false"
            value={value || ''}
          />
          {!disabled && !readOnly && value ? (
            <ClearImg 
              className='control__clear'
              onClick={this._onCancel}
            />
          ) : null}
        </label>
        <div className='text-area__hint-placeholder'>
          {error ? (
            <span className='text-area__error'>{errorMessage}</span>
          ) : null}
          {hint && !error && <span className='text-area__hint'>{hint}</span>}
        </div>
      </div>
    )
  }
}
