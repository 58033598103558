import React from 'react'
import {useSelector} from "react-redux";
import {getResourceRights} from "app/core/auth/auth";
import UploadMenuControl from "app/components/UploadMenuControl";

export const MassImportWrap = () => {
    const profile = useSelector(({profile}) => profile);
    const massImportableEntities = ['Organization', 'SkziLicense', 'EskziUser', 'KeyCarrier', 'KeyDocument', 'Eskzi'];
    const userHasMassImportRights =
        massImportableEntities.some(e => getResourceRights(profile, `MassImport/${e}`)['VIEW_LIST']);
    return <>
        {
            userHasMassImportRights && <UploadMenuControl />
        }
    </>;
};
