/** @format */

import Input from 'app/components/ui/Input';
import TextArea from 'app/components/ui/textArea';
import styles from './card.module.scss';
import { formatDate } from 'app/core/utility/date';

export default function RequisitesForm({
  serialNumber = null,
  creationDate = null,
  status = null,
  type = null,
  validityDate = null,
  user = null,
  organiation = null,
  subject = null,
  template = null,
  reviewedBy = null,
  userComment = '',
  adminComment = '',
}) {
  return (
    <div className={styles.requisites}>
      <Input className={styles.serialNumber} label="Сертификат" value={serialNumber} readOnly />
      <Input
        className={styles.creationDate}
        label="Дата создания"
        value={formatDate(creationDate, 'dd.mm.yyyy hh:MM:ss')}
        readOnly
      />
      <Input className={styles.status} label="Статус запроса" value={status?.name ?? ''} readOnly />
      <Input className={styles.type} label="Вид запроса" value={type?.name ?? ''} readOnly />
      <Input
        className={styles.validityDate}
        label="Дата рассмотрения"
        value={formatDate(validityDate, 'dd.mm.yyyy hh:MM:ss')}
        readOnly
      />
      <Input className={styles.user} label="Имя пользователя" value={user} readOnly />
      <Input className={styles.organiation} label="Организация" value={organiation} readOnly />
      <Input className={styles.subject} label="Субъект" value={subject} readOnly />
      <Input className={styles.template} label="Шаблон сертификата" value={template} readOnly />
      <Input className={styles.reviewedBy} label="Рассмотрел запрос" value={reviewedBy} readOnly />
      <TextArea
        className={styles.userComment}
        label="Заметки пользователя"
        value={userComment}
        readOnly
      />
      <TextArea
        className={styles.adminComment}
        label="Заметки администратора"
        value={adminComment}
        readOnly
      />
    </div>
  );
}

