import { detect, BrowserInfo, BotInfo, NodeInfo } from 'detect-browser';

export const SignatureTypesMapping = {
}

interface IJinn {
  CreateXmlCryptoContextAsync: () => IJinnObject;
  CreateBinaryBase64CryptoContextAsync: () => IJinnObject;
}

interface IJinnObject {
  SignDocument: (str: string, onSuccess: (str: string) => void, onError: (err: Error) => void) => void;
  SignHashDocument: (str: string, onSuccess: (str: string) => void, onError: (err: Error) => void) => void;
  SignDocumentWithTransform: (str: string, onSuccess: (str: string) => void, onError: (err: Error) => void) => void;
}

export interface ISignResult {
  signature: string;
}

export interface ISignDocumentOptions {
  encode?: boolean;
  showResult?: boolean;
  needVisualize?: boolean;
  mimeType?: string;
  signedAttributes?: string;
  unsignedAttributes?: string;
  documentTitle?: string;
  ownerCertificateName?: string;
  ownerCertificatePosition?: string;
  certificate?: string;
}

export interface ISignHashDocumentOptions {
  encode?: boolean;
  showResult?: boolean;
  needVisualize?: boolean;
  mimeType?: string;
  signedAttributes?: string;
  unsignedAttributes?: string;
  certificate?: string;
}

export interface ISignDocumentWithTransformOptions {
  encode?: boolean;
  showResult?: boolean;
  needVisualize?: boolean;
  elementUri?: string;
  xsltUri?: string;
  signedAttributes?: string;
  unsignedAttributes?: string;
  propertiesNamespaceRef?: string;
  propertiesNamespacePrefix?: string;
  documentTitle?: string;
  ownerCertificateName?: string;
  ownerCertificatePosition?: string;
  certificate?: string;
}

interface ISignDocumentRequest {
  doc: string;
  options?: ISignDocumentOptions;
}

interface ISignHashDocumentRequest {
  hash: string;
  options?: ISignHashDocumentOptions;
}

interface ISignDocumentWithTransformRequest {
  doc: string;
  xslt: string;
  options?: ISignDocumentWithTransformOptions;
}

let pluginVersion: string;
const contextsNPAPI: { [key: string]: IJinnObject } = {};

function generateUUID() {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

function isPPAPI(browser: BrowserInfo | BotInfo | NodeInfo) {
  switch (browser.name) {
    case 'chrome':
    case 'opera':
    case 'yandexbrowser': {
      return true;
    }
    case 'firefox': {
      return browser.version ? Number(browser.version.split('.')[0]) >= 52 : false;
    }
    default:
      return false;
  }
}

function isNPAPI(browser: BrowserInfo | BotInfo | NodeInfo) {
  switch (browser.name) {
    case 'ie': {
      return true;
    }
    case 'firefox': {
      return browser.version ? Number(browser.version.split('.')[0]) < 52 : false;
    }
    default:
      return false;
  }
}

function createPluginScript(): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = 'chrome-extension://ikhljbffpngkpffbiepkmgibcmheojca/version.txt';

    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.timeout = 1000;
    xhr.send();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject();
        }
      }
    };

    xhr.ontimeout = () => {
      reject();
    };
  });
}

function createPluginObject() {
  if (!document.getElementById('jinnPluginObject')) {
    const element = document.createElement('object');
    element.id = 'jinnPluginObject';
    element.type = 'application/x-vnd.jinnbrowserplugin';
    element.style.height = '0';
    document.body.appendChild(element);
  }
}

function callScript(method: string, context: string, data: object = {}) {
  const messageId = generateUUID();
  const SECRET_KEY = 'JinnSignExtensionSecretKey';

  return new Promise((resolve, reject) => {
    if (!context) {
      reject('Работа с контекстом завершена');
    }

    const message = { id: messageId, context, method, data };
    const eventMessage = { source: 'page', secret_key: SECRET_KEY, message };

    const listener = (event: MessageEvent) => {
      if (event.data.source !== 'content_script' || event.data.secret_key !== SECRET_KEY) {
        return;
      }

      if (!event.data.message || !event.data.message.id || !event.data.message.context) {
        reject('Ошибка формата ответа плагина');
      }

      if (message.id !== event.data.message.id) {
        return;
      }

      const chrome = (window as any).chrome;
      const resp = event.data.message.data;

      window.removeEventListener('message', listener);

      if (chrome.runtime && chrome.runtime.lastError) {
        reject(chrome.runtime.lastError);
      }

      if (!resp) {
        reject('Ошибка в работе расширения');
      }

      if (resp.result) {
        reject(resp.error);
      }

      resolve(resp);
    };

    window.postMessage(eventMessage, '*');
    window.addEventListener('message', listener, false);
  });
}

function callObject(method: string, context: string, data: object) {
  return new Promise((resolve, reject) => {
    const obj = (document.getElementById('jinnPluginObject') as unknown) as IJinn;

    if (!obj) {
      reject();
    }

    if (method === 'CreateXmlCryptoContext') {
      contextsNPAPI[context] = obj.CreateXmlCryptoContextAsync();
      resolve(void 0);
    }

    if (method === 'CreateBinaryBase64CryptoContext') {
      contextsNPAPI[context] = obj.CreateBinaryBase64CryptoContextAsync();
      resolve(void 0);
    }

    if (method === 'CloseCryptoContext') {
      document.body.removeChild((obj as unknown) as Node);
      resolve(void 0);
    }

    if (!contextsNPAPI[context]) {
      reject();
    }

    const contextObj = contextsNPAPI[context];

    const onSuccess = (result: string) => {
      resolve(JSON.parse(result));
    };

    const onError = (error: Error) => {
      reject(error);
    };

    if (method === 'SignDocument') {
      contextObj.SignDocument(JSON.stringify(data), onSuccess, onError);
    }

    if (method === 'SignDocumentWithTransform') {
      contextObj.SignDocumentWithTransform(JSON.stringify(data), onSuccess, onError);
    }

    if (method === 'SignHashDocument') {
      contextObj.SignHashDocument(JSON.stringify(data), onSuccess, onError);
    }
  });
}

async function call(method: string, context: string, data: object = {}) {
  const browser = detect() as BrowserInfo;

  if (!browser) {
    throw new Error('Ошибка определения браузера.');
  }

  if (isPPAPI(browser)) {
    if (!pluginVersion) {
      pluginVersion = await createPluginScript();
    }

    return await callScript(method, context, data);
  }

  if (isNPAPI(browser)) {
    await createPluginObject();
    return await callObject(method, context, data);
  }

  throw new Error('Ошибка определения браузера.');
}

function encodeToB64(data: string) {
  return btoa(
    encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(Number('0x' + p1));
    }),
  );
}

export async function getInfo() {
  const uuid = generateUUID();

  try {
    await call('CreateBinaryBase64CryptoContext', uuid, {});
    return {
      name: 'JinnClient',
      plugin: pluginVersion,
    };
  } catch (error) {
    throw error;
  } finally {
    await call('CloseCryptoContext', uuid, {});
  }
}

export async function signDocument(doc: string, options: ISignDocumentOptions = {}) {
  const uuid = generateUUID();
  const { encode, ...rest } = options;

  if (encode) {
    doc = encodeToB64(doc);
  }

  const rq: ISignDocumentRequest = { doc, options: rest };

  try {
    await call('CreateBinaryBase64CryptoContext', uuid);
    const response = await call('SignDocument', uuid, rq);
    return (response as ISignResult).signature;
  } catch (error) {
    throw error;
  } finally {
    await call('CloseCryptoContext', uuid);
  }
}

export async function signHashDocument(hash: string, options: ISignHashDocumentOptions = {}) {
  const uuid = generateUUID();
  const { encode, ...rest } = options;

  if (encode) {
    hash = encodeToB64(hash);
  }

  const rq: ISignHashDocumentRequest = { hash, options: rest };

  try {
    await call('CreateBinaryBase64CryptoContext', uuid);
    const response = await call('SignHashDocument', uuid, rq);
    return (response as ISignResult).signature;
  } catch (error) {
    throw error;
  } finally {
    await call('CloseCryptoContext', uuid);
  }
}

export async function signDocumentWithTransform(doc: string, xslt: string, options: ISignDocumentOptions = {}) {
  const uuid = generateUUID();
  const { encode, ...rest } = options;

  if (encode) {
    doc = encodeToB64(doc);
    xslt = encodeToB64(xslt);
  }

  const rq: ISignDocumentWithTransformRequest = { doc, xslt, options: rest };

  try {
    await call('CreateXmlCryptoContext', uuid);
    const response = await call('SignDocumentWithTransform', uuid, rq);
    return (response as ISignResult).signature;
  } catch (error) {
    throw error;
  } finally {
    await call('CloseCryptoContext', uuid);
  }
}
