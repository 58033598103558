/** @format */
import { formatSKZI } from 'app/components/list/Table/TableBody';
import { sortDirection } from 'app/components/ui/constants';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';

export const defaultSettings = {
  columns: [
    {
      title: 'Наименование',
      alias: 'skzi',
      useSorting: true,
      format: item => {
        const { skzi, isStandard } = item;

        return formatSKZI(skzi, isStandard);
      },
    },
    {
      title: 'Регистрационный номер',
      alias: 'serialNumber',
      useSorting: true,
      format: item => {
        const { serialNumber } = item;

        return serialNumber || '';
      },
    },
    {
      title: 'Пользователь',
      alias: 'eskziUser',
      useSorting: true,
      format: item => {
        const { eskziUser } = item;

        return formatFIO(eskziUser);
      },
    },
    {
      title: 'Дата изменения',
      alias: 'updateDate',
      useSorting: true,
      format: item => {
        const { updateDate } = item;

        return formatDate(updateDate, 'dd.mm.yyyy');
      },
    },
  ],
  sort: {
    column: 'updateDate',
    direction: sortDirection.desc,
  },
};
