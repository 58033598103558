import { PureComponent } from 'react'
import { connect } from 'react-redux'

import { cardLines } from './skziBuildData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import { capitalize, serverlikeErrors } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import { api } from 'app/services/service'

import withAuth from 'app/components/HOC/AuthHOC'

import * as errorAction from 'redux/actions/common'
import * as skziBuildCardAction from 'redux/actions/skziRegistry/skziBuildCardAction'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import { addToast } from 'redux/actions/toast'
import withRoutingProps from 'app/components/HOC/RoutingPropsHOC'
import breadcrumbsHOC from 'app/components/HOC/BreadcrumbsHOC'

import { virtualPages } from 'app/navigation/navigationContent'

const title = 'Добавление новой сборки / модификации';

class CreateSkziBuildCard extends PureComponent {
  constructor(props){
    super(props)
    this.state = { 
      tabTitle: 'Информация',
    }
  }

  async updateBreadcrumbs() {
    const { params, location, breadcrumbsPushAction } = this.props
    const { skziId, versionId } = params
    const { data } = await api('version/getVersion', { skziId, versionId })
    const { skzi, name: versionName } = data || {}
    const { name: skziName } = skzi ?? {}
    const { pathname } = location
    const virtualBreadcrumbs = [
      virtualPages.skzi({ path: pathname, name: skziName }),
      virtualPages.skziVersion({ path: pathname, name: versionName }),
    ];
    breadcrumbsPushAction(pathname, title, true, virtualBreadcrumbs);
  }

  componentDidMount() {
    const { skziBuildCard, skziBuildCardClearFormAction, clearErrorAction } = this.props
    const { inProgress } = skziBuildCard

    this.updateBreadcrumbs()
    if (inProgress) {
      return
    }

    skziBuildCardClearFormAction()
    clearErrorAction()
  }

  componentWillUnmount () {
    const { 
      skziBuildCardResetAction,
      clearErrorAction,
      skziBuildCardOnSelect,
      breadcrumbsPopAction
    } = this.props

    breadcrumbsPopAction()
    skziBuildCardOnSelect()
    skziBuildCardResetAction()
    clearErrorAction()
  }

  _onSubmit = () => {
    const { skziBuildCard, skziBuildAddCardAction, skziBuildCardInProgress, params, history, addToast } = this.props
    const { versionId, skziId } = params
    const { commonDataForm, skziBuildData } = skziBuildCard
    const { classId, certificateExpirationDate } = commonDataForm
    const { id } = classId || {}

    const buildData = {
      ...commonDataForm,
      id: skziBuildData.id,
      skziVersionId: versionId,
      classId: id || null,
      certificateExpirationDate: formatDate(certificateExpirationDate, 'yyyy-mm-dd')
    }

    skziBuildCardInProgress(true)
    skziBuildAddCardAction(buildData).then(response => {
      const { data, isError } = response;
      if(!isError && data && data.id){
        addToast('Запись успешно добавлена')
        history.push(`/skziRegistry/accounting/skzi/${skziId}/version/${versionId}/build/${data.id}`)
      }
    })
  }

  _onCancel = () => {
    const { clearErrorAction, skziBuildCardResetAction, history, params } = this.props
    const { versionId, skziId } = params
    skziBuildCardResetAction()
    clearErrorAction()
    history.push(`/skziRegistry/accounting/skzi/${skziId}/version/${versionId}`)
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { skziBuildCard, skziBuildCardUpdateCommonForm, setErrorByKey, error } = this.props
    const { commonDataForm } = skziBuildCard
    const { errorByFields }  = error || {}

    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    setErrorByKey(filteredErrors)
    skziBuildCardUpdateCommonForm({ ...commonDataForm, [key]: value })
  }

  _onClearError = () => {
    const { clearErrorAction } = this.props
    
    clearErrorAction()
  }

  _renderData = () => {
    const { skziBuildCard, profile, error } = this.props
    const { fieldsError } = error
    const { viewData, commonDataForm } = skziBuildCard

    return (
      <section className='skzi-build-common-data'>
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты сборки/модификации'}
          initialState={true}
        >
          <Card
            className='skzi-build-data-card'
            profile={profile}
            formLines={cardLines}
            data={viewData}
            formData={commonDataForm}
            editOnOpen={true}
            onValidationError={this._onValidation}
            errorByFields={fieldsError}
            onChange={this._onFormChange}
            onSubmit={this._onSubmit}
            onCancel={this._onCancel}
          />
        </ExpandingBlock>
        <ExpandingBlock
          disabled={true}
          className='skzi-build-certificates'
          renderHeader= {() => 'Сертификаты соответствия'}
          initialState={false}
        />
        <ExpandingBlock
          disabled={true}
          className='skzi-build-types'
          renderHeader= {() => 'Состав комплекта сборки / модификации'}
          initialState={false}
        />
      </section>
    )
  }

  render() {
    const { skziBuildCard } = this.props
    const { inProgress } = skziBuildCard

    return (
      <section className='skzi-build-card'>
        {inProgress ? <Overlay /> : null}
        <BreadcrumbsHeader />     
        {this._renderData()}
        <></>
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  {
    addToast,
    ...breadcrumbsActions,
    ...errorAction,
    ...skziBuildCardAction
  })(withAuth(withRoutingProps(breadcrumbsHOC(CreateSkziBuildCard, { title }))))