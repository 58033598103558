/** @format */

import { connect } from 'react-redux';

import MainCard, { cardMode, objectKey } from 'app/components/mainCard/cardComponent';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader';
import { cardLines } from '../card/cardLines';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import * as cardAction from 'redux/actions/catalog/cardActions';
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction';
import { CARDAFTERCREATE } from 'redux/actions/actionTypes';
import { catalogEntityData } from '../skziTypes/createCard';

export const cardConfig = ({ history, createKeyCarrierTypeAction, initCatalogEntityCreate, breadcrumbsPopAction }) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, catalogEntityData),
  resource: RESOURCES.keyCarrierType,
  updateAction: createKeyCarrierTypeAction,
  cardInitAction: initCatalogEntityCreate,
  getSignatureData: catalogEntityData,
  onCancel: () => {
    history.push('/catalog/keyCarrierType');
  },
  afterUpdate: requestResult => {
    const { payload, type } = requestResult || {};
    const { id } = payload || {};

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction();
      history.push(`/catalog/keyCarrierType/card/${id}`);
    }
  },
  pageBreadcrumbTitle: () => null,
});

const CatalogCreateCard = props => {
  const { catalogType, createKeyCarrierTypeAction, initCatalogEntityCreate, breadcrumbsPopAction, history } = props;

  return (
    <section className="main-card catalog-card">
      <BreadcrumbsHeader />
      <section className="main-card__data">
        <ExpandingBlock renderHeader={() => 'Реквизиты'} initialState={true}>
          <MainCard
            {...props}
            cardMode={cardMode.create}
            editOnOpen={true}
            config={cardConfig({
              catalogType,
              history,
              createKeyCarrierTypeAction,
              initCatalogEntityCreate,
              breadcrumbsPopAction,
            })}
          ></MainCard>
        </ExpandingBlock>
      </section>
    </section>
  );
};

export default connect(state => ({ ...state.card, profile: state.profile }), {
  ...cardAction,
  ...breadcrumbsActions,
})(CatalogCreateCard);
