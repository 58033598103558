import React from 'react';

const withToggle = PassedComponent => {
  class WithToggle extends React.Component {
    constructor(props) {
      super(props);
      const { initialState } = props;
      this.state = {
        toggleStatus: initialState || false,
      };
    }

    componentDidUpdate(prevProps) {
      const { initialState, onToggle } = this.props;
      const { initialState: prevInitialState } = prevProps;
      if (initialState !== prevInitialState) {
        this.setState({
          toggleStatus: initialState,
        });
        onToggle && onToggle(initialState);
      }
    }

    toggle = () => {
      const { onToggle } = this.props;
      const { toggleStatus } = this.state;
      this.setState({
        toggleStatus: !toggleStatus,
      });
      onToggle && onToggle(!toggleStatus);
    }

    render() {
      const { forwardedRef, ...rest } = this.props;
      const { toggleStatus } = this.state;

      return (
        <PassedComponent
          {...rest}
          ref={forwardedRef}
          toggle={this.toggle}
          toggleStatus={toggleStatus}
        />
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <WithToggle {...props} forwardedRef={ref} />;
  });
};
  
export default withToggle;