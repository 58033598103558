/** @format */
import Button from 'app/components/ui/button/button';

const CardAction = props => {
  const {
    CustomComponent,
    ImageComponent,
    actionKey,
    onClick,
    dowloadUrl,
    customActionProps,
    disabled = false,
  } = props;

  const onButtonClick = () => onClick(actionKey);

  if (CustomComponent) {
    return <CustomComponent onClick={onButtonClick} disabled={disabled} {...customActionProps} />;
  }

  return (
    <Button
      type="image"
      disabled={disabled}
      onClick={onButtonClick}
      {...customActionProps}
      href={dowloadUrl}
    >
      <ImageComponent className="button-image"></ImageComponent>
    </Button>
  );
};

export default CardAction;
