import React, { Component } from 'react'

import FileLine from './fileLine'
import FileButton from 'app/components/ui/ObsoleteFileUploadButton'

export default class fileField extends Component {
  _deleteFile = async (guid) => {
    const { fileList, fileApi, onUpdateFile, onError, objectType, onFileActionStart, onFileActionFinish } = this.props
    const { deleteFile } = fileApi

    try {
      onFileActionStart && onFileActionStart()
      const result = await deleteFile(guid)
      onFileActionFinish && onFileActionFinish()

      if (!result.isError) {
        onUpdateFile && onUpdateFile(fileList.filter(file => file.guid !== guid), objectType)
      } else {
        throw result
      }
    } catch (error) {
      onFileActionFinish && onFileActionFinish()
      onError && onError(error)
    }
  }

  _onUploadFile = file => {   
    const { fileList, onUpdateFile } = this.props

    onUpdateFile([...fileList, file] )
  }

  _onOpenFile = async (id) => {
    const { fileApi, onFileActionFinish, onFileActionStart } = this.props
    const { getFile } = fileApi

    onFileActionStart()
    const file = await getFile(id)
    onFileActionFinish()
    
    return file
  }

  _renderUploadButton = () => {
    const { 
      fileList,
      fileApi, 
      onFileActionStart,
      onFileActionFinish,
      needUploadButton = true, 
      maxFileCount, 
      fileRights,
      disabled = false,
      onError
    } = this.props

    if (!fileRights.CREATE) {
      return null
    }
    if (!needUploadButton) {
      return null
    }

    return (
      maxFileCount && (fileList.length >= maxFileCount) ? null : (
        <FileButton
          key={`${new Date().valueOf()}`} // fix same file name onChange firing
          onUploadStart={onFileActionStart}
          onUploadFinish={onFileActionFinish}
          onError={onError}
          onFileUploaded={this._onUploadFile}
          fileApi={fileApi}
          disabled = {disabled}
        />
      )
    )
  }

  render() {
    const { 
      fileList, 
      fileRights, 
      urlPArt
    } = this.props

    return (
      <>
        <div>
          {fileList.map((file, i) => (
            <FileLine 
              key={i} 
              urlPArt={urlPArt}
              file={file} 
              onOpen={this._onOpenFile} 
              onDelete={this._deleteFile} 
              fileRights={fileRights} 
            />
          ))}
        </div>
        {this._renderUploadButton()}
      </>
    )
  }
}