import React, { Children } from 'react';

import Button from 'app/components/ui/button/button'
import { ReactComponent as EditImg } from 'assets/img/commonVer2/edit_pen.svg'
import SaveButtons from './SaveButtons'
import CardActions from '../cardActions'

export default function FunctionalCard(props){
  const {
    cardState,
    showButtons,
    canEdit = true,
    editOnly,
    onSubmit,
    submitEnabled,
    submitTitle,
    onCancelForm,
    setState,
    onActionClick,
    downloadUrls,
    actions,
    className,
    editState,
    children,
    customActionProps,
    cancelEnabled
  } = props

  const childrenArr = Children.toArray(children);
  const { mainChildren, toolbarChildren } = childrenArr.reduce(
    (acc, cur) => {
      const target = cur.props.toolbar ? 'toolbarChildren' : 'mainChildren';
      acc[target].push(cur);
      return acc;
    },
    {
      mainChildren: [],
      toolbarChildren: [],
    }
  );
  const submitDisabled = submitEnabled ? !submitEnabled() : null;
  
  return (
    <aside className={`card-material card-material--${cardState} ${className || ''}`}>
      {
        showButtons && canEdit && !editOnly ? (
          <div className="card-material__management">
            {
              (!actions || editState) ? (
                <SaveButtons {...{
                  submitDisabled,
                  cancelEnabled,
                  submitTitle,
                  onSubmit,
                  onCancelForm,
                }} />
              ) : (
                <>
                  <Button
                    className='card-management__item'
                    type = 'primary'
                    onClick = {() => setState({ editState : true })}
                  >
                    <EditImg className='button-image' />
                    <span className='button-title'>Редактировать</span>
                  </Button>
                  {toolbarChildren}
                </>
              )
            }
            <CardActions
              onActionClick={onActionClick}
              actions={actions}
              dowloadUrls={downloadUrls}
              customActionProps={customActionProps}
            />
          </div>
        ) : (

        toolbarChildren.length ? (<div className="card-material__management">
          {toolbarChildren}
        </div>) : null
          
        )
      }
      {mainChildren}
      {showButtons && editOnly ? (
        <div className="card-material__edit-card-management">
          <SaveButtons {...{
            submitDisabled,
            cancelEnabled,
            submitTitle,
            onSubmit,
            onCancelForm,
          }} />
        </div>
      ) : null}
    </aside>
  )
}
