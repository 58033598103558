import React from 'react'

export default function progress(props) {
  const { progress, className } = props
  const decimal = Math.floor((progress / 10) % 10)
  const unit = progress % 10

  return (
    <div className={`progress ${className || ''}`}>
      <div className='progress__bar' style={{ width: progress + '%'}}></div>
      <span className='progress__percent percent'>
        {progress < 70 ? (
          <>
            <span className={`percent__unit ${progress > 46 ? 'unit--colored' : ''}`}>{decimal ? decimal : ''}</span>
            <span className={`percent__unit ${progress > 49 ? 'unit--colored' : ''}`}>{unit}</span>
            <span className={`percent__unit ${progress > 54 ? 'unit--colored' : ''}`}>%</span>
          </>
        ) : (
          <span className={`percent__unit unit--colored`}>{`${progress}%`}</span>
        )}
      </span>
    </div>
  )
}