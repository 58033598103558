/** @format */
import withModal from 'app/components/HOC/ModalHOC';
import Message from './message';
import styles from './dialog.module.scss';
import ButtonsPanel from './buttonsPanel';
import Button from 'app/components/ui/button/button';
import { getClassName } from 'app/core/utility/jsx';

export const dialogMode = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const dialogType = {
  confirm: 'confirm',
  warning: 'warning',
  success: 'success',
  navigation: 'navigation',
  message: 'message',
  danger: 'danger',
};

function ConfirmDialog(props) {
  const { header, type, title, error, messages, mode = dialogMode.small, children } = props;

  return (
    <div className={getClassName([styles.container, styles[mode]])}>
      <div className={styles.header}>{getHeader(type, header)}</div>
      {children}
      {!children && (
        <div className={styles.content}>
          <Message {...{ title, error, messages }} />
        </div>
      )}
      <ButtonsPanel>{_getButtons(props)}</ButtonsPanel>
    </div>
  );
}

const _getButtons = ({ item, type, onCancel, onSubmit, submitText, cancelText, navigateText, submitDisabled }) => {
  const submitBtn = (
    <Button
      className={styles.button}
      type={type === dialogType.danger ? 'danger' : 'primary'}
      onClick={() => onSubmit && onSubmit(item)}
      disabled={submitDisabled}
    >
      <span className="button-title">{submitText || 'Подтвердить'}</span>
    </Button>
  );

  const cancelBtn = (
    <Button className={styles.button} type="secondary" onClick={onCancel}>
      <span className="button-title">{cancelText || 'Отменить'}</span>
    </Button>
  );

  const closeBtn = (
    <Button className={styles.button} type="primary" onClick={onCancel}>
      <span className="button-title">Закрыть</span>
    </Button>
  );

  const forwardBtn = (
    <Button className={styles.button} type="text" onClick={() => onSubmit && onSubmit(item)}>
      <span className="button-title">{submitText || 'Далее'}</span>
    </Button>
  );

  const stayBtn = (
    <Button className={styles.button} type="text" onClick={onCancel}>
      <span className="button-title">{navigateText || 'Остаться в разделе'}</span>
    </Button>
  );

  switch (type) {
    case dialogType.confirm:
    case dialogType.danger:
      return [submitBtn, cancelBtn];
    case dialogType.navigation:
      return [forwardBtn, stayBtn];
    case dialogType.message:
    case dialogType.warning:
    case dialogType.success:
    default:
      return closeBtn;
  }
};

const getHeader = (type, header) => {
  if (header) {
    return header;
  }

  switch (type) {
    case dialogType.confirm:
    case dialogType.danger:
    case dialogType.navigation:
      return 'Подтвердите действие';
    case dialogType.message:
    case dialogType.warning:
    case dialogType.success:
    default:
      return 'Сообщение';
  }
};

export default withModal(ConfirmDialog);

