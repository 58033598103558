import {useEffect} from "react";
import { capitalize } from 'app/core/utility/common'

export default function useRolesForm({formData, serverFormViewModel, onAdd, errorByFields}){

  function getFormDataValue(fieldName){
    const capitalizeKey = capitalize(fieldName)
    const lineViewModel = serverFormViewModel ? serverFormViewModel[capitalizeKey] : null
    return formData[lineViewModel ? capitalizeKey : fieldName]
  }

  function getFieldsetError(fieldsetName, fieldName, index){
    return errorByFields[`${capitalize(fieldsetName)}[${index}].${capitalize(fieldName)}`] || errorByFields[`${fieldsetName}[${index}].${capitalize(fieldName)}`]
  }

  // костыля, которая фиксит, что с бэка приходит 0001 год, а минимум для датапикера 1990. Иначе падение
  useEffect(() => {
    if(Array.isArray(getFormDataValue('roles')) && getFormDataValue('roles').length === 0){
      onAdd('roles', {role: '', dateFrom: '', dateTill: ''})
    }
  }, [])


  return {
    getFieldsetError,
    getFormDataValue,
  }
}