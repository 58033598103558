import React from 'react'

export default function policySetting(props) {
  const { descTitle, description, controlHint, children, disabled = false } = props

  return (
    <div className={`policy-block__setting setting ${disabled ? 'setting--disabled' : ''}`}>
      <div className="setting__control control">
        {children}
        <div className="control__hint">{controlHint}</div>
      </div>
      <div className="setting__description description">
        <div className="description__header">{descTitle}</div>
        <div className="description__text">{description}</div>
      </div>
    </div>
  )
}
