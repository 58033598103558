import React from 'react'

import {
  specialSymbolRequired,
  digitsRequired,
  requiredLength,
  lowerCaseLettersRequired,
  upperCaseLettersRequired
} from 'app/core/utility/validation'

const passwordPoliciesTexts = {
  "matchChecking": 'пароли должны совпадать',
  "minimumLength": 'минимальное количество символов',
  "uppercaseLettersInPassword": 'буквы латинского алфавита верхнего регистра',
  "lowercaseLettersInPassword": 'буквы латинского алфавита нижнего регистра',
  "digitsInPassword": 'арабские цифры (0-9)',
  "specialSymbolsInPassword": 'специальные символы .,:;!?#/\\{}[]$<>@+_-%^"\''
}

const policyErrorMessage = (policies, password) => {
  const {
    minimumLength,
    uppercaseLettersInPassword,
    lowercaseLettersInPassword,
    digitsInPassword,
    specialSymbolsInPassword
  } = policies

  const upperCaseViolated = !upperCaseLettersRequired(password).valid
  const lowerCaseViolated = !lowerCaseLettersRequired(password).valid
  const lengthViolated = !requiredLength(password, minimumLength).valid
  const digitsViolated = !digitsRequired(password).valid
  const specialSymbolViolated = !specialSymbolRequired(password).valid

  return (
    <div className='password-policy'>
      Пароль должен соответствовать следующим требованиям и содержать:<br/>
      <div className="password-policy-rules">
        {uppercaseLettersInPassword ? <span className={`policy-rule policy-rule--${upperCaseViolated ? 'violated' : 'normal'}`}>{`- ${passwordPoliciesTexts.uppercaseLettersInPassword}`}</span> : null}
        {lowercaseLettersInPassword ? <span className={`policy-rule policy-rule--${lowerCaseViolated ? 'violated' : 'normal'}`}>{`- ${passwordPoliciesTexts.lowercaseLettersInPassword}`}</span>  : null}
        {digitsInPassword ? <span className={`policy-rule policy-rule--${digitsViolated ? 'violated' : 'normal'}`}>{`- ${passwordPoliciesTexts.digitsInPassword}`}</span> : null}
        {specialSymbolsInPassword ? <span className={`policy-rule policy-rule--${specialSymbolViolated ? 'violated' : 'normal'}`}>{`- ${passwordPoliciesTexts.specialSymbolsInPassword}`}</span> : null}
        {minimumLength ? <span className={`policy-rule policy-rule--${lengthViolated ? 'violated' : 'normal'}`}>{`- ${passwordPoliciesTexts.minimumLength} ${minimumLength}`}</span>  : null}
      </div>
    </div>
  )
}

export default policyErrorMessage