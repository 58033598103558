import { 
  BREADCRUMBS,
  BREADCRUMBSPUSHITEM,
  BREADCRUMBSPOPITEM,
  BREADCRUMBSCLICK,
  BREADCRUMBS_CLEAR
 } from '../actions/actionTypes'

const defaultState = {
  linkPart: [],
  componentPart: [],
  items: []
}

const breadcrumbsReducer = (state = defaultState, action) => {
  const { payload }  = action
  const { items } = state
  const { current, firstBreadcrumbs, forceUpdate } = payload || {}

  switch (action.type) {
    
    case BREADCRUMBS:
      return {
          ...state,
          ...payload
      }

    case BREADCRUMBSPUSHITEM:
      if (forceUpdate) {
        return {
          ...state,
          items: [...firstBreadcrumbs]
        }
      }

      const pushingIndex = items.findIndex((item) => item.link === current.link)

      if (pushingIndex >= 0) {
        return {
          ...state,
          items: items.slice(0, pushingIndex + 1)
        }
      }

      return {
          ...state,
          items: items.length ? [...items, current ] : [...firstBreadcrumbs]
      }

    case BREADCRUMBSCLICK:
      const clickedItemIndex = items.findIndex((item) => item.link === payload)
      if (clickedItemIndex >= 0) {
        return {
          ...state,
          items: items.slice(0,clickedItemIndex + 1)
        }
      }

      return state

    case BREADCRUMBSPOPITEM:
      items.pop()

      return {
          ...state,
          ...payload,
          items: [ ...items ]
      }

    case BREADCRUMBS_CLEAR:
      return defaultState
      
    default: 
        return state
  }
}

export default breadcrumbsReducer;