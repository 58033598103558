export default (state = {}, action, prefix = '') => {

  if( action.type === prefix + 'LIST_LOAD_LIST_FILTER_FOR_REQUEST' ){
    return action.payload;
  }
  
  if( action.type === prefix + 'LIST_FILTER_REQUEST' ){
    return action.payload;
  }

  if( action.type === prefix + 'LIST_FILTER_DROP' ){
    return {};
  }

  if( action.type === prefix + 'LIST_DROP' ){
    return {};
  }

  return state;
}