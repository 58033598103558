/** @format */
import { useState } from 'react';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog';
import { stringPlural } from 'app/core/utility/common';
import Overlay from 'app/components/ui/overlay';
import { dispatchLogic } from 'redux/actions/common';
import { useDispatch } from 'react-redux';
import service from 'app/services/service';
import withTooltip from 'app/components/HOC/TooltipHOC';

const Button = withTooltip(ButtonComponent);

export default function ApproveRequest({ afterActionCallback, items = [], withText = false }) {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();
  const onDialogToggle = () => {
    setDialogOpened(!dialogOpened);
  };

  const afterAction = () => {
    setDialogOpened(false);
    afterActionCallback && afterActionCallback();
  };

  const approveRequests = async () => {
    setInProgress(true);
    const response = await service('certificateAuthorityService', 'approveRequest', {
      requestIds: items.map(i => i.id),
    });
    const { isError, errors } = response || {};
    !isError && afterAction();
    isError && dispatchLogic(dispatch, 'MOCK', null, isError, errors);
    setInProgress(false);
  };
  const titleVariants = ['запрос', 'запросы', 'запросы'];
  const textVariants = ['выбранного запроса', 'выбранных запросов', 'выбранных запросов'];
  const type = items[0]?.type?.id;
  const disabled =
    !items.length || items.some(i => i?.type?.id !== type || i?.status?.id !== 'Queued');

  return (
    <>
      {inProgress && <Overlay />}
      <Button
        type={withText ? buttonTypes.primary : buttonTypes.image}
        onClick={onDialogToggle}
        disabled={disabled}
        tooltip={withText ? null : 'Одобрить'}
      >
        <Img img={Image.Check} />
        {withText && <span className="button-title">Одобрить</span>}
      </Button>
      {dialogOpened && (
        <ConfirmDialog
          header={`Одобрить ${stringPlural(items.length, titleVariants)}?`}
          type={dialogType.confirm}
          submitText="Одобрить"
          onSubmit={approveRequests}
          onCancel={onDialogToggle}
          messages={[`Подтвердите одобрение ${stringPlural(items.length, textVariants)}.`]}
        ></ConfirmDialog>
      )}
    </>
  );
}

