import {
  ADMITTANCECREATE,              
  ADMITTANCECREATEUPDATEFORMDATA,
  ADMITTANCECREATEINPROGRESS    ,
  ADMITTANCECREATERESETACTION   ,
  ADMITTANCECREATECLEARFORM ,
  ADMITTANCECREATEINITFORMDATA
  } from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const registerAdmittance = data => dispatch => {
  return service('admittanceService', 'createAdmittance', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, ADMITTANCECREATE, data, isError, errors)
    })
}

export const createAdmittanceUpdateFormData = (data) => dispatch => {
  dispatch({
    type: ADMITTANCECREATEUPDATEFORMDATA,
    payload: data
  })
}

export const createAdmittanceInitFormData = (data) => dispatch => {
  dispatch({
    type: ADMITTANCECREATEINITFORMDATA,
    payload: data
  })
}

export const createAdmittanceInProgressAction = value => dispatch => {
  dispatch({
    type: ADMITTANCECREATEINPROGRESS,
    payload: { inProgress: value }
  })
}

export const createAdmittanceResetAction = () => dispatch => {
  dispatch({
    type: ADMITTANCECREATERESETACTION,
    payload: null
  })
}

export const admittanceCreateClearForm = () => dispatch => {
  dispatch({
    type: ADMITTANCECREATECLEARFORM,
    payload: { 
    }
  })
}