import { 
  ERROR,
  FIELDSERROR,
  PASSWORDPOLICYGET,
  PASSWORDPOLICYUPDATE,
  PASSWORDPOLICYRESET,
  PASSWORDPOLICYINPROGRESS,
  PASSWORDPOLICYUPDATEFORMDATA,
  PASSWORDPOLICYRESETFORMDATA
} from '../actions/actionTypes'

const defaultState = {
  settings: null,
  updatedSettings: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { updatedSettings, settings } = state

  switch (action.type) {
    case PASSWORDPOLICYGET:
      return {
        ...state,
        settings: payload,
        updatedSettings: payload,
        inProgress: false
      }

    case ERROR:
    case FIELDSERROR:
      return {
        ...state,
        inProgress: false
      }
      
    case PASSWORDPOLICYUPDATE:
      return {
        ...state,
        settings: updatedSettings,
        inProgress: false
      }

    case PASSWORDPOLICYINPROGRESS:
      return {
        ...state,
        inProgress: payload
      }

    case PASSWORDPOLICYRESET:
      return {
        ...defaultState,
        updatedSettings,
        settings
      }

    case PASSWORDPOLICYUPDATEFORMDATA:
      const {name, value} = payload
      const { wrongPasswordBlockTime } = updatedSettings

      return {
        ...state,
        updatedSettings: {
          ...updatedSettings,
          wrongPasswordBlockTime: name === 'wrongPasswordAttempts' 
                                  && value === 0
                                    ? 0 
                                    : wrongPasswordBlockTime,
          [name]: value
        }
      }

    case PASSWORDPOLICYRESETFORMDATA:
      return {
        ...state,
        updatedSettings: settings
      }

    default:
      return state
  }
}