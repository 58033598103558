import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';

import PageItem from './pageItem'
import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'
import { debounce } from 'app/core/utility/common'
import Button from 'app/components/ui/button/button'
import globalConfig from 'app/core/config'

export const getPageData = ({ items, activePage = 1, itemsPerPage = globalConfig.paginationPerPage}) => {
  if (!items || items.length === 0){
    return items
  }

  const lastPage = Math.ceil(items.length/itemsPerPage)
  const firstPage = 1

  if (activePage < firstPage || activePage > lastPage){
      return items.slice(0, Math.max(items.length - 1, Math.abs(itemsPerPage)))
  }

  const firstElement = activePage === 1 ? 0 : (activePage - 1) * itemsPerPage
  const lastElement = firstElement + itemsPerPage
  
  return items.slice(firstElement, lastElement)
}

class Pagination extends Component {
  static get propTypes() {
    return {
      className: PropTypes.string,
      itemsCount: PropTypes.number,
      itemsPerPage: PropTypes.number,
      onPageChange: PropTypes.func,
    };
  }
  
  constructor(props) {
    super(props)
    
    const { itemsPerPage = globalConfig.paginationPerPage } = props

    this.state = {
      activePage: 1,
      jumpTo: null,
      perPage: itemsPerPage
    }
  }

  componentWillReceiveProps( props ){
    if( props.currentPage === 1 ){
      this.setState({ activePage : 1 })
    }
  }
  
  _onPageClick = value => {
    const { onPageChange } = this.props
    const { perPage, activePage } = this.state

    if (activePage !== value ){
      this.setState({ activePage: value}, () => {
        onPageChange && onPageChange({activePage: value, itemsPerPage: perPage})
      })
    }
  }

  _onPageChanged = debounce(
    ({ activePage, itemsPerPage }) => {
      const { onPageChange } = this.props

      onPageChange && onPageChange({ activePage, itemsPerPage })
    } , 200
  )

  _onPageDecrement = () => {
    const { activePage, perPage } = this.state
    const newPrevPage = Math.max(activePage - 1, 1 )

    newPrevPage !== activePage && this.setState({ activePage: newPrevPage }, 
      this._onPageChanged({activePage: newPrevPage, itemsPerPage: perPage})
    )
  }

  _onPageIncrement = () => {
    const { activePage, perPage } = this.state
    const totalPages =  this._getPagesCount()
    const newNextPage = Math.min(activePage + 1, Math.max(1, totalPages))

    newNextPage !== activePage && this.setState({ activePage: newNextPage }, 
      this._onPageChanged({activePage: newNextPage, itemsPerPage: perPage})
    ) 
  }

  _onWheel = e => {
    e.deltaY > 0 && this._onPageIncrement()
    e.deltaY < 0 && this._onPageDecrement()
  }

  _onPerPage = item => {
    const { perPage, activePage } = this.state
    const { onPageChange } = this.props
    const { title: newPerPage } = item
    const newActive = perPage > newPerPage ? 
    Math.ceil(
      Math.max((perPage * activePage - perPage + 1), newPerPage)
      / newPerPage
    ) : Math.ceil(
      Math.max( perPage * activePage, newPerPage)
      / newPerPage
    ) 
    
    this.setState({ activePage: newActive, perPage: newPerPage })
    onPageChange && onPageChange({ activePage: newActive, itemsPerPage: newPerPage })
  }

  _getPagesCount = () => {
    const { itemsCount = 0, itemsPerPage = globalConfig.paginationPerPage } = this.props

    return Math.ceil(itemsCount/new Number(itemsPerPage))
  }

  _isFirstEllipsis = () => {
    const { activePage } = this.state

    return activePage > 5
  }

  _isLastEllipsis = () => {
    const { activePage } = this.state
    const renderPages = this._getPages()

    return this._getPagesCount() - activePage > 3
           && renderPages[renderPages.length - 1] !== this._getPagesCount() - 1
  }

  _getPages = () => {
    const { activePage } = this.state

    const result = 
      [activePage - 4, activePage - 3, activePage - 2, activePage - 1, activePage, activePage + 1, activePage + 2, activePage + 3]
      .filter(item => item > 0 && item <= this._getPagesCount())
    
    return result
  }

  _renderPages = () => {
    const { activePage } = this.state
    const pages = this._getPages()
    const renderPages = pages && pages[0] ? pages : [1]
    const renderLastPage = renderPages[renderPages.length - 1] !== this._getPagesCount() && this._getPagesCount() !== 0

    return (
      <Fragment>
        <PageItem 
          key={1} 
          value={1} 
          isActive={activePage === 1}
          onClick={this._onPageClick}
        />
        {this._isFirstEllipsis() ? (
          <PageItem 
            key={'...left'} 
            value={'...'} 
            isActive={false}
            disabled={true}
          />
        ) : null}
        {renderPages.slice(1).map(item => 
          <PageItem 
            key={item} 
            value={item} 
            isActive={activePage === item}
            onClick={this._onPageClick}
        />)}
        {this._isLastEllipsis() ? (
          <PageItem 
            key={'...right'} 
            value={'...'} 
            isActive={false}
            disabled={true}
          />
        ) : null}
        {renderLastPage ? (
          <PageItem value={this._getPagesCount()} onClick={this._onPageClick}/>
        ) : null}
      </Fragment>
    )
  }

  resetActivePage = () => {
    this.setState({ activePage : 1 })
  }

  render() {
    const { className } = this.props

    return (
      <section className={`pagination ${className || ''}`}>
        <div className='pagination__page-picker' onWheel = {this._onWheel}>
          <Button
            className='pagination__item pagination__decrement'
            type = 'text'
            onClick = {this._onPageDecrement}
          >
            <ChevronImg className='button-image'/>
          </Button>
          {this._renderPages()}
          <Button
            className='pagination__item pagination__increment'
            type = 'text'
            onClick = {this._onPageIncrement}
          >
            <ChevronImg className='button-image'/>
          </Button>
        </div>
      </section>
    )
  }
}

export default Pagination