/** @format */

import { useState } from 'react';
import Img from 'app/components/ui/Img';

export default function ChipsItem({ name, icon, type, isDisabled, isItemActive, onClickHandler }) {
  const [isActive, setActiveState] = useState(true);

  const onClickItemHandler = () => {
    if (isDisabled) {
      return;
    }

    onClickHandler(type, !isItemActive);
    setActiveState(!isActive);
  };

  return (
    <>
      <div
        className={`chips__item ${isItemActive ?? isActive ? 'chips__item--active' : ''} ${
          isDisabled ? 'chips__item--disabled' : ''
        }`}
        onClick={onClickItemHandler}
      >
        {icon && <Img img={icon} />}
        {name}
      </div>
    </>
  );
}
