import useListFilter from "app/components/list/Filters/useListFilter";
import usePagination from "app/components/ui/pagination/serverSidePagination/usePagination";
import { arrayToIdHashMap, updateCollectionByAnotherCollection, updateCollectionByItemId } from "app/core/utility/common";
import service from "app/services/service";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dispatchLogic } from "redux/actions/common";
import { setUpdatedEvent } from "redux/actions/notification";
import { NotificationTypeMapping } from "./notificationItem";


export default function useNotification(props) {
  const [inProgress, onInProgress] = useState(false)
  const profile = useSelector(({ profile }) => profile);
  const [list, setList] = useState([])
  const dispatch = useDispatch()
  const currentPageRef = useRef(1)
  const connection = useSelector(({notification}) => notification ? notification.wsConnection : null)
  const updatedEvent = useSelector(({notification}) => notification.updatedEvent)
  const needRefreshByRest = useSelector(({notification}) => notification ? notification.needRefreshByRest : null)
  const lastRefreshedValues = useSelector(({notification}) => notification ? notification.lastRefreshedValues : null)
  
  const {
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    filterState,
    filterForRequest,
  } = useListFilter();

  const {
    currentPage,
    pageCount,
    perPage,
    totalCount,
    setPerPage,
    setTotalItems,
    setCurrentPage,
    needRefresh, 
    setNeedRefresh
  } = usePagination({
    defaultTotalItems: 0,
    defaultCurrentPage: 1,
  })

  const getList = async () => {
    const requestParams = {
      page: currentPage,
      pageSize: perPage,
      conditions: filterForRequest,
    };
  
    onInProgress(true)
    const response = await service('notificationService', 'getAll', requestParams)
    const { data, dataCount = 0, errors, isError } = response

    setTotalItems(isError ? 0 : dataCount)
    const array = data.map(item => ({...item, id: item.uid}))
    !isError && setList(array)
    isError && dispatchLogic(dispatch, 'ERROR', data, isError, errors)
    onInProgress(false)
  }

  const updateCurrentData = (wsData) => {
    const data = typeof wsData === 'string' ? JSON.parse(wsData) : wsData
    const { subject } = data || {}
    const { code, uid } = subject || {}
    
    if (NotificationTypeMapping[code] && !inProgress) {
      setList((prevState) => {
        const current = arrayToIdHashMap(prevState, 'uid')
        const newItem = {...subject, id : uid}

        if (current[uid]) {  
          return updateCollectionByItemId(prevState, newItem)
        } else if (currentPageRef.current === 1) {
          return [newItem, ...prevState]
        }
        return prevState
      })
    }
  }

  useEffect(() => {
    if (updatedEvent) {
      const updated = updateCollectionByItemId(list,updatedEvent)
      setList(updated)
      dispatch(setUpdatedEvent(null))
    }
  }, [updatedEvent]);

  useEffect(() => {
    if (connection) {
      connection.on('Notification', updateCurrentData)
    }
  }, [connection])

  useEffect(() => {
    setCurrentPage(1)
    setNeedRefresh(true)
  }, [filterState]);
  
  useEffect(() => {
    currentPageRef.current = currentPage
    setNeedRefresh(true)
  }, [currentPage, perPage])
  
  useEffect(() => {
    if (needRefresh) {
      setNeedRefresh(false)
      getList()
    }
  }, [needRefresh])

  useEffect(() => {
    if (lastRefreshedValues.length && !inProgress) {
      setList(updateCollectionByAnotherCollection(list, lastRefreshedValues))
    }
  }, [lastRefreshedValues])

  return {
    list,
    inProgress,
    onInProgress,
    totalCount,

    filterLogic: {
      filterState,
      setFilterStateAction,
      filterForRequest,
      setFilterForRequestAction,
      setFullFilterStateAction,
    },
    profile,
    pagination: {
      currentPage,
      pageCount,
      perPage,
      setCurrentPage,
    },
    totalCount,
  };
}