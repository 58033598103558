/** @format */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { breadcrumbsPushAction, breadcrumbsClearAction } from 'redux/actions/common/breadcrumbsAction';

export default function breadcrumbsHOC(Component, options) {
  return props => {
    const { keepBreadcrumbs, virtualBreadcrumbs, title } = options ?? {};

    const location = useLocation();
    const { pathname } = location;
    const dispatch = useDispatch();

    useEffect(() => {
      const executeBreadcrumbsLogic = async () => {
        if (!keepBreadcrumbs) {
          dispatch(breadcrumbsClearAction());
        }

        dispatch(breadcrumbsPushAction(pathname, title, virtualBreadcrumbs));
      };

      executeBreadcrumbsLogic();
    }, []);

    return <Component {...props} />;
  };
}
