/** @format */

import React from 'react';
import styles from './dashboard.module.scss';
import { getClassName } from 'app/core/utility/jsx';
import { Image } from 'app/components/ui/Img';
import IconWidget from './iconWidget';
import { IconWidgetData, NormalText } from './typography';
import OrganizationWidget from './organizationWidget';
import WaitForSign from './panels/waitForSign';
import Expiring from './panels/expiring';
import Activity from './panels/activity';
import KeyCarriers from './panels/keyCarriers';
import Licenses from './panels/licenses';
import MainChartWidget from './panels/mainChartWidget';
import EskziLegend from './panels/chartLegend/EskziLegend';
import KeyDocLegend from './panels/chartLegend/KeyDocLegend';
import Overlay from '../ui/overlay';
import useDashboard from './useDashboard';
import EskziChart from './panels/chart/EskziChart';
import KeyDocChart from './panels/chart/KeyDocChart';

export default function Dashboard() {
  const { dashboardData, inProgress, organization } = useDashboard();
  const { shortName, name, okz } = organization || {};
  const {
    eskziStandard,
    kdNotRecorded,
    eskziUserNewLastWeek,
    eskzi,
    licenses,
    keyDocuments,
    waitForSign,
    expiring,
    keyCarriers,
    licensesActive,
    activity,
  } = dashboardData;

  return (
    <section className={getClassName(['dashboard', styles.dashboard])}>
      {inProgress ? <Overlay /> : null}
      <IconWidget className={'dashboard-header-etalon'} img={Image.Etalon}>
        <NormalText>Эталонных экземпляров</NormalText>
        <IconWidgetData>{eskziStandard}</IconWidgetData>
      </IconWidget>
      <IconWidget className={'dashboard-header-key-docs'} img={Image.KeyDocument}>
        <NormalText>КД без носителя</NormalText>
        <IconWidgetData>{kdNotRecorded}</IconWidgetData>
      </IconWidget>
      <IconWidget className={'dashboard-header-user-accounts'} img={Image.UserAccounts}>
        <NormalText>Новых лицевых счетов</NormalText>
        <IconWidgetData>{eskziUserNewLastWeek}</IconWidgetData>
      </IconWidget>
      <OrganizationWidget name={shortName || name || ''} isOkz={okz} />
      <MainChartWidget
        slotEskzi={<EskziChart eskzi={eskzi} licenses={licenses} />}
        slotEskziLegend={<EskziLegend eskzi={eskzi} licenses={licenses} />}
        slotKeyDocLegend={<KeyDocLegend {...keyDocuments} />}
        slotKeyDoc={<KeyDocChart {...keyDocuments} />}
      />
      <WaitForSign {...waitForSign} />
      <Expiring {...expiring} />
      <KeyCarriers {...keyCarriers} />
      <Licenses {...licensesActive} />
      <Activity {...activity} />
    </section>
  );
}
