import Input from 'app/components/ui/Input'
import TextArea from 'app/components/ui/textArea'
import { notEmpty } from 'app/core/utility/validation'
import { CardState } from 'app/components/ui/card/cardMaterial'
import DatePicker from 'app/components/ui/date'
import { ReactComponent as LockImg }  from 'assets/img/commonVer2/ic_lock_on.svg'
import withTooltip from 'app/components/HOC/TooltipHOC'
import { formatFIOInitials } from 'app/core/utility/common'

const LockedImage = withTooltip(LockImg)

export const cardLines = {
  id: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field organization-card__id'
          label = '№'
          value={editValue || 'Черновик'}
          readOnly={cardState === CardState.view}
          disabled={cardState !== CardState.view}
        />
      )
    },
  },
  createDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <DatePicker
          className='card-field organization-card__createDate'
          label = 'Дата создания'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState !== CardState.view}
        />
      )
    },
  },
  inn: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field organization-card__inn'
          pattern='^[0-9]{0,12}$'
          label='ИНН'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('inn', value)}
          error={error}
          isRequired
        />
      )
    }
  },
  updateDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <DatePicker
          className='card-field organization-card__updateDate'
          label = 'Дата изменения'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState !== CardState.view}
        />
      )
    },
  },
  name: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field organization-card__name'
          label='Наименование'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('name', value)}
          error={error}
          isRequired
        />
      )
    },
  },
  kpp: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field organization-card__kpp'
          pattern='^[0-9]{0,9}$'
          label='КПП'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('kpp', value)}
          error={error}
        />
      )
    }
  },
  ogrn: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field organization-card__ogrn'
          pattern='^[0-9]{0,15}$'
          label='ОГРН/ОГРНИП'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('ogrn', value)}
          error={error}
        />
      )
    }
  },
  shortName: {
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field organization-card__shortName'
          label={'Короткое наименование'}
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('shortName', value)}
          error={error}
        />
      )
    },
  },
  code: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input
          className='card-field organization-card__code'
          label='Код'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('code', value)}
          error={error}
        />
      )
    },
  },
  description: {
    renderEdit: (props) => {
      const { cardState, editValue, onChange, error } = props

      return (
        <TextArea 
          className='card-field organization-card__description'
          value={editValue} 
          label='Примечание'
          readOnly={cardState === CardState.view}
          error={error}
          onChange={value => onChange && onChange('description', value)}
        />
      )
    },
  },  
  status: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, commonDataForm } = props
      const { bizLocked, bizLockAuthor } = commonDataForm || {}
      const tooltip = bizLocked ? `Заблокировал: ${formatFIOInitials(bizLockAuthor)}` : ''

      return (
        <Input
          className='card-field organization-card__status'
          label='Статус'
          value={'Действует'}
          readOnly={cardState !== CardState.edit}
          disabled={cardState === CardState.edit}
          ForcedCustomImage = {() => {
            return bizLocked 
                    ? <LockedImage tooltip={tooltip} className='locked-image'/>
                    : null
          }}
        />
      )
    },
  },
}