import { useEffect, useState, useRef } from 'react';

export function useIsVisible({ref, initial = false}) {
  const [isOnScreen, setIsOnScreen] = useState(initial);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting) );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}