import React, {useState} from 'react'
import ExpiredPassword from './ExpiredPassword'
import PasswordRecovery from './PasswordRecovery'
import MailRecovery from './MailRecovery'
import LoginPasswordSubmit from './LoginPasswordSubmit'
import useDefaultLogin from '../hooks/useDefaultLogin'
import InProgressSpinning from './InProgressSpinning'
import VisibleTabs from '../../components/ui/tabs/VisibleTabs'
import DomainAccountLogin from './DomainAccountLogin'
import LoginFormWrapper from './LoginFormWrapper'
import {AUTHTYPE} from '../../core/auth/auth'

function NotValidToken() {
  return <span className='not-valid-token'>Ссылка недействительна или устарела</span>
}

function LoginContent(props){

  const [activeTab, setActiveTab] = useState("ДОМЕННАЯ ЗАПИСЬ")

  const {
    login,
    password,
    confirmPassword,
    expiredPassword,
    loginError,
    passwordError,
    confirmPasswordError,
    expiredPasswordError,
    mailSuccess,
    passwordExpired,
    isMailRecovery,
    isPasswordRecovery,
    tokenCheckResult,
    inProgress,
    headerTitle,
    authMethods,
    visibleMailRecovery,
    onDomainSubmit,
    onMailRecoveryClick,
    setInProgress,
    onExpiredSubmit,
    onPasswordChange,
    onExpiredPasswordChange,
    onConfirmPasswordChange,
    onRecoveryPasswordSubmit,
    onLoginChange,
    onMailRecoverySubmit,
    onLoginSubmit
  } = useDefaultLogin(props)


  const getBodyComponent = () => {
    if (passwordExpired) {
      return (
        <LoginFormWrapper headerTittle={headerTitle}>
          <ExpiredPassword
            password={password}
            confirmPassword={confirmPassword}
            expiredPassword={expiredPassword}
            expiredPasswordError={expiredPasswordError}
            passwordError={passwordError}
            confirmPasswordError={confirmPasswordError}
            onSubmit={onExpiredSubmit}
            onPasswordChange={onPasswordChange}
            onExpiredPasswordChange={onExpiredPasswordChange}
            onConfirmPasswordChange={onConfirmPasswordChange}
          />
        </LoginFormWrapper>
      )
    }
    if (isMailRecovery && !isPasswordRecovery) {
      return (
        <LoginFormWrapper headerTittle={headerTitle}>
          <PasswordRecovery
            login={login}
            onLoginChange={onLoginChange}
            onRecoveryPasswordSubmit={onRecoveryPasswordSubmit}
            mailSuccess={mailSuccess}
            loginError={loginError}
          />
        </LoginFormWrapper>
      )
    } else if (isPasswordRecovery) {
      return tokenCheckResult.checked
        ? tokenCheckResult.isValid
          ? (
            <LoginFormWrapper headerTittle={headerTitle}>
              <MailRecovery
                onPasswordChange={onPasswordChange}
                onConfirmPasswordChange={onConfirmPasswordChange}
                password={password}
                confirmPassword={confirmPassword}
                passwordError={passwordError}
                confirmPasswordError={confirmPasswordError}
                onSubmit={onMailRecoverySubmit}
              />
              </LoginFormWrapper>
          )
          : (
            <LoginFormWrapper headerTittle={headerTitle}>
              <NotValidToken/>
            </LoginFormWrapper>

          )
        : (
          <>
            <InProgressSpinning />
            <div className="mock-loading-div" />
          </>
        )
    } else {
      return (authMethods.includes(AUTHTYPE.aokz) && authMethods.includes(AUTHTYPE.ad)) ? (
        <VisibleTabs
          activeTab={activeTab}
          onTabClick={(tab) => {
            setActiveTab(tab)
          }}
        >
          <LoginFormWrapper tabTitle="УЧЁТНАЯ ЗАПИСЬ" headerTittle={headerTitle}>
            <LoginPasswordSubmit
              login={login}
              onLoginChange={onLoginChange}
              onSubmit={onLoginSubmit}
              onPasswordChange={onPasswordChange}
              passwordError={passwordError}
              loginError={loginError}
              password={password}
              visibleMailRecovery={visibleMailRecovery}
              onMailRecoveryClick={onMailRecoveryClick}
            />
          </LoginFormWrapper>
          <DomainAccountLogin
            tabTitle="ДОМЕННАЯ ЗАПИСЬ"
            onDomainSubmit={onDomainSubmit}
            setInProgress={setInProgress}
          />
        </VisibleTabs>
      ) : (authMethods.includes(AUTHTYPE.ad)) ? (
          <DomainAccountLogin
            tabTitle="ДОМЕННАЯ ЗАПИСЬ"
            onDomainSubmit={onDomainSubmit}
            setInProgress={setInProgress}
          />
      ) : (
        <LoginFormWrapper tabTitle="УЧЁТНАЯ ЗАПИСЬ" headerTittle={headerTitle}>
          <LoginPasswordSubmit
            login={login}
            onLoginChange={onLoginChange}
            onSubmit={onLoginSubmit}
            onPasswordChange={onPasswordChange}
            passwordError={passwordError}
            loginError={loginError}
            password={password}
            visibleMailRecovery={visibleMailRecovery}
            onMailRecoveryClick={onMailRecoveryClick}
          />
        </LoginFormWrapper>
      )

    }
  }

  return (
    <div className={`login-content ${inProgress ? 'spin-blur' : ''}`}>
      {inProgress && <InProgressSpinning />}
      {getBodyComponent()}
    </div>
  )
}

export default LoginContent