export default ( state = null, action, prefix = '' ) => {

  if( action.type === prefix + 'LIST_LOAD_LIST_FIELDS_VISIBLE' ){
    return action.payload;
  }

  if( action.type === prefix + 'LIST_SET_VISIBLE_FIELDS' ){
    return action.payload;
  }

  if( action.type === prefix + 'LIST_TOGGLE_VISIBLE_FIELD' ){
    let flag = true;
    const k = Object.keys( action.payload )[0];
    let tempArr = [...state].filter( el => {
      const key = Object.keys( el )[0];
      if( key === k ){
        flag = false;
        return false;
      }
      return true;
    }) 

    if( flag ){
      tempArr.push( action.payload );
    }
    return tempArr;
  }

  if( action.type === prefix + 'LIST_DROP' ){
    return null;
  }

  return state;
}