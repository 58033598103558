import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ButtonMenu from 'app/components/ui/buttonMenu';
import { buttonTypes } from 'app/components/ui/button/button';
import Checkbox from 'app/components/ui/checkbox'
import { setVisibleFieldsAction, toggleVisibleFieldAction }  from  'redux/actions/list/listActions';
import { ReactComponent as SettingsImg }  from 'assets/img/menu/ic_settings.svg'

export const TableFieldSelect = ({ 
  fieldsConfig, 
  listFieldsVisible = [],
  changedFieldsVisibility, 
  setVisibleFieldsAction, 
  toggleVisibleFieldAction,
  settingsAsGear = false,
  headerInfo
}) => {

  useEffect( () => {
    if(  !changedFieldsVisibility  ) return;
    const arr = fieldsConfig
                  .filter( el => el.visibleOnStart === true )
                  .map( el => { 
                      let temp = {};
                      temp[ el.alias ] = el.title;
                      return temp;
                  })

    setVisibleFieldsAction( arr );
    return function unmount(){ 
      setVisibleFieldsAction( null );
    };
  }, []);

  
  if( !changedFieldsVisibility ){
    return null;
  }

  if( listFieldsVisible === null ){
    return null;
  }

  const onColumnClick = data => {
    let temp = {};
    temp[ data.alias ] = data.title;
    toggleVisibleFieldAction( temp )
  }

  const columns = fieldsConfig.filter( el => el.visibleAlways !== true );
  
  if( columns.length === 0 ) return null;

  return(
    <ButtonMenu
        className='table-column-select'
        title={!settingsAsGear && 'Столбцы'}
        tooltip={'Настройки колонок'}
        tooltipPosition={'bottomLeft'}
        type={settingsAsGear ? buttonTypes.image : buttonTypes.secondary}
        LeftImage={settingsAsGear && SettingsImg}
        hideChevron={settingsAsGear}
        persist
      >
      {columns.map((item, index) => {
        const { title, titleInfo } = item;
        let visible = false;
        listFieldsVisible.forEach( el => {
          const key = Object.keys( el )[0];
          if( key === item.alias ) visible = true;
        })
        
        return (
          <div key={index} >
            <Checkbox checked={ visible } onChange={() => onColumnClick(item, visible)} >
            {( headerInfo ) ? ( titleInfo || title ) : title }
            </Checkbox>
          </div>
        )
      }).filter(item => item)}
    </ButtonMenu>
  );
}

const mapStateToProps = state => {
	return {
    listFieldsVisible : state.listFieldsVisible
  };
}

const mapDispatchToProps = {
  setVisibleFieldsAction,
  toggleVisibleFieldAction
}

export default connect( mapStateToProps, mapDispatchToProps )( TableFieldSelect );