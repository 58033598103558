import React from 'react'
import Button from 'app/components/ui/button/button'
import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'

const breadcrumb = ({ title, headerTitle, disabled, breadcrumbsTitle, lastPart, link, onClick}) => {
  return breadcrumbsTitle ? (
    <>
      <ChevronImg className="item-separator "></ChevronImg>
      <div key ={link} className="br-item">
        <Button
          type = 'text'
          onClick = {() => onClick(link)}
          disabled={disabled || lastPart}
        >
          <span className='button-title'>{headerTitle || title}</span>
        </Button>
      </div>
    </>
  ) : null
}

export default breadcrumb