/** @format */

import React, { useState } from 'react';
import Img, { Image } from 'app/components/ui/Img';
import { cssModuleClassNameGetter } from 'app/core/utility/jsx';

import styles from './DownloadStateItem.module.scss';
const getClass = cssModuleClassNameGetter(styles);

const iconImages = {
  imported: Image.CheckCircle,
  recognized: Image.CheckCircle,
  duplicates: Image.Alert,
  notRecognized: Image.AlertTriangle,
  errors: Image.AlertTriangle,
};

export default React.memo(function DownloadStateItem({ text, type, handler, isDisabled, isActive }) {
  const onClickHandler = () => {
    !isDisabled && handler(type, !isActive);
  };

  const iconImage = iconImages[type];

  return (
    <div
      className={getClass('dlStateItem', [
        'dlStateItem',
        {
          noIcon: !iconImage,
          isActive,
          isDisabled,
        },
      ])}
      onClick={onClickHandler}
    >
      {iconImage && <Img img={iconImage} className={getClass('icon', ['icon', type, { isActive }])} />}
      {text}
    </div>
  );
});
