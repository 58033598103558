import React from 'react';

import { api } from 'app/services/service';
import { useDispatch } from 'react-redux';
import { dispatchLogic } from 'redux/actions/common';
import { ERROR } from 'redux/actions/actionTypes';

import UserAccountItemsPicker from './UserAccountItemsPicker';

const Issuer = ({ userId, config, setInProgress, pickerVisible, onCancel, onClose, afterAction }) => {
  const dispatch = useDispatch();
  const bookItem = async (eskziId, eskziUserId) => {
    setInProgress && setInProgress(true);
    if (!(eskziId && eskziUserId)) {
      return null;
    }

    const OkiDeliveryTemplateId = 28;
    const bookingData = {
      eskziId,
      eskziUserId,
      eventTemplateId: OkiDeliveryTemplateId,
      transmittalLetterDate: new Date(),
    };

    const { data, errors, isError } = await api(config.bookingApi, bookingData);
    setInProgress && setInProgress(false);

    if (errors && errors.length) {
      dispatchLogic(dispatch, ERROR, {}, isError, errors);
      return null;
    }

    return { eskziReservationId: data.id };
  };

  const signatureProps = {
    signType: 'EskziIssue',
    signHeader: 'Выдача',
    prepareDataToSign: bookItem,
    actionCallback: async requestData => {
      setInProgress && setInProgress(true);
      const result = await api(config.issueApi, requestData);      
      setInProgress && setInProgress(false);
      const { isError, isWarn } = result || {};
      !(isError || isWarn) && onClose && onClose();

      return result;
    },
    afterAction,
  };

  return (
    <UserAccountItemsPicker
      {...signatureProps}
      {...config.pickerConfig}
      userId={userId}
      pickerVisible={pickerVisible}
      onCancel={onCancel}
    />
  );
};

export default Issuer;
