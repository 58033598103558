import { useEffect, useState } from 'react'
import Card, { CardState } from 'app/components/ui/card/cardMaterial'
import { cardLines } from 'app/components/skzi/Card/cardLines'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import service from 'app/services/service'

const EskziCard = ({
  profile,
  eskzi,
  className
}) => {

  const { id } = eskzi || {}
  const [ data, setData ] = useState()
  const getEskziCardData = async () => {
    const response = await service('skziService', 'get', id)
    const { data: respData } = response;
    const { skzi: skziVersionId } = respData;
    setData({...respData, skziVersionId})
  }
  
  useEffect( () => {
    getEskziCardData()
  }, [])

  return (
    <section className='main-card eskzi-card'>
      <div className="main-card__data">
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты'}
          initialState={true}
        >
          {eskzi && (
            <Card
              commonDataForm={{...data}}
              className={`${className || ''}`}
              actions={[]}
              profile={profile}
              showButtons={false}
              formLines={cardLines}
              formData={data}
              cardMode={CardState.view}
            />
            )}
        </ExpandingBlock>
      </div>
    </section>
  )
}

export default EskziCard;