import React, { useCallback, useEffect, useRef, useState } from 'react'
import {ReactComponent as BellImg} from 'assets/img/commonVer2/ic_notify.svg'
import { useDispatch, useSelector } from 'react-redux'
import DropDownPanel from '../ui/DropDownPanel/DropDownPanel'
import Button, { buttonTypes } from '../ui/button/button'
import { useHistory } from 'react-router-dom'
import NotificationList from './allNotificationPage/notificationList'
import { NotificationTypeMapping, statuses } from './allNotificationPage/notificationItem'
import { arrayToIdHashMap, updateCollectionByItemId } from 'app/core/utility/common'
import { setUpdatedEvent, setNeedRefreshByRest, setLastRefreshedValues } from "redux/actions/notification";
import ReplicationResult from './allNotificationPage/notificationDetail/bulkOperationResult'
import service from 'app/services/service'
import Overlay, {OVERLAYSIZE} from 'app/components/ui/overlay'
import useToast from '../ui/toast/useToast'
import globalConfig from 'app/core/config';

export default function Notification({
  ///TODO
  // запуск опроса уведомлений сервера 
  // когда не работает SignalR
  // надо инкапсулировать всю логику в одном месте, например 
  // тут - src\app\hooks\useWebSockets.js 
  noSignalR

}) {
  const history = useHistory();
  const connection = useSelector(({notification}) => notification ? notification.wsConnection : null)
  const needRefreshByRest = useSelector(({notification}) => notification ? notification.needRefreshByRest : null)
  const [wsData, setWsDataState] = useState({})
  const [panelOpened, setPanelOpen] = useState(false)
  const [notificationList, setNotificationList ] = useState([])
  const [selectedItem, setSelectedItem ] = useState('')
  const [hasNewNotifications, setHasNewNotifications] = useState(false)
  const [initLoaded, setInitLoaded] = useState(false)
  const {addToast} = useToast()
  const updatedEvent = useSelector(({notification}) => notification.updatedEvent)
  const dispatch = useDispatch()
  const isPanelOpened = useRef(false)

  const onListToggle = () => {
    !panelOpened && setHasNewNotifications(false)
    setPanelOpen(!panelOpened)
  }

  const onShowAll = () => {
    setPanelOpen(false);
    history.push('/notification');
  };

  const updateNotifications = (eventData) => {
    const { subject } = eventData || {}
    const { uid } = subject || {}

    setNotificationList(prevState => {
      const current = arrayToIdHashMap(prevState, 'uid')
      const newItem = {...subject, id : uid}

      if (current[uid]) {
        setNotificationList(updateCollectionByItemId(prevState, newItem))
      } else {
        !panelOpened && setHasNewNotifications(true)
        setNotificationList([
          newItem,
          ...prevState
        ].slice(0, 5))
      }
    })
  }

  const fetchData = async () => {
    const response = await service('notificationService', 'getFirstFive')
    const { data = [], dataCount = 0, errors, isError } = response
    const withCorrectIdEvents = data.map(item => ({...item, id: item.uid}))
    const topFiveEvents = withCorrectIdEvents.slice(0, 5)

    if (isError) {
      const [ error ] = errors || []
      const { errorMessage, globalError } = error || {}
      const errorText = `Ошибка получения уведомлений. ${errorMessage || ''}`

      !globalError && addToast(errorText)
      return
    }
    
    if (noSignalR) {
      const hasInProgressEvents = withCorrectIdEvents.some(i => i.status.id === statuses.inProgress || i.status.id === statuses.started)
      dispatch(setNeedRefreshByRest(hasInProgressEvents))
      dispatch(setLastRefreshedValues(withCorrectIdEvents))
      
      if (initLoaded && !isPanelOpened.current) {
        const firstItem = withCorrectIdEvents[0] || {}
        const hasNewItem = notificationList.find(i => i.id === firstItem.id)
        
        !hasNewNotifications && setHasNewNotifications(!hasNewItem)
      }
    }
    
    !isError && setNotificationList(topFiveEvents)
    !initLoaded && setInitLoaded(true)

    return withCorrectIdEvents
  }

  useEffect(() => {
    const local = localStorage.getItem('hasNewNotifications')
    setHasNewNotifications(local === 'true')
  }, [])

  useEffect(() => {
    if (noSignalR) {
      return
    }

    if (connection) {
      connection.on('Notification', (event) => {
        setWsDataState(event)
      })
    }
  }, [connection])

  useEffect(() => {
    if (updatedEvent) {
      const updated = updateCollectionByItemId(notificationList,updatedEvent)
      setNotificationList(updated)
      dispatch(setUpdatedEvent(null))
    }
  }, [updatedEvent]);

  useEffect(() => {
    const data = typeof wsData === 'string' ? JSON.parse(wsData) : wsData
    const { subject } = data || {}
    const { code } = subject || {}
    
    if (NotificationTypeMapping[code]) {
      updateNotifications(data)
    }
  }, [wsData])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!needRefreshByRest
        || !noSignalR) {
      return
    }

    dispatch(setNeedRefreshByRest(false))

    setTimeout(() => {
      fetchData()
    }, globalConfig.noSignalrRefreshTimeout)

  }, [needRefreshByRest])

  useEffect(() => {
    localStorage.setItem('hasNewNotifications', hasNewNotifications)
  }, [hasNewNotifications])

  // из-за того, что мы опрашиваем
  // сервер в fetchData, который запускается в setTimeout,
  // значение panelOpened замыкается. Для получения актуального 
  // значения в fetchData сохраняем текущее состояние открытия 
  // панели в ref isPanelOpened
  useEffect(() => {
    isPanelOpened.current = panelOpened
  }, [panelOpened])

  return (
    <>
      <div className='circle-progressbar-container'>
        <div className={`notification ${hasNewNotifications ? 'notification--has-new' : ''}`}>
          <Button
            type={buttonTypes.image}
            onClick={onListToggle}
          >
            <BellImg className='notification__bell-image button-image'/>
          </Button>
        </div>
        {
          panelOpened
          && <DropDownPanel 
              initState={panelOpened}
              onClosePanelHandler={onListToggle}
            >
              {!initLoaded && (
                <div className="notification__progress">
                  <Overlay size={OVERLAYSIZE.small}/>
                </div>
              )}
              {initLoaded && <NotificationList
                list={notificationList}
                onClickElementHandler={setSelectedItem}
              />}
              <div className={'all-notification-button upload-list__button-wrap'}>
                <Button
                  type={'text'}
                  onClick={onShowAll}>
                  Все события
                </Button>
              </div>
          </DropDownPanel>
        }
      </div>
      {selectedItem && <ReplicationResult
                          data={selectedItem}
                          onCancel={() => setSelectedItem(null)}
                        />}
    </>
  )
}