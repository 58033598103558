import { 
  SKZIREGISTRYGETALL,
  SKZIREGISTRYGETSKZIDATA,
  // to do
  // remove all skzicard related methods
  SKZICARDBUILDEDIT,
  SKZICARDBUILDONFILESUPDATED,
  SKZICARDBUILDDELETE,
  SKZICARDVERSIONDELETE,
  SKZICARDUPDATEVERSIONDATA,
  SKZICARDVERSIONBUILDADD,
  SKZICARDVERSIONADD,
  //-------------
  SKZIREGISTRYONSELECT,
  SKZIREGISTRYADDBUILDOBJECTTYPE,
  SKZIREGISTRYDELETEBUILDOBJECTTYPE,
  SKZIREGISTRYSETMAINBUILDOBJECTTYPE,
  SKZIREGISTRYDELETE,
  SKZIREGISTRYINPROGRESS, 
  SKZIREGISTRYRESETACTION, 
  SKZIREGISTRYCLEARSTOREACTION,
  SKZIREGISTRYONDELETE, 
  SKZIREGISTRYPAGINATIONGET, 
  ERROR ,
  ERRORPAGEERROR
} from '../../actions/actionTypes'

import { getPageData } from 'app/components/ui/pagination/pagination'
import config from 'app/core/config'
import { dialogType } from 'app/components/dialog/confirmDialog/'
import { arrayToIdHashMap, updateCollectionByItemId, excludeByItemId, deleteArrayItemById } from 'app/core/utility/common'

const updateBuildDoc = (payloadBuild, currentBuild, objectTypes, action) => {
  const { documentation : payloadDocumentation = [] } = payloadBuild
  const { documentation : stateDocumentation = [] } = currentBuild || {}

  const payloadDocTypes = payloadDocumentation.reduce((acc,cur) => {
    acc[cur.objectType.id] = cur
    return acc
  }, {})

  switch (action) {
    // need to show added object type first in the object types list
    case SKZIREGISTRYADDBUILDOBJECTTYPE:
      const newDoctypes = excludeByItemId(
        payloadDocumentation.map(item => ({...item, id: item.objectType.id})),
        stateDocumentation.map(item => ({...item, id: item.objectType.id}))
      ) 
      
      return {
        ...payloadBuild,
        documentation: [
          ...newDoctypes,
          ...stateDocumentation.map(item => payloadDocTypes[item.objectType.id])
        ]
      }
       
    // need to maintain current types order
    case SKZIREGISTRYDELETEBUILDOBJECTTYPE:
      const clearedStateDocumentation = deleteArrayItemById(
        stateDocumentation.map(item => ({...item, id: item.objectType.id})),
        objectTypes[0]
      )
      
      return {
        ...payloadBuild,
        documentation: clearedStateDocumentation.map(item => payloadDocTypes[item.objectType.id])
      }
      
    // need to maintain current types order
    case SKZIREGISTRYSETMAINBUILDOBJECTTYPE:
      return {
        ...payloadBuild,
        documentation: stateDocumentation.map(item => payloadDocTypes[item.objectType.id])
      }
  }
}

const getUpdatedSkzi = (payload, stateSkzi, action) => {
  const { versions } = stateSkzi
  let { build: editedBuild, version: editedVersion, objectTypes } = payload
  const { id : editedBuildId } = editedBuild
  const { id : editedVersionId } = editedVersion
  const editedVersionIndex = versions.findIndex(item => item.id === editedVersionId)

  if (editedVersionIndex < 0){
    return stateSkzi
  }

  const { builds: editBuilds } = versions[editedVersionIndex]
  const currentBuild = editBuilds.find(item => item.id === editedBuildId)

  switch(action) {
    case SKZIREGISTRYADDBUILDOBJECTTYPE:
    case SKZIREGISTRYDELETEBUILDOBJECTTYPE:
    case SKZIREGISTRYSETMAINBUILDOBJECTTYPE:
      editedBuild = updateBuildDoc(editedBuild, currentBuild, objectTypes, action)
      break
  }

  const newBuilds = currentBuild
                    ? updateCollectionByItemId(editBuilds, editedBuild)
                    : [ editedBuild, ...editBuilds ]

  const newVersion = { ...versions[editedVersionIndex], builds: newBuilds }
  const newVersions = [
    ...versions.slice(0, editedVersionIndex),
    {...newVersion},
    ...versions.slice(editedVersionIndex + 1),
  ]

  return {
    ...stateSkzi,
    versions: newVersions
  }
}

const { paginationPerPage } = config

const defaultState = {
  items: [],
  selected: {
    items: [],
    selectedHashMap: {}
  },
  pageItems: [],
  pagination: { activePage: 1, itemsPerPage: paginationPerPage, dataCount: 0 },
  confirmObject: null,
  loadedSkzi: {},
  errorObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { 
      items,
      pageItems,
      selected,
      pagination, 
      loadedSkzi
  } = state
  const { activePage: stateActivePage, itemsPerPage: statePerPage } = pagination
  const { skzi, data, dataCount } = payload || {}
  const { id: skziId } = skzi || {}

  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case SKZIREGISTRYGETALL:
      const storeItems = data.map((item) => {

        return {
          ...item,
          type: item.type.name,
        }
      })

      return {
        ...state,
        items: storeItems,
        pagination: {activePage: 1, itemsPerPage: statePerPage, dataCount: dataCount},
        inProgress: false
      }

    case SKZIREGISTRYGETSKZIDATA:
      const { id } = payload
      
      return {
        ...state,
        loadedSkzi: { ...loadedSkzi, [id]:payload }
      }

    case SKZIREGISTRYPAGINATIONGET:
      const { activePage, itemsPerPage } = payload

      return {
        ...state,
        pagination: { activePage, itemsPerPage },
        pageItems: getPageData({ items, activePage, itemsPerPage }),
        inProgress: false
      }

    case SKZIREGISTRYONDELETE:
      const { confirmObject } = payload
      return {
        ...state,
        confirmObject
      }
 
    case SKZIREGISTRYONSELECT: 
      return {
        ...state,
        selected: payload || defaultState.selected
      }

    case SKZIREGISTRYDELETE:
      let confirmDialog = null
      const { items: selectedItems } = selected
      const afterDeleteItems = excludeByItemId(items, payload.map(item => ({id: item})))
      const afterDeleteSelected = excludeByItemId(selectedItems, payload.map(item => ({id: item}))) 

      if (errors && errors.length) {
        confirmDialog = {
          header: 'Удаление невозможно',
          type: dialogType.warning,
          messages: errors.map(item => item.errorMessage)
        }
      }
     
      return {
        ...state,
        selected: {
          items: afterDeleteSelected,
          selectedHashMap: arrayToIdHashMap(afterDeleteSelected)
        },
        items: afterDeleteItems,
        pageItems: getPageData({
            items: afterDeleteItems,
            activePage: stateActivePage,
            itemsPerPage : statePerPage
        }),
        inProgress: false,
        confirmObject: confirmDialog,
        errors
      }

    case ERROR:
      return {
        ...state,
        confirmObject: null,
        inProgress: false
      }

    case SKZICARDVERSIONBUILDADD: 
      return {
        ...state,
        loadedSkzi: {
          ...loadedSkzi,
          [skziId]: getUpdatedSkzi(payload, loadedSkzi[skziId], action.type)
        }
      }

    case SKZICARDVERSIONADD:
      const { version: newVersion } = payload

      if (loadedSkzi[skziId]) {
        const { versions: versionsToAdd } = loadedSkzi[skziId]
        
        return {
          ...state,
          loadedSkzi: {
            ...loadedSkzi,
            [skziId]: {
              ...loadedSkzi[skziId],
              versions: [
                newVersion,
                ...versionsToAdd
              ]
            }
          }
        }
      } else {
        return {
          ...state
        }
      }

    case SKZICARDUPDATEVERSIONDATA:
      const { version: updatedVersion } = payload
      const { versions: versionsToUpdate } = loadedSkzi[skziId]
      
      return {
        ...state,
        loadedSkzi: {
          ...loadedSkzi,
          [skziId]: {
            ...loadedSkzi[skziId],
            versions: updateCollectionByItemId(versionsToUpdate, updatedVersion)
          }
        }
      }
      

    case SKZIREGISTRYINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case SKZIREGISTRYRESETACTION: 
      return { 
        ...state, 
        confirmObject: null,
        error: null
      }

    case SKZIREGISTRYCLEARSTOREACTION:
      return {
        ...defaultState
      }

    case SKZICARDBUILDDELETE:
      const { skzi, build: deletedBuild, version: delVersion } = payload
      const { versions } = skzi
      const { id : deletedBuildId } = deletedBuild
      const { id : delVersionId } = delVersion
      const delVersionIndex = versions.findIndex(item => item.id === delVersionId)

      if (delVersionIndex < 0){
        return state
      }

      const { builds: delBuilds } = versions[delVersionIndex]
      const updVersion = { ...versions[delVersionIndex], builds: delBuilds.filter(item => item.id !== deletedBuildId) }
      
      const updVersions = [
        ...versions.slice(0, delVersionIndex),
        {...updVersion},
        ...versions.slice(delVersionIndex + 1),
      ]

      return {
        ...state,
        loadedSkzi: { 
          ...loadedSkzi,
          [skziId]:{
            ...loadedSkzi[skziId],
            versions: updVersions
          }
        }
      }

    case SKZIREGISTRYSETMAINBUILDOBJECTTYPE:
    case SKZIREGISTRYADDBUILDOBJECTTYPE:
    case SKZIREGISTRYDELETEBUILDOBJECTTYPE:
      const stateSkzi = loadedSkzi[skziId]

      return {
        ...state,
        loadedSkzi: { 
          ...loadedSkzi,
          [skziId]: getUpdatedSkzi(payload, stateSkzi, action.type)
        },
      }

    default: 
      return state
  }
}