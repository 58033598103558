/** @format */

import { useSelector, useDispatch } from 'react-redux';

import {
  skziInstanceInProgressAction,
  skziInstanceResetAction,
  skziGetSkziBuildDataAction,
} from 'redux/actions/skziInstance/skziInstanceAction';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';

export default function useComplectation({ items = [], isExternalUser = false }) {
  const disabled = items.length !== 1 || (isExternalUser && items?.[0]?.skzi?.documentationDisabled);

  const { skziInstance, profile } = useSelector(state => state);
  const dispatch = useDispatch();

  const startProgress = () => dispatch(skziInstanceInProgressAction(true));
  const finishProgress = () => dispatch(skziInstanceInProgressAction(false));

  const invoke = () => {
    if (items.length === 0) return;
    const item = items[0];
    dispatch(skziInstanceInProgressAction(true));
    dispatch(skziGetSkziBuildDataAction(item));
  };

  const close = () => {
    dispatch(skziInstanceResetAction());
  };

  const { inProgress, fileData } = skziInstance || {};
  const fileRights = getResourceRights(profile, RESOURCES.files);

  return {
    complectationTriggerLogic: {
      disabled,
      onAct: invoke,
    },
    complectationLogic: {
      inProgress,
      fileRights,
      fileData,
      onCancel: close,
      onFileActionStart: startProgress,
      onFileActionFinish: finishProgress,
    },
  };
}
