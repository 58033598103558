import {useEffect} from "react";
import service from '../../../services/service'
import { capitalize } from 'app/core/utility/common'

export default function useRoleForm({formData, serverFormViewModel, onAdd, errorByFields}){

  function getFormDataValue(fieldName){
    const capitalizeKey = capitalize(fieldName)
    const lineViewModel = serverFormViewModel ? serverFormViewModel[capitalizeKey] : null
    return formData[lineViewModel ? capitalizeKey : fieldName]
  }

  function getFieldsetError(fieldsetName, fieldName, index){
    return errorByFields[`${capitalize(fieldsetName)}[${index}].${capitalize(fieldName)}`]
  }

  const filterGroupCallback = (e, pageSize) => {
    const { data }  = e || {}
    const { length } = data || {}
    const dataCount = Math.min(pageSize, length)
    return {
      dataCount: dataCount,
      ...e
    }
  }
  
  useEffect(() => {
    if(Array.isArray(getFormDataValue('roles')) && getFormDataValue('roles').length === 0){
      onAdd('roles', {groupPath: '', userRole: ''})
    }
  }, [formData])

  const autocompleteGroupsService =  {
    serviceName: 'activeDirectorySettingsService',
    serviceMethod: 'getGroupsAutocompleteReduced',
    data: {reducerCallback: filterGroupCallback}
  }
  
  const getFilterRolesCallback = curRole => rawResult => {
    if (!rawResult.data) {
      return rawResult;
    }

    const selectedRoles = getFormDataValue('roles');
    const notAssignedToSameGroup = rawRole =>
      selectedRoles.every(
        sr =>
          sr.groupPath !== curRole.groupPath || sr?.userRole?.id !== rawRole.id
      );
    return {
      ...rawResult,
      data: rawResult.data.filter(notAssignedToSameGroup),
    };
  };

  const getAutocompleteRolesService = role => ({
    serviceName: 'userRolesService',
    serviceMethod: 'autocompleteReduced',
    data: { reducerCallback: getFilterRolesCallback(role) },
  });

  return {
    autocompleteGroupsService,
    getAutocompleteRolesService,
    getFieldsetError,
    getFormDataValue,
  }
}