import { 
  USERACCOUNTGETALL,
  USERACCOUNTDELETE,
  USERACCOUNTINPROGRESS, 
  USERACCOUNTRESETACTION, 
  USERACCOUNTONDELETE, 
  USERACCOUNTPAGINATIONGET, 
  USERACCOUNTONREPORTDIALOG,
  ERROR,
  ERRORPAGEERROR,
  USERACCOUNTONCLOSEDIALOG,
  USERACCOUNTONCLOSE,
  USERACCOUNTONAPPROVE,
  USERACCOUNTONREVERSEAPPROVE
} from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { getPageData } from 'app/components/ui/pagination/pagination'
import { formatFIO, stringPlural } from 'app/core/utility/common'
import { updateCollectionByItemId } from 'app/core/utility/common'

import config from 'app/core/config'
const { paginationPerPage } = config

const defaultState = {
  items: [],
  pageItems: [],
  pagination: { activePage: 1, itemsPerPage: paginationPerPage },
  onDeleteObject: null,
  reportDialog: null,
  confirmObject: null,
  userCloseObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { 
      items,
      pageItems,
      pagination      
  } = state
  const { activePage: stateActivePage, itemsPerPage: statePerPage } = pagination

  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case USERACCOUNTGETALL:
      const storeItems = payload.map((item, index) => ({ ...item, index: index + 1, }))

      return {
        ...state,
        items: storeItems,
        pagination: {activePage: 1, itemsPerPage: statePerPage},
        pageItems: getPageData({ items: storeItems, activePage: 1, itemsPerPage: statePerPage }),
        inProgress: false
      }

    case USERACCOUNTONREPORTDIALOG:
      return {
        ...state,
        reportDialog: payload
      }

    case USERACCOUNTONCLOSEDIALOG:
      return {
        ...state,
        userCloseObject: payload
      }

    case USERACCOUNTPAGINATIONGET:
      const { activePage, itemsPerPage } = payload

      return {
        ...state,
        pagination: { activePage, itemsPerPage },
        pageItems: getPageData({ items, activePage, itemsPerPage }),
        inProgress: false
      }

    case USERACCOUNTONDELETE:
      const name = payload.length ? payload.map(ua => formatFIO(ua)).join(', ') : formatFIO(payload)
      
      return {
        ...state,
        onDeleteObject: {
          item: payload,
          type: dialogType.confirm,
          title: `Вы уверены, что хотите удалить ${stringPlural(payload.length || 1, ['лицевой счет', 'лицевые счета', 'лицевые счета'] )} «${name}»?`
        }
      }

    case USERACCOUNTONCLOSE:
    case USERACCOUNTONAPPROVE:
    case USERACCOUNTONREVERSEAPPROVE:
      const updatedItems = updateCollectionByItemId(items, payload)

      return {
        ...defaultState,
        pagination: pagination,
        items: updatedItems,
        pageItems: getPageData({
            items: updatedItems,
            activePage: stateActivePage,
            itemsPerPage : statePerPage
        }),
      }
 
    case USERACCOUNTDELETE:
      const toObject = (acc, curr) => { acc[curr.id] = curr; return acc}
      const currentItemsObject = items.reduce(toObject, {})
      const deletedObject = payload.reduce(toObject, {})
      const afterDeleteItems = items.reduce((acc, curr) => {
        return deletedObject[curr.id] 
                ? [...acc]
                : [...acc, currentItemsObject[curr.id]]
      }, [])

      return {
        ...state,
        items: afterDeleteItems,
        pageItems: getPageData({
            items: afterDeleteItems,
            activePage: stateActivePage,
            itemsPerPage : statePerPage
        }),
        onDeleteObject: null,
        inProgress: false,
      }

    case ERROR:
      return {
        ...defaultState,
        items,
        pageItems,
        pagination  
      }

    case USERACCOUNTINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case USERACCOUNTRESETACTION: 
      return {
        ...defaultState,
        items,
        pageItems,
        pagination  
      }

    default: 
      return state
  }
}