import React from 'react'

import Button from 'app/components/ui/button/button'

const PageItem = props => {
  const { className, isActive, onClick, value, disabled } = props

  return (
    <Button
      className={`pagination__item ${className || ''}`}
      type={isActive ? 'secondary' : 'text'}
      onClick={() => onClick(value)}
      disabled={disabled}
    >
      {value}
    </Button>
  )
}

export default PageItem