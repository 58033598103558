import React from 'react'
import editImg from 'assets/img/common/editPen.svg'
import deleteImg from 'assets/img/common/binGray.svg'
import userAccountReportImg from 'assets/img/common/userAccountReport.svg'
import fileImg from 'assets/img/common/file.svg'
import replicateImg from 'assets/img/common/replicate.svg'
import revertConfirmAccountImg from 'assets/img/common/revertConfirmAccount.svg'
import confirmAccountImg from 'assets/img/common/confirmAccount.svg'
import closeAccountImg from 'assets/img/common/closeAccount.svg'
import downloadDocImg from 'assets/img/common/downloadDoc.svg'
import techHardwareJournalImg from 'assets/img/common/techHardwareJournal.svg'
import addVersionImg from 'assets/img/common/addVersion.svg'
import addBuildImg from 'assets/img/common/addBuild.svg'
import { ReactComponent as ConnectionImg } from 'assets/img/commonVer2/ic_connection.svg'
import { ReactComponent as EtalonSqReplicationImg }  from 'assets/img/actionPanel/ic_etalon_sq_replication.svg';
import { ReactComponent as DownloadToXlsImg } from 'assets/img/commonVer2/excel.svg'
import { ReactComponent as SignImg } from 'assets/img/actionPanel/ic_signature.svg'
import { ReactComponent as CopyImg } from 'assets/img/actionPanel/ic_copy_doc.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as CheckImg } from 'assets/img/commonVer2/ic_check.svg'
import { ReactComponent as TriangleAlertImg } from 'assets/img/commonVer2/ic_alert_triangle.svg'

import { ReactComponent as ResetPasswordComponentImage } from 'assets/img/commonVer2/ic_reset_password.svg'
import { ReactComponent as DeleteComponentImage } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as ConfirmAccountComponentImage } from 'assets/img/commonVer2/ic_unblock_account.svg'
import { ReactComponent as CloseAccountComponentImage } from 'assets/img/commonVer2/ic_block_account.svg'

import Button from 'app/components/ui/button/button'
import Spinner from './spinner'


export const itemActions = {
  edit: {
    key: 'edit',
    name: 'Редактировать',
    image: editImg
  },
  view: {
    key: 'view',
    name: 'Просмотр',
    image: editImg
  },
  delete: {
    key: 'delete',
    name: 'Удалить',
    tooltip: 'Удалить',
    image: deleteImg,
    ImageComponent: DeleteImg
  },
  file: {
    key: 'file',
    name: 'Управлять файлами',
    image: fileImg
  },
  report: {
    key: 'report',
    name: 'Сформировать лицевой счет пользователя СКЗИ',
    image: userAccountReportImg
  },
  close: {
    key: 'close',
    name: 'Закрыть',
    image: closeAccountImg
  },
  replicate: {
    key: 'replicate',
    name: 'Распространить',
    tooltip: 'Распространить',
    image: replicateImg,
    ImageComponent: EtalonSqReplicationImg
  },
  documentation: {
    key: 'documentation',
    name: 'Комплект поставки',
    image: downloadDocImg
  },
  accountConfirm: {
    key: 'accountConfirm',
    name: 'Подтвердить',
    image: confirmAccountImg
  },
  accountCancelConfirm: {
    key: 'accountCancelConfirm',
    name: 'Отменить',
    image: revertConfirmAccountImg
  },
  techHardwareJournal: {
    key: 'techHardwareJournal',
    name: 'Технический (аппаратный) журнал',
    image: techHardwareJournalImg
  },
  addVersion: {
    key: 'addVersion',
    name: 'Добавить версию',
    image: addVersionImg
  },
  addBuild: {
    key: 'addBuild',
    name: 'Добавить сборку',
    image: addBuildImg
  },
  resetPassword: {
    key: 'resetPassword',
    name: 'Сбросить пароль',
    image: addBuildImg
  },
  blockUser: {
    key: 'blockUser',
    name: 'Заблокировать',
    image: closeAccountImg
  },
  unblockUser: {
    key: 'unblockUser',
    name: 'Разблокировать',
    image: confirmAccountImg
  },
  xlsDownload: {
    key: 'xlsDownload',
    tooltip: 'Выгрузить в Excel',
    ImageComponent: DownloadToXlsImg
  },
  cardDeleteUserDisabled: {
    key: 'cardDeleteUserDisabled',
    name: 'Удалить',
    ImageComponent: DeleteComponentImage,
    tooltip: 'Удалить',
    disabled: true
  },
  cardResetPasswordDisabled: {
    key: 'cardResetPasswordDisabled',
    name: 'Сбросить пароль',
    ImageComponent: ResetPasswordComponentImage,
    tooltip: 'Сбросить пароль',
    disabled: true
  },
  cardBlockUserDisabled: {
    key: 'cardBlockUserDisabled',
    name: 'Заблокировать',
    ImageComponent: CloseAccountComponentImage,
    tooltip: 'Заблокировать',
    disabled: true
  },
  cardUnblockUserDisabled: {
    key: 'cardUnblockUserDisabled',
    name: 'Разблокировать',
    ImageComponent: ConfirmAccountComponentImage,
    tooltip: 'Разблокировать',
    disabled: true
  },
  cardDeleteUser: {
    key: 'cardDeleteUser',
    name: 'Удалить',
    ImageComponent: DeleteComponentImage,
    tooltip: 'Удалить'
  },
  cardResetPassword: {
    key: 'cardResetPassword',
    name: 'Сбросить пароль',
    ImageComponent: ResetPasswordComponentImage,
    tooltip: 'Сбросить пароль'
  },
  cardBlockUser: {
    key: 'cardBlockUser',
    name: 'Заблокировать',
    ImageComponent: CloseAccountComponentImage,
    tooltip: 'Заблокировать'
  },
  cardUnblockUser: {
    key: 'cardUnblockUser',
    name: 'Разблокировать',
    ImageComponent: ConfirmAccountComponentImage,
    tooltip: 'Разблокировать'
  },
  sign: {
    key: 'sign',
    CustomComponent: (props) => (
      <Button 
        type='secondary'
        {...props}
      >
        <SignImg className='button-image button-image--left'></SignImg>
        <span className='button-title'>Подписать</span>
      </Button>
    )
  },
  copy: {
    key: 'copy',
    tooltip: 'Скопировать',
    ImageComponent: CopyImg
  },
  testConnection: {
    key: 'testConnection',
    tooltip: 'Проверить соединение',
    ImageComponent: ConnectionImg
  },
  disabledTestConnection: {
    key: 'testConnection',
    tooltip: 'Проверить соединение',
    disabled: true,
    ImageComponent: ConnectionImg
  },
  connectionStatus: {
    key: 'connectionStatus',
    CustomComponent: (props) => {
      const { inProgress, text, status } = props
      return text && (
        <div className="test-connection-container">
          {inProgress && <Spinner />}
          {!inProgress && (
            status ? <CheckImg className="test-connection-container-icon__success"/>
              : <TriangleAlertImg className="test-connection-container-icon__error"/>
          )}
          <span className='test-connection-container--message'>{text}</span>
        </div>
      )
    }
  }
}

export const sortDirection = {
  asc: 1,
  desc: -1
}

export const keyCodes = {
  EnterKeyCode: 13,
  Esc: 'Escape',
  KeyDown: 40,
  KeyUp: 38,
  KeyLeft: 37,
  KeyRight: 39
}

export const ORG_CARD_TYPE = 'organization'
export const OKI_CARD_TYPE = 'okzScheme'
export const USER_ACCOUNT_CARD_TYPE = 'userAccount'
export const USER_CARD_TYPE = 'user'
export const SKZI_REGISRTY_CARD = 'skzi'
export const E_SKZI_REGISRTY_CARD = 'eskzi'
export const KEY_DOCUMENT_CARD = 'keyDocument'
export const MAINTENANCE_CARD = 'maintenance'

export const linkTargets = {
  [ORG_CARD_TYPE]: '/catalog/organization/card/',
  [OKI_CARD_TYPE]: '/catalog/okzScheme/card/',
  [USER_ACCOUNT_CARD_TYPE]: '/userAccounts/card/',
  [USER_CARD_TYPE]: '/accessManagement/users/card/',
  [SKZI_REGISRTY_CARD]: '/skziRegistry/accounting/skziCard/',
  [E_SKZI_REGISRTY_CARD]:'/skziOrganization/EskziCard/',
  [KEY_DOCUMENT_CARD]: '/keyDocument/oki/card/',
  [KEY_DOCUMENT_CARD]: '/keyDocument/oki/card/',
  [MAINTENANCE_CARD]: '/acts/maintenance/card/'
}

export const OKZ_VOLUME_UID = 'OkzEskziFilter'
export const OKI_VOLUME_UID = 'OkiEskziFilter'