import React, { Component } from 'react'

import FileUploadControl from 'app/components/ui/file/fileUploadControl'
import { fileListAPI } from 'app/core/utility/file'
import DropDown, { YesNo } from 'app/components/ui/DropDown'
import Button from 'app/components/ui/button/button'
import Input from 'app/components/ui/Input'
import { TRAININGCOURSECARDTYPEADD } from 'redux/actions/actionTypes'


export default class addTypeDialog extends Component {
  constructor(props){
    super(props)

    const { currentType, courseParams } = props
    const { contentId } = courseParams

    this.state = { 
      userAvailable: true,
      typeName: currentType ? currentType.name : '',
      allFilesProgressComplete: false,
      files: [],
      contentId: contentId
    }
    this.fileControl = React.createRef();
  }

  _onTypeAdd = async () => {
    const { onTypeAdd, onCancel, currentType } = this.props
    const { userAvailable, typeName , files } = this.state
    const { current } = this.fileControl

    if (currentType) {
      current._upload()
      onCancel()

      return
    }

    const result = await onTypeAdd({userAvailable, name: typeName, hasFiles: files.length})
    const { type, payload } = result
    const { id: contentId} = payload 
    
    if (type === TRAININGCOURSECARDTYPEADD && current) {
      this.setState({contentId : contentId}, () => {
        current._upload()
        onCancel()
      })
    }
  }

  _onCancel = async () => {
    const { onCancel } = this.props

    onCancel()
  }

  _onFileChange = (files) => {
    this.setState({files: files})
  }

  _uploadFinished = (result) => {
    const { onFileUploaded } = this.props

    onFileUploaded()
  }

  _allFilesProgressComplete = () => {
    this.setState({ allFilesProgressComplete: true })
  }

  _renderTypeData = () => {
    const { 
      currentType,
      error
    } = this.props
    const { userAvailable, typeName = '' } = this.state
    const { name } = currentType || {}

    return (
      <>
        <Input 
          className='add-type-dialog-data'
          label = 'Тип'
          className='add-type-dialog-data'
          value={typeName}
          onChange={value => this.setState({typeName: value})}
          error={error}
          disabled={name}
        />
        {!currentType ? (
          <DropDown
            className='add-type-dialog-data'
            items={YesNo}
            active={userAvailable ? YesNo[0] : YesNo[1]}
            onSelect={value => {
              this.setState({userAvailable: value.id === YesNo[0].id})}}
            label='Доступен для пользователя СКЗИ'
          />
        ) : null}
      </>
    )
  }

  render() {
    const { 
      courseParams,
      currentType   
    } = this.props
    const { typeName, files, contentId: stateContentId } = this.state
    const { courseId, contentId: propsContentId } = courseParams
    const urlPart = `course/${courseId}/materials/${stateContentId || propsContentId}/file`
    const cancelButtonText = 'Отмена'
    const headerText = currentType ? 'Загрузка файлов' : 'Добавление типа'
    const fileApi = fileListAPI( null, urlPart )

    return (
      <div className="add-type-dialog">
        <div className="add-type-dialog__header">{headerText}</div>
        {this._renderTypeData()}
        <FileUploadControl
          ref={this.fileControl}
          className='add-type-dialog__file-area'
          title='Добавить вложение'
          fileApi={fileApi}
          onFileChanged={this._onFileChange}
          onUploadFinish={this._uploadFinished}
          onAllFilesProgressComplete={this._allFilesProgressComplete}
          disabled={!typeName}
        />
        <div className="add-type-dialog__buttons-panel">
          <Button 
            type='text'
            onClick={this._onTypeAdd}
            disabled={currentType ? !files.length : !typeName}
          >
            <span className='button-title'>Добавить</span>
          </Button>
          <Button 
            type='text'
            onClick={this._onCancel}
          >
            <span className='button-title'>{cancelButtonText}</span>
          </Button>
        </div>
      </div>
    )
  }
}
