import { CardState } from 'app/components/ui/card/cardMaterial'
import { InputType } from 'app/components/ui/Input'
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker'
import Button from 'app/components/ui/button/button'
import {ReactComponent as DeleteImage} from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as PlusImage }  from 'assets/img/commonVer2/plus.svg'
import {SelectType} from '../../ui/picker/picker'
import Autocomplete, {Services} from '../../ui/autocomplete/autocomplete'
import useOrganizationForm from "../hooks/useOrganizationForm";

export default function OrganizationForm(props){

  const {
    cardState,
    formData = {},
    serverFormViewModel,
    onChange,
    errorByFields = {},
    onAdd,
    onRemove,
  } = props

  const {
    autocompleteOrganizationService,
    autocompletePathService,
    pickerService,
    getFormDataValue,
    getFieldsetError
  } = useOrganizationForm({formData, serverFormViewModel, onAdd, errorByFields})

  const fields = Array.isArray(getFormDataValue('organizations')) ? getFormDataValue('organizations').map((el, index) => {

    return(
      <div className="card-organization" key={index}>
        <div className="card-field card-organization-path card-separator">
          <Autocomplete
            type={InputType.hybrid}
            onEmptySearch={false}
            renderInputValue={(val) =>  val ?  (val.name ? val.name : val) : ''}
            renderItem={(val) =>  val ?  (val.elementPath ? val.elementPath : val) : ''}
            placeholder="Начните ввод или выберите из справочника"
            label="Путь к организационной единице в AD"
            serviceType={Services.activeDirectorySettingsService}
            selectType={SelectType.single}
            notStandardService={autocompletePathService}
            absoluteInfo
            onSelect={value => onChange({
              key: 'organizations',
              field: 'organizationPath'
            }, value ? (value.elementPath ? value.elementPath : value) : value, index)}
            error={getFieldsetError('organizations','organizationPath', index)}
            value={el.organizationPath}
            readOnly={cardState === CardState.view}
            isRequired
          />
        </div>
        <div className="card-organization-orgId">
          <AutocompletePicker
            label="Наименование организации"
            serviceType={Services.organizations}
            selectType={SelectType.single}
            notStandardService={autocompleteOrganizationService}
            notStandardPickerService={pickerService}
            value={el.organization}
            readOnly={cardState === CardState.view}
            absoluteInfo
            onSelectValue={value => {
              onChange({
                key: 'organizations',
                field: 'organization'
                }, value, index)
            }}
            error={getFieldsetError('organizations','organization', index)}
            isRequired
          />
        </div>
        <Button
          className="card-management__item card-remove-organization-button"
          type="image"
          notSubmit={true}
          onClick={() => onRemove('organizations', index)}
          disabled={(cardState === CardState.view) || (index === 0 && getFormDataValue('organizations').length === 1 )}
        >
          <DeleteImage />
        </Button>
      </div>
    )
  }) : ''
  return (
    <div className="card-organization-list">
      {fields}
      <Button
        notSubmit={true}
        className="card-management__item card-add-organization-button"
        type="secondary"
        onClick={() => onAdd('organizations', {organizationPath: '', organization: ''})}
        disabled={cardState === CardState.view}
      >
        <PlusImage className="button-image" />
        <span className="button-title">Добавить</span>
      </Button>
    </div>
  )
}