import React from 'react'
import Button from '../ui/button/button'
import Img from '../ui/Img'
import styles from './filter.module.scss'
import Image from 'app/components/ui/Img/imgMap'
import VolumeSave from './VolumeSave'

export default function FilterManagePanel({
  onSearch,
  onFilterClear,
  isDisabledSave,
  filterState,
  globalOrgId,
  gridFilterUid,
  selectedVolume,
  setSelectedVolume,
  withVolume
}) {
  return (
    <div className={styles.fullFilterManagePanel}>
      <div className={styles.managePanelLeft}>
        <Button 
          type = 'secondary' 
          onClick = {onFilterClear}
          disabled={!filterState.length}
        >
          <Img img={Image.Broom} className='button-image'/>
          <span className='button-title'>Очистить фильтр</span>
        </Button>
      </div>
      <div className={styles.managePanelRight}>
        {withVolume ?
          <VolumeSave
            {
              ...{
                isDisabledSave,
                filterState,
                globalOrgId,
                gridFilterUid,
                selectedVolume,
                setSelectedVolume
              }
            }
          /> : null}
        <Button type = 'primary'  onClick = { onSearch }>
          <Img img={Image.Search} className='button-image'/>
          <span className='button-title'>Найти</span>
        </Button>
      </div>
    </div>
  )
}
