import React from "react";
import {ReactComponent as IconOk} from "assets/img/commonVer2/ic_check.svg";

export default function ProcessingProgress({progress, processingState}) {
    return <>
        {
            !processingState.isError
            && !processingState.isFinished
            && progress
        }
        {
            (processingState.isFinished
                && !processingState.isError)
                    ? <IconOk className={'upload-list__icon upload-list__icon--ok'}/>
                    : null
        }
    </>;
}
