/** @format */

import { useHistory } from 'react-router-dom';
import Group from 'app/components/ui/managePanel/group';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { EventCode, EndpointType, ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import SendButton from 'app/components/skzi/EskziList/businessEventPanel/sendButton';
import { allHasChangeUser } from 'app/components/skzi/EskziList/businessEventPanel/OkiPanel';
import { Image } from 'app/components/ui/Img';
import { Delete } from 'app/components/list/TopPanel/Delete';
import { api } from 'app/services/service';

const sendBusinessEvents = [
  { name: 'Передача в Орган криптографической защиты', eventCode: 'OkzReceiving' },
  { name: 'Передача Обладателю конфиденциальной информации', eventCode: 'OkiReceiving' },
];

export default function BusinessEventPanel(props) {
  const { items, onInProgress, afterAction } = props;

  const history = useHistory();

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await api(
      'keyCarrier/deleteRange',
      items.map(i => i.id)
    );
    onInProgress(false);
    return response;
  };

  const afterDeleteAction = () => {
    history.push('/keyDocument/keyCarrier');
  };

  return (
    <>
      <Group>
        <SendButton
          items={items}
          className="send-button"
          title="Передать"
          actionList={sendBusinessEvents}
          disabled={false}
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturn]}
          tooltip="Вернуть"
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiSendingOk, EventCode.okzSendingOk]}
          tooltip="Подтвердить получение"
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturnOk]}
          tooltip="Подтвердить возврат"
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiWithdrawal]}
          tooltip="Изъять"
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.SoftReturn}
        />
        {!allHasChangeUser(items) ? (
          <BusinessEventTrigger
            items={items}
            eventCode={[EventCode.okiDelivery]}
            tooltip="Выдать"
            endpointType={EndpointType.keyCarrier}
            entityType={ENTITY_TYPE.keyCarrier}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        ) : (
          <BusinessEventTrigger
            items={items}
            eventCode={[EventCode.changeUser]}
            tooltip="Выдать другому пользователю"
            endpointType={EndpointType.keyCarrier}
            entityType={ENTITY_TYPE.keyCarrier}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        )}
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzDestruction, EventCode.okiDestruction]}
          tooltip="Уничтожение"
          endpointType={EndpointType.keyCarrier}
          entityType={ENTITY_TYPE.keyCarrier}
          afterAction={afterAction}
          Img={Image.Extermination}
        />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction={afterDeleteAction}
          setSelectedAction={() => {}}
          dialog={items => {
            return `Вы уверены, что хотите удалить носитель «${items[0].serialNumber}»?`;
          }}
        />
      </Group>
    </>
  );
}