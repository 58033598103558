import { Image } from "app/components/ui/Img";

export const ServiceName = {
    ORGANIZATION: 'organizationImport',
    LICENSE: 'LicenseImport',
    USER_ACCOUNT: 'UserAccountImport',
    KEY_CARRIER: 'KeyCarrierImport',
    OKZ_KEY_DOCUMENT: 'OkzKeyDocumentImport',
    OKI_KEY_DOCUMENT: 'OkiKeyDocumentImport',
    OKZ_KEY_ESKZI: 'OkzEskziImport',
    OKI_KEY_ESKZI: 'OkiEskziImport',
};

export const Url = {
    ORGANIZATION: 'OrganizationImport',
    LICENSE: 'SkziLicenseImport',
    USER_ACCOUNT: 'EskziUserImport',
    KEY_CARRIER: 'KeyCarrierImport',
    OKZ_KEY_DOCUMENT: 'KeyDocumentImport/okz',
    OKI_KEY_DOCUMENT: 'KeyDocumentImport/oki',
    OKZ_KEY_ESKZI: 'EskziImport/okz',
    OKI_KEY_ESKZI: 'EskziImport/oki',
};

export const RequestType = {
    ORGANIZATION: 'OrganizationImport',
    LICENSE: 'SkziLicenseImport',
    USER_ACCOUNT: 'EskziUserImport',
    KEY_CARRIER: 'KeyCarrierImport',
    OKZ_KEY_DOCUMENT: 'OkzKeyDocumentImport',
    OKI_KEY_DOCUMENT: 'OkiKeyDocumentImport',
    OKZ_KEY_ESKZI: 'OkzEskziImport',
    OKI_KEY_ESKZI: 'OkiEskziImport',
};

export const Title = {
    LOAD_RESULT: {
        ORGANIZATION: 'Результат обработки файла организаций',
        LICENSE: 'Результат обработки файла лицензий',
        USER_ACCOUNT: 'Результат обработки файла лицевых счетов',
        KEY_CARRIER: 'Результат обработки файла ключевых носителей',
        OKZ_KEY_DOCUMENT: 'Результат обработки файла ключевых документов',
        OKI_KEY_DOCUMENT: 'Результат обработки файла ключевых документов',
        OKZ_KEY_ESKZI: 'Результат обработки файла экземпляров СКЗИ',
        OKI_KEY_ESKZI: 'Результат обработки файла экземпляров СКЗИ',
    },
    CREATION_RESULT: {
        ORGANIZATION: 'Результат создания организаций',
        LICENSE: 'Результат создания лицензий',
        USER_ACCOUNT: 'Результат создания лицевых счетов',
        KEY_CARRIER: 'Результат создания ключевых носителей',
        OKZ_KEY_DOCUMENT: 'Результат создания ключевых документов',
        OKI_KEY_DOCUMENT: 'Результат создания ключевых документов',
        OKZ_KEY_ESKZI: 'Результат создания экземпляров СКЗИ',
        OKI_KEY_ESKZI: 'Результат создания экземпляров СКЗИ',
    },
    UPLOAD_MODAL: {
        ORGANIZATION: 'Загрузка организаций из файла',
        LICENSE: 'Загрузка лицензий из файла',
        USER_ACCOUNT: 'Загрузка лицевых счетов из файла',
        KEY_CARRIER: 'Загрузка ключевых носителей из файла',
        OKZ_KEY_DOCUMENT: 'Загрузка ключевых документов (для ОКЗ)',
        OKI_KEY_DOCUMENT: 'Загрузка ключевых документов (для Организации)',
        OKZ_KEY_ESKZI: 'Загрузка экземпляров СКЗИ (для ОКЗ) из файла',
        OKI_KEY_ESKZI: 'Загрузка экземпляров СКЗИ (для Организации) из файла',
    },
    UPLOAD_DESCRIPTION: {
        ORGANIZATION: 'организаций',
        LICENSE: 'лицензий СКЗИ',
        USER_ACCOUNT: 'лицевых счетов',
        KEY_CARRIER: 'ключевых носителей',
        OKZ_KEY_DOCUMENT: 'ключевых документов ОКЗ',
        OKI_KEY_DOCUMENT: 'ключевых документов ОКИ',
        OKZ_KEY_ESKZI: 'экземпляров СКЗИ ОКЗ',
        OKI_KEY_ESKZI: 'экземпляров СКЗИ ОКИ',
    }
};

export const Type = {
    ALL: 'Все виды',
    ORGANIZATION: 'Организации',
    LICENSE: 'Лицензии СКЗИ',
    USER_ACCOUNT: 'Лицевые счета',
    KEY_CARRIER: 'Ключевые носители',
    OKZ_KEY_DOCUMENT: 'Ключевые документы ОКЗ',
    OKI_KEY_DOCUMENT: 'Ключевые документы ОКИ',
    OKZ_KEY_ESKZI: 'Экземпляры СКЗИ ОКЗ',
    OKI_KEY_ESKZI: 'Экземпляры СКЗИ ОКИ',
};

export const Status = {
    LOADING: 'Загрузка',
    LOADING_END: 'Загрузка завершена',
    CREATING: 'Создание',
    CREATING_END: 'Создание завершено',
    ERROR: 'Ошибка',
};

export const FileValidateState = {
    OVER_SIZE: 'OVER_SIZE',
    MISS_EXTENSION: 'MISS_EXTENSION',
};

export const FileErrorMessage = {
    MISS_EXTENSION: 'Файл не может быть загружен (неверный формат или не распознан)',
    OVER_SIZE: 'Максимальный размер файла не должен превышать 100 Мб',
};

export const ImportProgressType = {
    IMPORT_PROGRESS: 'ImportProgress'
};

export const FilterFlagName = {
    notRecognized: 1,
    processed: 2,
    imported: 8,
    duplicate: 4,
    errors: 16,
};

export const FilterStatus = {
    RECOGNIZED: 'RECOGNIZED',
    IMPORTED: 'IMPORTED',
    DUPLICATE: 'DUPLICATE',
    NOT_RECOGNIZED: 'NOT_RECOGNIZED',
    ERROR: 'ERROR',
};
export const ImportsPageStatus = {
    UPLOADING: 'UPLOADING',
    IN_PROGRESS: 'IN_PROGRESS',
    UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR',
};
export const GroupStatuses = {
    UPLOADING: 1,
    CREATING: 2,
    COMPLETE: 3,
    IN_PROGRESS: 4,
    ERROR: 5,
};
export const FileImportRowStatus = {
    NOT_RECOGNIZED: 1,
    RECOGNIZED: 2,
    DUPLICATE: 4,
    IMPORTED: 8,
    ERROR: 0x10,
};
export const LicenseType = {
    'Пользовательская': 0,
    'Серверная': 1,
};

export const Icon = {
    IMPORTED: {
        tooltip: 'Создано в системе',
        svg: Image.CheckCircle,
        classes: 'icon__state icon__state--check',
    },
    RECOGNIZED: {
        tooltip: 'Распознано',
        svg: Image.CheckCircle,
        classes: 'icon__state icon__state--check',
    },
    DUPLICATE: {
        tooltip: 'Дубль',
        svg: Image.Alert,
        classes: 'icon__state icon__state--alert',
    },
    NOT_RECOGNIZED: {
        tooltip: 'Не распознано',
        svg: Image.AlertTriangle,
        classes: 'icon__state icon__state--alert-triangle',
    },
    ERROR: {
        tooltip: 'Ошибка',
        svg: Image.AlertTriangle,
        classes: 'icon__state icon__state--alert-triangle',
    },
};
