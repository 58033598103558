/** @format */
import { ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import { Image } from 'app/components/ui/Img';

import getIssuerConfig from './issuerConfig';

const toolbarConfig = {
  issuerButtonText: 'Выдать носитель',
  getIssuerConfig,
  endpointType: 'KeyCarrier',
  entityType: ENTITY_TYPE.keyCarrier,
  customActions: [
    [
      {
        eventCode: 'OkiWithdrawal',
        tooltip: 'Изъять',
        buttonImage: Image.SoftReturn,
        changeType: 'deleted',
      },
    ],
    [
      {
        eventCode: 'OkiDestruction',
        tooltip: 'Уничтожить',
        buttonImage: Image.Extermination,
        changeType: 'deleted',
      },
    ],
  ],
};

export default toolbarConfig;
