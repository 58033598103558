import {useDispatch, useSelector} from "react-redux";
import {useRef} from "react";
import {useParams} from "react-router-dom";
import {decapitalize} from "../../../../core/utility/common";
import {notEmpty} from "../../../../core/utility/validation";
import {generateText, setRequestData} from "../../../../../redux/actions/common/signatureAction";
import {updateCardAction, updateViewDataAction} from "../../../../../redux/actions/card";
import {dispatchLogic} from "../../../../../redux/actions/common";
import {CARD_UPDATE} from "../../../../../redux/actions/actionTypes";
import {
  extOrgEkziCardEditEvent,
  skziCardEditTask,
  keyDocumentEventEdit
} from "../../../../../redux/actions/skziInstance/eskziEventsAction";
import useToast from "../../../ui/toast/useToast";
import {getEventDataForServerFunctional, isDigitalSignNeeded} from "../../../signature/SignatureDialog";

//преобразование модели с бэка в данные для создания формы
export function useEventsFormData(event){
  const defaultFormState = event.model.reduce((acc, cur) => {
    acc[decapitalize(cur.key)] = ''
    return acc
  }, {})

  const defaultState = {
    commonDataForm: defaultFormState,
    viewData: defaultFormState,
    inProgress: false
  }

  const validationRules = event.model.reduce((acc, cur) => {
    if(cur.required) {
      const fieldKey = cur.key.substring(cur.key.length - 2) === 'Id'
        ? cur.key.substring(0, cur.key.length - 2)
        : cur.key
      acc[decapitalize(fieldKey)] = {
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
      }
    }
    return acc
  }, {})

  return {
    validationRules,
    defaultState
  }
}

export function useEventsSignature(props){

  const {
    event,
    commonDataForm,
    setInProgress,
    dispatchStore,
    setState,
    validate,
    isExternalUser,
    id,
    type='eskzi',
    setTriggerUpdate
  } = props

  const {
    profile,
    signature: { textForSignature, signatureResult, requestData },
    error: { errorObject }
  } = useSelector((state) => state)

  const signatureResultRef = useRef()
  signatureResultRef.current = signatureResult

  const { signatureRequestType } = requestData || {}
  const dispatch = useDispatch()

  function onSignDialog(event){
    const signTextRequest = {
      ...getEventDataForServerFunctional(event, null, commonDataForm),
      signatureRequestType: 'JournalActionDataChanged'
    }

    dispatch(setRequestData({
      event,
      currentEditFormData: {
        ...commonDataForm,
        Signatures: event.signatures
      },
      signatureRequestType: 'JournalActionDataChanged'
    }))
    setInProgress(true)
    const updateRequest = dispatch(generateText(signTextRequest)).then(response => {
      setInProgress(false)
      return response
    })
    dispatchStore(updateCardAction(updateRequest))
  }

  function saveCallback(res){
    const { data, errors, isError } = res;
    setInProgress(false)
    if (!errors && !isError) {
      dispatch(updateViewDataAction())
      setTriggerUpdate && setTriggerUpdate()
      setState({editState : false })
      addToast("Успешно обновлено")
    }
    dispatchLogic(dispatch, CARD_UPDATE, data, isError, errors)
    return res
  }

  function getUpdateEvent() {
    const updateEskziEvent = isExternalUser ? extOrgEkziCardEditEvent : skziCardEditTask
    switch (type) {
      case 'keyDocument':
        return keyDocumentEventEdit
      case 'eskzi':
        return updateEskziEvent
    }
  }

  function onSubmit() {
    if(validate()){
      return false
    }
    if (isDigitalSignNeeded(profile, 'JournalActionDataChanged')) {
      onSignDialog(event)
    } else {
      const dataToServer = {...getEventDataForServerFunctional(event, null, commonDataForm)}
      const updateEvent = getUpdateEvent()
      setInProgress(true)
      const updateRequest = dispatch(updateEvent(event, { data: dataToServer }, id))
      updateRequest.then(saveCallback)
      dispatchStore(updateCardAction(updateRequest))
    }
  }

  const {addToast} = useToast()

  function afterSign() {
    const signatureToServer = {
      data: { ...getEventDataForServerFunctional(event, null, commonDataForm) },
      signature: { data: textForSignature, signedData: signatureResultRef.current }
    }

    const updateEvent = getUpdateEvent()
    setInProgress(true);
    const updateRequest = dispatch(updateEvent(event, signatureToServer, id))
    updateRequest.then(saveCallback)
    dispatchStore(updateCardAction(updateRequest))
  }

  return {
    errorObject,
    textForSignature,
    requestData,
    profile,
    signatureRequestType,
    onSubmit,
    afterSign
  }
}