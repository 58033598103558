/** @format */

import React, { useEffect, useState } from 'react';
import { FilterFileContent } from 'app/components/MassImports/FilterFileContent';
import { FileImportRowStatus } from 'app/components/MassImports/enums';
import Severity from 'app/core/types/severity';

const DownloadGroup = {
  All: 'all',
  Importable: 'canImportCount',
  Duplicates: 'duplicates',
  Errors: 'errors',
};

export const Filter = ({ importsPayload, setImportsPayload, records, totalCountDownloads }) => {
  const [downloadGroups, setDownloadGroups] = useState([]);
  const [isDisabledGroupDuplicates, setDisabledGroupDuplicates] = useState(false);
  const [isGroupDuplicates, setGroupDuplicates] = useState(false);

  const onChipsClickHandler = (type, val) => {
    if (type === DownloadGroup.All && !val) {
      return;
    }

    const downloadGroupAllSet = type === DownloadGroup.All && val;
    const updatedGroupDuplicatesValue = isGroupDuplicates && !(type === DownloadGroup.Duplicates && !val);
    setGroupDuplicates(updatedGroupDuplicatesValue);
    if (type === DownloadGroup.Duplicates) {
      setDisabledGroupDuplicates(!val);
    }

    setDownloadGroups(prevDownloadGroups => {
      const updatedDownloadGroupItems = prevDownloadGroups
        .filter(dg => dg.type !== DownloadGroup.All)
        .map(dg => ({
          ...dg,
          isActive: !downloadGroupAllSet && (dg.type === type ? val : dg.isActive),
        }));
      const updatedDownloadGroupAllItem = {
        ...prevDownloadGroups.find(dg => dg.type === DownloadGroup.All),
        isActive: downloadGroupAllSet || updatedDownloadGroupItems.every(dg => !dg.isActive),
      };
      const downloadGroupItems = [updatedDownloadGroupAllItem, ...updatedDownloadGroupItems];
      const downloadGroupActiveState = Object.fromEntries(downloadGroupItems.map(dg => [dg.type, dg.isActive]));
      setImportsPayload(prevState => ({
        ...prevState,
        conditions: {
          ...prevState.conditions,
          groupDuplicates: updatedGroupDuplicatesValue,
          statuses: Object.entries({
            [DownloadGroup.Importable]: FileImportRowStatus.RECOGNIZED,
            [DownloadGroup.Duplicates]: FileImportRowStatus.DUPLICATE,
            [DownloadGroup.Errors]: FileImportRowStatus.ERROR,
          })
            .filter(e => downloadGroupActiveState[DownloadGroup.All] || downloadGroupActiveState[e[0]])
            .map(e => e[1]),
        },
      }));
      return downloadGroupItems;
    });
  };

  useEffect(() => {
    const { duplicates = 0, errorsCount = 0, canImportCount = 0 } = records;
    const [hasImportable, hasDuplicates, hasErrors] = [canImportCount > 0, duplicates > 0, errorsCount > 0];
    const downloadGroupItems = [
      {
        type: DownloadGroup.All,
        text: `Все записи`,
        handler: onChipsClickHandler,
        isDisabled: !(hasImportable || hasDuplicates || hasErrors),
        isActive: downloadGroups.length ? downloadGroups.find(dg => dg.type === DownloadGroup.All).isActive : true,
      },
      {
        type: DownloadGroup.Importable,
        text: `Уникальные: ${canImportCount}`,
        handler: onChipsClickHandler,
        isDisabled: !hasImportable,
        isActive: downloadGroups.length
          ? downloadGroups.find(dg => dg.type === DownloadGroup.Importable).isActive
          : false,
      },
      {
        type: DownloadGroup.Duplicates,
        text: `Дубли: ${duplicates}`,
        handler: onChipsClickHandler,
        isDisabled: !hasDuplicates,
        isActive: downloadGroups.length
          ? downloadGroups.find(dg => dg.type === DownloadGroup.Duplicates).isActive
          : false,
      },
      {
        type: DownloadGroup.Errors,
        text: `Ошибки: ${errorsCount}`,
        handler: onChipsClickHandler,
        isDisabled: !hasErrors,
        isActive: downloadGroups.length ? downloadGroups.find(dg => dg.type === DownloadGroup.Errors).isActive : false,
      },
    ];
    setDownloadGroups(downloadGroupItems);

    const duplicatesVisible =
      downloadGroups.find(dg => dg.type === DownloadGroup.Duplicates)?.isActive ||
      downloadGroups.find(dg => dg.type === DownloadGroup.All)?.isActive;
    setDisabledGroupDuplicates(!(duplicates && duplicatesVisible));
  }, [records]);

  const { licensesLeft } = records;
  const notEnoughLicenses = typeof licensesLeft !== 'undefined';
  const errorText = notEnoughLicenses ? `Недостаточно лицензий для создания (доступно ${licensesLeft}). ` : '';
  const headerText = `${errorText}Всего записей для создания в системе: ${totalCountDownloads}`;
  const headerState = notEnoughLicenses ? Severity.Error : Severity.Warning;

  return (
    <FilterFileContent
      records={records}
      setPayload={setImportsPayload}
      payload={importsPayload}
      downloadsList={downloadGroups}
      isDisabledGroupDuplicates={isDisabledGroupDuplicates}
      headerText={headerText}
      headerState={headerState}
      setGroupDuplicatesState={setGroupDuplicates}
      isGroupDuplicates={isGroupDuplicates}
    />
  );
};
