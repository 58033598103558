/** @format */
import withTooltip from 'app/components/HOC/TooltipHOC';
import { formatFIOInitials } from 'app/core/utility/common';
import { Image } from 'app/components/ui/Img';

const LockImage = withTooltip(Image.Lock);

export const blockedColumn = item => {
  const { bizLocked, bizLockAuthor } = item || {};
  const tooltip = bizLocked ? `Заблокировал: ${formatFIOInitials(bizLockAuthor)}` : '';
  return bizLocked ? <LockImage tooltip={tooltip} className="locked-image" /> : null;
};
