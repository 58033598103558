import Input from 'app/components/ui/Input'
import Autocomplete, { Services, renderItem }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import { formatFIO } from 'app/core/utility/common'
import { notEmpty } from 'app/core/utility/validation'
import TagsPicker from 'app/components/ui/picker/tagPicker'
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo'
import { CardState } from 'app/components/ui/card/cardMaterial'
import { cardMode as CMode } from 'app/components/mainCard/cardComponent'
import {OKI_CARD_TYPE, ORG_CARD_TYPE} from '../ui/constants'

export const cardLines = {
  number: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error, data } = props
      const {autoGeneratedNumber} = data || {}

      return (
        <Input
          className='card-field destruction-certificate-card__number'
          label = '№ акта'
          value={editValue}
          onChange={value => onChange('number', value)}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit && editValue === autoGeneratedNumber}
          error={error}
        />
      )
    },
  },
  id: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field destruction-certificate-card__id'
          label = '№'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  updateDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <DatePicker
          className='card-field destruction-certificate-card__change-date'
          label = 'Дата изменения'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  createDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <DatePicker
          className='card-field destruction-certificate-card__create-date'
          label = 'Дата создания'
          error={error}
          value={editValue} 
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  author: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props
      const { signature, user } = editValue || {}

      return (
        <div className='card-field destruction-certificate-card__author'>
          <Input 
            label = 'Автор'
            value={formatFIO(user)}
            readOnly={cardState === CardState.view}
            disabled={cardState === CardState.edit || cardState === CardState.create}
          />
          <SignatureInfo 
            signData={signature ? { ...signature, signer: user } : null}
          />
        </div>
      )
    },
  },
  signers: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { formData, cardState, editValue = [], onChange, error } = props
      const { organization } = formData
      const { id: orgId } = organization || {}

      const pickerService = {
        serviceName: 'userService',
        serviceMethod: 'getUserByOrganizationId',
        data: {orgId}
      }

      return (
        <TagsPicker
          className='card-field destruction-certificate-card__signers'
          label = 'Комиссия'
          error={error}
          readOnly={cardState === CardState.view}
          onChange={value => onChange('signers', value)}
          currentItems={editValue}
          disabled={!orgId}
          serviceType={Services.user} 
          notStandardService={pickerService} 
          renderTagName={item => formatFIO(item)}
          pickerHeaderText='Выбор участников комиссии'
        />
      )
    },
  },
  organization: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardMode, editValue, onChange, error, cardState } = props
      const autompleteService = {
        serviceName: 'organizationService', 
        serviceMethod: 'getServedByUserAutocomplete', 
        data: {}
      }

      const {id, okz} = editValue || {}
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
      return (
        <Autocomplete 
          className='card-field destruction-certificate-card__organizationId'
          onEmptySearch = {true} 
          label='Организация'
          notStandardService={autompleteService} 
          onSelect={value => onChange && onChange('organization', value)} 
          error={error}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit}
          linkTarget={cardMode === CMode.edit && cardState === CardState.view && id && {targetType, id}}
          value={editValue}
          renderItem = {renderItem.organizations}
          isRequired
      />)
    },
  },
  skziAmount: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field destruction-certificate-card__skziAmount'
          label = 'СКЗИ'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  kcAmount: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field destruction-certificate-card__kcAmount'
          label = 'Ключевых носителей (КН)'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  kdAmount: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field destruction-certificate-card__kdAmount'
          label = 'Ключевых документов (КД)'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
}