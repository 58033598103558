import Input from 'app/components/ui/Input'
import Autocomplete, { Services, renderItem, renderInputValue }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import { formatFIO } from 'app/core/utility/common'
import { notEmpty } from 'app/core/utility/validation'
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker'
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo'
import { SelectType } from 'app/components/ui/picker/picker'

import { CardState } from 'app/components/ui/card/cardMaterial'
import {USER_ACCOUNT_CARD_TYPE} from "../../ui/constants";

export const cardLines = {
  number: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error, data } = props
      const {autoGeneratedNumber} = data || {}

      return (
        <Input 
          className='card-field maintenance-card__number'
          label = 'Номер заключения'
          value={editValue}
          onChange={value => onChange('number', value)}
          readOnly={cardState === CardState.view}
          error={error}
          disabled={cardState === CardState.edit && autoGeneratedNumber}
        />
      )
    },
  },
  performerId: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error, formData } = props
      const { eskziId } = formData || {}
      const { id } = eskziId || {}

      const autocompleteService = {
        serviceName: 'maintenanceService',
        serviceMethod: 'getPerformers',
        data: {eskziId: id}
      }

      return (
        <Autocomplete 
          className='card-field maintenance-card__performerId'
          label='ФИО исполнителя'
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          serviceType={Services.skziUser} 
          notStandardService={autocompleteService}
          onSelect={value => onChange && onChange('performerId', value)} 
          renderItem={renderItem.eskziUser}
          renderInputValue={renderInputValue.eskziUser}
          disabled={!eskziId || cardState === CardState.create}
          isRequired
        />)
      },
  },
  issueDate: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker
          className='card-field maintenance-card__issueDate'
          label = 'Дата заключения'
          error={error}
          value={editValue} 
          onChange={value => onChange('issueDate', value)}
          readOnly={cardState === CardState.view}
          isRequired
        />
      )
    },
  },
  updateDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <DatePicker
          className='card-field maintenance-card__updateDate'
          label = 'Дата изменения'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  eskziId: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props
      // const autompleteService = () => service('organizationService', 'getServedByUserAutocomplete') 
      return (
        <AutocompletePicker
          label = 'СКЗИ'
          needParentClass
          className='card-field maintenance-card__eskziId'
          serviceType={Services.skziInstance}
          selectType={SelectType.single}
          // notStandardService={autompleteService}
          value={editValue}
          readOnly={cardState === CardState.view}
          onSelectValue={value => onChange('eskziId', value)} 
          error={error}
          renderItem={renderItem.eskzi}
          renderInputValue={renderInputValue.eskzi}
          disabled={cardState === CardState.edit}
          isRequired
        />
      )
    },
  },
  hardwareNumber: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange } = props

      return (
        <Input 
          className='card-field maintenance-card__hardwareNumber'
          label = 'Номер АРМ'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange('hardwareNumber', value)}
        />
      )
    },
  },
  printNumber: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange } = props

      return (
        <Input 
          className='card-field maintenance-card__printNumber'
          label = 'Номер печати или пломбира'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange('printNumber', value)}
        />
      )
    },
  },
  eskziNumber: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, formData } = props
      const { eskziId } = formData || {}
      const { serialNumber } = eskziId || {}

      return (
        <Input 
          className='card-field maintenance-card__eskziNumber'
          label = '№ экземпляра СКЗИ'
          value={serialNumber}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  distributiveRemovingDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker
          className='card-field maintenance-card__distributiveRemovingDate'
          label = 'Дата удаления дистрибутива'
          error={error}
          onChange={value => onChange('distributiveRemovingDate', value)}
          value={editValue} 
          readOnly={cardState === CardState.view}
        />
      )
    },
  },
  eskziUser: {
    renderEdit: props => {
      const { cardState, formData = {} } = props
      const { eskziId, eskziUser: formEskziUser } = formData
      const { eskziUser } = eskziId || formData || {eskziUser: {}}

      const actualEskziUser = eskziUser || formEskziUser

      return (
        <Input
          className='card-field maintenance-card__eskziUser'
          label = 'Пользователь СКЗИ'
          value={formatFIO(actualEskziUser)}
          linkTarget={cardState === CardState.view && actualEskziUser && actualEskziUser.id && {targetType: USER_ACCOUNT_CARD_TYPE, id: actualEskziUser.id}}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  author: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props
      const { signature, user } = editValue || {}

      return (
        <div className='card-field maintenance-card__author'>
          <Input 
            label = 'Автор'
            value={formatFIO(user)}
            readOnly={cardState === CardState.view}
            disabled={cardState === CardState.edit || cardState === CardState.create}
          />
          <SignatureInfo 
            signData={signature ? { ...signature, signer: user } : null}
          />
        </div>
      )
    },
  },
}