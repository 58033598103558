import { combineReducers } from 'redux'
import errorReducer, {defaultState as defaultErrorState} from 'redux/reducers/errorReducer'
import listInProgressReducer from 'redux/reducers/list/listInProgressReducer'
import listReducer from 'redux/reducers/list/listReducer'
import listSelectedReducer from 'redux/reducers/list/listSelectedReducer'
import listSortingReducer from 'redux/reducers/list/listSortingReducer'
import listPaginator from 'redux/reducers/list/listPaginatorReducer'

import {
  frontTreeSortActionLogic,
  frontSortActionLogic,
} from 'redux/actions/list/listActions'
import useReducerWithThunk from 'redux/store/useReducerWithThunk';
import { dispatchLogic } from 'redux/actions/common';
import { useState } from 'react'
import { sortDirection } from 'app/components/ui/constants'


export default (params) => {
  const { defaultSort } = params || {}
  const { column = 'id', direction = sortDirection.desc } = defaultSort || {}

  const [store, dispatch] = useReducerWithThunk(
    combineReducers({ 
      error: errorReducer, 
      list: listReducer,
      inProgress: listInProgressReducer,
      sorting: listSortingReducer,
      selectedItems: listSelectedReducer,
      paginator: listPaginator,
      
    }), 
    {
      error: defaultErrorState, 
      list: [], 
      inProgress: false,
      sorting:{ column, direction },
      selectedItems: [],
      paginator: { current : 1, itemsPerPage : 99999999, itemsCount : 0, itemFrom: 0, itemTo: 9999999 }
    }
  ) 

  const {
    list,
    error,
    inProgress,
    sorting,
    selectedItems,
    paginator
  } = store

  const [ selectedObjects, setSelectedObjects ] = useState([])
  const initPagginatorAction = data => {
    dispatch({ type: 'LIST_INIT_PAGINATOR', payload: data  });  
  }
  
  const setCurrentPageAction = data => {
    dispatch({ type: 'LIST_PAGINATOR_SET_CURRENT_PAGE', payload: data  });
  }

  const restoreLocalSorting = data => {
    const { sorting } = data || {}
    sorting && sortListSetAction(sorting)
  } 

  const onInitValues = async (list) => {
      return await dispatch({
        type: 'LIST_PAGINATION_GET',
        payload: list 
      })
    }
  
  const onUpdateValues = async (list) => {
    return await dispatch({
      type: 'LIST_PAGINATION_GET',
      payload: list 
    })
  }
  
  const onInProgress = (inProgress) => {
    dispatch({ type: inProgress ? 'LIST_IN_PROGRESS_ON' : 'LIST_IN_PROGRESS_OFF' });  
  }

  const onAddValues = (list) => {
    dispatch({
      type: 'LIST_ADD_ITEMS',
      payload: list 
    })
  }

  const onTreeAddValues = (list) => {
    dispatch({
      type: 'LIST_TREE_ADD_ITEMS',
      payload: list 
    })
  }

  const onTreeInitValues = ({sorting, config, list}) => {
    dispatch({
      type: 'LIST_TREE_GET',
      payload: sorting 
                ? frontTreeSortActionLogic(sorting, config, list) 
                : list
    })
  }

  const onRemoveValues = (list) => {
    dispatch({
      type: 'LIST_REMOVED',
      payload: list 
    })
  }

  const onTreeUpdateValues = (list) => {
    dispatch({
      type: 'LIST_TREE_UPDATE',
      payload: list 
    })
  }

  const onRemoveTreeValues = (list) => {
    dispatch({
      type: 'LIST_TREE_REMOVED',
      payload: list 
    })
  }

  const onClearList = () => {
    dispatch({
      type: 'LIST_DROP'
    })
  }

  const onToggleExpandItem = value => {
    dispatch({
      type: 'LIST_TREE_TOGGLE_EXPAND_ITEM',
      payload: value
    })
  }

  const setSelectedAction = value => {
    dispatch({
      type: 'LIST_SET_SELECTED',
      payload: value
    })
  }

  const setInProgressOn = () => {
    dispatch({
      type: 'LIST_IN_PROGRESS_ON'
    })
  }

  const setInProgressOff = () => {
    dispatch({
      type: 'LIST_IN_PROGRESS_OFF'
    })
  }

  const onRemoveSelected = value => {
    dispatch({
      type: 'LIST_REMOVED_SELECTED',
      payload: value
    })
  }

  const onItemUpdate = value => {
    dispatch({
      type: 'LIST_ITEM_UPDATE',
      payload: value
    })
  }

  const sortListAction = fieldName => {
    dispatch({ type: 'LIST_SORT_BY', payload: fieldName  });
  }

  const sortListSetAction = data => {
    dispatch({ type: 'LIST_SORT_BY_SET', payload: data  });
  } 

  const syncFrontListSortAction = ( sorting, config, list  ) => {

    dispatch({
      type : 'LIST_GET', 
      payload : frontSortActionLogic(sorting, config, list)
    })
  }

  const syncFrontTreeListSortAction = ( sorting, config, list  ) => {
    dispatch({ 
      type : 'LIST_TREE_SORT', 
      payload : frontTreeSortActionLogic(sorting, config, list)
    })
  }

  return {
    onInitValues,
    onUpdateValues,
    onInProgress,
    sortListSetAction,
    inProgress,
    sorting,
    selectedItems,
    selectedObjects,
    list,
    paginator,
    error,
    dispatch,
    syncFrontListSortAction,
    syncFrontTreeListSortAction,
    sortListAction,
    onAddValues,
    onTreeUpdateValues,
    onTreeInitValues,
    onTreeAddValues,
    onToggleExpandItem,
    onRemoveTreeValues,
    onRemoveValues,
    onClearList,
    onRemoveSelected,
    setSelectedAction,
    setSelectedObjects,
    setInProgressOn,
    setInProgressOff,
    setCurrentPageAction,
    initPagginatorAction,
    onItemUpdate,
    restoreLocalSorting
  }
}