import React from "react";
import withTooltip from 'app/components/HOC/TooltipHOC'
import TagComponent from 'app/components/ui/tag'
import {useParams} from "react-router-dom";
import {formatDate} from "../../../core/utility/date";
import {useSelector} from "react-redux";
import {getResourceRights} from "../../../core/auth/auth";
import {RESOURCES} from "../../../core/auth/resourceByPage";

const Tag = withTooltip(TagComponent)

export default function useCertificationBlockConfig({currentlyDeletingFiles, onFileDelete}){
  const profile = useSelector(({profile}) => profile);
  const fileRights = getResourceRights(profile, RESOURCES.files);
  const { skziId, versionId, buildId } = useParams()
  const urlPart = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/certificates`
  return [
    {
      title: 'Тип',
      alias: 'type',
      useSorting: true,
      width: 150,
      format: item => {
        const {type} = item
        const { name = '' } = type || {}

        return name
      }
    },
    {
      title: 'Номер',
      alias: 'number',
      useSorting: true,
      width: 300,
      format: item => item.number
    },
    {
      title: 'Начало действия',
      alias: 'certificateStartDate',
      useSorting: true,
      width: 300,
      format: item => formatDate( item.certificateStartDate, 'dd.mm.yyyy' )
    },
    {
      title: 'Окончание действия',
      alias: 'certificateExpirationDate',
      useSorting: true,
      width: 300,
      format: item => item.certificateExpirationDate ? formatDate( item.certificateExpirationDate, 'dd.mm.yyyy' ) : 'Бессрочно'
    },
    {
      title: 'Вложения',
      alias: 'documents',
      useSorting: false,
      isCustomRender: true,
      format: rowData => {
        const { files = [] } = rowData
        return (
          (
            <div className="type-files">
              {files.map((item) => {
                  return currentlyDeletingFiles[rowData.id] && currentlyDeletingFiles[rowData.id][item.guid] ? (
                    <Tag
                      key={item.guid}
                      className='deleting-file-tag'
                      text={'Удаление...'}
                      tooltip={item.name}
                      item={item}
                    />
                  ) : (
                    <Tag
                      key={item.guid}
                      text={item.name}
                      tooltip={item.name}
                      link={`${urlPart}/certificate/${rowData.id}/file/${item.guid}`}
                      item={item}
                      onDelete={fileRights.DELETE ? () => onFileDelete(item.guid, rowData.id) : null}
                    />
                  )
                }
              )}
            </div>
          )
        )
      }
    },
  ]
}