/** @format */

import ExpandingBlock from 'app/components/ui/expandingBlock';
import RequisitesForm from './requisitesForm';
import styles from '../card.module.scss';
import ManagementPanel from './managementPanel';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';

export default function Requisites({ data, initCardData, profile }) {
  return (
    <section className={styles.сardData}>
      <ExpandingBlock renderHeader={() => 'Реквизиты'} initialState={true}>
        <ManagementPanel
          rights={getResourceRights(profile, RESOURCES.certificateAuthorityUser)}
          afterAction={initCardData}
          items={[data]}
        />
        <RequisitesForm {...data} />
      </ExpandingBlock>
    </section>
  );
}
