import {
  USERSKZIADMITTANCESETALLDATA,
  USERSKZIADMITTANCEGETALLDATA,
  USERSKZIADMITTANCEGETADMITTANCE   ,
  USERSKZIADMITTANCEGETTRAINING     ,
  USERSKZIADMITTANCEINPROGRESS      ,
  USERSKZIADMITTANCERESETACTION     ,
  USERSKZIADMITTANCEONTRAININGSELECT,
  USERSKZIADMITTANCEONADMITTANCESELECT,
  USERSKZIADMITTANCEADDADMITANCE,
  USERSKZIADMITTANCEUPDATEADMITANCE,
  USERSKZIADMITTANCEDELETEADMITANCE,
  USERSKZIADMITTANCEADDTRAINING,
  USERSKZIADMITTANCEDELETETRAINING
  } from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const userSkziAdmittanceSetAllData = id => async dispatch => {
  return service('userAccountService', 'getUA', id)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, USERSKZIADMITTANCESETALLDATA, data, isError, errors)
    })
}

// after backend will fix their bugs we can use this two request simultaneously
export const userSkziAdmittanceGetAllData = data => async dispatch => {
  const { admittance: admittanceRequest, training: trainingRequest } = data
  const response = await Promise.all([
    service('courseService', 'getAllUserAdmittance', admittanceRequest),
    service('courseService', 'getAllUserTraining', trainingRequest)
  ])

  const [ admittance, training ] = response
  const { data: admittanceData, errors: admErr, isError: isAdmErr } = admittance
  const { data: trainingData, errors: trainingErr, isError: isTrainingErr } = training

  dispatchLogic(dispatch, USERSKZIADMITTANCEGETALLDATA, admittanceData, isAdmErr, admErr)
  dispatchLogic(dispatch, USERSKZIADMITTANCEGETTRAINING, trainingData, isTrainingErr, trainingErr)
}

export const userSkziAdmittanceGetAdmittance = data => dispatch => {
  service('courseService', 'getAllUserAdmittance', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, USERSKZIADMITTANCEGETADMITTANCE, data, isError, errors)
    })
}

export const userSkziAdmittanceGetTraining = data => dispatch => {
  service('courseService', 'getAllUserTraining', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, USERSKZIADMITTANCEGETTRAINING, data, isError, errors)
    })
}

export const userSkziAdmittanceAddAdmittance = data => dispatch => {
  return service('courseService', 'addUserAdmittance', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, USERSKZIADMITTANCEADDADMITANCE, data, isError, errors)
    })
}

export const userSkziAdmittanceUpdateAdmittance = data => dispatch => {
  return service('courseService', 'updateUserAdmittance', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, USERSKZIADMITTANCEUPDATEADMITANCE, data, isError, errors)
    })
}

export const userSkziAdmittanceDeleteAdmittance = items => dispatch => {
  return service('courseService', 'deleteAdmittance', items)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, USERSKZIADMITTANCEDELETEADMITANCE, data, isError, errors)
    })
}

export const userSkziAdmittanceDeleteTraining = items => dispatch => {
  return service('courseService', 'deleteUserTraining', items)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, USERSKZIADMITTANCEDELETETRAINING, data, isError, errors)
    })
}

export const userSkziAdmittanceAddTraining = data => dispatch => {
  return service('courseService', 'addUserTraining', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, USERSKZIADMITTANCEADDTRAINING, data, isError, errors)
    })
}

export const userSkziAdmittanceInProgressAction = value => dispatch => {
  dispatch({
    type: USERSKZIADMITTANCEINPROGRESS,
    payload: { inProgress: value }
  })
}

export const userSkziAdmittanceResetAction = () => dispatch => {
  dispatch({
    type: USERSKZIADMITTANCERESETACTION,
    payload: null
  })
}

export const userSkziAdmittanceSelectAdmittanceAction = selectedData => dispatch => {
  dispatch(
    {
      type: USERSKZIADMITTANCEONADMITTANCESELECT,
      payload: selectedData
    }
  )
}

export const userSkziAdmittanceSelectTrainingAction = selectedData => dispatch => {
  dispatch(
    {
      type: USERSKZIADMITTANCEONTRAININGSELECT,
      payload: selectedData
    }
  )
}