import { notEmpty } from 'app/core/utility/validation'

export const organizationValidationRules = {
  organizations: [
    {
      organizationPath: {
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
      },
      organization: {
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
      },
    }
  ]
}

export const rolesValidationRules = {
  roles: [
    {
      groupPath: {
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
      },
      userRole: {
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
      },
    }
  ]
}

export const mappingValidationRules = {
  name: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  surname: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  email: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
}

export const authenticationValidationRules = {
  serverAddress: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  serverPort: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  searchBase: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  serverAccount: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  serverPassword: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  refreshPeriod: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
}