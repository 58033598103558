import React, { PureComponent } from 'react'

import TabsHeader from './tabsHeader'
import VisibleTab from "./VisibleTab";
import Slider from '../slider'

export const TABSIZE = {
  small: 'small',
  medium: 'medium'
}

class VisibleTabs extends PureComponent {
  render() {
    const { children, activeTab } = this.props
    const tabsChildren = children && children.length
      ? children
      : children
        ? [children]
        : []
    const validChildren = tabsChildren.filter(tab => tab !== null)
    const displayTab = validChildren.find(item => item.props.tabTitle === activeTab)

    return (
      <div className='tabs-container'>
        <Slider
          forTabs={true}
        >
          <TabsHeader
            {...this.props}
            tabs={validChildren}
          />
        </Slider>
        {
          displayTab &&
          validChildren.map((el) => {
              const visibility = el.props.tabTitle === activeTab ? {} : {display : 'none'}
              return (
                <VisibleTab
                  key={el.props.tabTitle}
                  tabTitle={el.props.tabTitle}
                  activeTab={activeTab}
                  visibility={visibility}
                >
                  {el}
                </VisibleTab>
              )
            }
          )}
      </div>
    )
  }
}

export default VisibleTabs