// React
import * as React from 'react';
import { CryptoProviderType, SignatureFormat } from './types';

// Components
import { withCryptoProviders, IWithCryptoProvidersProps } from './CryptoProvidersHOC';

enum States {
  PROVIDERS_REQUEST,
  PROVIDERS_FAILURE,

  SIGN_DATA,

  SUBMIT_REQUEST,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,
}

export interface ISignatureModalProps {
  dataToSign?: string;
  signatureFormat: SignatureFormat,
  form?: {};
  service: (data: { form?: {}; signature: { value?: string } }) => Promise<Response>;
  onSuccess: () => void;
}

interface IState {
  currentState: States;
  createdClaimCode: string;
  signatureError?: string;
  submitError?: string;
}

const DEFAULT_STATE: IState = {
  createdClaimCode: '',
  currentState: States.PROVIDERS_REQUEST,
};

type Props = ISignatureModalProps & IWithCryptoProvidersProps;

export class SignatureModalView extends React.Component<Props, IState> {
  public state: IState = DEFAULT_STATE;

  public componentDidMount() {
    this.initProviders();
  }

  public render() {
    if (!this.props.children) {
      return null;
    }

    return this.props.children(
      this.isRenderProvidersRequest(),
      this.isRenderProvidersFailure(),
      this.isRenderSignatureForm(),
      this.isRenderSubmitRequest(),
      this.isRenderSubmitSuccess(),
      this.isRenderSubmitFailure(),
      this.isNextButtonEnabled(),

      {
        signatureData: this.props.dataToSign,
        providers: this.props.crypto.providers,
        currentProvider: this.props.crypto.currentProvider,
        onChangeProvider: this.handleChangeProvider,
        onChangeCryptoProCert: this.handleChangeCryptoProCert,
        signErrorMessage: this.state.signatureError,
      },

      this.onNext,
    );
  }

  private initProviders = async () => {
    await this.props.initProviders();

    if (!this.props.isInitialized()) {
      this.setState(() => ({
        currentState: States.PROVIDERS_FAILURE,
      }));

      return;
    }

    this.setState(() => ({
      currentState: States.SIGN_DATA,
    }));
  };

  private handleChangeProvider = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    this.props.changeProvider(CryptoProviderType[e.currentTarget.value as keyof typeof CryptoProviderType]);
    this.setState(() => ({ signatureError: undefined }));
  };

  private handleChangeCryptoProCert = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    this.props.changeCryptoProCert(e.currentTarget.value);
    this.setState(() => ({ signatureError: undefined }));
  };

  private onNext = async (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const { signData, dataToSign, signatureFormat, form, service } = this.props;

    if (!dataToSign) {
      return;
    }

    let signatureValue;
    try {
      signatureValue = await signData(dataToSign, signatureFormat);
    } catch (err) {
      if (err && (err as any).message) {
        this.setState(() => ({ signatureError: (err as any).message }));
      }
    }

    if (!signatureValue) {
      return;
    }

    this.setState({
      currentState: States.SUBMIT_REQUEST,
    });

    const data = {
      form,
      signature: { value: signatureValue },
    };

    service(data)
      .then((resp: any) => {
        this.setState({
          createdClaimCode: resp.claimCode,
          currentState: States.SUBMIT_SUCCESS,
        });
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.setState(() => ({
            submitError: error.response.data,
          }));
        }

        this.setState({
          currentState: States.SUBMIT_FAILURE,
        });
      });
  };

  private isNextButtonEnabled = () => {
    switch (this.state.currentState) {
      case States.SIGN_DATA: {
        return this.props.isReadyToSign();
      }
      default:
        return false;
    }
  };

  private isRenderProvidersRequest = () => this.state.currentState === States.PROVIDERS_REQUEST;
  private isRenderProvidersFailure = () => this.state.currentState === States.PROVIDERS_FAILURE;
  private isRenderSignatureForm = () => this.state.currentState === States.SIGN_DATA;
  private isRenderSubmitRequest = () => this.state.currentState === States.SUBMIT_REQUEST;
  private isRenderSubmitSuccess = () => this.state.currentState === States.SUBMIT_SUCCESS;
  private isRenderSubmitFailure = () => this.state.currentState === States.SUBMIT_FAILURE;
}

export const SignatureModal = withCryptoProviders(SignatureModalView);