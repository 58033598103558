/** @format */
import { sortDirection } from 'app/components/ui/constants';

export const defaultSettings = {
  columns: [
    {
      title: 'Наименование',
      alias: 'name',
      useSorting: true,
    },
  ],
  sort: {
    column: 'name',
    direction: sortDirection.asc,
  },
};
