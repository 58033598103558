import React, {useEffect, useState} from 'react'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import Overlay from 'app/components/ui/overlay'

import FunctionalCard from '../../ui/card/FunctionalCard'
import useFunctionalCard from '../../ui/card/FunctionalCard/useFunctionalCard'
import { mappingValidationRules } from '../formData/formValidationRules'
import service from '../../../services/service'
import { defaultMappingFormState } from '../formData/formStates'
import MappingForm from '../forms/MappingForm'
import useBlockEditState from "../hooks/useBlockEditState";

export default function MappingBlock({authenticationDataValid, setMappingDataValid}) {

    const {
      dataValid,
      setDataValid,
      editStateBlock,
      setEditStateBlock,
      checkValidDataCallback,
      checkValidResponse
    } = useBlockEditState(setMappingDataValid)

  const formData = {
    validationRules: mappingValidationRules,
    defaultState: defaultMappingFormState
  }

  const get = () => service('activeDirectorySettingsService', 'getMappingSettings').then(checkValidDataCallback)

  const update = (data) => {
      return service('activeDirectorySettingsService', 'updateMappingSettings', data)
  }

  const applyRightsToActions = () => {
    return ["edit", "delete"]
  }

  const {
    onSubmit,
    submitEnabled,
    onCancelForm,
    cardState,
    editState,
    setState,
    inProgress,
    fieldsError,
    onFormChange,
    commonDataForm,
    onAdd,
    onRemove
  } = useFunctionalCard({formData, get, update, updateCallback: checkValidResponse, parentEditState: editStateBlock})

  useEffect(()=> {
    setEditStateBlock(editState)
  }, [editState])

  return (
    <ExpandingBlock
      renderHeader= {() => 'Наименование реквизитов в AD'}
      initialState={authenticationDataValid && !dataValid}
      disabled={!authenticationDataValid}
    >
      {inProgress ? <Overlay /> : null}
      <FunctionalCard
        showButtons={true}
        cardState={cardState}
        canEdit={true}
        editOnly={false}
        onSubmit={onSubmit}
        submitEnabled={submitEnabled}
        submitTitle="Сохранить"
        onCancelForm={onCancelForm}
        setState={setState}
        className="ad-settings-data-card"
        actions={applyRightsToActions()}
        editState={editState}
        cancelEnabled={dataValid}
      >
        <div className="card-material__content">
          <MappingForm
            cardState={cardState}
            formData={commonDataForm}
            serverFormViewModel={null}
            onChange={onFormChange}
            errorByFields={fieldsError}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        </div>
      </FunctionalCard>
    </ExpandingBlock>
  )
}