import React, { useState, useEffect } from 'react'
import LoginContent from './components/LoginContent'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import InProgressSpinning from './components/InProgressSpinning'
import { api } from 'app/services/service'

export default function Login(props) {
  const {
    authMethods,
    onSubmit,
    isPasswordRecovery,
    onDomainSubmit,
    loading
  } = props
  
  const [version, setVersion] = useState()
  
  useEffect(() => {
    const fetchVersionInfo = async () => {
      const result = await api('systemVersion/getShortInfo');
      const {
        data: { version } = {},
      } = result;
      setVersion(version);
    };

    fetchVersionInfo();
  }, []);

  return loading ? (
      <>
        <section className='login-page'>
          <div className='login-container'>
            <div className="login-container__logo">
              <Logo />
            </div>
            <InProgressSpinning />
          </div>
        </section>
      </>
      ): (
        <section className='login-page'>
          <div className='login-container'>
            <div className="login-container__logo">
              <Logo />
            </div>
            <LoginContent
              authMethods={authMethods}
              onSubmit={onSubmit}
              isPasswordRecovery={isPasswordRecovery}
              onDomainSubmit={onDomainSubmit}
            />
            {version && <div className="version">
              Система (ядро v.{version})
            </div>}
          </div>          
        </section>
      )

}


