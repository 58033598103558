/** @format */

import React from 'react';
import InfoWithChart from '../infoWithChart';
import { CIRCLE_COLOR } from '../typography';
import Doughnut from 'app/components/ui/charts/Doughnut/Doughnut';

import styles from './keyCarriers.module.scss';
import typographyStyles from '../typography/typography.module.scss';

export default function KeyCarriers({ available, occupied }) {
  return (
    <InfoWithChart
      className="keyCarriers"
      headerText="Ключевые носители"
      dataRows={[
        {
          title: 'Доступно',
          data: available,
          // reference: '',
          circle: { color: CIRCLE_COLOR.primary },
        },
        {
          title: 'Занято',
          data: occupied,
          // reference: '',
          circle: { color: CIRCLE_COLOR.secondary },
        },
      ]}
    >
      <Doughnut
        vm={[
          {
            r: 44,
            width: 8,
            className: styles.subchart,
            invalidChartClassName: styles.arcInvalid,
            gap: 0.03,
            items: [
              { text: `Доступно: ${available}`, value: available, className: styles.arcAvailable },
              { text: `Занято: ${occupied}`, value: occupied, className: styles.arcUsed },
            ],
          },
        ]}
        width={96}
        height={96}
      >
        <text x="48" y="48" textAnchor="middle" dominantBaseline="middle" className={typographyStyles.accentText}>
          {available + occupied}
        </text>
      </Doughnut>
    </InfoWithChart>
  );
}
