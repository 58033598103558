import { useState } from 'react'

export default function useBlockEditState(setBlockDataValid){
  const [ dataValid, setDataValid ] = useState(false)
  const [ editStateBlock, setEditStateBlock] = useState(true)

  const checkValidDataCallback = (response) => {
    const {errors, isWarn} = response
    if(isWarn && errors && errors[0] && errors[0].errorCode === 'W_EMPTY_DATA'){
      return response
    }
    setDataValid(true)
    setBlockDataValid(true)
    setEditStateBlock(false)
    return response
  }

  const checkValidResponse = (response) => {
    const {data, errors, isError} = response
    if (data && !errors && !isError) {
      setDataValid(true)
      setBlockDataValid(true)
      setEditStateBlock(false)
    }
    return response
  }

  return {
    dataValid,
    editStateBlock,
    setEditStateBlock,
    setDataValid,
    checkValidDataCallback,
    checkValidResponse
  }

}