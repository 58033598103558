/** @format */

/**
 * Adds elements to array to reach required length
 * @param {Array<any>} arr Input array
 * @param {number} targetLength Length to pad the array to
 * @param {any} value Values to pad the array with
 * @param {boolean} padLeft Whether the new elements should be added left
 * @returns {Array<any>}
 */
const pad = (arr, targetLength, value, padLeft) => {
  const elementsToAdd = targetLength - arr.length;
  if (elementsToAdd <= 0) {
    return arr;
  }

  const padding = Array.from({ length: elementsToAdd }).map(_ => value);
  return padLeft ? [...padding, ...arr] : [...arr, ...padding];
};

/**
 * Splits the input array into an array of arrays of the specified size
 * @param {Array<any>} arr Input array
 * @param {number} chunkSize Size of the chunks
 * @returns {Array<any>}
 */
const split = (arr, chunkSize) =>
  arr.reduce((acc, cur, i) => {
    if (i % chunkSize === 0) {
      acc = [...acc, []];
    }

    acc[acc.length - 1].push(cur);
    return acc;
  }, []);
  
/**
 * Sums up values from the input array
 * @param {Array<any>} arr Input array
 * @param {Array<any>} valueSelector Function mapping array elements to values
 * @param {Array<any>} initialValue Initial value to add the sum to
 * @returns {any}
 */
const sum = (arr, initialValue = 0, valueSelector = x => x) =>
  arr.reduce((acc, cur) => acc + valueSelector(cur), initialValue);

export { pad, split, sum };
