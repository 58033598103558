import React, { Children } from 'react';
import { getClassName } from 'app/core/utility/jsx';
import styles from './managePanel.module.scss';
import { Border } from '.';

export default function ManagePanel({ children, borders }) {
  const childrenArr = Children.toArray(children);
  const { mainChildren, separateChildren } = childrenArr.reduce(
    (acc, cur) => {
      const target = cur.props.separate ? 'separateChildren' : 'mainChildren';
      acc[target].push(cur);
      return acc;
    },
    {
      mainChildren: [],
      separateChildren: [],
    }
  );

  return (
    <div
      className={getClassName([
        'buttons-manage-panel',
        styles.managePanel,
        {
          [styles.closedTop]: borders & Border.Top,
          [styles.closedBottom]: borders & Border.Bottom,
        },
      ])}
    >
      <div className={`${styles.area} ${styles.areaMain}`}>{mainChildren}</div>
      <div className={`${styles.area} ${styles.areaSeparate}`}>
        {separateChildren}
      </div>
    </div>
  );
}
