import React from 'react'

import { ReactComponent as AlertIconTriangle } from 'assets/img/commonVer2/ic_alert_triangle.svg';

export default function ErrorPanel(props) {
  const {
    children,
    Icon
  } = props
  return (
    <div className='error-panel'>
      <div className="error-panel__icon">
        {Icon ? <Icon/> : <AlertIconTriangle/>}
      </div>
      <div className="error-panel__message">
        {children}
      </div>
    </div>
  )
}
