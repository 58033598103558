/** @format */

import React from 'react';
import InfoWidget from '../infoWidget';

export default function Activity({ exams, cources }) {
  return (
    <InfoWidget
      className="activities"
      headerText="Активности"
      dataRows={[
        { 
          title: 'Записалось на экзамен', 
          data: exams, 
          // reference: '' 
        },
        { 
          title: 'Курсы назначены', 
          data: cources, 
          // reference: '' 
        },
      ]}
    />
  );
}
