/** @format */

import React from 'react';

import Button, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import Input from 'app/components/ui/Input';
import { copyToClipboard } from 'app/core/utility/common';

import styles from './HashSumInput.module.scss';

import withTooltip from 'app/components/HOC/TooltipHOC';
import { Position } from 'app/components/HOC/TooltipHOC';
const TooltipButton = withTooltip(Button);

const HashSumInput = ({ value, isInvalid, isDirty, onChange }) => {
  return (
    <div className={styles.hashSumInput}>
      <Input
        value={value}
        label="Контрольная сумма"
        hint={
          isDirty || !value || !value.length
            ? 'Для проверки дистрибутива укажите его контрольную сумму'
            : 'Контрольная сумму соответствует дистрибутиву'
        }
        error={
          isInvalid && !isDirty
            ? {
                errorCode: 'HASHSUM_INVALID',
                errorMessage: 'Контрольная сумма не соответствует дистрибутиву',
                isError: true,
              }
            : null
        }
        className="input-hashsum"
        onChange={onChange}
      />
      <TooltipButton
        type={buttonTypes.image}
        tooltip="Копировать"
        tooltipPosition={Position.BottomLeft}
        className="btn-copy"
        onClick={() => copyToClipboard(value)}
      >
        <Img img={Image.Copy} />
      </TooltipButton>
    </div>
  );
};

export default HashSumInput;
