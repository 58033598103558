import { capitalize } from 'app/core/utility/common'

export default function useExemplarForm({formData,errorByFields}){

  function getFormDataValue(fieldName){
    return formData[fieldName]
  }

  function getError(fieldName){
    return errorByFields[capitalize(fieldName)]
  }

  return {
    getError,
    getFormDataValue,
  }
}