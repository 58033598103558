import React from 'react';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import { OKI_CARD_TYPE, ORG_CARD_TYPE } from '../ui/constants';
import ListLink from '../ui/ListLink';
import { FilterControlType } from '../filter/FilterElement';
import ImgMap from 'app/components/ui/Img/imgMap';

const config = {
  fieldsConfig: [
    {
      title: '№',
      alias: 'id',
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      width: 50,
    },
    {
      title: 'ФИО пользователя',
      alias: 'name',
      useSorting: true,
      visibleOnStart: true,
      visibleAlways: true,
      format: item => formatFIO(item),
      width: 400,
    },
    {
      title: 'Экземпляры СКЗИ',
      iconTitle: ImgMap.OkzReport,
      alias: 'eskzis',
      format: item => {
        const { eskzis } = item;
        return (eskzis && eskzis.length) || '0';
      },
      useSorting: false,
      visibleOnStart: true,
      width: 160,
    },
    {
      title: 'Ключевые документы',
      iconTitle: ImgMap.KeyDocument,
      alias: 'keyDocuments',
      format: item => (item.keyDocuments && item.keyDocuments.length) || '0',
      useSorting: false,
      visibleOnStart: true,
      width: 160,
    },
    {
      title: 'Ключевые носители',
      iconTitle: ImgMap.HardwareSKZI,
      alias: 'keyCarriers',
      format: item => (item.keyCarriers && item.keyCarriers.length) || '0',
      useSorting: false,
      visibleOnStart: true,
      width: 160,
    },
    {
      title: 'Организация',
      alias: 'organization',
      format: item => {
        const { organization } = item;
        if (!organization) return '';

        const { shortName, name, id, okz } = organization;
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE;
        return (shortName || name) && id ? (
          <ListLink text={shortName || name} targetType={targetType} id={id} />
        ) : (
          name || ''
        );
      },
      useSorting: true,
      visibleOnStart: true,
      width: 310,
    },
    {
      title: 'Дата открытия',
      alias: 'accountCreateDate',
      format: item => formatDate(item.accountCreateDate, 'dd.mm.yyyy'),
      useSorting: true,
      visibleOnStart: true,
    },
    {
      title: 'Дата закрытия',
      alias: 'accountCloseDate',
      format: item => formatDate(item.accountCloseDate, 'dd.mm.yyyy'),
      useSorting: true,
      visibleOnStart: false,
    },
    {
      title: 'Статус',
      alias: 'status',
      format: item =>
        typeof item.status === 'string' ? item.status : 'Не задан',
      useSorting: true,
      visibleOnStart: true,
    },
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'fio', 
      label : 'ФИО',
      extended : false,
      order : 3
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'status', 
      label : 'Статус', 
      serviceType : 'eskziUserStatus', 
      extended : false,
      order : 4
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'accountCreateDate', 
      label : 'Дата открытия',
      extended : true,
      order : 6,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'accountCloseDate', 
      label : 'Дата закрытия',
      extended : true,
      order : 7,
    },
    {
      type : FilterControlType.selfServiceStatus, 
      alias : 'selfServiceStatus', 
      serviceType : 'selfServiceStatus',
      extended : true,
      order : 8
    },
  ]
};

export default config