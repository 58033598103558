export const getEskziName = skziData => {
  const { 
    skzi,
    build,
    version,
    skziClass,
  } = skziData || {}
  const { name: skziName = ''} = skzi || {}
  const { name: buildName = ''} = build || {}
  const { name: versionName  = ''} = version || {}
  const { name: skziClassName = ''} = skziClass || {}

  return `${skziName} ${versionName} ${buildName} ${skziClassName}`.trim()
}