import Button from "app/components/ui/button/button";
import React from "react";

const CreateControl = ({
                           onSubmitSignEnhance,
                           generateDataForRequest,
                            disabled
                       }) => {
    return <Button
        className={'modal-container__footer-create-btn'}
        type={'primary'}
        onClick={() => onSubmitSignEnhance(generateDataForRequest())}
        notSubmit
        disabled={disabled}
    >
        Создать записи
    </Button>;
}

export default CreateControl;