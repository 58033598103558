import React from 'react'
import { ReactComponent as LoadImg }  from 'assets/img/commonVer2/ic_loading.svg'

export const OVERLAYSIZE = {
  small: 'small',
  medium: 'medium',
  window: 'window',

}

export default function Overlay(props) {
  const { noTitle = false, size = OVERLAYSIZE.window } = props
  return (
    <>
      <div className={`overlay overlay--${size}`}>
      </div>
      <div className={`overlay__content content--${size}`}>
        {size === OVERLAYSIZE.small 
          ? <LoadImg className='content__in-progress'/>
          : <div className='content__wait'></div>}
        {!noTitle && size === OVERLAYSIZE.window ? (
          <div className='content__text'>Загрузка...</div>
        ) : null}
      </div>
    </>
  )
}
