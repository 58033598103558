/** @format */

import ExpandingBlock from '../ui/expandingBlock';
import styles from './history.module.scss';
import service from 'app/services/service';
import HistoryList from './historyList';

export default function History(props) {
  const { serviceName, itemId } = props;
  const dataService = ({ page, pageSize }) => service(serviceName, 'getHistory', { id: itemId, page, pageSize });

  return (
    <div className={styles.container}>
      <ExpandingBlock renderHeader={() => 'История изменений'} initialState={true}>
        <HistoryList {...props} loadData={dataService} />
      </ExpandingBlock>
    </div>
  );
}
