import React from 'react'
import Group from 'app/components/ui/managePanel/group';
import { useHistory } from "react-router-dom";
import DeleteOrganizations from 'app/components/organizations/DeleteOrganizations/DeleteOrganizations';

export default function BusinessEventPanel(props) {
  const {
    onInProgress,
    okzId
  } = props

  const history = useHistory();

  function afterDeleteActionCallback() {
    history.push( '/catalog/okzScheme' ) 
  }

  return (
    <>
      <Group>
        <DeleteOrganizations
            ids={[okzId]}
            onInProgress={onInProgress}
            afterAction={afterDeleteActionCallback}
        />
      </Group>
    </>
  )
}