import React from 'react'

import { ReactComponent as CheckOn } from 'assets/img/commonVer2/checkbox_on.svg'
import { ReactComponent as CheckOff } from 'assets/img/commonVer2/checkbox_off.svg'

// to do
// delete after styleguide 2.0
export const checkboxType = {
  yesNo: 'yesNo',
  standard: 'standard'
}

function Checkbox(props) {
  const { 
    className, 
    checked = false, 
    disabled = false, 
    onChange,
    children 
  } = props

  return (
    <label 
      className={`checkbox-label ${className || ''} ${disabled ? 'checkbox-label--disabled' : ''} `}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <input 
        className='native-input'
        type='checkbox'
        onChange={onChange}
        value={checked}
      ></input>
      <div className={`checkbox-visual `}>
        {checked ? (
            <CheckOn></CheckOn>
          ) : (
            <CheckOff></CheckOff>
          )}
      </div>
      {children ? <div className="checkbox__caption">{children}</div> : null}
    </label>
  )
}

export default Checkbox