import service from "app/services/service";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const mapToViewModel = data => {
  const {
    eskziUserNewLastWeek = 0,
    eskziStandard = 0,
    eskziTotal = 0,
    eskziAvailable = 0,
    eskziSent = 0,
    eskziDelivered = 0,
    eskziInstalled = 0,
    eskziDestructed = 0,
    eskziAllowUse = 0,
    eskziExpiring = 0,
    kdTotal = 0,
    kdNotRecorded = 0,
    kdAvailable = 0,
    kdSent = 0,
    kdInstalled = 0,
    kdExpiring = 0,
    kcAvailable = 0,
    kcDelivered = 0,
    licenseTotal = 0,
    licenseUsed = 0,
    licenseSingle = 0,
    licenseExpiring = 0,
    admittanceDetainSign = 0,
    destructionActDetainSign = 0,
    coursePrescribed = 0,
    courseReadyExam = 0,
  } = data || {};

  return {
    eskziStandard,
    kdNotRecorded,
    eskziUserNewLastWeek,
    eskzi: {
      total: eskziAvailable + eskziDelivered + eskziSent,
      available: eskziAvailable,
      issued: eskziDelivered,
      moved: eskziSent,
    },
    licenses: {
      total: licenseTotal,
      available: Math.max(0, licenseTotal - licenseUsed),
      used: licenseUsed,
    },
    keyDocuments: {
      total: kdAvailable + kdSent + kdInstalled,
      available: kdAvailable,
      moved: kdSent,
      installed: kdInstalled,
    },
    waitForSign: {
      admittance: admittanceDetainSign,
      maintenance: eskziAllowUse,
      destructionCertificate: destructionActDetainSign,
    },
    expiring: {
      keyDocs: kdExpiring,
      certificates: eskziExpiring,
      licenses: licenseExpiring,
    },
    keyCarriers: {
      available: kcAvailable,
      occupied: kcDelivered,
    },
    licensesActive: {
      multiple: Math.max(0, licenseTotal - licenseSingle),
      single: licenseSingle,
    },
    activity: {
      exams: courseReadyExam,
      cources: coursePrescribed,
    },
  };
};

const defaultState = mapToViewModel(null);

export default function useDashboard () {
  const profile = useSelector(({ profile }) => profile);
  const { userInfo } = profile || {};
  const { organization } = userInfo || {};
  const { shortName, name, okz, id } = organization || {};
  const [dashboardData, setDashboardData] = useState(defaultState);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    const asyncGetData = async () => {
      setInProgress(true);
      const { data } = await service('dashboardService', 'getAllData', id);
      setDashboardData(mapToViewModel(data));
      setInProgress(false);
    };
    asyncGetData();
  }, [id]);


  return {
    dashboardData,
    inProgress,
    organization: {
      shortName, 
      name, 
      okz
    }
  }
}

