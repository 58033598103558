import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import service from 'app/services/service'
import { ReactComponent as CheckImg } from 'assets/img/commonVer2/ic_check.svg'
import { ReactComponent as TriangleAlertImg } from 'assets/img/commonVer2/ic_alert_triangle.svg'
import {dispatchLogicToasts} from '../../../../redux/actions/common'

import {sortListAction} from '../../../../redux/actions/list/listActions'
import {formatDate} from '../../../core/utility/date'
import Spinner from '../../ui/spinner'
import { sortDirection } from 'app/components/ui/constants'

const pageConfig = {
  itemsPerPage : 9999999,
  defaultSortByAlias : 'id',
  defaultSortDirection : sortDirection.desc,
  filterMultiSelect : false,
  changedFieldsVisibility : false,
  itemsSelectable : true,
  itemsSelectableRadio : false,
}

export const syncListConfig = {
  pageConfig : pageConfig,
  fieldsConfig : [
    {
      title : 'Дата запуска',
      titleInfo : 'Дата запуска',
      alias : 'dateStart',
      format : item => formatDate(item.startDate, 'dd.mm.yyyy hh:MM:ss'),
      sortByValue : item => item.startDate,
      visibleOnStart : true,
      useSorting : true,
    },
    {
      title : 'Автор',
      alias : 'author',
      sortByValue : item => item.userName,
      format : item => item.userName,
      visibleOnStart : true,
      useSorting : true,
    },
    {
      title : 'Статус',
      alias : 'status',
      sortByValue : item => item.status,
      format : (item) => {
        switch (item.status){
          case 'InProcess':{
            return (
              <>
                <Spinner />
                <span className="child-info__text child-info__sync-continue-text">
                  Идет синхронизация…
                </span>
              </>
            )
          }
          case 'Success': {
            return (
              <>
                <CheckImg className='test-connection-container-icon__success' />
                <span className="child-info__text child-info__sync-text">
                  Завершено
                </span>
              </>
            )
          }
          case 'Failed': {
            return (
              <>
                <TriangleAlertImg className='test-connection-container-icon__error' />
                <span className="child-info__text child-info__sync-text">
                  Ошибка синхронизации
                </span>
              </>
            )
          }
          default:
            return (
              <>
                <CheckImg className='test-connection-container-icon__success' />
                <span className="child-info__text child-info__sync-text">
                  Завершено
                </span>
              </>
            )
        }


      },
      visibleOnStart : true,
      useSorting : true,
    },
    {
      title : 'Результат',
      alias : 'result',
      sortByValue : item => item.resultCount ,
      format : item => {
        const {totalCount, updateCount} = item;
        return (
          <div>
            <div className="child-info__text child-info__sync-text">
              Всего: {totalCount || '0'}
            </div>
            <div className="child-info__text child-info__sync-text">
              Обновлено: {updateCount  || '0'}
            </div>
          </div>
        );
      },
      visibleOnStart : true,
      useSorting : true,
    },
    {
      title : 'Дата завершения',
      alias : 'dateEnd',
      sortByValue : item => item.endDate,
      format : item => formatDate(item.endDate, 'dd.mm.yyyy hh:MM:ss'),
      visibleOnStart : true,
      useSorting : true,
    }
  ],
}

export default function useSynchronizationBlock(props){

  const {
    onAddValues,
    setInProgressOn,
    getList,
    dispatch
  } = props

  const dispatchRedux = useDispatch()
  const { name } = useSelector(({profile}) => profile)

  const onSyncClick = () => {
    onAddValues([
      {
        "startDate": new Date(),
        "status": 'InProcess',
        "userName": name,
      }
    ])
    setInProgressOn()
    service( 'activeDirectorySettingsService', 'startSync').then(res => {
      const { data, errors, isError } = res

      dispatchLogicToasts(dispatchRedux, '', data, isError, errors)
      getList()
    })
  }

  return {
    onSyncClick
  }
}