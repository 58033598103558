import {decapitalize} from "app/core/utility/common";
import {notEmpty} from "app/core/utility/validation";

export function useActionForm(event){
  const defaultFormState = event.model.reduce((acc, cur) => {
    acc[decapitalize(cur.key)] = ''
    return acc
  }, {})

  const defaultState = {
    commonDataForm: defaultFormState,
    viewData: defaultFormState,
    inProgress: false
  }

  const validationRules = event.model.reduce((acc, cur) => {
    if(cur.required) {
      const fieldKey = cur.key
      acc[decapitalize(fieldKey)] = {
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
      }
    }
    return acc
  }, {})

  return {
    validationRules,
    defaultState
  }
}