import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import withAuth from 'app/components/HOC/AuthHOC'
import { sortDirection } from 'app/components/ui/constants'
import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as EducationImg } from 'assets/img/commonVer2/education.svg'

import ButtonComponent from 'app/components/ui/button/button'
import SimpleSearch from 'app/components/ui/search/SimpleSearch'
import DropDown from 'app/components/ui/DropDown'
import Overlay from 'app/components/ui/overlay'
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog/'
import Pagination from 'app/components/ui/pagination/pagination'
import config from 'app/core/config'

import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { YesNo } from 'app/components/ui/DropDown'
import { formatDate } from 'app/core/utility/date'

import * as trainingCoursesAction from 'redux/actions/trainingCourses/trainingCoursesAction'
import * as errorAction from 'redux/actions/common'
import withTooltip from '../HOC/TooltipHOC'
import TableWithLogic from '../list/Table/tableWithLogic';
import { SelectType } from '../ui/picker/picker';
import { arrayToIdHashMap } from 'app/core/utility/common';

const Button = withTooltip(ButtonComponent)

class component extends Component {
  constructor(props){
    super(props)

    this.pagination = React.createRef();
    this.state = this._getState(props)
  }

  _getState = props => {
    const { paginationPerPage } = config
 
    return {
      filter: { 
        name: '', 
        isActive: { id: 'all', title: 'Все' },
      }, 
      pagination: { 
        itemsPerPage: paginationPerPage,
        activePage: 1
      },
      sort: { 
        column: 'changeDate',
        direction: sortDirection.desc
      },
      columns: [
        {
          title: 'Наименование',
          alias: 'name',
          useSorting: true,
        },
        {
          title: 'Вложения',
          alias: 'materialsCount',
          useSorting: true,
        },
        {
          title: 'Активен',
          alias: 'isActive',
          useSorting: true,
          width: 240,
          format: item => {
            const { isActive } = item
            
            return isActive ? 'Да' : 'Нет'
          }
        },
        {
          title: 'Дата изменения',
          alias: 'changeDate',
          useSorting: true,
          width: 240,
          format: item => {
            const { changeDate } = item
            
            return formatDate(changeDate, 'dd.mm.yyyy')
          }
        }    
      ],
    }
  }

  componentDidMount () {
    const { trainingCourses } = this.props
    const { inProgress } = trainingCourses

    if (inProgress) {
      return
    }

    this._getPage({
      filterData: this._getFilterStateForBackend()   
    })
  }

  _getFilterStateForBackend = () => {
    const { filter } = this.state
    const { name, isActive } = filter

    const result = {
      names: [ name ],
      isActive: isActive.id === 'Yes',
    }

    if (isActive.id === 'all') {
      delete result.isActive
    }
    
    return result
  }

  _onSearch = () => {
    this._getPage({ filterData: this._getFilterStateForBackend() })
  }

  _onSearchClick = async () => {
    const { trainingCoursesSelectAction } = this.props

    await trainingCoursesSelectAction({
      items: [],
      selectedHashMap: {}
    })
    this._onSearch()
  }

  _onSort = ({column, direction}) => {
    const { pagination } = this.state
    this.setState({ 
      sort: { column, direction },
      pagination: {
        ...pagination,
        activePage: 1
      }
    }, () => {
      this._onSearch()
    })
  }

  _onSelect = data => {
    const { trainingCoursesSelectAction } = this.props
    trainingCoursesSelectAction({
      items: data,
      selectedHashMap: arrayToIdHashMap(data)
    })
  }

  _getTableOptions = () => {
    const { sort } = this.state
    const { column, direction } = sort

    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => this._onSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: this._onSelect},
      useActions: { 
        use: false, 
      },
      onRowAction: this._onCardNavigation
    }
  }

  _getPage = ({ filterData }) => {
    const { trainingCoursesGetAction, trainingCoursesInProgressAction, location } = this.props
    const { sort, pagination } = this.state
    const { activePage, itemsPerPage } = pagination
    const { column, direction } = sort
    const { pathname } = location
    trainingCoursesInProgressAction(true)
    trainingCoursesGetAction({ filterData, column, direction, page: activePage, pageSize: itemsPerPage }, pathname)
    activePage === 1 && this.pagination.current.resetActivePage()
  }

  _getCourseLink = item => {
    const { profile } = this.props
    const { id } = item;

    const courseRights = getResourceRights(profile, RESOURCES.course)
    return courseRights.VIEW_CARD ? `/catalog/trainingCourses/card/${id}` : ''
  }

  _onCancel = () => {
    const { trainingCoursesResetAction } = this.props

    trainingCoursesResetAction()
  }

  _onCreate = () => {
    const { history } = this.props

    history.push('/catalog/trainingCourses/create')
  }

  _onConfirmDelete = () => {
    const { trainingCoursesOnDeleteAction, trainingCourses } = this.props
    const { selected } = trainingCourses
    const { items } = selected

    trainingCoursesOnDeleteAction(items)
  }

  _onDelete = async () => {
    const { trainingCourses, trainingCoursesDeleteAction, trainingCoursesInProgressAction } = this.props
    const { selected } = trainingCourses
    const { items } = selected

    trainingCoursesInProgressAction(true);
    const result = await trainingCoursesDeleteAction(items)
    const { errors = [] } = result
    this._onSearch()
  }

  _onPageChanged = ({ activePage, itemsPerPage }) => {
    this.setState({pagination: { activePage, itemsPerPage }}, () => {
      this._onSearch()
    })
  }

  _onSearchValueChanged = (value) => {
    const { filter, pagination} = this.state

    this.setState({
      filter: {
        ...filter,
        name: value
      },
      pagination: {
        ...pagination,
        activePage: 1
      }
    })
  }

  _onActiveChanged = async (value) => {
    const { filter, pagination } = this.state
    const { trainingCoursesSelectAction } = this.props

    await trainingCoursesSelectAction({
      items: [],
      selectedHashMap: {}
    })

    this.setState({
      filter: {
        ...filter,
        isActive: value
      },
      pagination: {
        ...pagination,
        activePage: 1
      }
    }, () => this._onSearch())
  }

  _renderModal = () => {
    const { trainingCourses, error, clearErrorAction } = this.props
    const { confirmObject } = trainingCourses
    const { errors } = error || {}

    return (
      <Fragment>
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onSubmit={this._onDelete}
            onCancel={this._onCancel}
          /> : null
        }
        {errors.length ?
          <ConfirmDialog
            needIcon={true}
            messages={errors.map(i => i.errorMessage)}
            type={dialogType.warning}
            onCancel={clearErrorAction}
          /> : null
        }
      </Fragment>
    )
  }

  _renderManagementPanel = () => {
    const { profile, trainingCourses } = this.props
    const { filter } = this.state
    const { inProgress, selected } = trainingCourses
    const { items } = selected
    const { name, isActive } = filter
    const courseRights = getResourceRights(profile, RESOURCES.course)

    return (
      <div className="trainingCourses__manage-panel manage-panel">
        <div className="trainingCourses__buttons">
          {courseRights.CREATE ? (
            <Button 
              className='create-button'
              type='primary'
              disabled={false}
              onClick={this._onCreate}
            >
              <PlusImg className='button-image button-image--left'></PlusImg>
                <span className='button-title'>Добавить</span>
            </Button>
          ) : null}
          {courseRights.DELETE ? (
            <div className="buttons-element">
              <Button 
                tooltip={'Удалить'}
                className='delete-button'
                type='image'
                onClick={this._onConfirmDelete}
                disabled={!items.length}
              >
                <DeleteImg className='button-image'></DeleteImg>
              </Button>
            </div>
          ) : null}
        </div>
        <div className="trainingCourses__filter">
          <SimpleSearch
            className='filter-item'
            placeholder='Поиск по наименованию'
            value={name}
            onSearch={this._onSearchClick}
            onChange={this._onSearchValueChanged}
            disabled={inProgress}
            noHint
          />
          <DropDown
            items = {[{id: 'all', title: 'Все'}, ...YesNo]}
            className='filter-item'
            active = {isActive}
            onSelect= {this._onActiveChanged}
            label={'Активен'}
            noHint
            noClearIcon
          />
        </div>
      </div>
    )
  }

  render() {
    const { 
      trainingCourses,
     } = this.props
    const { columns } = this.state
    const { selected, items, inProgress, pagination: { itemsPerPage, dataCount } } = trainingCourses
    
    return (
      <section className='trainingCourses'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <div className="trainingCourses__header">
          <EducationImg className='header-icon'></EducationImg>
          Справочники: Обучающие курсы
        </div>
        {this._renderManagementPanel()}
        <TableWithLogic
          list={items}
          defaultSort={{ 
            column: 'changeDate',
            direction: sortDirection.desc
          }}
          columns={columns}
          setSelected={this._onSelect}
          setSorting={this._onSort}
          selectType={SelectType.multiple}
          onItemClick={this._getCourseLink}
        />  
        <Pagination
          ref={this.pagination}
          itemsCount={ dataCount }
          itemsPerPage={ itemsPerPage }
          onPageChange={ this._onPageChanged }
        />
      </section>
    )
  }
}

const mapStateToProps = state => { 
  
  return { ...state }
}

export default connect(
  mapStateToProps,
  { 
    ...trainingCoursesAction,
    ...errorAction,
  })(withAuth(component))