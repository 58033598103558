import {useDispatch} from "react-redux";
import Group from 'app/components/ui/managePanel/group';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { EventCode, EndpointType, ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import { Image } from 'app/components/ui/Img';
import {Delete} from "app/components/list/TopPanel/Delete";
import SendButton from 'app/components/skzi/EskziList/businessEventPanel/sendButton';
import service from 'app/services/service';
import { useHistory } from "react-router-dom";
import RecordKDButton from 'app/components/keyDocuments/list/businessEventPanel/linkButton/RecordKDButton';

const sendBusinessEvents = [
  {name: "Передача в Орган криптографической защиты"
  , eventCode: 'OkzReceiving'},
  {name: "Передача Обладателю конфиденциальной информации", eventCode: 'OkiReceiving'}
]

export default function BusinessEventPanel(props) {
  const {
    items,
    onInProgress,
    keyDocumentId,
    afterAction
  } = props

  const history = useHistory();
  const [ selectedItem ] = items || []
  const { isStandard, replicationAllowed } = selectedItem || {}
  const disabled = items.length !== 1
                    || items[0] && items[0].bizLocked
                    || !( isStandard && replicationAllowed )

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service('keyDocumentService', 'deleteRange', items.map(i => i.id));
    onInProgress(false);
    return response;
  };

  function afterDeleteActionCallback() {
    history.push( '/keyDocument/okz' ) 
  }

  const allHasChangeUser = items.length && items.every(item => {
    const { availableActions = [] } = item || {}
    return availableActions.some(i => i.code === 'ChangeUser')
  })

  const dispatch = useDispatch()
  function afterActionCallback() {
    afterAction(keyDocumentId)
  }

    return (
    <>
      <Group>
        <SendButton
          items={items}
          className='send-button'
          title='Передать'
          actionList={sendBusinessEvents}
          disabled={false}
          entityType ={ENTITY_TYPE.keyDocument}
          endpointType={EndpointType.keyDocument}
          afterAction={afterActionCallback}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturn]}
          tooltip='Вернуть'
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterActionCallback}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiDeliveryOk]}
          tooltip='Получить экземпляр'
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterActionCallback}
          Img={Image.Delivery}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiSendingOk, EventCode.okzSendingOk]}
          tooltip='Подтвердить получение'
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterActionCallback}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturnOk]}
          tooltip='Подтвердить возврат'
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterActionCallback}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiWithdrawal, EventCode.okiWithdrawalKD]}
          tooltip='Изъять'
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterActionCallback}
          Img={Image.SoftReturn}
        />
        {!allHasChangeUser ? (
          <BusinessEventTrigger
            items={items}
            eventCode={[EventCode.okiDelivery]}
            tooltip='Выдать'
            endpointType={EndpointType.keyDocument}
            entityType={ENTITY_TYPE.keyDocument}
            afterAction={afterActionCallback}
            Img={Image.SoftSending}
          />
        ) : (
          <BusinessEventTrigger
            items={items}
            eventCode={[EventCode.changeUser]}
            tooltip='Выдать другому пользователю'
            endpointType={EndpointType.keyDocument}
            entityType={ENTITY_TYPE.keyDocument}
            afterAction={afterActionCallback}
            Img={Image.SoftSending}
          />
        )}
      </Group>
      <Group>
        <RecordKDButton
          onInProgress={onInProgress}
          items={items}
          afterAction={afterActionCallback}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiInstallation]}
          tooltip='Установить'
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterActionCallback}
          Img={Image.DownloadCircle}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiInstallationKeyDocument]}
          tooltip='Ввод в аппаратное СКЗИ'
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterActionCallback}
          Img={Image.Link}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiDestruction, EventCode.okzDestruction]}
          tooltip='Уничтожить'
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterActionCallback}
          Img={Image.Extermination}
        />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction = {afterDeleteActionCallback}
          dialog ={data => {
              return `Вы уверены, что хотите удалить ключевой документ «${ data[0].name }»?`
          }}
        />
      </Group>
    </>
  )
}