/** @format */

import { FilterControlType } from 'app/components/filter/FilterElement';
import styles from './keyDocTab.module.scss';
import { sortDirection } from 'app/components/ui/constants';
import { formatDate } from 'app/core/utility/date';
import { Services } from 'app/components/ui/autocomplete/autocomplete';

const issuerConfig = userId => ({
  bookingApi: 'keyDocumentSelect/bookEvent',
  issueApi: 'keyDocumentSelect/issueEvent',
  pickerConfig: {
    pickerService: {
      serviceName: 'keyDocumentSelectService',
      serviceMethod: 'getAll',
      data: { id: userId },
    },
    serviceType: Services.keyDocument,
    modalHeader: 'Ключевые документы',
    filterCssClass: styles.keyDocTabPickerFilter,
    filterConfig: [
      {
        type: FilterControlType.simpleSearch,
        alias: 'id',
        label: '№',
        pattern: '^[0-9]*$',
        extended: false,
        order: 0,
      },
      {
        type: FilterControlType.simpleSearch,
        alias: 'serialNumbers',
        label: 'Серийный номер',
        extended: false,
        requestFormatIsArray: true,
        order: 1,
      },
      {
        type: FilterControlType.simpleSearch,
        alias: 'names',
        label: 'Наименование',
        extended: false,
        requestFormatIsArray: true,
        order: 3,
      },
    ],
    pickerColumns: {
      columns: [
        {
          title: '№',
          titleInfo: '№',
          alias: 'id',
          useSorting: true,
          width: 100,
        },
        {
          title: 'Наименование',
          alias: 'name',
          useSorting: true,
        },
        {
          title: 'Серийный №',
          alias: 'serialNumber',
          useSorting: true,
          width: 200,
        },
        {
          title: '№ экз.',
          alias: 'identificationNumber',
          useSorting: true,
          width: 200,
        },
        {
          title: 'Дата создания',
          alias: 'createDate',
          useSorting: true,
          width: 200,
          format: item => formatDate(item.createDate, 'dd.mm.yyyy'),
        },
      ],
      sort: {
        column: 'updateDate',
        direction: sortDirection.desc,
      },
    },
  },
});

export default issuerConfig;
