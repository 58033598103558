import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import { notEmpty, identicalValues } from 'app/core/utility/validation'
import service from 'app/services/service'
import policyErrorMessage from '../components/passwordPolicies'
import useToast from '../../components/ui/toast/useToast'


const localMessages = {
  expiredUpdated: {
    errorCode: "PASSWORD_CHANGED",
    errorMessage: "Пароль успешно изменен. Войдите в систему используя новый пароль",
    isError: true
  },
  wrongPassword: {
    errorCode: "E_WRONG_LOGIN_OR_PASSWORD",
    errorMessage: "Введён неверный пароль",
    isError: true
  }
}

const defaultLocalState = {
  login: '',
  password: '',
  confirmPassword: '',
  expiredPassword: '',
  loginError: null,
  passwordError: null,
  confirmPasswordError: null,
  expiredPasswordError: null,
  mailSuccess: false,
  isMailRecovery: false,
  tokenCheckResult: { checked: false, isValid: false },
  inProgress: false,
  passwordExpired: false,
}

export default function useDefaultLogin(props){
  const {
    onSubmit,
    isPasswordRecovery,
    onDomainSubmit,
    authMethods
  } = props

  const [localState, setLocalState] = useState(defaultLocalState)

  const {
    login,
    password,
    confirmPassword,
    expiredPassword,
    loginError,
    passwordError,
    confirmPasswordError,
    expiredPasswordError,
    mailSuccess,
    isMailRecovery,
    tokenCheckResult,
    inProgress,
    passwordExpired,
  } = localState

  const setInProgress = (progress) => setLocalState({
    ...localState,
    inProgress: progress
  })

  const {
    addToast,
    removeAllToasts
  } = useToast()

  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)
  const history = useHistory()


  const visibleMailRecovery = !isPasswordRecovery
    && !isMailRecovery
    && !passwordExpired

  let headerTitle = (isPasswordRecovery || passwordExpired)
    ? 'Изменение пароля'
    : isMailRecovery
      ? 'Восстановление пароля'
      : 'Вход по учётной записи'


  if (isPasswordRecovery && !tokenCheckResult.checked) {
    service('userService', 'checkResetPasswordToken', searchParams.get('token'))
      .then(result => {
        setLocalState({
          ...localState,
          tokenCheckResult: { checked: true, isValid: !result.isError },
          password: ''
        })
      })
      .catch(err => {
        setLocalState({
          ...localState,
          tokenCheckResult: { checked: true, isValid: false },
        })
      })
  }

  const onMailRecoveryClick = () => {
    setLocalState({ ...localState, isMailRecovery: true })
  }


  const onPasswordChange = value => setLocalState({...localState, password: value, passwordError: null})
  const onExpiredPasswordChange = value => setLocalState({...localState, expiredPassword: value, expiredPasswordError: null})
  const onConfirmPasswordChange = value => setLocalState({...localState, confirmPassword: value, confirmPasswordError: null})
  const onLoginChange= value => setLocalState({...localState, login: value, loginError: null})

  const onExpiredSubmit = () => {
    const passwordError =  notEmpty(password).error
    const expiredPasswordError = notEmpty(expiredPassword).error
    const confirmPasswordError = notEmpty(confirmPassword).error || identicalValues(password, confirmPassword).error

    setLocalState({...localState, passwordError, expiredPasswordError, confirmPasswordError})

    if (!passwordError
      && !expiredPasswordError
      && !confirmPasswordError ) {

      setLocalState({
        ...localState,
        inProgress: true
      })
      service('userService', 'changeExpiredPassword', { login, newPassword: password, password: expiredPassword })
        .then(result => {
          if (result.isError) {
            const serverError = result.errors[0].detail
              ? result.errors[0].detail.response.errors[0]
              : result.errors[0]
            if ( serverError.errorCode === 'E_WRONG_LOGIN_OR_PASSWORD') {
              setLocalState({
                ...localState,
                inProgress: false,
                expiredPasswordError: localMessages.wrongPassword
              })
            } else if (serverError.errorCode === 'E_VALIDATE') {
              setLocalState({
                ...localState,
                inProgress: false,
                confirmPasswordError: {
                  ...serverError,
                  errorMessage: serverError.details
                    ? policyErrorMessage(serverError.details, confirmPassword)
                    : serverError.errorMessage
                }
              })
            } else {
              setLocalState({
                ...localState,
                inProgress: false,
              })
              if(serverError.errorCode !== 'E_UNAUTHORIZED'){
                addToast(serverError.errorMessage)
              }
            }
          } else {
            onSubmit()
          }
        })
    }
  }

  const onRecoveryPasswordSubmit = () => {
    const validateLoginError = notEmpty(login).error
    setLocalState({
      ...localState,
      loginError: validateLoginError,
      inProgress: !validateLoginError
    })
    if (!validateLoginError) {
      service('userService', 'resetPasswordByLogin', login)
        .then((res) => {
          setLocalState({
            ...localState,
            mailSuccess: !res.isError,
            inProgress: false
          })
        })
    }
  }

  const onMailRecoverySubmit = () => {
    const confirmPasswordError = notEmpty(confirmPassword).error || identicalValues(password, confirmPassword).error
    const passwordError = notEmpty(password).error

    setLocalState({
      ...localState,
      passwordError,
      confirmPasswordError,
      inProgress: !confirmPasswordError && !passwordError
    })
    if (!confirmPasswordError && !passwordError) {
      service('userService', 'updatePassword', { token: searchParams.get('token'), password})
        .then(result => {
          if (result.isError) {

            const [ error ] = result.errors
            const { errorCode, details } = error || {}
            const [actualError] = error && error.detail && error.detail.response && error.detail.response.errors || [{}]
            if (actualError.errorCode === "E_LICENSE_INVALID") {
              addToast(actualError.errorMessage)
            }

            setLocalState({
              ...localState,
              isMailRecovery: !result.isError,
              password: password,
              confirmPassword: confirmPassword,
              inProgress: false,
              confirmPasswordError: errorCode === 'E_VALIDATE'
                ? {
                  ...error,
                  errorMessage: details
                    ? policyErrorMessage(details, confirmPassword)
                    : error.errorMessage
                }
                : error,
              login: '',
            })
          } else {
            onSubmit()
            removeAllToasts()
          }

          !result.isError && history.push('/')
        })
    }
  }

  const onLoginSubmit = () => {
    const loginError = notEmpty(login).error
    const passError =  notEmpty(password).error

    setLocalState({
      ...localState,
      loginError: loginError,
      passwordError: loginError ? null : passError,
      inProgress: !loginError && !passError
    })
    if (!loginError && !passError) {
      onSubmit({login, password})
        .then(res => {

          if (res.type === "GLOBAL.ERROR") {
            const passwordExpired = res.payload.detail.response.errors[0].errorCode === 'E_PASSWORD_EXPIRED'
            setLocalState({
              ...localState,
              password: passwordExpired ? '' : password,
              passwordExpired,
              inProgress: false
            })
            addToast(res.payload.detail.response.errors[0].errorMessage)
          } else if (res.type === "ERROR") {
            setLocalState({
              ...localState,
              inProgress: false
            })
            if(res.errors[0].errorCode !== 'E_UNAUTHORIZED'){
              addToast(res.errors[0].errorMessage)
            }
          }
          else {
            removeAllToasts()
          }
        })
    }
  }

  return {
    login,
    password,
    confirmPassword,
    expiredPassword,
    loginError,
    passwordError,
    confirmPasswordError,
    expiredPasswordError,
    mailSuccess,
    passwordExpired,
    tokenCheckResult,
    isMailRecovery,
    isPasswordRecovery,
    inProgress,
    headerTitle,
    visibleMailRecovery,
    onDomainSubmit,
    authMethods,
    onMailRecoveryClick,
    onExpiredSubmit,
    onPasswordChange,
    onExpiredPasswordChange,
    onConfirmPasswordChange,
    onRecoveryPasswordSubmit,
    onLoginChange,
    onMailRecoverySubmit,
    onLoginSubmit,
    setInProgress
  }
}