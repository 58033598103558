import { 
  ESKZICARDESKZICARDGETEVENTS,
  ESKZIEVENTSUPDATETASK,
  KEYDOCUMENTSUPDATETASK,
  KEYDOCUMENTCARDGETEVENTS
 } from '../../actions/actionTypes'
 import { updateCollectionByItemId } from 'app/core/utility/common'

 const defaultState = {
  events: []
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { events } = state

  switch (action.type) {
    case KEYDOCUMENTCARDGETEVENTS:
    case ESKZICARDESKZICARDGETEVENTS:
      return {
          ...state,
          events: payload,
          inProgress: false
      }

    case KEYDOCUMENTSUPDATETASK:
    case ESKZIEVENTSUPDATETASK:
      return {
        ...state, 
        events: updateCollectionByItemId(events, payload)
      }

    default: 
      return state
  }
}