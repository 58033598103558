import React, { useState, useEffect } from 'react';
import RemoveConfirmButton from './RemoveConfirmButton'
import withSignatureHOC from 'app/components/HOC/withSignatureHOC';
import service from 'app/services/service'
import {
  dispatchLogic,
} from 'redux/actions/common'
import {useDispatch} from 'react-redux'

const RemoveButton = withSignatureHOC(RemoveConfirmButton)

export default function RemoveEventButton({disabled, eskziId, eventId, afterAction, setInProgress, type}) {

  const dispatch = useDispatch()

  const getServiceName = () => {
    switch (type) {
      case 'keyDocument':
        return 'keyDocumentService'
      case 'eskzi':
        return 'skziService'
    }
  }

  const cardRemoveAction = async (requestData) => {
    setInProgress(true)
    const { data } = requestData
    const response = await service(getServiceName(), 'deleteActivity', data)
    const { isWarn, isError, errors } = response || {}
    if (!isWarn && !isError) {
      afterAction()
    }
    dispatchLogic(dispatch, 'MOCK', data, isError, errors)
    setInProgress(false)
    return response
  }

  return (
    <>
      <RemoveButton 
        disabled={disabled}
        eskziId={eskziId}
        eventId={eventId}
        actionCallback={cardRemoveAction}
        // signType='deleteActivity'
        // signHeader='Отмена мероприятия'
      />
    </>
  )
}