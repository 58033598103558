import React from 'react'
import {isActive} from './navigationContent';

const subMenuItem = ({linkPart, title, location, rootLink, onClick}) => {
  const link = `${rootLink}${linkPart}`
  const isActiveLink = isActive(location.pathname, link)

  return (
    <li className={`sub-menu__element ${isActiveLink ? 'sub-menu__element--active' : ''}`} onClick={onClick}>
      <a href={link} className='element__sub-menu-link'>
        <div className='element__sub-menu-placeholder'>
        </div>
        <span className='element__sub-menu-title'>{title}</span>
      </a>
    </li>
  )
}

export default subMenuItem;