import React, { useState } from 'react';
import PickerComponent, { SelectType, Services } from 'app/components/ui/picker/picker';
import withModal from 'app/components/HOC/ObsoleteModalHOC';
import Button from 'app/components/ui/button/button';
import { ReactComponent as ApparatSKZI } from 'assets/img/actionPanel/ic_apparat_SKZI.svg';
import withTooltip from 'app/components/HOC/TooltipHOC';
import { FilterControlType } from 'app/components/filter/FilterElement';

const Picker = withModal(PickerComponent)
const TooltipButton = withTooltip(Button)

const filterConfig = [
  {
    type : FilterControlType.simpleSearch, 
    alias : 'id', 
    label : '№',
    pattern: '^[0-9]*$',
    extended : false,
    order : 2
  },
  { 
    type : FilterControlType.simpleSearch, 
    alias : 'serialNumbers', 
    label : 'Серийный №',
    requestFormatIsArray: true,
    extended : false,
    order : 3
  },  
  { 
    type : FilterControlType.simpleSearch, 
    alias : 'factoryNumbers', 
    label : 'Заводской №',
    requestFormatIsArray: true,
    extended : false,
    order : 4
  },  
  { 
    type : FilterControlType.autocompletePicker, 
    alias : 'eskziUserId', 
    label : 'Пользователь', 
    serviceType : 'skziUser',  
    multiSelect : false,
    extended : false,
    order : 5
  },   
]

export default function LinkKeyDocButton(props) {
  const { 
    disabled = false,
    className, 
    keyDocumentId,
    isText, 
    onCancel,
    onSubmitSignEnhance,
    getSignRequestData,
    generateTextErrors
  } = props 

  const [ showPicker, setShowPicker ] = useState(false)

  const pickerService = {
    serviceName: 'keyCarrierBindingService',
    serviceMethod: 'getAll',
    data: {id: keyDocumentId}
  }

  const _onSelect = async (selected) => {
    const [first] = selected
    if (first) {
      const { id } = first
      const requestData = {
        keyCarrierId : id,
        keyInformation : [ parseInt( keyDocumentId ) ],
        signatureRequestType: 'keyCarrierRecording'
      };
      const result = await onSubmitSignEnhance(requestData)
      setShowPicker(false)
    }
  }

  return (
    <>
      {isText ? (
        <Button 
          className = { className || ''}
          type='primary'
          disabled={disabled}
          onClick = { () => setShowPicker(true) }
        >
          <span className='button-title'>Добавить связь</span>
        </Button>
      ) : (
        <TooltipButton 
          disabled={disabled}
          className = { className || ''}
          type='image'
          onClick = { () => setShowPicker(true) }
          tooltip='Запись на ключевой носитель'
        >
          <ApparatSKZI className='button-image'></ApparatSKZI>
        </TooltipButton>
      )}
      {showPicker ? (
        <Picker
          serviceType={Services.keyCarrier}
          filterConfig={filterConfig}
          notStandardService={pickerService}
          selectType={SelectType.single}
          onCancel={() => setShowPicker(false)} 
          onSelect={_onSelect}
          renderHeader="Запись на ключевой носитель"
        />
      ) : null}

    </>
  )
}
