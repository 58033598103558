/** @format */
import { ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import { Image } from 'app/components/ui/Img';

import getIssuerConfig from './issuerConfig';

const toolbarConfig = {
  issuerButtonText: 'Выдать документ',
  getIssuerConfig,
  endpointType: 'KeyDocument',
  entityType: ENTITY_TYPE.keyDocument,
  customActions: [
    [
      {
        eventCode: 'OkiWithdrawal',
        tooltip: 'Изъять',
        buttonImage: Image.SoftReturn,
        changeType: 'deleted',
      },
    ],
    [
      {
        eventCode: ['OkiInstallation'],
        tooltip: 'Установить',
        buttonImage: Image.DownloadCircle,
        changeType: 'updated',
      },
      {
        eventCode: 'InstallationKeyDocument',
        tooltip: 'Ввод в аппаратное СКЗИ',
        buttonImage: Image.Link,
        changeType: 'updated',
      },
    ],
    [
      {
        eventCode: 'OkiDestruction',
        tooltip: 'Уничтожить экземпляр',
        buttonImage: Image.Extermination,
        changeType: 'deleted',
      },
    ],
  ],
};

export default toolbarConfig;