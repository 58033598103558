import { traverseTree } from './listReducer'

const initialTraverse = (item, result) => {
  result[item.id] = {selected: false, expanded: false , item: item}
  if (item.children && item.children.length) {
    item.children.forEach(item => initialTraverse(item,result))
  }
}

export default (state = [], action, prefix = '') => {

  if( action.type === prefix + 'LIST_DESELECT_ITEM' ){
    let temp = [ ...state ];
    return temp.filter( el => action.payload !== el ); 
  }

  if( action.type === prefix + 'LIST_SET_SELECTED' ){
    return action.payload;
  }

  // to do
  // сейчас при сортировке сбрасываются выбранные элементы
  // убрать это
  // if( action.type === prefix + 'LIST_GET'){
  //   return []; 
  // }

  if ( action.type === 'LIST_TREE_GET' ) {
    const mapped = traverseTree(action.payload)

    if ( !mapped ) {
      return []
    }

    const selected = mapped.reduce((acc,cur) => {
      initialTraverse(cur, acc)
      return acc
    }, {})
    
    if (mapped && mapped.length === 1) {
      selected.root = mapped[0]
    }

    return selected
  }

  if ( action.type === 'LIST_TREE_UPDATE' ) {
    const mapped = traverseTree(action.payload)

    if (!mapped) {
      return []
    }

    const responseSelected = mapped.reduce((acc,cur) => {
      initialTraverse(cur, acc)
      return acc
    }, {})

    const merged = Object.keys(state).reduce((acc,cur) => {
      if (responseSelected[cur]) {
        acc[cur] = state[cur]
      }

      return acc    
    }, {})
    
    if (mapped && mapped.length === 1) {
      merged[mapped[0].id].expanded = true
      merged.root = mapped[0]
    }

    console.log('mapped', mapped);
    return merged
  }

  //can add only root items
  if( action.type === 'LIST_TREE_ADD_ITEMS' ){
    const selected = action.payload.reduce((acc,cur) => {
      initialTraverse(cur, acc)
      return acc
    }, {})

    return {...selected, ...state}
  }

  if( action.type === 'LIST_TREE_TOGGLE_EXPAND_ITEM' ){   
      return {...action.payload}
  }

  if( action.type === prefix + 'LIST_REMOVED_SELECTED' ){
    let temp = [ ...state ];
    return temp.filter( el => action.payload.indexOf( el ) === -1 ) 
  }

  return state;
}