import { combineReducers } from 'redux'

import card from './card/cardReducer'
import cardDisableActionPanel from './card/cardDisableActionPanelReducer.js'
import error from './errorReducer'
import breadcrumbs from './breadcrumbsReducer'
import profile from './authorizeReducer'
import skziInstance from './skziInstance/skziInstanceReducer'
import eskziCard from './skziInstance/eskziCardReducer'
import eskziRegistration from './skziInstance/eskziRegistrationReducer'
import skziRegistry from './skziRegistry/skziRegistryReducer'
import createSkzi from './skziRegistry/createSkziReducer'
import eskziCommonData from './skziInstance/commonDataReducer'
import skziCard from './skziRegistry/skziCardReducer'
import skziVersionCard from './skziRegistry/skziVersionCardReducer'
import skziBuildCard from './skziRegistry/skziBuildCardReducer'
import userAccount from './userAccount/userAccountReducer'
import createUserAccount from './userAccount/createUserAccountReducer'
import userAccountCard from './userAccount/userAccountCardReducer'
import keyDocument from './keyDocument/keyDocumentReducer'
import createKeyDocument from './keyDocument/createKeyDocumentReducer'
import keyDocumentCard from './keyDocument/keyDocumentCardReducer'
import signature from './signatureReducer'
import eskziEvents from './skziInstance/eskziEventsReducer'
import createOrganization from './organizations/createOrganizationsReducer'
import organizationCard from './organizations/card/organizationCardReducer'
import organization from './organizations/organizationReducer'
import organizationService from './organizations/card/serviceOrgReducer'
import organizationClients from './organizations/card/clientsReducer'
import user from './user/userReducer'
import userCard from './user/userCardReducer'
import createUser from './user/createUserReducer'
import passwordPolicy from './passwordPolicyReducer'
import fileUpload from './fileReducer'
import trainingCourses from './trainingCourses/trainingCoursesReducer'
import createCourse from './trainingCourses/createCourseReducer'
import courseCard from './trainingCourses/courseCardReducer'
import userSkziAdmittance from './userAccount/userSkziAdmittanceReducer'
import admittanceCard from './admittance/admittanceCardReducer'
import createAdmittance from './admittance/createAdmittanceReducer'
import selfServiceRegistry from './selfServiceRegistry/selfServiceRegistryReducer'
import licenseCard from './license/licenseCardReducer'
import createLicense from './license/createLicenseReducer'
import fileTable from './file/fileTableReducer'
import uploadLicense from './license/uploadLicenseReducer'
import maintenanceCard from './maintenance/maintenanceCardReducer'
import createMaintenance from './maintenance/createMaintenanceReducer'
import toast from "./toastReducer"
import {reducer as processingUpload} from './uploadProcessing/uploadProcessing'
import notification from './notification'
import {reducer as availableLicense} from './license/license'

const prefixWrapper = ( reducer, pre = '') => {
    const prefix = pre;
    return function( action, payload ){  return reducer(action, payload, prefix )};
}

export default combineReducers({
    notification,
    availableLicense,
    processingUpload,
    error,
    profile,
    breadcrumbs,
    skziInstance,
    eskziCard,
    skziCard,
    skziVersionCard,
    skziBuildCard,
    eskziRegistration,
    eskziCommonData,
    skziRegistry,
    createSkzi,
    userAccount,
    createUserAccount,
    userAccountCard,
    keyDocument,
    createKeyDocument,
    keyDocumentCard,
    signature,
    eskziEvents,
    createOrganization,
    organizationCard,
    organization,
    organizationService,
    organizationClients,
    user,
    userCard,
    createUser,
    passwordPolicy,
    fileUpload,
    trainingCourses,
    createCourse,
    courseCard,
    userSkziAdmittance,
    admittanceCard,
    createAdmittance,
    selfServiceRegistry,
    licenseCard,
    createLicense,
    fileTable,
    uploadLicense,
    maintenanceCard,
    createMaintenance,
    toast,
    card,
    cardDisableActionPanel
})