import Input from 'app/components/ui/Input'
import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import { notEmpty } from 'app/core/utility/validation'
import { getEskziName } from 'app/components/skzi/constants'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import DatePicker from 'app/components/ui/date'
import { CardState } from 'app/components/ui/card/cardMaterial'
import { JournalType } from 'app/components/keyDocuments/card/cardLines'
import DropDown, { YesNo } from 'app/components/ui/DropDown'
import { ORG_CARD_TYPE, SKZI_REGISRTY_CARD } from 'app/components/ui/constants'
import {OKI_CARD_TYPE} from "../../ui/constants";
import TextArea from 'app/components/ui/textArea'

export const cardLines = {

    id: {
      validation: value => {
        return true
      },
      renderEdit: props => {
        const { editValue, error } = props
  
        return (
          <Input
            error={error} 
            className='card-field eskzi-card__id'
            label = '№'
            value={editValue || 'Черновик'}
            disabled={true}
          />
        )
      },
    },
    isStandard: {
      renderEdit: props => {
        const {
          editValue, 
          onChange, 
          error, 
          cardMode,
          commonDataForm,
        } = props
        const { skziVersionId, organizationType = {} } = commonDataForm || {}
        const { isSoftwareSkzi } = skziVersionId || {}
        const disabled = cardMode === CardState.create || cardMode === CardState.edit
          ? organizationType === 'Oki' || !isSoftwareSkzi
          : false;
        
        return (
          <DropDown
            className='card-field eskzi-card__isStandard'
            items={YesNo}
            error={error}
            active={editValue ? YesNo[0] : YesNo[1]}
            label='Эталон'
            disabled={disabled}
            readOnly={cardMode === CardState.view}
            onSelect={value => onChange('isStandard', value.id === YesNo[0].id)}
          />
        )
      },
    },
    status: {
      renderEdit: props => {
        const { editValue, error } = props
        const { name = '' } = editValue || {}

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__status'
            label = 'Статус'
            value={name}
            disabled={true}
          />
        )
      },
    },
    updateDate: {
      renderEdit: props => {
        const { editValue, error } = props
  
        return (
          <DatePicker
            className='card-field eskzi-card__change-date'
            label='Дата изменения' 
            disabled={true}
            error={error}
            value={editValue} 
          />
        )
      },
    },
    skziVersionId: {
      validation: value => {
        return notEmpty(value)
      },
      renderEdit: props => {
        const { editValue, onChange, error } = props
  
        return (
          <Autocomplete 
            className='card-field eskzi-card__name'
            onEmptySearch = {true} 
            // placeholder='Выберите СКЗИ'
            label='Наименование'
            serviceType={Services.skziBuild} 
            onSelect={value => onChange('skziVersionId', value)} 
            error={error}
            value={editValue}
            renderInputValue = {getEskziName}
            renderItem={(item) => {
              const name = getEskziName(item)
              
              return <span key={name}>{name}</span>
            }}
            isRequired
          />
        )
      },
    },
    receivedFrom: {
      validation: value => {
        return notEmpty(value)
      },
      renderEdit: props => {
        const { cardState, editValue, onChange, error, cardMode } = props
        const { okz, id } = editValue || {}
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
        return (
          <Input 
            className='card-field eskzi-card__receivedFrom'
            label = 'Получено от'
            linkTarget={cardState === CardState.view && id && {targetType, id}}
            value={editValue}
            error={error}
            onChange={value => onChange('receivedFrom', value)}
            disabled={cardMode === CardState.edit}
            isRequired
          />
        )
      },
    },
    organizationType: {
      validation: value => {
        return true
      },
      renderEdit: props => {
        const {
          editValue, 
          onChange, 
          error, 
          cardMode, 
          commonDataForm 
        } = props
        const { organizationType = {}, skziVersionId } = commonDataForm || {}
        const { isSoftwareSkzi } = skziVersionId || {}
        const disabled = cardMode === CardState.create || cardMode === CardState.edit
          ? organizationType === 'Oki' || !isSoftwareSkzi
          : false;

        return (
          <DropDown
            className='card-field eskzi-card__jornal'
            items={JournalType}
            error={error}
            active={editValue === JournalType[0].id ? JournalType[0] : JournalType[1]}
            label='Учет в журнале'
            disabled={disabled}
            readOnly={cardMode === CardState.view}
            onSelect={value => onChange('organizationType', value.id === JournalType[0].id ? JournalType[0].id : JournalType[1].id)}
          />
        )
      },
    }, 
    skzi: {
      renderEdit: props => {
        const { cardState, commonDataForm, error } = props
        const { skziVersionId: allData } = commonDataForm || {}
        const { skzi, version } = allData || {}
        const { name: skziName = '' } = skzi || {}
        const { name: versionName = ''} = version || {}
        const name = skziName || versionName ? `${skziName} ${versionName}` : ''

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__product'
            label = 'Продукт'
            linkTarget={cardState === CardState.view && skzi && skzi.id && {targetType: SKZI_REGISRTY_CARD, id: skzi.id}}
            value={name}
            disabled={true}
          />
        )
      },
    },

    version: {
      renderEdit: props => {
        const { error, commonDataForm } = props
        const { skziVersionId } = commonDataForm || {}
        const { version } = skziVersionId || {}
        const { name: versionName  = ''} = version || {}

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__version'
            label = 'Версия'
            value={versionName}
            disabled={true}
          />
        )
      },
    },   
    transmittalLetterNumber: {
      validation: value => {
        return notEmpty(value)
      },
      renderEdit: props => {
        const { editValue, onChange, error, cardMode } = props
  
        return (
          <Input 
            className='card-field eskzi-card__transmittalLetterNumber'
            label = 'Номер сопроводительного письма'
            value={editValue}
            error={error}
            onChange={value => onChange('transmittalLetterNumber', value)}
            disabled={cardMode === CardState.edit}
            isRequired
          />
        )
      },
    },
    transmittalLetterDate: {
      validation: value => {
        return notEmpty(value)
      },
      renderEdit: props => {
        const { editValue, onChange, error, cardMode } = props
  
        return (
          <DatePicker
            className='card-field eskzi-card__transmittalLetterDate'
            label = 'Дата сопроводительного письма'
            value={editValue} 
            error={error}
            onChange={value => onChange('transmittalLetterDate', value)}
            disabled={cardMode === CardState.edit}
            isRequired
          />
        )
      },
    },
    build: {
      renderEdit: props => {
        const { error, commonDataForm } = props
        const { skziVersionId } = commonDataForm || {}
        const { build } = skziVersionId || {}
        const { name: buildName = ''} = build || {}

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__build'
            label = 'Сборка'
            value={buildName}
            disabled={true}
          />
        )
      },
    },
    comment: {
      renderEdit: (props) => {
        const { cardState, editValue, onChange, error } = props
  
        return (
          <TextArea
            className='card-field eskzi-card__description'
            value={editValue} 
            label='Примечание'
            readOnly={cardState === CardState.view}
            error={error}
            onChange={value => onChange && onChange('comment', value)}
          />
        )
      },
    }, 
    serialNumbers: {
      renderEdit: props => {
        const { editValue = [], onChange, error } = props

        return (
          <ExpandingBlock
            className='card-field eskzi-card__serialNumbers'
            renderHeader= {() => 'Номера элементов поставки'}
            initialState={true}
          >
            {editValue.map(item => {
              const { number, objectType, id, main } = item
              const { name } = objectType || {}

              return (
                <Input
                  error={main ? error : null} 
                  key={id}
                  className='card-field eskzi-card__serialNumber'
                  label = {`${name}${main ? ' (Основной)' : ''}`}
                  value = {number}
                  onChange={value => {  
                      onChange('serialNumber', {id, value})
                    }
                  }
                />
              )
            })}
          </ExpandingBlock>
        )
      },
    }, 
  }
