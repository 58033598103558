import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  LICENSECREATE,
  SIGNATUREREADYTOSIGN,
  LICENSECREATEUPDATEFORMDATA,
  LICENSECREATEINPROGRESS    ,
  LICENSECREATERESETACTION   ,
  LICENSECREATECLEARFORM,
  LICENSECREATEINITFORMDATA
} from '../../actions/actionTypes'

const defaultState = {
  formData : {
    skziVersionId: '',
    key: '',
    amount: '',
    expirationDate: null,
    type: null,
    comment: ''
  },
  errorByFields: {},
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { formData } = state
    
  switch (action.type) {

    case LICENSECREATE:
      return {
          ...state,
          inProgress: false
      }
        
    case SIGNATUREREADYTOSIGN:
    case FIELDSERROR:
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData
      }

    case LICENSECREATEINITFORMDATA: 

      return {
        ...state,
        formData: { ...formData, ...payload }
      }

    case LICENSECREATEUPDATEFORMDATA: 
      const {key, value } = payload

      return {
        ...state,
        formData: {
          ...formData,  
          [key]: value
        }
      }

    case LICENSECREATECLEARFORM: 
      return {
        ...defaultState
      }

    case LICENSECREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case LICENSECREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        error: null
      }

    default: 
      return state
  }
}