export const useCircleProgressData = ({ filesCount = 0, percentage = 0 }) => {
    const isError = false;

    const radius = (28 - 2) / 2;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - dashArray * parseInt(percentage, 10) / 100;
    const count = filesCount;

    return {
        count,
        isError,
        radius,
        dashArray,
        dashOffset
    };
};