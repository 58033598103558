/** @format */
import { Border, ManagePanel, Group } from 'app/components/ui/managePanel';
import BlockCAUser from '../../blockCAUser';
import UnblockCAUser from '../../unblockCAUser';

export default function ManagementPanel({ rights, items }) {
  return (
    <ManagePanel borders={Border.All}>
      {rights.UPDATE && (
        <>
          <Group>
            <BlockCAUser items={items}/>
          </Group>
          <Group>
            <UnblockCAUser items={items}/>
          </Group>
        </>
      )}
    </ManagePanel>
  );
}

