/** @format */
import { useEffect, useState } from 'react';
import { sortDirection } from '../../../ui/constants';
import useCertificationBlockConfig from '../useCertificationBlockConfig';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import service from '../../../../services/service';
import { dispatchLogic } from '../../../../../redux/actions/common';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';

export default function useCertificationBlock({ setChanged }) {
  const { sorting, selectedItems, selectedObjects, setSelectedAction, setSelectedObjects, sortListAction } =
    useTableExtensions({
      defaultSort: { column: 'main', direction: sortDirection.desc },
    });

  const [certificationsTypes, setCertificationsTypes] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [currentlyDeletingFiles, setCurrentlyDeletingFiles] = useState({});
  const buildColumns = useCertificationBlockConfig({ currentlyDeletingFiles, onFileDelete });
  const { skziId, versionId, buildId } = useParams();
  const dispatch = useDispatch();

  function onFileDelete(guid, id) {
    setCurrentlyDeletingFiles(prevFiles => ({
      ...prevFiles,
      [id]: prevFiles[id] ? { ...prevFiles[id], [guid]: guid } : { [guid]: guid },
    }));
    return service('versionService', 'deleteBuildCertificateFile', { skziId, versionId, buildId, guid, id }).then(
      response => {
        setChanged({ deletedFile: { guid, id } });
      }
    );
  }

  useEffect(() => {
    service('versionService', 'getCertificatesTypes').then(response => {
      const { data, errors, isError } = response;
      setCertificationsTypes(data);
      dispatchLogic(dispatch, 'MOCK', data, isError, errors);
      return response;
    });
  }, []);

  const fileUploaded = res => {
    const { item = {}, data = {}, certId } = res || {};
    const { result = {} } = data;

    setChanged({ addedFile: { id: item.id || certId, fileResults: [{ ...data, ...result }] } });
  };

  return {
    tableLogic: {
      sorting,
      selectedItems,
      selectedObjects,
      setSelectedAction,
      setSelectedObjects,
      sortListAction,
    },
    inProgress,
    setInProgress,
    buildColumns,
    certificationsTypes,
    onFileUploaded: fileUploaded,
  };
}
