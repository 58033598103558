import React from 'react'

export default function Marked({text, search}) {
  if (!text) {
    return null
  }

  if( !search ){
    return text;
  }

  const str = text.replace(new RegExp(`${search}`, 'ig'), str => `<mark>${str}</mark>`);

  return <div className='marked-text' dangerouslySetInnerHTML = {{ __html: str }} />;
}