export const formatDate = (ISODate: (string | Date), format: string): string => {
  if (!ISODate) {
    return ''
  }
  
  const date: Date = new Date(ISODate)
  if (isNaN(date.valueOf())) {
    return ''
  }
  
  const year: number = date.getFullYear()
  const day: number = date.getDate()
  const month: number = date.getMonth() + 1
  const hours: number = date.getHours()
  const minutes: number = date.getMinutes()
  const seconds: number = date.getSeconds()

  return format
    .replace('dd', `${day < 10 ? '0' : ''}${day}` )
    .replace('mm', `${month < 10 ? '0' : ''}${month}` )
    .replace('yyyy', `${year}` )
    .replace('MM', `${minutes < 10 ? '0' : ''}${minutes}` )
    .replace('hh', `${hours < 10 ? '0' : ''}${hours}` )
    .replace('ss', `${seconds < 10 ? '0' : ''}${seconds}` )
}