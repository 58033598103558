/** @format */

import React, { useEffect, useState } from 'react';
import Item from './navItem';
import { applyProfileToMenu } from 'app/core/auth/auth';
import { api } from 'app/services/service';
import Img, { Image } from 'app/components/ui/Img';

import { withRouter } from 'react-router';
import { isActive } from './navigationContent';

const defaultLocalState = {
  activeSubmenu: '',
  hovered: false,
};

const onSubmenuToggle = props => {
  const { name, setLocalState, localState } = props;
  const { activeSubmenu } = localState;

  setLocalState({ ...localState, activeSubmenu: activeSubmenu === name ? '' : name });
};

const NavContainer = props => {
  const { profile, location, onMenuToggle, menuOpened } = props;
  const [localState, setLocalState] = useState(defaultLocalState);
  const { activeSubmenu, hovered } = localState;
  const cssClass = `nav-container nav-container--${menuOpened ? 'expanded' : 'collapsed'}`;
  const currentUserMenu = applyProfileToMenu(profile);
  const currentActiveMenu = currentUserMenu.find(cur => isActive(location.pathname, cur.link));
  const { name: activeMenuName = '' } = currentActiveMenu || {};
  const [version, setVersion] = useState();
  useEffect(() => {
    const fetchVersionInfo = async () => {
      const result = await api('systemVersion/getShortInfo');
      const { data: { version } = {} } = result;
      setVersion(version);
    };

    fetchVersionInfo();
  }, []);

  useEffect(() => {
    if (currentActiveMenu && menuOpened) {
      setLocalState({ ...localState, activeSubmenu: activeMenuName });
    }

    if (!activeMenuName) {
      setLocalState({ ...localState, activeSubmenu: '' });
    }
  }, [menuOpened, activeMenuName]);

  return (
    <>
      <div className={cssClass}>
        <div className="menu-content">
          <div className="nav-wrapper">
            <ul className="nav-menu">
              {currentUserMenu.map((item, index) => (
                <Item
                  key={index}
                  {...item}
                  menuOpened={menuOpened}
                  menuHovered={hovered}
                  location={location}
                  activeSubmenu={activeSubmenu}
                  onSubmenuToggle={name => onSubmenuToggle({ name, localState, setLocalState })}
                  onMenuToggle={onMenuToggle}
                />
              ))}
            </ul>
          </div>
          <div className="version">
            <a href="#/about" className="about-link" onClick={onMenuToggle}>
              <Img img={Image.Question} /> Система (ядро v.{version})
            </a>
          </div>
        </div>
      </div>
      <div
        className={`main-menu-background main-menu-background--${menuOpened ? 'opened' : 'closed'}`}
        onClick={onMenuToggle}
      ></div>
    </>
  );
};

export default withRouter(NavContainer);
