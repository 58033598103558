import { 
BREADCRUMBSPUSHITEM,
BREADCRUMBSPOPITEM,
TRAININGCOURSECARDTYPEADD,
TRAININGCOURSECARDONTYPEADD,

TRAININGCOURSECARDGET,
TRAININGCOURSECARDUPDATE,
TRAININGCOURSECARDUPDATEFORMDATA,
TRAININGCOURSECARDINPROGRESS,
TRAININGCOURSECARDRESETACTION,
TRAININGCOURSECARDGETTYPES ,    
TRAININGCOURSECARDONSELECT,
TRAININGCOURSECARDONTYPEDELETE,
TRAININGCOURSECARDTYPEDELETE,
TRAININGCOURSECARDSETMAINTYPE,
TRAININGCOURSECARDONFILEADD,
TRAININGCOURSECARDFILEDELETE,
TRAININGCOURSECARDONFILEDELETE

} from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/navigation/breadcrumbs'

export const getCourseAction = ({id, pathname}) => dispatch => {
  service('courseService', 'getCourse', id)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, TRAININGCOURSECARDGET, data, isError, errors)
    })
    .then(action => {
      const { payload } = action
      const { name } = payload || {}

      return dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: getPageBreadcrumb(pathname, name)
      })
    })
}

export const courseCardUpdateAction = (data) => dispatch => {
  const { pathname, ...requestParams } = data;
  service('courseService', 'updateCourse', requestParams)
    .then(response => {
      const { data, errors, isError } = response;
      dispatchLogic(dispatch, TRAININGCOURSECARDUPDATE, data, isError, errors)
      const { name } = data || {}
      dispatch({
        type: BREADCRUMBSPOPITEM,
        payload: {}
      })
      dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: getPageBreadcrumb(pathname, name)
      })
    })
}

export const courseCardOnSelect = selectedData => dispatch => {
  dispatch({
    type: TRAININGCOURSECARDONSELECT,
    payload: selectedData
  })
}

export const courseCardOnTypeDelete = selectedData => dispatch => {
  dispatch({
    type: TRAININGCOURSECARDONTYPEDELETE,
    payload: selectedData
  })
}

export const courseCardAddTypeAction = (data) => dispatch => {
  return service('courseService', 'addObjectTypeToCourse', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, TRAININGCOURSECARDTYPEADD, data, isError, errors)
    })
} 

export const courseCardRemoveTypesAction = (data) => dispatch => {
  return service('courseService', 'removeObjectTypeFromCourseRange', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, TRAININGCOURSECARDTYPEDELETE, data, isError, errors)
    })
} 

export const courseCardGetTypesAction = (data) => dispatch => {
  return service('courseService', 'getAllCourseTypes', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, TRAININGCOURSECARDGETTYPES, data, isError, errors)
    })
} 


export const courseCardResetAction = () => dispatch => {
  dispatch({
    type: TRAININGCOURSECARDRESETACTION,
    payload: { 
    }
  })
}

export const courseCardInProgress = isInProgress => dispatch => {
  dispatch({
    type: TRAININGCOURSECARDINPROGRESS,
    payload: isInProgress
  })
}

export const courseCardOnAddTypeAction = () => dispatch => {
  dispatch({
    type: TRAININGCOURSECARDONTYPEADD,
    payload: null
  })
}

export const courseCardDeleteFile = (data) => async dispatch => {
  const { guid, typeElement } = data
  
  await dispatch({
    type: TRAININGCOURSECARDONFILEDELETE,
    payload: {guid, typeElement}
  })

  return await service('courseService', 'deleteCourseTypeFile', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, TRAININGCOURSECARDFILEDELETE, { guid, typeElement }, isError, errors)
    })
}

export const courseCardOnAddFilesAction = (formData) => dispatch => {
  dispatch({
    type: TRAININGCOURSECARDONFILEADD,
    payload: { 
      ...formData
    }
  })
}


export const courseCardUpdateCommonForm = (formData) => dispatch => {
  dispatch({
    type: TRAININGCOURSECARDUPDATEFORMDATA,
    payload: { 
      ...formData
    }
  })
}