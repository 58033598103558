import React from 'react'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import {useHistory} from 'react-router-dom'
import FunctionalCard from '../../ui/card/FunctionalCard'
import UserForm from './UserForm'
import useCreateUserBlockData from '../hooks/useCreateUserBlockData'
import useFunctionalCard from '../../ui/card/FunctionalCard/useFunctionalCard'
import useCreateUserBlockControl from '../hooks/useCreateUserBlockControl'

export default function NewUserCreate(props){

  const history = useHistory()

  const {
    formData,
    get,
    update,
    createCallback
  } = useCreateUserBlockData()

  const {
    actions,
    items,
    rights,
    onBack
  } = useCreateUserBlockControl()

  const {
    cardState,
    editState,
    setState,
    inProgress,
    fieldsError,
    commonDataForm,
    onSubmit,
    submitEnabled,
    onFormChange,
  } = useFunctionalCard({formData, get, update, updateCallback: createCallback, parentEditState: true})


  return (
    <>
      <section className="user-card">
        <BreadcrumbsHeader customItems={{items}} history={history}/>
        <div className="user-card-area">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            {inProgress ? <Overlay /> : null}
            <FunctionalCard
              showButtons={true}
              actions={actions}
              cardState={cardState}
              canEdit={rights.UPDATE}
              editOnly={false}
              onSubmit={onSubmit}
              submitEnabled={submitEnabled}
              submitTitle="Сохранить"
              onCancelForm={onBack}
              setState={setState}
              className="ad-settings-data-card"
              editState={editState}
              cancelEnabled={true}
            >
              <div className="card-material__content">
                <UserForm
                  cardState={cardState}
                  formData={commonDataForm}
                  serverFormViewModel={null}
                  onChange={onFormChange}
                  errorByFields={fieldsError}
                />
              </div>
            </FunctionalCard>
          </ExpandingBlock>
        </div>
      </section>
    </>
  )
}