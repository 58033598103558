/** @format */
import { Table } from 'app/components/list/Table/Table';
import Overlay from 'app/components/ui/overlay';
import { sortDirection } from 'app/components/ui/constants';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import { useJournal } from 'app/components/journals/useJournal';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from 'app/components/ui/managePanel';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from 'app/components/ui/MainListContainer/MainListContainer';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import Filter from 'app/components/filter';
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import { fieldsConfig, filtersConfig } from './pageConfig';
import DownloadFileButton from 'app/components/downloadFileButton';
import CreateRequest from 'app/components/certificateRequest/createRequest';
import styles from './list.module.scss';

export default function Certificates() {
  const {
    baseTableLogic,
    itemSelectionLogic,
    objectSelectionLogic,
    paginationLogic,
    sortingLogic,
    filterLogic,
    fieldSelectionLogic,
    filterClearLogic,
    profile,
    rights,
    inProgress,
    setSelectedPagination,
    selectedObjects,
    refreshPage,
    toggleFilterState,
    fieldsVisible,
    filterOpened
  } = useJournal({
    defaultSort: {
      column: 'creationDate',
      direction: sortDirection.desc,
    },
    serviceName: 'certificateService',
    resource: RESOURCES.certRequest,
  });

  const downloadFilesIds = selectedObjects.map(i => i.id).join('&sn=');
  const downloadFilesUrl = `api/v1/cryptopro/certificates/download?sn=${downloadFilesIds}`;

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title="Ключевые документы : Мои сертификаты" Img={imgMap.KeyDocument} />
      <ManagePanel borders={Border.All}>
        {rights.CREATE && (
          <Group>
            <CreateRequest afterCreationCallback={refreshPage} />
          </Group>
        )}
        <Group>
          <DownloadFileButton url={downloadFilesUrl} disabled={!selectedObjects.length} />
        </Group>
        <Group separate>
          <FilterTriggerPanel
            {...{
              ...filterLogic,
              ...filterClearLogic,
            }}
            toggle={toggleFilterState}
          />
        </Group>
        <Group separate>
          <TableFieldSelect
            {...fieldSelectionLogic}
            listFieldsVisible={fieldsVisible}
            fieldsConfig={fieldsConfig}
            changedFieldsVisibility
            settingsAsGear
          />
        </Group>
      </ManagePanel>
      <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.twoRowFilter}
      >
        <Filter
          className={styles.filter}
          compactClassName={styles.compactFilter}
          {...{
            ...filterLogic,
            profile,
            inProgress,
            filtersConfig,
          }}
        />
        <Table
          {...{
            ...baseTableLogic,
            ...itemSelectionLogic,
            ...objectSelectionLogic,
            ...sortingLogic,
            setSelectedPagination,
            inProgress,
            fieldsConfig,
            itemsSelectable: true,
            headerInfo: true,
            onItemClick: item => `/keyDocument/certificates/card/${item.id}`,
          }}
        />
      </FilterWithTableContainer>
      <Pagination {...paginationLogic} />
    </MainListContainer>
  );
}

