/** @format */

import React from 'react';

import { stringPlural } from 'app/core/utility/common';
import { percentage } from 'app/core/utility/common';
import Doughnut from 'app/components/ui/charts/Doughnut/Doughnut';

import styles from './KeyDocChart.module.scss';
import { oneHundredPercentMagic } from '../chartLegend';

export default function KeyDocChart({ available, installed, moved, total }) {
  let activePercent = percentage(total, available);
  let installedPercent = percentage(total, installed);
  let movedPercent = percentage(total, moved);

  [activePercent, movedPercent, installedPercent] = oneHundredPercentMagic(
    activePercent,
    movedPercent,
    installedPercent
  );

  return (
    <Doughnut
      vm={[
        {
          r: 122,
          width: 24,
          className: styles.subchart,
          invalidChartClassName: styles.arcInvalid,
          gap: 0.03,
          items: [
            {
              text: `${activePercent}% Активных: ${available}`,
              value: available,
              className: styles.arcAvailable,
            },
            {
              text: `${installedPercent}% Установлено: ${installed}`,
              value: installed,
              className: styles.arcInstalled,
            },
            {
              text: `${movedPercent}% Передано в организации: ${moved}`,
              value: moved,
              className: styles.arcMoved,
            },
          ],
        },
      ]}
      width={268}
      height={268}
      className={styles.chart}
    >
      <circle cx="134" cy="134" r="90" stroke="#fafafa" strokeWidth="9" fill="none" />
      <circle cx="134" cy="134" r="78" fill="#fafafa" />
      <text
        x="134"
        y="120"
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.chartTextAccent}
      >
        {total}
      </text>
      <text
        x="134"
        y="155"
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.chartTextCommon}
      >
        {stringPlural(total, ['ключевой', 'ключевых', 'ключевых'])}
      </text>
      <text
        x="134"
        y="175"
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.chartTextCommon}
      >
        {stringPlural(total, ['документ', 'документа', 'документов'])}
      </text>
    </Doughnut>
  );
}
