/** @format */

import React from 'react';

import { getClassName } from 'app/core/utility/jsx';

import styles from './Tooltip.module.scss';

export default function Tooltip({ tooltip, position, wrap }) {
  const isAbsolutelyPositioned = position && (position.left || position.top || position.right || position.bottom);
  return (
    <div
      className={getClassName(
        [],
        [
          'tooltip',
          {
            [`tooltip${position}`]: typeof position === 'string',
            tooltipAbsolute: isAbsolutelyPositioned,
            tooltipWrap: wrap
          },
        ],
        styles
      )}
      style={isAbsolutelyPositioned && position}
    >
      {tooltip}
    </div>
  );
}
