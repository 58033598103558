/** @format */

import React from 'react';
import Img, { Image } from 'app/components/ui/Img';

import styles from './SpinnerLocal.module.scss';

const SpinnerLocal = ({ className }) => {
  return <Img img={Image.Loading} className={`${styles.spinner} ${className}`} />;
};

export default SpinnerLocal;
