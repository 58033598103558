/** @format */

import React from 'react';
import styles from './info.module.scss';
import { getClassName } from 'app/core/utility/jsx';
import { AccentText, Header, StyledNumber } from '../typography';
import Paper from 'app/components/ui/paper';

export default function InfoWidget({ className, headerText, dataRows }) {
  return (
    <Paper className={className}>
      <div className={getClassName(['dashboard-info', styles.info])}>
        <Header>{headerText}</Header>
        {dataRows.map(item => <SimpleInfoElement key={item.title} {...item} />)}
      </div>
    </Paper>
  );
}

export function SimpleInfoElement({ className, title, data, reference, regularStyleData = false }) {
  return (
    <a href={reference} className={getClassName([className, styles.infoElement])}>
      <AccentText>{title}</AccentText>
      {regularStyleData ? <AccentText>{data}</AccentText> : <StyledNumber>{data}</StyledNumber>}
    </a>
  );
}