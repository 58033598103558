import { connect } from 'react-redux'

import MainCard, { cardMode, objectKey } from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import { createLines } from './cardLines'
import { formatDate } from 'app/core/utility/date'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import withCheckLicenses from "../../HOC/withCheckLicenses";
import { useHistory } from 'react-router-dom'

export const keyDocumentObject = (data) => {
  const { 
    name,
    hardwareNumber,
    serialNumber,
    identificationNumber,
    sentTo,
    receivedFrom,
    transmittalLetterNumber,
    transmittalLetterDate,
    comment,
    expirationDate,
    organizationType,
    keyCarrier,
    okz
  } = data || {}
  const { id: keyCarrierId } = keyCarrier || {}

  return {
    receivedFrom: receivedFrom,
    transmittalLetterNumber: transmittalLetterNumber,
    transmittalLetterDate: formatDate(transmittalLetterDate, 'yyyy-mm-dd'),
    expirationDate: formatDate(expirationDate, 'yyyy-mm-dd'),
    serialNumber: serialNumber,
    comment: comment,
    name: name,
    organizationType: organizationType,
    identificationNumber: identificationNumber,
    sentTo: sentTo,
    hardwareNumber: hardwareNumber,
    keyCarrierId: keyCarrierId,
    okz
  }
}

export const cardConfig = ({ 
    history,
    registerKeyDocument,
    initKeyDocumentCreate,
    breadcrumbsPopAction,
    isOkz
  }) => ({
  cardLines: createLines,
  getKey: data => objectKey(data, keyDocumentObject),
  resource: RESOURCES.keyDocument,
  signatureHeaderText: 'Создание ключевого документа',
  signatureServiceName: '',
  oldSignatureService: true,
  signatureEntity: 'RegisterKd',
  updateAction: registerKeyDocument,
  cardInitAction: initKeyDocumentCreate,
  onCancel: () => {history.goBack()},
  afterUpdate: (requestResult) => {
    const { payload, type } = requestResult || {}
    const { id } = payload || {}

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction()  
      history.push(`/keyDocument/${isOkz ? 'okz' : 'oki'}/card/${id}`)
    }
  },
  getSignatureData: keyDocumentObject,
  pageBreadcrumbTitle: () => null,
})

const CreateCard = (props) => {
  const { 
    registerKeyDocument,
    initKeyDocumentCreate,
    breadcrumbsPopAction,
    isOkz
  } = props
  
  const history = useHistory();
  const { location } = history;

  return (
    <section className='main-card key-document-card--create'>
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={'Информация'} 
        onTabClick={null}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              location={location}
              config={cardConfig({
                history,
                registerKeyDocument,
                initKeyDocumentCreate,
                breadcrumbsPopAction,
                isOkz
              })}
              cardMode={cardMode.create}
              editOnOpen={true}
            > 
            </MainCard>
          </ExpandingBlock>
          <ExpandingBlock
            renderHeader= {() => 'Ключевой носитель'}
            disabled={true}
          >
          </ExpandingBlock>
        </section>
        <div
          tabTitle={'Мероприятия'}
          disabled={true}
        />
        <div
          tabTitle={'История'}
          disabled={true}
        />
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card}),
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(withCheckLicenses(CreateCard))