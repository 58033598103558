export const defaultOrganizationFormState = {
  commonDataForm : {
    organizations: []
  },
  viewData: {
    organizations: []
  },
  inProgress: false
}

export const defaultRolesFormState = {
  commonDataForm : {
    roles: []
  },
  viewData: {
    roles: []
  },
  inProgress: false
}

export const defaultMappingFormState = {
  commonDataForm : {
    name: '',
    surname: null,
    patronymic: '',
    email: '',
    snils: '',
  },
  viewData: {
    name: '',
    surname: null,
    patronymic: '',
    email: '',
    snils: '',
  },
  inProgress: false
}

export const defaultAuthenticationFormState = {
  commonDataForm : {
    serverAddress: '',
    serverPort: null,
    searchBase: '',
    serverAccount: '',
    serverPassword: '',
    refreshPeriod: null,
    dropSessionPeriod: null,
  },
  viewData: {
    serverAddress: '',
    serverPort: null,
    serverAccount: '',
    serverPassword: '',
    refreshPeriod: null,
    dropSessionPeriod: null,
  },
  inProgress: false
}