import { useEffect, useRef, useState } from "react";
import Autocomplete, { renderInputValue, renderItem, Services } from "../ui/autocomplete/autocomplete";
import styles from "./filter.module.scss";
import FilterElement, { FilterControlType } from "./FilterElement";
import FilterFull from "./FilterFull";
import FilterCompact from "./FilterCompact";
import { RESOURCES } from "app/core/auth/resourceByPage";
import { resetDependsFilters } from "app/core/utility/common";
import { getResourceRights } from "app/core/auth/auth";
import Button from "../ui/button/button";
import { getClassName } from "app/core/utility/jsx";
import FilterManagePanel from "./FilterManagePanel";
import VolumeAutocomplete from "./VolumeAutocomplete";
import { mapToValueForRequest } from "../list/Filters/useListFilter";
import SmallFilter from "./SmallFilter";
import useDependencies from "app/hooks/useDependencies";

export const GlobalOrgType = {
  okzOnly: 'okzOnly',
  okiOnly: 'okiOnly',
  all: 'all' 
}

const getGlobalOrgApi = type => {
  switch (type) {
    case GlobalOrgType.okiOnly:
      return {
        serviceName: "organizationService",
        serviceMethod: "getServedByUserAutocomplete",
      };
    case GlobalOrgType.okzOnly:
    case GlobalOrgType.all:
    default:
      return null;
  }
}

const getGlobalOrgServiceType = type => {
  switch (type) {
    case GlobalOrgType.okzOnly:
      return Services.organizationOkzOnly
    case GlobalOrgType.okiOnly:
    case GlobalOrgType.all:
    default:
      return Services.organizations;
  }
}

const getGlobalOrgTitle = type => {
  switch (type) {
    case GlobalOrgType.okzOnly:
      return 'ОКЗ'
    case GlobalOrgType.okiOnly:
    case GlobalOrgType.all:
    default:
      return 'Организация';
  }
}

const getContainer = (isModalFilter, isOpened) => {
  if (isModalFilter) {
    return SmallFilter
  }

  return isOpened ? FilterFull : FilterCompact
}

export default function Filter({
  className,
  compactClassName,
  filtersConfig,
  inProgress,
  setFilterStateAction,
  setFilterForRequestAction,
  setFullFilterStateAction,
  filterState,
  // filterFilled,
  globalOrgId,
  gridFilterUid,
  profile,
  isOpened = false,
  globalOrgPosition = 3,
  setGlobalOrg,
  globalOrganization,
  toggleFilterState,
  withVolume = false,
  withGlobalOrg = false,
  globalOrgType,
  isModalFilter = false,
  selectedVolume,
  onVolumeChange
}) {
  const [volumeRights, setVolumeRights] = useState({});
  const callbackRef = useRef(null);
  const { dependencies, setDependencyValue } = useDependencies();
  const _changeAndSearch = (item) => {
    if (!item) {
      _onSearch(filterState);
      return;
    }
    _onChange(item, _onSearch);
  };

  const _onChange = (item, callback) => {
    if (callback) {
      callbackRef.current = callback;
    }
    resetDependsFilters(filtersConfig, setFilterStateAction, item);
    setFilterStateAction(item);
  };

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(filterState);
      callbackRef.current = null;
    }
  }, [filterState]);

  const _onSearch = (newState) => {
    const valueForRequest = mapToValueForRequest(newState);
    setFilterForRequestAction(valueForRequest);
  };

  useEffect(() => {
    if (profile) {
      setVolumeRights(getResourceRights(profile, RESOURCES.volumeRights));
    }
  }, [profile]);

  const volume = (
    <VolumeAutocomplete
      gridFilterUid={gridFilterUid}
      className='volume-autocomplete volume'
      selectedVolume={selectedVolume}
      setSelectedVolume={(value) => {
        onVolumeChange(value);
        
        callbackRef.current = _onSearch;
        setFullFilterStateAction(value ? getFilters(value.value) : []);
      }}
      onDeleteSelectedVolume={
        volumeRights.DELETE &&
        (() => {
          onVolumeChange(null);
          callbackRef.current = _onSearch;
          setFullFilterStateAction([]);
        })
      }
    >
      {!isOpened && volumeRights.CREATE && (
        <div className='autocompete-item__create' key='create'>
          <Button type='secondary' onMouseDown={toggleFilterState}>
            <span className='button-title'>Создать том</span>
          </Button>
        </div>
      )}
    </VolumeAutocomplete>
  );

  const updateGlobalOrg = org => {
    setDependencyValue('globalOrgId', org?.id ?? null);
    setGlobalOrg(org);
  };
  
  useEffect(() => { setDependencyValue('globalOrgId', globalOrganization?.id ?? null); }, []);
  const globalOrg = (
    <Autocomplete
      label={getGlobalOrgTitle(globalOrgType)}
      className={"globalOrg"}
      serviceType={getGlobalOrgServiceType(globalOrgType)}
      notStandardService={getGlobalOrgApi(globalOrgType)}
      renderItem={renderItem.organizations}
      renderInputValue={renderInputValue.organizations}
      onSelect={updateGlobalOrg}
      value={globalOrganization}
      noHint
    />
  );

  const orderedFilters = filtersConfig.sort((a, b) => (a.order ?? 999) - (b.order ?? 999));
  const currentFilters = isOpened ? orderedFilters : orderedFilters.filter((i) => !i.extended)
  
  const filterComponents = currentFilters.map((el, i) => {
    const filterValue = filterState.find((f) => f.alias === el.alias);
    const value =
      [FilterControlType.multiselect, FilterControlType.unselectAllMultiselect].includes(el.type)
        ? filterValue
          ? filterValue.value
          : filterValue
        : filterValue;
    const dependsValue = el.dependsOn && filterState.find((f) => f.alias === el.dependsOn);

    const dependencyService = {
      serviceName: Services[el.serviceType],
      serviceMethod: "autocompleteDependsOn",
      data: {
        dependsValue,
      },
    };

    return (
      <FilterElement
        className={el.alias}
        key={el.alias}
        type={el.type}
        alias={el.alias}
        label={el.label}
        labelFrom={el.labelFrom}
        labelTo={el.labelTo}
        pattern={el.pattern}
        autosearch={el.autosearch}
        isExtendedSearchBlock={el.extended}
        requestFormatIsArray={el.requestFormatIsArray}
        requestEnumFormat={el.requestEnumFormat}
        selectedItem={value}
        serviceType={el.serviceType || null}
        disabled={inProgress || (el.dependantDisabled && !dependsValue)}
        onSearch={_changeAndSearch}
        onChange={_onChange}
        notStandartService={el.dependsOn ? dependencyService : el.notStandardService}
        options={el.options}
        dependencies={dependencies}
        elementDependencies={el.dependencies}
        unselectAllData={el.unselectAllData}
        requestValueMapper={el.requestValueMapper}
      />
    );
  });

  const withVolumeComponents = [volume].concat(filterComponents);
  const withGlobalOrganization = (withVolume ? withVolumeComponents : filterComponents)
    .slice(0, globalOrgPosition)
    .concat(withGlobalOrg ? [globalOrg] : [])
    .concat((withVolume ? withVolumeComponents : filterComponents).slice(globalOrgPosition));

  const allComponents = !withGlobalOrg && !withVolume
                        ? filterComponents
                        : withGlobalOrganization

  const _onFilterClear = withVolume
    ? () => {
        onVolumeChange(null);
        setFullFilterStateAction([]);
      }
    : () => setFullFilterStateAction([]);

  const FilterContainer = getContainer(isModalFilter, isOpened)

  return (
    <div className={getClassName(["listFilter", styles.filter])}>
      <FilterContainer className={isModalFilter || isOpened ? className : compactClassName}>{allComponents}</FilterContainer>
      {isOpened ? (
        <FilterManagePanel
          {
            ...{
              filterState,
              gridFilterUid,
              globalOrgId,
              withVolume,
            }
          }
          selectedVolume={selectedVolume}
          setSelectedVolume={onVolumeChange}
          onSearch={_changeAndSearch}
          onFilterClear={_onFilterClear}
          isDisabledSave={!filterState.length || !volumeRights.UPDATE}
        />
      ) : null}
    </div>
  );
}

function getFilters(data) {
  const result = [];
  for (let el in data) {
    data.hasOwnProperty(el) && result.push(data[el]);
  }
  return result;
}

