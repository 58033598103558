import React, { useCallback, useState, useEffect } from 'react'
import Button from "../../button/button"
import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'
import { Pages } from "./pages"
import { debounce } from 'app/core/utility/common'

const Pagination = ({
  className,
  currentPage,
  setCurrentPage,
  pageCount,
}) => {

  //используем локальный стейт текущей страницы для работы debounce
  const [ localCurrentPage, setLocalPage ] = useState(currentPage)

  useEffect(() => {
    setLocalPage(currentPage)
  }, [currentPage])

  const debounceOnPageChanged = useCallback(debounce(
    (page) => {
      setCurrentPage(page)
    }, 400
  ), [])

  const setPage = (page) => {

    if (page !== currentPage) {
      setLocalPage(page)
      setCurrentPage(page)
    }
  }

  const onPageDecrement = () => {
    const newPrevPage = Math.max(localCurrentPage - 1, 1 )

    if (newPrevPage !== localCurrentPage) {
      debounceOnPageChanged(newPrevPage)
      setLocalPage(newPrevPage)
    }
  }

  const onPageIncrement = () => {
    const newNextPage = Math.min(localCurrentPage + 1, Math.max(1, pageCount))

    if (newNextPage !== localCurrentPage) {
      debounceOnPageChanged(newNextPage)
      setLocalPage(newNextPage)
    }
  }

  const onWheel = e => {
    e.deltaY > 0 && onPageIncrement()
    e.deltaY < 0 && onPageDecrement()
  }

  return (
    <section className={`pagination ${className || ''}`}>
      <div className='pagination__page-picker' onWheel = {onWheel}>
        <Button
          className='pagination__item pagination__decrement'
          type = 'text'
          onClick = {onPageDecrement}
        >
          <ChevronImg className='button-image'/>
        </Button>
        <Pages
          currentPage={localCurrentPage}
          pageCount={pageCount}
          onPageClick={setPage}
        />
        <Button
          className='pagination__item pagination__increment'
          type = 'text'
          onClick = {onPageIncrement}
        >
          <ChevronImg className='button-image'/>
        </Button>
      </div>
    </section>
  )
}

export default Pagination