/** @format */
import { useState } from 'react';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog';
import { stringPlural } from 'app/core/utility/common';
import Overlay from 'app/components/ui/overlay';
import { dispatchLogic } from 'redux/actions/common';
import { useDispatch } from 'react-redux';
import service from 'app/services/service';
import TextArea from 'app/components/ui/textArea';
import styles from '../list.module.scss';
import withTooltip from 'app/components/HOC/TooltipHOC';

const Button = withTooltip(ButtonComponent);

export default function RejectRequest({ afterActionCallback, items = [], withText }) {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();
  const onDialogToggle = () => {
    setDialogOpened(!dialogOpened);
  };

  const afterAction = () => {
    setDialogOpened(false);
    afterActionCallback && afterActionCallback();
  };

  const rejectRequests = async () => {
    setInProgress(true);
    const response = await service('certificateAuthorityService', 'rejectRequest', {
      requestIds: items.map(i => i.id),
      managerComment: comment,
    });
    const { isError, errors } = response || {};
    !isError && afterAction();
    isError && dispatchLogic(dispatch, 'MOCK', null, isError, errors);
    setInProgress(false);
  };
  const titleVariants = ['запрос', 'запросы', 'запросы'];
  const type = items[0]?.type?.id;
  const disabled =
    !items.length || items.some(i => i?.type?.id !== type || i?.status?.id !== 'Queued');

  return (
    <>
      {inProgress && <Overlay />}
      <Button
        type={withText ? buttonTypes.secondary : buttonTypes.image}
        onClick={onDialogToggle}
        disabled={disabled}
        tooltip={withText ? null : 'Отклонить'}
      >
        <Img img={Image.CloseBorderless} />
        {withText && <span className="button-title">Отклонить</span>}
      </Button>
      {dialogOpened && (
        <ConfirmDialog
          header={`Отклонить ${stringPlural(items.length, titleVariants)}?`}
          type={dialogType.confirm}
          submitText="Отклонить"
          onSubmit={rejectRequests}
          onCancel={onDialogToggle}
        >
          <TextArea
            className={styles.rejectComment}
            label="Причина отклонения"
            value={comment}
            onChange={value => setComment(value)}
            hint="Пожалуйста, укажите причину"
          />
        </ConfirmDialog>
      )}
    </>
  );
}

