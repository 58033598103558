import ExpandingBlock from 'app/components/ui/expandingBlock'
import { Table as TableComponent } from 'app/components/list/Table/Table';
import Overlay from 'app/components/ui/overlay'
import { sortDirection } from 'app/components/ui/constants'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { getResourceRights } from 'app/core/auth/auth'
import service from 'app/services/service'
import withInfiniteScroll from 'app/components/HOC/InfiniteScrollHOC'
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { columns } from './tableConfig';
const Table = withInfiniteScroll(TableComponent)

const LicenseEskzi = (props) => {
  const { isExternalUser, licenseId, inProgress } = props || {}

  const {
    sorting,
    sortListAction,
  } = useTableExtensions({
    defaultSort: {column: 'updateDate', direction: sortDirection.desc },
  });

  const _getLink = (item, props) => {
    const { profile } = props
    const { id, okz } = item;
    const skziRights = getResourceRights(profile, RESOURCES.eskzi)

    return skziRights.VIEW_CARD ? `/${okz ? 'skziAccounting' : 'skziOrganization'}/EskziCard/${id}` : ''
  }

  const requestData = {itemId: licenseId, ...sorting}
  const getAction = isExternalUser 
    ? ({page, pageSize}) => service('licenseService', 'extOrgGetEskzi', {page, pageSize, ...requestData}) 
    : ({page, pageSize}) => service('licenseService', 'getEskzi', {page, pageSize, ...requestData})

  return (
    <section className='license-eskzi'>
      {inProgress ? <Overlay /> : null}
      <ExpandingBlock
        className='license-common-data__requisites'
        renderHeader= {() => 'Связанные экземпляры СКЗИ'}
        initialState={true}
      >
        <Table
          loadData = {getAction}
          dependencies = {[sorting]}
          sortListAction = {sortListAction}
          sorting = {sorting} 
          fieldsConfig = { columns }
          onItemClick = {item => _getLink(item, props)}
        />
      </ExpandingBlock>
    </section>
  )
}

export default LicenseEskzi