/** @format */
import React, { useState } from 'react';

import ExpandingBlock from 'app/components/ui/expandingBlock';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';

import Table from './UserAccountItemsTable';

const UserAccountCardTab = ({
  header,
  Toolbar,
  tableSchema,
  tableServiceName,
  tableMethodName,
  itemCardCallback,
  profile,
  userId,
  userAccountOrgId,
  setInProgress,
  issueDisabled
}) => {
  const { selectedObjects, setSelectedObjects } = useTableExtensions();

  const [changed, setChanged] = useState({
    deleted: [],
    added: [],
    updated: [],
  });

  const onUpdate = changed => {
    if (changed.deleted) {
      const deletedMap = Object.fromEntries(changed.deleted.map(d => [d, d]));
      setSelectedObjects(selectedObjects.filter(so => !deletedMap[so.id]));
    }

    if (changed.updated) {
      const updatedMap = Object.fromEntries(changed.updated.map(u => [u.id, u]));
      setSelectedObjects(selectedObjects.map(so => (updatedMap[so.id] ? updatedMap[so.id] : so)));
    }

    setChanged(changed);
  };

  return (
    <ExpandingBlock renderHeader={() => header} initialState={true}>
      <Toolbar
        items={selectedObjects.length === 1 ? selectedObjects : []}
        userId={userId}
        profile={profile}
        setInProgress={setInProgress}
        onUpdate={onUpdate}
        issueDisabled={issueDisabled}
      />
      <div className="items-wrapper">
        <Table
          schema={tableSchema}
          serviceName={tableServiceName}
          methodName={tableMethodName || 'getAll'}
          userId={userId}
          userAccountOrgId={userAccountOrgId}
          changedRows={changed}
          selectedObjects={selectedObjects}
          setSelectedObjects={setSelectedObjects}
          itemCardCallback={itemCardCallback}
        />
      </div>
    </ExpandingBlock>
  );
};

export default UserAccountCardTab;
