/** @format */

const matchesEventCode = eventCode => avalableAction =>
  Array.isArray(eventCode) ? eventCode.includes(avalableAction.code) : avalableAction.code.includes(eventCode);

const hasAvailableAction = eventCode => item => {
  const { availableActions = [] } = item;
  return availableActions.some(matchesEventCode(eventCode));
};

export { matchesEventCode, hasAvailableAction };
