import { 
  KEYDOCUMENTGETALL,
  KEYDOCUMENTDELETE,
  KEYDOCUMENTINPROGRESS, 
  KEYDOCUMENTRESETACTION, 
  KEYDOCUMENTONDELETE, 
  KEYDOCUMENTPAGINATIONGET, 
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  KEYDOCUMENTONCLOSE,
  KEYDOCUMENTONOKZREPORT,
  KEYDOCUMENTONOKIREPORT,
  KEYDOCUMENTRUNEVENT,
  SIGNATUREREADYTOSIGN
} from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { getPageData } from 'app/components/ui/pagination/pagination'
import { stringPlural } from 'app/core/utility/common'
import { updateCollectionByItemId } from 'app/core/utility/common'

import config from 'app/core/config'
const { paginationPerPage } = config
  
const defaultState = {
  items: [],
  pageItems: [],
  pagination: { activePage: 1, itemsPerPage: paginationPerPage },
  eventRunObject: null,
  onDeleteObject: null,
  deleteNotify: null,
  confirmObject: null,
  OKZfilter: null,
  OKIfilter: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { 
      items,
      pageItems,
      pagination      
  } = state
  const { activePage: stateActivePage, itemsPerPage: statePerPage } = pagination

  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case KEYDOCUMENTGETALL:
      const storeItems = payload.map((item, index) => ({ ...item, index: index + 1, }))

      return {
        ...state,
        items: storeItems,
        pagination: {activePage: 1, itemsPerPage: statePerPage},
        pageItems: getPageData({ items: storeItems, activePage: 1, itemsPerPage: statePerPage }),
        inProgress: false
      }

    case KEYDOCUMENTPAGINATIONGET:
      const { activePage, itemsPerPage } = payload

      return {
        ...state,
        pagination: { activePage, itemsPerPage },
        pageItems: getPageData({ items, activePage, itemsPerPage }),
        inProgress: false
      }

    case KEYDOCUMENTONDELETE:
      const name = payload.length ? payload.map(ua => ua.name).join(', ') : payload.name
      
      return {
        ...state,
        onDeleteObject: {
          item: payload,
          type: dialogType.confirm,
          title: `Вы уверены, что хотите удалить ${stringPlural(payload.length || 1, ['ключевой документ', 'ключевые документы', 'ключевые документы'] )} «${name}»?`
        }
      }

    case KEYDOCUMENTONCLOSE: 
      const updatedItems = updateCollectionByItemId(items, payload)

      return {
        ...defaultState,
        pagination: pagination,
        items: updatedItems,
        pageItems: getPageData({
            items: updatedItems,
            activePage: stateActivePage,
            itemsPerPage : statePerPage
        }),
      }
 
    case KEYDOCUMENTDELETE:
      const toObject = (acc, curr) => { acc[curr.id] = curr; return acc}
      const currentItemsObject = items.reduce(toObject, {})
      const deletedObject = payload.reduce(toObject, {})
      const afterDeleteItems = items.reduce((acc, curr) => {
        return deletedObject[curr.id] 
                ? [...acc]
                : [...acc, currentItemsObject[curr.id]]
      }, [])

      return {
        ...state,
        items: afterDeleteItems,
        pageItems: getPageData({
            items: afterDeleteItems,
            activePage: stateActivePage,
            itemsPerPage : statePerPage
        }),
        onDeleteObject: null,
        deleteNotify: {
          item: payload,
          type: dialogType.success,
          title: `${stringPlural(payload.length || 1, ['Ключевой документ', 'Ключевые документы', 'Ключевые документы'] )} «${payload.map(i => i.name).join(', ')}» успешно ${stringPlural(payload.length || 1, ['удален', 'удалены', 'удалены'] )}`
        },
        inProgress: false,
      }

    case KEYDOCUMENTONOKZREPORT:
      return {...state, OKZfilter: payload }

    case KEYDOCUMENTONOKIREPORT:
      return {...state, OKIfilter: payload }     

    case KEYDOCUMENTRUNEVENT: 
      const updatedCollection = updateCollectionByItemId(items, payload)

      return {
        ...state,
        items: updatedCollection,
        pageItems: getPageData({ 
          items: updatedCollection, 
          activePage: stateActivePage,
          itemsPerPage: statePerPage }),
        inProgress: false,
        eventRunObject: null
      }

    case FIELDSERROR:
    case ERROR:
      return {
        ...defaultState,
        items,
        pageItems,
        pagination  
      }

    case SIGNATUREREADYTOSIGN:
      return {...state, inProgress: false}

    case KEYDOCUMENTINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case KEYDOCUMENTRESETACTION: 
      return {
        ...defaultState,
        items,
        pageItems,
        pagination  
      }

    default: 
      return state
  }
}