import {
  BREADCRUMBSPUSHITEM,
  SKZIBUILDCARDONTYPEADD,
  SKZIBUILDCARDGET,
  SKZIBUILDCARDUPDATE,
  SKZIBUILDCARDUPDATEFORMDATA,
  SKZIBUILDCARDINPROGRESS,
  SKZIBUILDCARDRESETACTION,
  SKZIBUILDCARDGETTYPES,
  SKZIBUILDCARDONSELECT,
  SKZIBUILDCARDONTYPEDELETE,
  SKZIBUILDCARDTYPEDELETE,
  SKZIBUILDCARDSETMAINTYPE,
  SKZIBUILDCARDONFILEADD,
  SKZIBUILDCARDFILEDELETE,
  SKZIBUILDCARDONFILEDELETE,
  SKZISELFSERVICEBUILDCARDGET,
  SKZI_BUILD_CARD_ADD,
  SKZI_BUILD_CARD_CLEAR_FORM
} from '../actionTypes'
import { virtualPages } from 'app/navigation/navigationContent'

import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/navigation/breadcrumbs'

const updateBreadcrumbs = ({ dispatch, path, skziName, versionName, buildName }) => {
  const virtualBreadcrumbs = [
    virtualPages.skzi({ path, name: skziName }),
    virtualPages.skziVersion({ path, name: versionName }),
  ];
  
  return dispatch({
    type: BREADCRUMBSPUSHITEM,
    payload: {
      ...getPageBreadcrumb(path, `Сборка ${buildName}`, virtualBreadcrumbs),
      forceUpdate: true
    },
  })
}

export const getSkziBuildAction = ({ buildId, pathname }) => dispatch => {
  service('skziBuildService', 'getBuild', buildId)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZIBUILDCARDGET, data, isError, errors)
    })
    .then(action => {
      const { payload } = action
      const { skzi, skziVersion, name: buildName } = payload ?? {}
      const { name: versionName } = skziVersion ?? {}
      const { name: skziName } = skzi ?? {}
      return updateBreadcrumbs({ dispatch, path: pathname, skziName, versionName, buildName })
    })
}

export const getSelfServiceSkziBuildAction = ({buildId, pathname, skziData}) => dispatch => {
  service('selfServiceService', 'getBuild', buildId)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZISELFSERVICEBUILDCARDGET, data, isError, errors)
    })
    .then(action => {
      const { payload } = action
      const { name } = payload || {}
      const { skziName } = skziData || {}

      return dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: getPageBreadcrumb(
                  pathname, 
                  `${skziName} Сборка ${name}`)
      })
    })
}

export const skziBuildAddCardAction = (data) => dispatch => {
  return service('versionService', 'addBuild', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, SKZI_BUILD_CARD_ADD, data, isError, errors)
      return response
    })
}

export const skziBuildCardClearFormAction = () => {
  return ({
    type: SKZI_BUILD_CARD_CLEAR_FORM,
    payload: {
    }
  })
}

export const skziBuildCardUpdateAction = (params, pathname) => dispatch => {
  service('skziBuildService', 'updateBuild', params)
    .then(response => {
      const { data, errors, isError } = response;
      dispatchLogic(dispatch, SKZIBUILDCARDUPDATE, data, isError, errors)
      
      const { skzi, skziVersion, name: buildName } = data ?? {}
      const { name: versionName } = skziVersion ?? {}
      const { name: skziName } = skzi ?? {}
      updateBreadcrumbs({ dispatch, path: pathname, skziName, versionName, buildName })
    })
}

export const skziBuildCardOnSelect = selectedData => dispatch => {
  dispatch({
    type: SKZIBUILDCARDONSELECT,
    payload: selectedData
  })
}

export const skziBuildCardOnTypeDelete = selectedData => dispatch => {
  dispatch({
    type: SKZIBUILDCARDONTYPEDELETE,
    payload: selectedData
  })
}


export const skziBuildCardRemoveTypesAction = (data) => dispatch => {
  return service('versionService', 'removeObjectTypeFromBuildRange', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZIBUILDCARDTYPEDELETE, data, isError, errors)
    })
} 

export const skziBuildCardGetTypesAction = (data) => dispatch => {
  return service('versionService', 'getAllBuildTypes', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZIBUILDCARDGETTYPES, data, isError, errors)
    })
} 


export const skziBuildCardSetMainType =  (data) => dispatch => {
  const { selectedType } = data

  return service('versionService', 'setMainBuildObjectType', data)
    .then(response => {
      const { data , errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZIBUILDCARDSETMAINTYPE, isError ? null : selectedType, isError, errors)
    })
} 

export const skziBuildCardResetAction = () => dispatch => {
  dispatch({
    type: SKZIBUILDCARDRESETACTION,
    payload: { 
    }
  })
}

export const skziBuildCardInProgress = isInProgress => dispatch => {

  dispatch({
    type: SKZIBUILDCARDINPROGRESS,
    payload: isInProgress
  })
}

export const skziBuildCardOnAddTypeAction = (currentTypes) => dispatch => {
  const filter = {
    term: '',
    column: 'name',
    direction: 1,
  }

  return service('objectTypesService', 'picker', filter)
    .then(response => {
      const { data, errors, isError } = response;
      // const typesToAdd = excludeByItemId(data, currentTypes)

      return dispatchLogic(dispatch, SKZIBUILDCARDONTYPEADD, data, isError, errors)
    })
}

export const skziBuildCardDeleteFile = (data) => async dispatch => {
  const { guid, typeElement } = data
  
  await dispatch({
    type: SKZIBUILDCARDONFILEDELETE,
    payload: {guid, typeElement}
  })

  return await service('versionService', 'deleteBuildTypeFile', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, SKZIBUILDCARDFILEDELETE, { guid, typeElement }, isError, errors)
    })
}

export const skziBuildCardOnAddFilesAction = (formData) => dispatch => {
  dispatch({
    type: SKZIBUILDCARDONFILEADD,
    payload: { 
      ...formData
    }
  })
}


export const skziBuildCardUpdateCommonForm = (formData) => dispatch => {
  dispatch({
    type: SKZIBUILDCARDUPDATEFORMDATA,
    payload: { 
      ...formData
    }
  })
}