import React from 'react'
import Group from 'app/components/ui/managePanel/group';
import {DownloadButton} from "app/components/list/TopPanel/DownloadButton";
import Img from 'app/components/ui/Img/imgMap';
import Button from 'app/components/ui/button/button';
import { useHistory } from 'react-router-dom';

export default function ListManagePanel({
  filterForRequest,
  sorting,
  fieldsVisible,
  globalOrgId,
  items,
  rights,
  inProgress
}) {

  const history = useHistory()

  return (
    <>
      {rights.CREATE ? (
        <Group>
          <Button
            className='create-button'
            type='primary'
            disabled={inProgress}
            onClick={() => history.push( `/acts/maintenance/create`)}
          >
            <Img.Plus className='button-image button-image--left'></Img.Plus>
            <span className='button-title'>Добавить</span>
          </Button>
        </Group>
      ) : null}
      <Group>
        <DownloadButton
          listFilterForRequest = {{...filterForRequest, organizationId: globalOrgId}} 
          sorting = {sorting}
          listFieldsVisibility ={fieldsVisible}
          selected = {items.map(i => i.id)}
          titul="Выгрузить в Excel"
          inProgress = { inProgress }
          serviceName = 'maintenanceService'
        />
      </Group>
    </>
  )
}