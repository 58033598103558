import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Breadcrumb from './breadcrumb'
import Button, { buttonTypes } from 'app/components/ui/button/button'
import { breadcrumbsClickAction } from 'redux/actions/common/breadcrumbsAction'

const getOnBreadcrumbClick = (dispatch, history) => path => {
  dispatch(breadcrumbsClickAction(path))
  history.push(path)
}

const Breadcrumbs = props => {
  const { customItems } = props
  
  const breadcrumbs = useSelector(({ breadcrumbs }) => breadcrumbs);
  const dispatch = useDispatch()
  const {items} = customItems || breadcrumbs
  const noSubmenuItems = items.filter(item =>!item.disabled)
  const { ImgComponent, link } = noSubmenuItems[0] || {}
  const withoutFirstItems = noSubmenuItems.slice(1)
  const history = useHistory()
  
  const onBreadcrumbClick = getOnBreadcrumbClick(dispatch, history);
  
  return (
    <div className='breadcrumbs'>
      <div className="first-item">
        {ImgComponent ? (
          <Button
            type={buttonTypes.image}
            onClick={() => onBreadcrumbClick(`${link}`)}
          >
            <ImgComponent className='button-image'/>
          </Button>
        ) : null}
      </div>
      {
        withoutFirstItems.map((item, i) => {
          const lastPart = withoutFirstItems.length === i + 1
      
          return (
            <Breadcrumb
              {...item}
              onClick={() => onBreadcrumbClick(`${item.link}`)}
              lastPart={lastPart}
              key={item.link}
            />
          )
        })
      }
    </div>
  )
}

export default Breadcrumbs