import React, { Component } from 'react'

import FileUploadControl from 'app/components/ui/file/fileUploadControl'
import { fileListAPI } from 'app/core/utility/file'
import DropDown from 'app/components/ui/DropDown'
import Button from 'app/components/ui/button/button'
import { fileControlState } from 'app/components/ui/file/fileUploadControl'

const cancelText = {
  [fileControlState.preUpload] : 'Отмена',
  [fileControlState.uploading] : 'Закрыть',
  [fileControlState.uploadFinished] : 'Завершить'
}

export default class addFilesDialog extends Component {
  constructor(props){
    super(props)

    this.state = { 
      dialogState: fileControlState.preUpload,
      allFilesProgressComplete: false,
      files: []
    }
    this.fileControl = React.createRef();
  }

  _onFileAdd = async () => {
    const { onCancel } = this.props
    const { current } = this.fileControl

    this.setState({ dialogState: fileControlState.uploading }, () => {
      current._upload()
      onCancel()
    })
  }

  _onCancel = async () => {
    const { onCancel } = this.props
    const { current } = this.fileControl

    // current._cancelUpload()
    onCancel()
  }

  _onFileChange = (files) => {
    this.setState({files: files})
  }

  _uploadFinished = (result) => {
    const { onFileUploaded, id } = this.props

    this.setState({ dialogState: fileControlState.uploadFinished })
    onFileUploaded({id: id, result})
  }

  _allFilesProgressComplete = () => {
    this.setState({ allFilesProgressComplete: true })
  }

  render() {
    const {
      children,
      id,
      urlPart,
      className
    } = this.props

    const { dialogState, files, allFilesProgressComplete } = this.state
    const cancelButtonText = allFilesProgressComplete && dialogState == fileControlState.uploading ? 'Завершение...' : cancelText[dialogState]

    const fileApi = fileListAPI(
      {
        objectTypeId: id
      },
      urlPart
    )

    return (
      <div className={`add-type-dialog ${className ? className : ''}`}>
        <div className="add-type-dialog__header">{'Загрузка файлов'}</div>
        {dialogState === fileControlState.preUpload ? (
          children
        ) : null}
        <FileUploadControl
          ref={this.fileControl}
          className='add-type-dialog__file-area'
          title='Добавить вложение'
          dialogState={dialogState}
          fileApi={fileApi}
          onFileChanged={this._onFileChange}
          onFileUploaded={this._uploadFinished}
          // onUploadFinish={this._uploadFinished}
          onAllFilesProgressComplete={this._allFilesProgressComplete}
        />
        <div className="add-type-dialog__buttons-panel">
          {dialogState === fileControlState.preUpload ? (
            <Button 
              type='text'
              onClick={this._onFileAdd}
              disabled={!files.length}
            >
              <span className='button-title'>Добавить</span>
            </Button>
          ) : null}
          <Button 
            type='text'
            onClick={this._onCancel}
            disabled={allFilesProgressComplete && dialogState == fileControlState.uploading}
          >
            <span className='button-title'>{cancelButtonText}</span>
          </Button>
        </div>
      </div>
    )
  }
}
