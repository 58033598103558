/** @format */
import { sortDirection } from 'app/components/ui/constants';
import { renderItem } from 'app/components/ui/autocomplete/autocomplete';
import { formatFIO } from 'app/core/utility/common';

export const defaultSettings = {
  columns: [
    {
      title: 'ФИО',
      alias: 'name',
      format: item => formatFIO(item),
      useSorting: true,
    },
    {
      title: 'Организация',
      alias: 'organization',
      format: item => {
        const { organization } = item;

        return renderItem.organizations(organization);
      },
      useSorting: true,
      width: 500,
    },
  ],
  sort: {
    column: 'name',
    direction: sortDirection.asc,
  },
};
