import {ADD_TOAST, REMOVE_ALL_TOASTS, REMOVE_TOAST} from "../actions/actionTypes";

const defaultState = {
  list: []
}

export default function toastReducer(state = defaultState, action){
  const { payload, type }  = action
  const { list } = state
  switch (type) {
    case ADD_TOAST:
      return {
        list: [
          ...list,
          payload
        ]
      }
    case REMOVE_TOAST:
      const newList = list.filter(el => el !== payload)
      return {
        list: newList
      }
    case REMOVE_ALL_TOASTS:
      return {
        list: []
      }
    default:
      return state
  }

}