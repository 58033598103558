import Group from 'app/components/ui/managePanel/group';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import {DownloadButton} from "app/components/list/TopPanel/DownloadButton";
import { EventCode, EndpointType, ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import { Image } from 'app/components/ui/Img';
import Complectation from 'app/components/list/TopPanel/Complectation';

export default function ExternalOrgPanel(props) {
  const { 
    items,
    afterAction,
    globalOrgId,
    filterForRequest,
    sorting,
    fieldsVisible
  } = props

  return (
    <>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.sendingOk}
          tooltip='Подтвердить получение'
          endpointType={EndpointType.eskziExternalOki}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.Acceptation}
          isExternalUser
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.returnGeneric}
          tooltip='Вернуть'
          endpointType={EndpointType.eskziExternalOki}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.ArReturn}
          isExternalUser
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.installation}
          tooltip='Установить'
          endpointType={EndpointType.eskziExternalOki}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.DownloadCircle}
          isExternalUser
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.withdrawal}
          tooltip='Изъять'
          endpointType={EndpointType.eskziExternalOki}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.UploadCircle}
          isExternalUser
        />
      </Group>
      <Group>
        <Complectation            
          titul='Комплектация'
          items = { items }
          isExternal
        />    
      </Group>
      <Group>
        <DownloadButton
          id={globalOrgId}
          listFilterForRequest={filterForRequest}
          sorting={sorting}
          listFieldsVisibility={fieldsVisible}
          selected={items.map(i=>i.id)}
          titul="Выгрузить в Excel"
          serviceName='skziOrganizationService'
        />
      </Group>
    </>
  )
}