import React, { Component } from 'react'
import { compose } from 'redux'

import withToggle from 'app/components/HOC/ToggleHOC'

import Button, { buttonTypes } from 'app/components/ui/button/button'
import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'
import PopupPanel from 'app/components/ui/popupPanelList'
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC'

const ButtonWithTooltip = withTooltip(Button)

class ButtonMenu extends Component {
  constructor(props){
    super(props)
    this.domNode = React.createRef()
  }
  render() {
    const { 
      className, 
      title, 
      toggle, 
      toggleStatus,
      type,
      LeftImage,
      tooltip,
      hideChevron = false,
      persist = false,
      disabled = false,
      tooltipPosition = Position.Bottom,
      children,
    } = this.props
    
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { toggle: toggle, toggleStatus: toggleStatus })
    );
    
    const needsChevron = !hideChevron;
    
    return (
      <div className={`${className || ''} button-menu`} ref={this.domNode}>
        <ButtonWithTooltip
          type={type ?? buttonTypes.primary}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          disabled={ disabled }
          className={`${needsChevron && type === buttonTypes.image ? 'button--select' : ''}`}
          onMouseDown={toggle}
        >
          {LeftImage && (
            <LeftImage className='button-image button-image--left'/>
          )}
          {title && (<span className='button-title'>{title}</span>)}
          {needsChevron && (<ChevronImg className='button-image button-image--right'/>)}
        </ButtonWithTooltip>
        {toggleStatus ? (
          <PopupPanel
            {...this.props}
            items={childrenWithProps}
            closeOnMenuClick={!persist}
            parent={this.domNode}
          ></PopupPanel>
          ) : null}
      </div>
    )
  }
}

export default compose(withToggle)(ButtonMenu)