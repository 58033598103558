import {
  MAINTENANCECREATE,              
  MAINTENANCECREATEUPDATEFORMDATA,
  MAINTENANCECREATEINPROGRESS    ,
  MAINTENANCECREATERESETACTION   ,
  MAINTENANCECREATECLEARFORM ,
  MAINTENANCECREATEINITFORMDATA
  } from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const registerMaintenance = data => dispatch => {
  return service('maintenanceService', 'createMaintenance', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, MAINTENANCECREATE, data, isError, errors)
    })
}

export const createMaintenanceUpdateFormData = (data) => dispatch => {
  dispatch({
    type: MAINTENANCECREATEUPDATEFORMDATA,
    payload: data
  })
}

export const createMaintenanceInitFormData = (data) => dispatch => {
  dispatch({
    type: MAINTENANCECREATEINITFORMDATA,
    payload: data
  })
}

export const createMaintenanceInProgressAction = value => dispatch => {
  dispatch({
    type: MAINTENANCECREATEINPROGRESS,
    payload: { inProgress: value }
  })
}

export const createMaintenanceResetAction = () => dispatch => {
  dispatch({
    type: MAINTENANCECREATERESETACTION,
    payload: null
  })
}

export const maintenanceCreateClearForm = () => dispatch => {
  dispatch({
    type: MAINTENANCECREATECLEARFORM,
    payload: { 
    }
  })
}