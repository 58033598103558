import React from 'react';
import Img from '../TopPanel/Img';

export default ({ type, title, titleImg }) => {
  if( !title ) return null;

  return(
    <div className = { type + '__header list__header'}>
      <Img type = { titleImg } className = {"header-image"} />
      { title }
    </div>
  )
}