import {
  ERROR,
  WARNING,
  FIELDSERROR,
  ERRORPAGEERROR,
  SIGNATUREREADYTOSIGN,
  CARDUPDATE,
  CARDINIT,
  CARDPREINIT,
  CARDRESET,
  CARDPREINITUPDATE,
  CARDINPROGRESS,
  CARDSEREVERUPDATE,
  CARD_SET_WARNING,
  CARDAFTERCREATE,
  ESKZICARDESKZICARDGETEVENTS,
  KEYDOCUMENTCARDGETEVENTS,
  KEYDOCUMENTSUPDATETASK,
  ESKZIEVENTSUPDATETASK,
  CLEAR_CARD
} from '../../actions/actionTypes'

const defaultState = {
  commonDataForm: {},
  viewData: {},
  preInitData: null,
  cardInProgress: false,
  warningObject: null
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { viewData, preInitData: statePreInitData } = state

  switch (action.type) {
    case CARDUPDATE:
      return {
        ...state,
        commonDataForm: payload,
      }

    case CARDPREINIT:
      return {
        preInitData: payload,
        cardInProgress: false
      }

    case CARDPREINITUPDATE:
      return {
        preInitData: null,
        commonDataForm: {...statePreInitData},
        viewData: {...statePreInitData},
        cardInProgress: false
      }

    case CARDINIT:
    case CARDSEREVERUPDATE:
      return {
        commonDataForm: {...payload},
        viewData: {...payload},
        cardInProgress: false
      }

    case CARDRESET:

      return {
        ...state,
        commonDataForm: { ...viewData }
      }

    case CLEAR_CARD: 
      return {
        ...defaultState
      }

    case CARDINPROGRESS:
      return {
        ...state,
        cardInProgress: payload,
      }

    case CARD_SET_WARNING:

      return {
        ...state,
        warningObject: payload
      }

    case ERRORPAGEERROR:
    case CARDAFTERCREATE:
      return {
        ...defaultState
      }

    case ESKZIEVENTSUPDATETASK:
    case KEYDOCUMENTSUPDATETASK:
    case KEYDOCUMENTCARDGETEVENTS:
    case ESKZICARDESKZICARDGETEVENTS:
    case SIGNATUREREADYTOSIGN:
    case FIELDSERROR:
    case ERROR:
    case WARNING:
      return {
        ...state,
        cardInProgress: false
      }

    default:
      return state
  }
}
