/** @format */
import { Table } from 'app/components/list/Table/Table';
import Overlay from 'app/components/ui/overlay';
import { sortDirection } from 'app/components/ui/constants';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import { useJournal } from 'app/components/journals/useJournal';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from 'app/components/ui/managePanel';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import MainListContainer from 'app/components/ui/MainListContainer/MainListContainer';
import Filter, { GlobalOrgType } from 'app/components/filter';
import { fieldsConfig, filtersConfig } from './pageConfig';
import BlockCAUser from './blockCAUser';
import UnblockCAUser from './unblockCAUser';

export default function Users() {
  const {
    baseTableLogic,
    itemSelectionLogic,
    objectSelectionLogic,
    paginationLogic,
    sortingLogic,
    filterLogic,
    globalOrgLogic,

    profile,
    rights,
    inProgress,
    setSelectedPagination,
    selectedObjects,
    refreshPage,
  } = useJournal({
    defaultSort: {
      column: 'creationDate',
      direction: sortDirection.desc,
    },
    serviceName: 'certificateAuthorityUserService',
    resource: RESOURCES.certificateAuthorityUser,
  });

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title="Удостоверяющий центр : Пользователи" Img={imgMap.Sign} />
      <ManagePanel borders={Border.All}>
        {rights.UPDATE && (
          <>
            <Group>
              <BlockCAUser afterActionCallback={refreshPage} items={selectedObjects} />
            </Group>
            <Group>
              <UnblockCAUser afterActionCallback={refreshPage} items={selectedObjects} />
            </Group>
          </>
        )}
      </ManagePanel>
      <Filter
        {...{
          ...filterLogic,
          ...globalOrgLogic,
          profile,
          inProgress,
          filtersConfig,
        }}
        globalOrgPosition={4}
        globalOrgType={GlobalOrgType.okiOnly}
        withGlobalOrg
      />
      <Table
        {...{
          ...baseTableLogic,
          ...itemSelectionLogic,
          ...objectSelectionLogic,
          ...sortingLogic,
          setSelectedPagination,
          inProgress,
          fieldsConfig,
          itemsSelectable: true,
          headerInfo: true,
          onItemClick: item => `/certificateAuthority/user/card/${item.id}`,
        }}
      />
      <Pagination {...paginationLogic} />
    </MainListContainer>
  );
}

