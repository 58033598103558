/** @format */
import HistoryItem from './historyItem';
import withInfiniteScroll from 'app/components/HOC/InfiniteScrollHOC';
import styles from './history.module.scss';

function HistoryList({ list }) {
  return (
    <div className={styles.list}>
      {list.map(item => (
        <HistoryItem {...item} />
      ))}
    </div>
  );
}

export default withInfiniteScroll(HistoryList);
