/** @format */

import { useHistory, useLocation, useParams } from "react-router-dom";

export default function withRoutingProps(Component, requestedProps){
    return props => {
        const routingProps = {
            ...!requestedProps || requestedProps.includes('history') ? { history: useHistory() } : null,
            ...!requestedProps || requestedProps.includes('location') ? { location: useLocation() } : null,
            ...!requestedProps || requestedProps.includes('params') ? { params: useParams() } : null,
        }
        return <Component {...props} {...routingProps} />
    };
}