import React, { Component } from 'react'

import Input, { InputType } from 'app/components/ui/Input'
import { ReactComponent as Eye}  from 'assets/img/commonVer2/eye.svg'
import { ReactComponent as CrossedEye} from 'assets/img/commonVer2/crossed-eye.svg'

export default class passwordInput extends Component {
  constructor(props){
    super(props)

    this.state = {
      showPassword: false
    }
  }

  _crosseEye = () => {
    return (
      <CrossedEye
        className='password-eye'
        onClick={() => this.setState({showPassword: false})}
      />
    )
  }

  _eye = () => {
    return (
      <Eye
        className='password-eye'
        onClick={() => this.setState({showPassword: true})}
      />
    )
  }

  render() {
    const { placeholder, id, name } = this.props
    const { showPassword } = this.state
    
    return (
      <Input
        {...this.props}
        id={ id || 'password' }
        name={ name || 'password' }
        inputType={showPassword ? InputType.text : InputType.password}
        placeholder={ placeholder }
        CustomImage={showPassword ? this._crosseEye : this._eye}
      />
    )
  }
}