
import React from 'react'

import { capitalize, serverlikeErrors, formatFIO } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import { Table } from 'app/components/list/Table/Table';
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo'
import ExpandingBlock from 'app/components/ui/expandingBlock'

const signInfoColumns = [
  {
    title: 'ФИО участника',
    alias: 'createDate',
    format: item => {
      const { user } = item
      
      return formatFIO(user)
    }
  },
  {
    title: 'Email',
    alias: 'Email',
    format: item => {
      const { user } = item
      const { email = '' } = user || {}

      return email
    }
  },
  {
    title: 'Срок подписания',
    alias: 'deadLine',
    width: 150,
    format: item => {
      const { deadLine } = item

      return formatDate(deadLine, 'dd.mm.yyyy')
    }
  },
  {
    title: 'Статус',
    alias: 'status',
    width: 200,
    format: item => {
      const { signature } = item

      return signature ? 'Подписано' : 'На подписании'
    }
  },
  {
    title: 'Дата подписания',
    alias: 'signDate',
    width: 150,
    format: item => {
      const { signature } = item
      const { validationTime } = signature || {}

      return formatDate(validationTime, 'dd.mm.yyyy')
    }
  },
  {
    title: 'Подпись',
    alias: 'signature',
    format: item => {
      const { signature, user } = item
      const { certificateInfo } = signature || {}

      return certificateInfo ? (
        <SignatureInfo 
          signData={{ ...signature, signer: user }}
          title='Данные подписи'
        />
      ) : null
    }
  },
]


export default function SignInfo(props) {
  const { signers } = props

  return (
    <ExpandingBlock
      className='sign-info'
      renderHeader= {() => 'Подписание комиссией'}
      initialState={true}
    >
      <Table
        list={signers}
        fieldsConfig = { signInfoColumns }
      />
    </ExpandingBlock>
  )
}
