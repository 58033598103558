import React, {useEffect} from "react";
import {Table} from 'app/components/list/Table/Table';
import withInfiniteScrollHOC from 'app/components/HOC/InfiniteScrollHOC';
import service from "app/services/service";
import {Url} from "app/components/MassImports/enums";
import {getEnumTypeByString} from "app/components/MassImports/utils";
import useReducerTable from "app/components/list/Table/useTableExtensions";

const TableWithInfiniteScroll = withInfiniteScrollHOC(Table);
const GroupTableWithInfiniteScroll = withInfiniteScrollHOC(Table);

export const ImportTable = (props) => {
    const {
        parseFileInfo,
        setRecordsState,
        duplicateConfig,
        importsPayload,
        config,
        setInProgressState
    } = props;

    const {
        sortListAction,
        onToggleExpandItem,
        setSelectedAction,
        onTreeInitValues,
        onInitValues,
        selectedItems,
        inProgress,
        sorting,
    } = useReducerTable({
        defaultSort: {
            column: importsPayload && importsPayload.sortBy,
            direction: importsPayload && importsPayload.sortDirection
        }
    });

    const {fileId, fileType} = parseFileInfo;

    const onDuplicateListChanged = (list) => onTreeInitValues({sorting, config: duplicateConfig.fieldsConfig, list});
    const onListChanged = (list) => onInitValues(list);
    const onLoadTableData = ({page, pageSize}) => service('MassImports', 'postScanned', {
        payload: {
            ...importsPayload,
            conditions: {...importsPayload.conditions, fileId},
            page,
            pageSize,
            sortDirection: sorting.direction,
            sortBy: sorting.column,
        },
        importType: Url[getEnumTypeByString(fileType)],
        cb: setRecordsState,
    });

    useEffect(() => {
        setInProgressState(inProgress);
    }, [inProgress]);

    return <div className={'modal-container__body'}>
        {
            importsPayload.conditions.groupDuplicates
                ? <GroupTableWithInfiniteScroll
                    fieldsConfig={duplicateConfig.fieldsConfig}
                    inProgress={inProgress}
                    itemsSelectable={false}
                    changedFieldsVisibility={false}
                    headerInfo
                    itemsSelectableRadio={false}
                    sorting={sorting}
                    selectedItems={selectedItems}
                    dependencies={[sorting, importsPayload]}
                    isTree
                    loadData={onLoadTableData}
                    onToggleExpandItem={onToggleExpandItem}
                    sortListAction={sortListAction}
                    setSelectedAction={setSelectedAction}
                    onListChanged={onDuplicateListChanged}
                />
                : <TableWithInfiniteScroll
                    sorting={sorting}
                    selectedItems={[]}
                    fieldsConfig={config.fieldsConfig}
                    inProgress={inProgress}
                    itemsSelectable={false}
                    changedFieldsVisibility={false}
                    headerInfo
                    itemsSelectableRadio={false}
                    dependencies={[sorting, importsPayload]}
                    onListChanged={onListChanged}
                    loadData={onLoadTableData}
                    sortListAction={sortListAction}
                />
        }
    </div>;
};
