/** @format */

import React from 'react';

import { cssModuleClassNameGetter } from 'app/core/utility/jsx';
import { useMouse } from 'app/hooks/useMouse';
import Tooltip from 'app/components/ui/Tooltip';

import styles from './Doughnut.module.scss';
import { useChartTooltip } from '../useChartTooltip';

const getClass = cssModuleClassNameGetter(styles);

const getArcAngle = (value, valuesSum) => (value / valuesSum) * Math.PI * 2;

const hydrateChartVm = vm =>
  vm.map(subchart => {
    const { items, gap } = subchart;
    const total = items.reduce((acc, cur) => {
      acc += cur.value || 0;
      return acc;
    }, 0);

    return {
      ...subchart,
      items:
        total > 0
          ? items.reduce((hydratedItems, item, i) => {
              const { value } = item;
              const angle = getArcAngle(value, total);
              const startTheta = !!i ? hydratedItems[i - 1].endTheta + (angle > gap ? gap / 2 : 0) : 0;
              const hydratedItem = {
                ...item,
                angle,
                startTheta,
                endTheta: startTheta + (angle > gap ? angle - gap / 2 : 0),
              };
              const newItems = [...hydratedItems, hydratedItem];
              return newItems;
            }, [])
          : [{ invalid: true }],
    };
  });

export default function Doughnut({ vm: subCharts, className, style, width, height, children }) {
  const { wrapperRef, hoveredItem, tooltipPosition, getOnMouseEnter, clearTooltipPosition } = useChartTooltip();

  const [centerX, centerY] = [width / 2, height / 2];
  const getArcPontX = (r, theta) => centerX + r * Math.cos(theta);
  const getArcPointY = (r, theta) => centerY - r * Math.sin(theta);

  const getArcPoint = (r, theta) => `${getArcPontX(r, theta)},${getArcPointY(r, theta)}`;
  const getEllipseRadii = r => `${r},${r}`; // Both ellipse radii are equal for circles
  const xAxisRotation = 0;
  const getLargeArcFlag = angle => (angle > Math.PI ? 1 : 0);
  const getSweepFlag = () => 0;
  const getItemPath = (r, angle, startTheta, endTheta) =>
    `M ${getArcPoint(r, startTheta)} A${getEllipseRadii(r)} ${xAxisRotation} ${getLargeArcFlag(
      angle
    )},${getSweepFlag()} ${getArcPoint(r, endTheta)}`;
  const hydratedChartVm = hydrateChartVm(subCharts);

  return (
    <div className={styles.chart} ref={wrapperRef} onMouseOut={clearTooltipPosition}>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        className={className}
        style={{ ...style, width: `${width}px`, height: `${height}px` }}
      >
        {hydratedChartVm.map(({ r, width: strokeWidth, items, className, invalidChartClassName }) => (
          <g className={getClass([styles.subchart, className])} style={{ strokeWidth }}>
            {items.length === 1 ? (
              <circle
                cx={centerX}
                cy={centerY}
                r={r}
                className={items[0].invalid ? invalidChartClassName : items[0].className}
              />
            ) : (
              items.map(({ text, angle, startTheta, endTheta, className: itemClassName }) => (
                <path
                  d={getItemPath(r, angle, startTheta, endTheta)}
                  className={itemClassName}
                  onMouseOver={getOnMouseEnter({ text })}
                />
              ))
            )}
          </g>
        ))}
        {children}
      </svg>
      {tooltipPosition && hoveredItem && <Tooltip tooltip={hoveredItem.text} position={tooltipPosition} />}
    </div>
  );
}
