/** @format */
import { sortDirection } from 'app/components/ui/constants';
import { formatDate } from 'app/core/utility/date';

export const defaultSettings = {
  columns: [
    {
      title: '№',
      alias: 'id',
      useSorting: true,
      width: 240,
    },
    {
      title: 'Лицензионный ключ',
      alias: 'key',
      format: item => {
        const { key } = item;

        return `** ${key.slice(-4)}`;
      },
      useSorting: true,
    },
    {
      title: 'Свободно',
      alias: 'vacantAmount',
      format: item => {
        if (item.vacantAmount === 0) return '0';
        return item.vacantAmount || 'Бесконечно';
      },
      useSorting: true,
      width: 240,
    },
    {
      title: 'Срок действия',
      alias: 'expirationDate',
      format: item => (item.expirationDate ? formatDate(item.expirationDate, 'dd.mm.yyyy') : 'Бессрочно'),
      useSorting: true,
      width: 240,
    },
  ],
  sort: {
    column: 'id',
    direction: sortDirection.desc,
  },
};
