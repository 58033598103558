import React from 'react'
import ButtonB from 'app/components/ui/button/button';
import {ReactComponent as PlusImg} from 'assets/img/commonVer2/plus.svg'
import Card from 'app/components/ui/card/card'
import withModal from 'app/components/HOC/ObsoleteModalHOC'
import useCreateControl from "./hooks/useCreateControl";
import {MaintenanceCardLines} from "./MaintenanceCardLines";

const ModalCard = withModal(Card)

export default function CreateControl({inProgress,
                                        title,
                                        onSubmitSignEnhance,
                                        generateDataForRequest,
                                        disabled = false,
                                        cardFormLines=MaintenanceCardLines,
                                        skzi}){

  const {
    rights,
    addFormData,
    fieldsError,
    addActionDialog,
    addDefaultFormData,
    onCreateClick,
    onCancel,
    getActionKey,
    onValidation,
    onCreateActionChange,
  } = useCreateControl()

  return (
    <>
      <div  className="manage-panel__item">
        <ButtonB className='create-button' type='primary' disabled={ disabled || inProgress || !rights.CREATE } onClick={onCreateClick} >
          <PlusImg className='button-image button-image--left' />
          <span className='button-title'>{ title }</span>
        </ButtonB>
      </div>
      {addActionDialog ?
        <ModalCard
          className="technicalJournalReport__add-event__data-card"
          formLines={cardFormLines({skzi: skzi})}
          data={addDefaultFormData}
          formData={addFormData}
          errorByFields={fieldsError}
          onValidationError={onValidation}
          onChange={onCreateActionChange}
          onSubmit={() => onSubmitSignEnhance(generateDataForRequest())}
          renderHeader = "Добавить запись"
          onCancel={onCancel}
          editOnly={true}
          getObjectKeyFunc={getActionKey}
        /> : null
      }
    </>
  )
}


