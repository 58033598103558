/** @format */
import React from 'react';

import Alert from 'app/components/ui/Alert';
import Severity from 'app/core/types/severity';
import styles from './DestructionFormBody.module.scss';

export default ({ children }) => (
  <div className={styles.formBody}>
    <Alert
      severity={Severity.Warning}
      content="Связанные ключевые документы будут уничтожены"
      className={styles.alert}
    />
    {children}
  </div>
);
