import {ERROR, ERRORPAGEERROR, FIELDSERROR, SIGNATUREREADYTOSIGN, WARNING} from "../../actions/actionTypes";

export default ( state = false, action, prefix = '' ) => {

  if( action.type === 'LIST_IN_PROGRESS_ON' ){
    return true;
  }

  if( action.type === 'LIST_IN_PROGRESS_OFF' ){
    return false;
  }

  if( action.type === 'LIST_DROP' ){
    return false;
  }

  switch (action.type){
    case SIGNATUREREADYTOSIGN:
    case FIELDSERROR:
    case ERROR:
    case ERRORPAGEERROR:
    case WARNING:
      return false
  }

  return state;
}