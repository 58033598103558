import React, { useMemo } from 'react';
import {Table} from 'app/components/list/Table/Table';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import Overlay from 'app/components/ui/overlay';
import ConfirmDialog from 'app/components/dialog/confirmDialog';
import { sortDirection } from 'app/components/ui/constants';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import { useJournal } from 'app/components/journals/useJournal';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from "app/components/ui/managePanel";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import ListManagePanel from './ListManagePanel';
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from "app/components/ui/MainListContainer/MainListContainer";
import Filter from "app/components/filter";
import styles from './list.module.scss';
import config from './config'

export default function UserList() {
  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    dropFiltersAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    filterOpened,
    toggleFilterState,
    onClearFilter,

    // useTableExtensions
    onInProgress,
    sortListAction,
    onRemoveValues,
    onItemUpdate,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,

    // global
    errorObject,
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
    clearError,
  } = useJournal({
    defaultSort: {
      column: 'name',
      direction: sortDirection.desc,
    },
    serviceName: 'userService',
  });

  const rights = useMemo(() => getResourceRights(profile, RESOURCES.user), [profile])

  return(
    <MainListContainer>
    {inProgress && <Overlay />}
    <PageHeader title='Учетные записи' Img={imgMap.AccessManagement} />
    <ManagePanel borders={Border.All}>
      <ListManagePanel
        rights={rights}
        filterForRequest={filterForRequest}
        sorting={sorting}
        fieldsVisible={fieldsVisible}
        globalOrgId={globalOrgId}
        items={selectedObjects}
        afterAction={onRemoveValues}
        setSelectedAction={setSelectedAction}
        onInProgress={onInProgress}
        inProgress={inProgress}
      />
      <Group separate>
        <FilterTriggerPanel
          toggle={toggleFilterState}
          filterFilled={filterFilled}
          onClearFilter={onClearFilter}
          disabledClear={!filterState.length}
        />
      </Group>
      <Group separate>
        <TableFieldSelect
          listFieldsVisible={fieldsVisible}
          setVisibleFieldsAction={setVisibleFieldsAction}
          toggleVisibleFieldAction={toggleVisibleFieldAction}
          headerInfo={true}
          fieldsConfig={config.fieldsConfig}
          changedFieldsVisibility={true}
          settingsAsGear={true}
        />
      </Group>
    </ManagePanel>
    <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.trheeRowFilter}
      >
        <Filter
          className={styles.filter}
          inProgress={inProgress}
          setGlobalOrg={setGlobalOrg}
          globalOrganization={globalOrganization}
          setFilterStateAction={setFilterStateAction}
          setFilterForRequestAction={setFilterForRequestAction}
          setFullFilterStateAction={setFullFilterStateAction}
          filterState={filterState}
          filterFilled={filterFilled}
          profile={profile}
          filtersConfig={config.tablePageFiltersConfig}
          isOpened={filterOpened}
          toggleFilterState={toggleFilterState}
          compactClassName={styles.compactFilter}
        />
        <Table
          {...{
            totalCount,
            list,
            sortListAction,
            sorting,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedPagination,
            inProgress,
          }}
          listFieldsVisible={fieldsVisible}
          fieldsConfig={config.fieldsConfig}
          itemsSelectable={true}
          changedFieldsVisibility={true}
          onItemClick={item => { 
            return `/accessManagement/users/card/${ item.id }` 
          }}
          headerInfo={true}
        />
      </FilterWithTableContainer>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageCount={pageCount}
        perPage={perPage}
      />
      {errorObject ? 
        <ConfirmDialog
          {...errorObject}
          onCancel={clearError}
        /> : null
      }
    </MainListContainer>
  );
}