import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useToast from "app/components/ui/toast/useToast";
import { eskziCardServiceAddAction, eskziCardServiceDeleteRangeAction, eskziCardServiceUpdateAction } from "redux/actions/skziInstance/eskziCardAction";
import { getTechJournalDataForServer } from "app/components/signature/SignatureDialog";
import service from "app/services/service";
import { dispatchLogic } from "redux/actions/common";
import { arrayToIdHashMap } from "app/core/utility/common";

export function useDeleteControlWithSign({
  setProgress, 
  getList, 
  items,
  setSelectedAction,
  setSelectedPagination
}){

  const dispatch = useDispatch()
  const {addToast} = useToast()
  const generateDeleteDataForRequest = () => ({Ids: items.map(item => item.maintenanceRecordId)})

  function deleteActionCallback(data){
    setProgress(true)
    const actualData = {
      ...data,
      data: data.data.Ids
    }

    // считаем что же все-таки удалилось,
    // т.к. возвращаются maintenanceRecordId удаленных записей, а не 
    // number, по которому определяется уникальность записей в таблице
    const selectedByMaintenanceRecordId = arrayToIdHashMap(items.map(item => ({...item, id: item.maintenanceRecordId})))

    return dispatch(eskziCardServiceDeleteRangeAction(actualData)).then(response => {
      setProgress(false)
      const {isError, isWarn, data} = response || {}
      if (!isError && !isWarn) {
        setSelectedAction([])
        setSelectedPagination({removed:data.map(i => {
          const item = selectedByMaintenanceRecordId[i]
          return item ? {...item, id: item.number} : null
        }).filter(i => i !== null)})
        addToast('Успешно удалено')
        data && getList()
      }
      return response
    })
  }

  return {
    generateDeleteDataForRequest,
    deleteActionCallback,
  }
}

export function useEditControlCallback({setProgress, getList, items, setSelectedPagination}){
  const dispatch = useDispatch()
  const {addToast} = useToast()
  const eskziCard = useSelector(({eskziCard}) => eskziCard)
  const { serviceData } = eskziCard || {}
  const { editActionDialog } = serviceData
  const { formData: editFormData } = editActionDialog || {}
  const editEskziId = editFormData && editFormData.eskzi && editFormData.eskzi.id
  const editItem = items.length === 1 && items[0].maintenanceRecordId && items[0]

  const generateEditDataForRequest = () => getTechJournalDataForServer({id: editEskziId}, editFormData)

  function editActionCallback(data){
    setProgress(true)
    return dispatch(eskziCardServiceUpdateAction({
      ...data,
      data: {
        ...data.data,
        id: editItem.maintenanceRecordId
      }} )).then(response => {
      setProgress(false)
      const {isError, isWarn, data} = response || {}
      if (!isError && !isWarn) {
        // бек не знает какую запись редактировали, number в ответе нет
        // а нам нужно сохранить уникальный номер записи
        const updated = {...data, id: editItem.id}
        setSelectedPagination({updated:[updated]})
        addToast('Успешно обновлено')
        data && getList()
      }
      return response
    })
  }

  return {
    editActionCallback,
    generateEditDataForRequest,
    editItem
  }
}

export function useCreateControlCallback({setProgress, getList}){
  const dispatch = useDispatch()
  const {addToast} = useToast()
  const eskziCard = useSelector(({eskziCard}) => eskziCard)
  const { serviceData } = eskziCard || {}
  const { addActionDialog } = serviceData
  const { formData: addFormData } = addActionDialog || {}
  const addEskziId = addFormData &&  addFormData.eskzi && addFormData.eskzi.id
  const generateAddDataForRequest = () => getTechJournalDataForServer({id: addEskziId}, addFormData)

  function addActionCallback(data) {
    setProgress(true)
    return dispatch(eskziCardServiceAddAction(data)).then(response => {
      setProgress(false)
      const {isError, isWarn, data} = response || {}
      if (!isError && !isWarn) {
        addToast('Успешно добавлено')
        data && data.id && getList()
      }
      return response
    })
  }
  return {
    addActionCallback,
    generateAddDataForRequest
  }
}

export function useInstallationControlCallback({setProgress, getList}){
  const dispatch = useDispatch()
  const {addToast} = useToast()
  const [eventTemplate, setEventTemplate] = useState(null)
  const [isShowDialog, setIsShowDialog] = useState(false)
  const generateInstallationDataForRequest = () => {}

  useEffect(() => {
    service('buisnessProcessService', 'getEventTemplate', {code: "OkiInstallationKeyDocument"})
      .then(response => {
        const { data, errors, isError } = response;

        dispatchLogic(dispatch, 'MOCK', data, isError, errors)
        data && setEventTemplate({
          ...data,
          model: [
            ...data.model,
            {
              "key": "KeyDocumentCustom",
              "title": "Ключевой документ",
              "readOnly": false,
              "required": true
            }
          ]
        })
        return response
      })
  }, [])

  function installationActionCallback(data){
    setProgress(true)
    return service('keyDocumentService', 'runEvent', data)
      .then(response => {
        setProgress(false)
        dispatch({ type: 'LIST_IN_PROGRESS_OFF' });
        const { data, errors, isError, isWarn } = response;
        dispatchLogic(dispatch, 'MOCK', data, isError, errors )
        if (!isError && !isWarn) {
          setIsShowDialog(false)
          addToast('Успешно введено в аппаратное СКЗИ')
          data && getList()
        }
        return response;
      })
  }

  return {
    eventTemplate,
    isShowDialog,
    setIsShowDialog,
    installationActionCallback,
    generateInstallationDataForRequest
  }
}

export default function useActionPanel(){
  const {inProgress, list = [], selectedItems = []} = useSelector(state=> ({
    inProgress: state.listInProgress,
    list: state.list,
    selectedItems : state.listSelected,
  }))

  const items = list.filter( el => selectedItems.indexOf( el.number ) > -1 );

  const dispatch = useDispatch()


  const setProgress = (inProgress) => {
    dispatch({ type: inProgress ? 'LIST_IN_PROGRESS_ON' : 'LIST_IN_PROGRESS_OFF' });
  }

  return {
    inProgress,
    items,
    setProgress,
  }
}