import { 
  BREADCRUMBSPUSHITEM,
  MAINTENANCECARDONTYPEADD,
  
  MAINTENANCECARDGET,
  MAINTENANCECARDUPDATE,
  MAINTENANCECARDUPDATEFORMDATA,
  MAINTENANCECARDINPROGRESS,
  MAINTENANCECARDRESETACTION,
  MAINTENANCECARDGETTYPES ,    
  MAINTENANCECARDONSELECT,
  MAINTENANCECARDONTYPEDELETE,
  MAINTENANCECARDTYPEDELETE,
  MAINTENANCECARDONFILEADD,
  MAINTENANCECARDFILEDELETE,
  MAINTENANCECARDONFILEDELETE
  
} from '../actionTypes'
  
import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/navigation/breadcrumbs'
import { excludeByItemId } from 'app/core/utility/common'

  export const getMaintenanceAction = ({maintenanceId, pathname}) => dispatch => {
    service('maintenanceService', 'getMaintenance', maintenanceId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, MAINTENANCECARDGET, data, isError, errors)
      })
      .then(action => {
        const { payload } = action
        const { number, id } = payload || {}
  
        return dispatch({
          type: BREADCRUMBSPUSHITEM,
          payload: getPageBreadcrumb(pathname, `Заключение об эксплуатации № ${number || id}`)
        })
      })
  }
  
  export const maintenanceCardUpdateAction = (data) => dispatch => {
    return service('maintenanceService', 'updateMaintenance', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        dispatchLogic(dispatch, MAINTENANCECARDUPDATE, data, isError, errors)
      })
  }

  export const maintenanceCardOnSelect = selectedData => dispatch => {
    dispatch({
      type: MAINTENANCECARDONSELECT,
      payload: selectedData
    })
  }
  
  export const maintenanceCardOnTypeDelete = selectedData => dispatch => {
    dispatch({
      type: MAINTENANCECARDONTYPEDELETE,
      payload: selectedData
    })
  }

  export const maintenanceCardRemoveTypesAction = (data) => async dispatch => {
    const { items } = data

    await dispatch({
      type: MAINTENANCECARDONFILEDELETE,
      payload: items
    })

    return service('maintenanceService', 'deleteMaintenanceFile', data)
      .then(response => {
        const { data: result, errors, isError } = response;
        
        return dispatchLogic(dispatch, MAINTENANCECARDTYPEDELETE, result, isError, errors)
      })
  } 
  
  export const maintenanceCardGetTypesAction = (data) => dispatch => {
    return service('maintenanceService', 'getAllMaintenanceFiles', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, MAINTENANCECARDGETTYPES, data, isError, errors)
      })
  } 
  
  
  export const maintenanceCardResetAction = () => dispatch => {
    dispatch({
      type: MAINTENANCECARDRESETACTION,
      payload: { 
      }
    })
  }
  
  export const maintenanceCardInProgress = isInProgress => dispatch => {
    dispatch({
      type: MAINTENANCECARDINPROGRESS,
      payload: isInProgress
    })
  }
  
  export const maintenanceCardOnAddTypeAction = (currentTypes) => dispatch => {
    const filter = {
      term: '',
      column: 'name',
      direction: 1,
    }
  
    service('objectTypesService', 'picker', filter)
      .then(response => {
        const { data, errors, isError } = response;
        const typesToAdd = excludeByItemId(data, currentTypes)
  
        dispatchLogic(dispatch, MAINTENANCECARDONTYPEADD, typesToAdd, isError, errors)
      })
  }
  
  export const maintenanceCardDeleteFile = (data) => async dispatch => {
    const { items } = data
    
    await dispatch({
      type: MAINTENANCECARDONFILEDELETE,
      payload: items
    })
  
    return await service('maintenanceService', 'deleteMaintenanceFile', data)
      .then(response => {
        const { data: resultData, errors, isError } = response;
  
        dispatchLogic(dispatch, MAINTENANCECARDFILEDELETE, resultData, isError, errors)
      })
  }
  
  export const maintenanceCardOnAddFilesAction = (formData) => dispatch => {
    dispatch({
      type: MAINTENANCECARDONFILEADD,
      payload: { 
        ...formData
      }
    })
  }
  
  
  export const maintenanceCardUpdateCommonForm = (formData) => dispatch => {
    dispatch({
      type: MAINTENANCECARDUPDATEFORMDATA,
      payload: { 
        ...formData
      }
    })
  }