/** @format */
import { sortDirection } from 'app/components/ui/constants';
import { formatFIO } from 'app/core/utility/common';

export const defaultSettings = {
  columns: [
    {
      title: '№',
      alias: 'id',
      useSorting: true,
      width: 140,
    },
    {
      title: 'Наименование',
      alias: 'name',
      useSorting: true,
    },
    {
      title: 'Серийный номер',
      alias: 'serialNumber',
      format: item => {
        const { serialNumber } = item;

        return serialNumber || '';
      },
      useSorting: true,
    },
    {
      title: '№ экземпляра',
      alias: 'identificationNumber',
      useSorting: true,
      width: 200,
    },
    {
      title: 'Пользователь',
      alias: 'eskziUser',
      useSorting: true,
      format: item => {
        const { eskziUser } = item;

        return formatFIO(eskziUser);
      },
    },
  ],
  sort: {
    column: 'id',
    direction: sortDirection.desc,
  },
};
