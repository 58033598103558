export default (state = [], action, prefix = '') => {

  if( action.type === prefix + 'LIST_LOAD_LIST_FILTER_STATE' ){
    return action.payload;
  }

  if( action.type === prefix + 'LIST_FULL_FILTER_STATE' ){
    return action.payload;
  }

  if( action.type === prefix + 'LIST_FILTER_STATE' ){
    const item = action.payload;
    let temp = state.filter( el => ( el.alias !== item.alias ));
    if( item.valueForRequest ){
      temp.push( item );
    }
    return temp;
  }

  if( action.type === prefix + 'LIST_FILTER_DROP' ){
    return [];
  }

  // if( action.type === 'LIST_REMOVE_FILTER_ITEM' ){
  //   let temp = { ...state };
  //   let arr = [ ...temp[ action.payload.alias] ];
  //   temp[ action.payload.alias ] = arr.filter( el => el.filterParam !== action.payload.filterParam );
  //   return temp;
  // }

  if( action.type === prefix + 'LIST_DROP' ){
    return [];
  }

  return state;
}