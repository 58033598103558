/** @format */

import { useState, useEffect, useRef, useCallback } from 'react';

import { useMouse } from 'app/hooks/useMouse';

export const useChartTooltip = () => {
  const wrapperRef = useRef();
  const { event, clientCoords, onMouseEvent } = useMouse();
  const [hoveredItem, setHoveredItem] = useState();
  const [tooltipPosition, setTooltipPosition] = useState();

  const getOnMouseEnter = itemData => e => {
    onMouseEvent(e);
    setHoveredItem(itemData);
  };

  const clearTooltipPosition = () => {
    setTooltipPosition(null);
  };

  useEffect(() => {
    const tooltipOffset = 20;
    clientCoords &&
      setTooltipPosition({
        left: clientCoords.x - wrapperRef.current.offsetLeft + tooltipOffset,
        top: clientCoords.y - wrapperRef.current.offsetTop + tooltipOffset,
      });
  }, [event]);

  return { wrapperRef, hoveredItem, tooltipPosition, getOnMouseEnter, clearTooltipPosition };
};
