/** @format */
import { formatDate } from 'app/core/utility/date';

export const buildColumns = [
  {
    title: 'Наименование',
    alias: 'name',
    useSorting: true,
  },
  {
    title: 'Дата добавления',
    alias: 'createDate',
    useSorting: true,
    width: 400,
    format: item => {
      const { createDate } = item;

      return formatDate(createDate, 'dd.mm.yyyy');
    },
  },
];

export const courseColumns = [
  {
    title: 'Наименование',
    alias: 'name',
    useSorting: true,
  },
  {
    title: 'Вложения',
    alias: 'materialsCount',
    useSorting: true,
    width: 200,
  },
  {
    title: 'Активен',
    alias: 'isActive',
    useSorting: true,
    width: 200,
    format: item => {
      const { isActive } = item;

      return isActive ? 'Да' : 'Нет';
    },
  },
  {
    title: 'Дата изменения',
    alias: 'changeDate',
    useSorting: true,
    width: 300,
    format: item => {
      const { changeDate } = item;

      return formatDate(changeDate, 'dd.mm.yyyy');
    },
  },
];
