import React from "react";
import ChipsItem from "app/components/ui/chips/chipsItem";

export default function ChipsList({chips, onClickHandler}) {
    return <>
        <div className={`chips__list--progressbar`}>
            {
                chips.map((item) => {
                    return <ChipsItem
                        key={item.name}
                        isDisabled={item.isDisabled}
                        name={item.name}
                        icon={item.icon}
                        type={item.type}
                        isItemActive={item.isActive}
                        onClickHandler={onClickHandler}
                    />;
                })
            }
        </div>

    </>;
};
