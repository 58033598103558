/** @format */
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterElement';
import styles from './list.module.scss';
import ColoredChipText, { ChipType } from 'app/components/ui/coloredChipText';
import DownloadFileButton from 'app/components/downloadFileButton';

const StatusColorMap = {
  AffiliationChanged: ChipType.error,
  CACompromise: ChipType.error,
  Expired: ChipType.normal,
  Hold: ChipType.normal,
  KeyCompromise: ChipType.error,
  NotYetValid: ChipType.normal,
  CessationOfOperation: ChipType.error,
  Superseded: ChipType.error,
  Unspecified: ChipType.error,
  Valid: ChipType.success,
  KeyExpired: ChipType.normal,
};

export const fieldsConfig = [
  {
    title: 'Дата создания',
    alias: 'creationDate',
    format: item => formatDate(item.creationDate, 'dd.mm.yyyy hh:MM:ss'),
    visibleOnStart: true,
    useSorting: true,
    width: 200,
  },
  {
    title: 'Имя пользователя',
    alias: 'userName',
    format: item => {
      return item.userName;
    },
    visibleOnStart: true,
    visibleAlways: true,
    useSorting: true,
  },
  {
    title: 'Серийный №',
    alias: 'serialNumber',
    format: item => {
      const { id, serialNumber } = item ?? {};
      return (
        <div className={styles.serialNumber}>
          {serialNumber}
          <DownloadFileButton url={`api/v1/cryptopro/acceptorcertificates/download?sn=${id}`} />
        </div>
      );
    },
    visibleOnStart: true,
    useSorting: true,
    width: 380,
  },
  {
    title: 'Статус сертификата',
    alias: 'status',
    format: item => {
      const { status } = item || {};
      const { id, name } = status || {};
      return <ColoredChipText text={name} type={StatusColorMap[id]} />;
    },
    visibleOnStart: true,
    visibleAlways: true,
    useSorting: true,
  },
  {
    title: 'Действителен с',
    alias: 'validFromDate',
    format: item => formatDate(item.validFromDate, 'dd.mm.yyyy hh:MM:ss'),
    visibleOnStart: true,
    useSorting: true,
    width: 240,
  },
  {
    title: 'Действителен до',
    alias: 'validityDate',
    format: item => formatDate(item.validityDate, 'dd.mm.yyyy hh:MM:ss'),
    visibleOnStart: true,
    useSorting: true,
    width: 240,
  },
];

export const inCardFieldsConfig = fieldsConfig.filter(i => i.alias !== 'userName');

export const filtersConfig = [
  {
    type: FilterControlType.simpleSearch,
    alias: 'serialNumber',
    label: 'Серийный №',
    extended: false,
    order: 1,
  },
  {
    type: FilterControlType.simpleSearch,
    alias: 'userName',
    label: 'Имя пользователя',
    extended: false,
    order: 2,
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'status',
    label: 'Статус сертификата',
    extended: false,
    notStandardService: {
      serviceName: 'certificateAuthorityCertificateService',
      serviceMethod: 'statuses',
    },
    order: 3,
  },
  {
    type: FilterControlType.dateRangePicker,
    alias: 'creationDate',
    label: 'Дата создания',
    extended: true,
    order: 5,
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'validityPeriod',
    label: 'Истекает срок действия за период',
    extended: true,
    notStandardService: {
      serviceName: 'certificateAuthorityCertificateService',
      serviceMethod: 'validityPeriod',
    },
    order: 6,
  },
];

export const inCardFiltersConfig = [
  {
    type: FilterControlType.simpleSearch,
    alias: 'serialNumber',
    label: 'Серийный №',
    extended: false,
    order: 1,
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'status',
    label: 'Статус сертификата',
    extended: false,
    notStandardService: {
      serviceName: 'certificateAuthorityCertificateService',
      serviceMethod: 'statuses',
    },
    order: 3,
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'validityPeriod',
    label: 'Истекает срок действия за период',
    extended: false,
    notStandardService: {
      serviceName: 'certificateAuthorityCertificateService',
      serviceMethod: 'validityPeriod',
    },
    order: 6,
  },
];
