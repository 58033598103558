/** @format */

import { getClassName } from 'app/core/utility/jsx';
import React from 'react';
import { SimpleInfoElement } from '../../infoWidget';
import { CircleInfoElement } from '../../infoWithChart';
import styles from './legend.module.scss';

export default function ChartLegend({ headerText, headerData, dataRows = [] }) {
  return (
    <div className={getClassName(['dashboard-chart-legend', styles.legend])}>
      <SimpleInfoElement
        className={styles.legendInfoLine}
        title={headerText}
        data={headerData}
        regularStyleData
      />
      {dataRows.map(item => (
        <CircleInfoElement key={item.title} className={styles.legendInfoLine} {...item} />
      ))}
    </div>
  );
}

export const oneHundredPercentMagic = (...args) => {
  if (!args || !args.length) {
    return [];
  }

  const indexedData = args.map((val, i) => ({ index: i, value: Number(val) }));

  const sum = indexedData.reduce(
    (acc, cur) => {
      if (acc.maxValue < cur.value) {
        acc.maxIndex = cur.index;
        acc.maxValue = cur.value;
      }
      if (acc.minValue > cur.value) {
        acc.minValue = cur.value;
        acc.minIndex = cur.index;
      }
      acc.total += cur.value;
      return acc;
    },
    { minIndex: -1, maxIndex: -1, maxValue: 0, minValue: 100, total: 0 }
  );

  if (sum.total === 0) {
    return indexedData.map(i => i.value.toString());
  }

  if (sum.total > 100 && sum.maxIndex >= 0) {
    indexedData[sum.maxIndex].value -= 1;
  } else if (sum.total < 100 && sum.minIndex >= 0) {
    indexedData[sum.minIndex].value += 1;
  }

  return indexedData.map(i => i.value.toString());
};
