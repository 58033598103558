import {compareAsc} from 'date-fns';

interface IErrorData {
  errorCode: string,
  errorMessage: string,
  isError: boolean
}

interface IErrorResult {
  valid: boolean,
  error: IErrorData | null
}

export const checkDatesRange = (startDateVal: Date, expirationDateVal: Date): IErrorResult => {
    // Логика сравнения дат https://date-fns.org/v2.26.0/docs/compareAsc
    const notValid: boolean = compareAsc(expirationDateVal, startDateVal) === -1;
    const error = notValid
        ? {
            errorCode: "E_NOTVALID",
            errorMessage: "Дата начала действия не может быть больше чем дата окончания действия",
            isError: true
        }
        : null
    return {
        valid: !notValid,
        error: error
    }
}

export const notEmpty = (value: string | object): IErrorResult => {
  const notValid = value === '' || !value
  const error = notValid 
                ? {
                    errorCode: "E_EMPTY",
                    errorMessage: "Обязательное для заполнения",
                    isError: true
                  }
                : null 
  return { 
    valid: !notValid,
    error: error
  }
}

export const identicalValues = (value1: any, value2: any): IErrorResult => {
  const notValid = value1 !== value2 
  const error = notValid 
                ? {
                    errorCode: "E_NOTIDENTICAL",
                    errorMessage: "Значения не совпадают",
                    isError: true
                  }
                : null 
  return { 
    valid: !notValid,
    error: error
  }
}

export const identicalPasswords = (value1: string, value2: string): object => {
  const result = identicalValues(value1, value2)
  const errorMessage = "Пароли не совпадают" 
  return result.valid 
          ? result
          : {
            ...result,
            error: {
              ...result.error,
              errorMessage
            }
          }
}

export const email = (email: string) : IErrorResult => {
  const re = /^\S+@\S+\.\S+$/;
  const valid = re.test(String(email))
  const error = {
                  "errorCode": "E_NOTVALID",
                  "errorMessage": "Почта указана неверно",
                  "isError": true
                }

  return { 
    valid,
    error: valid ? null : error
  }
}

export const notEmptyStringCollection = (value: Array<string>): IErrorResult => {
  const valid: boolean = value.reduce<boolean>((acc: boolean, item: string): boolean => {
    return acc || item.trim() !== ''
  }, false)
  const error = valid 
                ? null 
                : {
                    "errorCode": "E_EMPTY",
                    "errorMessage": "Обязательное для заполнения",
                    "isError": true
                  }
  return { 
    valid: valid,
    error: error
  }
}

export const notEmptyCollection = (value: Array<object>): IErrorResult => {
  const valid: boolean = value.reduce<boolean>((acc: boolean, item: object): boolean => {
    return acc || !!item
  }, false)
  const error = valid 
                ? null 
                : {
                    "errorCode": "E_EMPTY",
                    "errorMessage": "Обязательное для заполнения",
                    "isError": true
                  }
  return { 
    valid: valid,
    error: error
  }
}

export const upperCaseLettersRequired = (value: string): IErrorResult => {
  const re = /[A-Z]/;
  const valid = re.test(String(value))
  const error = {
                  "errorCode": "E_NOTVALID",
                  "errorMessage": "Обязательны латинские символы верхнего регистра",
                  "isError": true
                }

  return { 
    valid,
    error: valid ? null : error
  }
}

export const lowerCaseLettersRequired = (value: string): IErrorResult => {
  const re = /[a-z]/;
  const valid = re.test(String(value))
  const error = {
                  "errorCode": "E_NOTVALID",
                  "errorMessage": "Обязательны латинские символы нижнего регистра",
                  "isError": true
                }

  return { 
    valid,
    error: valid ? null : error
  }
}

export const requiredLengthLimit = (str: string | number, lengthLimit: number): IErrorResult => {
  const notValid = String(str).length > lengthLimit
  const error = notValid
    ? {
      "errorCode": "E_NOTIDENTICAL",
      "errorMessage": "Слишком много символов",
      "isError": true
    }
    : null

  return {
    valid: !notValid,
    error: error
  }
}

export const requiredLength = (str: string | number, length: number): IErrorResult => {
  const notValid = String(str).length < length
  const error = notValid 
                ? {
                  "errorCode": "E_NOTIDENTICAL",
                  "errorMessage": "Недостаточно символов",
                  "isError": true
                  }
                : null 
                
  return { 
    valid: !notValid,
    error: error
  }
}

export const digitsRequired = (value: string | number): IErrorResult => {
  const re = /\d/;
  const valid = re.test(String(value))
  const error = {
                  "errorCode": "E_NOTVALID",
                  "errorMessage": "Обязательны арабские цифры (0-9)",
                  "isError": true
                }

  return { 
    valid,
    error: valid ? null : error
  }
}

export const specialSymbolRequired = (value: string): IErrorResult => {
  const re = /[.,: ;!?#\\\/\{}[\]\$<>@\+_\-%\^"']/;
  const valid = re.test(String(value))
  const error = {
                  "errorCode": "E_NOTVALID",
                  "errorMessage": 'Обязательны специальные символы .,:;!?#/\\{}[]$<>@+_-%^"\'',
                  "isError": true
                }

  return { 
    valid,
    error: valid ? null : error
  }
}


export const licenseSpecialSymbolForbidden = (value: string): IErrorResult => {
  if (!value) {
    return { 
      valid: true,
      error: null
    }
  }

  const re = /[^A-Za-zА-Яа-яёЁ0-9 \-]/;
  const valid = !re.test(String(value))
  const error = {
                  "errorCode": "E_NOTVALID",
                  "errorMessage": 'Специальные символы недопустимы',
                  "isError": true
                }

  return { 
    valid,
    error: valid ? null : error
  }
}
