/** @format */

import React from 'react';
import Radio from 'app/components/ui/radio';

import LinkedItemAction from './linkedItemAction';
import styles from './closeDialog.module.scss';

export default ({
  itemsActions,
  withdrawAll,
  destroyAll,
  groupActionChangeDisabled,
  onWithdrawAll,
  onDestroyAll,
  onWithdrawItem,
  onDestroyItem,
}) => [
  {
    title: '№',
    alias: 'id',
    visibleOnStart: true,
    useSorting: true,
    width: 96,
  },
  {
    title: 'Наименование',
    alias: 'name',
    visibleOnStart: true,
    useSorting: true,
    width: 340,
  },
  {
    title: 'Серийный №',
    alias: 'serialNumber',
    visibleOnStart: true,
    useSorting: true,
    width: 175,
  },
  {
    title: () => (
      <span>
        <Radio
          key="withdrawAll"
          className={`cb-withdraw ${styles.cbTable}`}
          value={true}
          checkedValue={withdrawAll}
          disabled={groupActionChangeDisabled}
          onChange={onWithdrawAll}
        >
          Изъять
        </Radio>
      </span>
    ),
    alias: 'withdraw',
    visibleOnStart: true,
    useSorting: true,
    width: 162,
    format: item => (
      <Radio
        key="withdraw"
        className={`cb-withdraw ${styles.cbTable}`}
        value={LinkedItemAction.Withdraw}
        checkedValue={itemsActions[item.id] && itemsActions[item.id].action}
        disabled={itemsActions[item.id] && itemsActions[item.id].readOnly}
        onChange={() => onWithdrawItem(item.id)}
      />
    ),
  },
  {
    title: () => (
      <span>
        <Radio
          key="destroyAll"
          className={`cb-destroy ${styles.cbTable}`}
          value={true}
          checkedValue={destroyAll}
          disabled={groupActionChangeDisabled}
          onChange={onDestroyAll}
        >
          Уничтожить
        </Radio>
      </span>
    ),
    alias: 'destroy',
    visibleOnStart: true,
    useSorting: true,
    width: 168,
    format: item => (
      <Radio
        key="destroy"
        className={`cb-destroy ${styles.cbTable}`}
        value={LinkedItemAction.Destroy}
        checkedValue={itemsActions[item.id] && itemsActions[item.id].action}
        disabled={itemsActions[item.id] && itemsActions[item.id].readOnly}
        onChange={() => onDestroyItem(item.id)}
      />
    ),
  },
];
