import React, {useEffect} from 'react'
import withModal from 'app/components/HOC/ObsoleteModalHOC'
import Button from 'app/components/ui/button/button'
import ActionButtonImage from "app/components/list/TopPanel/ActionButtonImage";
import withTooltip from "app/components/HOC/TooltipHOC";
import FunctionalCard from "app/components/ui/card/FunctionalCard";
import useFunctionalCard, {CardState} from "app/components/ui/card/FunctionalCard/useFunctionalCard";
import ActionForm from "./ActionForm";
import {useActionForm} from "./hooks/useActionForm";
import {formatDate} from  "app/core/utility/date";
import {getResourceRights} from "app/core/auth/auth";
import {useSelector} from "react-redux";
import {setErrorByKeyFormAction} from "redux/actions/common";

const FunctionalCardModal = withModal(FunctionalCard)
const ButtonWithTooltip = withTooltip( ActionButtonImage );

export default function InstallationControl(props){

  const {
    inProgress,
    title,
    item,
    onSubmitSignEnhance,
    isShowDialog,
    setIsShowDialog,
    generateTextErrors,
    skzi
  } = props
  const formData = useActionForm(item)

  const get = () => Promise.resolve(item)
  const accessRights = useSelector(({profile}) => profile)
  const rights = getResourceRights( accessRights, "KeyDocument/Action" );

  const {
    cardState,
    fieldsError,
    onFormChange,
    validate,
    commonDataForm,
    onCancel,
    dispatchStore
  } = useFunctionalCard({formData, get, parentEditState: true})

  useEffect(() => {
    dispatchStore(setErrorByKeyFormAction(generateTextErrors))
  }, [generateTextErrors])

  useEffect(() => {
    !isShowDialog && onCancel()
  }, [isShowDialog])

  return (
    <>
      <div  className="manage-panel__item">
        <ButtonWithTooltip img={'LinkImg'} action={() => setIsShowDialog(true)} disabled={inProgress || !rights.CREATE || !item}  tooltip={title} />
      </div>
      {isShowDialog ? (
        <FunctionalCardModal
          renderHeader = {title}
          cardState={CardState.edit}
          canEdit={true}
          editOnly={true}
          className="technicalJournalReport__installation-event__data-card"
          editState={true}
          onCancel={()=> {
            setIsShowDialog(false)
            onCancel()
          }}
        >
          <div className="card-material__content">
            <ActionForm
              fixedValues={skzi ? {hardwareId: skzi} : {}}
              eventId={item && item.id}
              cardState={cardState}
              formData={commonDataForm}
              model={item.model}
              onChange={onFormChange}
              errorByFields={fieldsError}
            />
          </div>
          <div className="installation-event__buttons-panel">
            <Button
              type='text'
              onClick={() => {
                if(validate()){
                  return false
                }
                onSubmitSignEnhance(generateDataForRequest(commonDataForm))
              }}
            >
              <span className='button-title'>Сохранить</span>
            </Button>
            <Button
              type='text'
              onClick={()=> {
                onCancel()
                setIsShowDialog(false)
              }}
            >
              <span className='button-title'>Отмена</span>
            </Button>
          </div>
        </FunctionalCardModal>
        )
        : null
      }
    </>
  )
}

function generateDataForRequest(data){
  const {hardwareId = {}, keyDocumentCustom ={}, performerId ={}, transmittalLetterDate = '', transmittalLetterNumber = '', eventTemplateId} = data || {}
  return {
    eskziId: keyDocumentCustom.id,
    eventTemplateId,
    hardwareId: hardwareId.id,
    performerId: performerId.id,
    transmittalLetterDate: formatDate(transmittalLetterDate, 'yyyy-mm-dd'),
    transmittalLetterNumber,
  }
}