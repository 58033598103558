import React from 'react'
import {ReactComponent as ExitImg}  from 'assets/img/commonVer2/exit-arrow.svg'
import exclamationYellow from 'assets/img/common/exclamation-yellow.svg'
import exclamationRed from 'assets/img/common/exclamation-red.svg'
import { stringPlural } from 'app/core/utility/common'

import InfoPanel from 'app/components/ui/infoPanel'
import Button from 'app/components/ui/button/button'

 const headerUserInfo = props => {
  const { onLogout, name, licenseInfo, currentUser } = props
  const { roles = [] } = currentUser || {}
  const { isTrial, daysToExpiration, licenseIsExpiring, licenseIsExpired } = licenseInfo || {}
  const rolesString = roles.map(item => item.role.name).join(', ')
  const { role } = roles[0] || {}
  const { name: firstRole } = role || {}

  return (
    <div className="header__user-info">
      {isTrial && (
        <div className={`user-info__trial-info trial-info-expiring--${licenseIsExpiring ? 'yes' : 'no'}`}>
          <img className='trial-info__license-img' src={licenseIsExpiring ? exclamationRed : exclamationYellow} />
          <span className='trial-info__license-label'>До окончания лицензии {`${daysToExpiration} ${stringPlural(daysToExpiration, ['день', 'дня', 'дней'] )}`}.</span>
        </div>
      )}
      <div className="user-info__item user-info__user-panel">
        <div className="user-panel__user-data">
          <span className="user-data__fio">{name}</span>
          {roles.length > 1 ? (
            <InfoPanel
              className = {`standard ${roles.length > 1 ? 'pointer' : ''}`}
              onHover={false}
              renderTrigger={() => {
                return <div className='user-data__roles'>{`${firstRole}, ...`}</div>
              }}
            > 
              {rolesString}
            </InfoPanel>
          ) : (
            <div className="user-data__roles">{firstRole}</div>
          )}
        </div>
      </div>
      <Button
          className='user-info__logout'
          type = 'image'
          onClick={onLogout}
        >
          <ExitImg 
            className='button-image'
            title={'Выйти из системы'}
          />
      </Button>
    </div>
  )
}

export default headerUserInfo