/** @format */
import { useState } from 'react';
import ImportCertificate from './importControl';
import ImportResult from './importResult';
import Overlay from 'app/components/ui/overlay';

export default function ImportCertificateWizard({ massImport, keyDocumentId, onCancel, onImportComplete, okz }) {
  const [importResult, setImportResult] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const onImportCompletion = async result => {
    const { isError } = result

    onImportComplete && (await onImportComplete());
    setImportResult(result);
    setInProgress(false);
  };

  return (
    <>
      {inProgress && <Overlay />}
      {!importResult && (
        <ImportCertificate
          massImport={massImport}
          keyDocumentId={keyDocumentId}
          onCancel={onCancel}
          setInProgress={setInProgress}
          onCompletion={onImportCompletion}
          okz={okz}
        />
      )}
      {importResult && <ImportResult massImport={massImport} onClose={onCancel} importResult={importResult} />}
    </>
  );
}
