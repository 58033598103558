/** @format */

import { percentage } from 'app/core/utility/common';
import React from 'react';
import ChartLegend, { oneHundredPercentMagic } from '.';
import { CIRCLE_COLOR, CIRCLE_SIZE } from '../../typography';
import styles from './legend.module.scss';

export default function EskziLegend({ eskzi, licenses }) {
  const { total: totalEskzi, available: availableEskzi, issued, moved } = eskzi || {};
  const { total: totalLicenses, available: availableLicenses, used } = licenses || {};

  let availableEskziPercent = percentage(totalEskzi, availableEskzi);
  let issuedPercent = percentage(totalEskzi, issued);
  let movedPercent = percentage(totalEskzi, moved);

  let availableLicensesPercent = percentage(totalLicenses, availableLicenses);
  let usedPercent = percentage(totalLicenses, used);

  [availableEskziPercent, issuedPercent, movedPercent] = oneHundredPercentMagic(
    availableEskziPercent,
    issuedPercent,
    movedPercent
  );
  [availableLicensesPercent, usedPercent] = oneHundredPercentMagic(
    availableLicensesPercent,
    usedPercent
  );

  return (
    <div className={styles.eskziLegend}>
      <ChartLegend
        headerText={'Экземпляров по статусам'}
        headerData={totalEskzi}
        dataRows={[
          {
            title: 'Доступно',
            data: availableEskzi,
            // reference: '',
            circle: {
              color: CIRCLE_COLOR.available,
              percent: availableEskziPercent,
              size: CIRCLE_SIZE.regular,
            },
          },
          {
            title: 'Выдано пользователю',
            data: issued,
            // reference: '',
            circle: {
              color: CIRCLE_COLOR.issued,
              percent: issuedPercent,
              size: CIRCLE_SIZE.regular,
            },
          },
          {
            title: 'Передано в организации',
            data: moved,
            // reference: '',
            circle: { color: CIRCLE_COLOR.moved, percent: movedPercent, size: CIRCLE_SIZE.regular },
          },
        ]}
      />
      <ChartLegend
        headerText={'Всего лицензий'}
        headerData={totalLicenses}
        dataRows={[
          {
            title: 'Доступно',
            data: availableLicenses,
            // reference: '',
            circle: {
              color: CIRCLE_COLOR.available,
              percent: availableLicensesPercent,
              size: CIRCLE_SIZE.regular,
            },
          },
          {
            title: 'Использовано',
            data: used,
            // reference: '',
            circle: { color: CIRCLE_COLOR.used, percent: usedPercent, size: CIRCLE_SIZE.regular },
          },
        ]}
      />
    </div>
  );
}
