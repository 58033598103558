/** @format */

import { sum } from 'app/core/utility/arrays';
import { not } from 'app/core/utility/functional';

const isSingleActivationLicense = lic => (lic.entity || lic).vacantAmount === 1;
const isUnlimitedLicense = lic => typeof (lic.entity || lic).vacantAmount === 'undefined';
const selectActivationsCount = lic => (lic.entity || lic).vacantAmount;

const calculateLicenseCount = licenses => {
  const hasUnlimitedLicenses = licenses.some(isUnlimitedLicense);
  const hasLimitedLicenses = licenses.some(not(isUnlimitedLicense));
  return licenses.every(isSingleActivationLicense)
    ? licenses.length
    : `${licenses.length} (${[
        ...(hasUnlimitedLicenses ? ['∞'] : []),
        ...(hasLimitedLicenses ? [sum(licenses, 0, lic => selectActivationsCount(lic) ?? 0)] : []),
      ].join(', ')})`;
};

const calculateReplicationResultStats = replicationItems => {
  const [orgCountExpected, orgCountActual] = [
    replicationItems.length,
    replicationItems.filter(ri => ri.organization?.isSuccessful).length,
  ];
  const orgCount = `${orgCountExpected}/${orgCountActual}`;

  const [eskzisCountExpected, eskzisCountActual] = [
    sum(replicationItems, 0, v => +v.copiesNumber),
    sum(replicationItems, 0, v => +v.copiesNumberFact),
  ];
  const eskzisCount = `${eskzisCountExpected}/${eskzisCountActual}`;

  const [licenseCountExpected, licenseCountActual] = [
    sum(replicationItems, 0, v => v.licenses.length),
    sum(replicationItems, 0, v => v.licenses.filter(lic => lic.isSuccessful).length),
  ];
  const licenseCount = `${licenseCountExpected}/${licenseCountActual}`;

  return { orgCount, eskzisCount, licenseCount };
};

export { calculateLicenseCount, calculateReplicationResultStats };
