/** @format */
import withModal from 'app/components/HOC/ModalHOC';
import styles from './import.module.scss';
import Severity from 'app/core/types/severity';
import FileItem from 'app/components/ui/file/fileItem';
import Alert from 'app/components/ui/Alert/Alert';
import Header from 'app/components/HOC/ModalHOC/header';
import Button, { buttonTypes } from 'app/components/ui/button/button';
import ButtonsPanel from 'app/components/HOC/ModalHOC/buttons';

const SuccessMessage = ({ count, massImport }) => {
  return (
    <>
      Файлов загружено: {count}
      <br />
      {massImport && `Ключевых документов создано: ${count}`}
    </>
  );
};

const ErrorMessage = ({ count }) => {
  return <>Не удалось импортировать: {count}</>;
};

function ImportResult({ onClose, importResult, massImport }) {
  const { data = [] } = importResult;
  const errorFiles = data.filter(i => !i.success);
  const successCount = data.length - errorFiles.length;

  return (
    <div className={`${styles.import}`}>
      <Header
        className={styles.header}
        title={`Результат загрузки сертификат${massImport ? 'ов' : 'а'}`}
        onCancel={onClose}
      />
      <div className={`${styles.result}`}>
        {successCount ? (
          <Alert
            className={styles.alert}
            severity={Severity.Success}
            content={<SuccessMessage count={successCount} massImport={massImport} showIcon />}
          />
        ) : null}
        {errorFiles.length ? (
          <Alert
            className={styles.alert}
            severity={Severity.Error}
            content={<ErrorMessage count={errorFiles.length} showIcon />}
          />
        ) : null}
        {errorFiles && errorFiles.length ? (
          <div className={styles.fileArea}>
            {errorFiles.map(file => {
              return (
                <FileItem hideCancel file={{ blob: { name: file.fileName, size: file.fileSize } }} error={{ errorMessage: file.error }} />
              );
            })}
          </div>
        ) : null}
      </div>
      <ButtonsPanel centered>
        <Button className={styles.button} type={buttonTypes.text} onClick={onClose} caption="Закрыть" />
      </ButtonsPanel>
    </div>
  );
}

export default withModal(ImportResult);
