import { 
ERROR,
CLEARERROR,
FIELDSERROR,
ERRORPAGEERROR,
KEYDOCUMENTCARDGET, 
KEYDOCUMENTCARDUPDATEFORMDATA ,      
KEYDOCUMENTCARDINPROGRESS ,          
KEYDOCUMENTCARDRESETACTION ,         
KEYDOCUMENTCARDUPDATE ,              
KEYDOCUMENTCARDDELETE ,              
KEYDOCUMENTCARDONDELETE ,   
KEYDOCUMENTCARDTABCLICK,
KEYDOCUMENTCARDGETEVENTS,
SIGNATUREREADYTOSIGN,
KEYDOCUMENTSUPDATETASK,
KEYDOCUMENTCARDONCANCEL,
KEYDOCUMENTCARDONRUNACTION,
KEYDOCUMENTCARDEVENTFORMCHANGED,
KEYDOCUMENTCARDRUNACTION
  } from '../../actions/actionTypes'
  
import { dialogType } from 'app/components/dialog/confirmDialog/'
    
const defaultState = {
  tabs: { primaryActive: 'Общие данные', secondaryActive: '' },
  commonDataForm : {
    name: '',
    keyCarrierSerialNumber: '',
    keyCarrierTypeId: null,
    expirationDate: {
      timeless: false,
      date: new Date()
    },
    serialNumber: '',
    identificationNumber: '',
    single: false
  },
  keyDocumentData: {},
  eventRunObject: null,
  confirmDeleteObject: null,
  deletedInfoObject: null,
  inProgress: false
}
  
export default (state = defaultState, action) => {
  const { payload } = action
  const { keyDocumentData, commonDataForm, tabs, inProgress } = state
    
  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case KEYDOCUMENTCARDGET:
      const { keyCarrierType: getkeyCarrierType, expirationDate: expDate } = payload

      return {
          ...state,
          commonDataForm: {
            ...payload, 
            keyCarrierTypeId: getkeyCarrierType,
            expirationDate: expDate ? { timeless: false, date: expDate } : { timeless: true, date: null }
          },
          keyDocumentData: {
            ...payload,
            keyCarrierTypeId: getkeyCarrierType,
            expirationDate: expDate ? { timeless: false, date: expDate } : { timeless: true, date: null }
          },
          inProgress: false
      }

    case KEYDOCUMENTCARDUPDATEFORMDATA: 
      const { key, value } = payload

      return {
        ...state,
        commonDataForm: {...commonDataForm, [key]: value }
      }

    case KEYDOCUMENTCARDTABCLICK:
      return {
        ...state,
        tabs: {...tabs,...payload}
      }

    case KEYDOCUMENTCARDUPDATE:
      const { keyCarrierType: updateKK, expirationDate: expireDate } = payload

      return {
          ...state,
          commonDataForm: {
            ...payload, 
            keyCarrierTypeId: updateKK,
            expirationDate: expireDate ? { timeless: false, date: expireDate } : { timeless: true, date: null }
          },
          keyDocumentData: {
            ...payload,
            keyCarrierTypeId: updateKK,
            expirationDate: expireDate ? { timeless: false, date: expireDate } : { timeless: true, date: null }
          },
          inProgress: false
      }

    case CLEARERROR:
    case KEYDOCUMENTCARDRESETACTION:
      return {
        ...defaultState,
        tabs: {...tabs},
        keyDocumentData,
        commonDataForm,
        inProgress
      }
      
    case KEYDOCUMENTSUPDATETASK:
    case SIGNATUREREADYTOSIGN:
    case KEYDOCUMENTCARDGETEVENTS:
    case FIELDSERROR:
    case ERROR: 
    
      return {
        ...state,
        inProgress: false
      }

    case KEYDOCUMENTCARDEVENTFORMCHANGED:
      const { eventRunObject } = state
      const { formData } = eventRunObject

      return {
        ...state,
        eventRunObject: {
          ...eventRunObject,
          formData: {...formData, ...payload}
        }
      }

    case KEYDOCUMENTCARDINPROGRESS:
      return {
        ...state,
        inProgress: payload
      }

    case KEYDOCUMENTCARDONRUNACTION:
      const { eskzi, action: eventTemplate } = payload
      const { model } = eventTemplate

      return {
        ...state,
        eventRunObject: {
          action: eventTemplate,
          item: eskzi,
          formData: model.reduce((acc, cur) => {
            acc[cur.key] = cur.defaultValue || null

            return acc
          }, {})
        },
      }

    case KEYDOCUMENTCARDONCANCEL:
      return {
        ...state,
        commonDataForm: {...keyDocumentData}
      }

    case KEYDOCUMENTCARDRUNACTION:
      const { keyCarrierType: afterActionkeyCarrierType, expirationDate: afterActionExpDate } = payload

      const newData = {
        ...payload, 
        keyCarrierTypeId: afterActionkeyCarrierType,
        expirationDate: afterActionExpDate ? { timeless: false, date: afterActionExpDate } : { timeless: true, date: null }
      }

      return {
          ...state,
          commonDataForm: newData,
          keyDocumentData: newData,
          inProgress: false,
          eventRunObject: null
      }

    case KEYDOCUMENTCARDDELETE:
      return {
        ...state,
        deletedInfoObject: {
          type: dialogType.success,
          title: `Ключевой документ успешно удален`
        },
        inProgress: false
      }

    case KEYDOCUMENTCARDONDELETE:
      const { name } = payload

      return {
        ...state,
        confirmDeleteObject: {
          type: dialogType.confirm,
          title: `Вы уверены, что хотите удалить ключевой документ «${name}»?`
        },
        inProgress: false
      }

    default: 
      return state
  }
}