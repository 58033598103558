import { detect, BrowserInfo, BotInfo, NodeInfo } from 'detect-browser';
import { SignatureFormat } from '../types';

const CAPICOM_CURRENT_USER_STORE = 2;
const CAPICOM_MY_STORE = 'My';
const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
const CADESCOM_BASE64_TO_BINARY = 1;

const CADESCOM_CADES_BES = 1;
const CADESCOM_CADES_T = 0x5;
const CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d;
const CADESCOM_PKCS7_TYPE = 0xffff;

export const SignatureTypesMapping: any = {
  [SignatureFormat.CADES_BES]: CADESCOM_CADES_BES,
  [SignatureFormat.CADES_T]: CADESCOM_CADES_T,
  [SignatureFormat.CADES_X_LONG_TYPE_1]: CADESCOM_CADES_X_LONG_TYPE_1,
  [SignatureFormat.PKCS7]: CADESCOM_PKCS7_TYPE,
}

interface IPluginObject extends HTMLObjectElement {
  CreateObject: (name: string) => {};
}

export async function call(name: string) {
  const browser = detect() as BrowserInfo;

  if (!browser) {
    throw new Error('Ошибка определения браузера.');
  }

  try {
    if (isPPAPI(browser)) {
      (window as any).cadesplugin = {};
      await createPluginScript(browser);
      const obj = await (window as any).cpcsp_chrome_nmcades.CreatePluginObject();
      return obj.CreateObjectAsync(name);
    }

    if (isNPAPI(browser)) {
      createPluginObject(browser);

      if (browser.name === 'ie') {
        if (name.match(/X509Enrollment/i)) {
          const certEnrollClassFactory = document.getElementById('certEnrollClassFactory');
          if (certEnrollClassFactory) {
            return (certEnrollClassFactory as IPluginObject).CreateObject(name);
          }
        }

        const webClassFactory = document.getElementById('webClassFactory');
        if (webClassFactory) {
          try {
            return (webClassFactory as IPluginObject).CreateObject(name);
          } catch {
            // @ts-ignore
            return ActiveXObject(name);
          }
        }
      }

      const cadesPluginObject = document.getElementById('cadesPluginObject');
      if (cadesPluginObject) {
        return (cadesPluginObject as IPluginObject).CreateObject(name);
      }
    }
  } catch (e) {
    throw new Error('Ошибка загрузки плагина CryptoPro: плагин недоступен.');
  }
}

function isPPAPI(browser: BrowserInfo | BotInfo | NodeInfo) {
  switch (browser.name) {
    case 'chrome':
    case 'opera':
    case 'yandexbrowser': {
      return true;
    }
    case 'firefox': {
      return browser.version ? Number(browser.version.split('.')[0]) >= 52 : false;
    }
    default:
      return false;
  }
}

function isNPAPI(browser: BrowserInfo | BotInfo | NodeInfo) {
  switch (browser.name) {
    case 'ie': {
      return true;
    }
    case 'firefox': {
      return browser.version ? Number(browser.version.split('.')[0]) < 52 : false;
    }
    default:
      return false;
  }
}

function getExtensionString(browser: BrowserInfo | BotInfo | NodeInfo): Promise<string> {
  return new Promise((resolve, reject) => {
    const { name } = browser;

    if (name === 'opera' || name === 'yandexbrowser') {
      resolve('chrome-extension://epebfcehmdedogndhlcacafjaacknbcm/nmcades_plugin_api.js');
    }

    if (name === 'chrome') {
      resolve('chrome-extension://iifchhfnnmpdbibifmljnfjhpififfog/nmcades_plugin_api.js');
    }

    if (name === 'firefox') {
      window.postMessage('cadesplugin_echo_request', '*');

      setTimeout(() => reject(), 1000);

      const eventHandler = (event: MessageEvent) => {
        const { data } = event;
        const isLoaded = typeof data === 'string' && data.match('cadesplugin_loaded');

        if (isLoaded) {
          const extensionString = data.split('url:')[1];
          resolve(extensionString);
        }
      };

      window.addEventListener('message', eventHandler, false);
      return;
    }

    reject();
  });
}

function createPluginScript(browser: BrowserInfo | BotInfo | NodeInfo) {
  if (document.getElementById('cadesPluginScript')) {
    return Promise.resolve();
  }

  return new Promise(async (resolve, reject) => {
    const script = document.createElement('script');
    script.id = 'cadesPluginScript';
    script.type = 'text/javascript';
    script.src = await getExtensionString(browser);
    script.onload = () => setTimeout(() => resolve(void 0));
    script.onerror = (error) => {
      console.log(error);
      reject();
    };
    document.body.appendChild(script);
  });
}

function createPluginObject(browser: BrowserInfo | BotInfo | NodeInfo) {
  if (!document.getElementById('cadesPluginObject')) {
    const element = document.createElement('object');
    element.setAttribute('id', 'cadesPluginObject');
    element.setAttribute('type', 'application/x-cades');
    element.setAttribute('style', 'height: 0');
    document.body.appendChild(element);
  }

  if (browser.name === 'ie') {
    if (!document.getElementById('certEnrollClassFactory')) {
      const element = document.createElement('object');
      element.setAttribute('id', 'certEnrollClassFactory');
      element.setAttribute('classid', 'clsid:884e2049-217d-11da-b2a4-000e7bbb2b09');
      element.setAttribute('style', 'height: 0');
      document.body.appendChild(element);
    }

    if (!document.getElementById('webClassFactory')) {
      const element = document.createElement('object');
      element.setAttribute('id', 'webClassFactory');
      element.setAttribute('classid', 'clsid:B04C8637-10BD-484E-B0DA-B8A039F60024');
      element.setAttribute('style', 'height: 0');
      document.body.appendChild(element);
    }
  }
}

function encodeToB64(data: string) {
  return btoa(
    encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(Number('0x' + p1));
    }),
  );
}

function promiseTimeout(ms: number, promise: Promise<{ name: string; version: string; plugin: string }>) {
  const timeout = new Promise((reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(void 0);
    }, ms);
  });

  return Promise.race([promise, timeout]);
}

export async function getInfo(): Promise<any> {
  const promise = async () => {
    const connect = await call('CAdESCOM.About');

    const info = {
      name: await connect.CSPName(),
      version: await connect.ProviderVersion(),
      plugin: await connect.Version,
    };

    return info;
  };

  return promiseTimeout(3000, promise());
}

export async function getCert(hash: string) {
  const store = await call('CAdESCOM.Store');
  await store.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE, CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

  const certs = await store.Certificates;
  const certsByHash = await certs.Find(CAPICOM_CERTIFICATE_FIND_SHA1_HASH, hash);
  const cert = await certsByHash.Item(1);

  store.Close();

  return cert;
}

export async function getCerts() {
  const store = await call('CAdESCOM.Store');

  await store.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE, CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

  const certificates = await store.Certificates;
  const count = await certificates.Count;
  const certs = [];

  for (let i = 1; i <= count; i++) {
    const item = await certificates.Item(i);
    const cert = {
      serial: await item.SerialNumber,
      hash: await item.Thumbprint,
      version: await item.Version,
      issuer: await item.IssuerName,
      subject: await item.SubjectName,
      from: await item.ValidFromDate,
      to: await item.ValidToDate,
    };
    certs.push(cert);
  }

  store.Close();
  return certs;
}

export async function signData(hash: string, data: string, signType: boolean, signatureFormat: SignatureFormat = SignatureFormat.CADES_BES): Promise<string> {
  data = encodeToB64(data);

  const cert = await getCert(hash);
  const signer = await call('CAdESCOM.CPSigner');
  const signerData = await call('CAdESCOM.CadesSignedData');

  try {
    await signer.propset_Certificate(cert);
    await signerData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
    await signerData.propset_Content(data);
  } catch (error) {
    signer.Certificate = cert;
    signerData.ContentEncoding = CADESCOM_BASE64_TO_BINARY;
    signerData.Content = data;
  }
  
  const cpSignatureFormat = signatureFormat in SignatureTypesMapping
    ? SignatureTypesMapping[signatureFormat]
    : SignatureTypesMapping[SignatureFormat.CADES_BES];

  const signature = await signerData.SignCades(signer, cpSignatureFormat, signType);

  return signature;
}
