import React from 'react'
import LoginPasswordSubmit from './LoginPasswordSubmit'
import useDomainLogin from '../hooks/useDomainLogin'
import LoginFormWrapper from './LoginFormWrapper'

export default function DomainAccountLogin(props){

  const {
    headerTitle,
    login,
    loginHint,
    passwordError,
    loginError,
    password,
    onLoginSubmit,
    onLoginChange,
    onPasswordChange,
  } = useDomainLogin(props)

  return (
    <LoginFormWrapper headerTittle={headerTitle}>
      <LoginPasswordSubmit
        login={login}
        loginHint={loginHint}
        onLoginChange={onLoginChange}
        onSubmit={onLoginSubmit}
        onPasswordChange={onPasswordChange}
        passwordError={passwordError}
        loginError={loginError}
        password={password}
        id='domain-'
      />
    </LoginFormWrapper>
  )
}