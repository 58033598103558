import React from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {linkTargets} from './constants'
import Button, { buttonTypes } from 'app/components/ui/button/button'
import { ReactComponent as NewWindowImg }  from 'assets/img/commonVer2/new_window.svg'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'

export default function InputLinkControl({targetType, id}) {
  const history = useHistory()
  const profile = useSelector(({profile}) => profile)
  const rights = getResourceRights(profile, RESOURCES[targetType])
  return (
    rights.VIEW_CARD ? <Button
      type={buttonTypes.imageSmall}
      className='button-image control__custom-image control__custom-link'
      onClick={(e) => {
        e.preventDefault()
        history.push(`${linkTargets[targetType]}${id}`)
      }}
    >
      <NewWindowImg />
    </Button> : ''
  )
}