import {
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  CLEARERROR,
  SKZIBUILDCARDGET,
  SKZISELFSERVICEBUILDCARDGET,
  SKZIBUILDCARDUPDATE,
  SKZIBUILDCARDUPDATEFORMDATA,
  SKZIBUILDCARDINPROGRESS,
  SKZIBUILDCARDRESETACTION,
  SKZIBUILDCARDGETTYPES,
  SKZIBUILDCARDONSELECT,
  SKZIBUILDCARDONTYPEADD,
  SKZIBUILDCARDONFILEADD,
  SKZIBUILDCARDONTYPEDELETE,
  SKZIBUILDCARDTYPEDELETE,
  SKZIBUILDCARDTYPEADD,
  SKZIBUILDCARDSETMAINTYPE,
  SKZIBUILDCARDONFILEDELETE,
  SKZIBUILDCARDFILEDELETE,
  SIGNATUREREADYTOSIGN,
  SKZI_BUILD_CARD_ADD,
  SKZI_BUILD_CARD_CLEAR_FORM
} from '../../actions/actionTypes'
  
  import { dialogType } from 'app/components/dialog/confirmDialog/'
  import { excludeByItemId, arrayToIdHashMap } from 'app/core/utility/common'
  
  const defaultState = {
    selected: {
      items: [],
      selectedHashMap: {}
    },
    commonDataForm : {
      name: '',
      classId: null,
      certificate: '',
      certificateExpirationDate: null,
      createDate: null,
      description: ''
    },
    viewData: {
      name: '',
      classId: null,
      certificate: '',
      certificateExpirationDate: null,
      createDate: null,
      description: '',
      producer: '',
      skzi: '',
      version: '',
      type: ''
    },
    createTypeForm : {

    },
    skziBuildData: {},
    confirmObject: null,
    addTypeDialog: false,
    addFilesDialog: false,
    deleteTypeConfirm: null,
    currentlyDeletingFiles: {},
    inProgress: false
  }
  
  export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { skziBuildData, viewData, selected, currentlyDeletingFiles } = state
    const { documentation } = skziBuildData

    switch (action.type) {
      case SKZIBUILDCARDONSELECT: 
        return {
          ...state,
          selected: payload || defaultState.selected
        }

      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case SKZIBUILDCARDUPDATE:
      case SKZISELFSERVICEBUILDCARDGET:
      case SKZI_BUILD_CARD_ADD:
      case SKZIBUILDCARDGET:
        const { skziVersion, skzi } = payload
        const { name: skziName, producer, type } = skzi || {}
        const { name: skziVersionName } = skziVersion || {}
        const { name: typeName } = type || {}
        const { name: producerName } = producer || {}
        const data = {
          ...payload, 
          classId: payload.class,
          producer: producerName,
          skzi: skziName,
          version: skziVersionName,
          type: typeName
        }

        return {
          ...state,
          commonDataForm: data,
          viewData: data,
          skziBuildData: {...payload},
          inProgress: false
        }

      case SKZIBUILDCARDSETMAINTYPE:
        const updatedDocumentation = documentation.map(doc => ({
          ...doc,
          main: doc.id === payload.id
        }))

        return {
          ...state,
          skziBuildData: {
            ...skziBuildData,
            documentation: updatedDocumentation
          },
          inProgress: false
        }

      case SKZIBUILDCARDTYPEADD:
        return {
          ...state,
          skziBuildData: payload,
          inProgress: false
        }
   
      case SKZIBUILDCARDONTYPEADD:
        return {
          ...state,
          addTypeDialog: payload,
          inProgress: false
        }

      case SKZIBUILDCARDONFILEDELETE:
        const { guid: deletingFileGuid } = payload

        return {
          ...state,
          currentlyDeletingFiles: {
            ...currentlyDeletingFiles,
            [deletingFileGuid]: deletingFileGuid
          }
        }

      case SKZIBUILDCARDFILEDELETE:
        const { guid, typeElement } = payload
        const affectedTypeIndex = documentation.findIndex(item => item.id === typeElement.id)
        const updatedFiles = documentation[affectedTypeIndex].files.filter(file => file.guid !== guid)

        const afterDeleteFileDocumentation = [
          ...documentation.slice(0, affectedTypeIndex),
          {
            ...documentation[affectedTypeIndex],
            files: updatedFiles
          },
          ...documentation.slice(affectedTypeIndex + 1),
        ]
        
        return {
          ...state,
          skziBuildData: {
            ...skziBuildData,
            documentation: afterDeleteFileDocumentation
          },
        }

      case SKZIBUILDCARDONFILEADD:
        return {
          ...state,
          addFilesDialog: true,
        }

      case CLEARERROR:
        return {
          ...state,
          confirmObject: null,
          deleteTypeConfirm: null,
          inProgress: false
        }

      case SKZI_BUILD_CARD_CLEAR_FORM:
        return {
          ...defaultState,
          commonDataForm: {
            ...defaultState.commonDataForm,
            createDate: new Date()
          }
        }

      case FIELDSERROR:
      case ERROR:
        return {
          ...state,
          inProgress: false
        }

      case SKZIBUILDCARDGETTYPES:
        return {
          ...state,
          skziBuildData: {
            ...skziBuildData,
            documentation: payload
          },
          inProgress: false
        }
   
      case SKZIBUILDCARDONTYPEDELETE:

        return {
          ...state,
          deleteTypeConfirm: {
            item: payload,
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить элементы: «${payload.map(item => item.objectType.name).join(', ')}»?`
          },
          inProgress: false
        }

      case SKZIBUILDCARDTYPEDELETE:
        const { items: selectedItems, } = selected
        let confirmDialog = null
        const deletedTypeToBuildLink = []

        const currentlySelectedByTypeId = selectedItems.reduce((acc, cur) => {
          acc[cur.objectType.id] = cur
          return acc
        }, {})

        payload.forEach(typeId => {
          if(currentlySelectedByTypeId[typeId]) {
            deletedTypeToBuildLink.push(currentlySelectedByTypeId[typeId])
            delete currentlySelectedByTypeId[typeId]
          }
        })
        const afterDeleteSelected = Object.keys(currentlySelectedByTypeId).reduce((acc, cur) => {
          acc.push(currentlySelectedByTypeId[cur])
          return acc
        }, [])

        if (errors && errors.length) {
          confirmDialog = {
            header: 'Удаление невозможно',
            type: dialogType.warning,
            messages: errors.map(item => item.errorMessage)
          }
        }
  
        return {
          ...state,
          selected: {
            items: afterDeleteSelected,
            selectedHashMap: arrayToIdHashMap(afterDeleteSelected)
          },
          skziBuildData: {
            ...skziBuildData,
            documentation: excludeByItemId(documentation, deletedTypeToBuildLink)
          },
          deleteTypeConfirm: null,
          confirmObject: confirmDialog,
          inProgress: false
        }

      case SKZIBUILDCARDUPDATEFORMDATA:

        return {
          ...state,
          commonDataForm: {...payload, classId: payload.classId},
        }

      case SIGNATUREREADYTOSIGN:
        return {
          ...state,
          inProgress: false
        }

      case SKZIBUILDCARDINPROGRESS:
        return {...state, inProgress: payload}
  
      case SKZIBUILDCARDRESETACTION: 
  
        return { 
          ...defaultState,
          viewData: viewData,
          selected: selected,
          skziBuildData: skziBuildData,
          commonDataForm: viewData,
        }
  
      default: 
        return state
    }
  }