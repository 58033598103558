import React, { useEffect, useMemo } from 'react';
import FunctionalCard from '../../card/FunctionalCard';
import useFunctionalCard from '../../card/FunctionalCard/useFunctionalCard';
import { setErrorByKeyFormAction } from 'redux/actions/common';
import useEventForm from './useBusinessEventForm';
import BusinessEventContent from './businessEventContent';
import { ErrorInfo } from 'app/components/Notification/allNotificationPage/notificationDetail/bulkOperationResult'
import { useSelector } from 'react-redux';

export default function BusinessEventForm(props) {
  const {
    items,
    className,
    formBody,
    viewModel,
    onCancel,
    getSignRequestData,
    generateTextErrors,
    onSubmitSignEnhance,
    type,
    entityType,
    isExternalUser,
    needForm = true,
    defaultSignData,
    eventCode,
    formAlert = ''
  } = props;

  const error = useSelector(({error}) => error)
  const { errorByKeys = [] } = error
  const errors = Array.from(new Set(errorByKeys.map(i => i.errorMessage)))
  const { id, model } = viewModel || {};
  const [firstItem = {}] = items || [];
  const { formData } = useEventForm({ viewModel });
  const objectIds = items ? items.map((i) => i.id) : [];
  const organizationIds = items ? items.map((i) => i.okz ? i.okz.id : i.oki.id) : [];

  const {
    cardState,
    fieldsError,
    commonDataForm,
    validate,
    onFormChange,
    dispatchStore,
  } = useFunctionalCard({
    formData,
    parentEditState: true,
    serverFormViewModel: model,
  });

  useEffect(() => {
    dispatchStore(setErrorByKeyFormAction(generateTextErrors));
  }, [generateTextErrors]);

  // для случая когда форма не нужна,
  // но при этом в модели поле есть
  useEffect(() => {
    if (!needForm) {
      onSubmitSignEnhance(
        getSignRequestData({
          ...defaultSignData,
          eventTemplateId: id,
          journalObjects: items && items.length > 1 ? items : null,
          item: firstItem,
          type,
          isExternal: isExternalUser
        })
      );
    }
  }, [])
  
  // TODO
  // надо использовать этот компонент, а не formAlert.
  // т.е. удалить везде formAlert, обернуть <BusinessEventContent ниже
  // в FormBody и использовать пропс formBody
  const FormBody = useMemo(() => formBody ? formBody : ({ children }) => children, [formBody]);

  return needForm ? (
    <>
      {errors.length ? 
        <ErrorInfo
          messages={errors}
        />
      : null}
      <FunctionalCard
        showButtons={true}
        cardState={cardState}
        editOnly={true}
        onSubmit={() => {
          if (validate()) {
            return;
          }
          onSubmitSignEnhance(
            getSignRequestData({
              ...commonDataForm,
              eventTemplateId: id,
              /// TODO
              // после рефакторинга, когда проверки в вызове апи можно будет исправить, вместо null возвращать [], либо вообще переделать формирование journalObjects
              journalObjects: items && items.length > 1 ? items : null,
              item: firstItem,
              type,
              isExternal: isExternalUser
            })
          );
        }}
        onCancelForm={onCancel}
        className={className}
      >
        {/* <FormBody> */}
          <BusinessEventContent
            items={items}
            entityType={entityType}
            objectIds={objectIds}
            organizationIds={organizationIds}
            cardState={cardState}
            formData={commonDataForm}
            model={model}
            eventCode={eventCode}
            onChange={onFormChange}
            errorByFields={fieldsError}
            isExternalUser={isExternalUser}
            formAlert={formAlert}
          />
        {/* </FormBody> */}
      </FunctionalCard>
    </>
  ) : null;
}
