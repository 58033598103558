import React, { Component } from 'react'
import { connect } from 'react-redux'

import FileUploadControl from 'app/components/ui/file/fileUploadControl'
import { fileListAPI } from 'app/core/utility/file'
import Button from 'app/components/ui/button/button'
import { capitalize, serverlikeErrors } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import Card from 'app/components/ui/card/cardMaterial'
import { cardLines } from 'app/components/admittance/admittanceCard/admittanceData'

import * as errorAction from 'redux/actions/common'

class addAdmittanceDialog extends Component {
  constructor(props){
    super(props)

    this.card = React.createRef()
    this.fileControl = React.createRef();

    this.state = this._getState(props)
  }

  _getState = (props) => {
    const { admittanceParams, profile } = props
    const { 
      admittanceId = 0,
      userInfo
    } = admittanceParams
    const { userInfo: profileUser } = profile

    const initData = {
      name: '',
      issueDate: null,
      skziInfo: null,
      userId: userInfo,
      signers: [{...profileUser}],
      status: 'Создание',
      id: 'Черновик',
      createDate: new Date(),
      changeDate: new Date(),
      issuer: {user: profileUser},
      lastUpdater: {user: profileUser}
    }

    return { 
      data: {...initData},
      formData: {...initData},
      admittanceId,
      files: [],
    } 
  }

  _onFilesUpload = () => {
    const { current } = this.fileControl

    current._upload()
  }

  _onAdmittanceSubmit = async () => {
    const { current } = this.card

    current._onApply()
  }

  _onCardSubmit = async () => {
    const { onAdmittanceAdd, admittanceParams } = this.props
    const { id } = admittanceParams
    const { formData } = this.state

    onAdmittanceAdd({ admittanceId: id, ...formData })
  }

  _onCancel = async () => {
    const { onCancel, clearErrorAction } = this.props

    clearErrorAction()
    onCancel()
  }

  _onFileChange = (files) => {
    this.setState({files: files})
  }

  _uploadFinished = () => {
    const { onFileUploaded } = this.props

    onFileUploaded && onFileUploaded()
  }

  _allFilesProgressComplete = () => {
    this.setState({ allFilesProgressComplete: true })
  }

  _getAdmittanceKey = (data) => {
    const { name, issueDate, skziInfo, signers = [] } = data
    const { skzi, version } = skziInfo || {}
    const { id: skziId } = skzi || {}
    const { id: versionId } = version || {}

    return `${name}${skziId}${versionId}${formatDate(issueDate, 'dd.mm.yyyy')}${signers.map(item => item.id).join('')}`
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { setErrorByKey, error } = this.props
    const { formData } = this.state
    const { errorByFields }  = error || {}

    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    setErrorByKey(filteredErrors)
    this.setState({ formData: {...formData, [key]: value}})
  }

  _renderAdmittanceData = () => {
    const { 
      error
    } = this.props
    const { formData, data } = this.state
    const { fieldsError } = error || {}

    return (
      <Card
        ref={this.card}
        showButtons={false}
        editOnly={true}
        className='add-admittance-dialog__data'
        formLines={cardLines}
        data={data}
        editOnOpen={true}
        formData={formData}
        onValidationError={this._onValidation}
        errorByFields={fieldsError}
        onChange={this._onFormChange}
        onSubmit={this._onCardSubmit}
        userEnabled={false}
      />
    )
  }

  render() {
    const { 
      admittanceParams   
    } = this.props
    const { formData, data } = this.state
    const { id: admittanceId } = admittanceParams
    const { skziInfo } = formData
    const urlPart = `admittance/${admittanceId}/content/add`
    const cancelButtonText = 'Отмена'
    const headerText = 'Добавление заключения о допуске'
    const fileApi = fileListAPI( null, urlPart )
    const hasChanges = this._getAdmittanceKey(formData) !== this._getAdmittanceKey(data)

    return (
      <div className="add-admittance-dialog">
        <div className="add-admittance-dialog__header">{headerText}</div>
        {this._renderAdmittanceData()}
        <FileUploadControl
          ref={this.fileControl}
          className='add-admittance-dialog__file-area'
          title='Добавить вложение'
          fileApi={fileApi}
          onFileChanged={this._onFileChange}
          onUploadFinish={this._uploadFinished}
          disabled={!skziInfo}
        />
        <div className="add-admittance-dialog__buttons-panel">
          <Button 
            type='text'
            onClick={this._onAdmittanceSubmit}
            disabled={!hasChanges}
          >
            <span className='button-title'>{admittanceId ? 'Сохранить' : 'Добавить'}</span>
          </Button>
          <Button 
            type='text'
            onClick={this._onCancel}
          >
            <span className='button-title'>{cancelButtonText}</span>
          </Button>
        </div>
      </div>
    )
  }
}

export default connect(
  state => state.error,
  { ...errorAction },
  null,
  { forwardRef: true }
)(addAdmittanceDialog)