//TODO компонент требует рефакторинга
//switch case не лучшая идея внутри общего компонента, возможно лучше использовать справочники
//или придумать что-то другое
import React, { useEffect, useState } from 'react';
import {Table} from 'app/components/list/Table/Table';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import Picker,  { SelectType } from 'app/components/ui/picker/picker';
import withModal from 'app/components/HOC/ObsoleteModalHOC';
import ButtonComponent from 'app/components/ui/button/button';
import { Services } from 'app/components/ui/picker/picker';
import withTooltip from '../HOC/TooltipHOC';

const Button = withTooltip(ButtonComponent)


const ModalPicker = withModal(Picker)
const headerText = (serviceType) => {
  switch (serviceType) {
    case Services.keyCarrier:
      return 'ключевого носителя'
    case Services.skziInstance:
      return 'экземпляра'
    case Services.keyDocument:
      return 'ключевого документа'
  }
}

const renderTagName = (serviceType, item) => {
  switch (serviceType) {
    case Services.keyCarrier:
      return `Серийный № ${item.serialNumber}`
    case Services.keyDocument:
      return `Серийный № ${item.serialNumber}`
    case Services.skziInstance:
      return `Регистрационный № ${item.serialNumber}`
  }
}

const renderPlaceholder = (serviceType) => {
  switch (serviceType) {
    case Services.keyCarrier:
      return `Серийный №`
    case Services.keyDocument:
      return `Серийный №`
    case Services.skziInstance:
      return `Регистрационный №`
  }
}

const onSelect = (
  items, 
  onAddLocalStateValues, 
  serviceType,
  setShowPicker
) => {

  setShowPicker(false)
  switch (serviceType) {
    case Services.keyCarrier:
      return onAddLocalStateValues(items.map(item => ({
        ...item,
        children: [...item.keyDocuments]
      })))
    case Services.keyDocument:
      return onAddLocalStateValues(items.map(item => ({
        ...item,
        children: item.keyCarrier && item.keyCarrier.single ? [item.keyCarrier] : null
      })))
    case Services.skziInstance:
      return onAddLocalStateValues(items.map(item => ({
        ...item,
        children: [...item.keyDocuments]
      })))
  } 
}

export default ({ 
  config, 
  onListChanged, 
  organizationId, 
  serviceType,
  parentData
}) => {

  const {
    syncFrontListSortAction,
    sortListAction,
    sortListSetAction,
    setSelectedAction,
    onInitValues,
    onAddValues,
    onRemoveValues,
    onClearList,
    onRemoveSelected,
    inProgress,
    sorting,
    selectedItems,
    list,
    paginator,
  } = useTableExtensions();

  useEffect(() => {
    parentData && onInitValues(parentData)
  }, [parentData])

  useEffect(() => {
      list 
      && list.length 
      && onClearList()
  }, [organizationId])

  useEffect(() => {
    onListChanged && onListChanged(list)
  }, [list.length])

  useEffect( () => { 
    syncFrontListSortAction( sorting, config.fieldsConfig, list )
  }, [ sorting ]);

  const [ showPicker, setShowPicker ] = useState(false)
  const fieldsConfig = config[ 'fieldsConfig' ];
  const pageConfig = config[ 'pageConfig' ];
   
  const pickerService = {
    serviceName: serviceType,
    serviceMethod: 'destructionAvailable',
    data: {organizationId}
  }

  return( 
    <>
      {showPicker && organizationId ? (
        <ModalPicker
          serviceType={serviceType}
          notStandardService={pickerService}
          disabledItems={list}
          // excludeItems={list}
          selectType={SelectType.multiple}
          onCancel={() => setShowPicker(false)} 
          onSelect={values => onSelect(values, onAddValues, serviceType, setShowPicker)}
          searchPlaceholder={renderPlaceholder(serviceType)}
          renderTagName={item => renderTagName(serviceType, item)}
          renderHeader = {`Добавление ${headerText(serviceType)}`}
        />
      ) : null}
      {!parentData ? (
        <div className="manage-panel">
          <Button
            className='create-button'
            type='primary'
            disabled={!organizationId}
            onClick={() => setShowPicker(true)}
          >
            <PlusImg className='button-image button-image--left'></PlusImg>
            <span className='button-title'>Добавить</span>
          </Button>
          <Button 
            type='image'
            tooltip={'Удалить'}
            onClick={() => onRemoveSelected(selectedItems)}
            disabled={!selectedItems.length}
          >
            <DeleteImg className='button-image'></DeleteImg>
          </Button>
        </div>
      ) : null}
      <Table 
        sortListAction={sortListAction} 
        // sortListAction={listSortInFrontAction} 
        sortListSetAction={sortListSetAction}
        paginator={paginator}
        sorting={sorting}
        selectedItems={selectedItems}
        setSelectedAction={setSelectedAction} 
        inProgress={inProgress}
        list={list}
        fieldsConfig = { fieldsConfig }
        itemsSelectable = { !parentData }
        itemsSelectableRadio = { pageConfig.itemsSelectableRadio }
        defaultSortByAlias = { pageConfig.defaultSortByAlias } 
        changedFieldsVisibility = { pageConfig.changedFieldsVisibility === true }
        onItemClick = { pageConfig.onItemClick }
        headerInfo = { pageConfig.headerInfo === true }
      />  
    </>
  );
}