import React, {useEffect} from 'react'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import Overlay from 'app/components/ui/overlay'

import FunctionalCard from '../../ui/card/FunctionalCard'
import useFunctionalCard from '../../ui/card/FunctionalCard/useFunctionalCard'
import { organizationValidationRules } from '../formData/formValidationRules'
import service from '../../../services/service'
import OrganizationForm from '../forms/OrganizationForm'
import { defaultOrganizationFormState } from '../formData/formStates'
import useBlockEditState from '../hooks/useBlockEditState'

export default function OrganizationBlock({authenticationDataValid, setOrganizationsDataValid}) {

  const {
    dataValid,
    setDataValid,
    editStateBlock,
    setEditStateBlock,
    checkValidDataCallback,
    checkValidResponse
  } = useBlockEditState(setOrganizationsDataValid)

  const formData = {
    validationRules: organizationValidationRules,
    defaultState: defaultOrganizationFormState
  }

  const get = () => service('activeDirectorySettingsService', 'getOrganizationsSettings').then(checkValidDataCallback)

  const update = (data) => {
    return service('activeDirectorySettingsService', 'updateOrganizationsSettings', data)
  }

  const applyRightsToActions = () => {
    return ["edit", "delete"]
  }

  const {
    onSubmit,
    submitEnabled,
    onCancelForm,
    cardState,
    editState,
    setState,
    inProgress,
    fieldsError,
    onFormChange,
    commonDataForm,
    onAdd,
    onRemove
  } = useFunctionalCard({formData, get, update, applyRightsToActions, updateCallback: checkValidResponse, parentEditState: editStateBlock})

  useEffect(()=> {
    setEditStateBlock(editState)
  }, [editState])

  return (
    <ExpandingBlock
      renderHeader= {() => 'Данные организаций'}
      initialState={authenticationDataValid && !dataValid}
      disabled={!authenticationDataValid}
    >
      {inProgress ? <Overlay /> : null}
      <FunctionalCard
        showButtons={true}
        cardState={cardState}
        canEdit={true}
        editOnly={false}
        onSubmit={onSubmit}
        submitEnabled={submitEnabled}
        submitTitle="Сохранить"
        onCancelForm={onCancelForm}
        setState={setState}
        className="ad-settings-data-card"
        actions={applyRightsToActions()}
        editState={editState}
        cancelEnabled={dataValid}
      >
        <div className="card-material__content">
          <OrganizationForm
            cardState={cardState}
            formData={commonDataForm}
            serverFormViewModel={null}
            onChange={onFormChange}
            errorByFields={fieldsError}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        </div>
      </FunctionalCard>
    </ExpandingBlock>
  )
}