/** @format */

import React from 'react';
import { getClassName } from 'app/core/utility/jsx';
import styles from './filter.module.scss';

export default function SmallFilter({ children, className }) {
  return (
    <div className={getClassName(['smallFilter', styles.filterSmall, className || ''])}>
      {children}
    </div>
  );
}

