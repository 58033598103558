import service from '../../../services/service'
import { defaultState } from 'redux/reducers/user/userCardReducer'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from "react-redux"
import { BREADCRUMBSPUSHITEM } from '../../../../redux/actions/actionTypes'
import { getPageBreadcrumb } from 'app/navigation/breadcrumbs'
import { addToast } from '../../../../redux/actions/toast'
import {validationRules, validationRulesADUser} from '../userCard/userData'
import {useState} from 'react'


export default function useUserBlockData({setIsADUser}) {

  const location = useLocation()
  const [editStateBlock, setEditStateBlock] = useState(false)
  const { pathname } = location
  const dispatch = useDispatch()
  const profile = useSelector(({profile}) => profile);
  dispatch({
    type: BREADCRUMBSPUSHITEM,
    payload: {
      ...getPageBreadcrumb(pathname),
      forceUpdate: true
    }
  })

  let { id } = useParams()

  const updateCallback = (response) => {
    const {data, errors, isError} = response
    if (data && !errors && !isError) {
      dispatch(addToast('Запись успешно обновлена'))
      setEditStateBlock(false)
    }
    return response
  }

  const [formData, setFormData] = useState({
    validationRules,
    defaultState,
  })

  const get = () => service('userService', 'getUser', id).then(
    response => {
      if (response && response.data && response.data.externalPath){
        setFormData({
          validationRules: validationRulesADUser(profile),
          defaultState,
        })
        setIsADUser(true)
      }
      return response
    }
  )

  const update = (data) => service('userService', 'updateUser', {
    ...data,
    organizationId: data.organization ? data.organization.id : null
  })

  return {
    formData,
    id,
    editStateBlock,
    setEditStateBlock,
    get,
    update,
    updateCallback
  }
}
