import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from 'app/components/ui/button/button'
import {ReactComponent as DwnlBtn} from 'assets/img/commonVer2/clip.svg'
import FileItem from './fileItem'
import { intersectByItemId } from 'app/core/utility/common'
import * as fileActions from 'redux/actions/common/fileActions'
import Img from 'app/components/ui/Img'
import MassImportFileItem from "./MassImportFileItem";
import HashSumInput from './HashSumInput';
import hashStatusIcons from 'app/components/skziRegistry/hashStatusIcons'

import styles from './fileUploadControl.module.scss';
import { HashStatus } from 'app/components/skziRegistry/enums'
import { cancelUploadObject, getCancelUploadObject } from './helpers'

export const fileControlState = {
  preUpload: 'preUpload',
  uploadFinished: 'uploadFinished',
  uploading: 'uploading',
}

class fileUploadControl extends Component {
  constructor(props){
    super(props)

    this.filer = React.createRef();
    this.cancellationCallbacks={}
    const uploadedFiles = Array.from(props.uploadedFiles || []).map((file, i) => ({
      blob: { name: file.name, fileSize: file.fileSize, },
      guid: file.guid,
      fileKey: Number(`${new Date().valueOf()}${i}`),
      hashSum: file.userHashSum,
      hashStatus: file.hashStatus,
    }));
    this.files=[]
    this.state = {
      progressData: {},
      hashSums: Object.fromEntries(uploadedFiles.map(f => [f.fileKey, f.hashSum])),
      uploadedFiles,
      uploadedFilesToRemove: [],
    }
  }

  componentDidMount(){
    const { current } = this.filer

    const {setUploader} = this.props
    setUploader && setUploader(this)

    // current.addEventListener('change', this._getFile);
  }

  _showFileSelect = () => {
    if (this.filer && this.filer.current) {
      this.filer.current.click();
    }
  }

  _addFileToList = (files) => {
    const { onFileChanged, withHashSums } = this.props
    const addedFileRecords = Array.from(files).map((file, i) => ({
      blob: file,
      fileKey: Number(`${new Date().valueOf()}${i}`),
    }))
    const newFileList = [
      ...this.files,
      ...addedFileRecords,
    ]

    const validated = this._validateAllFiles(newFileList)
    this.files = validated
    this.setState(prevState => ({ hashSums: {
      ...prevState.hashSums,
      ...Object.fromEntries(addedFileRecords.map(fr => [fr.fileKey, null]))
    } }))
    onFileChanged && onFileChanged( validated )
  }

  _validateAllFiles = (files) => {
    const { allFilesValidation } = this.props

    return allFilesValidation ? allFilesValidation(files) : files
  }

  _getFile = (input) => {
    const { onError } = this.props
    try {
      input.currentTarget.files
      && input.currentTarget.files.length
      && this._addFileToList(input.currentTarget.files)

    } catch (error) {
      onError && onError([error])
    }
  };

  _setCancelCallback = (cancelCallback, fileKey) => {
    this.cancellationCallbacks[fileKey] = cancelCallback
  }

  _onUploadUpdate = (progress, fileKey) => {
    const { onAllFilesProgressComplete, fileProgressAction } = this.props
    const { progressData } = this.state
    let allUploadComplete = false

    if (progress === 1) {
      const activeFiles = intersectByItemId(
        Object.keys(this.cancellationCallbacks).map(item => ({id: item})),
        this.files.map(item => ({...item, id: item.fileKey})),
      )
      allUploadComplete = activeFiles.reduce((acc,cur) => {
        const currentProgress = `${fileKey}` === cur.id
        return acc & (currentProgress || progressData[cur.id] === 1)
      }, true)

      allUploadComplete && onAllFilesProgressComplete && onAllFilesProgressComplete()
    }

    fileProgressAction({[fileKey]: progress})
    this.setState({
      progressData: {...progressData, [fileKey]: progress },
      allUploadComplete: allUploadComplete
    })
  }

  _uploadFiles = async ({customApi}) => {
    const {
      onError,
      onFileUploaded,
      fileApi,
      fileUploadFileResultAction,
      allFilesValidation
    } = this.props
    const { uploadFiles } = customApi || fileApi
    const fileKey = new Date().valueOf()
    
    try {
      const filesToUpload = allFilesValidation 
                            ? this.files.filter(i => !i.error)
                            : this.files

      const result = await uploadFiles(
        filesToUpload,
        progress => this._onUploadUpdate(progress, fileKey),
        cancelCallback => this._setCancelCallback(cancelCallback, fileKey),
      )

      const { data: axiosResult } = result || {}
      if (!axiosResult) {
        throw getCancelUploadObject(fileKey)
      }

      const { data } = axiosResult

      if (!axiosResult.isError) {
        onFileUploaded && onFileUploaded({ ...data})
        fileUploadFileResultAction({ axiosResult, fileKey })
        return {axiosResult, fileKey}
      } else {
        throw {axiosResult, fileKey}
      }
    } catch (error) {
      onError && onError(error)
      fileUploadFileResultAction(error)
      return error
    }
  }

  _uploadFile = async ({ blob, fileKey, customApi, formData, hashSum }) => {
    const {
      onError,
      onFileUploaded,
      fileApi,
      fileUploadFileResultAction
    } = this.props
    const { uploadFile } = customApi || fileApi

    try {
      const result = await uploadFile(
        blob,
        progress => this._onUploadUpdate(progress, fileKey),
        cancelCallback => this._setCancelCallback(cancelCallback, fileKey),
        formData,
        hashSum,
      )

      const { data: axiosResult } = result || {}

      if (!axiosResult) {
        throw getCancelUploadObject(fileKey)
      }

      const { data } = axiosResult

      if (!axiosResult.isError) {
        onFileUploaded && onFileUploaded({ ...data})
        fileUploadFileResultAction({ axiosResult, fileKey })
        return {axiosResult, fileKey}
      } else {
        throw {axiosResult, fileKey}
      }
    } catch (error) {
      onError && onError(error)
      fileUploadFileResultAction(error)
      return error
    }
  }
  
  _deleteFile = async ({ guid, fileKey, customApi }) => {    
    const {
      onError,
      onFileDeleted,
      fileApi,
    } = this.props;
    const { deleteFile } = customApi || fileApi;
    
    try {
      const axiosResult = await deleteFile(guid);
      if (!axiosResult) {
        throw {
          axiosResult: {
            errors: [{ errorMessage: 'Отменено' }],
            isError: true
          }, fileKey
        }
      }

      if (!axiosResult.isError) {
        onFileDeleted && onFileDeleted({ guid });
        return { axiosResult, fileKey };
      } else {
        throw { axiosResult, fileKey }
      }
    } catch (error) {
      onError && onError(error)
      return error
    }
  }
  
  _updateFile = async ({ guid, fileKey, customApi, hashSum }) => {
    const {
      onError,
      onFileUpdated,
      fileApi,
    } = this.props;
    const { updateFileMeta } = customApi || fileApi;
    
    try {
      const axiosResult = await updateFileMeta(guid, hashSum);
      
      if (!axiosResult) {
        throw {
          axiosResult: {
            errors: [{ errorMessage: 'Отменено' }],
            isError: true
          }, fileKey
        }
      }
      
      const { data } = axiosResult

      if (!axiosResult.isError) {
        onFileUpdated && onFileUpdated({ ...data });
        return { axiosResult, fileKey }
      } else {
        throw { axiosResult, fileKey }
      }
    } catch (error) {
      onError && onError(error)
      return error
    }
  }
  
  _updateInitialHashSums = () => {
    const { hashSums } = this.state;
    this.setState(prevState => ({
      uploadedFiles: prevState.uploadedFiles.map(f => ({ ...f, hashSum: hashSums[f.fileKey] }))
    }));
  }
  
  _delete = async (customApi = null) => {
    const { onDeleteFinish } = this.props;
    const { uploadedFilesToRemove } = this.state;
        
    const result = await Promise.all(uploadedFilesToRemove.map(async file => {
      const { guid, fileKey } = file;
      const result = await this._deleteFile({
        guid,
        fileKey,
        customApi,
      })

      return result;
    }));
    
    const failedFileKeys = result
      .filter(({ axiosResult }) => axiosResult.isError)
      .map(({ fileKey }) => fileKey);
    this.setState(prevState => ({
      uploadedFilesToRemove: prevState.uploadedFilesToRemove
        .filter(({ fileKey }) => !failedFileKeys.includes(fileKey))
    }));
    
    onDeleteFinish && onDeleteFinish(result);
  }
  
  _update = async (customApi = null) => {
    const { onUpdateFinish } = this.props;
    const { uploadedFiles, hashSums } = this.state;    
    const filesToUpdate = uploadedFiles.filter(f => f.hashSum !== hashSums[f.fileKey]);
        
    const result = await Promise.all(filesToUpdate.map(async file => {
      const { guid, fileKey } = file;
      const result = await this._updateFile({
        guid,
        fileKey,
        customApi,
        hashSum: hashSums[fileKey],
      });

      return result;
    }));
    
    this._updateInitialHashSums();
    onUpdateFinish && onUpdateFinish(result);
  }
  
  _upload = async (customApi = null, formData) => {
    const { onUploadFinish, fileStartUploadAction, isShowFileProgressInHeader = true, uploadAllFilesAtOnce } = this.props
    const { hashSums } = this.state;
    let result = {}
    if (this.files && this.files.length) {
      if (isShowFileProgressInHeader && !uploadAllFilesAtOnce) {
        fileStartUploadAction(this.files);
      }

      if (uploadAllFilesAtOnce) {
        result = await this._uploadFiles({customApi})
      } else {
        result = await Promise.all(this.files.map(async file => {
          const result = await this._uploadFile({
            ...file,
            customApi,
            formData,
            hashSum: hashSums[file.fileKey],
          })
          const { axiosResult, fileKey: resultFileKey } = result
          const { errors } = axiosResult || {}
          const [ error ] = errors || [];
  
          if (error) {
            this.files = this.files.map(file => {
              return file.fileKey === resultFileKey ? { ...file, error } : file
            })
          }
  
          return result
        }));
      }
      

      onUploadFinish && onUploadFinish(result)
    }
  }

  _cancelUpload = () => {
    Object.keys(this.cancellationCallbacks).forEach(fileKey => {

      this._onCancelFile(fileKey)
    })
  }

  _onCancelFile = (fileKey) => {
    const { onFileChanged } = this.props

    this.setState(prevState => ({
      uploadedFiles: prevState.uploadedFiles.filter(f => f.fileKey !== fileKey),
      uploadedFilesToRemove: [
        ...prevState.uploadedFilesToRemove,
        prevState.uploadedFiles.find(f => f.fileKey === fileKey)
      ],
      hashSums: Object.fromEntries(Object.entries(prevState.hashSums).filter(e => +e[0] !== fileKey)),
    }));
    
    const updatedList = this._validateAllFiles(this.files.filter(f => f.fileKey !== fileKey));
    this.files = updatedList;
    this.filer.current.value = null;
    onFileChanged && onFileChanged( updatedList )
  }

  _renderFile = (file) => {
    const { progressData, hashSums } = this.state;
    const { massImportFile, withHashSums, fileValidator } = this.props;
    const { fileKey, error } = file;

    const FileItemComponent = massImportFile ? MassImportFileItem : FileItem;
    const FileItemFinal = <FileItemComponent
        progress={progressData[fileKey]}
        onCancel={this._onCancelFile}
        file={file}
        icon={withHashSums &&
          <Img
            img={hashStatusIcons[file.hashStatus || HashStatus.NotSet]}
            className={`${styles.hashStatusIcon} ${styles[Object.keys(HashStatus).find(k => HashStatus[k] === file.hashStatus) || 'NotSet']}`} />
        }
        error={error}
        key={fileKey}
        fileValidator={fileValidator}
    />;

    return withHashSums
      ? (
          <div className={styles.fileItemWrapper} key={`fi-${fileKey}`}>
            {FileItemFinal}
            <HashSumInput
              value={hashSums[file.fileKey]}
              isInvalid={file.hashStatus === HashStatus.Error}
              isDirty={file.hashSum !== hashSums[file.fileKey]}
              onChange={v => { this.setState(prevState => ({
                hashSums: { ...prevState.hashSums, [file.fileKey]: v }
              })); }} />
          </div>
        )
      : FileItemFinal;
  }

  render() {
    const { className, disabled, title, isMultiple, accept = '' } = this.props
    const { uploadedFiles = [] } = this.state

    return (
      <div className={`file-upload-area ${className || ''}`}>

        <Button
          className='upload-button'
          type='secondary'
          onClick={this._showFileSelect}
          disabled={disabled}
        >
          <DwnlBtn className='button-image'></DwnlBtn>
          <span className='button-title'>{title || 'Загрузить'}</span>
        </Button>
        {(uploadedFiles.length + this.files.length) > 0 && <div className={`file-area ${styles.fileArea}`}>
          {uploadedFiles.map(this._renderFile)}
          {this.files.map(this._renderFile)}
        </div>}
        <div style={{ display: 'none' }}>
          <input
            onChange={this._getFile}
            ref={this.filer}
            type="file"
            multiple={isMultiple}
            accept={accept}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  state => state,
  {
    ...fileActions
  },
  null,
  {forwardRef : true})(fileUploadControl)