/** @format */
import { SignatureDialog } from 'app/components/signature/SignatureDialog';
import useFullSign from 'app/components/HOC/hooks/useFullSign';
import ConfirmDialog from 'app/components/dialog/confirmDialog/';
import { getWarningObject } from 'app/components/license/card/create';
import Overlay from 'app/components/ui/overlay';
import { useSelector } from 'react-redux';
import withModal from 'app/components/HOC/ObsoleteModalHOC';

const Dialog = withModal(SignatureDialog);

export default function withSignatureHOC(PassedComponent) {
  const SignatureHOC = props => {
    const { signType, signHeader, actionCallback, afterAction } = props;

    const {
      isNeedSignDialog,
      onCancelSign,
      afterChooseSign,
      onSubmitSignEnhance,
      isProgressSign,
      generateTextErrors,
      onWarningCancel,
      onSupressWarning,
      warningObject,
      signatureActions: { setSignResult },
      signatureState,
    } = useFullSign({
      signType,
      signHeader,
      actionCallback,
      afterAction,
    });

    const profile = useSelector(({ profile }) => profile);

    return (
      <>
        <PassedComponent {...props} generateTextErrors={generateTextErrors} onSubmitSignEnhance={onSubmitSignEnhance} />
        {isProgressSign && <Overlay />}
        {warningObject ? (
          <ConfirmDialog {...getWarningObject(warningObject)} onSubmit={onSupressWarning} onCancel={onWarningCancel} />
        ) : null}
        {isNeedSignDialog && (
          <Dialog
            setSignResult={setSignResult}
            signature={signatureState}
            profile={profile}
            className="signature-form"
            compact
            onCancel={onCancelSign}
            afterSignCallback={afterChooseSign}
            renderHeader={() => {
              return <div className="sign__header">{signHeader}</div>;
            }}
          />
        )}
      </>
    );
  };

  return SignatureHOC;
}

