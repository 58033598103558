import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getResourceRights} from "../../../../core/auth/auth";
import {RESOURCES} from "../../../../core/auth/resourceByPage";
import {useState} from "react";
import service from "../../../../services/service";
import {dispatchLogic} from "../../../../../redux/actions/common";
import {formatDate} from "../../../../core/utility/date";

function valueToType(type){
  return {
    id: type.id,
    name: type.title,
  }
}

export default function useActionPanel({ selected, setSelected, setInProgress, setChanged }){
  const { skziId, versionId, buildId } = useParams()
  const profile = useSelector(({profile}) => profile)
  const fileRights = getResourceRights(profile, RESOURCES.files)
  const dispatch = useDispatch()
  const [canCreate, setCanCreate] = useState(true)
  const [canUpdate, setCanUpdate] = useState(true)
  const [canDelete, setCanDelete] = useState(true)
  const [visibleDialogs, setVisibleDialogs] = useState({
    isShowAddDialog: false,
    isShowEditDialog: false,
    isShowDeleteDialog: false,
    isShowFileDialog: false
  })

  function onDeleteConfirm(e){
    setInProgress(true)
    service('versionService', 'removeCertificatesFromBuildRange', {
      skziId,
      versionId,
      buildId,
      items: selected,
    }).then(response => {
      const { data, errors, isError } = response;
      setInProgress(false)
      if(data && data.length){
        setChanged({deleted: data})
      }
      dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      return response
    })
    setVisibleDialogs({...visibleDialogs, isShowDeleteDialog: false})
  }

  function onAddCertificate(data){
    setInProgress(true)
    return service('versionService', 'addCertificateToBuild', {
      skziId,
      versionId,
      buildId,
      data: {
        number: data.number,
        type: valueToType(data.type),
        certificateStartDate: formatDate(data.certificateStartDate, 'yyyy-mm-dd'),
        certificateExpirationDate: formatDate(data.certificateExpirationDate, 'yyyy-mm-dd')
      },
    }).then(response => {
      const { data, errors, isError } = response;
      setInProgress(false)
      if(data){
        setChanged({added: [data]})
      }
      setVisibleDialogs({...visibleDialogs, isShowAddDialog: false})
      dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      return response
    }).catch(() => {
      setInProgress(false)
      setVisibleDialogs({...visibleDialogs, isShowAddDialog: false})
    })
  }

  function onEditCertificate(data){
    const rowId = data.id
    setInProgress(true)
    return service('versionService', 'editCertificateToBuild', {
      skziId,
      versionId,
      buildId,
      data: {
        id: rowId,
        number: data.number,
        type: valueToType(data.type),
        certificateStartDate: formatDate(data.certificateStartDate, 'yyyy-mm-dd'),
        certificateExpirationDate: formatDate(data.certificateExpirationDate, 'yyyy-mm-dd')
      },
    }).then(response => {
      const { data, errors, isError } = response;
      const { certificates } = data || {}
      const updatedCertificate = certificates.find(i => i.id === rowId);
      setInProgress(false)
      setChanged({updated: updatedCertificate})
      setSelected(selected?.map(so => so.id === rowId ? updatedCertificate : so) ?? [])
      setVisibleDialogs({...visibleDialogs, isShowEditDialog: false})
      dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      return response
    }).catch(() => {
      setInProgress(false)
      setVisibleDialogs({...visibleDialogs, isShowEditDialog: false})
    })

  }

  return {
    canCreate,
    canUpdate,
    canDelete,
    visibleDialogs,
    fileRights,
    setVisibleDialogs,
    onEditCertificate,
    onAddCertificate,
    onDeleteConfirm,
  }

}