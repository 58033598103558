/** @format */
import { sortDirection } from 'app/components/ui/constants';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';

export const defaultSettings = {
  columns: [
    {
      title: '№',
      alias: 'id',
      useSorting: true,
      width: 100,
    },
    {
      title: 'Серийный номер',
      alias: 'serialNumber',
      useSorting: true,
      format: item => {
        const { serialNumber } = item;

        return serialNumber || '';
      },
    },
    {
      title: 'Тип',
      alias: 'keyCarrierType',
      useSorting: true,
      format: item => {
        const { keyCarrierType } = item;
        const { name } = keyCarrierType || {};

        return name || '';
      },
    },
    {
      title: 'Пользователь',
      alias: 'eskziUser',
      useSorting: true,
      format: item => {
        const { eskziUser } = item;

        return formatFIO(eskziUser);
      },
    },
    {
      title: 'Дата создания',
      alias: 'createDate',
      useSorting: true,
      width: 200,
      format: item => formatDate(item.createDate, 'dd.mm.yyyy'),
    },
  ],
  sort: {
    column: 'id',
    direction: sortDirection.desc,
  },
};
