import { 
  BREADCRUMBSPUSHITEM,
  ADMITTANCECARDGET,
  ADMITTANCECARDUPDATE,
  ADMITTANCECARDUPDATEFORMDATA,
  ADMITTANCECARDINPROGRESS,
  ADMITTANCECARDRESETACTION,

} from '../actionTypes'
  
import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/navigation/breadcrumbs'

  export const getAdmittanceAction = ({admittanceId, pathname}) => dispatch => {
    service('admittanceService', 'getAdmittance', admittanceId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, ADMITTANCECARDGET, data, isError, errors)
      })
      .then(action => {
        const { payload } = action
        const { name, id } = payload || {}
  
        return dispatch({
          type: BREADCRUMBSPUSHITEM,
          payload: getPageBreadcrumb(pathname, `Допуск № ${name || id}`)
        })
      })
  }
  
  export const admittanceCardUpdateAction = (requestParams, pathname) => dispatch => {
    return service('admittanceService', 'updateAdmittance', requestParams)
      .then(response => {
        const { data, errors, isError } = response;
        dispatchLogic(dispatch, ADMITTANCECARDUPDATE, data, isError, errors)
        
        const { name, id } = data || {}
        dispatch({
          type: BREADCRUMBSPUSHITEM,
          payload: { ...getPageBreadcrumb(pathname, `Допуск № ${name || id}`), forceUpdate: true },
        })
      })
  }

  export const admittanceCardSignAction = (data) => dispatch => {
    return service('admittanceService', 'signAdmittance', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        dispatchLogic(dispatch, ADMITTANCECARDUPDATE, data, isError, errors)
      })
  }

  
  export const admittanceCardResetAction = () => dispatch => {
    dispatch({
      type: ADMITTANCECARDRESETACTION,
      payload: { 
      }
    })
  }
  
  export const admittanceCardInProgress = isInProgress => dispatch => {
    dispatch({
      type: ADMITTANCECARDINPROGRESS,
      payload: isInProgress
    })
  }
  
  
  export const admittanceCardUpdateCommonForm = (formData) => dispatch => {
    dispatch({
      type: ADMITTANCECARDUPDATEFORMDATA,
      payload: { 
        ...formData
      }
    })
  }