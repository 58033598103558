/** @format */
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterElement';
import ColoredChipText, { ChipType } from 'app/components/ui/coloredChipText';

const StatusColorMap = {
  Active: ChipType.success,
  Inactive: ChipType.normal,
}

export const fieldsConfig = [
  {
    title: 'Дата создания',
    alias: 'creationDate',
    format: item => formatDate(item.creationDate, 'dd.mm.yyyy hh:MM:ss'),
    visibleOnStart: true,
    userSorting: true,
    width: 250,
  },
  {
    title: 'Имя пользователя',
    alias: 'userName',
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Электронная почта',
    alias: 'email',
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Статус',
    alias: 'status',
    format: item => {
      const { status } = item || {};
      const { id, name } = status || {};
      return <ColoredChipText text={name} type={StatusColorMap[id]} />
    },
    visibleOnStart: true,
    visibleAlways: true,
    userSorting: true,
    width: 250,
  },
];

export const filtersConfig = [
  {
    type: FilterControlType.simpleSearch,
    alias: 'userName',
    label: 'Имя пользователя',
    extended: false,
    order: 1,
  },
  {
    type: FilterControlType.simpleSearch,
    alias: 'email',
    label: 'Электронная почта',
    extended: false,
    order: 2,
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'status',
    label: 'Статус',
    notStandardService: {
      serviceName: 'certificateAuthorityUserService',
      serviceMethod: 'statuses',
    },
    extended: false,
    order: 3,
  },
];