const EventCode = {
  changeUser: 'ChangeUser', // Выдать другому пользователю
  destruction: 'Destruction', // Уничтожить
  externalOkiInstallation: 'ExternalOkiInstallation', // Установить
  installation: 'Installation', // Установить
  okiDelivery: 'OkiDelivery', // Выдать
  okiDeliveryOk: 'OkiDeliveryOk', // Получить экземпляр
  okiDestruction: 'OkiDestruction', // Уничтожение
  okiInstallation: 'OkiInstallation', // Установить
  okiInstallationKeyDocument: 'OkiInstallationKeyDocument', // Ввод в аппаратное СКЗИ
  okiSendingOk: 'OkiSendingOk', // Подтвердить получение
  okiWithdrawal: 'OkiWithdrawal', // Изъять
  okiWithdrawalKD: 'OkiWithdrawal_KD', // Изъять
  okzDestruction: 'OkzDestruction', // Уничтожение
  okzReturn: 'OkzReturn', // Вернуть
  okzReturnOk: 'OkzReturnOk', // Подтвердить возврат
  okzSendingOk: 'OkzSendingOk', // Подтвердить получение
  returnGeneric: 'Return', // Вернуть
  returnOk: 'ReturnOk', // Подтвердить возврат
  sendingOk: 'SendingOk', // Подтвердить получение
  withdrawal: 'Withdrawal', // Изъять
};

const EndpointType = {
  eskziExternalOki: 'EskziExternalOki',
  keyCarrier: 'keyCarrier',
  keyDocument: 'keyDocument',
  skziAccounting: 'skziAccounting',
  skziOrganization: 'skziOrganization',
};

const ENTITY_TYPE = {
  eskzi: 'skziInstance',
  keyDocument: 'keyDocument',
  keyCarrier: 'keyCarrier',
}

export { EventCode, EndpointType, ENTITY_TYPE };