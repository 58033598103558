import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  ESKZIREGISTRATION, 
  ESKZIUPDATEFORMDATA, 
  SIGNATUREREADYTOSIGN,
  ESKZIREGISTRATIONRESETSTATE, 
  ESKZIREGISTRATIONINPROGRESS, 
  ESKZIREGISTRATIONSETERRORBYKEY, 
  ESKZIREGISTRATIONCLEARFORM 
} from '../../actions/actionTypes'
import { dialogType } from 'app/components/dialog/confirmDialog/'
// import { stringPlural } from 'app/core/utility/common'
import { getEskziName } from 'app/components/skzi/constants'

const defaultState = {
  formData: {
    skziVersionId: '',
    serialNumbers: [],
    expirationDate: new Date(),
    receivedFrom: '',
    transmittalLetterNumber: '',
    isStandard: false,
    organizationType: { id: 'Okz', title: 'Орган криптографической защиты'},
    transmittalLetterDate: new Date()
  },
  registeredInfoObject: null,
  errorObject: null,
  inProgress: false,
  errorByFields: {}
}

export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { formData } = state
    const { serialNumbers, organizationType } = formData
    const { formData: defaultFormData } = defaultState

    switch (action.type) {
      case ESKZIREGISTRATION:
        return {
            ...state,
            registeredInfoObject: {
              type: dialogType.navigation,
              title: `Экземпляр «${getEskziName(payload.skzi)}» успешно зарегистрирован.`
            },
            inProgress: false
        }

      case ESKZIUPDATEFORMDATA: 
        const { key, value } = payload

        if (key === 'serialNumber') {
          return {
            ...state,
            formData: {
              ...formData, 
              serialNumbers: [...serialNumbers.filter(item => item.id !== value.id), value],
              [value.id]: value.value
            }
          }
        }

        if (key === 'skziVersionId') {
          return {
            ...state,
            formData: {
              ...defaultFormData, 
              organizationType: organizationType,
              [key]: value
            }
          }
        }
        
        return {
          ...state,
          formData: {
            ...formData, 
            [key]: value
          }
        }

      case ESKZIREGISTRATIONRESETSTATE:
        return {
          ...state,
          registeredInfoObject: null,
          errorObject: null,
          inProgress: false,
          errorByFields: {}
        }

      case CLEARERROR: 
        return {
          ...defaultState,
          formData,
        }

      case ERROR:
        return {
          ...state,
          inProgress: false
        }

      case ESKZIREGISTRATIONSETERRORBYKEY:
        const { errorByFields } = state

        return {
          ...state,
          errorByFields: { ...errorByFields, ...payload }
        }

      case ESKZIREGISTRATIONINPROGRESS: 
        return {
          ...state,
          inProgress: payload
        }

      case ESKZIREGISTRATIONCLEARFORM: 
        const { formData: defaultForm } = defaultState

        return {
          ...state,
          formData: {...defaultForm}
      }

      case FIELDSERROR:
        const errorByField = errors.reduce((acc, item) => {
          const { field } = item
          
          return { ...acc, [field]: item }
        }, {})

        return {
          ...state,
          errorByFields: errorByField,
          inProgress: false
        }

      case ERRORPAGEERROR:
      case ERROR:
      case SIGNATUREREADYTOSIGN:

        return {
          ...state,
          inProgress: false
        }

      default: 
          return state
    }
}