import React, { Component } from 'react'

import withToggle from 'app/components/HOC/ToggleHOC'
import Button from 'app/components/ui/button/button'

import withTooltip, { Position } from 'app/components/HOC/TooltipHOC'
import { decapitalize, capitalize } from 'app/core/utility/common'
import { itemActions } from 'app/components/ui/constants'
// import InfoPanel from 'app/components/ui/infoPanel'
import Action from './cardAction'
import ErrorPanel from 'app/components/error'
import { ErrorInfo } from 'app/components/Notification/allNotificationPage/notificationDetail/bulkOperationResult'

const CardAction = withTooltip(Action)

class Card extends Component {
  constructor(props) {
    super(props)
    
    this.state={}
  }

  componentWillUnmount(){
    const { onLeaveAction } = this.props

    onLeaveAction && onLeaveAction()
  }

  _handlerClick = name => {
    const { onEditCallback, onActionClick, toggle, data, cardIndex } = this.props

    name === itemActions.edit.key && !this._getToggleStatus() && toggle()
    name === itemActions.edit.key && onEditCallback && onEditCallback(cardIndex)
    name !== itemActions.edit.key && onActionClick && onActionClick(name, data)    
  }

  //to do - use memoization
  _getToggleStatus = () => {
    const { toggleStatus, cardIndex, editingIndex } = this.props

    return editingIndex !== undefined 
            ? cardIndex === editingIndex
            : toggleStatus
  }

  _submitEnabled = () => {
    const { getObjectKeyFunc, data, formData } = this.props

    if (!data) {
      return true
    }

    return getObjectKeyFunc && getObjectKeyFunc(data) !== getObjectKeyFunc(formData)
  }

  _onSubmit = () => {
    const { onSubmit } = this.props

    onSubmit && onSubmit()
  }

  _validate = () => {
    const { formData, formLines = {}, serverFormViewModel } = this.props
    const { onValidationError } = this.props
    const cardLines = Object.keys(formLines).reduce((acc, cur) => {
      return { ...acc, ...formLines[cur].content }
    }, {})

    const errors = Object.keys(cardLines).reduce((acc, current) => {
      const validationResult = 
              cardLines[decapitalize(current)]
              && cardLines[decapitalize(current)].validation 
              && cardLines[decapitalize(current)].validation(
                  formData[current], 
                  serverFormViewModel ? serverFormViewModel[current]: null
                 )

      return {
        ...acc,
        [capitalize(current)]: validationResult ? validationResult.error : null
      }
    }, {})

    onValidationError && onValidationError(errors)

    const hasErrors = Object.keys(errors).reduce((res, key) => {
      return res || errors[key]
    }, false)

    !hasErrors && this._onSubmit()
  }
  
  _onApply = () => {
    this._validate()
  }

  _onChange = (key, value) => {
    const { onChange } = this.props

    onChange && onChange(key, value)
  }

  _onCancel = () => {
    const { toggle, onCancel, editingIndex } = this.props

    !editingIndex && toggle && toggle()
    onCancel && onCancel()
  }

  _renderButtons = () => {
    const { showButtons = true, editOnly, onCancel, isModal } = this.props

    if (!showButtons) {
      return null
    }

    if (editOnly) {
      return (
        <div className={`card__management ${isModal ? 'management--modal' : ''}`}>
          {isModal ? <Button
            className='management__item'
            type = 'secondary'
            onClick = {onCancel}
          >
            <span className='button-title'>Отменить</span>
          </Button> : null}
          <Button
            className='management__item'
            type = 'primary'
            onClick = {this._onApply}
            disabled = {!this._submitEnabled()}
          >
            <span className='button-title'>Сохранить</span>
          </Button>
        </div>
      )
    }

    return (
      this._getToggleStatus() ? (
        <div className="card__management">
          <Button
            className='management__item'
            type = 'secondary'
            onClick = {this._onCancel}
          >
            <span className='button-title'>Отменить</span>
          </Button>
          <Button
            className='management__item'
            type = 'primary'
            onClick = {this._onApply}
            disabled = {!this._submitEnabled()}
          >
            <span className='button-title'>Сохранить</span>
          </Button>
        </div> 
      ) : null
    )
  }

  _renderProp = () => {
    const { renderProp } = this.props

    return renderProp && !this._getToggleStatus() ? renderProp() : null
  }

  _renderGroup = group => {
    const { formLines, errorByFields = {}, formData = {}, data = {}, editOnly, serverFormViewModel } = this.props
    const { cardProp } = this.state
    const { content, groupTitle } = group
    const actualFormLinesKeys = serverFormViewModel 
                              ? Object.keys(content).filter(item => {return serverFormViewModel[capitalize(item)]})
                              : Object.keys(content)

    return (
      <>
        {Object.keys(formLines).length > 1 || groupTitle ? (
          <div className={`card-line card-line--group`}>
            <div className={`card-title card-title--group`}></div>
            <div className={`card-value card-value--group`}>
              {groupTitle}
            </div>
          </div>
          ) : null
        }
        {
          actualFormLinesKeys.map(key => {
            const capitalizeKey = capitalize(key)
            const lineViewModel = serverFormViewModel ? serverFormViewModel[capitalizeKey] : null            
            const hideForEdit = key === 'signatures'
            const props = {
              ...this.props,
              value: data[lineViewModel ? capitalizeKey : key],
              serverFieldViewModel: lineViewModel,
              editValue: formData[lineViewModel ? capitalizeKey : key],
              onChange: this._onChange,
              error: errorByFields[capitalizeKey],
              hideForEdit: hideForEdit,
              // we need some prop to update card for complex card lines
              changeCardProp: (value) => this.setState({cardProp: value}),
              cardProp
            }

            return (
              <>
                {hideForEdit && this._getToggleStatus() 
                  ? null
                  : (
                      <div key={key} className={`card-line ${content[key].isDescription ? 'card-line--unset': ''}`}>
                        <div className={`card-title card-title--${(editOnly || this._getToggleStatus()) && content[key].isRequired && content[key].isRequired(lineViewModel) ? 'required' : 'normal'} ${content[key].isDescription ? 'card-title--top': ''}`}>
                          <span className='title-text'>{content[key].title(props)}</span>
                        </div>
                        <div className={`card-value ${content[key].isDescription ? `card-value--${editOnly ||this._getToggleStatus() ? 'edit-top' : 'view-top'}`: ''}`}>
                          {this._renderField(content[key], props, lineViewModel)}
                        </div>
                      </div>
                  )
                }
              </>
              
            )
          })
        }
      </>
    ) 
  }

  _renderField = (fieldDescription, props, serverFieldViewModel) => {
    const { editOnly, eskziIds } = this.props
    
    return (
      <>
        {(editOnly || this._getToggleStatus()) && fieldDescription.renderEdit
          ? fieldDescription.renderEdit(props, serverFieldViewModel, eskziIds) 
          : fieldDescription.renderValue 
              ? fieldDescription.renderValue({...props}) 
              : props.value
        }
      </>
    )
  }

  _renderActions = () => {
    const { 
      actions = [],
      editOnly,
      isModal
    } = this.props

    return !isModal ? (
      <div className={`card__actions ${editOnly || !actions.length || this._getToggleStatus() ? 'actions--empty' : ''}`}>
        { 
          actions.map((group, i) => {
            return (
              <div key={i} className='actions__group'>
                {group.map(key => {
                  return itemActions[key] 
                    ? (
                      <CardAction
                        key={itemActions[key].name}
                        tooltipPosition={Position.Right}
                        tooltip={itemActions[key].name}
                        onClick={() => this._handlerClick(key)}
                        image={itemActions[key].image}
                      />
                    ) : null
                  })
                }
              </div>)
          })
        }
      </div>
    ) : null
  }

  render() {
    const { 
      className,
      formLines = [],
      isModal,
      errorByKeys = []
    } = this.props
    const { key } = this.state
    const errors = Array.from(new Set(errorByKeys.map(i => i.errorMessage)))
      
    return (
      <>
        {errors.length ? <ErrorPanel>
          <ErrorInfo
            messages={errors}
          />
        </ErrorPanel>
        : null}
        
        <article key={key} className={`card ${className || ''} ${isModal ? 'card--modal' : ''}`}>
          {this._renderActions()}
          <div className="card__content">
            {Object.keys(formLines).map((key, i) => {
              return this._renderGroup(formLines[key])
            })}
            {!isModal ? this._renderButtons() : null}
            {this._renderProp()}
          </div>
        </article>
        {isModal ? this._renderButtons() : null}
      </>
    )
  }
}

export default withToggle(Card)