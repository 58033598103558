import { PureComponent } from 'react'
import { connect } from 'react-redux'

import { cardLines } from './skziBuildData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Button from 'app/components/ui/button/button'
import TagComponent from 'app/components/ui/tag'
import { formatDate } from 'app/core/utility/date'
import { sortDirection } from 'app/components/ui/constants'
import DownloadButton from '../mainList/downoadBuildButton'

import withAuth from 'app/components/HOC/AuthHOC'
import withTooltip from 'app/components/HOC/TooltipHOC'

import * as errorAction from 'redux/actions/common'
import * as skziBuildCardAction from 'redux/actions/skziRegistry/skziBuildCardAction'
import * as selfServiceRegistryAction from 'redux/actions/selfServiceRegistry/selfServiceRegistryAction'
import CardCertificationBlock from "../../skzi/Card/CardCertificationBlock";
import { ContentComponentType, HashStatus } from 'app/components/skziRegistry/enums'
import hashStatusIcons from 'app/components/skziRegistry/hashStatusIcons'
import Img, { Image } from 'app/components/ui/Img'
import styles from './skziBuildCard.module.scss';
import { arrayToIdHashMap } from 'app/core/utility/common'
import TableWithLogic from 'app/components/list/Table/tableWithLogic'
import { SelectType } from 'app/components/ui/picker/picker'

const Tag = withTooltip(TagComponent)

const downloadButtonVisible = (props) => {
  const { skziBuildCard } = props || {}
  const { skziBuildData } = skziBuildCard || {}
  const { selfServiceState } = skziBuildData || {}
  
  switch (selfServiceState) {
    case 'HasAdmittance':
    case 'HasEskzi':
      return true
    default:
      return false
  }
}

class SkziCard extends PureComponent {
  constructor(props){
    super(props)

    this.state = { 
      tabTitle: 'Информация',
      sort: { 
        column: 'main',
        direction: sortDirection.desc
      },
      buildColumns: [
        {
          title: 'Тип элемента',
          alias: 'objectType',
          useSorting: true,
          width: 300,
          format: item => {
            const { objectType } = item
            const { name } = objectType
            
            return name
          }
        },
        {
          title: 'Вложения',
          alias: 'documents',
          useSorting: false,
          format: rowData => {
            const { files, objectType: { name: objectTypeName } } = rowData
            const { match } = this.props
            const { params = {} } = match
            const { skziId, versionId, buildId } = params
            const urlPart = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/file`

            return (
              <div className="type-files">
                {files.map((item) => { 
                  return (
                    <Tag
                      key={item.guid}
                      item={item}
                      tooltip={objectTypeName === ContentComponentType.InstallationPackage
                        ? `${item.hashStatus || HashStatus.NotSet}: ${item.name}`
                        : item.name
                      }
                      icon={objectTypeName === ContentComponentType.InstallationPackage &&
                        <Img
                          img={hashStatusIcons[item.hashStatus || HashStatus.NotSet]}
                          className={`${styles.fileTagIcon} ${styles[Object.keys(HashStatus).find(k => HashStatus[k] === item.hashStatus) || 'NotSet']}`} />
                      }
                      text={item.name}
                      link={!item.hashStatus || item.hashStatus === HashStatus.Ok || item.canBeDownloaded
                        ? `${urlPart}/${item.guid}`
                        : null}
                    />
                  );
                }
                )}
              </div>
            )
          }
        },
      ]
    }
  }

  componentDidMount() {
    const { skziBuildCard, getSelfServiceSkziBuildAction, skziBuildCardInProgress, match = {}, location} = this.props
    const { pathname, search } = location
    const { params = {} } = match
    const { buildId } = params
    const { inProgress } = skziBuildCard

    if (inProgress) {
      return
    }

    const searchParams = new URLSearchParams(search);
    const skziName = searchParams.get('skziName') ? decodeURI(searchParams.get('skziName')) : ''
    
    skziBuildCardInProgress(true)
    getSelfServiceSkziBuildAction({ buildId, pathname, skziData: {skziName: skziName}})
  }

  componentWillUnmount () {
    const { 
      skziBuildCardResetAction,
      clearErrorAction,
      skziBuildCardOnSelect
    } = this.props

    skziBuildCardOnSelect()
    skziBuildCardResetAction()
    clearErrorAction()
  }

  _onSort = ({column, direction}) => {
    const { skziBuildCardInProgress, skziBuildCardGetTypesAction, match } = this.props
    const { params = {} } = match || {}
    const { skziId, versionId, buildId } = params

    this.setState({ sort: { column, direction }}, () => {
      skziBuildCardInProgress(true);
      skziBuildCardGetTypesAction({skziId, versionId, buildId, column, direction })
    })
  }

  _onSelect = selectedData => {
    const { skziBuildCardOnSelect } = this.props
    skziBuildCardOnSelect({
      items: selectedData,
      selectedHashMap: arrayToIdHashMap(selectedData)
    })
  }

  _renderDownloadButton = () => {
    const { skziBuildCard, match } = this.props
    const { selected } = skziBuildCard
    const { items } = selected
    const { params = {} } = match
    const { skziId, versionId, buildId } = params
    const canDownload = items?.length
      && items.find(item => item.files && item.files.length)
      && items.every(item => (item.objectType && item.objectType.name !== ContentComponentType.InstallationPackage) || item.files.every(f => f.canBeDownloaded))

    const dowloadUrl = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/content/download?id=${items?.map(item => item.objectType.id).join('&id=')}`

    return (
      <Button 
        tooltip={'Скачать'}
        type='image'
        disabled={!canDownload}
        href={canDownload ? dowloadUrl : ''}
      >
        <Img img={Image.Download} />
      </Button>
    )
  }

  _renderData = () => {
    const { skziBuildCard, skziBuildCardInProgress, profile, match } = this.props
    const { params = {} } = match
    const { skziId, versionId, buildId } = params
    const { buildColumns  } = this.state
    const { skziBuildData, viewData, commonDataForm, inProgress } = skziBuildCard
    const { documentation = [], documentationDisabled } = skziBuildData
    const { skzi, skziVersion, name } = skziBuildData || {}
    const { name: versionName, id: versionIdFromFormData } = skziVersion || {}
    const { name: skziName } = skzi || {}
    const downloadText = `Скачать ${skziName} ${versionName} (${name})`

    return (
      <section className='skzi-build-common-data'>
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты сборки'}
          initialState={true}
        >
          <div class="card-material__management">
            {downloadButtonVisible(this.props) ? (
              <DownloadButton
                lastBuild={skziBuildData}
                versionId={versionIdFromFormData}
                downloadText={downloadText}
                inProgressAction={skziBuildCardInProgress}
              />
            ) : null}
          </div>
          <Card
            className='skzi-build-data-card'
            profile={profile}
            formLines={cardLines}
            actions={[]}
            canEdit={false}
            key={this._getBuildKey(viewData)}
            data={viewData}
            formData={commonDataForm}
            getObjectKeyFunc={this._getBuildKey}
          />
        </ExpandingBlock>
        <CardCertificationBlock
            routeParams={{ skziId, versionId, buildId }}
            inProgressParent={inProgress}
        />
        {!documentationDisabled ? (
          <ExpandingBlock
            className='skzi-build-types'
            renderHeader= {() => 'Комплектация'}
            initialState={true}
          >
            <div className="skzi-build-types manage-panel">
              <div className="buttons-element">
                {this._renderDownloadButton()}
              </div>
            </div>
            <TableWithLogic
              list={documentation}
              defaultSort={{ 
                column: 'main',
                direction: sortDirection.desc
              }}
              columns={buildColumns}
              setSelected={this._onSelect}
              setSorting={this._onSort}
              selectType={SelectType.multiple}
            />
          </ExpandingBlock>
        ) : null}
      </section>
    )
  }

  _getBuildKey = (data) => {
    const { name, description, classId, certificateExpirationDate, certificate } = data
    const { id } = classId || {}

    const canEdit = `${name}${description}${id}${certificate}${formatDate(certificateExpirationDate, 'yyyy-mm-dd')}`

    return canEdit
  }

  render() {
    const { skziBuildCard } = this.props
    const { inProgress } = skziBuildCard

    return (
      <section className='self-service-skzi-build-card'>
        {inProgress ? <Overlay /> : null}
        <BreadcrumbsHeader />     
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...skziBuildCardAction,
    ...selfServiceRegistryAction,
  })(withAuth(SkziCard))