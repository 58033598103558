import {THEME} from 'app/appContainer/themeConfig'


export const loadLocalState = () => {
  const data = JSON.parse(localStorage.getItem('currentTheme'))

  return data || THEME['default']
}

export const saveLocalState = (value) => {
  const newValue = value || THEME['default']

  localStorage.setItem('currentTheme', JSON.stringify(newValue))
  return newValue
}