/** @format */

import Input from 'app/components/ui/Input';
import styles from '../card.module.scss';
import { formatDate } from 'app/core/utility/date';

export default function RequisitesForm({
  status = null,
  creationDate = null,
  organizationName = '',
  snils = '',
  email = '',
  userName = '',
  ogrn = '',
}) {
  return (
    <div className={styles.requisites}>
      <Input className={styles.fio} label="ФИО" value={userName} readOnly />
      <Input
        className={styles.creationDate}
        label="Дата создания"
        value={formatDate(creationDate, 'dd.mm.yyyy hh:MM:ss')}
        readOnly
      />
      <Input className={styles.status} label="Статус" value={status?.name ?? ''} readOnly />
      <Input className={styles.snils} label="СНИЛС" value={snils} readOnly />
      <Input className={styles.email} label="Электронная почта" value={email} readOnly />
      <Input className={styles.organization} label="Организация" value={organizationName ?? ''} readOnly />
      <Input className={styles.ogrn} label="ОГРН" value={ogrn ?? ''} readOnly />
    </div>
  );
}

