/** @format */

const AgentStatus = {
  Unknown: 'Недоступен',
  Active: 'Активен',
};

const HostStatus = {
  Active: 'Действующий',
  Archived: 'Архивный',
};

export { AgentStatus, HostStatus };
