import React, { useEffect, useState } from 'react'
import { withCryptoProviders } from '../CryptoProvidersHOC'
import ExpandingBlock from '../../ui/expandingBlock'
import { formatDate } from 'app/core/utility/date'


function CertificateList (props) {
  const { 
    crypto,
    initProviders,
  } = props

  const { providers } = crypto || {}
  const { CRYPTO_PRO } = providers || {}
  const { certsList = []} = CRYPTO_PRO || {}
  
  useEffect(() => {
    initProviders()
  }, [])
  
  return (
    <>
      {certsList.map(i => {
        const { name } = i
        return (
        <ExpandingBlock
          renderHeader= {() => name}
          initialState={false}
        >
          {Object.keys(i).map(key => {
            const val = key === 'from' || key === 'to' 
                        ? formatDate( i[key], 'dd.mm.yyyy' )
                        : i[key]

            return (
              <div className='cert-debug-data'>
                <div className='cert-debug-data__name'>{key}:</div>
                <div className='cert-debug-data__value'>{val}</div>
              </div>
            )
          })}
        </ExpandingBlock>
      )})}
    </>
  )
}

const List = withCryptoProviders(CertificateList)

export default function SignatureDebug() {
  return (
    <div className='cert-debug'>
      <div className="cert-debug__header">
        Установленные сертификаты
      </div>
      <List/>
    </div>
  )
}
