import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { getResourceRights } from 'app/core/auth/auth'
import { ReactComponent as accessManagementImg } from 'assets/img/menu/ic_settings.svg'

export default function useCreateUserBlockControl(){

  const history = useHistory()
  const profile  = useSelector(({profile}) => profile)
  const rights = getResourceRights(profile, RESOURCES.user)


  const actions = ['edit']

  const items = [
    {
      disabled: false,
      ImgComponent: accessManagementImg,
      link: '/accessManagement/users'
    },
    {
      breadcrumbsTitle: true,
      disabled: false,
      headerTitle: `Создать учетную запись`,
      id: 'accessManagement/users/create',
      link: '/accessManagement/users/create',
      name: 'userCreate',
      resourceRight: 'CREATE',
      title: 'Учетные записи'
    }
  ]


  const onBack = () => history.push('/accessManagement/users')

  return {
    actions,
    items,
    rights,
    onBack
  }

}