import Image from "app/components/ui/Img/imgMap";
import Img from "app/components/ui/Img/Img";
import Tag from "app/components/ui/tag";
import styles from "./moreChip.module.scss";

export default function MoreChip({ 
  title, 
  onTogglePopup
}) {

  return (
    <div className={styles.moreChip}>
      <Tag 
        className={styles.tag} 
        text={title} 
        onClick={() => {
          onTogglePopup(true)
        }}
      >
        <Img img={Image.Chevron} />
      </Tag>
    </div>
  );
}
