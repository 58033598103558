import React, { Component } from 'react'

import Overlay from 'app/components/ui/overlay'
import withRoutingProps from './RoutingPropsHOC'

const withAuth = PassedComponent => {
  class Auth extends Component {
    constructor(props) {
      super(props)

      this.state = { needToRenderPassedComponent: true } 
    }

    UNSAFE_componentWillMount() {
      this._authorize()
    }

    // in case of user want to change password
    // and currenlty logged in, we have to show login page
    _isPasswordRecovery = () => {
      const { history } = this.props
      const { location } = history
      const { pathname } = location

      return pathname.toLowerCase().indexOf('passwordrecovery') !== -1
    }

    _authorize = () => {
      const { profile, history, authorizeAction } = this.props
      const { isAuthorised, authorizationComplete, licenseInfo } = profile
      const { isTrial, licenseIsExpired = false } = licenseInfo || {}

      if (authorizationComplete) {
        if (isTrial && licenseIsExpired) {
          this.setState({ needToRenderPassedComponent : false }, () => { history.push('/trialEnd')})
          return
        }

        if (!isAuthorised) {
          this.setState({ needToRenderPassedComponent : false })
        } 

      } else {
        authorizeAction && authorizeAction()
      }
    }

    render() {
      const { profile, authorizeAction } = this.props
      const { needToRenderPassedComponent } = this.state
      const { isAuthorised, authorizationComplete, isLoggedOut, inProgress } = profile
      const needToRender = (authorizationComplete && authorizeAction) // thirdPartyAuth, authenticated user, appContainer (because authorizeAction !== undefined)
                            || (isAuthorised && needToRenderPassedComponent) // any component (work area component) exept appContainer 
                            || (isLoggedOut && authorizeAction) // appContainer with thirdPartyAuth after logout
      
      return needToRender ? (
        <PassedComponent 
          {...this.props}
          isPasswordRecovery={this._isPasswordRecovery()}
        />
      ) : (inProgress ? <Overlay /> : null)
    }
  }
  return withRoutingProps(Auth, ['history']);
}

export default withAuth