/** @format */

import skziBuildService from 'app/services/skziBuildService';
import FilesDialog from 'app/components/skziRegistry/mainList/skziVersion/files/fileDialog';
import Overlay from 'app/components/ui/overlay';

const ComplectationModal = ({ logic }) => {
  const { getBuildFiles } = skziBuildService;
  const { inProgress, fileRights, fileData, onCancel, onFileActionStart, onFileActionFinish } = logic;

  return (
    <>
      {inProgress && <Overlay />}
      {fileData ? (
        <FilesDialog
          {...{
            renderHeader: () => <div className="modal__header">Комплект поставки</div>,
            downloadAllService: getBuildFiles,
            fileRights,
            data: fileData,
            readOnly: true,
            onCancel,
            onFileActionStart,
            onFileActionFinish,
          }}
        />
      ) : null}
    </>
  );
};

export default ComplectationModal;
