/** @format */

import withTooltip from 'app/components/HOC/TooltipHOC';
import Button, { buttonTypes } from 'app/components/ui/button/button';
import Img from 'app/components/list/TopPanel/Img';

const ButtonWithTooltip = withTooltip(Button);

const DeleteButton = ({ img, tooltip, buttonText, logic }) => {
  const { disabled, onAct } = logic;
  return (
    <ButtonWithTooltip
      type={buttonTypes.image}
      tooltip={!buttonText ? tooltip || 'Удалить' : ''}
      disabled={disabled}
      caption={buttonText}
      onMouseDown={onAct}
    >
      <Img type={img || 'DeleteImg'} />
    </ButtonWithTooltip>
  );
};

export default DeleteButton;
