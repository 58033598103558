import { 
  FILESUPLOADSTART,
  FILESUPLOADPROGRESS,
  FILESUPLOADFILERESULT,
  FILESUPLOADONDIALOGTOGGLE
 } from '../actionTypes'


export const fileStartUploadAction = (data) => dispatch => {
  dispatch({
      type: FILESUPLOADSTART,
      payload: data
  })
}

export const fileOnDialogToggleAction = (data) => dispatch => {
  dispatch({
      type: FILESUPLOADONDIALOGTOGGLE,
      payload: data
  })
}

export const fileProgressAction = (data) => dispatch => {
  dispatch({
      type: FILESUPLOADPROGRESS,
      payload: data
  })
}

export const fileUploadFileResultAction = (data) => dispatch => {
  dispatch({
      type: FILESUPLOADFILERESULT,
      payload: data
  })
}

