import React from 'react';
import { formatFIO } from 'app/core/utility/common';
import Input from 'app/components/ui/Input';
import DatePicker from 'app/components/ui/date';
import TagsPicker from 'app/components/ui/picker/tagPicker'
import Autocomplete, {
  renderItem,
  renderInputValue,
  Services,
} from 'app/components/ui/autocomplete/autocomplete';
import Checkbox from 'app/components/ui/checkbox';
import { OKI_CARD_TYPE, ORG_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import { ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';

export default function EventFieldComponent({
  items,
  eventCode,
  value = null,
  onChange,
  error,
  type,
  title,
  readOnly,
  id,
  eventId,
  viewCardState,
  entityType,
  objectIds,
  organizationIds,
  isExternalUser,
  organizationType,
  formData,
  isRequired
}) {
  const [objectId] = objectIds || [];

  switch (type) {
    case 'eskziUserId': {
      const services = {
        [ENTITY_TYPE.eskzi]: {
          serviceName: 'skziUserService',
          serviceMethod: 'eskziAutocomplete',
          data: {
            eskziId: objectId,
            eskziIds: objectIds,
          },
        },
        [ENTITY_TYPE.keyCarrier]: {
          serviceName: 'skziUserService',
          serviceMethod: 'keyCarrierAutocomplete',
          data: {
            eskziId: objectId,
            eskziIds: objectIds,
          },
        },
        [ENTITY_TYPE.keyDocument]: {
          serviceName: 'skziUserService',
          serviceMethod: 'keyDocumentAutocomplete',
          data: {
            eskziId: objectId,
            eskziIds: objectIds,
          },
        },
      };

      return (
        <Autocomplete
          id={id}
          className="card-field card-user"
          onEmptySearch={true}
          notStandardService={services[entityType]}
          linkTarget={viewCardState && value && value.id && {targetType: USER_ACCOUNT_CARD_TYPE, id: value.id}}
          label={title || 'ФИО пользователя СКЗИ'}
          onSelect={onChange}
          error={error}
          value={value}
          renderItem={(item) => formatFIO(item)}
          renderInputValue={(item) => formatFIO(item)}
          readOnly={readOnly}
          isRequired={isRequired}
        />
      );
    }
    case 'organizationId': {
      const { id, okz } = value || {};
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE;

      const services = {
        [ENTITY_TYPE.eskzi]: {
          serviceName: 'organizationService',
          serviceMethod: eventId
            ? 'eskziEventAutocomplete'
            : 'eskziAutocomplete',
          data: {
            organizationType,
            eskziId: objectId,
            eskziIds: objectIds,
            eventId,
          },
        },
        [ENTITY_TYPE.keyCarrier]: {
          serviceName: 'organizationService',
          serviceMethod: eventId
            ? 'keyCarrierEventAutocomplete'
            : 'keyCarrierAutocomplete',
          data: {
            organizationType,
            eskziId: objectId,
            eskziIds: objectIds,
            eventId,
          },
        },
        [ENTITY_TYPE.keyDocument]: {
          serviceName: 'organizationService',
          serviceMethod: eventId
            ? 'keyDocumentEventAutocomplete'
            : 'keyDocumentAutocomplete',
          data: {
            organizationType,
            eskziId: objectId,
            eskziIds: objectIds,
            eventId,
          },
        },
      };

      return (
        <Autocomplete
          id={id}
          className="card-field card-organization"
          onEmptySearch={true}
          notStandardService={services[entityType]}
          label={title || 'Кому передано'}
          onSelect={onChange}
          error={error}
          value={value}
          linkTarget={viewCardState && { targetType, id }}
          renderItem={renderItem.organizations}
          renderInputValue={(value) => {
            const { shortName, name } = value || {};
            return shortName || name;
          }}
          readOnly={readOnly}
          isRequired={isRequired}
        />
      );
    }
    case 'hardwareId': {
      const autocompleteService = {
        serviceName: 'skziService',
        serviceMethod: 'hardwareAutocomplete',
        data: {
          eskziId: objectId,
          eskziIds: objectIds,
        },
      };

      return (
        <Autocomplete
          id={id}
          className="card-field card-hardware"
          onEmptySearch={true}
          notStandardService={autocompleteService}
          label={title || 'Серийный номер аппаратного СКЗИ'}
          onSelect={onChange}
          error={error}
          value={value}
          renderItem={(item) => item.serialNumber}
          renderInputValue={(item) => (item ? item.serialNumber : '')}
          readOnly={readOnly}
          isRequired={isRequired}
        />
      );
    }
    case 'keyDocumentCustom': {
      const autocompleteService = {
        serviceName: 'skziService',
        serviceMethod: 'hardwareAutocomplete',
        data: {
          eskziId: objectId,
          eskziIds: objectIds,
        },
      };

      return (
        <Autocomplete
          id={id}
          className="card-field card-keyDocumentCustom"
          onEmptySearch={true}
          notStandardService={autocompleteService}
          label={title || 'Ключевой документ'}
          onSelect={onChange}
          error={error}
          value={value}
          renderItem={(item) => item.serialNumber}
          renderInputValue={(item) => (item ? item.serialNumber : '')}
          readOnly={readOnly}
          isRequired={isRequired}
        />
      );
    }
    case 'receivedFrom':
      return (
        <Input
          id={id}
          className="card-field card-received-from"
          label={title || 'Получено от'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
          placeholder="Введите наименование"
          isRequired={isRequired}
        />
      );
    case 'performerId': {
      const services = {
        [ENTITY_TYPE.eskzi]: {
          serviceName: 'skziInstanceService',
          serviceMethod: 'getPerformers',
          data: {
            eskziId: objectId,
            eskziIds: objectIds,
          },
        },
        [ENTITY_TYPE.keyCarrier]: {
          serviceName: 'skziUserService',
          serviceMethod: 'keyCarrierPerformers',
          data: {
            eskziId: objectId,
            eskziIds: objectIds,
          },
        },
        [ENTITY_TYPE.keyDocument]: {
          serviceName: 'skziUserService',
          serviceMethod: 'keyDocPerformers',
          data: {
            eskziId: objectId,
            eskziIds: objectIds,
          },
        },
      };

      if (isExternalUser) {
        services[entityType] = {
          serviceName: 'skziUserService', 
          serviceMethod: 'extOrgPerformers', 
          data: {
            eskziId: objectId, 
            eskziIds: objectIds
          }
        }
      }

      return (
        <Autocomplete
          id={id}
          className="card-field card-performerId"
          onEmptySearch={true}
          notStandardService={services[entityType]}
          label={title || 'Исполнитель'}
          onSelect={onChange}
          error={error}
          value={value}
          renderItem={(item) => renderItem.eskziUser(item)}
          renderInputValue={(item) => renderInputValue.eskziUser(item)}
          readOnly={readOnly}
          isRequired={isRequired}
        />
      );
    }
    case 'hardwareNumber':
      return (
        <Input
          id={id}
          className="card-field card-hardwareNumber"
          label={title || 'Номер аппаратного средства'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
          placeholder="Введите номер"
          isRequired={isRequired}
        />
      );
    case 'distributiveRemovingDate':
      return (
        <DatePicker
          id={id}
          className="card-field card-distributiveRemovingDate"
          label={title || 'Дата удаления дистрибутива'}
          readOnly={readOnly}
          value={!!value ? new Date(value) : null}
          onChange={onChange}
          error={error}
          isRequired={isRequired}
        />
      );
    case 'transmittalLetterDate':
      return (
        <DatePicker
          id={id}
          className="card-field card-transmittalLetterDate"
          label={title || 'Дата выполнения действия'}
          readOnly={readOnly}
          value={!!value ? new Date(value) : null}
          onChange={onChange}
          error={error}
          isRequired={isRequired}
        />
      );
    case 'transmittalLetterNumber':
      // https://jira.infosec.ru/browse/AOKZ-6917
      const disabled = eventCode === "Installation"
                        && items
                        && items.length > 1
      
      return (
        <Input
          id={id}
          className="card-field card-transmittalLetterNumber"
          label={title || 'Номер подтверждающего документа'}
          placeholder = {disabled ? 'Автоматически' : 'Введите номер'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
          isRequired={isRequired}
        />
      );
    case 'printNumber':
      return (
        <Input
          id={id}
          placeholder="Введите номер"
          className="card-field card-printNumber"
          label={title || 'Номер печати или пломбира'}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
          isRequired={isRequired}
        />
      );
    case 'useDestructionCertificate':
      return (
        <Checkbox
          className="card-field card-useDestructionCertificate"
          onChange={e => onChange(!value)}
          checked={value}
          disabled={readOnly}
        >
          {title}
        </Checkbox>
      );
    case 'signers':
      const disabledTags = Array.isArray(eventCode) ? 
      eventCode.some(ec => ['Destruction', 'OkiDestruction', 'OkzDestruction'].includes(ec)) && !formData?.useDestructionCertificate
      : eventCode === "Destruction" && !formData?.useDestructionCertificate
      const organizationId = Array.isArray(organizationIds) ? organizationIds[0] : organizationIds
      const pickerService = {
        serviceName: 'userService',
        serviceMethod: 'getUserByOrganizationId',
        data: {orgId: organizationId}
      }

      return (
        <TagsPicker
          className='card-field card-signers'
          label = 'Комиссия'
          error={error}
          readOnly={readOnly}
          onChange={onChange}
          currentItems={value || []}
          serviceType={Services.user} 
          notStandardService={pickerService} 
          renderTagName={item => formatFIO(item)}
          pickerHeaderText='Выбор участников комиссии'
          disabled={disabledTags}
          isRequired={isRequired}
        />
      );
    default:
      return (
        <Input
          id={id}
          className="card-field card-def"
          label={title}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          error={error}
          isRequired={isRequired}
        />
      );
  }
}
