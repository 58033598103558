import React, { useCallback, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import {Table} from 'app/components/list/Table/Table';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import { DownloadButton } from 'app/components/list/TopPanel/DownloadButton';
import Overlay from 'app/components/ui/overlay';
import { sortDirection } from '../ui/constants';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Button from '../ui/button/button';
import Pagination from '../ui/pagination/serverSidePagination/pagination';
import { useJournal } from '../journals/useJournal';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from "app/components/ui/managePanel";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from "app/components/ui/MainListContainer/MainListContainer";
import Filter, { GlobalOrgType } from "app/components/filter";
import styles from './list.module.scss';
import config from './config'
import ImgMap from 'app/components/ui/Img/imgMap';

export default function DestructionCertificateList() {
  const history = useHistory();

  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    filterOpened,
    toggleFilterState,
    onClearFilter,

    // useTableExtensions
    sortListAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,

    // global
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
  } = useJournal({
    defaultSort: {
      column: 'updateDate',
      direction: sortDirection.desc,
    },
    serviceName: 'destructionCertificateService',
  });

  const rights = useMemo(
    () => getResourceRights(profile, RESOURCES.destructionCertificate),
    [profile]
  );
  const onCreate = useCallback(
    () => history.push(`/acts/destructioncertificate/create`),
    []
  );
  const onRowClick = useCallback(item => {
    return `/acts/destructioncertificate/card/${item.id}`;
  }, []);

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title='Акты и заключения: Акты уничтожения' Img={imgMap.Extermination} />
      <ManagePanel borders={Border.All}>
        {rights.CREATE ? (
          <Group>
            <Button
              type="primary"
              disabled={inProgress}
              onClick={onCreate}
            >
              <ImgMap.Plus className="button-image button-image--left"></ImgMap.Plus>
              <span className="button-title">Создать</span>
            </Button>
          </Group>
        ) : null}
        <Group>
          <DownloadButton
            listFilterForRequest = {{...filterForRequest, organizationId: globalOrgId}}
            sorting={sorting}
            listFieldsVisibility={fieldsVisible}
            selected={selectedItems}
            titul="Выгрузить в Excel"
            type="DownloadButton"
            inProgress={inProgress}
            serviceName="destructionCertificateService"
          />
        </Group>
        <Group separate>
          <FilterTriggerPanel
            toggle={toggleFilterState}
            filterFilled={filterFilled}
            onClearFilter={onClearFilter}
            disabledClear={!filterState.length}
          />
        </Group>
        <Group separate>
          <TableFieldSelect
            listFieldsVisible={fieldsVisible}
            setVisibleFieldsAction={setVisibleFieldsAction}
            toggleVisibleFieldAction={toggleVisibleFieldAction}
            headerInfo={true}
            fieldsConfig={config.fieldsConfig}
            changedFieldsVisibility={true}
            settingsAsGear={true}
          />
        </Group>
      </ManagePanel>
    <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.twoRowFilter}
      >
        <Filter
          className={styles.filter}
          inProgress={inProgress}
          setGlobalOrg={setGlobalOrg}
          globalOrganization={globalOrganization}
          setFilterStateAction={setFilterStateAction}
          setFilterForRequestAction={setFilterForRequestAction}
          setFullFilterStateAction={setFullFilterStateAction}
          filterState={filterState}
          filterFilled={filterFilled}
          profile={profile}
          globalOrgId={globalOrgId}
          filtersConfig={config.tablePageFiltersConfig}
          isOpened={filterOpened}
          toggleFilterState={toggleFilterState}
          globalOrgType={GlobalOrgType.all}
          withGlobalOrg
          globalOrgPosition = {4}
        />
        <Table
          {...{
            totalCount,
            list,
            sortListAction,
            sorting,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedPagination,
            inProgress,
          }}
          listFieldsVisible={fieldsVisible}
          fieldsConfig={config.fieldsConfig}
          itemsSelectable={true}
          changedFieldsVisibility={true}
          onItemClick={onRowClick}
          headerInfo={true}
        />
      </FilterWithTableContainer>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageCount={pageCount}
        perPage={perPage}
      />
    </MainListContainer>
  );
}