/** @format */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog';
import Overlay from 'app/components/ui/overlay';
import { dispatchLogic } from 'redux/actions/common';
import service from 'app/services/service';
import TextArea from 'app/components/ui/textArea';
import styles from './style.module.scss';
import Autocomplete from 'app/components/ui/autocomplete/autocomplete';
import useToast from 'app/components/ui/toast/useToast';
import withTooltip from 'app/components/HOC/TooltipHOC';

const Button = withTooltip(ButtonComponent);

export default function CertificateRevocation({
  afterActionCallback,
  item,
  disabled,
  CAUser = false,
}) {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [inProgress, setInProgress] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [revocationReason, setRevocationReason] = useState(null);
  const [comment, setComment] = useState('');
  const onDialogToggle = () => {
    setDialogOpened(!dialogOpened);

    if (!dialogOpened) {
      setRevocationReason(null);
      setComment(null);
    }
  };

  const afterAction = () => {
    setDialogOpened(false);
    addToast('Запрос на аннулирование создан');
    afterActionCallback && afterActionCallback();
  };

  const revokeCert = async () => {
    setInProgress(true);
    const response = await service(
      'certificateAuthorityCertificateService',
      CAUser ? 'caRevocation' : 'revocation',
      {
        certificateSn: item?.id,
        revocationReason: revocationReason?.id,
        comment: comment,
      }
    );
    const { isError, errors } = response || {};
    !isError && afterAction();
    isError && dispatchLogic(dispatch, 'MOCK', null, isError, errors);
    setInProgress(false);
  };

  return (
    <>
      {inProgress && <Overlay />}
      <Button
        type={buttonTypes.image}
        onClick={onDialogToggle}
        disabled={disabled || item?.status?.id !== 'Valid'}
        tooltip="Аннулировать"
      >
        <Img img={Image.Stop} />
      </Button>
      {dialogOpened && (
        <ConfirmDialog
          header={`Аннулирование сертификата`}
          type={dialogType.confirm}
          submitText="Аннулировать"
          submitDisabled={!revocationReason}
          onSubmit={revokeCert}
          onCancel={onDialogToggle}
        >
          <div className={styles.reason}>
            <Autocomplete
              label="Причина аннулирования"
              notStandardService={{
                serviceName: 'certificateAuthorityCertificateService',
                serviceMethod: 'revocationReasons',
              }}
              onSelect={setRevocationReason}
              value={revocationReason}
            />
          </div>
          <TextArea
            className={styles.comment}
            label="Комментарий"
            value={comment}
            onChange={setComment}
          />
        </ConfirmDialog>
      )}
    </>
  );
}

