/** @format */

import React from 'react';
import Group from 'app/components/ui/managePanel/group';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import { useJournal } from 'app/components/journals/useJournal';
import { sortDirection } from 'app/components/ui/constants';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import { Table } from 'app/components/list/Table/Table';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import PageHeader from '../../PageHeader/pageHeader';
import Overlay from 'app/components/ui/overlay';
import { Border } from 'app/components/ui/managePanel';
import { OkiConfig as config } from './pageConfig';
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from 'app/components/ui/MainListContainer/MainListContainer';
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import styles from './keyCarrierList.module.scss';
import Filter, { GlobalOrgType } from 'app/components/filter';
import Images from 'app/components/ui/Img/imgMap';
import { DownloadButton } from 'app/components/list/TopPanel/DownloadButton';

export default function KeyCarrierList() {
  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    onClearFilter,

    // useReducerTable
    sortListAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,

    // global
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
    filterOpened,
    toggleFilterState,
  } = useJournal({
    defaultSort: {
      column: 'id',
      direction: sortDirection.desc,
    },
    serviceName: 'keyCarrierOkiReportService',
  });

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title="Журналы учета: Ключевые носители для Организаций" Img={Images.KeyCarrier} />
      <ManagePanel borders={Border.All}>
        <Group>
          <DownloadButton
            id={globalOrgId}
            listFilterForRequest={filterForRequest}
            sorting={sorting}
            listFieldsVisibility={fieldsVisible}
            selected={selectedObjects.map(i => i.id)}
            titul="Выгрузить в Excel"
            serviceName="keyCarrierOkiReportService"
          />
        </Group>
        <Group separate>
          <FilterTriggerPanel
            toggle={toggleFilterState}
            filterFilled={filterFilled}
            onClearFilter={onClearFilter}
            disabledClear={!filterState.length}
          />
        </Group>
        <Group separate>
          <TableFieldSelect
            listFieldsVisible={fieldsVisible}
            setVisibleFieldsAction={setVisibleFieldsAction}
            toggleVisibleFieldAction={toggleVisibleFieldAction}
            headerInfo={true}
            fieldsConfig={config.fieldsConfig}
            changedFieldsVisibility={true}
            settingsAsGear={true}
          />
        </Group>
      </ManagePanel>
      <FilterWithTableContainer useContainer={filterOpened} listDisplayVariant={ListDisplayVariant.threeRowFilter}>
        <Filter
          className={styles.okiKeyCarrierFilter}
          inProgress={inProgress}
          setGlobalOrg={setGlobalOrg}
          globalOrganization={globalOrganization}
          setFilterStateAction={setFilterStateAction}
          setFilterForRequestAction={setFilterForRequestAction}
          setFullFilterStateAction={setFullFilterStateAction}
          filterState={filterState}
          filterFilled={filterFilled}
          profile={profile}
          globalOrgId={globalOrgId}
          filtersConfig={config.tablePageFiltersConfig}
          isOpened={filterOpened}
          toggleFilterState={toggleFilterState}
          withGlobalOrg
          globalOrgType={GlobalOrgType.okiOnly}
          globalOrgPosition={4}
        />
        <Table
          {...{
            totalCount,
            list,
            sortListAction,
            sorting,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedPagination,
            inProgress,
          }}
          listFieldsVisible={fieldsVisible}
          fieldsConfig={config.fieldsConfig}
          itemsSelectable={true}
          changedFieldsVisibility={true}
          onItemClick={item => {
            return `/keyDocument/keyCarrier/card/${item.keyCarrier.id}`;
          }}
          headerInfo={true}
        />
      </FilterWithTableContainer>
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} perPage={perPage} />
    </MainListContainer>
  );
}

