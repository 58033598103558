/** @format */

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DatePicker from 'app/components/ui/date';
import Button, { buttonTypes } from 'app/components/ui/button/button';
import Alert from 'app/components/ui/Alert';
import { Table } from 'app/components/list/Table/Table';
import SpinnerLocal from 'app/components/ui/SpinnerLocal';
import withModal from 'app/components/HOC/ObsoleteModalHOC';

import Severity from 'app/core/types/severity';

import getTableSchema from './tableSchema';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { sortDirection } from 'app/components/ui/constants';
import { api } from 'app/services/service';
import { getFullSkziName } from 'app/core/entities/skzi';

import LinkedItemAction from './linkedItemAction';
import styles from './closeDialog.module.scss';

const fetchLinkedSkzis = async eskziUserId => {
  const eskziResult = await api('instance/getAll', { filterData: { eskziUserId } });
  const { data } = eskziResult;
  return data.map(item => {
    const {
      id,
      serialNumber,
      skzi: { skzi, version, build, skziClass, isHardwareSkzi, isSoftwareSkzi, isHardSoftSkzi } = {},
    } = item;
    return {
      id,
      name: getFullSkziName({ skzi, version, build, skziClass }),
      serialNumber,
      isHardwareSkzi,
      isSoftwareSkzi,
      isHardSoftSkzi,
    };
  });
};

const fetchLinkedKeyDocuments = async eskziUserId => {
  const eskziResult = await api('keyDocument/getAll', { filterData: { eskziUserId } });
  const { data } = eskziResult;
  return data.map(item => {
    const { id, name, serialNumber } = item;
    return {
      id,
      name,
      serialNumber,
    };
  });
};

const fetchLinkedKeyCarriers = async eskziUserId => {
  const eskziResult = await api('keyCarrier/getAll', { filterData: { eskziUserId } });
  const { data } = eskziResult;
  return data.map(item => {
    const { id, keyCarrierType, serialNumber, single } = item;
    return {
      id,
      name: keyCarrierType && keyCarrierType.name,
      serialNumber,
      single,
    };
  });
};

const defaultEskziActionSelector = ({ isSoftwareSkzi, isHardSoftSkzi }) => {
  return isSoftwareSkzi
    ? { action: LinkedItemAction.Destroy, readOnly: true }
    : isHardSoftSkzi
    ? { action: null, readOnly: false }
    : { action: LinkedItemAction.Withdraw, readOnly: true };
};

const defaultKeyDocumentActionSelector = _ => ({ action: LinkedItemAction.Destroy, readOnly: true });

const defaultKeyCarrierActionSelector = ({ single }) => {
  return single
    ? { action: LinkedItemAction.Destroy, readOnly: true }
    : { action: LinkedItemAction.Withdraw, readOnly: true };
};

const CloseDialog = props => {
  const { onCancel, eskziUser, onSubmitSignEnhance, closeUADate, setCloseUADate } = props;

  const profile = useSelector(({ profile }) => profile);
  const [inProgress, setInProgress] = useState();
  const [eskzis, setEskzis] = useState([]);
  const [keyDocuments, setKeyDocuments] = useState([]);
  const [keyCarriers, setKeyCarriers] = useState([]);
  const [eskzisActions, setEskzisActions] = useState({});
  const [keyDocumentsActions, setKeyDocumentsActions] = useState({});
  const [keyCarriersActions, setKeyCarriersActions] = useState({});

  useEffect(() => {
    const fetchLinkedItems = async () => {
      setInProgress(true);
      const results = await Promise.all([
        fetchLinkedSkzis(eskziUser),
        fetchLinkedKeyDocuments(eskziUser),
        fetchLinkedKeyCarriers(eskziUser),
      ]);

      const [eskziResult, keyDocumentResult, keyCarrierResult] = results;

      setEskzis(eskziResult);
      setKeyDocuments(keyDocumentResult);
      setKeyCarriers(keyCarrierResult);
      setEskzisActions(Object.fromEntries(eskziResult.map(eskzi => [eskzi.id, defaultEskziActionSelector(eskzi)])));
      setKeyDocumentsActions(
        Object.fromEntries(keyDocumentResult.map(kd => [kd.id, defaultKeyDocumentActionSelector(kd)]))
      );
      setKeyCarriersActions(
        Object.fromEntries(keyCarrierResult.map(kc => [kc.id, defaultKeyCarrierActionSelector(kc)]))
      );
      setInProgress(false);
    };

    fetchLinkedItems();
  }, [eskziUser]);

  const linkedItemsCount = eskzis.length + keyDocuments.length + keyCarriers.length;

  const getItemIdsByAction = (itemActions, action) =>
    Object.entries(itemActions)
      .filter(e => e[1].action === action)
      .map(e => +e[0]);

  const eskzisToWithdraw = getItemIdsByAction(eskzisActions, LinkedItemAction.Withdraw);
  const keyDocumentsToWithdraw = getItemIdsByAction(keyDocumentsActions, LinkedItemAction.Withdraw);
  const keyCarriersToWithdraw = getItemIdsByAction(keyCarriersActions, LinkedItemAction.Withdraw);
  const itemsToWithdrawCount = eskzisToWithdraw.length + keyDocumentsToWithdraw.length + keyCarriersToWithdraw.length;

  const eskzisToDestroy = getItemIdsByAction(eskzisActions, LinkedItemAction.Destroy);
  const keyDocumentsToDestroy = getItemIdsByAction(keyDocumentsActions, LinkedItemAction.Destroy);
  const keyCarriersToDestroy = getItemIdsByAction(keyCarriersActions, LinkedItemAction.Destroy);
  const itemsToDestroyCount = eskzisToDestroy.length + keyDocumentsToDestroy.length + keyCarriersToDestroy.length;

  const withdrawAll = itemsToWithdrawCount === linkedItemsCount;
  const destroyAll = itemsToDestroyCount === linkedItemsCount;
  const groupActionChangeDisabled =
    eskzisActions &&
    Object.values(eskzisActions).every(a => a.readOnly) &&
    keyDocumentsActions &&
    Object.values(keyDocumentsActions).every(a => a.readOnly) &&
    keyCarriersActions &&
    Object.values(keyCarriersActions).every(a => a.readOnly);

  const getOnChangeActionForAll = action => () => {
    const updater = prevValue => {
      const newValue = Object.fromEntries(
        Object.entries(prevValue).map(e => [
          e[0],
          { action: e[1].readOnly ? e[1].action : action, readOnly: e[1].readOnly },
        ])
      );
      return newValue;
    };
    setEskzisActions(updater);
    setKeyDocumentsActions(updater);
    setKeyCarriersActions(updater);
  };

  const getOnChangeItemAction = (itemActionsSetter, action) => id => {
    itemActionsSetter(prevValue => {
      const newValue = Object.fromEntries(
        Object.entries(prevValue).map(e => [e[0], +e[0] === +id && !e[1].readOnly ? { action, readOnly: false } : e[1]])
      );
      return newValue;
    });
  };

  const headerTableSchema = getTableSchema({
    withdrawAll,
    destroyAll,
    groupActionChangeDisabled,
    onWithdrawAll: getOnChangeActionForAll(LinkedItemAction.Withdraw),
    onDestroyAll: getOnChangeActionForAll(LinkedItemAction.Destroy),
  });
  const eskzisTableSchema = getTableSchema({
    itemsActions: eskzisActions,
    onWithdrawItem: getOnChangeItemAction(setEskzisActions, LinkedItemAction.Withdraw),
    onDestroyItem: getOnChangeItemAction(setEskzisActions, LinkedItemAction.Destroy),
  });

  const keyDocumentsTableSchema = getTableSchema({
    itemsActions: keyDocumentsActions,
    onWithdrawItem: getOnChangeItemAction(setKeyDocumentsActions, LinkedItemAction.Withdraw),
    onDestroyItem: getOnChangeItemAction(setKeyDocumentsActions, LinkedItemAction.Destroy),
  });

  const keyCarriersTableSchema = getTableSchema({
    itemsActions: keyCarriersActions,
    onWithdrawItem: getOnChangeItemAction(setKeyCarriersActions, LinkedItemAction.Withdraw),
    onDestroyItem: getOnChangeItemAction(setKeyCarriersActions, LinkedItemAction.Destroy),
  });

  const { sorting, sortListAction } = useTableExtensions({
    defaultSort: {
      column: 'name',
      direction: sortDirection.desc,
    },
  });

  const tableAddonProps = {
    sorting,
    sortListAction,
  };

  // Handles linked items sorting
  useEffect(() => {
    const { column, direction } = sorting;

    const ItemsType = {
      eskzis: 'eskzis',
      keyDocuments: 'keyDocuments',
      keyCarriers: 'keyCarriers',
    };

    const actionMaps = {
      [ItemsType.eskzis]: eskzisActions,
      [ItemsType.keyDocuments]: keyDocumentsActions,
      [ItemsType.keyCarriers]: keyCarriersActions,
    };

    const ActionCol = {
      withdraw: 'withdraw',
      destroy: 'destroy',
    };

    const actionColToActionMap = {
      [ActionCol.withdraw]: LinkedItemAction.Withdraw,
      [ActionCol.destroy]: LinkedItemAction.Destroy,
    };

    const getSortableValue = (item, itemsType, col) => {
      return col in ActionCol
        ? // Sorting by action cols requires retrieving data from action maps
          actionMaps[itemsType]?.[item.id]?.action === actionColToActionMap[col]
        : // Sorting by other cols is straightforward, as they map directly to item fields
          item[col];
    };

    const getComparer = itemsType => (a, b) =>
      direction *
      (getSortableValue(a, itemsType, column) > getSortableValue(b, itemsType, column)
        ? 1
        : getSortableValue(a, itemsType, column) < getSortableValue(b, itemsType, column)
        ? -1
        : 0);

    setEskzis([...eskzis].sort(getComparer(ItemsType.eskzis)));
    setKeyDocuments([...keyDocuments].sort(getComparer(ItemsType.keyDocuments)));
    setKeyCarriers([...keyCarriers].sort(getComparer(ItemsType.keyCarriers)));
  }, [sorting]);

  const itemsWithActionCount = itemsToWithdrawCount + itemsToDestroyCount;
  const itemsCount = eskzis.length + keyDocuments.length + keyCarriers.length;
  const confirmDisabled = !closeUADate || itemsWithActionCount !== itemsCount;

  const handleCloseUAConfirm = async () => {
    const { userInfo } = profile;
    const { id: signerId, organization } = userInfo;
    const { id: organizationId } = organization;

    onSubmitSignEnhance({
      eskziUserId: eskziUser,
      closeDate: closeUADate,
      signers: [signerId],
      organizationId,
      eskzis: eskzisToDestroy,
      keyDocuments: keyDocumentsToDestroy,
      keyCarriers: keyCarriersToDestroy,
      signatureRequestType: 'closeUa',
    });
  };

  return (
    <div className={`user-account-close ${styles.closeDialog}`}>
      <div className="modal__header">Закрытие лицевого счета</div>

      <div className={`modal__content user-account-close__content ${styles.content}`}>
        <Alert
          severity={Severity.Warning}
          showIcon
          content="Укажите дату закрытия лицевого счета и отметьте объекты учета (при наличии) для изъятия или уничтожения"
        />
        <DatePicker label="Дата закрытия" value={closeUADate} onChange={setCloseUADate} />
        <div className={styles.data}>
          <Table
            {...tableAddonProps}
            totalCount={linkedItemsCount}
            totalCountLabel="всего результатов"
            list={[]}
            fieldsConfig={headerTableSchema}
            strictWidth
          />
          {inProgress && <SpinnerLocal className={styles.spinner} />}
          {!inProgress && !eskzis.length && !keyDocuments.length && !keyCarriers.length && (
            <div className={styles.noData}>Связанные объекты учета отсутствуют</div>
          )}
          {eskzis.length > 0 && (
            <Table
              {...tableAddonProps}
              totalCount={eskzis.length}
              totalCountLabel="экземпляры скзи"
              list={eskzis}
              fieldsConfig={eskzisTableSchema}
              hideHeader
              strictWidth
            />
          )}
          {keyDocuments.length > 0 && (
            <Table
              {...tableAddonProps}
              totalCount={keyDocuments.length}
              totalCountLabel="ключевые документы"
              list={keyDocuments}
              fieldsConfig={keyDocumentsTableSchema}
              hideHeader
              strictWidth
            />
          )}
          {keyCarriers.length > 0 && (
            <Table
              {...tableAddonProps}
              totalCount={keyCarriers.length}
              totalCountLabel="ключевые носители"
              list={keyCarriers}
              fieldsConfig={keyCarriersTableSchema}
              hideHeader
              strictWidth
            />
          )}
        </div>
      </div>

      <div className={`modal__footer ${styles.footer}`}>
        <Button
          type={buttonTypes.text}
          disabled={confirmDisabled}
          className="btn-confirm"
          onClick={handleCloseUAConfirm}
        >
          <span className="button-title">Подтвердить</span>
        </Button>
        <Button type={buttonTypes.text} className="btn-cancel" onClick={onCancel}>
          <span className="button-title">Отмена</span>
        </Button>
      </div>
    </div>
  );
};

export default withModal(CloseDialog);
