/** @format */

import { dialogType } from './confirmDialog/';

export const getDefaultWarningDialogProps = warningObject => {
  const { error: { errorMessage } = {} } = warningObject || {};
  return {
    type: dialogType.danger,
    header: 'Подтвердите выполнение операции',
    messages: [errorMessage],
  };
};
