import { 
    AUTHORIZE,
    UPDATEPROFILE,
    RUNAUTHORIZE,
    FINISHAUTHORIZE,
    LOGOUT,
    AUTHORIZEERROR,
    TRIALHIDE,
    ERRORPAGEERROR
} from '../actions/actionTypes'
import { formatFIO } from 'app/core/utility/common'

const defaultState = {
    isAuthorised: false,
    authorizationComplete: false,
    isLoggedOut: false,
    userType: 'AOKZ',
    licenseInfo: {},
    userInfo: {
        lastName: "",
        firstName: "",
      },
    accessRights: [],
    inProgress: false
}

export default (state = defaultState, action) => {
    const { licenseInfo: stateLicence } = state
    const { payload }  = action

    switch (action.type) {
        case RUNAUTHORIZE: 
            return {
                ...state,
                inProgress: true
            }

        case FINISHAUTHORIZE: 
            return {
                ...state,
                authorizationComplete: true,
                inProgress: false
            }

        case AUTHORIZE:
        case UPDATEPROFILE:
            const { userInfo, accessRights, logoutUrl, licenseInfo } = payload || {}
            const profile = {
                ...payload,
                licenseInfo,
                currentUser: userInfo,
                name: formatFIO(userInfo),
                logoutUrl,
                accessRights,
                isAuthorised: true,
                authorizationComplete: true,
                inProgress: false
            }

            return profile

        case LOGOUT:
            const { userType } = state
            return {
                ...defaultState,
                userType,
                isLoggedOut: true,
                inProgress: false
            }

        case TRIALHIDE:
            return {
                ...state,
                licenseInfo: {
                    ...stateLicence,
                    trialMessageHidden: true
                }
            }

        case AUTHORIZEERROR: 
            return {
                ...defaultState,
                authorizationComplete: true,
                inProgress: false
            }

        case ERRORPAGEERROR: 
            const { detail } = payload
            const { status, userType: responseUserType } = detail
            const actualUserType = responseUserType || defaultState.userType
            
            // always login page for 401
            if (status === 401) { 
                return {
                    ...defaultState,
                    authorizationComplete: true,
                    isLoggedOut: actualUserType !== 'AOKZ', // for poib 
                    userType: actualUserType,
                    inProgress: false
                }
            }

            // show no rights 
            if (status === 403) {
                return {
                    ...state,
                    inProgress: false,
                    authorizationComplete: true,
                }
            }

        default: 
            return state
    }
}