import React, {useState, useEffect} from "react";
import DownloadState from "app/components/downloadState/DonwloadState";
import Input from "app/components/ui/Input";
import useDebounce from "app/hooks/useDebounce";
import Alert from 'app/components/ui/Alert';
import Severity from 'app/core/types/severity';

import styles from './FilterFileContent.module.scss';

export const FilterFileContent = ({headerText, headerState = Severity.Success, payload, setPayload, isDisabledGroupDuplicates, downloadsList, setGroupDuplicatesState, isGroupDuplicates}) => {
    const [inputValue, setInputValueState] = useState(null);
    const debouncedSearchTerm = useDebounce(inputValue, 1000);

    const onClickGroupDuplicatesHandler = () => {
        setPayload({
            ...payload,
            conditions: {
                ...payload.conditions,
                groupDuplicates: !isGroupDuplicates
            }
        });
        setGroupDuplicatesState(!isGroupDuplicates);
    };

    useEffect(() => {
        if (debouncedSearchTerm || debouncedSearchTerm === '') {
            setPayload({
                ...payload,
                conditions: {
                    ...payload.conditions,
                    searchRequests: [inputValue]
                }
            });
        }
    }, [debouncedSearchTerm]);

    return <div className={styles.filterFileContent}>
        <Alert severity={headerState} content={headerText} showIcon={headerState === Severity.Error} className={styles.alert} />
        <DownloadState
            downloadsList={downloadsList}
            isGroupDuplicates={isGroupDuplicates}
            isDisabled={isDisabledGroupDuplicates}
            groupingHandler={onClickGroupDuplicatesHandler}
            headerText={headerText}
            className={styles.dlState}
        />
        <Input
            type={'text'}
            label='Поиск'
            value={inputValue}
            useCancelIcon
            onChange={(value) => setInputValueState(value)}
            absoluteInfo
            className={styles.nameFilter}
        />
    </div>;
};
