import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import service from "../../services/service";
import {dispatchLogic} from "../../../redux/actions/common";
import useToast from "../ui/toast/useToast";
import {useHistory} from "react-router-dom";
import LicenseType from "app/core/types/licenseType";

export default function withCheckLicenses(Component){
  return (props) => {
    const {licenseType} = props
    const dispatch = useDispatch()
    const {addToast} = useToast()
    const history = useHistory()
    useEffect( () => {
      const getAvailableLicenses = async () => {
        const response = await service('licensingService', 'checkAvailableLicense')
        const {data, isError, errors} = response || {}
        if(licenseType === LicenseType.eskzi) {
          if(data && !data.eskziLicenses){
            // FIXME Нарушение SRP в 2 строках ниже
            history.length > 1 ? history.goBack() : history.push(  `/skziAccounting`)            
            addToast("Недостаточно пользовательских лицензий для создания сущности")
          }
        } else if(data && !data.keyDocumentLicenses) {
          // FIXME Нарушение SRP в 2 строках ниже
          history.length > 1 ? history.goBack() : history.push( `/keyDocument/okz` )          
          addToast("Недостаточно пользовательских лицензий для создания сущности")
        }
        dispatchLogic(dispatch, 'MOCK', data, isError, errors )
      }
      getAvailableLicenses();
    }, [])
    return <Component {...props} />
  }
}