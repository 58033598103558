import React from 'react';

import { Services } from 'app/components/ui/autocomplete/autocomplete';
import { formatSKZI } from 'app/components/list/Table/TableBody';
import { formatDate } from 'app/core/utility/date';
import { sortDirection } from 'app/components/ui/constants';
import { FilterControlType } from 'app/components/filter/FilterElement';

export default userId => ({
  bookingApi: 'skziInstance/bookEvent',
  issueApi: 'skziInstance/issueEvent',
  pickerConfig: {
    pickerService: {
      serviceName: 'exemplarSelectService',
      serviceMethod: 'getAll',
      data: { id: userId },
    },
    serviceType: Services.skziInstance,
    modalHeader: 'Экземпляры СКЗИ',
    filterConfig: [
      {
        type : FilterControlType.simpleSearch, 
        alias : 'id', 
        label : '№',
        pattern: '^[0-9]*$',
        extended : false,
        order : 1
      },
      {
        type : FilterControlType.simpleSearch, 
        alias : 'serialNumbers', 
        label : 'Регистрационный N',
        extended : false,
        order : 2,
        requestFormatIsArray : true,
      },
      {
        type : FilterControlType.autocomplete, 
        alias : 'skziVersionId', 
        label : 'СКЗИ', 
        serviceType : 'skziVersion',
        extended : false,
        order : 3
      },
      { 
        type : FilterControlType.checkbox, 
        alias : 'onlyStandard', 
        label : 'Эталонные экземпляры',
        extended : false,
        order : 4,
      },
    ],
    pickerColumns: {
      columns: [
        { 
          title : '№',
          titleInfo : '№', 
          alias : 'id',
          useSorting: true,
          width : 100 
        },
        {
          title: 'СКЗИ',
          alias: 'skzi',
          useSorting: true,
          format: item =>
            formatSKZI(item.skzi, item.isStandard === true),
        },
        {
          title: 'Регистрационный №',
          alias: 'serialNumber',
          useSorting: true,
        },
        {
          title: 'Дата изменения',
          alias: 'updateDate',
          useSorting: true,
          width: 150,
          format: item => {
            return (
              <span className="skzi-expire-date">
                {formatDate(item.updateDate, 'dd.mm.yyyy')}
              </span>
            );
          },
        },
      ],
      sort: {
        column: 'updateDate',
        direction: sortDirection.desc,
      },
    },
  },
});
