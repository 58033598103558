import { getClassName } from 'app/core/utility/jsx';
import React from 'react';
import styles from './filter.module.scss';

export default function FilterFull({
  children,
  className
}) {
  return (
    <div className={getClassName([
      styles.filterFull,
      className || ''
    ])}>
      {children}
    </div>
  )
}