/** @format */

import React from 'react';
import ChartLegend, { oneHundredPercentMagic } from '.';
import { CIRCLE_COLOR, CIRCLE_SIZE } from '../../typography';
import { percentage } from 'app/core/utility/common';
import styles from './legend.module.scss';

export default function KeyDocLegend({ total, available, moved, installed }) {
  let activePercent = percentage(total, available);
  let movedPercent = percentage(total, moved);
  let installedPercent = percentage(total, installed);

  [activePercent, movedPercent, installedPercent] = oneHundredPercentMagic(
    activePercent,
    movedPercent,
    installedPercent
  );

  return (
    <div className={styles.keyDocLegend}>
      <ChartLegend
        headerText={'Ключевых документов по статусам'}
        headerData={total}
        dataRows={[
          {
            title: 'Активных',
            data: available,
            // reference: '',
            circle: {
              color: CIRCLE_COLOR.available,
              percent: activePercent,
              size: CIRCLE_SIZE.regular,
            },
          },
          {
            title: 'Установлено',
            data: installed,
            // reference: '',
            circle: {
              color: CIRCLE_COLOR.installed,
              percent: installedPercent,
              size: CIRCLE_SIZE.regular,
            },
          },
          {
            title: 'Передано в организации',
            data: moved,
            // reference: '',
            circle: { color: CIRCLE_COLOR.moved, percent: movedPercent, size: CIRCLE_SIZE.regular },
          },
        ]}
      />
    </div>
  );
}

