import * as React from 'react'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from 'redux/store/store'

import AppContainer from './appContainer/appContainer'


class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <AppContainer/>
        </HashRouter>
      </Provider>
    );
  }
}

export default App