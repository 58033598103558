import { 
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  SELFSERVICEREGISTRYGETALL,
  SELFSERVICEREGISTRYINPROGRESS, 
  SELFSERVICEREGISTRYRESETACTION, 
  SELFSERVICEREGISTRYCLEARSTOREACTION,
  SELFSERVICEREGISTRYPAGINATIONGET, 
  SELFSERVICEREGISTRYSETFILTERACTION,
  SELFSERVICEREGISTRYONBUILDSSORT,
  SELFSERVICEREGISTRYONCOURSESSORT,
  SELFSERVICEREGISTRYGETBUILDSETDATA,
  SELFSERVICEREGISTRYINSTALLSKZI,
  SELFSERVICEREGISTRYCREATECONCLUSION,
  SIGNATUREREADYTOSIGN,
  SELFSERVICEREGISTRYCOURSEASSIGN,
  SELFSERVICEREGISTRYEXAMREQUEST,
  SELFSERVICEREGISTRYONCOURSEASSIGN
} from '../../actions/actionTypes'

import { updateCollectionByItemId } from 'app/core/utility/common'
import { getPageData } from 'app/components/ui/pagination/pagination'
import config from 'app/core/config'

const { paginationPerPage } = config

const defaultState = {
  filter: {
    status: { id: 'All', title: 'Все'},
    producer: null,
    type: null
  },
  items: [],
  pageItems: [],
  pagination: { activePage: 1, itemsPerPage: paginationPerPage, dataCount: 0 },
  confirmObject: null,
  assignCourseDialog: 0,
  examResultDialog: false,
  assignCouseResultDialog: false,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { 
      items,
      pagination, 
      pageItems,
      filter
  } = state
  const { itemsPerPage: statePerPage, activePage: stateActivePage } = pagination

  switch (action.type) {
    case SELFSERVICEREGISTRYGETALL:

      return {
        ...state,
        items: payload.data,
        pagination: {activePage: 1, itemsPerPage: statePerPage, dataCount: payload.dataCount},
        inProgress: false
      }

    case SELFSERVICEREGISTRYPAGINATIONGET:
      const { activePage, itemsPerPage } = payload

      return {
        ...state,
        pagination: { activePage, itemsPerPage },
        pageItems: getPageData({ items, activePage, itemsPerPage }),
        inProgress: false
      }

    case SELFSERVICEREGISTRYINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case SELFSERVICEREGISTRYSETFILTERACTION:
      const { key, value } = payload

      return {
        ...state,
        filter: {
          ...filter,
          [key]: value
        }
      }

    case SELFSERVICEREGISTRYGETBUILDSETDATA:
      return {
        ...state,
        getBuildDialog: payload
      }

    case SELFSERVICEREGISTRYONCOURSESSORT:
      const { data: courseData, versionId: payloadVersionId } = payload
      const updatedVer = items.find(item => item.id === payloadVersionId)

      if (!updatedVer) {
        return {
          ...state,
          inProgress: false
        }
      }

      const newVersionItem = { 
        ...updatedVer,
        courses: courseData
      }

      return {
        ...state,
        items: updateCollectionByItemId(items, newVersionItem),
        pageItems: updateCollectionByItemId(pageItems, newVersionItem),
        inProgress: false
      } 
    
    case SELFSERVICEREGISTRYONBUILDSSORT:
      const { data, versionId: sortVersionId } = payload
      const updatedItem = items.find(item => item.id === sortVersionId)

      if (!updatedItem) {
        return {
          ...state,
          inProgress: false
        }
      }

      const newItem = { 
        ...updatedItem,
        builds: data
      }

      const updated = 
      {
        ...state,
        items: updateCollectionByItemId(items, newItem).filter(item => item.builds.length),
        pageItems: updateCollectionByItemId(pageItems, newItem).filter(item => item.builds.length),
        inProgress: false
      } 

      return updated

    case SELFSERVICEREGISTRYCREATECONCLUSION:
      return {
        ...state,
        currentConclusion: payload,
        inProgress: false
      }

    case SELFSERVICEREGISTRYRESETACTION: 
      return { 
        ...state, 
        confirmObject: null,
        currentConclusion: null,
        getBuildDialog: null,
        assignCourseDialog: 0,
        examResultDialog: false,
        assignCouseResultDialog: false,
        error: null
      }

    case SIGNATUREREADYTOSIGN:
      return {
        ...state,
        //getBuildDialog: null,
        inProgress: false,
      }

    case SELFSERVICEREGISTRYCOURSEASSIGN:
      return {
        ...state,
        items: updateCollectionByItemId(items, payload),
        pageItems: updateCollectionByItemId(pageItems, payload),
        assignCouseResultDialog: true,
        inProgress: false
      } 

    case SELFSERVICEREGISTRYEXAMREQUEST:
      return {
        ...state,
        items: updateCollectionByItemId(items, payload),
        pageItems: updateCollectionByItemId(pageItems, payload),
        examResultDialog: true,
        inProgress: false
      } 

    case SELFSERVICEREGISTRYONCOURSEASSIGN:
      return {
        ...state,
        assignCourseDialog: payload
      }

    case SELFSERVICEREGISTRYCLEARSTOREACTION:
      return {
        ...defaultState
      }

    case ERRORPAGEERROR:
    case ERROR:
    case FIELDSERROR:
    case SELFSERVICEREGISTRYINSTALLSKZI:
      return {
        ...state,
        inProgress: false
      }

    default: 
      return state
  }
}