import React, { Component } from 'react'

import FileUploadControl from 'app/components/ui/file/fileUploadControl'
import { fileListAPI } from 'app/core/utility/file'
import Button from 'app/components/ui/button/button'
import withModal from 'app/components/HOC/ObsoleteModalHOC'

class addTypeDialog extends Component {
  constructor(props){
    super(props)

    this.state = {
      allFilesProgressComplete: false,
      files: [],
    }
    this.fileControl = React.createRef();
  }

  componentWillUnmount () {
    const { unmountAction } = this.props
    unmountAction && unmountAction()
  }

  _onUpload = async () => {
    const { onUpload } = this.props
    const { current } = this.fileControl
    current._upload()
    onUpload && onUpload()
  }

  _onCancel = async () => {
    const { onCancel, forceShow } = this.props

    !forceShow && onCancel()
  }

  _onFileChange = (files) => {
    this.setState({files: files})
  }

  _uploadFinished = (result) => {
    const { onFileUploaded } = this.props
    onFileUploaded && onFileUploaded(result )
  }

  _allFilesProgressComplete = () => {
    this.setState({ allFilesProgressComplete: true })
  }

  render() {
    const { files } = this.state
    const urlPart = `EskziLicenseManagement/uploadLicense`
    const cancelButtonText = 'Отмена'
    const fileApi = fileListAPI( null, urlPart )

    return (
      <div className="add-license-dialog">
        <FileUploadControl
          ref={this.fileControl}
          className='add-type-dialog__file-area'
          title='Добавить файл'
          fileApi={fileApi}
          onFileChanged={this._onFileChange}
          onUploadFinish={this._uploadFinished}
          onAllFilesProgressComplete={this._allFilesProgressComplete}
          isMultiple={false}
          disabled={files.length}
        />
        <div className="add-type-dialog__buttons-panel">
          <Button
            type='text'
            onClick={this._onUpload}
            disabled={!files.length}
          >
            <span className='button-title'>Добавить лицензии</span>
          </Button>
          <Button
            type='text'
            onClick={this._onCancel}
          >
            <span className='button-title'>{cancelButtonText}</span>
          </Button>
        </div>
      </div>
    )
  }
}

const Dialog = withModal(addTypeDialog)

export default Dialog;