import {useEffect} from "react";
import service from "../../../services/service";
import { capitalize } from 'app/core/utility/common'

export default function useOrganizationForm({formData, serverFormViewModel, onAdd, errorByFields}){

  function getFormDataValue(fieldName){
    const capitalizeKey = capitalize(fieldName)
    const lineViewModel = serverFormViewModel ? serverFormViewModel[capitalizeKey] : null
    return formData[lineViewModel ? capitalizeKey : fieldName]
  }

  function getFieldsetError(fieldsetName, fieldName, index){
    return errorByFields[`${capitalize(fieldsetName)}[${index}].${capitalize(fieldName)}`]
  }

  const filterOrganizationCallback = (e) => {
    return {
      ...e
    }
  }

  const filterPathCallback = (e, pageSize) => {
    const { data }  = e || {}
    const { length } = data || {}
    const dataCount = Math.min(pageSize, length)
    return {
      dataCount: dataCount,
      ...e
    }
  }

  const autocompleteOrganizationService = {
    serviceName: 'organizationService',
    serviceMethod: 'getServedByUserAutocompleteReduced',
    data: {reducerCallback: filterOrganizationCallback}
  }

  const autocompletePathService = {
    serviceName: 'activeDirectorySettingsService',
    serviceMethod: 'getOrganizationAutocompleteReduced',
    data: {reducerCallback: filterPathCallback}
  }

  const pickerService = {
    serviceName: 'organizationService',
    serviceMethod: 'getServedByUserPickerReduced',
    data: {reducerCallback: filterOrganizationCallback}
  }

  useEffect(() => {
    if(Array.isArray(getFormDataValue('organizations')) && getFormDataValue('organizations').length === 0){
      onAdd('organizations', {organizationPath: '', organization: ''})
    }
  }, [formData])

  return {
    autocompleteOrganizationService,
    autocompletePathService,
    getFieldsetError,
    getFormDataValue,
    pickerService,
  }
}