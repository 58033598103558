import React, { Component } from 'react'

import SubMenuItem from './subMenu'
import {ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'
import {isActive} from './navigationContent'

const navItem = class navItem extends Component {

  render() {
    const { 
        name, 
        link, 
        title, 
        ImgComponent,
        submenu, 
        onSubmenuToggle,
        onMenuToggle,
        menuOpened,
        menuHovered,
        location, 
        activeSubmenu  } = this.props
    const showSubmenu = activeSubmenu === name && (menuHovered || menuOpened)
    
    return (
        <li 
            key={name} 
            className={`nav-menu__element ${isActive(location.pathname, link) ? 'nav-menu__element--active' : ''}`}
        >
            {submenu 
                ? (
                    <div className='element__menu-nav-parent-link' >
                        <div className={`element__menu-nav-link submenu-toggle--${showSubmenu ? 'expanded' : 'collapsed' }`} onClick={() => onSubmenuToggle(name)}>
                            <div className='element__menu-nav-logo'>
                                {ImgComponent ? <ImgComponent></ImgComponent> : null}
                            </div>
                            <span className='element__menu-nav-title'>{title}</span>
                            <ChevronImg
                                className={`element__submenu-toggle `}
                            />
                        </div>
                        <div className={`element__sub-menu content-toggle--${showSubmenu ? 'expanded' : 'collapsed'}`}>
                            <ul className="sub-menu">
                                {submenu.map((item, index) =>
                                    <SubMenuItem 
                                        key={index} 
                                        {...item} 
                                        rootLink={link} 
                                        location={location}
                                        onClick={onMenuToggle}
                                    />)}
                            </ul>
                        </div>
                    </div>
                )
                : (
                    <a 
                        href={link} 
                        className={`element__menu-nav-link`}
                        onClick={onMenuToggle}
                    >
                        <div className='element__menu-nav-logo'>
                            {ImgComponent ? <ImgComponent></ImgComponent> : null}</div>
                        <span className='element__menu-nav-title'>{title}</span>
                    </a>
                )
            }
            {/* <Link to={link} className="nav-link">{name}</Link> */}
        </li>
    )
  }
}

export default navItem;