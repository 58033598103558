import React from 'react'

import Button from 'app/components/ui/button/button'
import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'

const onExpand = (e, callback) => {
  e && e.stopPropagation()
  e && e.preventDefault()

  callback && callback(e)
}


export default function ExpandButton({ expanded, onClick, disabled }) {

  
  return (
    <Button 
      className='expand-button'
      type='image'
      onClick={(e) => onExpand(e, onClick)}
      disabled={disabled}
    >
      <ChevronImg className={`button-image ${!expanded ? 'button-image--rotate90n' : ''}`}></ChevronImg>
    </Button>
  )
}