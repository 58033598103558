/** @format */

import React from 'react';
import InfoWithChart from '../infoWithChart';
import { CIRCLE_COLOR } from '../typography';
import Doughnut from 'app/components/ui/charts/Doughnut/Doughnut';

import styles from './licenses.module.scss';
import typographyStyles from '../typography/typography.module.scss';

export default function Licenses({ single, multiple }) {
  return (
    <InfoWithChart
      className="licenses"
      headerText="Лицензии"
      dataRows={[
        {
          title: 'Однопользовательские',
          data: single,
          // reference: '',
          circle: { color: CIRCLE_COLOR.primary },
        },
        {
          title: 'Многопользовательские',
          data: multiple,
          // reference: '',
          circle: { color: CIRCLE_COLOR.secondary },
        },
      ]}
    >
      <Doughnut
        vm={[
          {
            r: 44,
            width: 8,
            className: styles.subchart,
            invalidChartClassName: styles.arcInvalid,
            gap: 0.03,
            items: [
              { text: `Однопользовательские: ${single}`, value: single, className: styles.arcSingleUser },
              { text: `Многопользовательские: ${multiple}`, value: multiple, className: styles.arcMultiUser },
            ],
          },
        ]}
        width={96}
        height={96}
      >
        <text x="48" y="48" textAnchor="middle" dominantBaseline="middle" className={typographyStyles.accentText}>
          {single + multiple}
        </text>
      </Doughnut>
    </InfoWithChart>
  );
}
