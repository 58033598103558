import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import MainCard, { objectKey } from 'app/components/mainCard/cardComponent'
import { getDefaultWarningDialogProps } from 'app/components/dialog/helpers'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import { cardLines } from './cardLines'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import ServiceOkz from './serviceOkz'
import Tabs from 'app/components/ui/tabs/tabs'
import { useHistory, useParams } from 'react-router-dom'
import ManagePanel from "app/components/ui/managePanel/managePanel";
import BusinessEventPanel from "./businessEventPanel/organizationPanel";

export const organizationData = (data) => {
  const { 
    name,
    shortName,
    ogrn,
    inn,
    kpp,
    code,
    description
  } = data || {}

  return { 
    name,
    shortName,
    ogrn,
    inn,
    kpp,
    code,
    description,
    okz: false,
  }
}

export const cardConfig = ({
    updateOrganizationCard,
    initOrganizationCard,
  }) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, organizationData),
  resource: RESOURCES.organizationOki,
  updateAction: updateOrganizationCard,
  cardInitAction: initOrganizationCard,
  getSignatureData: organizationData,
  getDeleteMessage: data => `Вы уверены, что хотите удалить организацию ${data.name}?`,
  pageBreadcrumbTitle: action => {
    const { payload } = action
    const { shortName, name } = payload || {}    
    return `${shortName || name || ''}`
  },
  getServerWarning: getDefaultWarningDialogProps,
})

const OkiCard = (props) => {
  const { 
    updateOrganizationCard,
    initOrganizationCard,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    commonDataForm,
    cardInProgressAction
  } = props

  const { shortName, name, serviceOkz } = commonDataForm || {}
  const { id } = useParams();
  const [ title, setTitle ] = useState( shortName || name );
  const history = useHistory();
  
  useEffect(() => {
    title && breadcrumbsPushAction(history.location.pathname,`${title || ''}`)
    return () => {
      title && breadcrumbsPopAction()
    }
  }, [title])

  useEffect(() => {
    setTitle(shortName || name)
  }, [shortName, name])

  return (
    <section className='main-card organization-card'>
      <BreadcrumbsHeader />
      <Tabs
        activeTab={'Информация'} 
      >
      <section tabTitle={'Информация'} className="main-card__data">
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты'}
          initialState={true}
        >
          <MainCard
            {...props}
            itemId={id}
            key={id}
            clearBeforeUnmount={false}
            config={cardConfig({
              updateOrganizationCard,
              initOrganizationCard,
            })}
          > 
            <ManagePanel>
              <BusinessEventPanel
                organizationId={id}
                onInProgress={cardInProgressAction}
              />
            </ManagePanel>
          </MainCard>
          <ServiceOkz
            id={id}
            list={serviceOkz}
            rigths={RESOURCES.organization}
          />
        </ExpandingBlock>
      </section>
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card, profile: state.profile}),
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(OkiCard)