import React from "react";
import { getClassName } from "app/core/utility/jsx";
import styles from "./selfServiceStatus.module.scss";
import Autocomplete, { renderInputValue, renderItem, Services } from "app/components/ui/autocomplete/autocomplete";
import FilterDash from "../filterDash/FilterDash";

export default function SelfServiceStatus({
  className,
  onChange,
  value,
  serviceType,
  statusTitle = "Статус самообслуживания",
  skziTitle = 'СКЗИ',
  noHint
}) {
  const { status, skziVersionId } = value || {};

  const _onChangeSkzi = (val) => {
    onChange({
      ...value,
      skziVersionId: val,
    });
  };

  const _onChangeStatus = (val) => {
    const actualValue = val
      ? {
          ...value,
          status: val,
        }
      : null;

    onChange(actualValue);
  };

  return (
    <div className={getClassName([styles.selfServiceStatus, className || ""])}>
      <Autocomplete
        serviceType={serviceType}
        value={status}
        onSelect={_onChangeStatus}
        label={statusTitle}
        noHint={noHint}
      />
      <FilterDash/>
      <Autocomplete
        serviceType={Services.skziVersion}
        renderItem={renderItem.skziVersion}
        renderInputValue={renderInputValue.skziVersion}
        value={skziVersionId}
        onSelect={_onChangeSkzi}
        label={skziTitle}
        disabled={!status}
        noHint={noHint}
      />
    </div>
  );
}