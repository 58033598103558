/** @format */

import { useJournal } from 'app/components/journals/useJournal';
import PageHeader from 'app/components/PageHeader/pageHeader';
import { sortDirection } from 'app/components/ui/constants';
import { Image } from 'app/components/ui/Img';
import MainListContainer, {
  FilterWithTableContainer,
  MainListLayoutType,
} from 'app/components/ui/MainListContainer/MainListContainer';
import Overlay from 'app/components/ui/overlay';
import Filter from 'app/components/filter';
import { Table } from 'app/components/list/Table/Table';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import fieldsConfig from './tableSchema';
import filtersConfig from './filtersConfig';

import styles from './Hosts.module.scss';

const Hosts = props => {
  const {
    baseTableLogic,
    filterLogic,
    sortingLogic,
    paginationLogic,

    inProgress,
    profile,
    setGlobalOrg,
    globalOrganization,
  } = useJournal({
    defaultSort: {
      column: 'name',
      direction: sortDirection.desc,
    },
    serviceName: 'hostsService',
  });

  return (
    <MainListContainer layoutType={MainListLayoutType.filterAndTable}>
      {inProgress && <Overlay />}
      <PageHeader
        title="Администрирование: Агентская сеть"
        Img={Image.AccessManagement}
      />
      <FilterWithTableContainer>
        <Filter
          {...{
            ...filterLogic,
            profile,
            inProgress,
            filtersConfig,
            compactClassName: styles.filter,
            setGlobalOrg,
            globalOrganization,
          }}
        />
        <Table
          {...{
            ...baseTableLogic,
            ...sortingLogic,
            inProgress,
            fieldsConfig,
            headerInfo: true,
            onItemClick: item => `/accessManagement/hosts/card/${item.id}`,
          }}
        />
      </FilterWithTableContainer>
      <Pagination {...paginationLogic} />
    </MainListContainer>
  );
};

export default Hosts;
