import Group from 'app/components/ui/managePanel/group';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { EventCode, EndpointType, ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import { Image } from 'app/components/ui/Img';
import JournalDownload from 'app/components/skzi/EskziList/JournalDownload';
import Complectation from 'app/components/list/TopPanel/Complectation';
import {Delete} from "app/components/list/TopPanel/Delete";
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';
import { formatDate } from 'app/core/utility/date';
import DestructionFormBody from '../../DestructionFormBody';
import { Type as MassImportType } from 'app/components/MassImports/enums';
import MassImportButton from 'app/components/MassImports/MassImportButton';
import ExportToExcelButton from 'app/components/exportToExcelButton';

const okiDeliveryOkData = {
  transmittalLetterDate: formatDate(new Date(), 'yyyy-mm-dd')
}

export const allHasChangeUser = (items) => items.length && items.every(item => {
  const { availableActions = [] } = item
  return availableActions.some(i => i.code === 'ChangeUser')
})

export default function BusinessEventPanel(props) {
  const { 
    globalOrgId,
    items,
    afterAction,
    filterForRequest,
    sorting,
    fieldsVisible,
    setSelectedAction,
    massImportRights,
    onInProgress
  } = props

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service('skziOrganizationService', 'deleteRange', items.map(i => i.id));
    onInProgress(false);
    return response;
  };

  return (
    <>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturn]}
          tooltip='Вернуть'
          endpointType={EndpointType.skziOrganization}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.okiDeliveryOk}
          tooltip='Получить экземпляр'
          endpointType={EndpointType.skziOrganization}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.Delivery}
          needForm={false}
          defaultSignData={okiDeliveryOkData}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.sendingOk}
          tooltip='Подтвердить получение'
          endpointType={EndpointType.skziOrganization}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.returnOk}
          tooltip='Подтвердить возврат'
          endpointType={EndpointType.skziOrganization}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        {!allHasChangeUser(items) ? (
          <BusinessEventTrigger
            items={items}
            eventCode={EventCode.okiDelivery}
            tooltip='Выдать'
            endpointType={EndpointType.skziOrganization}
            entityType={ENTITY_TYPE.eskzi}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        ) : (
          <BusinessEventTrigger
            items={items}
            eventCode={EventCode.changeUser}
            tooltip='Выдать другому пользователю'
            endpointType={EndpointType.skziOrganization}
            entityType={ENTITY_TYPE.eskzi}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        )}
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.withdrawal}
          tooltip='Изъять'
          endpointType={EndpointType.skziOrganization}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.SoftReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.installation}
          tooltip='Установить'
          endpointType={EndpointType.skziOrganization}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.DownloadCircle}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.destruction}
          tooltip='Уничтожение'
          endpointType={EndpointType.skziOrganization}
          formBody={DestructionFormBody}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterAction}
          Img={Image.Extermination}
          formAlert={'При уничтожении объектов будут уничтожены все связанные элементы.'}
        />
      </Group>
      <Group>
        <Complectation            
          titul='Комплектация'
          items = { items }  
        />    
      </Group>
      <Group>
        <JournalDownload
          items ={items}
        />
      </Group>
      <Group>
        { massImportRights.IMPORT && <MassImportButton type={MassImportType.OKI_KEY_ESKZI} tooltip="Загрузка экземпляров СКЗИ из файла" /> }
        <ExportToExcelButton 
          id={globalOrgId}
          listFilterForRequest={filterForRequest}
          sorting={sorting}
          listFieldsVisibility={fieldsVisible}
          selected={items.map(i=>i.id)}
          serviceName='skziOrganizationService'
          onStart={() => onInProgress(true)}
          onFinish={() => onInProgress(false)}
        />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction = {afterAction}
          setSelectedAction = {setSelectedAction}
          dialog ={data => {
              const arr = ['выбранный экземпляр', 'выбранные экземпляры', 'выбранные экземпляры']
              return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)} СКЗИ?`
          }}
        />
      </Group>
    </>
  )
}