/** @format */

import React, { useState } from 'react';

import withSignatureHOC from 'app/components/HOC/withSignatureHOC';
import { api } from 'app/services/service';
import { formatDate } from 'app/core/utility/date';

import CloseDialog from './closeDialog';

const CloseDialogWithSignature = withSignatureHOC(CloseDialog);

export default function CloseUA({ currentUAId, onDone, onCancel, onInProgress, onItemChanged }) {
  const [closeUADate, setCloseUADate] = useState(new Date());

  const closeUA = async ({ data, signature = {} }) => {
    const { signers, organizationId, eskzis, keyDocuments, keyCarriers } = data;
    const { data: destructionCertificateSignableText, signedData: destructionCertificateSignedText } = signature;
    onInProgress(true);
    const result = await api('userAccount/closeUserAccount', {
      id: currentUAId,
      accountCloseDate: formatDate(closeUADate, 'yyyy-mm-dd'),
      organizationId,
      destructionCertificateSigners: signers,
      eskzisToDestroy: eskzis,
      keyDocumentsToDestroy: keyDocuments,
      keyCarriersToDestroy: keyCarriers,
      destructionCertificateSignableText,
      destructionCertificateSignedText,
    });
    const { data: item, isError } = result;
    !isError && onItemChanged(item);
    onInProgress(false);
    return result;
  };

  return (
    <CloseDialogWithSignature
      closeUADate={closeUADate}
      setCloseUADate={setCloseUADate}
      signType="EskziUserClosing"
      signHeader="Закрытие лицевого счета"
      eskziUser={currentUAId}
      actionCallback={closeUA}
      afterAction={onDone}
      onCancelSignHandler={onCancel}
      onCancel={onCancel}
    />
  );
}
