/** @format */
import { Border, ManagePanel, Group } from 'app/components/ui/managePanel';
import ApproveRequest from '../approve/approveRequest';
import RejectRequest from '../reject/rejectRequest';

export default function ManagementPanel({ rights, afterAction, requestData }) {
  return (
    <ManagePanel borders={Border.All}>
      {rights.UPDATE && (
        <Group>
          <ApproveRequest afterActionCallback={afterAction} items={[requestData]} withText />
        </Group>
      )}
      {rights.UPDATE && (
        <Group>
          <RejectRequest afterActionCallback={afterAction} items={[requestData]} withText />
        </Group>
      )}
    </ManagePanel>
  );
}

