import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  ORGANIZATIONCARDGET, 
  ORGANIZATIONCARDUPDATEFORMDATA ,
  ORGANIZATIONCARDINPROGRESS ,
  ORGANIZATIONCARDRESETACTION ,
  ORGANIZATIONCARDUPDATE ,
  ORGANIZATIONCARDDELETE ,
  ORGANIZATIONCARDONDELETE ,
  ORGANIZATIONCARDTABCLICK,
  ORGANIZATIONCARDONCANCEL,
  ERRORPAGEERROR
  } from 'redux/actions/actionTypes'
    
  import { dialogType } from 'app/components/dialog/confirmDialog/'
      
  const defaultState = {
    tabs: { primaryActive: 'Общие данные', secondaryActive: '' },
    commonDataForm : {
      name: '',
      shortName: '',
      ogrn: '',
      inn: '',
      kpp: '',
      code: '',
      okz: false,
      description: ''
    },
    organizationData: {},
    confirmDeleteObject: null,
    deletedInfoObject: null,
    inProgress: false
  }
    
  export default (state = defaultState, action) => {
    const { payload } = action
    const { organizationData, commonDataForm, tabs } = state
      
    switch (action.type) {
      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case ORGANIZATIONCARDGET:
        return {
            ...state,
            commonDataForm: {
              ...payload, 
            },
            organizationData: {
              ...payload,
            },
            inProgress: false
        }
  
      case ORGANIZATIONCARDUPDATEFORMDATA: 
        const { key, value } = payload
  
        return {
          ...state,
          commonDataForm: {...commonDataForm, [key]: value }
        }
  
      case ORGANIZATIONCARDTABCLICK:
        return {
          ...state,
          tabs: {...tabs,...payload}
        }
  
      case ORGANIZATIONCARDUPDATE:
  
        return {
            ...state,
            commonDataForm: {
              ...payload, 
            },
            organizationData: {
              ...payload,
            },
            inProgress: false
        }
  
      case CLEARERROR:
      case ORGANIZATIONCARDRESETACTION:
        return {
          ...defaultState,
          tabs: {...tabs},
          organizationData,
          commonDataForm
        }
        
      case FIELDSERROR:
      case ERROR: 
        return {
          ...state,
          inProgress: false
        }
  
      case ORGANIZATIONCARDINPROGRESS:
        return {
          ...state,
          inProgress: true
        }
  
      case ORGANIZATIONCARDDELETE:
          return {
            ...state,
            deletedInfoObject: {
              type: dialogType.success,
              title: `Организация успешно удалена`
            },
            inProgress: false
        }

      case ORGANIZATIONCARDONCANCEL:
        return {
          ...state,
          commonDataForm: {...organizationData}
        }

      case ORGANIZATIONCARDONDELETE:
        const { name } = payload
  
        return {
          ...state,
          confirmDeleteObject: {
            type: dialogType.confirm,
            title: `Вы уверены, что хотите уничтожить организацию «${name}»?`
          },
          inProgress: false
        }
  
      default: 
        return state
    }
  }