import {
  CARDINIT,
  CARDSEREVERUPDATE,
  CARDAFTERCREATE,
  CARD_SET_WARNING
} from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'

import { formatKeyDoc } from 'app/components/list/Table/TableBody'
import { formatFIO } from 'app/core/utility/common'

const reselect = data => {
  const { 
    signers = [], 
    eskzis = [], 
    keyCarriers = [], 
    keyDocuments = [],
    eskzi = []
  } = data || {}

  return {
      ...data, 
      signers: signers.map(item => ({... item, ...item.user})),
      skziAmount: eskzis.length + '',
      kcAmount: keyCarriers.length + '',
      kdAmount: keyDocuments.length + '',
      keyCarriers : keyCarriers, 
      keyDocuments : keyDocuments,
      eskzi: eskzi
    }
}

export const initCreate = (itemId, props) => dispatch => {
  const { 
    profile
  } = props
  const { userInfo } = profile
  const { organization } = userInfo || {}

  return dispatch({
    type: CARDINIT,
    payload: {
      id: 'Черновик',
      author: {user: userInfo, signature: null},
      signers: [userInfo],
      organization: organization || null,
      kdAmount: '0',
      kcAmount: '0',
      skziAmount: '0',
      createDate: new Date(),
      updateDate: new Date()
    }
  })
}


export const setWarningAction = (warningObject) => dispatch => {

  dispatch({
    type: CARD_SET_WARNING,
    payload: warningObject
  })
}

export const register = data => dispatch => {

  return service('destructionCertificateService', 'create', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
    })
}

export const update = data => dispatch => {

  return service('destructionCertificateService', 'update', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, CARDSEREVERUPDATE, reselect(data), isError, errors)
    })
}

export const signAction = data => dispatch => {

  return service('destructionCertificateService', 'sign', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, CARDSEREVERUPDATE, reselect(data), isError, errors)
    })
}

export const initCard = (itemId) => dispatch => {

  return service('destructionCertificateService', 'getData', itemId)
    .then(response => {
      const { data, errors, isError } = response;

      return dispatchLogic(
        dispatch, 
        CARDINIT, 
        reselect(data), 
        isError, 
        errors)
    })
}