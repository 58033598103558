/** @format */

import { useState } from 'react';

export const useMouse = () => {
  const [clientCoords, setClientCoords] = useState({ x: 0, y: 0 });
  const [targetCoords, setTargetCoords] = useState({ x: 0, y: 0 });
  const [targetBoundingRect, setTargetBoundingRect] = useState();
  const [event, setEvent] = useState();

  const onMouseEvent = e => {
    const nativeEvent = e.nativeEvent;
    setEvent(nativeEvent);
    setClientCoords({ x: nativeEvent.clientX, y: nativeEvent.clientY });

    const {
      left: targetLeft,
      top: targetTop,
      width: targetWidth,
      height: targetHeight,
    } = nativeEvent.target.getBoundingClientRect();

    setTargetBoundingRect({ left: targetLeft, top: targetTop, width: targetWidth, height: targetHeight });

    setTargetCoords({
      x: nativeEvent.clientX - targetLeft,
      y: nativeEvent.clientY - targetTop,
    });
  };

  return { clientCoords, targetCoords, targetBoundingRect, event, onMouseEvent };
};
