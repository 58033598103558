import { downloadFileGET, uploadFile, del, post } from '../serverRequest/request'

export const getFilesExtensions = (files) => {
  return files.map(item => {
    const fileExtension = item.blob.name.split('.');
    return fileExtension[fileExtension.length - 1];
  });
};

export const getFilesSizes = (files) => files.map(item => item.blob.size);

export const checkFilesExtension = (acceptExtension, userExtension) => {
  return userExtension.every((item) => acceptExtension.includes(item));
};

export const humanTextFileSize = (fileSizeInBytes) => {
  var i = -1;
  var byteUnits = ['Кб', 'Мб', 'Гб', 'Тб', 'Пб'];

  do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
  } while (fileSizeInBytes > 1024);

  return `${Math.max(fileSizeInBytes.toFixed(1), 0.1)} ${byteUnits[i]}`;
}


export const downloadFile = ({blob, fileName}) => {
  return new Promise((res, rej) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    // we need to append the element to the dom -> otherwise it will not work in firefox
    document.body.appendChild(a)
    a.click()
    a.remove()
    res()
  })
}

// to do
// refactor - return promise
export const downloadFileFormHandler = (service, data, onError, onCancel) => {
  service(data)
    .then(({blob, fileName: fn, ...rest }) => {
      const name = fn || `devFileName.xls`
      const { errors } = rest

      if (errors && errors.length) {
        onError && onError(rest)
        return Promise.resolve(rest)
      } else {
        return downloadFile({blob, fileName: name})
      }
    })
    .then(data => { 
      if (!data || !data.isError) {
        onCancel && onCancel() 
      }
    })
}


export const fileListAPI = (settings, urlPart, fileList) =>  {
  const { objectTypeId = '' } = settings || {}
  const url = urlPart.replace(/\/+$/, "");

  return {
    serverFileList: () => {
      return [
        ...fileList
      ]
    },
  
    getFile: async (id) => {
      const { blob, fileName } = await downloadFileGET(`${url}/${id}`)

      await downloadFile({blob, fileName})
    },
  
    deleteFile: async (guid) => {
      return await del(`${url}/${guid}`)
    },
  
    uploadFile: async (file, onProgress, setCancellCallback, data, hashSum) => {
      const formData = new FormData();
      const fileName = file.name || '';
      const actualUrl = `${objectTypeId ? `${url}/${objectTypeId}` : url}${hashSum || hashSum === '' ? `?userHashSum=${hashSum}` : ''}`
      if (data) {
        for (const element in data) {
          if (data.hasOwnProperty(element)) {
            formData.append(element, data[element]);
          }
        }
      }
      formData.append('file', file, fileName);
      return await uploadFile(actualUrl, formData, onProgress, setCancellCallback)
    },

    uploadFiles: async (files, onProgress, setCancellCallback) => {
      const formData = new FormData();
      files.forEach(file => {
        formData.append('files', file.blob, file.blob.name);
      }); 
      return await uploadFile(url, formData, onProgress, setCancellCallback)
    },
    
    updateFileMeta: async (guid, data) => {
      return await post(`${url}/${guid}/update`, data);
    }
  }
}