import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'

import withToggle from 'app/components/HOC/ToggleHOC'

class ExpandingBlock extends Component {
  static get propTypes() {
    return {
      renderHeader: PropTypes.func,
      renderContent: PropTypes.func,
      disabled: PropTypes.bool,
      canToggle: PropTypes.bool
    };
  }

  render() {
    const { 
      className, 
      renderHeader, 
      disabled, 
      toggleStatus, 
      toggle,
      children,
     } = this.props
    
    return (
      <div className={`expanding-block ${className || ''} expanding-block--${toggleStatus ? 'opened' : 'closed'} ${disabled ? 'expanding-block--disabled' : ''}`}>
        <div className={`expanding-block__head head head--${toggleStatus ? 'opened' : 'closed'}`} onClick={toggle}>
          <ChevronImg
            className='head__toggle-state'
          />
          <div className="head__component">
            {renderHeader(toggleStatus)}
          </div>
        </div>
        <div className={`expanding-block__content`}>{children}</div>
      </div>
    )
  }
}

export default withToggle(ExpandingBlock) 