import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import MainCard, { cardMode, objectKey } from 'app/components/mainCard/cardComponent'
import { getDefaultWarningDialogProps } from 'app/components/dialog/helpers'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import { cardLines } from './cardLines'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import Tabs from 'app/components/ui/tabs/tabs'
import { organizationData } from './card'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'
import { useHistory } from 'react-router-dom'


export const cardConfig = ({ 
    history,
    createOrganizationAction,
    initOrganizationCreate,
    breadcrumbsPopAction,
  }) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, organizationData),
  resource: RESOURCES.organization,
  updateAction: createOrganizationAction,
  cardInitAction: initOrganizationCreate,
  getSignatureData: organizationData,
  onCancel: () => {history.push('/catalog/organization')},
  afterUpdate: (requestResult) => {
    const { payload, type } = requestResult || {}
    const { id } = payload || {}

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction()  
      history.push(`/catalog/organization/card/${id}`)
    }
  },
  pageBreadcrumbTitle: () => null,
  getServerWarning: getDefaultWarningDialogProps,
})

const OkzCard = (props) => {
  const { 
    createOrganizationAction,
    initOrganizationCreate,
    breadcrumbsPopAction,
  } = props

  const history = useHistory();

  return (
    <section className='main-card organization-card'>
      <BreadcrumbsHeader />
      <Tabs
        activeTab={'Информация'} 
      >
      <section tabTitle={'Информация'} className="main-card__data">
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты'}
          initialState={true}
        >
          <MainCard
            {...props}
            cardMode={cardMode.create}
            editOnOpen={true}
            config={cardConfig({
              history,
              createOrganizationAction,
              initOrganizationCreate,
              breadcrumbsPopAction,
            })}
          > 
          </MainCard>
        </ExpandingBlock>
      </section>
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card, profile: state.profile}),
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(OkzCard)