import React from 'react'

import { sortDirection } from 'app/components/ui/constants'
import { formatDate } from 'app/core/utility/date'

export const defaultSettings = {
  columns: [
    {
      title: 'Наименование',
      alias: 'name',
      useSorting: true,
      width: 535
    },
    {
      title: 'Дата добавления',
      alias: 'createDate',
      useSorting: true,
      width: 300,
      format: item => {
        const { createDate } = item
        
        return formatDate(createDate, 'dd.mm.yyyy')
      }
    },
  ],
  sort: { 
    column: 'name',
    direction: sortDirection.asc
  }
} 