import React from 'react';
import {
  organizationName
} from 'app/components/list/Table/TableBody'
import { formatFIOInitials } from 'app/core/utility/common';
import { OKI_CARD_TYPE, ORG_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatDate } from 'app/core/utility/date';
import imgMap from 'app/components/ui/Img/imgMap';
import { FilterControlType } from 'app/components/filter/FilterElement';

const config = {
  fieldsConfig: [
    {
      title: '№',
      alias: 'number',
      format: item => item.number,
      visibleOnStart: true,
      visibleAlways: true,
      useSorting: true,
      // width : 200
    },
    {
      title: 'Дата создания',
      alias: 'createDate',
      format: item => formatDate(item.createDate, 'dd.mm.yyyy'),
      visibleOnStart: true,
      useSorting: true,
      width: 200,
    },
    {
      title: 'Автор',
      alias: 'author',
      format: item => {
        const { user } = item.author || {};
        return formatFIOInitials(user);
      },
      visibleOnStart: true,
      useSorting: true,
      width: 250,
    },
    {
      title: 'Организация',
      alias: 'organization',
      format: item => {
        const name = organizationName(item.organization);
        const { id, okz } = item.organization || {};
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE;
        return name && id ? (
          <ListLink text={name} targetType={targetType} id={id} />
        ) : (
          name || ''
        );
      },
      visibleOnStart: true,
      useSorting: true,
      width: 300,
    },
    {
      title: 'Ключевые документы',
      iconTitle: imgMap.KeyDocument,
      alias: 'keyDocumentsCount',
      format: item => (item.keyDocuments || []).length,
      visibleOnStart: true,
      useSorting: true,
      width: 170,
    },
    {
      title: 'Ключевые носители',
      iconTitle: imgMap.HardwareSKZI,
      alias: 'keyCarriersCount',
      format: item => (item.keyCarriers || []).length,
      visibleOnStart: true,
      useSorting: true,
      width: 170,
    },
    {
      title: 'Экземпляры СКЗИ',
      iconTitle: imgMap.OkzReport,
      alias: 'eskzisCount',
      format: item => (item.eskzis || []).length,
      visibleOnStart: true,
      useSorting: true,
      width: 170,
    },
    {
      title: 'Дата изменения',
      alias: 'updateDate',
      format: item => formatDate(item.updateDate, 'dd.mm.yyyy'),
      visibleOnStart: true,
      useSorting: true,
      width: 200,
    },
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'number', 
      label : '№',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.autocompletePicker, 
      alias : 'authorId', 
      label : 'Автор', 
      serviceType : 'user',
      extended : false,
      order : 3
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'signStatus', 
      label : 'Статус', 
      serviceType : 'signStatus',
      extended : false,
      order : 4
    },
    {
      type : FilterControlType.signerWithSignState, 
      alias : 'signerId', 
      label : 'Участник комиссии',
      serviceType : 'signer',
      extended : true,
      order : 6,
      dependencies: [{
        alias: 'globalOrgId',
        targetProps: {
          notStandardService: (depVal, initVal) => ({
            serviceName: 'signerService',
            serviceMethod: 'autocomplete',
            data: { organizationId: depVal },
          }),
          notStandardPickerService: (depVal, initVal) => ({
            serviceName: 'signerService',
            serviceMethod: 'picker',
            data: { organizationId: depVal },
          }),
        },
      }],
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'createDate', 
      label : 'Дата',
      extended : true,
      order : 7,
    },
  ]
};

export default config