import { 
  ERROR, 
  WARNING,
  FIELDSERROR, 
  ERRORLIST,
  CLEARERROR,
  ERRORPAGEERROR,
  AUTHORIZEERROR,
  ERRORPAGECLEARERROR,
  SKZIINSTANCERESETACTION,
  ESKZICARDSERVICERESETACTION,
  AUTHORIZE,
  ESKZICARDADDLICENSES,
  ESKZICARDDELETELICENSES,
  SIGNATUREREADYTOSIGN,
} from '../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'

export const errorTypes = {
  error: 'error',
  warning: 'warning'
}

export const defaultState = {
  errorPageError: null,
  errorObject: null,
  warningObject: null,
  errorByFields: [],
  fieldsError: {},
  errors: []
}

export default (state = defaultState, action) => {
  const { payload, errors }  = action

  switch (action.type) {
      case ERROR:
        return {
            ...state,
            errors: errors,
            errorObject: {
              error: errors[0],
              title: errors[0].errorMessage,
              type: dialogType.warning,
              realType: errors[0].isError ? errorTypes.error : errorTypes.warning
            },
          }
      case WARNING:    
        return {
            ...state,
            errors: errors,
            warningObject: {
              error: payload,
              title: payload.errorMessage,
              type: dialogType.confirm,
            },
          }

      case FIELDSERROR:
        const fieldsError = errors.reduce((acc, item) => {
          const { field, fieldset, errorMessage } = item
          if(!field && fieldset){
            return { ...acc, [fieldset]: {
              ...item,
                errorValues: item['errorValues'].map(element=> element.reduce((accum, itemValues) => {
                  const {field} = itemValues
                  return { ...accum, [field]: itemValues }
                }, {}))
              }}
          }
          return errorMessage ? { ...acc, [field]: item } : acc
        }, {})

        const itemsErrors = errors.filter(i => i.field && i.field.split('[').length === 2).map(err => ({ errorMessage: err.errorMessage, key: err.field.split('[')[1].split(']')[0] }));

        return {
          ...state,
          errorByFields: errors,
          fieldsError: fieldsError,
          errorByKeys: itemsErrors
        }

      case ERRORLIST:
        if (payload && payload[0]) {
          const { field } = payload[0]
          if (field && field.includes('JournalObjects[')) {
            const itemsErrorsData = payload.filter(i => i.field.split('[').length === 2).map(err => ({ errorMessage: err.errorMessage, key: err.field.split('[')[1].split(']')[0] }));
            return {
              ...state,
              errorByKeys: itemsErrorsData
            }
          } 
        } 
        
        return state

      case AUTHORIZEERROR:
      case ERRORPAGEERROR:
        const { detail } = payload
        const { response } = detail || {}

        const errorMessage = response.errors && response.errors.length ? response.errors[0].errorMessage : ''

        return {
          ...state,
          errorPageError: detail,
          lastErrorMessage: errorMessage
        }

      case AUTHORIZE:
      case SKZIINSTANCERESETACTION:
      case ESKZICARDSERVICERESETACTION:
      case CLEARERROR: 
      case ESKZICARDADDLICENSES:
      case ESKZICARDDELETELICENSES:
      case SIGNATUREREADYTOSIGN:
      case ERRORPAGECLEARERROR:
        const { lastErrorMessage } = state
        return {
          ...defaultState,
          lastErrorMessage
        };

      default: 
          return state
  }
}