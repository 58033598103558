import React from 'react'
import SkziVersion from './skziVersionItem'

export default (props) => {
  const { 
    profile, 
    items, 
    onCourceAssign,
    onExamRequest 
  } = props

  return (
    <div className='skzi-version-list'>
      {items.map(item => {
        const { id: versionId, skzi, id } = item
        const { id: skziId } = skzi || {}
        
        return <SkziVersion 
          {...props}
          {...item} 
          skziId={skziId}
          versionId={versionId}
          profile={profile}
          onCourceAssign={onCourceAssign}
          onExamRequest={onExamRequest}
          key={id}
        />
      })}
    </div>
  )
}
