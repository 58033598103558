import React from 'react'
import styles from './pageHeader.module.scss'
import { getClassName } from 'app/core/utility/jsx'

export default function PageHeader(props) {
  const { title, Img, className } = props

  return (
    <div className = {getClassName([className, styles.header])}>
      <Img className = {styles.headerImage} />
      {title}
    </div>
  )
}
