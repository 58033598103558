import { 
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  CLEARERROR,
  ADMITTANCECARDGET,
  ADMITTANCECARDUPDATE,
  ADMITTANCECARDUPDATEFORMDATA,
  ADMITTANCECARDINPROGRESS,
  ADMITTANCECARDRESETACTION,
  ADMITTANCECARDONSELECT,
  SIGNATUREREADYTOSIGN,
  } from '../../actions/actionTypes'
    
  const defaultState = {
    selected: {
      items: [],
      selectedHashMap: {}
    },
    commonDataForm : {
      name: '',
      issueDate: null,
      skziInfo: null,
      currentUser: '',
      signers: []
    },
    viewData: {
      name: '',
      issueDate: null,
      skziInfo: null,
      currentUser: '',
      signers: []
    },
    admittanceData: {},
    confirmObject: null,
    inProgress: false
  }
  
  export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { admittanceData, viewData, selected, currentlyDeletingFiles } = state
    const { content } = admittanceData
    const { signers, isSigned } = payload || {}

    switch (action.type) {
      case ADMITTANCECARDONSELECT: 
        return {
          ...state,
          selected: payload || defaultState.selected
        }

      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case ADMITTANCECARDUPDATE: 
      case ADMITTANCECARDGET:
        const { user } = payload || {}

        return {
          ...state,
          commonDataForm: { 
            ...payload,
            userId: user,
            signers: signers.map(item => item.user),
            status: isSigned ? 'Подписано' : 'На подписании'
          },
          viewData: { 
            ...payload, 
            userId: user,
            signers: signers.map(item => item.user),
            status: isSigned ? 'Подписано' : 'На подписании'
          },
          admittanceData: {...payload, userId: user},
          inProgress: false
        }

      case CLEARERROR:
        return {
          ...state,
          confirmObject: null,
          deleteTypeConfirm: null,
          inProgress: false
        }
      
      case SIGNATUREREADYTOSIGN:
      case FIELDSERROR:
      case ERROR:
        return {
          ...state,
          inProgress: false
        }

      case ADMITTANCECARDUPDATEFORMDATA:

        return {
          ...state,
          commonDataForm: {...payload},
        }

      case ADMITTANCECARDINPROGRESS:
        return {...state, inProgress: payload}
  
      case ADMITTANCECARDRESETACTION: 
        return { 
          ...defaultState,
          viewData: viewData,
          selected: selected,
          admittanceData: admittanceData,
          commonDataForm: viewData,
        }
  
      default: 
        return state
    }
  }