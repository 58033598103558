/** @format */

import ConfirmDialog from 'app/components/dialog/confirmDialog/';
import { dialogType } from 'app/components/dialog/confirmDialog/';

const DeleteModal = ({ logic }) => {
  const { items, confirm, onSubmit, onCancel } = logic;
  return (
    (confirm.type === dialogType.confirm || confirm.type === dialogType.warning) && (
      <ConfirmDialog {...confirm} onSubmit={() => onSubmit(items)} onCancel={onCancel} />
    )
  );
};

export default DeleteModal;