import { Component } from 'react'
import { connect } from 'react-redux'

import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import { cardLines } from './createCourseForm'
import Card from 'app/components/ui/card/cardMaterial'

import withAuth from 'app/components/HOC/AuthHOC'
import breadcrumbsHOC from 'app/components/HOC/BreadcrumbsHOC'
import { capitalize, serverlikeErrors } from 'app/core/utility/common'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import * as createCourseActions from 'redux/actions/trainingCourses/createCourseAction'
import * as errorActions from 'redux/actions/common'
import { TRAININGCOURSESCREATE } from 'redux/actions/actionTypes'

class createCourse extends Component {

  componentWillUnmount () {
    this._onClearForm()
  }

  _onCancel = () => {
    const { history } = this.props
    this._onClearForm()

    history.push('/catalog/trainingCourses')
  }

  _onSubmit = async () => {
    const { createCourse: { formData }, registerCourse, createCourseInProgressAction, createCourseResetAction, breadcrumbsPopAction, history } = this.props
    const { isActive, name } = formData
    const active = typeof(isActive) === typeof(true)
                    ? isActive
                    : isActive.id === 'Yes'

    const requestBody = {
      id: 0,
      name: name,
      isActive: active
    }

    createCourseResetAction()
    createCourseInProgressAction(true)
    const createResult = await registerCourse(requestBody)
    const { payload, type } = createResult || {}
    const { id } = payload || {}

    if (type === TRAININGCOURSESCREATE) {
      breadcrumbsPopAction()  
      history.push(`/catalog/trainingCourses/card/${id}`)
    }
  }

  _updateFormData = (key, value) => {
    const { createCourseUpdateFormData, setErrorByKey, error: { errorByFields } } = this.props
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))

    createCourseUpdateFormData({key, value})
    setErrorByKey(filteredErrors)
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onClearForm = () => {
    const { courseCreateClearForm } = this.props

    courseCreateClearForm()
  }

  _renderData = () => {
    const { createCourse, profile, error } = this.props
    const { formData } = createCourse
    const { fieldsError } = error || {}

    return (
      <section className='course-common-data'>
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты'}
          initialState={true}
        >
          <Card
            className='course-data-card'
            profile={profile}
            formLines={cardLines}
            editOnOpen={true}
            formData={formData}
            onValidationError={this._onValidation}
            errorByFields={fieldsError}
            onChange={this._updateFormData}
            onSubmit={this._onSubmit}
            onCancel={this._onCancel}
          />
        </ExpandingBlock>
        <ExpandingBlock
          className='course-files'
          renderHeader= {() => 'Вложения'}
          disabled={true}
        />
      </section>
    )
  }

  render() {
    const { createCourse } = this.props
    const { inProgress } = createCourse

    return (
      <section className='create-course'>
        {inProgress ? <Overlay /> : null}
        <BreadcrumbsHeader />
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...breadcrumbsActions,
    ...createCourseActions,
    ...errorActions
  })(withAuth(breadcrumbsHOC(createCourse)))