/** @format */

import React, { useEffect, useState } from 'react';

import Input from 'app/components/ui/Input';
import SimpleSearch from 'app/components/ui/search/SimpleSearch';
import { Table } from 'app/components/list/Table/Table';
import withInfiniteScrollHOC from 'app/components/HOC/InfiniteScrollHOC';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import useDebounce from 'app/hooks/useDebounce';
import useExemplarReplicationForm from './useExemplarReplicationForm';
import DatePicker from 'app/components/ui/date';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import { sortDirection } from 'app/components/ui/constants';
import Img, { Image } from 'app/components/ui/Img';

import styles from './exemplarReplication.module.scss';
import { api } from 'app/services/service';
import { sum } from 'app/core/utility/arrays';
import LicensePicker from '../licensePicker';

const TableWithInfiniteScroll = withInfiniteScrollHOC(Table);

export const getOrganizationDetails = value => {
  const { ogrn, inn, kpp } = value || {};
  const innString = inn ? `ИНН ${inn}` : '';
  const kppString = kpp ? `КПП ${kpp}` : '';
  const ogrnString = ogrn ? `ОГРН/ОГРНИП ${ogrn}` : '';

  return value ? [innString, kppString, ogrnString].join('; ') : '';
};

export const getOrganizationName = value => {
  const { name = '', shortName = '' } = value || {};
  const attrString = getOrganizationDetails(value);
  return value ? `${shortName || name} ${attrString}` : '';
};

export default function ExemplarReplicationForm(props) {
  const { eskziId, cardState, formData = {}, onChange, errorByFields = {} } = props;

  const { getError, getFormDataValue } = useExemplarReplicationForm({ formData, errorByFields });

  const [nameInnFilter, setNameInnFilter] = useState('');
  const debouncedNameInnFilter = useDebounce(nameInnFilter, 500);

  const { sortListAction, selectedItems, setSelectedAction, selectedObjects, setSelectedObjects, sorting } =
    useTableExtensions({
      defaultSort: {
        column: 'name',
        direction: sortDirection.asc,
      },
    });

  const onLoadOrgs = ({ page, pageSize }) =>
    api('organization/getOki', {
      eskziId,
      term: nameInnFilter,
      page,
      pageSize,
      direction: sorting.direction,
    });

  const [exemplarsAndLicensesToggleState, setExemplarsAndLicensesToggleState] = useState(true);
  const [organizationsToggleState, setOrganizationsToggleState] = useState(false);
  const [replicationItemsMap, setReplicationItemsMap] = useState({});

  const convertItemsMapToInputModel = itemsMap =>
    Object.entries(itemsMap).map(e => ({
      organizationId: +e[0],
      ...e[1],
      licenses: e[1].licenses.map(lic => lic.id),
    }));

  useEffect(() => {
    const itemsMap = Object.fromEntries(
      selectedItems.map(id => [id, replicationItemsMap[id] || { copiesNumber: 0, licenses: [] }])
    );
    setReplicationItemsMap(itemsMap);
    const inputModel = convertItemsMapToInputModel(itemsMap);
    onChange('replicationItems', inputModel);
  }, [selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateReplicationItem = (id, field, value) => {
    const itemsMap = Object.fromEntries(
      selectedItems.map(curId => [
        curId,
        +curId === +id ? { ...replicationItemsMap[id], [field]: value } : replicationItemsMap[curId],
      ])
    );
    setReplicationItemsMap(itemsMap);
    const inputModel = convertItemsMapToInputModel(itemsMap);
    onChange('replicationItems', inputModel);
  };

  const { ReplicationItems: replicationItemsError } = errorByFields;
  const hasCopiesError = item => replicationItemsError && replicationItemsError.detail?.includes(item.id);

  const orgTableSchema = [
    {
      title: 'Наименование организации',
      alias: 'name',
      format: item => (
        <div>
          <div>{item.shortName || item.name}</div>
          <div className={styles.orgDetails}>{getOrganizationDetails(item)}</div>
        </div>
      ),
      visibleOnStart: true,
      useSorting: true,
    },
    {
      title: 'Экземпляры',
      alias: 'copiesNumber',
      format: item => (
        <Input
          type="number"
          placeholder={''}
          value={(replicationItemsMap[item.id] || {}).copiesNumber}
          onChange={value => updateReplicationItem(item.id, 'copiesNumber', value ? +value : null)}
          absoluteInfo
          disabled={!replicationItemsMap[item.id]}
          slotAppend={hasCopiesError(item) && <Img img={Image.AlertTriangle} className={styles.alertImg} />}
        />
      ),
      visibleOnStart: true,
      width: 176,
    },
    {
      title: 'Лицензии (активации)',
      alias: 'licenses',
      format: item => {
        const selectedLicenses = Object.fromEntries(
          Object.entries(replicationItemsMap).map(e => [e[0], e[1].licenses])
        );

        const excluded = Object.keys(selectedLicenses).reduce((acc, cur) => {
          if (item.id !== +cur) {
            return [...acc, ...selectedLicenses[cur]];
          }
          return acc;
        }, []);

        return (
          <LicensePicker
            eskziId={eskziId}
            organizationId={item.id}
            onSelect={({ organizationId, items }) => updateReplicationItem(organizationId, 'licenses', items)}
            alreadySelected={selectedLicenses}
            excludeItems={excluded}
            disabled={!replicationItemsMap[item.id]}
          />
        );
      },
      visibleOnStart: true,
      width: 176,
    },
  ];

  const eskzisCount = sum(Object.values(replicationItemsMap), 0, v => +v.copiesNumber);
  const licenseCount = sum(Object.values(replicationItemsMap), 0, v => v.licenses.length);

  return (
    <div className={styles.exemplarReplicationForm}>
      <ExpandingBlock
        renderHeader={() => 'Экземпляры и лицензии'}
        initialState={exemplarsAndLicensesToggleState}
        onToggle={toggleState =>
          toggleState && (setOrganizationsToggleState(false) || setExemplarsAndLicensesToggleState(true))
        }
      >
        <div className={`${styles.blockContent}  ${styles.exemplarsAndLicenses} exemplar-replicate-form__content`}>
          <div className={styles.hint}>
            При создании и передаче экземпляров можно передать такое же или меньшее количество лицензий (при их
            наличии).
          </div>
          <Input
            id="etalonId"
            className={`card-field ${styles.cardFieldEtalonId}`}
            label="Эталон для распространения"
            disabled
            value={getFormDataValue('etalonId')}
          />
          <Input
            id="availableCount"
            className={`card-field ${styles.cardFieldAvailableCount}`}
            label="Доступно лицензий"
            disabled
            value={getFormDataValue('availableCount') || '0'}
          />
          <Input
            id="transmittalLetterNumber"
            className={`card-field ${styles.cardFieldTransmittalLetterNumber}`}
            label="№ сопроводительного документа"
            value={getFormDataValue('transmittalLetterNumber')}
            onChange={value => onChange('transmittalLetterNumber', value)}
            error={getError('transmittalLetterNumber')}
          />
          <DatePicker
            id="transmittalLetterDate"
            className={`card-field ${styles.cardFieldTransmittalLetterDate}`}
            label="Дата сопроводительного письма"
            value={getFormDataValue('transmittalLetterDate')}
            maxDate={new Date()}
            onChange={value => onChange('transmittalLetterDate', value)}
            error={getError('transmittalLetterDate')}
          />
        </div>
      </ExpandingBlock>
      <ExpandingBlock
        renderHeader={() =>
          `Организации: ${selectedItems.length} (экземпляров: ${eskzisCount}, лицензий: ${licenseCount})`
        }
        initialState={organizationsToggleState}
        className={`${styles.expandingBlock} ${styles.organizations}`}
        onToggle={toggleState =>
          toggleState && (setExemplarsAndLicensesToggleState(false) || setOrganizationsToggleState(true))
        }
      >
        <div className={`${styles.blockContent} ${styles.organizations} exemplar-replicate-form__content`}>
          {/* Фильтр по наименованию/ИНН */}
          <SimpleSearch
            className="search-name-inn"
            label="Наименование или ИНН"
            value={nameInnFilter}
            onChange={setNameInnFilter}
            noHint
          />
        </div>
        {/* Таблица организаций */}
        <TableWithInfiniteScroll
          {...{
            sorting,
            sortListAction,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedObjects,
          }}
          fieldsConfig={orgTableSchema}
          loadData={onLoadOrgs}
          itemsSelectable
          dependencies={[sorting, debouncedNameInnFilter]}
        />
      </ExpandingBlock>
    </div>
  );
}
