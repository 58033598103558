import React from 'react';

import UserAccountCardTab from '../UserAccountCardTab';
import UserAccountCardTabToolbar from '../UserAccountCardTabToolbar';

import toolbarConfig from './toolbarConfig';
import tableSchema from './tableSchema';

const Toolbar = UserAccountCardTabToolbar(toolbarConfig);

const SkziInstancesTab = ({ profile, userId, setInProgress, issueDisabled }) =>
  UserAccountCardTab({
    header: 'Экземпляры СКЗИ',
    Toolbar,
    tableSchema,
    tableServiceName: 'instanceService',
    setInProgress,
    issueDisabled,
    itemCardCallback: item =>
      `/${item.okz ? 'skziAccounting' : 'skziOrganization'}/EskziCard/${
        item.id
      }`,
    profile,
    userId,
  });

export default SkziInstancesTab;