/** @format */

import React from 'react';
import styles from './icon.module.scss';
import { getClassName } from 'app/core/utility/jsx';
import Img from 'app/components/ui/Img';
import Paper from 'app/components/ui/paper';

export default function IconWidget({ className, img, children, reference }) {
  return (
    <Paper className={className}>
      <a href={reference} className={getClassName(['dashboard-icon-info', styles.iconInfo])}>
        <Icon img={img}></Icon>
        {children}
      </a>
    </Paper>
  );
}

function Icon({ img }) {
  return (
    <div className={styles.icon}>
      <Img img={img} className={getClassName([styles.iconImage])} />
    </div>
  );
}
