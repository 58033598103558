import React, { Component } from 'react'
import { connect } from 'react-redux'


import Overlay from 'app/components/ui/overlay'
import ConfirmDialog,  { dialogType } from 'app/components/dialog/confirmDialog/'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import ButtonComponent from 'app/components/ui/button/button'

import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as DownloadToXlsImg } from 'assets/img/commonVer2/excel.svg'

import { formatDate } from 'app/core/utility/date'
import { stringPlural, arrayToIdHashMap } from 'app/core/utility/common'
import SignatureDialog, { isDigitalSignNeeded } from 'app/components/signature/SignatureDialog'
import AddAdmittanceDialog from './addAdmittanceDialog'
import AddTraining from './addTrainingDialog'
import { sortDirection } from 'app/components/ui/constants'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import withModal from 'app/components/HOC/ObsoleteModalHOC'

import { 
  USERSKZIADMITTANCEADDADMITANCE,
  USERSKZIADMITTANCEUPDATEADMITANCE
 } from 'redux/actions/actionTypes'

import * as errorAction from 'redux/actions/common'
import * as userSkziAdmittanceAction from 'redux/actions/userAccount/userSkziAdmittanceAction'
import * as signatureActions from 'redux/actions/common/signatureAction'
import withTooltip from 'app/components/HOC/TooltipHOC'
import { SelectType } from 'app/components/ui/picker/picker'
import TableWithLogic from 'app/components/list/Table/tableWithLogic'

const Button = withTooltip(ButtonComponent)
const ModalAddAdmittanceDialog = withModal(AddAdmittanceDialog)
const AddTrainingDialog = withModal(AddTraining)

class userSkziAccess extends Component {
  constructor(props){
    super(props)

    this.admittanceDialog = React.createRef()

    this.state = { 
      addAdmittanceDialog: null, 
      addTrainingDialog: null,
      changedAdm: {},
      changedTraining: {},
      admittanceColumns: [
        {
          title: 'Номер',
          alias: 'id',
          useSorting: true,
          width: 150
        },
        {
          title: 'Номер заключения',
          alias: 'name',
          useSorting: true,
        },
        {
          title: 'Дата выдачи',
          alias: 'issueDate',
          useSorting: true,
          width: 300,
          format: item => {
            const { issueDate } = item
            
            return formatDate(issueDate, 'dd.mm.yyyy')
          }
        },
        {
          title: 'СКЗИ',
          alias: 'skziInfo',
          useSorting: true,
          width: 400,
          format: item => {
            const { skziInfo } = item || {}
            const { skzi, version } = skziInfo || {}
            const { name: skziName = '' } = skzi || {}
            const { name: versionName = '' } = version || {}

            return  `${skziName} ${versionName}` 
          }
        },
        {
          title: 'Дата изменения',
          alias: 'changeDate',
          useSorting: true,
          width: 300,
          format: item => {
            const { changeDate } = item
            
            return formatDate(changeDate, 'dd.mm.yyyy')
          }
        },
      ],
      trainingColumns: [
        {
          title: 'СКЗИ',
          alias: 'skziVersion',
          useSorting: true,
        },
        {
          title: 'Дата добавления',
          alias: 'changeDate',
          useSorting: true,
          width: 300,
          format: item => {
            const { changeDate } = item
            
            return formatDate(changeDate, 'dd.mm.yyyy')
          }
        },
      ]
    }
  }

  componentWillUnmount () {
    const { 
      userSkziAdmittanceSelectAdmittanceAction,
      userSkziAdmittanceSelectTrainingAction
    } = this.props

    userSkziAdmittanceSelectTrainingAction()
    userSkziAdmittanceSelectAdmittanceAction()
  }

  _getAdmittanceLink = item => {
    const { profile } = this.props
    const { id } = item;

    const admittanceRights = getResourceRights(profile, RESOURCES.admittance)

    return admittanceRights.VIEW_CARD ? `/acts/admittance/card/${id}` : ''
  }
  
  _onAdmittanceSelect = data => {
    const { userSkziAdmittanceSelectAdmittanceAction } = this.props
    userSkziAdmittanceSelectAdmittanceAction({
      items: data,
      selectedHashMap: arrayToIdHashMap(data)
    })
  }

  _onTrainingSelect = data => {
    const { userSkziAdmittanceSelectTrainingAction } = this.props
    userSkziAdmittanceSelectTrainingAction({
      items: data,
      selectedHashMap: arrayToIdHashMap(data)
    })
  }

  _getTrainingLink = (item) => {
    const { profile } = this.props
    const { skziVersionId, skziId } = item;
    const versionRights = getResourceRights(profile, RESOURCES.version)

    return versionRights.VIEW_CARD ? `/skziRegistry/accounting/skzi/${skziId}/version/${skziVersionId}?tabName=${encodeURIComponent('Обучающие курсы')}` : ''
  }

  _registerWithoutSign = async (data) => {
    const { 
      userSkziAdmittanceAddAdmittance, 
      userSkziAdmittanceInProgressAction,
      userSkziAdmittanceUpdateAdmittance
    } = this.props
    const { admittanceId } = data
    const requestData = {
      admittanceId,
      data: this._getSignatureRequestData(data),
      signature: null
    }

    let addResult;
    userSkziAdmittanceInProgressAction(true)

    if (admittanceId) {
      addResult = await userSkziAdmittanceUpdateAdmittance(requestData)
    } else {
      addResult = await userSkziAdmittanceAddAdmittance(requestData)
    }
    
    this._finishAdmittanceAction(addResult)
  }

  _finishAdmittanceAction = (result) => {
    const { type, payload } = result
    const { id: admittanceId } = payload || {}
    const { current } = this.admittanceDialog

    if (type === USERSKZIADMITTANCEADDADMITANCE
        || type === USERSKZIADMITTANCEUPDATEADMITANCE) {
      
      this.setState({ 
        addAdmittanceDialog: {id: admittanceId}
      }, () => {
          current._onFilesUpload()
          this.setState({ addAdmittanceDialog: null, changedAdm:{added:[payload]}})
      })
    }
  }

  _onSignDialog = (data) => {
    const { 
      setRequestData, 
      generateText, 
      userSkziAdmittanceInProgressAction
    } = this.props

    const signTextRequest = {
      ...this._getSignatureRequestData(data),
      signatureRequestType: 'Admittance'
    }
  
    setRequestData({...data});
    userSkziAdmittanceInProgressAction(true)
    generateText(signTextRequest);
  }

  _onTrainingAdd = async (skziVersion) => {
    const { 
      userSkziAdmittanceAddTraining,
      userSkziAdmittanceInProgressAction
    } = this.props
    const { currentUserAccount } = this.props
    const { id: userId } = currentUserAccount
    const { version } = skziVersion
    const { id: skziVersionId } = version

    userSkziAdmittanceInProgressAction(true)
    const result = await userSkziAdmittanceAddTraining({ userId, skziVersionId })
    const { payload, errors } = result || {}
    this.setState({ addTrainingDialog: false, changedTraining: {added:errors && errors.length ? [] : [payload]} })
  }

  _onFilesAdd = () => {
    const { current } = this.admittanceDialog

    current._onFilesUpload()
    this.setState({ addAdmittanceDialog: null })
  }

  _onAdmittanceAdd = async (data) => {
    const { profile } = this.props

    if (isDigitalSignNeeded(profile, 'Admittance')) {
      this._onSignDialog(data)
    } else {
      this._registerWithoutSign(data)
    }
  }

  _getSignatureRequestData = (data) => {
    const { name, userId, issueDate, skziInfo, signers } = data
    const { version } = skziInfo || {}
    const { id: versionId } = version || {}
    const { id } = userId || {}
    
    return { 
      name, 
      userId: id,
      issueDate: formatDate(issueDate, 'yyyy-mm-dd'),
      skziVersionId: versionId,
      signers: signers.map(item => item.id)
    }
  }

  _onRunAdmittanceDelete = async () => {
    const { 
      userSkziAdmittance, 
      userSkziAdmittanceDeleteAdmittance,
      userSkziAdmittanceInProgressAction
    } = this.props
    const { selectedAdmittance } = userSkziAdmittance
    const { items } = selectedAdmittance

    this.setState({ deleteAdmittanceConfirm: null})
    userSkziAdmittanceInProgressAction(true)
    const result = await userSkziAdmittanceDeleteAdmittance(items)
    const { payload = [] } = result || {}
    this.setState({changedAdm: {deleted: payload}})
  }

  _onRunTrainingDelete = async () => {
    const { 
      userSkziAdmittance, 
      userSkziAdmittanceInProgressAction,
      userSkziAdmittanceDeleteTraining
    } = this.props
    const { selectedTraining } = userSkziAdmittance
    const { items } = selectedTraining

    this.setState({ deleteTrainingConfirm: null})
    userSkziAdmittanceInProgressAction(true)
    const result = await userSkziAdmittanceDeleteTraining(items)
    const { payload = [] } = result || {}
    this.setState({changedTraining: {deleted: payload}})
  }

  _afterSign = async () => {
    const { 
      signature,
      userSkziAdmittanceInProgressAction, 
      userSkziAdmittanceAddAdmittance,
    } = this.props
    const { textForSignature, signatureResult, requestData } = signature
    const { admittanceId } = requestData
    
    const signatureToServer = {
      admittanceId,
      data: this._getSignatureRequestData(requestData),
      signature: { data: textForSignature, signedData: signatureResult }
    }
    
    userSkziAdmittanceInProgressAction(true)
    const result = await userSkziAdmittanceAddAdmittance(signatureToServer)
    this._finishAdmittanceAction(result)
  }

  _onClearError = () => {
    const { clearErrorAction } = this.props
    
    clearErrorAction()
  }

  _onAdmittanceDeleteConfirm = () => {
    const { userSkziAdmittance } = this.props
    const { selectedAdmittance } = userSkziAdmittance
    const { items } = selectedAdmittance

    this.setState({
      deleteAdmittanceConfirm: {
        type: dialogType.confirm,
        title: `Вы уверены, что хотите удалить ${stringPlural(items.length, ['допуск', 'допуска', 'допусков'] )}: «${items.map(item => item.name || item.id).join(', ')}»?`
      }
    })
  }

  _onTrainingDeleteConfirm = () => {
    const { userSkziAdmittance } = this.props
    const { selectedTraining } = userSkziAdmittance
    const { items } = selectedTraining

    this.setState({
      deleteTrainingConfirm: {
        type: dialogType.confirm,
        title: `Вы уверены, что хотите удалить: «${items.map(item => item.skziVersion).join(', ')}»?`
      }
    })
  }

  _renderModal = () => {
    const { 
      userSkziAdmittance,
      userSkziAdmittanceResetAction,
      resetSignature,
      signature, 
      error, 
      profile 
    } = this.props
    const { confirmObject } = userSkziAdmittance
    const { deleteAdmittanceConfirm, deleteTrainingConfirm, addAdmittanceDialog, addTrainingDialog } = this.state
    const { textForSignature } = signature || {}
    
    return (
      <>
        {deleteAdmittanceConfirm ? 
          <ConfirmDialog
            {...deleteAdmittanceConfirm}
            onSubmit={this._onRunAdmittanceDelete}
            onCancel={() => this.setState({deleteAdmittanceConfirm: null})}
          /> : null
        }
        {deleteTrainingConfirm ? 
          <ConfirmDialog
            {...deleteTrainingConfirm}
            onSubmit={this._onRunTrainingDelete}
            onCancel={() => this.setState({deleteTrainingConfirm: null})}
          /> : null
        }
        {addAdmittanceDialog ?
          <ModalAddAdmittanceDialog
            ref={this.admittanceDialog}
            compact
            onCancel={() => this.setState({addAdmittanceDialog: false})}
            admittanceParams={{ ...addAdmittanceDialog }}
            onAdmittanceAdd={this._onAdmittanceAdd}
            onFilesAdd={this._onFilesAdd}
            profile={profile}
            error={error}
          /> : null}
        {addTrainingDialog ? (          
          <AddTrainingDialog
            compact
            onCancel={() => this.setState({addTrainingDialog: false})}
            onSubmit={this._onTrainingAdd}
            renderHeader="Добавление обучения"
          />
        ) : null}
        {textForSignature ? (
          <SignatureDialog
            className={'signature-form'}
            compact
            profile={profile}
            onCancel={resetSignature}
            afterSignCallback={this._afterSign}
            renderHeader="Добавление допуска к СКЗИ"
          />) :null}
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onCancel={userSkziAdmittanceResetAction}
          /> : null
        }
      </>
    )
  }

  _renderDownloadToXlsButton = () => {
    const { userSkziAdmittance, currentUserAccount } = this.props
    const { selectedAdmittance } = userSkziAdmittance
    const { id: userId } = currentUserAccount
    const { items } = selectedAdmittance
    const dowloadUrl = `api/v1/admittance/xls/download?userId=${userId}&id=${items.map(item => item.id).join('&id=')}`

    return (
      <div className="buttons-element">
        <Button 
          className='delete-button'
          tooltip={'Скачать'}
          type='image'
          disabled={!items.length}
          href={items.length ? dowloadUrl : ''}
        >
          <DownloadToXlsImg className='button-image'></DownloadToXlsImg>
        </Button>
      </div>
    )
  }

  render() {
    const { userSkziAdmittance, currentUserAccount, profile } = this.props
    const { id: userId } = currentUserAccount || {}
    const { inProgress, selectedTraining, selectedAdmittance } = userSkziAdmittance
    const { items: selectedAdmittanceItems } = selectedAdmittance
    const { items: selectedTrainingItems } = selectedTraining
    const { 
      admittanceColumns, 
      trainingColumns, 
      changedAdm,
      changedTraining 
    } = this.state

    const userAccountRights = getResourceRights(profile, RESOURCES.userAccount)

    return (
      <section className='user-skzi-access'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <ExpandingBlock
          className='table-management'
          renderHeader= {() => 'Допуски к СКЗИ'}
          initialState={true}
        >
          <div className="manage-panel">
            {userAccountRights.UPDATE ? (
              <Button 
                className='create-button'
                type='primary'
                onClick={() => this.setState({addAdmittanceDialog: {userInfo: currentUserAccount}})}
              >
                <PlusImg className='button-image button-image--left'></PlusImg>
                <span className='button-title'>Добавить</span>
              </Button>
            ) : null}
            {this._renderDownloadToXlsButton()}
            {userAccountRights.UPDATE ? (
              <div className="buttons-element">
                <Button 
                  tooltip={'Удалить'}
                  className='delete-button'
                  type='image'
                  onClick={this._onAdmittanceDeleteConfirm}
                  disabled={!selectedAdmittanceItems.length}
                >
                  <DeleteImg className='button-image'></DeleteImg>
                </Button>
              </div>
            ) : null}
          </div>
          <TableWithLogic
            serviceName='courseService'
            methodName='getAllUserAdmittance'
            apiPayload={{userId}}
            defaultSort={{ 
              column: 'changeDate',
              direction: sortDirection.desc
            }}
            columns={admittanceColumns}
            changedRows = {changedAdm}
            setSelected={this._onAdmittanceSelect}
            onItemClick={this._getAdmittanceLink}
            selectType={SelectType.multiple}
          />
        </ExpandingBlock>
        <ExpandingBlock
          className='table-management'
          renderHeader= {() => 'Обучение СКЗИ'}
          initialState={true}
        >
          <div className="manage-panel">
            {userAccountRights.UPDATE ? (
              <Button 
                className='create-button'
                type='primary'
                onClick={() => this.setState({ addTrainingDialog: true })}
              >
                <PlusImg className='button-image button-image--left'></PlusImg>
                <span className='button-title'>Добавить</span>
              </Button>
            ) : null}
            {userAccountRights.UPDATE ? (
              <div className="buttons-element">
                <Button 
                  tooltip={'Удалить'}
                  className='delete-button'
                  type='image'
                  onClick={this._onTrainingDeleteConfirm}
                  disabled={!selectedTrainingItems.length}
                >
                  <DeleteImg className='button-image'></DeleteImg>
                </Button>
              </div>
            ) : null}
          </div>
          <TableWithLogic
            serviceName='courseService'
            methodName='getAllUserTraining'
            apiPayload={{userId}}
            defaultSort={{ 
              column: 'skziVersion',
              direction: sortDirection.desc
            }}
            columns={trainingColumns}
            changedRows = {changedTraining}
            setSelected={this._onTrainingSelect}
            onItemClick={this._getTrainingLink}
            selectType={SelectType.multiple}
          />
        </ExpandingBlock>
      </section>
    )
  }
}


const mapStateToProps = state => { 
  const { profile, userSkziAdmittance, error,signature } = state

  return {
    profile,
    signature,
    userSkziAdmittance,
    error 
  }
}

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...userSkziAdmittanceAction,
    ...signatureActions
  })(userSkziAccess)