const ActionTypes = {
  setConnection: 'NOTIFICATION_SET_CONNECTION',
  resetConnection: 'NOTIFICATION_RESET_CONNECTION',
  setUpdatedEvent: 'NOTIFICATION_SET_UPDATED_EVENT',
  setNeedRefreshByRest: 'NOTIFICATION_SET_NEED_REST_REFRESH',
  setLastRefreshedValues: 'NOTIFICATION_SET_LAST_REFRESHED_VALUES'
};

export function setConnection(connection){
  return {
    type: ActionTypes.setConnection,
    payload: connection
  }
}

export function resetConnection(){
  return {
    type: ActionTypes.resetConnection,
    payload: null
  }
}

// обновление уведомления не по сокетам
export function setUpdatedEvent(event){
  return {
    type: ActionTypes.setUpdatedEvent,
    payload: event
  }
}

export function setNeedRefreshByRest(value){
  return {
    type: ActionTypes.setNeedRefreshByRest,
    payload: value
  }
}

export function setLastRefreshedValues(value){
  return {
    type: ActionTypes.setLastRefreshedValues,
    payload: value
  }
}

export default ActionTypes