import {useEffect, useState} from "react";
import {Config} from "../configs/";
import {ServiceName, Status, Title} from "app/components/MassImports/enums";
import {CREATION_RESULT, LOAD_RESULT} from "app/components/MassImports/consts";

export const useInitResultFile = (type, status) => {
    const [fileStatus, setFileStatus] = useState(null);
    const [importsPayload, setImportsPayload] = useState(null);
    const [config, setConfig] = useState(null);
    const [duplicateConfig, setDuplicateConfig] = useState(null);
    const [title, setTitle] = useState(null);
    const [serviceMethod, setServiceMethod] = useState(null);

    useEffect(() => {
        if (config) {
            setDuplicateConfig({
                fieldsConfig: [Config.DUPLICATE_FIELD, ...config.fieldsConfig]
            });
            setImportsPayload(Config.PAYLOAD[fileStatus]);
        }
    }, [config]);
    useEffect(() => {
        if (fileStatus) {
            setConfig(Config.RESULTS[type]);
            setTitle(Title[fileStatus][type]);
            setServiceMethod(ServiceName[type]);
        }
    }, [fileStatus]);
    useEffect(() => {
        switch (status) {
            case Status.LOADING_END:
                setFileStatus(LOAD_RESULT);
                break;
            case Status.CREATING_END:
                setFileStatus(CREATION_RESULT);
                break;
        }
    }, []);

    return {
        config,
        duplicateConfig,
        importsPayload,
        setImportsPayload,
        title,
        serviceMethod
    };
};
