/** @format */

import { Route, Switch } from 'react-router-dom';
import AdmittanceList from 'app/components/admittance/List';
import OkzEskziJournal from 'app/components/journals/eskzi/okzList';
import OkiEskziJournal from 'app/components/journals/eskzi/okiList';
import OkzKeyDocJournal from 'app/components/journals/keyDocument/okzList';
import OkiKeyDocJournal from 'app/components/journals/keyDocument/okiList';
import OkiKeyCarrierJournal from 'app/components/journals/keyCarrier/okiList';
import OkzKeyCarrierJournal from 'app/components/journals/keyCarrier/okzList';
import TechnicalJournal from 'app/components/journals/technicalJournal';
import PageLicense from '../components/license/EskziLicenseList';
import PageLicenseExternal from '../components/license/ExternalEskziLicenseList';
import MaintenanceList from 'app/components/maintenance/List';
import PageOrganization from '../components/organizations/catalog/OrganizationsCatalog';
import PageKeyCarrier from '../components/keyCarrier/list/KeyCarrierList';
import UAList from 'app/components/userAccount/List';
import PageKeyDocOki from '../components/keyDocuments/list/okiList';
import PageKeyDocOkz from '../components/keyDocuments/list/okzList';
import CertRequests from 'app/components/certificateRequest';
import CertificateAuthorityRequests from 'app/components/certificateAuthority/requests';
import CertificateAuthorityCertificates from 'app/components/certificateAuthority/certificates';
import CertificateAuthorityUsers from 'app/components/certificateAuthority/users';
import CertificateAuthorityRequestsCard from 'app/components/certificateAuthority/requests/card';
import CertificateAuthorityUserCard from 'app/components/certificateAuthority/users/card';
import Certificates from 'app/components/certificates';
import CatalogList from '../components/catalog/list/list';
import KeyCarrierTypeCreate from 'app/components/catalog/keyCarrierType/createCard';
import ObjectTypeCreate from 'app/components/catalog/objectTypes/createCard';
import SkziTypeCreate from 'app/components/catalog/skziTypes/createCard';
import SkziTypeCard from 'app/components/catalog/skziTypes/card';
import ObjectTypeCard from 'app/components/catalog/objectTypes/card';
import KeyCarrierTypeCard from 'app/components/catalog/keyCarrierType/card';
import CreateEskziCard from '../components/skzi/Card/createCard';
import EskziCard from '../components/skzi/Card/card';

import SkziRegistry from '../components/skziRegistry/mainList/listForm';
import CreateSkzi from '../components/skziRegistry/createSkzi/createSkzi';
import CreateUserAccount from '../components/userAccount/createUserAccount/createUserAccount';
import SkziCard from '../components/skziRegistry/skziCard/skziCard';
import SkziVersionCard from '../components/skziRegistry/skziVersionCard/skziVersionCard';
import SkziBuildCard from '../components/skziRegistry/skziBuildCard/skziBuildCard';
import CreateSkziBuildCard from '../components/skziRegistry/skziBuildCard/CreateSkziBuildCard';
import CreateSkziVersionCard from '../components/skziRegistry/skziVersionCard/CreateSkziVersionCard';
import UserAccountCard from '../components/userAccount/userAccountCard/userAccountCard';
import CreateKeyDocument from '../components/keyDocuments/card/createCard';
import KeyDocumentCard from '../components/keyDocuments/card/keyDocumentCard';
import CreateOrganization from 'app/components/organizations/card/createCard';
import OrganizationCard from 'app/components/organizations/card/card';
import Users from '../components/user/list/list';
import ErrorPage, { pageType } from '../components/infoPages/ErrorPage';
import TestPage from 'app/components/infoPages/TestPage';
import PasswordPolicy from 'app/components/passwordPolicy/mainForm';
import TrainingCourses from 'app/components/trainingCourses/mainList';
import CreateTrainingCourses from 'app/components/trainingCourses/createCourse/createCourse';
import TrainingCoursesCard from 'app/components/trainingCourses/courseCard/courseCard';
import AdmittanceCard from 'app/components/admittance/admittanceCard/admittanceCard';
import CreateAdmittance from 'app/components/admittance/create/createAdmittance';
import SelfServiceRegistry from 'app/components/selfServiceRegistry/mainList/listForm';
import SelfServiceBuildCard from 'app/components/selfServiceRegistry/skziBuildCard/skziBuildCard';
import LicenseCreate from 'app/components/license/card/create';
import LicenseCard from 'app/components/license/card/card';
import MaintenanceCard from 'app/components/maintenance/card/maintenanceCard';
import MaintenanceCreate from 'app/components/maintenance/create/createMaintenance';

import CreateKeyCarrier from 'app/components/keyCarrier/card/createCard';
import KeyCarrierCard from 'app/components/keyCarrier/card/keyCarrierCard';
import CertificateCard from 'app/components/certificates/card/certificateCard';
import ActiveDirectorySettings from '../components/ActiveDirectorySettings';
import DestructionCertificateCreate from 'app/components/destructionCertificate/createCard';
import DestructionCertificateCard from 'app/components/destructionCertificate/card';
import StartPage from 'app/components/StartPage';
import Dashboard from 'app/components/dashboard';
import UserCreate from 'app/components/user/userCard/CreateUser';
import OKZTree from 'app/components/okziScheme/tree';
import CreateOkzCard from 'app/components/okziScheme/card/createCard';
import OkzCard from 'app/components/okziScheme/card/okzCard';
import User from '../components/user/userCard';
import LicensingPage from '../components/licensing';
import DestructionCertificateList from 'app/components/destructionCertificate/List';
import ImportPage from '../components/importPage/importPage';
import AllNotifications from 'app/components/Notification/allNotificationPage/allNotifications';
import SignatureDebug from 'app/components/signature/signDebug/SignatureDebug';
import EskziOkzList from 'app/components/skzi/EskziList/okzList';
import EskziOkiList from 'app/components/skzi/EskziList/okiList';
import ExternalOrgList from 'app/components/skzi/EskziList/externalOrgList';
import { catalogType } from 'app/components/catalog/list/pageConfig';
import About from 'app/components/_views/About/About';
import Hosts from 'app/components/_views/Hosts';
import Host from 'app/components/_views/Hosts/Host';

const Navigation = () => {
  return (
    <Switch>
      <Route exact path="/" component={StartPage} />

      <Route exact path="/about" component={About} />

      <Route
        exact
        path="/accessManagement/activeDirectorySettings"
        component={ActiveDirectorySettings}
      />
      <Route exact path="/accessManagement/hosts" component={Hosts} />
      <Route exact path="/accessManagement/hosts/card/:id" component={Host} />
      <Route exact path="/accessManagement/licensing" component={LicensingPage} />
      <Route exact path="/accessManagement/passwordPolicy" component={PasswordPolicy} />
      <Route exact path="/accessManagement/users" component={Users} />
      <Route exact path="/accessManagement/users/create" component={UserCreate} />
      <Route exact path="/accessManagement/users/card/:id" component={User} />

      <Route exact path="/acts/admittance" component={AdmittanceList} />
      <Route exact path="/acts/admittance/create" component={CreateAdmittance} />
      <Route exact path="/acts/admittance/card/:admittanceId" component={AdmittanceCard} />
      <Route exact path="/acts/destructioncertificate" component={DestructionCertificateList} />
      <Route
        exact
        path="/acts/destructioncertificate/create"
        component={DestructionCertificateCreate}
      />
      <Route
        exact
        path="/acts/destructioncertificate/card/:id"
        component={DestructionCertificateCard}
      />
      <Route exact path="/acts/maintenance" component={MaintenanceList} />
      <Route exact path="/acts/maintenance/create" component={MaintenanceCreate} />
      <Route exact path="/acts/maintenance/card/:maintenanceId" component={MaintenanceCard} />

      <Route
        exact
        path="/catalog/exemplarStatuses"
        render={() => (
          <CatalogList
            key={'/catalog/exemplarStatuses'}
            type={catalogType.exemplarStatuses}
            readOnlyMode
          />
        )}
      />
      <Route
        exact
        path="/catalog/keyCarrierType"
        render={() => (
          <CatalogList key={'/catalog/keyCarrierType'} type={catalogType.keyCarrierType} />
        )}
      />
      <Route exact path="/catalog/keyCarrierType/create" component={KeyCarrierTypeCreate} />
      <Route exact path="/catalog/keyCarrierType/card/:id" component={KeyCarrierTypeCard} />
      <Route
        exact
        path="/catalog/objectTypes"
        render={() => <CatalogList key={'/catalog/objectTypes'} type={catalogType.objectTypes} />}
      />
      <Route exact path="/catalog/objectTypes/create" component={ObjectTypeCreate} />
      <Route exact path="/catalog/objectTypes/card/:id" component={ObjectTypeCard} />
      <Route exact path="/catalog/okzScheme" component={OKZTree} />
      <Route exact path="/catalog/okzScheme/create" component={CreateOkzCard} />
      <Route
        exact
        path="/catalog/okzScheme/card/:id"
        render={props => <OkzCard {...props} key={props.match.params.id} />}
      />
      <Route exact path="/catalog/organization" component={PageOrganization} />
      <Route exact path="/catalog/organization/create" component={CreateOrganization} />
      <Route exact path="/catalog/organization/card/:id" component={OrganizationCard} />
      <Route
        exact
        path="/catalog/skziTypes"
        render={() => <CatalogList key={'/catalog/skziTypes'} type={catalogType.skziTypes} />}
      />
      <Route exact path="/catalog/skziTypes/create" component={SkziTypeCreate} />
      <Route exact path="/catalog/skziTypes/card/:id" component={SkziTypeCard} />
      <Route exact path="/catalog/trainingCourses/create" component={CreateTrainingCourses} />
      <Route exact path="/catalog/trainingCourses" component={TrainingCourses} />
      <Route exact path="/catalog/trainingCourses/card/:id" component={TrainingCoursesCard} />

      <Route exact path="/cert" component={SignatureDebug} />

      <Route exact path="/dashboard" component={Dashboard} />

      <Route exact path="/exemplars/okz" component={OkzEskziJournal} />
      <Route exact path="/exemplars/oki" component={OkiEskziJournal} />
      <Route exact path="/exemplars/keyCarrierOki" component={OkiKeyCarrierJournal} />
      <Route exact path="/exemplars/keyCarrierOkz" component={OkzKeyCarrierJournal} />
      <Route exact path="/exemplars/keyDocumentOki" component={OkiKeyDocJournal} />
      <Route exact path="/exemplars/keyDocumentOkz" component={OkzKeyDocJournal} />
      <Route exact path="/exemplars/technicalJournalOki" component={TechnicalJournal} />

      <Route exact path="/extorglicense" component={PageLicenseExternal} />
      <Route
        exact
        path="/extorglicense/card/:licenseId"
        render={props => <LicenseCard {...props} isExternalUser />}
      />

      <Route exact path="/extorgeskzi" component={ExternalOrgList} />
      <Route
        exact
        path="/extorgeskzi/card/:eskziId"
        render={props => <EskziCard {...props} isExternalUser />}
      />

      <Route exact path="/import" component={ImportPage} />
      <Route exact path="/keyDocument/okz" component={PageKeyDocOkz} />
      <Route exact path="/keyDocument/oki" component={PageKeyDocOki} />
      <Route exact path="/keyDocument/certRequest" component={CertRequests} />
      <Route exact path="/certificateAuthority/request" component={CertificateAuthorityRequests} />
      <Route
        exact
        path="/certificateAuthority/certificate"
        component={CertificateAuthorityCertificates}
      />
      <Route
        exact
        path="/certificateAuthority/user"
        component={CertificateAuthorityUsers}
      />
      <Route
        exact
        path="/certificateAuthority/request/card/:requestId"
        component={CertificateAuthorityRequestsCard}
      />
      <Route
        exact
        path="/certificateAuthority/user/card/:userId"
        component={CertificateAuthorityUserCard}
      />
      <Route
        exact
        path="/certificateAuthority/certificate/card/:certificateId"
        render={props => <CertificateCard {...props} CAUser />}
      />
      <Route exact path="/keyDocument/certificates" component={Certificates} />
      <Route
        exact
        path="/keyDocument/okz/create"
        render={props => <CreateKeyDocument {...props} isOkz />}
      />
      <Route
        exact
        path="/keyDocument/oki/create"
        render={props => <CreateKeyDocument {...props} isOkz={false} />}
      />
      <Route
        exact
        path="/keyDocument/okz/card/:keyDocumentId"
        render={props => <KeyDocumentCard {...props} isOkz />}
      />
      <Route
        exact
        path="/keyDocument/oki/card/:keyDocumentId"
        render={props => <KeyDocumentCard {...props} isOkz={false} />}
      />
      <Route exact path="/keyDocument/card/:keyDocumentId" component={KeyDocumentCard} />
      <Route exact path="/keyDocument/keyCarrier" component={PageKeyCarrier} />
      <Route exact path="/keyDocument/keyCarrier/create" component={CreateKeyCarrier} />
      <Route exact path="/keyDocument/keyCarrier/card/:keyCarrierId" component={KeyCarrierCard} />
      <Route
        exact
        path="/keyDocument/certificates/card/:certificateId"
        component={CertificateCard}
      />

      <Route exact path="/license" component={PageLicense} />
      <Route exact path="/license/create" component={LicenseCreate} />
      <Route exact path="/license/card/:licenseId" component={LicenseCard} />

      <Route exact path="/notification" component={AllNotifications} />

      <Route exact path="/skziAccounting" component={EskziOkzList} />
      <Route exact path="/skziAccounting/create" component={CreateEskziCard} />
      <Route
        exact
        path="/skziAccounting/EskziCard/:eskziId"
        render={props => <EskziCard {...props} isExternalUser={false} isOkz />}
      />

      <Route exact path="/skziOrganization" component={EskziOkiList} />
      <Route exact path="/skziOrganization/create" component={CreateEskziCard} />
      <Route
        exact
        path="/skziOrganization/EskziCard/:eskziId"
        render={props => <EskziCard {...props} isExternalUser={false} isOkz={false} />}
      />

      <Route exact path="/skziRegistry/accounting" component={SkziRegistry} />
      <Route exact path="/skziRegistry/accounting/create" component={CreateSkzi} />
      <Route exact path="/skziRegistry/accounting/skziCard/:id" component={SkziCard} />
      <Route
        exact
        path="/skziRegistry/accounting/skzi/:skziId/version/create"
        component={CreateSkziVersionCard}
      />
      <Route
        exact
        path="/skziRegistry/accounting/skzi/:skziId/version/:versionId"
        component={SkziVersionCard}
      />
      <Route
        exact
        path="/skziRegistry/accounting/skzi/:skziId/version/:versionId/build/create"
        component={CreateSkziBuildCard}
      />
      <Route
        exact
        path="/skziRegistry/accounting/skzi/:skziId/version/:versionId/build/:buildId"
        component={SkziBuildCard}
      />
      <Route exact path="/skziRegistry/selfService" component={SelfServiceRegistry} />
      <Route
        exact
        path="/skziRegistry/selfService/skzi/:skziId/version/:versionId/build/:buildId"
        component={SelfServiceBuildCard}
      />

      <Route exact path="/userAccounts" component={UAList} />
      <Route exact path="/userAccounts/create" component={CreateUserAccount} />
      <Route exact path="/userAccounts/card/:userId" component={UserAccountCard} />

      {/* MISC */}

      {process && process.env && process.env.NODE_ENV !== 'production' ? (
        <Route exact path="/dGVzdHBhZ2U=" component={TestPage} />
      ) : null}

      {/* ERROR PAGES */}

      <Route
        exact
        path="/connectionError"
        render={() => <ErrorPage type={pageType.connectionError} />}
      />
      <Route exact path="/serverError" render={() => <ErrorPage type={pageType.serverError} />} />
      <Route exact path="/trialEnd" render={() => <ErrorPage type={pageType.trialEnd} />} />
      <Route exact path="/unauthorized" render={() => <ErrorPage type={pageType.unauthorized} />} />
      <Route
        exact
        path="/unexpectedError"
        render={() => <ErrorPage type={pageType.unexpectedError} />}
      />

      <Route render={() => <ErrorPage type={pageType.notFound} />} />
    </Switch>
  );
};

export default Navigation;

