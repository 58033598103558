import Input from 'app/components/ui/Input'
import DatePicker from 'app/components/ui/date'
import { formatFIO } from 'app/core/utility/common'
import { notEmpty } from 'app/core/utility/validation'
import service from 'app/services/service'
import { debounce } from 'app/core/utility/common'
import DropDown from 'app/components/ui/DropDown'
import { cardMode as mode} from 'app/components/mainCard/cardComponent'

import { CardState } from 'app/components/ui/card/cardMaterial'
import {OKI_CARD_TYPE, ORG_CARD_TYPE, USER_ACCOUNT_CARD_TYPE} from '../../ui/constants'

export const JournalType = [
  {
    id: 'Okz',
    title: 'Органа криптографической защиты информации'
  },
  {
    id: 'Oki',
    title: 'Организации'
  },
]

const getGeneratedId = debounce( async (value, onChange, cardInProgressAction, viewData) => {
  const { id } = viewData
  cardInProgressAction(true)
  const result = typeof id === 'number' ? await service('keyDocumentService', 'getNewIdByIdentificationNumber', {data: value, kdId: id}) 
  : await service('keyDocumentService', 'getIdByIdentificationNumber', value) 
  const { data, isError, error } = result
  
  onChange('identificationNumber', !isError ? data : error[0].errorMessage)
  cardInProgressAction(false)
}, 700)

const commonLines = {
  id: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field key-document-card__id'
          label = '№'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  name: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, isCertificate, onChange, editValue, error } = props

      return (
        <Input 
          className='card-field key-document-card__name'
          label = 'Наименование'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit && isCertificate}
          error={error}
          onChange={value => onChange('name', value)}
          isRequired
        />
      )
    },
  },
  status: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props
      const { name } = editValue || {}

      return (
        <Input 
          className='card-field key-document-card__status'
          label = 'Статус'
          value={name}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  updateDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <DatePicker
          className='card-field key-document-card__updateDate'
          label = 'Дата изменения'
          value={editValue} 
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  serialNumber: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, isCertificate, editValue, onChange, error, viewData, cardInProgressAction } = props
      return (
        <Input
          className='card-field key-document-card__serialNumber'
          label = 'Серийный №'
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit && isCertificate}
          onChange={async value => {
            onChange('serialNumber', value)
            getGeneratedId(value, onChange, cardInProgressAction, viewData)
          }}
          isRequired
        />
      )
    },
  },
  identificationNumber: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field key-document-card__identificationNumber'
          label = '№ экземпляра'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  organizationType: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const {
        cardState,
        editValue,
        onChange,
        error,
        cardMode,
        commonDataForm,
      } = props;
      const { organizationType = {} } = commonDataForm || {};

      const stringValue = editValue === 'All' 
                          ? 'Органа криптографической защиты информации; Организации' 
                          : editValue === JournalType[0].id 
                            ? JournalType[0].title
                            : JournalType[1].title
                            
      const disabled = cardMode === CardState.create || cardMode === CardState.edit
          ? organizationType === 'Oki'
          : false;
      
      return cardMode === mode.create && editValue !== 'All' ? (
        <DropDown
          className='card-field key-document-card__jornal'
          items={JournalType}
          error={error}
          active={editValue === JournalType[0].id ? JournalType[0] : JournalType[1]}
          disabled={disabled}
          readOnly={cardState === CardState.view}
          onSelect={value => onChange('organizationType', value.id === JournalType[0].id ? JournalType[0].id : JournalType[1].id)}
          label='Учет в журнале'
        />
      ) : (
        <Input 
          className='card-field key-document-card__jornal'
          label='Учет в журнале'
          value={stringValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  }, 
  receivedFrom: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, isCertificate, editValue, onChange, error } = props
      const {id, okz} = editValue || {}
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
      return (
        <Input 
          className='card-field key-document-card__receivedFrom'
          label = 'Получено от'
          linkTarget={cardState === CardState.view && id && {targetType, id}}
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit && isCertificate}
          onChange={value => onChange('receivedFrom', value)}
          isRequired
        />
      )
    },
  },
  sentTo: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { editValue, profile, cardMode, cardState } = props
      const { shortName, name } = editValue || {}
      const { currentUser } = profile || {}
      const { organization } = currentUser || {}
      const { shortName: profileShort, name: profileName } = organization || {}
      const value = cardMode === mode.create
                    ? profileShort || profileName
                    : shortName || name

      const {id, okz} = editValue || {}
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE

      return (
        <Input 
          className='card-field key-document-card__movedTo'
          label = 'Передано в'
          linkTarget={cardState === CardState.view && id && {targetType, id: id}}
          value={value}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  eskziUser: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props

      return (
        <Input 
          className='card-field key-document-card__eskziUser'
          label = 'Пользователь'
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
          linkTarget={cardState === CardState.view && editValue && editValue.id && {targetType: USER_ACCOUNT_CARD_TYPE, id: editValue.id}}
          value={formatFIO(editValue)}
        />
      )
    },
  },
  expirationDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, isCertificate, editValue, onChange } = props

      return (
        <DatePicker
          className='card-field key-document-card__expirationDate'
          label = 'Срок действия'
          value={editValue} 
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit && isCertificate}
          onChange={value => onChange('expirationDate', value)}
        />
      )
    },
  },
  hardwareNumber: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <Input 
          className='card-field key-document-card__hardwareNumber'
          label = 'Номер аппаратного средства'
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  comment: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error} = props

      return (
        <Input 
          className='card-field key-document-card__comments'
          label = 'Назначение'
          readOnly={cardState === CardState.view}
          value={editValue}
          error={error}
          onChange={value => onChange('comment', value)}
        />
      )
    },
  },
};

export const  cardLines = {
  ...commonLines,  
}

export const createLines = {
  ...commonLines,
  transmittalLetterNumber: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field key-document-card__transmittalLetterNumber'
          label = 'Номер сопроводительного письма'
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          onChange={value => onChange('transmittalLetterNumber', value)}
        />
      )
    },
  },
  transmittalLetterDate: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error, cardMode } = props

      return (
        <DatePicker
          className='card-field key-document-card__transmittalLetterDate'
          label = 'Дата сопроводительного письма'
          value={editValue} 
          error={error}
          readOnly={cardState === CardState.view}
          onChange={value => onChange('transmittalLetterDate', value)}
          disabled={cardMode === mode.edit}
          isRequired
        />
      )
    },
  },
}