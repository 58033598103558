import * as React from 'react';
import * as CryptoPro from './cryptopro';
import * as JinnClient from './jinn';
import { CryptoProviderType, ICryptoProviders, ICertificate, SignatureFormat } from './types';

export interface ISignatureFormProps {
  signatureData?: string;
  providers: ICryptoProviders;
  currentProvider?: CryptoProviderType;
  onChangeProvider: (e: React.SyntheticEvent<HTMLSelectElement>) => void;
  onChangeCryptoProCert: (e: React.SyntheticEvent<HTMLSelectElement>) => void;
  signErrorMessage?: string;
}

export interface ICryptoFN {
  children: (
    isRenderProvidersRequest: boolean,
    isRenderProvidersFailure: boolean,
    isRenderSignatureForm: boolean,
    isRenderSubmitRequest: boolean,
    isRenderSubmitSuccess: boolean,
    isRenderSubmitFailure: boolean,
    isNextButtonEnabled: boolean,

    signatureProps?: ISignatureFormProps,

    onNext?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => Promise<void>,
  ) => React.ReactNode;
}

export interface IWithCryptoProvidersProps extends ICryptoFN {
  crypto: {
    providers: ICryptoProviders;
    currentProvider?: CryptoProviderType;
  };

  initProviders: () => Promise<void>;
  changeProvider: (value: CryptoProviderType) => void;
  changeCryptoProCert: (value: string) => void;
  signData: (data: string, signatureFormat: SignatureFormat) => Promise<string | undefined>;
  isInitialized: () => boolean;
  isReadyToSign: () => boolean;
}

interface IState {
  providers: ICryptoProviders;
  currentProvider?: CryptoProviderType;
  signing: boolean;
}

type WrappedComponent<P> =
  | React.ComponentClass<P & IWithCryptoProvidersProps>
  | React.SFC<P & IWithCryptoProvidersProps>
  | React.FC<P & IWithCryptoProvidersProps>;

export function withCryptoProviders<P>(Component: WrappedComponent<P>): React.ComponentClass<P & ICryptoFN> {
  return class extends React.PureComponent<P & ICryptoFN, IState> {
    public state: IState = {
      providers: {},
      signing: false,
    };

    public onInitProviders = () => {
      return initCryptoProviders()
        .then((cryptoProviders) => {
          this.setState(() => ({
            currentProvider: cryptoProviders.currentProvider,
            providers: cryptoProviders.providers,
          }));
        })
        .catch(() => {
          this.setState(() => ({
            currentProvider: undefined,
            providers: {},
          }));
        });
    };

    public onChangeProvider = (value: CryptoProviderType) => {
      if (value === CryptoProviderType.CRYPTO_PRO) {
        this.setState((prevState) => ({
          providers: {
            ...prevState.providers,
            CRYPTO_PRO: {
              ...prevState.providers.CRYPTO_PRO,
              currentCert: undefined,
            },
          },
        }));
      }

      this.setState(() => ({
        currentProvider: value ? value : undefined,
      }));
    };

    public onChangeCryptoProCert = (value: string) => {
      this.setState((prevState) => ({
        providers: {
          ...prevState.providers,
          CRYPTO_PRO: {
            ...prevState.providers.CRYPTO_PRO,
            currentCert: value ? value : undefined,
          },
        },
      }));
    };

    public onSignData = async (data: string, signatureFormat: SignatureFormat) => {
      const { currentProvider, providers } = this.state;

      if (!currentProvider) {
        return;
      }

      this.setState(() => ({
        signing: true,
      }));

      try {
        if (currentProvider === CryptoProviderType.CRYPTO_PRO) {
          if (providers.CRYPTO_PRO && providers.CRYPTO_PRO.currentCert) {
            return await CryptoPro.signData(providers.CRYPTO_PRO.currentCert, data, true, signatureFormat);
          }
        }

        if (currentProvider === CryptoProviderType.JINN_CLIENT) {
          return await JinnClient.signDocument(data, { encode: true });
        }

        return;
      } finally {
        this.setState(() => ({
          signing: false,
        }));
      }
    };

    public isInitialized = () => {
      return this.state.providers.CRYPTO_PRO !== undefined || this.state.providers.JINN_CLIENT !== undefined;
    };

    public isReadyToSign = () => {
      const { currentProvider, providers, signing } = this.state;

      if (signing || !currentProvider) {
        return false;
      }

      if (currentProvider === CryptoProviderType.CRYPTO_PRO) {
        if (!providers.CRYPTO_PRO || !providers.CRYPTO_PRO.currentCert) {
          return false;
        }
      }

      if (currentProvider === CryptoProviderType.JINN_CLIENT) {
        return true;
      }

      return true;
    };

    public render() {
      return (
        <Component
          {...this.props}
          crypto={this.state}
          isReadyToSign={this.isReadyToSign}
          isInitialized={this.isInitialized}
          initProviders={this.onInitProviders}
          changeProvider={this.onChangeProvider}
          changeCryptoProCert={this.onChangeCryptoProCert}
          signData={this.onSignData}
        />
      );
    }
  };
}

function initCryptoProCerts() {
  return CryptoPro.getCerts()
    .then((certs) => certs.filter((x) => new Date(x.to) >= new Date()))
    .then((certs) => {
      const result: Array<ICertificate> = [];
      certs.forEach((cert) => {
        if (cert.subject) {
          const namePart = cert.subject.split(', ').filter((item: string) => item.indexOf('CN=') >= 0);
          const name = namePart.length ? namePart[0].replace('CN=', '') : '';

          name &&
            result.push({
              name,
              hash: cert.hash,
              subject: cert.subject,
              issuer: cert.issuer,
              from: cert.from,
              to: cert.to,
              serial: cert.serial,
              version: cert.version,
            });
        }
      });

      return result;
    })
    .catch(() => undefined);
}

function getDefaultCryptoProvider(cryptoPro: boolean, jinnClient: boolean): CryptoProviderType | undefined {
  if (jinnClient) {
    return CryptoProviderType.JINN_CLIENT;
  }

  if (cryptoPro) {
    return CryptoProviderType.CRYPTO_PRO;
  }

  return undefined;
}

async function initCryptoProviders(): Promise<{ providers: ICryptoProviders; currentProvider?: CryptoProviderType }> {
  const [cryptoPro, jinnClient] = await Promise.all([
    CryptoPro.getInfo().catch(() => undefined),
    JinnClient.getInfo().catch(() => undefined),
  ]);

  return {
    currentProvider: getDefaultCryptoProvider(!!cryptoPro, !!jinnClient),
    providers: {
      CRYPTO_PRO: cryptoPro && {
        ...(cryptoPro as {}),
        certsList: await initCryptoProCerts(),
      },
      JINN_CLIENT: jinnClient,
    },
  };
}