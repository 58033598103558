import React from "react";
import Button from 'app/components/ui/button/button'

export default function SaveButtons({onSubmit, cancelEnabled = true, submitDisabled = false, submitTitle, onCancelForm}) {

  return (
    <>
      <Button
        className='card-management__item'
        type='primary'
        onClick={onSubmit}
        disabled={submitDisabled}
      >
        <span className='button-title'>{submitTitle || 'Сохранить'}</span>
      </Button>
      <Button
        disabled={!cancelEnabled}
        className='card-management__item'
        type='secondary'
        onClick={onCancelForm}
      >
        <span className='button-title'>Отмена</span>
      </Button>
    </>
  )
}