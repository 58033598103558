import { 
ERROR,
FIELDSERROR,
ERRORPAGEERROR,
CLEARERROR,
SKZICARDGET, 
SKZICARDGETVERSIONS,
SKZICARDUPDATEFORMDATA,
SKZICARDINPROGRESS    ,
SKZICARDSETERRORBYKEY ,
SKZICARDRESETACTION    ,
SKZICARDUPDATE,
SKZICARDDELETE,
SKZICARDONDELETE,
SKZICARDONADDVERSION ,
SKZICARDUPDATEVERSIONDATA,
SKZICARDUPDATECREATEFORMDATA,
SKZICARDUPDATEEDITVERSIONFORMDATA,
SKZICARDUPDATEEDITBUILDFORMDATA,
SKZICARDVERSIONADD,
SKZICARDVERSIONDELETE,
SKZICARDVERSIONONDELETE,
SKZICARDVERSIONONBUILDADD,
SKZICARDUPDATEADDBUILDFORMDATA,
SKZICARDVERSIONBUILDADD,
SKZICARDBUILDONDELETE,
SKZICARDBUILDONEDIT,  
SKZICARDBUILDEDIT,    
SKZICARDBUILDDELETE,
SKZICARDBUILDONEDITFILES ,
SKZICARDBUILDONFILESUPDATED,
SKZIREGISTRYADDBUILDOBJECTTYPE,
SKZIREGISTRYDELETEBUILDOBJECTTYPE,
SKZIREGISTRYSETMAINBUILDOBJECTTYPE,
SKZICARDCLOSEFILESPICKER,
SKZICARDSUBMITFILESPICKER,
SKZICARDONVERSIONEDIT,
SKZICARDONCANCEL,
SKZICARDONSELECT
} from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { mapDataToLines } from 'app/components/skziRegistry/skziCard/constants'
import { excludeByItemId, arrayToIdHashMap } from 'app/core/utility/common'

const defaultState = {
  selected: {
    items: [],
    selectedHashMap: {}
  },
  commonDataForm : {
    skziTypeId: null,
    description: '',
    producerId: null,
    name: '',
    updateDate: null,
    shortDescription: ''
  },
  viewData: {
    skziTypeId: null,
    description: '',
    producerId: null,
    name: '',
    updateDate: null,
    shortDescription: ''
  },
  createVersionForm: {
    name: '',
    description: ''
  },
  editVersionForm: {
    name: '',
  },
  defaultCreateVersionForm: {
    name: '',
    description: ''
  },
  createBuildForm : {
    name: '',
    classId: null,
    skziVersionId: '',
  },
  defaultcreateBuildForm : {
    name: '',
    classId: null,
    skziVersionId: '',
  },
  editVersionObject: null,
  editBuildForm: {
    classId: null,
    name: ''
  },
  skziData: {},
  errorByFields: {},
  errorObject: null,
  deletedInfoObject: null,
  confirmObject: null,
  deleteBuildConfirm: null,
  deleteVersionConfirmObject: null,
  addBuildDialog: null,
  editBuildDialog: null,
  addVersionDialog: null,
  buildFilesDialog: null,
  skziUpdated: false,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { skziData, selected } = state
  const { versions } = skziData
  const { commonDataForm, viewData, buildFilesDialog } = state
    
  switch (action.type) {
    case SKZICARDONSELECT: 
      return {
        ...state,
        selected: payload || defaultState.selected
      }
      
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case SKZICARDUPDATE: 
    case SKZICARDGET:
      
      return {
        ...state,
        commonDataForm: mapDataToLines(payload),
        viewData: mapDataToLines(payload),
        skziData: {...payload},
        inProgress: false
      }

    case SKZICARDGETVERSIONS:
      return {
        ...state,
        skziData: {
          ...skziData,
          versions: payload
        },
        inProgress: false
      }

    case CLEARERROR:
      return {
        ...state,
        errorObject: null,
        deletedInfoObject: null,
        confirmObject: null,
        deleteBuildConfirm: null,
        deleteVersionConfirmObject: null,
        addBuildDialog: null,
        editBuildDialog: null,
        addVersionDialog: null,
        inProgress: false
      }
    
    case FIELDSERROR:
    case ERROR:
      return {
        ...state,
        inProgress: false
      }
      
    case SKZICARDBUILDONEDITFILES:
      const { objectTypes } = payload
      
      return {
        ...state,
        buildFilesDialog: {
          ...payload, 
          objectTypes: objectTypes.map(item => ({ ...item, title: item.name})),
          showPicker: true
        },
        inProgress: false
      }

    case SKZICARDONVERSIONEDIT: 
      const { skzi, version } = payload 
      const { name } = version
      return {
        ...state,
        editVersionObject: {
            skzi,
            version
          },
        editVersionForm: {
          name: name
        }
      }

    case SKZICARDONADDVERSION:
        return {
          ...state,
          addVersionDialog: {item: payload},
          createVersionForm: defaultState.createVersionForm,
          inProgress: false
      }

    case SKZICARDBUILDONEDIT:
      const { build } = payload
      const { class: cls, certificateExpirationDate } = build
      const initialBuildData = {
        ...build,
        classId: cls,
        certificateExpirationDate
      }

      return {
        ...state,
        editBuildDialog: {
          ...payload,
          build: initialBuildData,
          initialBuildData: initialBuildData
        },
        inProgress: false
      }

    case SKZICARDVERSIONONBUILDADD:
      const { createBuildForm: cbfState } = state

      return {
        ...state,
        addBuildDialog: {...payload},
        createBuildForm: {
          ...cbfState,
        },
        inProgress: false
      }
   
    case SKZICARDVERSIONADD:

      return {
        ...state,
        skziData: {
          ...skziData,
          versions: [
            ...versions,
            payload
          ]
        },
        addVersionDialog: null,
        inProgress: false
      }

    case SKZICARDVERSIONBUILDADD:
    case SKZICARDBUILDDELETE:
    case SKZICARDBUILDEDIT:
      return {
        ...defaultState
      }

    case SKZIREGISTRYADDBUILDOBJECTTYPE:
    case SKZIREGISTRYDELETEBUILDOBJECTTYPE:
    case SKZIREGISTRYSETMAINBUILDOBJECTTYPE:
    case SKZICARDBUILDONFILESUPDATED:
      
      return {
        ...state,
        buildFilesDialog: {
          ...buildFilesDialog,
          showPicker: false
        },
        inProgress: false
      }

    case SKZICARDVERSIONDELETE:
      const { items: selectedItems } = selected
      let confirmDialog = null
      const afterDeleteSelected = excludeByItemId(selectedItems, payload.map(item => ({id: item}))) 

      if (errors && errors.length) {
        confirmDialog = {
          header: 'Удаление невозможно',
          type: dialogType.warning,
          messages: errors.map(item => item.errorMessage)
        }
      }

      return {
        ...state,
        selected: {
          items: afterDeleteSelected,
          selectedHashMap: arrayToIdHashMap(afterDeleteSelected)
        },
        skziData: {
          ...skziData,
          versions: excludeByItemId(versions, payload.map(item => ({id: item})))
        },
        deleteVersionConfirmObject: null,
        confirmObject: confirmDialog,
        inProgress: false
      }

    case SKZICARDVERSIONONDELETE: 

      return {
        ...state,
        deleteVersionConfirmObject: {
          item: payload,
          type: dialogType.confirm,
          title: `Вы уверены, что хотите удалить: «${payload.map(item => item.name).join(', ')}»?`
        },
        inProgress: false
      }

    case SKZICARDUPDATEVERSIONDATA:
      return {
        ...state,
        editVersionObject: null,
        inProgress: false
      }

    case SKZICARDONDELETE:
        return {
          ...state,
          confirmObject: {
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить: «${skziData.name}»? `
          },
          inProgress: false
        }

    case SKZICARDUPDATEFORMDATA: 
      return {
        ...state,
        commonDataForm: {...payload }
      }

    case SKZICARDUPDATECREATEFORMDATA: 
      return {
        ...state,
        createVersionForm: {...payload }
      }

    case SKZICARDUPDATEEDITVERSIONFORMDATA: 
      return {
        ...state,
        editVersionForm: {...payload }
      }

    case SKZICARDUPDATEADDBUILDFORMDATA: 
      const { createBuildForm: cbf } = state
      
      return {
        ...state,
        createBuildForm: { ...cbf, ...payload }
      }

      

    case SKZICARDUPDATEEDITBUILDFORMDATA: 
      return {
        ...state,
        editBuildDialog: payload
      }

    case SKZICARDINPROGRESS:
      return {...state, inProgress: payload}

    case SKZICARDSETERRORBYKEY:
      const { errorByFields } = state

      return {
        ...state,
        errorByFields: { ...errorByFields, ...payload }
      }

    case SKZICARDONCANCEL:
      return { 
        ...state,
        commonDataForm: {...viewData}
      }

    case SKZICARDCLOSEFILESPICKER:
      return {
        ...state,
        buildFilesDialog: null
      }

    case SKZICARDSUBMITFILESPICKER:
      return {
        ...state,
        buildFilesDialog: {
          ...buildFilesDialog,
          showPicker: false
        }
      }

    case SKZICARDRESETACTION: 

      return { 
        ...defaultState,
        viewData: viewData,
        skziData: skziData,
        selected: selected,
        commonDataForm: commonDataForm,
      }

    default: 
      return state
  }
}