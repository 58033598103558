import { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import { cardLines } from './skziBuildData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import ButtonComponent from 'app/components/ui/button/button'
import TagComponent from 'app/components/ui/tag'
import Img, { Image } from 'app/components/ui/Img'
import Input from 'app/components/ui/Input'
import { arrayToIdHashMap, capitalize, excludeByItemId, serverlikeErrors } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import AddTypeDialog, { Mode } from './addTypeDialog'
import AddFileDialog from "./addFilesDialog";
import { itemActions, sortDirection } from 'app/components/ui/constants'

import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { ContentComponentType, HashStatus } from 'app/components/skziRegistry/enums'
import hashStatusIcons from 'app/components/skziRegistry/hashStatusIcons'

import withAuth from 'app/components/HOC/AuthHOC'
import withModal from 'app/components/HOC/ObsoleteModalHOC'
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC'

import * as errorAction from 'redux/actions/common'
import * as skziBuildCardAction from 'redux/actions/skziRegistry/skziBuildCardAction'
import CertificationBlock from "./CertificationBlock";
import service from 'app/services/service'
import withRoutingProps from 'app/components/HOC/RoutingPropsHOC'
import styles from './skziBuildCard.module.scss';
import TableWithLogic from 'app/components/list/Table/tableWithLogic'
import { SelectType } from 'app/components/ui/picker/picker'

const Button = withTooltip(ButtonComponent)
const ModalTypeDialog = withModal(AddTypeDialog)
const ModalFileDialog = withModal(AddFileDialog)
const Tag = withTooltip(TagComponent)
const TooltippedImg = withTooltip(Img)

class SkziCard extends PureComponent {
  constructor(props){
    super(props)

    const { profile } = props
    const fileRights = getResourceRights(profile, RESOURCES.files)

    this.state = { 
      tabTitle: 'Информация',
      sort: { 
        column: 'main',
        direction: sortDirection.desc
      },
      addTypeDialogMode: Mode.Add,
      buildColumns: [
        {
          title: 'Основной',
          alias: 'main',
          useSorting: true,
          width: 150,
          format: item => {
            const { main } = item
            
            return main ? (
              <TooltippedImg
                img={Image.Main}
                tooltip = 'Основной элемент комплекта (серийный номер этого элемента будет номером экземпляра)' 
                tooltipPosition={Position.Right}
                className='is-main'
              /> 
            ) : null
          }
        },
        {
          title: 'Тип элемента',
          alias: 'objectType',
          useSorting: true,
          width: 300,
          format: item => {
            const { objectType } = item
            const { name } = objectType
            
            return name
          }
        },
        {
          title: 'Вложения',
          alias: 'documents',
          useSorting: false,
          isCustomRender: true,
          format: rowData => {
            const { files, objectType: { name: objectTypeName } } = rowData
            const { params } = this.props
            const { skziId, versionId, buildId } = params
            const { currentlyDeletingFiles = {}} = this.state || {}
            const urlPart = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/file`

            return (
              <div className="type-files">
                {files.map((item) => {
                  return (
                    <Tag
                      key={item.guid}
                      item={item}
                      tooltip={objectTypeName === ContentComponentType.InstallationPackage
                        ? `${item.hashStatus || HashStatus.NotSet}: ${item.name}`
                        : item.name
                      }
                      icon={objectTypeName === ContentComponentType.InstallationPackage &&
                        <Img
                          img={hashStatusIcons[item.hashStatus || HashStatus.NotSet]}
                          className={`${styles.fileTagIcon} ${styles[Object.keys(HashStatus).find(k => HashStatus[k] === item.hashStatus) || 'NotSet']}`} />
                      }
                      { ...currentlyDeletingFiles[item.guid] ? {
                        text: 'Удаление...',
                        className: 'deleting-file-tag',
                      } : {
                        text: item.name,
                        link: !item.hashStatus || item.hashStatus === HashStatus.Ok || item.canBeDownloaded
                          ? `${urlPart}/${item.guid}`
                          : null,
                        onDelete: fileRights.DELETE 
                          ? () => this._onFileDelete({file: item, typeElement: rowData})
                          : null
                      }}
                    />
                  );
                }
                )}
              </div>
            )
          }
        },
      ]
    }
  }

  componentDidMount() {
    const { skziBuildCard, getSkziBuildAction, skziBuildCardInProgress, params, location} = this.props
    const { pathname } = location
    const { buildId } = params
    const { inProgress } = skziBuildCard

    if (inProgress) {
      return
    }
   
    skziBuildCardInProgress(true)
    getSkziBuildAction({ buildId, pathname})
  }

  componentWillUnmount () {
    const { 
      skziBuildCardResetAction,
      clearErrorAction,
      skziBuildCardOnSelect
    } = this.props

    skziBuildCardOnSelect()
    skziBuildCardResetAction()
    clearErrorAction()
  }

  _onSubmit = () => {
    const {
      skziBuildCard,
      skziBuildCardUpdateAction,
      skziBuildCardInProgress,
      params,
      location
    } = this.props
    const { versionId } = params
    const { commonDataForm, skziBuildData } = skziBuildCard
    const { classId, certificateExpirationDate } = commonDataForm
    const { id } = classId || {}
    const { pathname } = location

    const buildData = {
      ...commonDataForm,
      id: skziBuildData.id,
      skziVersionId: versionId,
      classId: id || null,
      certificateExpirationDate: formatDate(certificateExpirationDate, 'yyyy-mm-dd')
    }

    skziBuildCardInProgress(true)
    skziBuildCardUpdateAction(buildData, pathname)
  }

  _onCancel = () => {
    const { clearErrorAction, skziBuildCardResetAction } = this.props

    skziBuildCardResetAction()
    clearErrorAction()
  }

  _applyRightsToActions = () => {
    const { profile } = this.props
    const actions = []
    const buildRights = getResourceRights(profile, RESOURCES.build)

    buildRights.UPDATE && actions.push(itemActions.edit.key)
    buildRights.DELETE && actions.push(itemActions.delete.key)

    return actions
  }

  _onFileDelete = ({file, typeElement}) => {
    const { params } = this.props
    const { currentlyDeletingFiles } = this.state
    const { skziId, versionId, buildId } = params
    const { guid } = file
    const { id } = typeElement

    this.setState({currentlyDeletingFiles: {
      ...currentlyDeletingFiles,
      [guid]: guid
    }})

    service('versionService', 'deleteBuildTypeFile', {skziId, versionId, buildId, guid, typeElement})
      .then(response => {
        const { isError } = response
        if (isError) {
          const deleting = {...currentlyDeletingFiles}
          delete deleting[guid]
          this.setState({currentlyDeletingFiles: deleting})
        } else {
          this.setState({changed: {deletedFile: {guid, id}}})
        }
      })
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { skziBuildCard, skziBuildCardUpdateCommonForm, setErrorByKey, error } = this.props
    const { commonDataForm } = skziBuildCard
    const { errorByFields }  = error || {}

    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    setErrorByKey(filteredErrors)
    skziBuildCardUpdateCommonForm({ ...commonDataForm, [key]: value })
  }

  _onSelect = data => {
    const { skziBuildCardOnSelect } = this.props

    skziBuildCardOnSelect({
      items: data,
      selectedHashMap: arrayToIdHashMap(data)
    })
  }

  _onDataChanged = (list) => {
    this.setState({currentTypes: list})
  }

  _onSetMainType = async() => {
    const { skziBuildCard, errorAction, skziBuildCardInProgress, params } = this.props
    const { currentTypes = [] } = this.state
    const { selected } = skziBuildCard
    const { skziId, versionId, buildId } = params
    const { items } = selected
    const [ selectedType ] = items
    const { objectType } = selectedType || {}
    const { id: objectTypeId } = objectType
    const mainType = currentTypes.find(i => i.main)

    skziBuildCardInProgress(true)
    const result = await service('versionService', 'setMainBuildObjectType', {skziId, versionId, buildId, objectTypeId, selectedType})
    const { isError } = result || {}
    if (!isError) {
      this.setState({changed: {
        updated: [
          {...selectedType, main: true},
          {...mainType, main: false}
        ]
      }})
    } else {
      errorAction(result)
    }

    skziBuildCardInProgress(false)
  }

  _onTypeDeleteConfirm = () => {
    const { skziBuildCardOnTypeDelete, skziBuildCard } = this.props
    const { selected } = skziBuildCard
    const { items } = selected

    skziBuildCardOnTypeDelete(items)
  }

  _onRunTypeDelete = async () => {
    const { skziBuildCard, skziBuildCardRemoveTypesAction, skziBuildCardInProgress, params } = this.props
    const { selected } = skziBuildCard
    const { items } = selected
    const { skziId, versionId, buildId } = params

    skziBuildCardInProgress(true)
    const result = await skziBuildCardRemoveTypesAction({skziId, versionId, buildId, objectTypes: items.map(item => ({...item.objectType}))})
    const { payload = [] } = result || {}
    this.setState({changed: {deleted: payload}})
  }

  _onFilesAddDialog = () => {
    const { skziBuildCardOnAddFilesAction } = this.props

    skziBuildCardOnAddFilesAction()
  }

  _onTypeAddDialog = async () => {
    const { skziBuildCardOnAddTypeAction, skziBuildCardInProgress } = this.props
    const { currentTypes } = this.state

    skziBuildCardInProgress(true)
    this.setState({ addTypeDialogMode: Mode.Add })
    const result = await skziBuildCardOnAddTypeAction()
    const { payload } = result || {}
    const allowedTypes = excludeByItemId(payload, currentTypes)
    this.setState({ allowedTypes })
    
    skziBuildCardInProgress(false)
  }
  
  _onTypeEditDialog = async () => {
    const { skziBuildCardOnAddTypeAction, skziBuildCardInProgress } = this.props
    const { currentTypes } = this.state

    skziBuildCardInProgress(true)
    this.setState({ addTypeDialogMode: Mode.Edit })
    const result = await skziBuildCardOnAddTypeAction()
    const { payload } = result || {}
    const allowedTypes = excludeByItemId(payload, currentTypes)
    this.setState({ allowedTypes })
    
    skziBuildCardInProgress(false)
  }

  _fileUploaded = (uploadResult) => {
    const { id, result } = uploadResult || {}
    this.setState({ changed: { addedFile: { id, fileResults:[result] } } })
  }
  
  _fileDeleted = (deleteResult) => {
    const { id, result } = deleteResult || {}
    const { guid } = result || {};
        
    this.setState({ changed: { deletedFile: { id, guid } } })
  }
  
  _fileUpdated = (updateResult) => {
    const { id, result } = updateResult ?? {}
    const normalizedResult = {
      ...result,
      ...(result.hashStatus === HashStatus.NotSet ? { userHashSum: null } : {})
    }
    
    const { guid, ...updatedFields } = normalizedResult ?? {}
    this.setState({ changed: { updatedFile: { id, guid, ...updatedFields } } })
  }

  _onTypeAdd = async (type, hasFiles) => {
    const { 
      skziBuildCardResetAction,
      skziBuildCardInProgress,
      params 
    } = this.props
    const { skziId, versionId, buildId } = params

    skziBuildCardInProgress(true)
    const result = await service('versionService', 'addObjectTypeToBuild', {skziId, versionId, buildId, objectTypes: [type]})
    skziBuildCardInProgress(false)
    const { data } = result || {}
    if (data && data[0]) {
      this.setState({changed: {added: data.map(i => ({...i, id: i.objectType.id}))}})
    }

    !hasFiles && skziBuildCardResetAction()

    return result
  }

  _renderDownloadButton = () => {
    const { skziBuildCard, params } = this.props
    const { selected } = skziBuildCard
    const { items } = selected
    const { skziId, versionId, buildId } = params
    const canDownload = items.length
      && items.find(item => item.files && item.files.length)
      && items.every(item => (item.objectType && item.objectType.name !== ContentComponentType.InstallationPackage) || item.files.every(f => f.canBeDownloaded))

    const dowloadUrl = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/content/download?id=${items.map(item => item.objectType.id).join('&id=')}`

    return (
      <Button 
        tooltip={'Скачать'}
        type='image'
        disabled={!canDownload}
        href={canDownload ? dowloadUrl : ''}
      >
        <Img img={Image.Download} />
      </Button>
    )
  }

  _renderData = () => {
    const { skziBuildCard, profile, error, params } = this.props
    const { skziId, versionId, buildId } = params
    const { fieldsError } = error
    const { buildColumns, changed } = this.state
    const { skziBuildData, selected, viewData, commonDataForm, inProgress } = skziBuildCard
    const { items } = selected
    const { documentationDisabled } = skziBuildData
    const fileRights = getResourceRights(profile, RESOURCES.files)
    const [ selectedType ] = items || []
    const makeMainDisabled = items.length !== 1 || selectedType.main
    const buildRights = getResourceRights(profile, RESOURCES.build)
    
    return (
      <section className='skzi-build-common-data'>
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты сборки/модификации'}
          initialState={true}
        >
          <Card
            className='skzi-build-data-card'
            profile={profile}
            formLines={cardLines}
            actions={this._applyRightsToActions()}
            canEdit={buildRights.UPDATE}
            key={this._getBuildKey(viewData)}
            data={viewData}
            formData={commonDataForm}
            onValidationError={this._onValidation}
            errorByFields={fieldsError}
            onChange={this._onFormChange}
            onSubmit={this._onSubmit}
            onCancel={this._onCancel}
            getObjectKeyFunc={this._getBuildKey}
          />
        </ExpandingBlock>
        {!documentationDisabled && (
          <CertificationBlock
            inProgress={inProgress}
          />
        )
        }
        {!documentationDisabled && (
          <ExpandingBlock
            className='skzi-build-types'
            renderHeader= {() => 'Состав комплекта сборки / модификации'}
            initialState={true}
          >
            <div className="skzi-build-types manage-panel">
              {fileRights.CREATE ? (
                <Button 
                  className='create-button'
                  type='primary'
                  onClick={this._onTypeAddDialog}
                >
                  <Img img={Image.Plus} />
                  <span className='button-title'>Добавить</span>
                </Button>
              ) : null}
              <div className="buttons-element">
                {this._renderDownloadButton()}
              </div>
              {fileRights.CREATE && (
                <div className="buttons-element">
                  <Button 
                    tooltip={'Редактировать'}
                    type='image'
                    onClick={this._onTypeEditDialog}
                    disabled={items.length !== 1}
                  >
                    <Img img={Image.Edit} />
                  </Button>
                </div>
              )}
              {fileRights.UPDATE ? (
                <div className="buttons-element">
                  <Button 
                    tooltip={'Сделать основным'}
                    type='image'
                    onClick={this._onSetMainType}
                    disabled={makeMainDisabled}
                  >
                    <Img img={Image.Main} className='button-image' />
                  </Button>
                </div>
              ) : null}
              {fileRights.DELETE ? (
                <div className="buttons-element">
                  <Button 
                    tooltip={'Удалить'}
                    type='image'
                    onClick={this._onTypeDeleteConfirm}
                    disabled={!items.length}
                  >
                    <Img img={Image.Delete} />
                  </Button>
                </div>
              ) : null}
            </div>    
            <TableWithLogic
              serviceName='versionService'
              methodName='getAllBuildTypes'
              apiPayload={{skziId, versionId, buildId}}
              mapResultFunc = {i => ({
                ...i,
                id: i.objectType ? i.objectType.id : 0
              })}
              defaultSort={{ 
                column: 'main',
                direction: sortDirection.desc
              }}
              columns={buildColumns}
              setSelected={this._onSelect}
              changedRows = {changed}
              onListChanged={this._onDataChanged}
              selectType={SelectType.multiple}
            />      
          </ExpandingBlock>
        )}
      </section>
    )
  }

  _getBuildKey = (data) => {
    const { name, description, classId, certificateExpirationDate, certificate } = data
    const { id } = classId || {}

    const canEdit = `${name}${description}${id}${certificate}${formatDate(certificateExpirationDate, 'yyyy-mm-dd')}`

    return canEdit
  }

  _renderFileDialog = () => {
    const { params, skziBuildCard, skziBuildCardResetAction } = this.props
    const { skziId, versionId, buildId } = params
    const { selected } = skziBuildCard
    const [ selectedType ] = selected.items
    const { id } = selectedType.objectType || {}
    const urlPart = `skzi/${skziId}/version/${versionId}/build/${buildId}/file`
    return (
      <ModalFileDialog
        onCancel={skziBuildCardResetAction}
        id={id}
        urlPart={urlPart}
        onFileUploaded={this._fileUploaded}
      >
        <Input
          value={selectedType && selectedType.objectType && selectedType.objectType.name}
          label='Тип элемента'
          disabled={true}
        />
      </ModalFileDialog>
    )
  }

  _renderTypeDialog = () => {
    const { skziBuildCard: { selected: { items } }, params, skziBuildCardResetAction } = this.props
    const { skziId, versionId, buildId } = params
    const { allowedTypes, currentTypes, addTypeDialogMode } = this.state

    return (
      <ModalTypeDialog
        mode={addTypeDialogMode}
        typeToEdit={addTypeDialogMode === Mode.Edit && items && items.length === 1 ? items[0] : null }
        forceShow={true}
        onCancel={skziBuildCardResetAction}
        types={allowedTypes}
        isFirstType={currentTypes && !currentTypes.length}
        currentTypes={currentTypes}
        buildParams={{skziId, versionId, buildId}}
        onFileUploaded = {skziBuildCardResetAction}
        onTypeAdd={this._onTypeAdd}
        onTypeAddFinish={this._fileUploaded}
        onFileDeleteFinish={this._fileDeleted}
        onFileUpdateFinish={this._fileUpdated}
      />
    )
  }

  _renderModal = () => {
    const { skziBuildCard, skziBuildCardResetAction } = this.props
    const { confirmObject, addTypeDialog, addFilesDialog, deleteTypeConfirm } = skziBuildCard

    return (
      <Fragment>
        {deleteTypeConfirm ? 
          <ConfirmDialog
            {...deleteTypeConfirm}
            onSubmit={this._onRunTypeDelete}
            onCancel={skziBuildCardResetAction}
          /> : null
        }
        {addTypeDialog ? (          
          this._renderTypeDialog()
        ) : null}
        {addFilesDialog ? ( // TODO refactor, remove addFilesDialog, use only addTypeDialog
          this._renderFileDialog()
        ) : null}
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onCancel={skziBuildCardResetAction}
          /> : null
        }
      </Fragment>
    )
  }

  render() {
    const { skziBuildCard } = this.props
    const { inProgress } = skziBuildCard

    return (
      <section className='skzi-build-card'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <BreadcrumbsHeader />     
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...skziBuildCardAction
  })(withAuth(withRoutingProps(SkziCard, ['params', 'location'])))