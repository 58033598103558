import { capitalize } from 'app/core/utility/common'

export default function useAuthenticationForm({formData, serverFormViewModel, errorByFields}){

  function getFormDataValue(fieldName){
    const capitalizeKey = capitalize(fieldName)
    const lineViewModel = serverFormViewModel ? serverFormViewModel[capitalizeKey] : null
    return formData[lineViewModel ? capitalizeKey : fieldName]
  }

  function getError(fieldName){
    return errorByFields[capitalize(fieldName)]
  }

  return {
    getError,
    getFormDataValue,
  }
}