import { 
  ERROR, 
  WARNING,
  CLEARERROR, 
  FIELDSERROR,
  ERRORLIST,
  ERRORPAGEERROR,
  ERRORPAGECLEARERROR 
} from './actionTypes'
import {addToast} from './toast'
import {texts} from '../../app/components/infoPages/ErrorPage'


export const errorAction = data => dispatch => {
  const { errors = [], isError } = data

  if (errors.length || isError ) {
    dispatchLogic(dispatch, ERROR, data, true, errors)
  }
}

export const errorListAction = data => {
  return {
    type: ERRORLIST,
    payload: data
  }
}

export const setErrorByKeyFormAction = (errors) => {
  return {
    type: FIELDSERROR,
    errors: errors,
    payload: null
  }
}

export const clearErrorFormAction = () => {
  return {
    type: CLEARERROR,
    payload: null
  }
}

export const setErrorByKey = (errors) => dispatch => {
  dispatch({
    type: FIELDSERROR,
    errors: errors,
    payload: null
  })
}

export const clearErrorAction = () => dispatch => {
  dispatch({
    type: CLEARERROR,
    payload: null
  })
}

export const cleanErrorPageError = () => dispatch => {
  dispatch({
    type: ERRORPAGECLEARERROR,
    payload: null
  })
}

export const setErrorPageError = (response) => dispatch => {
  dispatch({
    type: ERRORPAGEERROR,
    payload: response
  })
}

// entry point for all responses
//  - redux store manipulation logic which depends on response
export const dispatchLogic = (dispatch, type, data, isError, errors, isWarn) => {
  const isWarning = isWarn || (errors && errors[0] && !errors[0].isError)
  const isGlobalError = errors && errors[0] && errors[0].globalError
  if (!isGlobalError && isWarning) {
    return dispatch({
      type: WARNING,
      payload: errors[0]
    })
  }

  if (errors && errors[0]) {
    const { field, globalError } = errors[0]
    let actionType = ERROR

    if (globalError) {
      return dispatch({
        type: ERRORPAGEERROR,
        payload: errors[0]
      })
    }
    
    // backend return "fieldName" for several requests
    // we need to show modal error instead of mapping to controls
    if (field) {
      switch (field) {
        case 'SortBy':
        case 'JournalActionDataId':
        case "JournalObjects":
        case 'Signature':
        case 'SerialNumbers':
          actionType = ERROR
          break
        default:
          actionType = FIELDSERROR
          break
      }
    }

    return dispatch(
      {
        type: actionType,
        errors: errors,
        payload: data
      }
    )
  } else {
    return dispatch(
      {
        type: type,
        payload: data,
        errors: errors
      }
    )
  }
}

export const dispatchLogicToasts = (dispatch, type, data, isError, errors, isWarn) => {
  if (!data && errors) {
    if (isError) {
      const serverError = errors && errors[0] && errors[0].detail
        ? errors[0].detail.response && errors[0].detail.response.errors && errors[0].detail.response.errors[0]
        : errors && errors[0]
      const { errorMessage } = serverError || { errorMessage : "" }
      return dispatch(addToast(`${texts.serverError}\r\n${errorMessage}`))
    }

    return false
  }
  return true
}