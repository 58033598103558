import React from 'react'
import { ORG_CARD_TYPE, OKI_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatKeyDoc, organizationName } from 'app/components/list/Table/TableBody';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterElement';
import { blockedColumn } from 'app/components/list/Table/columns';

export const OkiConfig = {
  fieldsConfig : [
    { 
      alias : 'bizLocked',
      format : blockedColumn,
      visibleOnStart : true, 
      visibleAlways : true,
      width : 50 
    },
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name', 
      format : item => formatKeyDoc( item ) ,
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true,
    },
    { 
      title : 'Серийный №', 
      alias : 'serialNumber', 
      format : item => item.serialNumber ,
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true, 
      width : 200
    },
    { 
      title : '№ экземпляра', 
      alias : 'identificationNumber', 
      format : item => item.identificationNumber || '',
      visibleOnStart : true, 
      useSorting : true, 
      width : 200
    },
    { 
      title : 'Срок действия',
      alias : 'expirationDate', 
      format : item => formatDate(item.expirationDate, 'dd.mm.yyyy'),
      visibleOnStart : true, 
      useSorting : true, 
      width : 200
    },
    { 
      title : 'Получено от',
      alias : 'receivedFrom', 
      format : item => item.receivedFrom || '',
      visibleOnStart : true, 
      useSorting : true, 
      width : 230
    },
    { 
      title : 'Состояние',
      alias : 'certState', 
      format : item => {
        const { certState } = item
        const { name } = certState || {}
        return name || ''
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 230
    },
    { 
      title : 'Статус', 
      alias : 'status', 
      format : item => item.status && item.status.name,
      visibleOnStart : true, 
      useSorting : true, 
      width : 200
    },
    {
      title : 'Выдано',
      alias : 'eskziUser', 
      format : item => {
        return item.eskziUser
            ? <ListLink
                text={formatFIO(item.eskziUser)}
                targetType={USER_ACCOUNT_CARD_TYPE}
                id={item.eskziUser.id}
            />
            : formatFIO(item.eskziUser)}
      ,
      visibleOnStart : true,
      useSorting : true, 
      width : 270
    },
    {
      title : 'Дата изменения',
      alias : 'updateDate', 
      format : item => formatDate(item.updateDate, 'dd.mm.yyyy'),
      visibleOnStart : true,
      useSorting : true, 
      width : 200
    },
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'serialNumbers', 
      label : 'Серийный №',
      extended : false,
      order : 3,
      requestFormatIsArray : true,
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'statuses', 
      label : 'Статус', 
      serviceType : 'instanceStatuses',
      extended : false,
      order : 4,
      autosearch: true
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'certState', 
      label : 'Состояние', 
      serviceType : 'keyDocCertStates',
      extended : true,
      order : 5,
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'receivedFrom', 
      label : 'Получено от',
      extended : true,
      order : 6,
    },   
    {
      type : FilterControlType.autocompletePicker, 
      alias : 'eskziUserId', 
      label : 'Выдано', 
      serviceType : 'skziUser',  
      multiSelect : false,
      extended : true,
      order : 7,
      dependencies: [{
        alias: 'globalOrgId',
        targetProps: {
          notStandardService: (depVal, initVal) => ({
            serviceName: 'skziUserService',
            serviceMethod: 'autocomplete',
            data: { organizationId: depVal },
          }),
          notStandardPickerService: (depVal, initVal) => ({
            serviceName: 'skziUserService',
            serviceMethod: 'picker',
            data: { organizationId: depVal },
          }),
        },
      }],
    },
    {
      type : FilterControlType.dateRangePicker, 
      alias : 'expirationDate', 
      label : 'Срок действия',
      extended : true,
      order : 8,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'registerDate', 
      label : 'Зарегистрирован',
      extended : true,
      order : 9,
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'isRecorded', 
      label : 'Связь с носителем', 
      extended : true,
      order : 10,
      options: [
        { id: 'true', title: 'Записан на носитель'},
        { id: 'false', title: 'Без носителя'}
      ]
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'names', 
      label : 'Наименование',
      extended : true,
      requestFormatIsArray : true,
      order : 11,
    },
    { 
      type : FilterControlType.checkbox, 
      alias : 'isHardwareInstalled', 
      label : 'Введен в аппаратный СКЗИ',
      extended : true,
      order : 12,
    },
    {
      type: FilterControlType.unselectAllMultiselect,
      alias: 'certificate',
      label: 'Источник',
      notStandardService: {
        serviceName: 'certificateService',
        serviceMethod: 'certificateSources',
      },
      unselectAllData: {
        name: 'Без сертификата',
        id: 'noCert'
      },
      requestValueMapper: (data) => {
        return {
          sources: data?.unselectAllValue?.value ? [] : data?.items,
          // включенный чек-бокс - нужно выбрать все КД без сертификата.
          // выключенный - выбираются все, с сертификатом и без
          // поэтому value ? false : null
          certificateExists: data?.unselectAllValue?.value ? false : null
        }
      },
      extended: true,
      order: 13,
    },
  ]
}

export const OkzConfig = {
  fieldsConfig : [
    { 
      alias : 'bizLocked',
      format : blockedColumn,
      visibleOnStart : true, 
      visibleAlways : true,
      width : 50 
    },
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name', 
      format : item => formatKeyDoc( item ) ,
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true,
    },
    { 
      title : 'Серийный №', 
      alias : 'serialNumber', 
      format : item => item.serialNumber ,
      visibleOnStart : true, 
      visibleAlways : true,
      useSorting : true, 
      width : 200
    },
    {
      title : '№ экземпляра', 
      alias : 'identificationNumber', 
      format : item => item.identificationNumber || '',
      visibleOnStart : true, 
      useSorting : true, 
      width : 200
    },
    { 
      title : 'Срок действия',
      alias : 'expirationDate', 
      format : item => formatDate(item.expirationDate, 'dd.mm.yyyy'),
      visibleOnStart : true, 
      useSorting : true, 
      width : 200
    },
    { 
      title : 'Получено от',
      alias : 'receivedFrom', 
      format : item => item.receivedFrom || '',
      visibleOnStart : true, 
      useSorting : true, 
      width : 250
    },
    { 
      title : 'Состояние',
      alias : 'certState', 
      format : item => {
        const { certState } = item
        const { name } = certState || {}
        return name || ''
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 230
    },
    {
      title : 'Статус',
      alias : 'status',
      format : item => item.status && item.status.name,
      visibleOnStart : true,
      useSorting : true,
      width : 200
    },
    { 
      title : 'Передано в',
      alias : 'sentTo',
      format : item => {
        const name = organizationName( item.sentTo )
        const { id, okz } = item.sentTo || {}
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
        return name && id ? <ListLink
          text={name}
          targetType={targetType}
          id={id}
        /> : name || ''
      },
      visibleOnStart : true, 
      useSorting : true, 
      width : 250
    },
    {
      title : 'Дата изменения',
      alias : 'updateDate', 
      format : item => formatDate(item.updateDate, 'dd.mm.yyyy'),
      visibleOnStart : true,
      useSorting : true, 
      width : 200
    },
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'serialNumbers', 
      label : 'Серийный №',
      extended : false,
      order : 3,
      requestFormatIsArray : true,
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'statuses', 
      label : 'Статус', 
      serviceType : 'instanceStatuses',
      extended : false,
      order : 4,
      autosearch: true
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'certState', 
      label : 'Состояние', 
      serviceType : 'keyDocCertStates',
      extended : true,
      order : 5,
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'receivedFrom', 
      label : 'Получено от',
      extended : true,
      order : 6,
    },   
    {
      type : FilterControlType.autocomplete, 
      alias : 'sentTo', 
      label : 'Передано в', 
      serviceType : 'organizations',  
      multiSelect : false,
      extended : true,
      order : 7,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'expirationDate', 
      label : 'Срок действия',
      extended : true,
      order : 8,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'registerDate', 
      label : 'Зарегистрирован',
      extended : true,
      order : 9,
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'isRecorded', 
      label : 'Связь с носителем', 
      extended : true,
      order : 10,
      options: [
        { id: 'true', title: 'Записан на носитель'},
        { id: 'false', title: 'Без носителя'}
      ]
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'names', 
      label : 'Наименование',
      extended : true,
      requestFormatIsArray : true,
      order : 11,
    },
    { 
      type : FilterControlType.checkbox, 
      alias : 'isHardwareInstalled', 
      label : 'Введен в аппаратный СКЗИ',
      extended : true,
      order : 12,
    },
    {
      type: FilterControlType.unselectAllMultiselect,
      alias: 'certificate',
      label: 'Источник',
      notStandardService: {
        serviceName: 'certificateService',
        serviceMethod: 'certificateSources',
      },
      unselectAllData: {
        name: 'Без сертификата',
        id: 'noCert'
      },
      requestValueMapper: (data) => {
        return {
          sources: data?.unselectAllValue?.value ? [] : data?.items,
          // включенный чек-бокс - нужно выбрать все КД без сертификата.
          // выключенный - выбираются все, с сертификатом и без
          // поэтому value ? false : null
          certificateExists: data?.unselectAllValue?.value ? false : null
        }
      },
      extended: true,
      order: 13,
    },
  ]
}