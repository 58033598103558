import { useState } from 'react'
import { connect } from 'react-redux'


import MainCard, { cardMode, objectKey } from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import Overlay from 'app/components/ui/overlay'

import { cardLines } from '../userAccountCard/userAccountData'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'

import * as mainCardAction from 'redux/actions/card/cardActions'
import { breadcrumbsPopAction } from 'redux/actions/common/breadcrumbsAction'
import * as errorActions from 'redux/actions/common';
import { useHistory } from 'react-router-dom'
import { formatDate } from 'app/core/utility/date'

export const getUserAccountKey =  (userAccountData) => {
  const {
    lastName = '',
    firstName = '',
    middleName = '',
    snils = '',
    accountCreateDate = '',
    organization = '',
    comments = '',
    position = '',
    id = '',
    status = '',
  } = userAccountData || {};
  const { id: orgId = '' } = organization || {};

  return {
    lastName: lastName,
    firstName: firstName,
    middleName: middleName,
    snils: snils,
    organizationId: orgId,
    position: position,
    accountCreateDate: formatDate(accountCreateDate, 'yyyy-mm-dd'),
    comments: comments,
    id: id,
    status: status,
  }
}

export const cardConfig = ({ 
    history,
    registerUserAccountAction,
    initUserAccountCreate,
    breadcrumbsPopAction,
  }) => ({
  cardLines: cardLines,
  resource: RESOURCES.userAccount,
  getKey: data => objectKey(data, getUserAccountKey),
  signatureHeaderText: 'Создание лицевого счёта',
  signatureServiceName: '',
  oldSignatureService: true,
  updateAction: registerUserAccountAction,
  cardInitAction: initUserAccountCreate,
  onCancel: () => {
    history.push('/userAccounts');
  },
  afterUpdate: (requestResult) => {
    const { payload, type } = requestResult || {}
    const { id } = payload || {}
    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction()  
      history.push(`/userAccounts/card/${id}`)
    }
  },
  getSignatureData: getUserAccountKey,
  pageBreadcrumbTitle: () => null,
})

const CreateCard = (props) => {
  const {
    registerUserAccountAction,
    initUserAccountCreate,
    breadcrumbsPopAction,
    cardInProgress,
  } = props
  const history = useHistory();

  const [ activeTab, setActiveTab ] = useState('Информация')

  return (
    <section className='main-card user-account-card'>
      {cardInProgress ? <Overlay /> : null}
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={setActiveTab}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              config={cardConfig({
                history,
                registerUserAccountAction,
                initUserAccountCreate,
                breadcrumbsPopAction,
              })}
              cardMode={cardMode.create}
              editOnOpen={true}
            />
          </ExpandingBlock>
        </section>
        <div
          tabTitle={'Экземпляры СКЗИ'}
          disabled={true}
        />
        <div
          tabTitle={'Ключевые документы'}
          disabled={true}
        />
        <div
          tabTitle={'Ключевые носители'}
          disabled={true}
        />
        <div
          tabTitle={'Допуск к СКЗИ'}
          disabled={true}
        />
        <div
          tabTitle={'История'}
          disabled={true}
        />
      </Tabs>
    </section>
  )
}

const mapStateToProps = state => {
  const { card, profile } = state

  return {
    ...card,
    profile: profile
  }
}

export default connect(
  mapStateToProps,
  { 
    ...mainCardAction,
    ...errorActions,
    breadcrumbsPopAction,
  })(CreateCard)