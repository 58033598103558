import {useEffect, useState} from 'react'
import {notEmpty} from '../../../core/utility/validation'
import {getValueForSelect} from '../formData/selectValues'
import service from '../../../services/service'
import {authenticationValidationRules} from '../formData/formValidationRules'
import {defaultAuthenticationFormState} from '../formData/formStates'

export default function useAuthenticationBlock(setAuthenticationDataValid) {

  const [testConnectionInfo, setTestConnectionInfo] = useState({
    inProgress: false,
    text: null,
  })

  const [editStateBlock, setEditStateBlock] = useState(true)

  const formData = {
    validationRules: authenticationValidationRules,
    defaultState: defaultAuthenticationFormState
  }

  const get = () => service('activeDirectorySettingsService', 'getConnectionSettings')
    .then(reformatData)
    .then(checkValidAuthenticationDataCallback)

  const update = (data) => {
    const reformatData = {
      ...data,
      refreshPeriod: data.refreshPeriod ? data.refreshPeriod.value : null,
      dropSessionPeriod: data.dropSessionPeriod ? data.dropSessionPeriod.value : null
    }
    return service('activeDirectorySettingsService', 'updateConnectionSettings', reformatData)
  }

  const testConnection = (data) => service('activeDirectorySettingsService', 'testConnection', {
    serverAddress: data.serverAddress,
    serverPort: data.serverPort,
    serverAccount: data.serverAccount,
    serverPassword: data.serverPassword,
    searchBase: data.searchBase,
  })

  const checkValidAuthenticationDataCallback = (response) => {
    const {errors, isWarn} = response
    if(isWarn && errors && errors[0] && errors[0].errorCode === 'W_EMPTY_DATA'){
      return response
    }
    setAuthenticationDataValid(true)
    setEditStateBlock(false)
    return response
  }

  const checkValidResponse = (response) => {
    const {data, errors, isError} = response
    if (data && !errors && !isError) {
      setAuthenticationDataValid(true)
      setEditStateBlock(false)
    }
    return response
  }

  const reformatData = (response) => {
    const {data, isError} = response
    if (data && !isError) {

      const formattedData = {
        ...data,
        refreshPeriod: getValueForSelect(data.refreshPeriod, 'refreshPeriod'),
        dropSessionPeriod: getValueForSelect(data.dropSessionPeriod, 'dropSessionPeriod')
      }
      return {
        ...response,
        data: formattedData
      }
    }
    return response
  }


  return {
    editStateBlock,
    testConnectionInfo,
    formData,
    setTestConnectionInfo,
    setEditStateBlock,
    checkValidResponse,
    get,
    update,
    testConnection
  }
}
