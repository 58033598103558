import React from 'react';
import {Table} from 'app/components/list/Table/Table';
import { sortDirection } from 'app/components/ui/constants';
import { ReactComponent as KeyDocumentImg }  from 'assets/img/menu/ic_keydocument.svg';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import Overlay from 'app/components/ui/overlay';
import { useJournal } from '../useJournal';
import TechnicalJournalActionPanel from './TechnicalJournalActionPanel';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import FilterTriggerPanel from "app/components/filter/FilterTriggerPanel";
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from "app/components/ui/MainListContainer/MainListContainer";
import styles from "./list.module.scss";
import Filter, { GlobalOrgType } from "app/components/filter";
import { Border, Group, ManagePanel } from 'app/components/ui/managePanel';
import PageHeader from 'app/components/PageHeader/pageHeader';
import { config } from './pageConfig';

// следует указать на работу апи - в данных с сервера у записи
// есть number, который мы используем как id для уникальности записей
// в таблице, а есть maintenanceRecordId, который есть не у всех записей
// Для удаления надо использовать maintenanceRecordId, для выгрузки в эксель
// number. Потому и проблемы при высчитывании выбранных элементов после 
// удаления - мы удаляем по maintenanceRecordId, а обновляем выбранные по 
// number. Для редактирования та же ерунда - редактируем по 
// maintenanceRecordId
 
export default function TechnicalJournal() {
  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    onClearFilter,

    // useReducerTable
    onInProgress,
    sortListAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,
    updateAfterMassOperation,

    // global
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
    filterOpened,
    toggleFilterState,
  } = useJournal({
    defaultSort: {
      column: 'number',
      direction: sortDirection.desc,
    },
    serviceName: 'technicalJournalReportService',
  });

  return(
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title='Журналы учета: Технический (аппаратный)' Img={KeyDocumentImg} />
      <ManagePanel borders={Border.All}>
        <TechnicalJournalActionPanel
          getList = {updateAfterMassOperation}
          filterForRequest = {filterForRequest}
          sorting = {sorting}
          fieldsVisible = {fieldsVisible}
          items = {selectedObjects}
          globalOrgId = {globalOrgId}
          inProgress = {inProgress}
          setProgress = {onInProgress}
          setSelectedAction = {setSelectedAction}
          setSelectedPagination={setSelectedPagination}
        />
        <Group separate>
          <FilterTriggerPanel
            toggle={toggleFilterState}
            filterFilled={filterFilled}
            onClearFilter={onClearFilter}
            disabledClear={!filterState.length}
          />
        </Group>
        <Group separate>
          <TableFieldSelect
            listFieldsVisible={fieldsVisible}
            setVisibleFieldsAction={setVisibleFieldsAction}
            toggleVisibleFieldAction={toggleVisibleFieldAction}
            headerInfo={true}
            fieldsConfig={config.fieldsConfig}
            changedFieldsVisibility={true}
            settingsAsGear={true}
          />
        </Group>
      </ManagePanel>
      <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.fourRowFilter}
      >
        <Filter
          className={styles.filter}
          inProgress={inProgress}
          setGlobalOrg={setGlobalOrg}
          globalOrganization={globalOrganization}
          setFilterStateAction={setFilterStateAction}
          setFilterForRequestAction={setFilterForRequestAction}
          setFullFilterStateAction={setFullFilterStateAction}
          filterState={filterState}
          filterFilled={filterFilled}
          profile={profile}
          globalOrgId={globalOrgId}
          filtersConfig={config.tablePageFiltersConfig}
          isOpened={filterOpened}
          toggleFilterState={toggleFilterState}
          standartService
          withGlobalOrg
          globalOrgType={GlobalOrgType.okiOnly}
        />
        <Table
          {...{
            totalCount,
            list,
            sortListAction,
            sorting,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedPagination,
            inProgress,
          }}
          listFieldsVisible={fieldsVisible}
          fieldsConfig={config.fieldsConfig}
          itemsSelectable
          changedFieldsVisibility
          onItemClick= { item => { return `/skziAccounting/EskziCard/${ item.eskzi.id }?tab=maintenance` } }
          headerInfo
        />
      </FilterWithTableContainer>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageCount={pageCount}
        perPage={perPage}
      />
    </MainListContainer>
  );
}