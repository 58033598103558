import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  TRAININGCOURSESCREATE,
  SIGNATUREREADYTOSIGN,
  TRAININGCOURSESCREATEUPDATEFORMDATA,
  TRAININGCOURSESCREATEINPROGRESS    ,
  TRAININGCOURSESCREATERESETACTION   ,
  TRAININGCOURSESCREATECLEARFORM  
} from '../../actions/actionTypes'

const defaultState = {
  formData : {
    isActive: { id: 'Yes', title: 'Да'},
    name: '',
  },
  errorByFields: {},
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { formData } = state
    
  switch (action.type) {

    case TRAININGCOURSESCREATE:
      return {
        ...state,
        inProgress: false
      }
        
    case SIGNATUREREADYTOSIGN:
    case FIELDSERROR:
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData
      }

    case TRAININGCOURSESCREATEUPDATEFORMDATA: 
      const { key, value } = payload

      return {
        ...state,
        formData: {...formData, [key]: value }
      }

    case TRAININGCOURSESCREATECLEARFORM: 
      return {
        ...defaultState
      }

    case TRAININGCOURSESCREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case TRAININGCOURSESCREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        registeredInfoObject: null,
        error: null
      }

    default: 
      return state
  }
}