
export function getValueForSelect(value, type){
  switch (type){
    case 'refreshPeriod':
      return frequencyValues.find(el=> el.value === value)
    case 'dropSessionPeriod':
      return resetValues.find(el=> el.value === value)
  }
}

export const frequencyValues = [
  {
    id: 1,
    title: '10 мин',
    value: "00:10:00"
  },
  {
    id: 2,
    title: '30 мин',
    value: "00:30:00"
  },
  {
    id: 3,
    title: '1 час',
    value: "01:00:00"
  },
  {
    id: 4,
    title: '2 часа',
    value: "02:00:00"
  },
  {
    id: 5,
    title: '4 часа',
    value: "04:00:00"
  },
  {
    id: 6,
    title: '8 часов',
    value: "08:00:00"
  },
  {
    id: 7,
    title: '24 часа',
    value: "1.00:00:00"
  },
  {
    id: 8,
    title: '3 дня',
    value: "3.00:00:00"
  },{
    id: 9,
    title: '1 неделя',
    value: "7.00:00:00"
  },
]

export const resetValues = [
  {
    id: 1,
    title: '5 мин',
    value: "00:05:00"
  },
  {
    id: 2,
    title: '10 мин',
    value: "00:10:00"
  },
  {
    id: 3,
    title: '15 мин',
    value: "00:15:00"
  },
  {
    id: 4,
    title: '30 мин',
    value: "00:30:00"
  },
  {
    id: 5,
    title: '1 час',
    value: "01:00:00"
  }
]