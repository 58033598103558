import { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import { cardLines } from '../createCourse/createCourseForm'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import ButtonComponent from 'app/components/ui/button/button'
import TagComponent from 'app/components/ui/tag'
import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as AddFilesImg } from 'assets/img/commonVer2/clip.svg'

import { arrayToIdHashMap, capitalize, serverlikeErrors } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import AddFileDialog from './addFilesDialog'
import { itemActions, sortDirection } from 'app/components/ui/constants'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import Img, { Image } from 'app/components/ui/Img'
import withAuth from 'app/components/HOC/AuthHOC'
import withModal from 'app/components/HOC/ObsoleteModalHOC'
import withTooltip from 'app/components/HOC/TooltipHOC'

import * as errorAction from 'redux/actions/common'
import * as courseCardAction from 'redux/actions/trainingCourses/courseCardAction'
import withRoutingProps from 'app/components/HOC/RoutingPropsHOC'
import { SelectType } from 'app/components/ui/picker/picker'
import TableWithLogic from 'app/components/list/Table/tableWithLogic'

const Button = withTooltip(ButtonComponent)
const ModalFileDialog = withModal(AddFileDialog)
const Tag = withTooltip(TagComponent)

class CourseCard extends PureComponent {
  constructor(props){
    super(props)

    const { params, profile } = props
    const { id: courseId } = params

    const courseRights = getResourceRights(profile, RESOURCES.course)

    this.state = { 
      tabTitle: 'Информация',
      sort: { 
        column: 'main',
        direction: sortDirection.desc
      },
      typeColumns: [
        {
          title: 'Тип',
          alias: 'name',
          useSorting: true,
          width: 350,
        },
        {
          title: 'Доступен для пользователя СКЗИ',
          alias: 'userAvailable',
          useSorting: true,
          width: 350,
          format: item => {
            const { userAvailable } = item
            
            return userAvailable ? 'Да' : 'Нет'
          }
        },
        {
          title: 'Дата добавления',
          alias: 'createDate',
          useSorting: true,
          width: 240,
          format: item => {
            const { createDate } = item
            
            return formatDate(createDate, 'dd.mm.yyyy')
          }
        },
        {
          title: 'Вложения',
          alias: 'documents',
          useSorting: false,
          format: rowData => {
            const { files, id: contentId } = rowData
            const { courseCard } = this.props
            const { currentlyDeletingFiles } = courseCard
            const urlPart = `api/v1/course/${courseId}/materials/${contentId}/file`

            return (
              <div className="type-files">
                {files.map((item) => { 
                  return currentlyDeletingFiles[item.guid] ? (
                    <Tag 
                      key={item.guid} 
                      className='deleting-file-tag'
                      text={'Удаление...'}
                      tooltip={item.name} 
                      item={item} 
                    />
                  ) : (
                    <Tag 
                      key={item.guid} 
                      text={item.name}
                      tooltip={item.name} 
                      link={`${urlPart}/${item.guid}`}
                      item={item} 
                      onDelete={courseRights.UPDATE ? () => this._onFileDelete({file: item, typeElement: rowData}) : null}
                    />
                  )
                }
                )}
              </div>
            )
          }
        },
      ]
    }
  }

  componentDidMount() {
    const { courseCard, getCourseAction, courseCardInProgress, params, location} = this.props
    const { pathname } = location
    const { id } = params
    const { inProgress } = courseCard

    if (inProgress) {
      return
    }
   
    courseCardInProgress(true)
    getCourseAction({ id, pathname})
  }

  componentWillUnmount () {
    const { 
      courseCardResetAction, 
      clearErrorAction,
      courseCardOnSelect
    } = this.props

    courseCardOnSelect()
    courseCardResetAction()
    clearErrorAction()
  }

  _onSubmit = () => {
    const { courseCard, courseCardUpdateAction, courseCardInProgress, location } = this.props
    const { pathname } = location
    const { commonDataForm, courseData } = courseCard
    const { isActive, name } = commonDataForm

    courseCardInProgress(true)
    courseCardUpdateAction({
      id: courseData.id,
      isActive, 
      name,
      pathname,
    })
  }

  _onCancel = () => {
    const { clearErrorAction, courseCardResetAction } = this.props

    courseCardResetAction()
    clearErrorAction()
  }

  _applyRightsToActions = () => {
    const { profile } = this.props
    const actions = []
    const courseRights = getResourceRights(profile, RESOURCES.course)

    courseRights.UPDATE && actions.push(itemActions.edit.key)
    courseRights.DELETE && actions.push(itemActions.delete.key)

    return actions
  }

  _onFileDelete = ({file, typeElement}) => {

    const { courseCardDeleteFile, params } = this.props
    const { id: courseId } = params
    const { guid } = file
    const { id: contentId } = typeElement

    courseCardDeleteFile({ courseId, guid, contentId, typeElement })
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { courseCard, courseCardUpdateCommonForm, setErrorByKey, error } = this.props
    const { commonDataForm } = courseCard
    const { errorByFields }  = error || {}
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    
    setErrorByKey(filteredErrors)
    courseCardUpdateCommonForm({ ...commonDataForm, [key]: value })
  }

  _onSort = ({column, direction}) => {
    const { courseCardInProgress, courseCardGetTypesAction, params } = this.props
    const { id: courseId } = params

    this.setState({ sort: { column, direction }}, () => {
      courseCardInProgress(true);
      courseCardGetTypesAction({courseId, column, direction })
    })
  }

  _onSelect = selectedData => {
    const { courseCardOnSelect } = this.props
    courseCardOnSelect({
      items: selectedData,
      selectedHashMap: arrayToIdHashMap(selectedData)
    })
  }

  _onTypeDeleteConfirm = () => {
    const { courseCardOnTypeDelete, courseCard } = this.props
    const { selected } = courseCard
    const { items } = selected

    courseCardOnTypeDelete(items)
  }

  _onRunTypeDelete = async () => {
    const { courseCard, courseCardRemoveTypesAction, courseCardInProgress, params } = this.props
    const { selected } = courseCard
    const { items } = selected
    const { id: courseId } = params

    courseCardInProgress(true)
    courseCardRemoveTypesAction({ courseId, types: items.map(item => item.id)})
  }

  _onFilesAddDialog = () => {
    const { courseCardOnAddFilesAction } = this.props

    courseCardOnAddFilesAction()
  }

  _onTypeAddDialog = () => {
    const { courseCardOnAddTypeAction, courseCardInProgress } = this.props

    courseCardInProgress(true)
    courseCardOnAddTypeAction()
  }

  _onTypeAdd = async ({userAvailable, name, hasFiles}) => {
    const { 
      courseCardResetAction,
      courseCardInProgress,
      courseCardAddTypeAction, 
      params, 
    } = this.props
    const { id: courseId } = params

    courseCardInProgress(true)
    const result = await courseCardAddTypeAction({courseId, userAvailable, name})
    !hasFiles && courseCardResetAction()
    return result
  }

  _refreshTypesData = () => {
    const { courseCardInProgress, courseCardGetTypesAction, params } = this.props
    const {sort: { column, direction }} = this.state
    const { id: courseId } = params

    courseCardInProgress(true)
    courseCardGetTypesAction({ courseId, column, direction})
  }

  _renderDownloadButton = () => {
    const { courseCard, params } = this.props
    const { selected } = courseCard
    const { items } = selected
    const { id: courseId } = params
    const canDownload = items.length && items.find(item => item.files && item.files.length)

    const dowloadUrl = `api/v1/course/${courseId}/materials/download?id=${items.map(item => item.id).join('&id=')}`

    return (
      <Button 
        tooltip={'Скачать файлы'}
        type='image'
        disabled={!canDownload}
        href={canDownload ? dowloadUrl : ''}
      >
        <Img img={Image.Download} />
      </Button>
    )
  }

  _renderData = () => {
    const { courseCard, profile, error } = this.props
    const { fieldsError } = error
    const { typeColumns  } = this.state
    const { courseData, selected, viewData, commonDataForm } = courseCard
    const { items } = selected
    const { materials = [] } = courseData
    const courseMaterialsRights = getResourceRights(profile, RESOURCES.courseMaterials)
    const courseRights = getResourceRights(profile, RESOURCES.course)

    return (
      <section className='course-common-data'>
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты'}
          initialState={true}
        >
          <Card
            className='course-data-card'
            profile={profile}
            formLines={cardLines}
            actions={this._applyRightsToActions()}
            canEdit={courseRights.UPDATE}
            key={this._getCourseKey(viewData)}
            data={viewData}
            formData={commonDataForm}
            onValidationError={this._onValidation}
            errorByFields={fieldsError}
            onChange={this._onFormChange}
            onSubmit={this._onSubmit}
            onCancel={this._onCancel}
            getObjectKeyFunc={this._getCourseKey}
          />
        </ExpandingBlock>
        <ExpandingBlock
          className='course-types'
          renderHeader= {() => 'Вложения'}
          initialState={true}
        >
          <div className="course-types manage-panel">
            {courseRights.CREATE ? (
              <Button 
                className='create-button'
                type='primary'
                onClick={this._onTypeAddDialog}
              >
                <PlusImg className='button-image button-image--left'></PlusImg>
                <span className='button-title'>Добавить</span>
              </Button>
            ) : null}
            <div className="buttons-element">
              {this._renderDownloadButton()}
            </div>
            {courseMaterialsRights.UPDATE ? (
              <div className="buttons-element">
                <Button 
                  tooltip={'Добавить файлы'}
                  type='image'
                  onClick={this._onFilesAddDialog}
                  disabled={items.length !== 1}
                >
                  <AddFilesImg className='button-image'></AddFilesImg>
                </Button>
              </div>
            ) : null}
            {courseMaterialsRights.DELETE ? (
              <div className="buttons-element">
                <Button 
                  tooltip={'Удалить'}
                  type='image'
                  onClick={this._onTypeDeleteConfirm}
                  disabled={!items.length}
                >
                  <DeleteImg className='button-image'></DeleteImg>
                </Button>
              </div>
            ) : null}
          </div>
          <TableWithLogic
            list={materials}
            defaultSort={{ 
              column: 'main',
              direction: sortDirection.desc
            }}
            columns={typeColumns}
            setSelected={this._onSelect}
            setSorting={this._onSort}
            selectType={SelectType.multiple}
          />   
        </ExpandingBlock>
      </section>
    )
  }

  _getCourseKey = (data) => {
    const { name, isActive } = data

    return `${name}${isActive}`
  }

  _renderFileDialog = () => {
    const { params, courseCard, courseCardResetAction } = this.props
    const { id: courseId } = params
    const { selected } = courseCard
    const [ selectedType ] = selected.items
    const { id: contentId } = selectedType || {}

    return (
      <ModalFileDialog
        forceShow={true}
        onCancel={courseCardResetAction}
        currentType={selectedType}
        courseParams={{courseId, contentId}}
        onFileUploaded={this._refreshTypesData}
      />
    )
  }

  _renderTypeDialog = () => {
    const { params, courseCard, courseCardResetAction } = this.props
    const { id: courseId } = params
    const { courseData } = courseCard
    const { materials } = courseData

    return (
      <ModalFileDialog
        forceShow={true}
        onCancel={courseCardResetAction}
        isFirstType={!materials.length}
        courseParams={{ courseId }}
        onFileUploaded={this._refreshTypesData}
        onTypeAdd={this._onTypeAdd}
        onTypeAddFinish={this._refreshTypesData}
      />
    )
  }

  _renderModal = () => {
    const { courseCard, courseCardResetAction } = this.props
    const { addFilesDialog, confirmObject, addTypeDialog, deleteTypeConfirm } = courseCard

    return (
      <Fragment>
        {deleteTypeConfirm ? 
          <ConfirmDialog
            {...deleteTypeConfirm}
            onSubmit={this._onRunTypeDelete}
            onCancel={courseCardResetAction}
          /> : null
        }
        {addFilesDialog ? (
          this._renderFileDialog()
        ) : null}
        {addTypeDialog ? (
          this._renderTypeDialog()
        ) : null}
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onCancel={courseCardResetAction}
          /> : null
        }
      </Fragment>
    )
  }

  render() {
    const { courseCard } = this.props
    const { inProgress } = courseCard

    return (
      <section className='course-card'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <BreadcrumbsHeader />     
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...courseCardAction
  })(withAuth(withRoutingProps(CourseCard)))