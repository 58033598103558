/** @format */

import React from 'react';
import { OKI_CARD_TYPE, ORG_CARD_TYPE } from '../../ui/constants';
import { formatDate } from 'app/core/utility/date';
import ListLink from 'app/components/ui/ListLink';

export default [
  {
    title: 'Наименование',
    alias: 'name',
    useSorting: true,
    visibleOnStart: true,
  },
  {
    title: 'Краткое описание',
    alias: 'shortDescription',
    useSorting: true,
    visibleOnStart: true,
    width: 560,
  },
  {
    title: 'Тип',
    alias: 'type',
    useSorting: true,
    visibleOnStart: true,
    width: 240,
    format: item => {
      const { type } = item;
      const { name } = type || {};
      return name || '';
    },
  },
  {
    title: 'Производитель',
    alias: 'producer',
    useSorting: true,
    visibleOnStart: true,
    width: 400,
    format: item => {
      const { producer } = item;
      const { name, id, okz } = producer || {};
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE;
      return name && id ? <ListLink text={name} targetType={targetType} id={id} /> : name || '';
    },
  },
  {
    title: 'Дата изменения',
    alias: 'updateDate',
    useSorting: true,
    visibleOnStart: true,
    width: 200,
    format: item => {
      const { updateDate } = item;
      return formatDate(updateDate, 'dd.mm.yyyy');
    },
  },
];
