import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import ActionButtonImage from './ActionButtonImage';
import { downloadAction } from 'redux/actions/list/listActions';
import withTooltip from 'app/components/HOC/TooltipHOC';
const ButtonWithTooltip = withTooltip( ActionButtonImage );

export const DownloadButton = ({ 
  disable, 
  items, 
  titul, 
  img, 
  // downloadAction,
  listFilterForRequest, 
  sorting,
  listFieldsVisibility,
  selected,
  id,
  hardCodeRequestParams,
  isDisabled,
  serviceName
}) => {
  const history = useHistory();
  const validFields = listFieldsVisibility 
                      ? listFieldsVisibility.filter(i => !i['bizLocked'])
                      : listFieldsVisibility

  let disabled = false;

  if( isDisabled && isDisabled( items ) ){
    disabled = true;
  }

  const _onClick = () => {
    const columns = {};

    if( hardCodeRequestParams ){
      downloadAction( hardCodeRequestParams )
      return;
    }

    const requestParams = {
      filter : { 
        conditions : listFilterForRequest || {}, 
        sortBy : sorting.column, 
        sortDirection : sorting.direction ? 1 : -1
      },
      id : id,
      ids : selected,
      serviceName: serviceName
    };
    
    if( validFields ){
      validFields.forEach( el => {
        const key = Object.keys( el )[0];
        
        columns[ key ] = el[ key ];
      })
      requestParams.columns = columns;
    }

    downloadAction( requestParams )
  }


  return  <ButtonWithTooltip 
            img = { "DownloadToXlsImg" || img } 
            action = { _onClick } 
            disabled = { disabled  }  
            tooltip = { titul } 
          />
}

const mapStateToProps = state => {
	return {
    id : state.listId,
    listFilterForRequest : state.listFilterForRequest,
    sorting : state.listSorting,
    listFieldsVisibility : state.listFieldsVisible,
    selected : state.listSelected    
  };
}

const mapDispatchToProps = {
  // downloadAction
}

export default connect( mapStateToProps, mapDispatchToProps )( DownloadButton );