/** @format */
import { Table } from 'app/components/list/Table/Table';
import Overlay from 'app/components/ui/overlay';
import { sortDirection } from 'app/components/ui/constants';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import { useJournal } from 'app/components/journals/useJournal';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from 'app/components/ui/managePanel';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import Filter, { GlobalOrgType } from 'app/components/filter';
import {
  fieldsConfig,
  inCardFieldsConfig,
  filtersConfig,
  inCardFiltersConfig,
} from './pageConfig';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from 'app/components/ui/MainListContainer/MainListContainer';
import styles from './list.module.scss';
import DownloadFileButton from 'app/components/downloadFileButton';
import CertificateRevocation from './revocation';
import CertificateSuspension from './suspension';
import CertificateRenewal from './renewal';
import CreateRequest from 'app/components/certificateRequest/createRequest';
import service from 'app/services/service';

export default function Certificate({ inCard = false, userId }) {
  const {
    baseTableLogic,
    itemSelectionLogic,
    paginationLogic,
    sortingLogic,
    filterLogic,
    globalOrgLogic,
    fieldSelectionLogic,
    filterClearLogic,
    profile,
    rights,
    inProgress,
    setSelectedAction,
    selectedObjects,
    refreshPage,
    fieldsVisible,
    filterOpened,
    toggleFilterState,
  } = useJournal({
    defaultSort: {
      column: 'creationDate',
      direction: sortDirection.desc,
    },
    serviceName: 'certificateAuthorityCertificateService',
    customService: inCard
      ? requestParams =>
          service('certificateAuthorityCertificateService', 'getAllByUser', {
            ...requestParams,
            userId,
          })
      : null,
    resource: RESOURCES.certificateAuthorityCertificate,
  });

  const downloadFilesIds = selectedObjects.map(i => i.id).join('&sn=');
  const downloadFilesUrl = `api/v1/cryptopro/acceptorcertificates/download?sn=${downloadFilesIds}`;

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      {!inCard && (
        <PageHeader
          title="Удостоверяющий центр : Сертификаты"
          Img={imgMap.Sign}
        />
      )}
      <ManagePanel borders={Border.All}>
        {rights.CREATE && (
          <CreateRequest
            afterCreationCallback={refreshPage}
            title="Сертификат"
            userId={userId}
            CAUser
          />
        )}
        {rights.UPDATE && (
          <Group>
            <CertificateRevocation
              item={selectedObjects[0]}
              disabled={selectedObjects.length !== 1}
              CAUser
            />
          </Group>
        )}
        {rights.UPDATE && (
          <Group>
            <CertificateSuspension
              item={selectedObjects[0]}
              disabled={selectedObjects.length !== 1}
              CAUser
            />
          </Group>
        )}
        {rights.UPDATE && (
          <Group>
            <CertificateRenewal
              item={selectedObjects[0]}
              disabled={selectedObjects.length !== 1}
              CAUser
            />
          </Group>
        )}
        <Group>
          <DownloadFileButton
            url={downloadFilesUrl}
            disabled={!selectedObjects.length}
          />
        </Group>
        {!inCard && (
          <Group separate>
            <FilterTriggerPanel
              {...{
                ...filterLogic,
                ...filterClearLogic,
              }}
              toggle={toggleFilterState}
            />
          </Group>
        )}
        <Group separate>
          <TableFieldSelect
            {...fieldSelectionLogic}
            listFieldsVisible={fieldsVisible}
            fieldsConfig={fieldsConfig}
            changedFieldsVisibility
            settingsAsGear
          />
        </Group>
      </ManagePanel>
      <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={
          inCard
            ? ListDisplayVariant.inCardFilter
            : ListDisplayVariant.twoRowFilter
        }
      >
        <Filter
          className={inCard ? styles.inCardCertificate : styles.certificate}
          compactClassName={styles.inCardCertificate}
          {...{
            ...filterLogic,
            ...globalOrgLogic,
            profile,
            inProgress,
            filtersConfig: inCard ? inCardFiltersConfig : filtersConfig,
          }}
          globalOrgPosition={4}
          globalOrgType={GlobalOrgType.okiOnly}
          withGlobalOrg={!inCard}
        />
        <Table
          {...{
            ...baseTableLogic,
            ...itemSelectionLogic,
            ...sortingLogic,
            ...paginationLogic,
            setSelectedAction,
            inProgress,
            fieldsConfig: inCard ? inCardFieldsConfig : fieldsConfig,
            itemsSelectable: true,
            headerInfo: true,
            onItemClick: item =>
              `/certificateAuthority/certificate/card/${item.id}`,
          }}
          listFieldsVisible={fieldsVisible}
          changedFieldsVisibility
        />
      </FilterWithTableContainer>
      <Pagination {...paginationLogic} />
    </MainListContainer>
  );
}
