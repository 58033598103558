import { 
ERROR,
CLEARERROR,
FIELDSERROR,
ERRORPAGEERROR,
USERCARDGET, 
USERCARDUPDATEFORMDATA ,      
USERCARDINPROGRESS ,          
USERCARDRESETACTION ,         
USERCARDUPDATE ,              
USERCARDDELETE ,              
USERCARDONDELETE ,     
USERCARDTABCLICK,
USERCARDONCANCEL,
USERCARDROLEUPDATE,
USERCARDROLEDELETE,
USERCARDROLEADD,
USERCARDGETUSERROLESACTION,
USERCARDRESETPASSWORD,
USERCARDUNBLOCKUSER,
USERCARDBLOCKUSER
  } from '../../actions/actionTypes'
  
import { formatFIO, updateCollectionByItemId, excludeByItemId, deleteArrayItemById } from 'app/core/utility/common'
import { dialogType } from 'app/components/dialog/confirmDialog/'
//import { getPageData } from 'app/components/ui/pagination/pagination'
import config from 'app/core/config'

const { paginationPerPage } = config

export const defaultUserFields = {
  email: '',
  firstName: '',
  id: 0,
  lastName: '',
  login: '',
  middleName: '',
  organizationId: 1,
  snils: '',
}

export const defaultRolesState = {
  commonDataForm : {
    roles: [],
  },
  viewData: {},
  inProgress: false
}

export const defaultState = {
  commonDataForm : {
    lastName: '',
    firstName: '',
    middleName: '',
    snils: '',
    login: '',
    email: '',
    organizationId: null,
    createDate: null,
    status: '',
    lastPasswordSaveTime: '',
    lastLoginTime: '',
    lastSessionEndReason: '',
    roles: [],
  },
  viewData: {},
  tabs: { primaryActive: 'Общие данные', secondaryActive: '' },
  confirmDeleteObject: null,
  deletedInfoObject: null,
  resetPasswordInfoObject: null,
  blockInfoObject: null,
  unblockInfoObject: null,
  inProgress: false
}

const mapServerRoleObject = (item, i) => {
  return {
    index: i+1,
    id: item.id,
    roleId: item.role,
    dateFrom: new Date(item.dateFrom),
    dateTill: {
        timeless: !item.dateTill,
        date: item.dateTill || null
      }
  }
}
  
export default (state = defaultState, action) => {
  const { payload } = action
  const { tabs, viewData, commonDataForm } = state
  const { roles: stateRoles } = viewData
    
  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case USERCARDGET:
      const { userResponse, userRoleResponse } = payload
      const { organization: getOrg } = userResponse
      
      return {
          ...state,
          commonDataForm: {
            ...userResponse, 
            organizationId: getOrg
          },
          viewData: {
            ...userResponse,
            organizationId: getOrg,
            roles: [
              ...userRoleResponse.map(mapServerRoleObject)
            ]
          },
          inProgress: false
      }

    case USERCARDGETUSERROLESACTION:
      return {
        ...state,
        viewData: {
          ...viewData,
          roles: payload.map(mapServerRoleObject)
        },
        inProgress: false
      }

    case USERCARDUPDATEFORMDATA: 
      const { key, value } = payload

      return {
        ...state,
        commonDataForm: {...commonDataForm, [key]: value }
      }

    case USERCARDONCANCEL:
      return {
        ...state,
        commonDataForm: {...viewData}
      }

    case USERCARDROLEUPDATE:
      return {
        ...state,
        viewData: {
          ...viewData,
          roles: updateCollectionByItemId(stateRoles, mapServerRoleObject(payload))
        }
      }

    case USERCARDROLEDELETE:
      return {
        ...state,
        viewData: {
          ...viewData,
          roles: deleteArrayItemById(stateRoles, payload)
        }
      }
      
    case USERCARDROLEADD:  
      return {
        ...state,
        viewData: {
          ...viewData,
          roles: [ mapServerRoleObject(payload), ...stateRoles ]
                    .map((item, i) => ({...item, index: i+1 }))
        }
      } 

    case USERCARDRESETPASSWORD: 
      return {
        ...state,
        resetPasswordInfoObject: {
          type: dialogType.message,
          title: `Пароль сброшен. Инструкция о восстановлении пароля отправлена пользователю`
        },
        inProgress: false
      }

    case USERCARDUNBLOCKUSER: 
      return {
        ...state,
        viewData: {
          ...viewData,
          status: 'Активен'
        },
        unblockInfoObject: {
          type: dialogType.message,
          title: `Пользователь разблокирован`
        },
        inProgress: false
      }

    case USERCARDBLOCKUSER: 
      return {
        ...state,
        viewData: {
          ...viewData,
          status: 'Заблокирован'
        },
        blockInfoObject: {
          type: dialogType.message,
          title: `Пользователь заблокирован`
        },
        inProgress: false
      }


    case USERCARDUPDATE:
      const { organization: updateOrg } = payload

      return {
          ...state,
          commonDataForm: {
            ...payload, 
            organizationId: updateOrg
          },
          viewData: {
            ...payload,
            organizationId: updateOrg
          },
          inProgress: false
      }

    case CLEARERROR:
    case USERCARDRESETACTION:
      return {
        ...defaultState,
        tabs,
        viewData,
        commonDataForm
      }

    case FIELDSERROR:
    case ERROR: 
      return {
        ...state,
        inProgress: false
      }

    case USERCARDINPROGRESS:
      return {
        ...state,
        inProgress: true
      }

    case USERCARDDELETE:
        return {
          ...state,
          deletedInfoObject: {
            type: dialogType.success,
            title: `Учетная запись успешно удалена`
          },
          inProgress: false
      }

    case USERCARDTABCLICK:
      return {
        ...state,
        tabs: {...tabs,...payload}
      }


    case USERCARDONDELETE:
      return {
        ...state,
        confirmDeleteObject: {
          type: dialogType.confirm,
          title: `Вы уверены, что хотите удалить учетную запись «${formatFIO(payload)}»?`
        },
        inProgress: false
      }

    default: 
      return state
  }
}