/** @format */

import React from 'react';
import InfoWidget from '../infoWidget';

export default function WaitForSign({ admittance, maintenance, destructionCertificate }) {
  return (
    <InfoWidget
      className="wait-for-sign"
      headerText="Ожидают подписания / создания"
      dataRows={[
        {
          title: 'Заключения о допуске',
          data: admittance,
          // reference: '',
        },
        {
          title: 'Акты об уничтожении',
          data: destructionCertificate,
          // reference: '',
        },
        { 
          title: 'Заключения об эксплуатации', 
          data: maintenance, 
          // reference: '' 
        },
      ]}
    />
  );
}
