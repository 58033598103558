import React from 'react'

export default function Spinner(props){
  return (
    <div
      className="spinner"
      {...props}
    >
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="20"/>
      </svg>
    </div>
  )
}