import useToast from "app/components/ui/toast/useToast"
import { formatDate } from "app/core/utility/date"
import service from "app/services/service"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { dispatchLogic } from "redux/actions/common"
import { setNeedRefreshByRest } from "redux/actions/notification"

export function useReplicateExemplar({initialState, onCancel}){
  const {addToast} = useToast()
  const { id } = initialState
  const [ inProgress, setProgress ] = useState(false)
  const [ validLicensesCount, setValidLicensesCount ] = useState([])
  const dispatch = useDispatch()

  const getSignRequestData = formData => {
    const {
      eskziId,
      //organizationId,
      //copiesNumber,
      //licensiesNumber,
      transmittalLetterNumber,
      transmittalLetterDate,
      replicationItems,
    } = formData || {}
    //const { id } = organizationId || {}

    return {
      //organizationId: id,
      //copiesNumber: copiesNumber,
      //licensiesNumber: licensiesNumber,
      transmittalLetterNumber: transmittalLetterNumber,
      transmittalLetterDate: formatDate(transmittalLetterDate, 'yyyy-mm-dd'),
      eskziId: eskziId,
      replicationItems,
      signatureRequestType: 'replicateOKI' // нужно для выбора апи подписи
    }
  }

  const replicateExemplarCallback = async (signedData) => {
    setProgress(true)
    const response = await service('replicationService', 'replicateOKI', signedData) 
      
    const {isError, isWarn, errors, data} = response || {}

    if (!isError && !isWarn) {
      addToast('Распространение экземпляра успешно запущено')
      dispatch(setNeedRefreshByRest(true))
      onCancel()
    } else {
      dispatchLogic(dispatch, 'MOCK', data, isError, errors)
    }

    setProgress(false)
    return response
  }

  useEffect(() => {
    const updateLicenseData = async () => {
      setProgress(true)
      const response = await service('licenseService', 'getUnusedEskziLicense', {eskziId: id}) 
        
      const {isError, errors, data, dataCount} = response || {}
  
      if (!isError) {
        setValidLicensesCount(dataCount)
      } else {
        dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      }
  
      setProgress(false)
    }
    updateLicenseData()
  }, [])
  
  return {
    replicateExemplarCallback,
    getSignRequestData,
    inProgress,
    validLicensesCount
  }
}