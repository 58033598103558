import React from 'react'

import Input from 'app/components/ui/Input'
import DatePicker from 'app/components/ui/date'
import Autocomplete, { Services, renderItem, renderInputValue }  from 'app/components/ui/autocomplete/autocomplete'
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker'
import { notEmpty } from 'app/core/utility/validation'
import service from 'app/services/service'
import { decapitalize, formatFIO } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import { SignatureInfo } from 'app/components/signature/SignatureInfo'
import {defaultSettings as hardwareColumnSettings} from "../ui/picker/entities/hardware";
import {SelectType} from "../ui/picker/picker";

export const getObjectKey = (data) => {
  const { ReceivedFrom = '', HardwareNumber = '', EskziUserId, OrganizationId, TransmittalLetterDate = null, TransmittalLetterNumber = '', PerformerId = {}, HardwareId, PrintNumber = '', DistributiveRemovingDate = null } = data
  const transmittalDateString = formatDate(TransmittalLetterDate, 'yyyy-mm-dd')
  const distribDateString = formatDate(DistributiveRemovingDate, 'yyyy-mm-dd')

  const { id: orgId = '' } = OrganizationId || {}
  const { id: userId = '' } = EskziUserId || {}
  const { id: hardwareId = '' } = HardwareId || {}
  const { id: performerId = '' } = PerformerId || {}

  return `${ReceivedFrom}${HardwareNumber}${performerId}${userId}${orgId}${transmittalDateString}${distribDateString}${TransmittalLetterNumber}${hardwareId}${PrintNumber}`
}

// to do
// make all values calculations here
export const mapSignature = signature => {
  const { signer, validationTime, certificateInfo } = signature || {}
  const { 
    notBefore = '', 
    notAfter= '', 
    certificateSerialNumber = '', 
    organization = '', 
    email = '',
    fio = '',
    keyIdentifier = '',
    issuer = '',
    authorityKeyIdentifier = ''
  } = certificateInfo || {}

  return {
    certificateFio: fio,
    signFio: formatFIO(signer),
    signatureDate: validationTime,
    status: null,
    email: email,
    organization: organization,
    serialNumber: certificateSerialNumber,
    validThrought: {from: notBefore, to: notAfter},
    keyIdentifier,
    authorityKeyIdentifier,
    issuer
  }
}

export const getFormData = serverFormViewModel => {
  const { data, model, signatures, changeCurentSignature } = serverFormViewModel

  return model.reduce((acc, cur) => {
    // naming agreement
    const dataKey = cur.key.substring(cur.key.length - 2) === 'Id' 
                      ? decapitalize(cur.key.substring(0, cur.key.length - 2))
                      : decapitalize(cur.key)

    acc[cur.key] = data[dataKey]
    return acc
  }, signatures ? {Signatures: signatures.map(mapSignature), changeCurentSignature } : {})
}

export const cardLines = (componentProps) => ({
  firstGroup : {
    groupTitle: componentProps.name,
    content: {
      eskziUserId: {
        title: props => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || 'ФИО пользователя СКЗИ'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value } = props

          return formatFIO(value)
        },
        renderEdit: (props, serverFieldViewModel, ids) => {
          const { value, editValue, onChange, error, isKeyDocument, autompleteServiceType, eskziId } = props
          const { readOnly } = serverFieldViewModel

          if (readOnly) {
            return formatFIO(value)
          }

          let autompleteService = {
            serviceName: 'skziUserService', 
            serviceMethod: 'eskziAutocomplete', 
            data: {eskziId, eskziIds: ids}
          }
          
          if( autompleteServiceType === 'isKeyDocument' || isKeyDocument ){
            autompleteService = {
              serviceName: 'skziUserService', 
              serviceMethod: 'keyDocumentAutocomplete', 
              data: {eskziId, eskziIds: ids}
            }
          }
          
          if( autompleteServiceType === 'isKeyCarrier' ){
            autompleteService = {
              serviceName: 'skziUserService', 
              serviceMethod: 'keyCarrierAutocomplete', 
              data: {eskziId, eskziIds: ids}
            }
          }

          return (
            <Autocomplete 
              onEmptySearch = {true} 
              notStandardService={autompleteService}
              onSelect={value => onChange && onChange('eskziUserId', value)} 
              error={error}
              value={editValue}
              renderItem={item => formatFIO(item)}
              renderInputValue={item => formatFIO(item)}
            />
          );
        },
      },
      organizationId: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}
          
          return  title || 'Кому передано'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value } = props 
          const { shortName, name } = value || {}

          return shortName || name
        },
        renderEdit: (props, serverFieldViewModel, ids) => {
          const { editValue, onChange, error, eskziId, isKeyDocument, isEventKeyDocument, autompleteServiceType, eventResultId } = props
          const { organizationType, readOnly } = serverFieldViewModel
          const { shortName, name } = editValue || {}

          if (readOnly) {
            return shortName || name
          }

          let autompleteService = {
            serviceName: 'organizationService', 
            serviceMethod: 'eskziAutocomplete', 
            data: {organizationType, eskziId, eskziIds: ids}
          }
          
          if( autompleteServiceType === 'isKeyDocument' || isKeyDocument ){
            autompleteService = {
              serviceName: 'organizationService', 
              serviceMethod: 'keyDocumentAutocomplete', 
              data: {organizationType, eskziId, eskziIds: ids}
            }
          }

          if( autompleteServiceType === 'isEventKeyDocument' || isEventKeyDocument ){
            autompleteService = {
              serviceName: 'organizationService', 
              serviceMethod: 'keyDocumentEventAutocomplete', 
              data: {eventId: eventResultId, eskziId}
            }
          }

          if( autompleteServiceType === 'isKeyCarrier' ){
            autompleteService = {
              serviceName: 'organizationService', 
              serviceMethod: 'keyCarrierAutocomplete', 
              data: {organizationType, eskziId, eskziIds: ids}
            }
          }
          
          return (
            <Autocomplete 
              onEmptySearch = {true} 
              notStandardService={autompleteService} 
              onSelect={value => onChange && onChange('organizationId', value)} 
              error={error}
              value={editValue}
              renderItem = {renderItem.organizations}
            />
          );
        },
      },
      hardwareId: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}
          
          return  title || 'Серийный номер аппаратного СКЗИ'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value } = props 
          const { serialNumber } = value || {}

          return serialNumber
        },
        renderEdit: (props, serverFieldViewModel) => {
          const { editValue, onChange, error, eskziId } = props        
          const { readOnly } = serverFieldViewModel

          if (readOnly) {
            const { serialNumber } = editValue
            return serialNumber || ''
          }
          const pickerService = {
            serviceName: 'skziService',
            serviceMethod: 'hardwareAutocompletePicker',
            data: {eskziId: eskziId}
          }

          const autompleteService = {
            serviceName: 'skziService', 
            serviceMethod: 'hardwareAutocomplete', 
            data: {eskziId}
          }

          return (
            <AutocompletePicker
              searchPlaceholder="Серийный №"
              onEmptySearch = {true} 
              notStandardService={autompleteService }
              notStandardPickerService={pickerService}
              onSelectValue={value => onChange && onChange('hardwareId', value)}
              tableColumns={hardwareColumnSettings}
              selectType={SelectType.single}
              error={error}
              value={editValue}
              renderItem = { item => item.serialNumber }
              renderInputValue={ item => item ? item.serialNumber : '' }
            />)
        },
      },
      receivedFrom: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || 'Получено от'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value } = props 

          return value
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props
    
          return (
            <Input 
              placeholder = 'Введите наименование'
              value={editValue}
              onChange={value => onChange && onChange('receivedFrom', value)}
              error={error}
            />
          )
        },
      },
      performerId: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || 'ФИО исполнителя'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value } = props 

          return formatFIO(value)
        },
        renderEdit: (props, serverFieldViewModel, ids) => {
          const { editValue, onChange, error, eskziId, isKeyDocument, autompleteServiceType, isExternalUser } = props

          let autompleteService = {
            serviceName: 'maintenanceService', 
            serviceMethod: 'getPerformers', 
            data: {eskziId, eskziIds: ids}
          }

          if( autompleteServiceType === 'isKeyDocument' || isKeyDocument ){
            autompleteService = {
              serviceName: 'skziUserService', 
              serviceMethod: 'keyDocPerformers', 
              data: {eskziId, eskziIds: ids}
            }
          }
          
          if( autompleteServiceType === 'isKeyCarrier' ){ 
            autompleteService = {
              serviceName: 'skziUserService', 
              serviceMethod: 'keyCarrierPerformers', 
              data: {eskziId, eskziIds: ids}
            }
          }
          
          if (isExternalUser) {
            autompleteService = {
              serviceName: 'skziUserService', 
              serviceMethod: 'extOrgPerformers', 
              data: {eskziId, eskziIds: ids}
            }
          }
 
          return (
            <Autocomplete 
              placeholder = 'Введите ФИО'
              onEmptySearch = {true} 
              notStandardService={ autompleteService }
              onSelect={value => onChange && onChange('performerId', value)} 
              error={error}
              value={editValue}
              renderItem = { item => renderItem.eskziUser(item) }
              renderInputValue={ item => renderInputValue.eskziUser(item) }
            />
          )
        },
      },
      hardwareNumber: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || 'Номер аппаратного средства'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props
    
          return (
            <Input 
              placeholder = 'Введите номер'
              value={editValue}
              onChange={value => onChange && onChange('hardwareNumber', value)}
              error={error}
            />
          )
        },
      },
      distributiveRemovingDate: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || 'Дата удаления дистрибутива'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value } = props
          return formatDate(value, 'dd.mm.yyyy')
        },
        renderEdit: props => {
          const { editValue = null, onChange, error } = props
    
          return (
            <DatePicker 
              error={error}
              value={editValue ? new Date(editValue) : editValue} 
              onChange= {value => onChange && onChange('distributiveRemovingDate', value ? value.toISOString() : value)}
            />
          )
        },
      },
      transmittalLetterDate: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || 'Дата выполнения действия'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value } = props
          return formatDate(value, 'dd.mm.yyyy')
        },
        renderEdit: props => {
          const { editValue = null, onChange, error } = props
    
          return (
            <DatePicker 
              error={error}
              value={editValue ? new Date(editValue) : editValue} 
              onChange= {value => onChange && onChange('transmittalLetterDate', value ? value.toISOString() : value)}
            />
          )
        },
      },
      transmittalLetterNumber: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || 'Номер подтверждающего документа'
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value } = props

          return value
        },
        renderEdit: props => {
          const { 
            editValue, 
            onChange, 
            error,
            actionCode,
            eskziIds 
          } = props
          // https://jira.infosec.ru/browse/AOKZ-6917
          const disabled = actionCode === "OkiInstallation"
                            && eskziIds && eskziIds.length > 1
          
          return (
            <Input 
              placeholder = {disabled ? 'Автоматически' : 'Введите номер'}
              value={editValue}
              onChange={value => onChange && onChange('transmittalLetterNumber', value)}
              error={error}
              disabled={disabled}
            />
          )
        },
      },
      printNumber: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || ''
        },
        isRequired: props => props.required,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props
    
          return (
            <Input 
              placeholder = 'Введите номер'
              value={editValue}
              onChange={value => onChange && onChange('printNumber', value)}
              error={error}
            />
          )
        },
      },
      signatures: {
        title: (props) => {
          const { serverFieldViewModel } = props
          const { title } = serverFieldViewModel || {}

          return  title || ''
        },
        isRequired: props => props.required,
        isDescription: true,
        validation: (value, props) => {
          return props && props.required ? notEmpty(value) : true
        },
        renderValue: props => {
          const { value, changeCardProp, cardProp } = props
          return (
            <SignatureInfo 
              signatures = {value}
              changeCurrentSignature = {changeCardProp}
              currentSignature = {cardProp}
            />
          )
        },
      },
    }
  }
})