/** @format */

import React, { useEffect, useState, useMemo } from 'react';

import Input from 'app/components/ui/Input';
import DatePicker from 'app/components/ui/date';
import SimpleSearch from 'app/components/ui/search/SimpleSearch';
import { Table } from 'app/components/list/Table/Table';

import withModal from 'app/components/HOC/ObsoleteModalHOC';

import { getOrganizationDetails } from 'app/components/skzi/Eskzi/replication/exemplar/exemplarReplicationForm';
import { formatSKZI } from 'app/components/destructionCertificate/destructionObjectSet';
import { statuses } from 'app/components/Notification/allNotificationPage/notificationItem';
import ResultButtons from 'app/components/Notification/allNotificationPage/notificationDetail/resultButtons';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import useDebounce from 'app/hooks/useDebounce';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { sortDirection } from 'app/components/ui/constants';
import { calculateLicenseCount, calculateReplicationResultStats } from '../helpers';

import styles from './exemplarReplication.module.scss';
import Img, { Image } from 'app/components/ui/Img';

const ReplicationResult = props => {
  const {
    status,
    onCancel,
    etalon = {},
    transmittalLetterDate = new Date(),
    transmittalLetterNumber = '',
    replicationItems = [],
    onSubmitSignEnhance,
    getSignRequestData,
    uid,
    error,
  } = props;

  const needSign = status.id === statuses.doneWithErrors;

  const { sorting, sortListAction } = useTableExtensions({
    defaultSort: {
      column: 'name',
      direction: sortDirection.asc,
    },
  });

  const [nameInnFilter, setNameInnFilter] = useState('');
  const debouncedNameInnFilter = useDebounce(nameInnFilter, 500);
  const [filteredReplicationItems, setFilteredReplicationItems] = useState(replicationItems);

  useEffect(() => {
    setFilteredReplicationItems(
      replicationItems.filter(ri =>
        ['shortName', 'name', 'inn'].some(
          f =>
            ri.organization.entity[f] &&
            ri.organization.entity[f].toUpperCase().includes(debouncedNameInnFilter.toUpperCase())
        )
      )
    );
  }, [replicationItems, debouncedNameInnFilter]);

  const [exemplarsAndLicensesToggleState, setExemplarsAndLicensesToggleState] = useState(true);
  const [organizationsToggleState, setOrganizationsToggleState] = useState(false);

  const hasCopiesError = item => item.copiesNumber !== item.copiesNumberFact;
  const hasLicensesError = item => item.licenses.length !== item.licenses.filter(lic => lic.isSuccessful).length;

  const orgTableSchema = [
    {
      title: 'Наименование организации',
      alias: 'name',
      format: item => (
        <div>
          <div>{item.organization.entity.shortName || item.organization.entity.name}</div>
          <div className={styles.orgDetails}>{getOrganizationDetails(item.organization.entity)}</div>
        </div>
      ),
      visibleOnStart: true,
      useSorting: true,
    },
    {
      title: 'Экземпляры',
      alias: 'copiesNumber',
      format: item => (
        <Input
          value={`${item.copiesNumber || 0}/${item.copiesNumberFact || 0}`}
          absoluteInfo
          readOnly
          slotAppend={hasCopiesError(item) ? <Img img={Image.AlertTriangle} className={styles.alertImg} /> : null}
        />
      ),
      visibleOnStart: true,
    },
    {
      title: 'Лицензии (активации)',
      alias: 'licenses',
      format: item => (
        <Input
          value={`${item.licenses.length}/${item.licenses.filter(lic => lic.isSuccessful).length}`}
          absoluteInfo
          readOnly
          slotAppend={hasLicensesError(item) ? <Img img={Image.AlertTriangle} className={styles.alertImg} /> : null}
        />
      ),
      visibleOnStart: true,
    },
  ];

  const { orgCount, eskzisCount, licenseCount } = useMemo(
    () => calculateReplicationResultStats(replicationItems),
    [replicationItems]
  );

  return (
    <div className={styles.exemplarReplicationForm}>
      <ExpandingBlock
        renderHeader={() => 'Экземпляры и лицензии'}
        initialState={exemplarsAndLicensesToggleState}
        onToggle={toggleState =>
          toggleState && (setOrganizationsToggleState(false) || setExemplarsAndLicensesToggleState(true))
        }
      >
        <div
          className={`${styles.blockContent} ${styles.exemplarsAndLicenses} ${styles.result} exemplar-replicate-form__content`}
        >
          <Input
            id="etalonId"
            className={`card-field ${styles.cardFieldEtalonId}`}
            label="Эталон для распространения"
            value={formatSKZI(etalon)}
            disabled
          />
          <Input
            id="transmittalLetterNumber"
            className={`card-field ${styles.cardFieldTransmittalLetterNumber}`}
            label="№ сопроводительного документа"
            value={transmittalLetterNumber}
            disabled
          />
          <DatePicker
            id="transmittalLetterDate"
            className={`card-field ${styles.cardFieldTransmittalLetterDate}`}
            label="Дата сопроводительного письма"
            value={transmittalLetterDate}
            disabled
          />
        </div>
      </ExpandingBlock>
      <ExpandingBlock
        renderHeader={() => `Организации: ${orgCount} (экземпляров: ${eskzisCount}, лицензий: ${licenseCount})`}
        initialState={organizationsToggleState}
        className={`${styles.expandingBlock} ${styles.organizations}`}
        onToggle={toggleState =>
          toggleState && (setExemplarsAndLicensesToggleState(false) || setOrganizationsToggleState(true))
        }
      >
        <div className={`${styles.blockContent} ${styles.organizations} exemplar-replicate-form__content`}>
          {/* Фильтр по наименованию/ИНН */}
          <SimpleSearch
            className="search-name-inn"
            label="Наименование или ИНН"
            value={nameInnFilter}
            onChange={setNameInnFilter}
            noHint
          />
        </div>
        {/* Таблица организаций */}
        <Table
          {...{
            sorting,
            sortListAction,
          }}
          fieldsConfig={orgTableSchema}
          list={filteredReplicationItems}
          totalCount={filteredReplicationItems.length}
        />
      </ExpandingBlock>
      <ResultButtons
        callback={() =>
          onSubmitSignEnhance(
            getSignRequestData({
              etalon,
              transmittalLetterDate,
              transmittalLetterNumber,
              replicationItems,
              uid,
            })
          )
        }
        needSign={needSign}
        onCancel={onCancel}
      />
    </div>
  );
};

export default withModal(ReplicationResult);
