export const cardLinesKeys = {
  skziBuildId: null,
  serialNumber: null,
  certificateExpirationDate: null,
  status: null,
  isStandard: null,
  okz: null,
  oki: null,
  eskziUserId: null,
  hardwareNumber: '',
  product: null
}

export const mapDataToLines = ( data ) => {
  const { serialNumbers = [] } = data
  
  const mapped = Object.keys(cardLinesKeys).reduce((acc, key) => 
  {
    let value;
    switch (key) {
      case 'identificationNumber':
        value = data.identificationNumber
        break       
      case 'oki':
        value = data.oki
        break       
      case 'okz':
        value = data.okz
        break       
      case 'skziBuildId':
        const { skzi } = data
        
        value = { ...skzi }
        break
      case 'serialNumber':
        value = data.serialNumber
        break                
      case 'certificateExpirationDate':
        value = data.certificateExpirationDate
        break  
      case 'status':
        const { status } = data || {}
        const { name } = status || {}
        value = name
        break                
      case 'isStandard':
        value = data.isStandard
        break
      case 'eskziUserId':
        value = data.eskziUser
        break
      case 'hardwareNumber':
        value = data.hardwareNumber
        break              
    }
    acc[key] = value
    return acc
  }, {})

  const withSerialNumbers = serialNumbers.reduce((acc,cur) => {
    acc[cur.id] = cur.number
    return acc
  }, {})

  return {...data, ...mapped, ...withSerialNumbers}
};