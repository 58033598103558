import React, { Component } from 'react'

import { keyCodes } from '../ui/constants'
import Overlay from 'app/components/ui/overlay'
import { eventStop } from 'app/core/utility/common'
import { getClassName } from 'app/core/utility/jsx'

// Obsolete
const withModalObsolete = PassedComponent => {
 class Modal extends Component {

    componentDidMount(){
      const { onCancel, forceShow } = this.props

      window.onkeydown = (e) => {
        switch (e.code) {
          case keyCodes.Esc:
            !forceShow && onCancel && onCancel();
            break;
          default:
            break;
        }
      }
    }

    render() {
      const { 
        className, 
        onCancel, 
        renderHeader, 
        forceShow,
        isGlobalOverlay,
        inProgress,
        forwardedRef,
        compact,
      } = this.props
      
      const containerCssClass = getClassName([
        'modal-container',
        {
          [className]: !!className,
          'modal-container--global': isGlobalOverlay,
          compact,
        }
      ])

      return (
        <div className={containerCssClass}>
          <div className="modal-container__overlay" onClick={(e) => {
            eventStop(e)
            !forceShow && onCancel && onCancel()
          }}></div>
          <div className="modal-container__panel">
            {inProgress ? <Overlay size={'small'}/> : null}
            {/* ///TODO  */}
            {/* перенести верстку хедера модального окна (<div className='modal__header'>) сюда */}
            {!renderHeader
              ? null
              : typeof renderHeader === 'function'
              ? renderHeader()
              : <div className='modal__header'>{renderHeader}</div>
            }
            <div className={`modal-container__content ${renderHeader ? 'modal-container__content--withheader' : ''}`}>
              <PassedComponent 
                {...this.props}
                ref={forwardedRef}
                isModal={true}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <Modal {...props} forwardedRef={ref} />;
  });
}

export default withModalObsolete