import React, {useEffect, useState} from "react";
import DropDownPanel from "app/components/ui/DropDownPanel/DropDownPanel";
import {useHistory} from "react-router-dom";
import CircleProgressBar from "app/components/ui/CircleProgressBar/CircleProgressBar";
import AsyncOperationUpload from "app/components/asyncOperationUpload";
import Button from 'app/components/ui/button/button';
import {useDispatch, useSelector} from "react-redux";
import {ActionCreator} from "redux/actions/uploadProcessing/uploadProcessing";
import {useConnectionByType} from "app/hooks/useConnectionByType";
import {getCreateUploadState, getMetric, getWSUploads} from "redux/actions/uploadProcessing/selectors";
import isEqual from "lodash.isequal";
import unionBy from 'lodash.unionby';
import {Status} from "app/components/MassImports/enums";

export default function UploadMenuControl() {
    const history = useHistory();
    const dispatch = useDispatch();
    const isCreateUpload = useSelector(getCreateUploadState);
    const uploadsMetric = useSelector(getMetric);
    const WSUploads = useSelector(getWSUploads);
    const {data} = useConnectionByType('longPolling');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isProcessingFiles, setProcessingFilesState] = useState(false);

    const [isInitProcessing, setProcessingInitState] = useState(false);
    const [isComplete, setCompleteState] = useState(false);

    useEffect(() => {
        if (uploadsMetric) {
            if (!isInitProcessing && (uploadsMetric.filesCount > 0)) {
                setProcessingInitState(true);
            }

            if (isInitProcessing && (uploadsMetric.filesCount === 0)) {
                setCompleteState(true);
                setProcessingInitState(false);
            }
        }
    }, [uploadsMetric]);

    const onClickCircleButtonHandler = () => {
        setIsMenuOpen(prevState => !prevState);
        setCompleteState(false);
    };
    const onClickShowAllButtonHandler = () => {
        setIsMenuOpen(false);
        history.push('/import');
    };
    const onClickMenuElementHandler = async (fileId, fileStatus, fileType, shortOfLicenses) => {
        switch (fileStatus) {
            case Status.LOADING_END:
            case Status.CREATING_END:
                dispatch(ActionCreator.setParsedFileInfo({fileStatus, fileId, fileType}));
                setIsMenuOpen(false);
                break;
            case Status.LOADING:            
            case 'Загрузка файла': // TODO Remove this fallback line when api/v1/ImportProgress/fileHistory loading status gets fixed
                dispatch(ActionCreator.setFileSingleParsingId(fileId));
                dispatch(ActionCreator.setFileSingleParsing(true));
                setIsMenuOpen(false);
                break;
            case Status.ERROR:
                if (shortOfLicenses) {
                    dispatch(ActionCreator.setParsedFileInfo({fileStatus: Status.LOADING_END, fileId, fileType}));
                    setIsMenuOpen(false);
                }
                
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        if (WSUploads.length > 0) {
            const processingFiles = WSUploads
                .filter(item => (item.file.status === Status.LOADING) || (item.file.status === Status.CREATING));

            if (processingFiles.length > 0) {
                setProcessingFilesState(true);
            }

            if (isProcessingFiles && (processingFiles.length === 0)) {
                setProcessingFilesState(false);
            }
        }
    }, [WSUploads]);
    useEffect(() => {
        if (data) {
            const {subject} = data;
            let {importProgress} = subject;

            if (!isEqual(importProgress, WSUploads)) {
                importProgress = unionBy(importProgress, WSUploads, 'fileId')
                    .sort((a, b) => b.fileId - a.fileId);
                dispatch(ActionCreator.setWSUploads(importProgress));
                dispatch(ActionCreator.setMetric(subject));
            }
        }
    }, [data]);

    return (<>
         <div className={`circle-progressbar-container ${isMenuOpen ? 'active' : 'inactive'}`}>
            <CircleProgressBar
                uploadsMetric={uploadsMetric}
                disabled={WSUploads.length === 0}
                isCompleted={isComplete}
                onClick={onClickCircleButtonHandler}
            />
            {
                isMenuOpen
                && <DropDownPanel initState={isMenuOpen} onClosePanelHandler={() => setIsMenuOpen(false)}>
                    {
                        WSUploads
                        && (WSUploads.length > 0)
                        && <AsyncOperationUpload
                            onClickElementHandler={onClickMenuElementHandler}
                            uploads={WSUploads}
                            isFull={false}
                        />
                    }
                    <div className={'upload-list__button-wrap'}>
                        <Button
                            type={'text'}
                            onClick={onClickShowAllButtonHandler}>
                            Все операции
                        </Button>
                    </div>
                </DropDownPanel>
            }
            {
                isCreateUpload
                && <div className={'circle-progressbar-container__tooltip'}>
                    Создание записей началось
                </div>
            }
        </div>
    </>);
}
