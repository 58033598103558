/** @format */
import companyLogo from 'assets/img/companyLogo.png';

export default {
  visible: true,
  paragraphs: [
    'X-Control — отечественное программное обеспечение, разработанное компанией Spacebit, повышает эффективность организации за счет автоматизации учета средств криптографической защиты.',
    'Компания Spacebit — российский разработчик продуктов и решений для обеспечения информационной безопасности.',
  ],
  companyLogo,
};
