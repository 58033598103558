/** @format */
import { useState } from 'react';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog';
import { stringPlural } from 'app/core/utility/common';
import Overlay from 'app/components/ui/overlay';
import { dispatchLogic } from 'redux/actions/common';
import { useDispatch } from 'react-redux';
import service from 'app/services/service';
import withTooltip from 'app/components/HOC/TooltipHOC';

const Button = withTooltip(ButtonComponent);

export default function UnblockCAUser({ afterActionCallback, items = [] }) {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();
  const onDialogToggle = () => {
    setDialogOpened(!dialogOpened);
  };

  const afterAction = () => {
    afterActionCallback && afterActionCallback();
  };

  const approveRequests = async () => {
    setDialogOpened(false);
    setInProgress(true);
    const response = await service('certificateAuthorityUserService', 'unblockUser', {
      userIds: items.map(i => i.id),
    });
    const { isError, errors } = response || {};
    !isError && afterAction();
    isError && dispatchLogic(dispatch, 'MOCK', null, isError, errors);
    setInProgress(false);
  };
  const titleVariants = ['пользователя', 'пользователей', 'пользователей'];
  const textVariants = [
    'выбранного пользователя',
    'выбранных пользователей',
    'выбранных пользователей',
  ];
  const disabled = !items.length || items.some(i => i?.status?.id !== 'Inactive');

  return (
    <>
      {inProgress && <Overlay />}
      <Button
        type={buttonTypes.image}
        onClick={onDialogToggle}
        disabled={disabled}
        tooltip={'Разблокировать'}
      >
        <Img img={Image.UnblockUser} />
      </Button>
      {dialogOpened && (
        <ConfirmDialog
          header={`Разблокировать ${stringPlural(items.length, titleVariants)}?`}
          type={dialogType.confirm}
          submitText="Разблокировать"
          onSubmit={approveRequests}
          onCancel={onDialogToggle}
          messages={[`Подтвердите отмену блокировки ${stringPlural(items.length, textVariants)}.`]}
        ></ConfirmDialog>
      )}
    </>
  );
}

