import React, {useEffect, useState} from 'react';
import Title from "app/components/list/ManagePanel/Title";
import {Table} from "app/components/list/Table/Table";
import Overlay from 'app/components/ui/overlay';
import ActionPanel from "./ActionPanel";
import {useJournal} from "../../journals/useJournal";
import {sortDirection} from "../../ui/constants";
import isOkzImg from 'assets/img/common/key.svg'
import service from "../../../services/service";
import InfoPanel from "app/components/ui/infoPanel";
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import { blockedColumn } from 'app/components/list/Table/columns';
import SimpleSearch from "app/components/ui/search/SimpleSearch";

const config = {
    type: 'organization',
    pageSecurityKey: 'Organization/Entity',
    pageTitle: 'Справочники: Организации',
    fieldsConfig: [
        {
            alias : 'bizLocked',
            format : blockedColumn,
            visibleOnStart : true,
            visibleAlways : true,
            width : 50
        },
        {
            title: 'Наименование',
            alias: 'name',
            visibleOnStart: true,
            useSorting: true,
            format: el => {
                const {name, shortName, okz} = el;
                return <>
                    {shortName || name}
                    {okz && <InfoPanel
                        image={isOkzImg}
                        className='okz'
                    >
                        Наличие Органа криптографической защиты
                    </InfoPanel>}
                </>
            }
        },
        {
            title: 'Код',
            alias: 'code',
            visibleOnStart: true,
            useSorting: true,
            width: 220
        },
        {
            title: 'ИНН',
            alias: 'inn',
            visibleOnStart: true,
            useSorting: true,
            width: 220
        },
        {
            title: 'КПП',
            alias: 'kpp',
            visibleOnStart: true,
            useSorting: true,
            width: 220
        },
        {
            title: 'ОГРН/ОГРНИП',
            alias: 'ogrn',
            visibleOnStart: true,
            useSorting: true,
            width: 220
        },
    ]
};

export default function OrganizationCatalog() {
    const [searchValue, setSearchValue] = useState('');

    const {type, pageTitle, fieldsConfig} = config;
    const {
        filterForRequest,
        fieldsVisible,
        onRemoveValues,
        onInProgress,
        sortListAction,
        inProgress,
        sorting,
        selectedItems,
        list,
        paginator,
        setSelectedAction,

        //pagination
        currentPage,
        perPage,
        pageCount,
        setCurrentPage,
        selectedObjects,
        setSelectedPagination,
        totalCount,
        setFilterForRequestAction
    } = useJournal({
        defaultSort: {
            column: 'id',
            direction: sortDirection.desc
        },
        serviceName: 'organizationService'
    });

    const onSearchHandler = (evt) => {
        if ((evt !== null) || (evt !== undefined)) {
            setFilterForRequestAction({super: evt});
        }
    };

    useEffect(() => {
        if (searchValue === '') {
            setFilterForRequestAction({super: ''});
        }
    }, [searchValue]);

    const onItemClickHandler = (item) => {
        return `/catalog/organization/card/${item.id}`
    };
    const deleteActionCallback = async () => {
        onInProgress(true);
        const response = await service('organizationService', 'deleteRange', selectedItems);
        onInProgress(false);
        return response;
    };
    // TO DO: Необходим рефакторинг механизма задания значения fieldsVisible
    const fieldsForExcel = fieldsConfig.map(item => {
        let temp = {};
        temp[ item.alias ] = item.title;
        return temp;
    })

    return (
        <>
            {inProgress && <Overlay/>}
            <section className={(type + " list")}>
                <Title type={type} title={pageTitle} titleImg={'organizationImg'}/>
                <div className={type + "__buttons action_panel manage_panel list__manage-panel"}>
                    <ActionPanel
                        setSelectedAction={setSelectedAction}
                        onRemoveValues={onRemoveValues}
                        filterForRequest={filterForRequest}
                        fieldsVisible={fieldsForExcel}
                        deleteActionCallback={deleteActionCallback}
                        fileUploadCallBack={() => {}}
                        selectedItems={selectedItems}
                        isProgress={inProgress}
                        onInProgress={onInProgress}
                        sorting={sorting}
                    />
                    <SimpleSearch
                        onChange={setSearchValue}
                        onSearch={onSearchHandler}
                        value={searchValue}
                        placeholder={'Наименование'}
                        noHint
                    />
                </div>
                <Table
                    totalCount={totalCount}
                    list = {list}
                    listFieldsVisible = {fieldsVisible}
                    sortListAction = {sortListAction}
                    paginator = {paginator}
                    sorting = {sorting}
                    selectedItems = {selectedItems}
                    setSelectedAction = {setSelectedAction}
                    fieldsConfig={fieldsConfig}
                    inProgress = {inProgress}
                    itemsSelectable={true}
                    changedFieldsVisibility={false}
                    onItemClick={onItemClickHandler}
                    headerInfo={true}
                    itemsSelectableRadio={false}
                    setSelectedPagination = {setSelectedPagination}
                    selectedObjects = {selectedObjects}
                />
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                    perPage={perPage}
                />
            </section>
        </>
    );
}
