import React from 'react'

import { ReactComponent as LogoImg } from 'assets/img/logo.svg'


const applicationLogo = ({history}) => {
  return (
    <div className={`application-logo`} onClick={() => history.push('/')}>
      <LogoImg
        className='application-logo__logo'
      />
    </div>
  )
}

export default applicationLogo