/** @format */
import { useState, useRef, useEffect } from 'react';
import Alert from 'app/components/ui/Alert/Alert';
import Severity from 'app/core/types/severity';
import FileUploadControl from 'app/components/ui/file/fileUploadControl';
import { fileListAPI } from 'app/core/utility/file';
import { ONE_MB_IN_BYTES, exceedFileSizeError, exceedFileTypeError, validFileExtensions } from './consts';
import Header from 'app/components/HOC/ModalHOC/header';
import Button, { buttonTypes } from 'app/components/ui/button/button';
import ButtonsPanel from 'app/components/HOC/ModalHOC/buttons';
import styles from './import.module.scss';
import withModal from 'app/components/HOC/ModalHOC';
import { stringPlural } from 'app/core/utility/common';

const MassImportMessage = () => {
  return (
    <>
      Для каждого загруженного сертификата будет создан ключевой документ
      <br />
      Добавьте сертификат (cer, crt, pem, p7b). Максимальный размер одного файла 1 Мб.
    </>
  );
};

const KeyDocumentImportMessage = () => {
  return <>Добавьте сертификат (cer, crt, pem, p7b). Максимальный размер одного файла 1 Мб.</>;
};

const ErrorMessage = ({ count }) => {
  const variants = [
    'файл не соответствует ограничениям и не будет загружен',
    'файла не соответствуют ограничениям и не будут загружены',
    'файлов не соответствуют ограничениям и не будут загружены',
  ];
  return <>{`${count} ${stringPlural(count, variants)}`}</>;
};

function ImportCertificate({ massImport, keyDocumentId, onCancel, setInProgress, onCompletion, okz }) {
  const urlPart = massImport ? `KeyDocument/orgtype-${okz ? 'okz' : 'oki'}/certificate/files` : `KeyDocument/${keyDocumentId}/certificate/files`;
  const fileApi = fileListAPI(null, urlPart);
  const fileControl = useRef(null);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [importDisabled, setImportDisabled] = useState(true);
  const message = massImport ? <MassImportMessage /> : <KeyDocumentImportMessage />;

  const massImportFilesValidation = files => {
    return files.map(file => {
      return file.blob?.size > ONE_MB_IN_BYTES
        ? {
            ...file,
            error: exceedFileSizeError,
          }
        : file;
    });
  };

  const keyDocFilesValidation = files => {
    const allowedExtensions = validFileExtensions.reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {});

    const sizeRestrictions = files.map(file => {
      return file.blob?.size > ONE_MB_IN_BYTES
        ? {
            ...file,
            error: exceedFileSizeError,
          }
        : { ...file, error: null };
    });

    const extRestrictions = sizeRestrictions.map(file => {
      if (file.error) {
        return file;
      }

      const ext = file.blob?.name?.split('.')[1];
      const actualFile = allowedExtensions[ext]
        ? { ...file, error: null }
        : {
            ...file,
            error: exceedFileTypeError,
          };
      allowedExtensions[ext] = false;
      return actualFile;
    });

    return extRestrictions;
  };

  const onUpload = () => {
    setInProgress(true);
    fileControl.current._upload();
  };

  useEffect(() => {
    currentFiles && setImportDisabled(!currentFiles.some(i => !i.error));
    setInvalidFiles(currentFiles.filter(file => !!file.error));
  }, [currentFiles]);

  return (
    <div className={styles.import}>
      <Header className={styles.header} title={`Загрузка сертификат${massImport ? 'ов' : 'а'}`} onCancel={onCancel} />
      {invalidFiles.length !== 0 && (
        <Alert
          showIcon
          className={styles.alert}
          severity={Severity.Error}
          content={<ErrorMessage count={invalidFiles.length} />}
        />
      )}
      <Alert className={styles.alert} severity={Severity.Info} content={message} />
      <FileUploadControl
        isMultiple
        uploadAllFilesAtOnce
        isShowFileProgressInHeader={false}
        ref={fileControl}
        title="Добавить сертификат"
        fileApi={fileApi}
        onFileChanged={setCurrentFiles}
        onUploadFinish={data => {
          const { axiosResult } = data;
          onCompletion(axiosResult);
        }}
        allFilesValidation={massImport ? massImportFilesValidation : keyDocFilesValidation}
        accept={validFileExtensions.map(i => `.${i}`).join(', ')}
      />
      <ButtonsPanel>
        <Button type={buttonTypes.primary} onClick={onUpload} caption="Загрузить" disabled={importDisabled} />
        <Button type={buttonTypes.secondary} onClick={onCancel} caption="Отмена" />
      </ButtonsPanel>
    </div>
  );
}

export default withModal(ImportCertificate);
