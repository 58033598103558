import React, { Component } from 'react'

import Input from 'app/components/ui/Input'

class Number extends Component {
  constructor(props){
    super(props)

    this.input = React.createRef()
  }

  _onDecrement = () => {
    const { value } = this.props
    this._onChange((value || 0) - 1)

    this.input.current.focus()
  }

  _onIncrement = () => {
    const { value } = this.props
    this._onChange((value || 0) + 1)

    this.input.current.focus()
  }

  _onChange = (value) => {
    const { onChange, min = 0, max } = this.props
    const notEmptyValue = parseInt(value || 0)

    const correctedValue = !max && max !== 0
                            ? !min && min !== 0
                                ? notEmptyValue 
                                : Math.max(notEmptyValue, min)
                            : Math.min(Math.max(notEmptyValue, min), max)

    correctedValue === notEmptyValue && onChange && onChange( correctedValue )
  }

  render() {
    const { placeholder, className } = this.props 

    return (
      <Input 
        {...this.props}
        ref={this.input}
        className={`${className || ''} number`}
        placeholder={placeholder || '0'}
        pattern='^[0-9]*'
        onChange={this._onChange}
      />
    )
  }
}

export default Number