import React from "react";

const BigUploadItem = ({name, size, progress}) => {
    return <div className={'big-upload-item'}>
        <div className={'big-upload-item__name'}>{name}</div>
        <div className={'big-upload-item__size'}>
            ({size} Мб)
        </div>
        <div className={'big-upload-item__progressbar'}>
            <div
                className={`${progress === 'infinity' ? 'infinity-bar' : ''} upload-list__item-bar`}
                style={{width: progress === 'infinity' ? '80px' : progress}}
            />
        </div>
        <div>
            {
                progress
            }
        </div>
    </div>;
};

export default BigUploadItem;
