import { get, post, downloadFileGET, del } from '../core/serverRequest/request'

import { sortDirection } from 'app/components/ui/constants'

export default {
  getBuild: (buildId) => {
    return get(`SkziBuild/${buildId}`)
  },

  updateBuild: (data) => {
    const { id } = data

    return post(`SkziBuild/${id}`, data)
  },
  
  getBuildContent: ({ skziId, versionId, buildId }) => {
    return post(`skzi/${skziId}/version/${versionId}/build/${buildId}/content`)
  },

  getBuildFiles: ({ skziId, versionId, buildId }) => {
    return downloadFileGET(`skzi/${skziId}/version/${versionId}/build/${buildId}/file/all`)
  },
  
  updateBuildFile: ({ skziId, versionId, buildId, fileGuid, userHashSum }) => {
    post(`skzi/${skziId}/version/${versionId}/build/${buildId}/file/${fileGuid}`, userHashSum)
  },
  
  deleteBuildFile: ({ skziId, versionId, buildId, fileGuid }) => {
    del(`skzi/${skziId}/version/${versionId}/build/${buildId}/file/${fileGuid}`)
  },

  autocompleteDependsOn: (data) => {
    const {term, dependsValue, page, pageSize} = data
    const { version } = dependsValue || {}
    const { id } = version || {}
    const conditions = {
      names: [
        term
      ],
      skziVersionId: id
    }
    const filter = {
      conditions,
      sortBy: "name",
      sortDirection: sortDirection.asc,
      page, pageSize
    }

    return post('SkziBuild/filter', filter)
  },

  autocomplete: ({term, page, pageSize}) => {
    const conditions = {
      names: [
        term
      ],
      producers: [],
      types: []
    }
    const filter = {
      conditions,
      sortBy: "name",
      sortDirection: sortDirection.asc,
      page, pageSize
    }

    return post('SkziBuild/filter', filter)
  },
}