/** @format */

import React from 'react';

import { stringPlural } from 'app/core/utility/common';
import { percentage } from 'app/core/utility/common';
import Doughnut from 'app/components/ui/charts/Doughnut/Doughnut';

import styles from './EskziChart.module.scss';
import { oneHundredPercentMagic } from '../chartLegend';

export default function EskziChart({ eskzi = {}, licenses = {} }) {
  const {
    available: availableEskzis = 0,
    issued: issuedEskzis = 0,
    moved: movedEskzis = 0,
    total: totalEskzis = 0,
  } = eskzi;
  const {
    available: availableLicenses = 0,
    used: usedLicenses = 0,
    total: totalLicenses = 0,
  } = licenses;

  let availableEskziPercent = percentage(totalEskzis, availableEskzis);
  let issuedPercent = percentage(totalEskzis, issuedEskzis);
  let movedPercent = percentage(totalEskzis, movedEskzis);

  let availableLicensesPercent = percentage(totalLicenses, availableLicenses);
  let usedPercent = percentage(totalLicenses, usedLicenses);

  [availableEskziPercent, issuedPercent, movedPercent] = oneHundredPercentMagic(
    availableEskziPercent,
    issuedPercent,
    movedPercent
  );
  [availableLicensesPercent, usedPercent] = oneHundredPercentMagic(
    availableLicensesPercent,
    usedPercent
  );

  return (
    <Doughnut
      vm={[
        {
          r: 122,
          width: 24,
          className: styles.subchart,
          invalidChartClassName: styles.arcInvalid,
          gap: 0.03,
          items: [
            {
              text: `${availableEskziPercent}% Доступно: ${availableEskzis}`,
              value: availableEskzis,
              className: styles.arcEskziAvailable,
            },
            {
              text: `${issuedPercent}% Выдано пользователю: ${issuedEskzis}`,
              value: issuedEskzis,
              className: styles.arcEskziIssued,
            },
            {
              text: `${movedPercent}% Передано в организации: ${movedEskzis}`,
              value: movedEskzis,
              className: styles.arcEskziMoved,
            },
          ],
        },
        {
          r: 80,
          width: 8,
          className: styles.subchart,
          invalidChartClassName: styles.arcInvalid,
          gap: 0.04,
          items: [
            {
              text: `${availableLicensesPercent}% Доступно: ${availableLicenses}`,
              value: availableLicenses,
              className: styles.arcLicensesAvailable,
            },
            {
              text: `${usedPercent}% Использовано: ${usedLicenses}`,
              value: usedLicenses,
              className: styles.arcLicensesUsed,
            },
            { value: Math.max(0, totalEskzis - totalLicenses) },
          ],
        },
      ]}
      width={268}
      height={268}
      className={styles.chart}
    >
      <circle cx="134" cy="134" r="76" fill="#fafafa" />
      <text
        x="134"
        y="120"
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.chartTextAccent}
      >
        {totalEskzis}
      </text>
      <text
        x="134"
        y="155"
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.chartTextCommon}
      >
        {stringPlural(totalEskzis, ['экземпляр', 'экземпляра', 'экземпляров'])}
      </text>
    </Doughnut>
  );
}

