import React, { useState } from 'react';
import Button from 'app/components/ui/button/button';
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import { ReactComponent as IconClose } from "assets/img/commonVer2/close-v2.svg";
import useToast from "app/components/ui/toast/useToast";


export default function LinkKeyDocButton(props) {
  const { 
    disabled = false,
    eskziId, 
    eventId,
    onSubmitSignEnhance,
    getSignRequestData,
    generateTextErrors
  } = props 

  const {addToast} = useToast()
  const [ confirmCardRemove, setConfirmCardRemove ] = useState(null);
  const confirmObject = {
    header: "Отменить мероприятие?",
    messages: ["При этом все связанные события будут отменены. Действие по отмене мероприятия будет сохранено в истории операций."],
    type: "confirm",
    cancelText: "Закрыть",
    submitText: "Отменить"
  }

  const cardSubmitAction = async () => {
    const requestData = {
      eskziId : eskziId,
      eventId : eventId,
      // signatureRequestType: 'deleteActivity'
    };
    const result = await onSubmitSignEnhance(requestData)
    const { isError, isWarn , errors = []} = result || {}
    !isError && setConfirmCardRemove( null );
    !isError && addToast('Мероприятие успешно отменено')
  }

  const confirmRemoveAction = () => {
    setConfirmCardRemove( confirmObject );
  }

  const cancelConfirmRemoveAction = () => {
    setConfirmCardRemove( null );
  }

  return (
    <>
      <Button
        className='card-management__item'
        type = 'secondary'
        disabled={disabled}
        onClick = {confirmRemoveAction}
      >
        <IconClose className='button-image' />
        <span className='button-title'>Отменить мероприятие</span>
      </Button>
      {confirmCardRemove ? 
        <ConfirmDialog
          {...confirmCardRemove}
          onSubmit={cardSubmitAction}
          onCancel={cancelConfirmRemoveAction}
        /> : null
      }
    </>
  )
}
