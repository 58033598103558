/** @format */
import { useEffect, useState } from 'react';
import withModal from 'app/components/HOC/ObsoleteModalHOC';
import styles from './form.module.scss';
import Autocomplete, { Services } from 'app/components/ui/autocomplete/autocomplete';
import Input from 'app/components/ui/Input';
import useCreateCertificate from 'app/components/signature/cryptopro/certRequest/useCreateCertificate';
import DropDown from 'app/components/ui/DropDown';
import { keyUsageTypes } from 'app/components/signature/cryptopro/certRequest/utility';
import Button from 'app/components/ui/button/button';
import Overlay from 'app/components/ui/overlay';

const filterKeySpecByTemplate = templateKeySpec => {
  switch (templateKeySpec) {
    case 'Signature':
      return keySpecList.filter(i => i.value === keyUsageTypes.signature);
    case 'KeyExchange':
      return keySpecList.filter(i => i.value === keyUsageTypes.both);
    case 'Unspecified':
    default:
      return keySpecList;
  }
};

const keySpecList = [
  {
    title: 'Подпись и шифрование',
    value: keyUsageTypes.both,
  },
  {
    title: 'Подпись',
    value: keyUsageTypes.signature,
  },
  {
    title: 'Шифрование',
    value: keyUsageTypes.keyExchange,
  },
];

const filterKeySpec = keySpec => {
  if (!keySpec || keyUsageTypes.both === keySpec) {
    return keySpecList;
  }

  return keySpecList.filter(i => i.value === keySpec);
};

const getAvailableKeySpec = (providerkeySpec, templateKeySpec) => {
  const filterByProvider = filterKeySpec(providerkeySpec);
  const filterByTemplate = filterKeySpecByTemplate(templateKeySpec);
  const resultKeySpec = filterByProvider.filter(i =>
    filterByTemplate.find(s => s.value === i.value)
  );
  return resultKeySpec;
};

const createEnabled = ({
  selectedProvider,
  selectedAlgorithm,
  template,
  keySize,
  CAUser,
  user,
  userId
}) => {
  return selectedProvider && selectedAlgorithm && template && keySize && (CAUser && !userId ? !!user : true);
};

function CreateForm({ onCancel, afterCreationCallback, userId, CAUser }) {
  const {
    providerList,
    algorithmList,
    supportedKeySpec,
    selectedProvider,
    selectedAlgorithm,
    template,
    user,
    comment,
    keySize,
    keySizeList,
    inProgress,
    setSelectedAlgorithm,
    setSelectedProvider,
    setKeySpec,
    setTemplate,
    setUser,
    setComment,
    setKeySize,
    setInProgress,
    createRequest,
  } = useCreateCertificate({ afterCreationCallback, userId, CAUser });
  const [keySpecItem, setKeySpecItem] = useState(false);

  const handleKeySpecChange = value => {
    setKeySpecItem(value);
    setKeySpec(value.value);
  };

  useEffect(() => {
    if (!template || !selectedProvider || !supportedKeySpec) {
      return;
    }
    const currentKeySpec = getAvailableKeySpec(supportedKeySpec, template?.keySpec);
    currentKeySpec[0] && setKeySpecItem(currentKeySpec[0]);
  }, [selectedProvider, supportedKeySpec, template]);

  const onSubmit = async () => {
    setInProgress(true);
    await createRequest();
    setInProgress(false);
  };

  return (
    <div className={styles.container}>
      {inProgress ? <Overlay /> : null}
      <div className={styles.header}>Запрос на сертификат</div>
      <div className={styles.form}>
        {!userId && CAUser && (
          <Autocomplete
            label="Пользователь сертификата"
            notStandardService={{
              serviceName: 'certificateAuthorityService',
              serviceMethod: 'servedUsersAutocomplete',
            }}
            onSelect={setUser}
            value={user}
            className={styles.user}
          />
        )}
        <Autocomplete
          label="Шаблон"
          placeholder="Для начала работы выберите шаблон"
          notStandardService={{
            serviceName: 'certificateRequestService',
            serviceMethod: 'templates',
          }}
          onSelect={setTemplate}
          value={template}
          className={styles.template}
        />
        <DropDown
          label="Криптопровайдер"
          placeholder="Выберите"
          className={styles.provider}
          items={providerList}
          active={selectedProvider}
          onSelect={setSelectedProvider}
          disabled={!template}
          noClearIcon
        />
        <DropDown
          label="Использование"
          placeholder="Выберите"
          className={styles.cases}
          items={getAvailableKeySpec(supportedKeySpec, template?.keySpec)}
          active={keySpecItem}
          onSelect={handleKeySpecChange}
          disabled={!template}
          noClearIcon
        />
        <DropDown
          label="Размер ключа"
          placeholder="Выберите"
          className={styles.keySize}
          items={keySizeList}
          active={keySize}
          onSelect={setKeySize}
          disabled={!template}
          noClearIcon
        />
        <DropDown
          label="Алгоритм шифрования"
          placeholder="Выберите"
          className={styles.algorithm}
          items={algorithmList}
          active={selectedAlgorithm}
          onSelect={setSelectedAlgorithm}
          disabled={!template}
          noClearIcon
        />
        <Input
          className={styles.comment}
          label="Комментарий"
          value={comment}
          onChange={setComment}
          disabled={!template}
        />
      </div>
      <div className={styles.management}>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={
            !createEnabled({ selectedProvider, selectedAlgorithm, template, keySize, CAUser, user, userId })
          }
        >
          <span className="button-title">Создать</span>
        </Button>
        <Button type="secondary" onClick={onCancel}>
          <span className="button-title">Отмена</span>
        </Button>
      </div>
    </div>
  );
}

export default withModal(CreateForm);

