import React, { Fragment } from "react";
import {formatDate} from "app/core/utility/date";
import {getIconByState} from "app/components/MassImports/utils";

const commonCols = {
    state: {
        title: '',
        alias: 'state',
        visibleOnStart: true,
        useSorting: true,
        format: el => getIconByState(el),
        sortByValue: el => el.isValid,
        width: 50,
    },
    lineId: {
        title: '№',
        alias: 'lineId',
        visibleOnStart: true,
        useSorting: true,
        width: 120,
        sortByValue: el => el.lineId,
        format: el => <>{el.lineId}</>
    },
    number: {
        title: '№',
        alias: 'number',
        visibleOnStart: true,
        useSorting: true,
        sortByValue: el => el.key,
    },
    comments: {
        title: 'Примечание',
        alias: 'comments',
        visibleOnStart: true,
        useSorting: true,
        width: 220,
        sortByValue: el => el.comments,
    },
    result: {
        title: 'Результат',
        alias: 'result',
        visibleOnStart: true,
        useSorting: true,
        sortByValue: el => el.result,
        format: el => Array.isArray(el.result) ? <>{el.result.map((item, i) => <Fragment key={i}>{item}<br /></Fragment>)}</> : el.result,
    },
    snils: {
        title: 'СНИЛС',
        alias: 'snils',
        visibleOnStart: true,
        useSorting: true,
        width: 220,
        sortByValue: el => el.snils,
    },
    expirationDate: {
        title: 'Срок действия',
        alias: 'expirationDate',
        visibleOnStart: true,
        useSorting: true,
        width: 220,
        sortByValue: el => el.expirationDate,
    },
    purpose: {
        title: 'Назначение',
        alias: 'comment',
        visibleOnStart: true,
        useSorting: true,
        width: 220,
        sortByValue: el => el.comment,
    },
    isStandardExemplar: {
        title: 'Эталонный экземпляр',
        alias: 'isStandard',
        visibleOnStart: true,
        useSorting: true,
        width: 220,
        sortByValue: el => el.isStandard,
    },
    receivedFrom: {
        title: 'От кого получены',
        alias: 'receivedFrom',
        visibleOnStart: true,
        useSorting: true,
        width: 220,
        sortByValue: el => el.receivedFrom,
    },
    inn: {
        title: 'ИНН',
        alias: 'inn',
        visibleOnStart: true,
        useSorting: true,
        width: 220,
        sortByValue: el => el.inn,
    },
    deliveryTransmittalLetterNumber: {
        title: 'Номер сопроводительного письма о получении',
        alias: 'deliveryTransmittalLetterNumber',
        visibleOnStart: true,
        useSorting: true,
        width: 270,
        sortByValue: el => el.deliveryTransmittalLetterNumber,
    },
    deliveryTransmittalLetterDate: {
        title: 'Дата сопроводительного письма о получении',
        alias: 'deliveryTransmittalLetterDate',
        visibleOnStart: true,
        useSorting: true,
        width: 250,
        sortByValue: el => el.deliveryTransmittalLetterDate,
    },
};

export const RESULTS = {
    ORGANIZATION: {
        fieldsConfig: [
            commonCols.state,
            commonCols.lineId,
            {
                title: 'Наименование',
                alias: 'name',
                visibleOnStart: true,
                useSorting: true,
                sortByValue: el => el.name,
            },
            {
                title: 'Короткое наименование',
                alias: 'shortName',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.shortName,
            },
            commonCols.inn,
            {
                title: 'ОГРН/ОГРНИП',
                alias: 'ogrn',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.ogrn,
            },
            {
                title: 'КПП',
                alias: 'kpp',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.kpp,
            },
            {
                title: 'Примечание',
                alias: 'description',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.description,
            },
            commonCols.result,
        ],
    },
    LICENSE: {
        fieldsConfig: [
            commonCols.state,
            commonCols.lineId,
            {
                title: 'Лицензионный ключ',
                alias: 'key',
                visibleOnStart: true,
                useSorting: true,
                sortByValue: el => el.key,
            },
            {
                title: 'Количество лицензий',
                alias: 'amount',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.amount,
            },
            commonCols.result,
        ],
    },
    USER_ACCOUNT: {
        fieldsConfig: [
            commonCols.state,
            commonCols.lineId,
            {
                title: 'Фамилия',
                alias: 'lastName',
                visibleOnStart: true,
                useSorting: true,
                sortByValue: el => el.lastName,
            },
            {
                title: 'Имя',
                alias: 'firstName',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.firstName,
            },
            {
                title: 'Отчество',
                alias: 'middleName',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.middleName,
            },
            commonCols.snils,
            {
                title: 'Должность',
                alias: 'position',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.position,
            },
            {
                title: 'Дата открытия лицевого счёта',
                alias: 'accountCreateDate',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.accountCreateDate,
            },
            {
                title: 'Дата закрытия лицевого счёта',
                alias: 'accountCloseDate',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.accountCloseDate,
            },
            commonCols.comments,
            commonCols.result,
        ],
    },
    KEY_CARRIER: {
        fieldsConfig: [
            commonCols.state,
            commonCols.lineId,
            {
                title: 'Серийный номер носителя',
                alias: 'serialNumber',
                visibleOnStart: true,
                useSorting: true,
                sortByValue: el => el.serialNumber,
            },
            {
                title: 'Заводской номер носителя',
                alias: 'factoryNumber',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.factoryNumber,
            },
            {
                title: 'Признак разового носителя',
                alias: 'single',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.single,
            },
            {
                title: 'Описание',
                alias: 'comment',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.comment,
            },
            commonCols.result,
        ],
    },
    OKZ_KEY_DOCUMENT: {
        fieldsConfig: [
            commonCols.state,
            commonCols.lineId,
            {
                title: 'Наименование ключевого документа',
                alias: 'name',
                visibleOnStart: true,
                useSorting: true,
                width: 330,
                sortByValue: el => el.name,
            },
            {
                title: 'Серийный номер ключевого документа',
                alias: 'serialNumber',
                visibleOnStart: true,
                useSorting: true,
                width: 340,
                sortByValue: el => el.serialNumber,
            },
            commonCols.receivedFrom,
            commonCols.deliveryTransmittalLetterNumber,
            commonCols.deliveryTransmittalLetterDate,
            commonCols.expirationDate,
            commonCols.purpose,
            commonCols.result,
        ],
    },
    OKI_KEY_DOCUMENT: {
        fieldsConfig: [
            commonCols.state,
            commonCols.lineId,
            {
                title: 'Наименование ключевого документа',
                alias: 'name',
                visibleOnStart: true,
                useSorting: true,
                width: 320,
                sortByValue: el => el.name,
            },
            {
                title: 'Серийный номер ключевого документа',
                alias: 'serialNumber',
                visibleOnStart: true,
                useSorting: true,
                width: 320,
                sortByValue: el => el.serialNumber,
            },
            commonCols.receivedFrom,
            commonCols.deliveryTransmittalLetterNumber,
            commonCols.deliveryTransmittalLetterDate,
            commonCols.expirationDate,
            commonCols.purpose,
            commonCols.result,
        ],
    },
    OKZ_KEY_ESKZI: {
        fieldsConfig: [
            commonCols.state,
            commonCols.number,
            {
                title: 'Наименование СКЗИ',
                alias: 'skziName',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.skziName,
            },
            {
                title: 'Регистрационные номера элементов поставки',
                alias: 'serialNumber',
                visibleOnStart: true,
                useSorting: true,
                width: 260,
                sortByValue: el => el.serialNumber,
            },
            commonCols.isStandardExemplar,
            
            commonCols.receivedFrom,
            commonCols.deliveryTransmittalLetterDate,
            commonCols.deliveryTransmittalLetterNumber,
            
            commonCols.result,
        ],
    },
    OKI_KEY_ESKZI: {
        fieldsConfig: [
            commonCols.state,
            commonCols.number,
            {
                title: 'Наименование СКЗИ',
                alias: 'skziName',
                visibleOnStart: true,
                useSorting: true,
                width: 220,
                sortByValue: el => el.skziName,
            },
            {
                title: 'Регистрационные номера элементов поставки',
                alias: 'serialNumber',
                visibleOnStart: true,
                useSorting: true,
                width: 260,
                sortByValue: el => el.serialNumber,
            },
            commonCols.isStandardExemplar,
            
            commonCols.receivedFrom,
            commonCols.deliveryTransmittalLetterDate,
            commonCols.deliveryTransmittalLetterNumber,
            
            commonCols.result,
        ],
    },
};
