import React from 'react'

import Tooltip from '../ui/Tooltip'

import styles from './TooltipHOC.module.scss'

const withTooltip = PassedComponent => {
  class WithTooltip extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        hovered: false,
      }
    }

    _onMouseEnter = () => {
      if( !this.props.tooltip ) return;
      this.setState({ hovered: true })
    }
  
    _onMouseLeave = () => {
      this.setState({ hovered: false })
    }

    render() {
      const { forwardedRef, tooltip, wrap, tooltipPosition = 'bottom', ...rest } = this.props;
      const { hovered } = this.state
      
      return PassedComponent ? (
        <div 
          className={styles.tooltipWrapper}
          onMouseEnter={this._onMouseEnter}
          onMouseLeave={this._onMouseLeave}
          onPointerLeave={this._onMouseLeave}
        >
          <PassedComponent
            {...rest}
            ref={forwardedRef}
          />
          {hovered && tooltip && <Tooltip tooltip={tooltip} position={tooltipPosition} wrap={wrap}/> }
        </div>
      ) : null
    }
  }

  return React.forwardRef((props, ref) => {
    return <WithTooltip {...props} forwardedRef={ref} />;
  });
}
  
export default withTooltip

export const Position = {
  Bottom: 'bottom',
  BottomLeft: 'bottomLeft',
  Left: 'left',
  Right: 'right',
};