import React from 'react'

import Button from 'app/components/ui/button/button'

export default function SubmitButton (props) {
  const { onSubmit, label } = props

  return (
    <div className="submit-container">
      <Button
        className='btn-submit'
        onClick={onSubmit}
      >
        <span className='button-title'>{label}</span>
      </Button>
    </div>
  )
}