import { 
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  CLEARERROR,
  TRAININGCOURSECARDGET,
  TRAININGCOURSECARDUPDATE,
  TRAININGCOURSECARDUPDATEFORMDATA,
  TRAININGCOURSECARDINPROGRESS,
  TRAININGCOURSECARDRESETACTION,
  TRAININGCOURSECARDGETTYPES ,    
  TRAININGCOURSECARDONSELECT,
  TRAININGCOURSECARDONTYPEADD,
  TRAININGCOURSECARDONFILEADD,
  TRAININGCOURSECARDONTYPEDELETE,
  TRAININGCOURSECARDTYPEDELETE,
  TRAININGCOURSECARDTYPEADD,
  TRAININGCOURSECARDONFILEDELETE,
  TRAININGCOURSECARDFILEDELETE
  } from '../../actions/actionTypes'
  
  import { dialogType } from 'app/components/dialog/confirmDialog/'
  import { excludeByItemId, arrayToIdHashMap } from 'app/core/utility/common'

  
  const defaultState = {
    selected: {
      items: [],
      selectedHashMap: {}
    },
    commonDataForm : {
      changeDate: null,
      isActive: true,
      name: ''
    },
    viewData: {
      changeDate: null,
      isActive: true,
      name: ''
    },
    createTypeForm : {

    },
    courseData: {},
    confirmObject: null,
    addTypeDialog: false,
    addFilesDialog: false,
    deleteTypeConfirm: null,
    currentlyDeletingFiles: {},
    inProgress: false
  }
  
  export default (state = defaultState, action) => {
    const { payload, errors } = action
    const { courseData, viewData, selected, currentlyDeletingFiles } = state
    const { materials } = courseData
      
    switch (action.type) {
      case TRAININGCOURSECARDONSELECT: 
        return {
          ...state,
          selected: payload || defaultState.selected
        }

      case ERRORPAGEERROR:
        return {
            ...state,
            inProgress: false
        }
      case TRAININGCOURSECARDUPDATE: 
      case TRAININGCOURSECARDGET:

        return {
          ...state,
          commonDataForm: {...payload },
          viewData: {...payload},
          courseData: {...payload},
          inProgress: false
        }

      case TRAININGCOURSECARDTYPEADD:

        return {
          ...state,
          courseData: {
            ...courseData ,
            materials: [
              payload,
              ...materials
            ]
          },
          inProgress: false
        }
   
      case TRAININGCOURSECARDONTYPEADD:
        return {
          ...state,
          addTypeDialog: true,
          inProgress: false
        }

      case TRAININGCOURSECARDONFILEDELETE:
        const { guid: deletingFileGuid } = payload

        return {
          ...state,
          currentlyDeletingFiles: {
            ...currentlyDeletingFiles,
            [deletingFileGuid]: deletingFileGuid
          }
        }

      case TRAININGCOURSECARDFILEDELETE:
        const { guid, typeElement } = payload
        const affectedTypeIndex = materials.findIndex(item => item.id === typeElement.id)
        const updatedFiles = materials[affectedTypeIndex].files.filter(file => file.guid !== guid)

        const afterDeleteFileDocumentation = [
          ...materials.slice(0, affectedTypeIndex),
          {
            ...materials[affectedTypeIndex],
            files: updatedFiles
          },
          ...materials.slice(affectedTypeIndex + 1),
        ]
        
        return {
          ...state,
          courseData: {
            ...courseData,
            materials: afterDeleteFileDocumentation
          },
        }

      case TRAININGCOURSECARDONFILEADD:
        return {
          ...state,
          addFilesDialog: true,
        }

      case CLEARERROR:
        return {
          ...state,
          confirmObject: null,
          deleteTypeConfirm: null,
          inProgress: false
        }
      
      case FIELDSERROR:
      case ERROR:
        return {
          ...state,
          inProgress: false
        }

      case TRAININGCOURSECARDGETTYPES:
        return {
          ...state,
          courseData: {
            ...courseData,
            materials: payload
          },
          inProgress: false
        }
   
      case TRAININGCOURSECARDONTYPEDELETE:

        return {
          ...state,
          deleteTypeConfirm: {
            item: payload,
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить элементы: «${payload.map(item => item.name).join(', ')}»?`
          },
          inProgress: false
        }

      case TRAININGCOURSECARDTYPEDELETE:
        const { items: selectedItems } = selected
        let confirmDialog = null
        const deletedObjects = payload.map(item => ({ id:item }))
        const updatedSelected = excludeByItemId(selectedItems, deletedObjects)
        const updatedMaterials = excludeByItemId(materials, deletedObjects)

        if (errors && errors.length) {
          confirmDialog = {
            header: 'Удаление невозможно',
            type: dialogType.warning,
            messages: errors.map(item => item.errorMessage)
          }
        }
  
        return {
          ...state,
          selected: {
            items: updatedSelected,
            selectedHashMap: arrayToIdHashMap(updatedSelected)
          },
          courseData: {
            ...courseData,
            materials: updatedMaterials
          },
          deleteTypeConfirm: null,
          confirmObject: confirmDialog,
          inProgress: false
        }

      case TRAININGCOURSECARDUPDATEFORMDATA: 
        return {
          ...state,
          commonDataForm: {...payload, classId: payload.class},
        }

      case TRAININGCOURSECARDINPROGRESS:
        return {...state, inProgress: payload}
  
      case TRAININGCOURSECARDRESETACTION: 
  
        return {
          ...defaultState,
          viewData: viewData,
          selected: selected,
          courseData: courseData,
          commonDataForm: viewData,
        }
  
      default: 
        return state
    }
  }