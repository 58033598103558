/** @format */

import DownloadXlsButton from 'app/components/ui/button/downloadXlsButton';
import { Image } from 'app/components/ui/Img';

export default function JournalDownload(props) {
  const { items, buttonText } = props;
  const [item] = items || [];
  const { id, technicalJournalAvailable } = item || {};
  const disabled = !items || items.length !== 1 || !technicalJournalAvailable;

  return (
    <DownloadXlsButton
      url={`Eskzi/${id}/technicaljournal`}
      items={items}
      tooltip="Технический аппаратный журнал"
      Img={Image.Journal}
      disabled={disabled}
      buttonText={buttonText ? 'Технический аппаратный журнал' : false}
    />
  );
}
