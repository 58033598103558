export const RESOURCES = {
  eskziActions: 'Eskzi/Action',
  keyDocActions: 'KeyDocument/Action',
  report: 'Report/Entity',
  eskzi: 'Eskzi/Entity',
  eskziHistory: 'Eskzi/History',
  externalOrgEskzi: 'Eskzi/Entity/ExternalOki',
  skzi: 'Skzi/Entity',
  build: 'Skzi/Build',
  version: 'Skzi/Version',
  userAccount: 'EskziUser/Entity',
  keyDocument: 'KeyDocument/Entity',
  organization: 'Organization/Entity',
  organizationOki: 'Organization/Oki',
  skziType: 'SkziType/Entity',
  objectType: 'ObjectType/Entity',
  keyCarrierType: 'KeyCarrierType/Entity',
  eskziStatus: 'EskziStatus/Entity',
  KeyCarrierType: 'KeyCarrierType/Entity',
  files: 'Skzi/File',
  user: 'User/Entity',
  eskziTechJournal: 'Eskzi/TechnicalJournal',
  course: 'Course/Entity',
  courseMaterials: 'Course/Materials',
  admittance: 'Admittance/Entity',
  admittanceFiles: 'Admittance/Content',
  skziLicense: 'SkziLicense/Entity',
  extOrgSkziLicense: 'SkziLicense/Entity/ExternalOki',
  skziLicenseFiles: 'SkziLicense/Content',
  maintenanceRecords: 'Eskzi/Maintenance',
  maintenance: 'EskziUsageCertificate/Entity',
  maintenanceFiles: 'EskziUsageCertificate/Content',
  keyCarrier: 'KeyCarrier/Entity',
  destructionCertificate: 'EskziDestructionCertificate/Entity',
  okzScheme: 'Organization/OkzScheme',
  volumeRights: 'PersistentGrid/Volume/Entity',
  eskziLicenceLink: 'EskziLicense/Entity', // связь экземпляра с лицензией
  massImportOrganization: 'MassImport/Organization',
  massImportKeyCarrier: 'MassImport/KeyCarrier',
  massImportKeyDocument: 'MassImport/KeyDocument',
  massImportSkziLicense: 'MassImport/SkziLicense',
  massImportEskzi: 'MassImport/Eskzi',
  dashboard: 'Dashboard',
  okzEskzi: 'Eskzi/Entity/Okz',
  host: 'Host/Entity',
  certRequest: 'CryptoproCA/Certificates',
  certificateAuthorityCertificate: 'CryptoproCA/AcceptorCertificates',
  certificateAuthorityUser: 'CryptoproCA/AcceptorUsers',
  keyDocumentCertificates: 'KeyDocument/Certificates'
}

// 1. Справочники:
//   1.1. EskziStatus/Entity - Статусы экземпляров
//   1.2. KeyCarrierType/Entity - Типы ключевых носителей
//   1.3. Organization/Entity - Организации
//   1.4. SkziClass/Entity - Классы СКЗИ
//   1.5. SkziType/Entity - Типы СКЗИ
// 2. Реестр сертифицированных СКЗИ
//   2.1. Skzi/Entity - СКЗИ
//   2.2. Skzi/Version - Версия СКЗИ
//   2.3. Skzi/Build - Сборка/модификация
//   2.4. Skzi/File - Файлы
// 3. Экземпляры СКЗИ
//   3.1. Eskzi/Entity - Экземпляры СКЗИ
//   3.2. Eskzi/Action - Действия над экземплярами (вкладка "Мероприятия" и выполнение действий в один клик)
// 4. Ключевые документы
//   4.1. KeyDocument/Entity - Ключевые документы
//   4.2. KeyDocument/Action - Действия над ключевыми документами (вкладка "Мероприятия" и выполнение действий в один клик)
// 5. Лицевые счета
//   5.1. EskziUser/Entity - Лицевые счета (Пользователи СКЗИ)
// 6. Пользователи АОКЗ
//   6.1. User/Entity - Пользователи АОКЗ
// 7. Уведомления
//   7.1. Notification/Entity - Уведомления
// 8. Настройки
//   8.1. Settings/Notification - Настройки уведомлений
//   8.2. Settings/System - Системные настройки