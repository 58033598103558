import React, { Component } from 'react'
import { connect } from 'react-redux'

import Checkbox, { checkboxType } from 'app/components/ui/checkbox'
import Number from 'app/components/ui/number/number'
import Setting from './policySetting'
import Button from 'app/components/ui/button/button'
import Overlay from 'app/components/ui/overlay'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import { capitalize } from 'app/core/utility/common'

import withAuth from 'app/components/HOC/AuthHOC'

import * as passwordPolicyActions from 'redux/actions/password/passwordPolicyAction'
import * as errorAction from 'redux/actions/common'
import { breadcrumbsPushAction } from 'redux/actions/common/breadcrumbsAction'

class mainForm extends Component {
  constructor(props){
    super(props)
  }

  UNSAFE_componentWillMount () {
    const { 
      getPasswordPolicyAction, 
      passwordPolicyProgressAction,
      breadcrumbsPushAction,
      location 
    } = this.props
    const { pathname } = location

    breadcrumbsPushAction(pathname, '', true)
    passwordPolicyProgressAction(true)
    getPasswordPolicyAction()
  }

  _onSettingChange = (name, value) => {
    const { passwordPolicyUpdateFormAction, setErrorByKey, errorByFields } = this.props

    const filteredErrors = name !== 'minPasswordValidityPeriod' 
                            ? errorByFields.filter(err => err.field !== capitalize(name))
                            : errorByFields.filter(err => err.field !== capitalize(name) && err.field !== 'MaxPasswordValidityPeriod')
    
    setErrorByKey && setErrorByKey(filteredErrors)
    passwordPolicyUpdateFormAction({name, value})
  }

  _validate = () => {
    const { updatedSettings } = this.props
    const { wrongPasswordBlockTime, wrongPasswordAttempts, sessionLifeTime } = updatedSettings
    const errors = []

    if (wrongPasswordBlockTime === 0
        && wrongPasswordAttempts > 0) {
      errors.push({
        errorCode: "E_VALIDATE",
        errorMessage: "Значение должно быть больше 0",
        field: "WrongPasswordBlockTime",
        isError: true
      })
    }

    if (sessionLifeTime < 0) {
      errors.push({
        errorCode: "E_VALIDATE",
        errorMessage: "Значение должно быть положительным",
        field: "SessionLifeTime",
        isError: true
      })
    }

    return errors.length
            ? errors
            : null
  }

  _hasChanges = () => {
    const { updatedSettings, settings } = this.props

    return JSON.stringify(updatedSettings) !== JSON.stringify(settings)
  }

  _onCancel = () => {
    const { passwordPolicyResetFormAction } = this.props

    passwordPolicyResetFormAction()
  }

  _onApply = () => {
    const { 
      updatePolicyAction,
      passwordPolicyProgressAction,
      updatedSettings,
      setErrorByKey
    } = this.props

    const err = this._validate()

    if (err) {
      setErrorByKey(err)
    } else {
      passwordPolicyProgressAction(true)
      updatePolicyAction(updatedSettings)
    }
  }

  _renderModal = () => {
    const { errorObject, passwordPolicyResetAction } = this.props
    const { title: errorTitle, type: errorType, error } = errorObject || {}

    return (
      <>
        {errorObject ? (
          <ConfirmDialog
            title={errorTitle}
            type={errorType}
            error={error}
            onCancel={passwordPolicyResetAction}
          />) : null
        }
      </>
    )
  }

  render() {
    const { updatedSettings, fieldsError,  inProgress } = this.props
    const { 
      maxPasswordValidityPeriod,
      minPasswordValidityPeriod,
      sessionLifeTime,
      inactivePeriodBeforeBlock,
      wrongPasswordBlockTime,
      wrongPasswordAttempts,
      matchChecking,
      minimumLength,
      lowercaseLettersInPassword,
      uppercaseLettersInPassword,
      digitsInPassword,
      specialSymbolsInPassword,
      passwordRepeatability,
      multipleDevicesLoginAllowed
    } = updatedSettings || {}

    return (
      <section className='password-policy-sttings'>
        {inProgress ? <Overlay/> : null}
        <div className='password-policy'>
          <aside className='password-policy__left-block'>
            <div className='policy-block'>
              <div className='policy-block__header'>Срок действия пароля</div>
              <Setting 
                descTitle='Минимальный'
                description='Количество дней, в течение которых необходимо использовать пароль, прежде чем пользователь сможет сменить его. При значении параметра, равном "0", пароль можно изменить сразу'
              >
                <Number
                  onChange={value => this._onSettingChange('minPasswordValidityPeriod', value)}
                  value={minPasswordValidityPeriod}
                  max={999}
                  error={fieldsError['MinPasswordValidityPeriod']}
                  hint={'Кол-во дней'}
                />
              </Setting>
              <Setting 
                descTitle='Максимальный'
                description='Количество дней, в течение которых может быть использован пароль, прежде чем пользователю необходимо будет сменить его. При значении параметра, равном "0", пароль может использоваться бессрочно'
              >
                <Number
                  onChange={value => this._onSettingChange('maxPasswordValidityPeriod', value)}
                  value={maxPasswordValidityPeriod}
                  max={999}
                  error={fieldsError['MaxPasswordValidityPeriod']}
                  hint={'Кол-во дней'}
                />
              </Setting>
            </div>
            <div className='policy-block'>
              <div className='policy-block__header'>Проверка пароля</div>
              <Setting 
                descTitle='Проверка совпадений'
                description='Запрет совпадения логина и пароля'
                controlHint=''
              >
                <Checkbox 
                  onChange={() => this._onSettingChange('matchChecking', !matchChecking)}
                  checked={matchChecking}
                  type={checkboxType.yesNo}
                />
              </Setting>
              <Setting 
                descTitle='Минимальная длина'
                description='Минимальное количество знаков в пароле. Диапазон значений параметра: 4-20'
              >
                <Number
                  onChange={value => this._onSettingChange('minimumLength', value)}
                  value={minimumLength}
                  max={999}
                  error={fieldsError['MinimumLength']}
                  hint={'Кол-во знаков'}
                />
              </Setting>
              <Setting 
                descTitle='Повторяемость пароля'
                description='Количество новых уникальных паролей, которые должны быть установлены, прежде чем можно будет установить ранее используемый пароль. При значении параметра, равном "0", пользователь может использовать предыдущий пароль'
              >
                <Number
                  onChange={value => this._onSettingChange('passwordRepeatability', value)}
                  max={999}
                  value={passwordRepeatability}
                  error={fieldsError['PasswordRepeatability']}
                  hint={'Кол-во значений'}
                />
              </Setting>
              </div>
            <div className='policy-block'>
              <div className='policy-block__header'>Блокировка пароля</div>
              <Setting 
                descTitle='Ошибки ввода пароля'
                description='Количество неудачных попыток ввода пароля, после которого учетная запись блокируется. При значении параметра, равном "0", учетная запись не будет блокироваться при неудачных попытках ввода пароля'
              >
                <Number
                  onChange={value => this._onSettingChange('wrongPasswordAttempts', value)}
                  value={wrongPasswordAttempts}
                  max={999}
                  error={fieldsError['WrongPasswordAttempts']}
                />
              </Setting>
              <Setting 
                descTitle='Временная блокировка из-за ошибки ввода пароля'
                description='Количество минут, в течение которых учетная запись пользователя будет заблокирована, если пользователь ввел неверный пароль несколько раз подряд'
                disabled={wrongPasswordAttempts === 0}
              >
                <Number
                  onChange={value => this._onSettingChange('wrongPasswordBlockTime', value)}
                  value={wrongPasswordBlockTime}
                  max={999}
                  error={fieldsError['WrongPasswordBlockTime']}
                  hint={'Кол-во минут'}
                />
              </Setting>
              <Setting 
                descTitle='Блокировка при длительной неактивности'
                description='Количество дней, через которое учетная запись пользователя будет автоматически заблокирована, вследствие неиспользования. При значении параметра, равном "0", учетная запись не будет блокироваться при длительной неактивности'
              >
                <Number
                  onChange={value => this._onSettingChange('inactivePeriodBeforeBlock', value)}
                  value={inactivePeriodBeforeBlock}
                  max={999}
                  error={fieldsError['InactivePeriodBeforeBlock']}
                  hint={'Кол-во дней'}
                />
              </Setting>
              <Setting 
                descTitle='Сброс сессии при неактивности'
                description='Количество минут бездействия пользователя, после которых сессия пользователя завершится. При значении 0 сессии не будут сбрасываться'
              >
                <Number
                  onChange={value => this._onSettingChange('sessionLifeTime', value)}
                  value={sessionLifeTime}
                  max={999}
                  error={fieldsError['SessionLifeTime']}
                  hint={'Кол-во минут'}
                />
              </Setting>
            </div>
            <div className='policy-block'>
              <div className='policy-block__header'>Вход с нескольких устройств</div>
              <Checkbox 
                id='multipleDevicesLoginAllowed'
                onChange={() => this._onSettingChange('multipleDevicesLoginAllowed', !multipleDevicesLoginAllowed)}
                checked={multipleDevicesLoginAllowed}
              >
                <div className='policy-block__setting setting'>
                  <div className="setting__description description">
                    {/* <div className="description__header">Одновременный вход</div> */}
                    <div className="description__text">Разрешить одновременное подключение с нескольких устройств</div>
                  </div>
                </div>
              </Checkbox>
            </div>
          </aside>
          <aside className='password-policy__right-block'>
            <div className='policy-block'>
              <div className='policy-block__header'>Обязательные атрибуты пароля</div>
              <Checkbox 
                id='lowercaseLettersInPassword'
                onChange={() => this._onSettingChange('lowercaseLettersInPassword', !lowercaseLettersInPassword)}
                checked={lowercaseLettersInPassword}
              >
                <div className='policy-block__setting setting'>
                  <div className="setting__description description">
                    <div className="description__header">Нижний регистр</div>
                    <div className="description__text">Латинские буквы нижнего регистра (a-z)</div>
                  </div>
                </div>
              </Checkbox>
              <Checkbox 
                onChange={() => this._onSettingChange('uppercaseLettersInPassword', !uppercaseLettersInPassword)}
                checked={uppercaseLettersInPassword}
              >
                <div className='policy-block__setting setting'>
                  <div className="setting__description description">
                    <div className="description__header">Верхний регистр</div>
                    <div className="description__text">Латинские буквы верхнего регистра (A-Z)</div>
                  </div>
                </div>
              </Checkbox>
              <Checkbox 
                onChange={() => this._onSettingChange('digitsInPassword', !digitsInPassword)}
                checked={digitsInPassword}
              >
                <div className='policy-block__setting setting'>
                  <div className="setting__description description">
                    <div className="description__header">Цифры</div>
                    <div className="description__text">Арабские цифры от 0 до 9</div>
                  </div>
                </div>
              </Checkbox>
              <Checkbox 
                onChange={() => this._onSettingChange('specialSymbolsInPassword', !specialSymbolsInPassword)}
                checked={specialSymbolsInPassword}
              >
                <div className='policy-block__setting setting'>
                  <div className="setting__description description">
                    <div className="description__header">Специальные символы</div>
                    <div className="description__text">{`Спецсимволы .,: ;?!*+%-<>@[]/\\_{}$#()`}</div>
                  </div>
                </div>
              </Checkbox>
            </div>
          </aside>
        </div>
        <div className="dialog__footer">
          <Button
              className='footer__item'
              type = 'primary'
              onClick = {this._onApply}
              disabled={!this._hasChanges()}
            >
            <span className='button-title'>Сохранить</span>
          </Button>
          <Button
            className='footer__item'
            type = 'secondary'
            onClick = {this._onCancel}
          >
            <span className='button-title'>Отменить</span>
          </Button>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => {   
  return { 
    ...state.passwordPolicy, 
    ...state.error, 
    profile: state.profile 
  }
}

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...passwordPolicyActions,
    breadcrumbsPushAction
  })(withAuth(mainForm))