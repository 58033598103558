import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import EmptyListMessage from './EmptyListMessage'
import { sortListAction, sortListSetAction, setSelectedAction } from 'redux/actions/list/listActions';
import { arrayToIdHashMap, deleteArrayItemById, excludeByItemId, intersectByItemId, mergeArraysById, treeToIdHashmap } from 'app/core/utility/common'
import TotalCount from './TotalCount';

const searchTraverse = ({items, alias, search, level = 0, count = 0}) => {
  if (!alias || !search || !items || !items.length) {
    return { searchFound: false, count: 0}
  }

  let searchFound = false
  let currentCount = count

  items.forEach(item => {
    const currentFound = `${item[alias]}`
                          .toUpperCase()
                          .indexOf(search.toUpperCase()) !== -1

    if (currentFound) {
      currentCount++
    }
    
    const { 
      searchFound: childrenFound, 
      count: childrenCount = 0
    } = searchTraverse({
          items: item.children, 
          alias, 
          search, 
          level: level+1,
          count: count
        })

    currentCount = currentCount + childrenCount    
    item.searchFound = childrenFound || currentFound
    searchFound = searchFound || currentFound || childrenFound
  })

  return { searchFound, count: currentCount}
}

const filterTreeBySearch = (items) => {
  if (!items || !items.length) {
    return null
  }

  const filtered = items.filter(item => item.searchFound)
  filtered.forEach(item => {
    item.children = filterTreeBySearch(item.children)
  })

  return filtered
}

const filterSelected = (tree, selectedItems) => {
  const hashmap = treeToIdHashmap(tree)
  Object.keys(selectedItems).forEach(key => {
    selectedItems[key].selected = hashmap[key]
                                ? selectedItems[key].selected
                                : false
    selectedItems[key].expanded = hashmap[key] && hashmap[key].searchFound
                                  || selectedItems[key].expanded

  })

  return JSON.parse(JSON.stringify(selectedItems))
}

const filterSelectedForList = (list, selectedItems) => {
  return intersectByItemId(selectedItems, list)
}

const applySearchToTree = ({
  items, 
  alias, 
  search, 
  selectedItems, 
  setSelectedAction
}) => {
  const { count } = searchTraverse({items, alias, search})
  const filtered = filterTreeBySearch(items)
  setSelectedAction(filterSelected(filtered, selectedItems))
  return { filtered, count }
}

const filterListBySearch = ({items, alias, search}) => {
  // const result = { searchFound: false, count: 0}
  if (!alias || !search || !items || !items.length) {
    return []
  }

  return items.filter(item => {
    return `${item[alias]}`
              .toUpperCase()
              .indexOf(search.toUpperCase()) !== -1
  }).map(item => ({...item, searchFound: true}))
}

const applySearchToList = ({
  items, 
  alias, 
  search, 
  selectedItems, 
  setSelectedAction
}) => {
  const filtered = filterListBySearch({items, alias, search})
  // setSelectedAction(filterSelectedForList(filtered, selectedItems))
  return filtered
}

export const Table = ({ 
  search,
  searchAlias,
  setSearchResult,
  isTree,
  list = [],
  disabledItems = [], 
  disableSelectAll,
  onItemClick,
  changedFieldsVisibility,
  listFieldsVisible,
  sortListAction, 
  sortListSetAction,
  sorting, 
  // to do
  // должен остаться только selectedObjects и setSelectedObjects
  // !!! если используется setSelectedObjects или setSelectedPagination, то обязательно указать и selectedObjects
  selectedItems = [], // используется в дереве
  selectedObjects = [],
  setSelectedObjects,
  setSelectedAction, 
  setSelectedPagination,
  // ------------
  headerInfo,
  fieldsConfig, 
  inProgress,
  loadingAttempted,
  defaultSortByAlias,
  defaultSortDirection,
  itemsSelectable,
  itemsSelectableRadio,
  onToggleExpandItem,
  totalCount,
  totalCountLabel,
  hideHeader,
  strictWidth,
}) => {
  const [ selectAll, setSelectAll ] = useState( () => false );
  const [ expandAll, setExpandAll ] = useState( () => false );
  const [ currentList, setCurrentList ] = useState(list)
  const [ fields, setFields ] = useState( () => []);
  let isInitialMount = useRef(true);
  
  // to do
  // после перехода с глобального стора на хуки для списков убрать,
  // использовать только sorting 
  // выяснилось что из-за изменения сортировки инициируется
  // запрос данных в списках. т.е. если убрать этот кусок везде 
  // сломается отображение данных. когда откажемся от компонентов в 
  // "src\app\components\list\..." и соответственно от всего относящегося к этому кода, например (src\app\components\list\CompositeListPage\hooks\usePageData.js),
  // тогда и это можно удалить
  useEffect(()=>{
    if( isInitialMount.current === true ){

      sortListSetAction && sortListSetAction({ column : defaultSortByAlias, direction : defaultSortDirection })
      isInitialMount.current = false;
    }
  }, []);

  // to do
  // рефакторинг - вынести наружу, здесь не должно ничего быть
  useEffect(() => {

    if (!search) {
      setCurrentList(list)
      !isTree && setSelectedAction && setSelectedAction(selectedObjects.map(i => i.id))
      return
    }

    if (isTree) {
      const { filtered, count}  = applySearchToTree({
                                      items: JSON.parse(JSON.stringify(list)),
                                      alias: searchAlias,
                                      search: search,
                                      selectedItems,
                                      setSelectedAction
                                    })
      setCurrentList(filtered)
      setSearchResult(count)
    } else {
      const filtered = applySearchToList({
                          items: list,
                          alias: searchAlias,
                          search: search,
                          selectedItems,
                          setSelectedAction
                        })

      setCurrentList(filtered)
      setSearchResult && setSearchResult(filtered.length)
    }
  }, [search, list])

  // to do 
  // 
  useEffect(() => {
    if( !changedFieldsVisibility ){
      setFields(fieldsConfig);
      return;
    }

    if( listFieldsVisible === null ){
      setFields([]);
      return;
    }

    const temp = fieldsConfig.filter( el => {
      const alias = el.alias;
      let rez = false;
      listFieldsVisible.forEach( k => {
        const key = Object.keys( k )[0];
        if( key === alias ) rez = true;
      })
      return rez;
    });

    setFields( temp );

  }, [ listFieldsVisible, fieldsConfig ])
  
  const toggleSelectItem = ( itemId ) => {
    if (isTree) {
      const updated = {
        ...selectedItems,
        [itemId]: {
          ...selectedItems[itemId],
          selected: !selectedItems[itemId].selected
        }
      }
      delete updated.root
      const allSelected = Object.keys(updated).reduce((acc,cur) => {
        return acc && updated[cur].selected
      }, true)
      setSelectedAction(updated)
      setSelectAll(allSelected );
    } else {
      let arr = [ ...selectedItems ];
      if( arr.indexOf( itemId ) === -1 ){
        arr.push( itemId );
      }else{
        arr = arr.filter( el => el !== itemId )
      }

      // to do
      // тут начинается каша
      // которая требует глубокого рефакторинга
      // 1. setSelectedAction - выставляет выбранными только id (selectedItems), так работало изначально. selectedItems сбрасыается при экшене LIST_GET - обновлении списка.
      // 2. setSelectedObjects - выставляет выбранные объекты и уже есть логика (например бесконечный скролл), где используется только текущая страница выбранных объектов
      // 3. setSelectedPagination - выставляет выбранный объект, но данные используются уже дальше по-другому - сохраняется все, что было выбрано на вех страницах.
      // от этого всего надо избавится в пользу setSelectedObjects, но учитывать все варианты, для чего придется рефакторить все места использования. 
      // рефакторинг - оставить только selectedObjects и setSelectedObjects,
      // убрать setSelectedAction, setSelectedPagination и selectedItems
      setSelectedAction(arr)
      const item = list.find(i => i.id === itemId)
      const isExluded = !!selectedObjects.find(i=>i.id===itemId)
      const selectResult = isExluded
                            ? deleteArrayItemById(selectedObjects, item)
                            : [...selectedObjects, item]
      setSelectedObjects && setSelectedObjects(selectResult)
      setSelectedPagination && setSelectedPagination(isExluded ? {removed: [item]} : {added: [item]})
    }
  }

  useEffect(() => {
    if (!isTree) {
      if (selectedObjects && selectedObjects.length) {
        const selectedObject = arrayToIdHashMap(selectedObjects)
        setSelectAll(list.every(i => !!selectedObject[i.id]))
      } else {
        setSelectAll( selectedItems.length === list.length );
      }
    }
  }, [list, selectedItems, selectedObjects])

  // to do
  // доделать выбор одного элемента для пагинации, вроде как 
  // сейчас можно выбрать на разных страницах по одному
  const radioSelectItem = itemId => {
    setSelectedAction([itemId])
    setSelectedObjects && setSelectedObjects(list.filter(i => i.id === itemId))
  }

  const selectAllItems = () => {
    if (selectedObjects && selectedObjects.length) {
      setSelectedAction(mergeArraysById(selectedObjects, list).map(i=>i.id))
    } else {
      setSelectedAction( list.map( item => item.id ) );
    }
    setSelectedObjects && setSelectedObjects(list)
    setSelectedPagination && setSelectedPagination({added: list})
  }

  const unselectAllItems = () => {
    if (selectedObjects && selectedObjects.length) {
      setSelectedAction(excludeByItemId(selectedObjects, list).map(i=>i.id))
    } else {
      setSelectedAction([])
    }
    setSelectedObjects && setSelectedObjects([])
    setSelectedPagination && setSelectedPagination({removed: list})
  }

  const selectAllClick = () => {
    if (isTree) {
      Object.keys(selectedItems).forEach(key => {
        selectedItems[key].selected = !selectAll
      })

      delete selectedItems.root
      setSelectedAction({...selectedItems})
      setSelectAll(!selectAll)
    } else {
      ( selectAll ? unselectAllItems : selectAllItems )();
    }
  }

  const expandAllClick = () => {
    Object.keys(selectedItems).forEach(key => {
      selectedItems[key].expanded = !expandAll
    })
    onToggleExpandItem({...selectedItems})
    setExpandAll( !expandAll )
  }

  // move to reducer?
  const onToggleTreeExpand = (id) => {
    const updated = {
      ...selectedItems,
      [id]: {
        ...selectedItems[id],
        expanded: !selectedItems[id].expanded
      }
    }
    
    const allExpanded = Object.keys(updated).reduce((acc,cur) => {
      const { item } = selectedItems[cur]
      const hasChildren = item && item.children && item.children.length

      if (cur === 'root'
          || selectedItems.root && `${selectedItems.root.id}` === cur
          || !hasChildren) {
        return acc && true
      } else {
        return acc && updated[cur].expanded
      }
    }, true)

    onToggleExpandItem({...updated})
    setExpandAll( allExpanded )
  }

  const clickOnItem = item => {
    return onItemClick && onItemClick( item );
  }

  const _sortList = ( fieldName ) => {
    sortListAction( fieldName );
  }

  if( fields.length === 0 ) return null;

  return (
    <>
      <TotalCount
        totalCount={totalCount}
        totalCountLabel={totalCountLabel}
      />
      <div className="table-container  table-container--no-borders">
        <table id="tableIdtableId" className="table list__table">
          <TableHeader
            isTree = { isTree }
            sorting = { sorting }
            fieldsConfig = { fields }
            itemsSelectable = { itemsSelectable }
            itemsSelectableRadio = { itemsSelectableRadio }
            sortList = { _sortList }
            selectAll = { selectAll }
            expandAll = { expandAll }
            selectedItems = { selectedItems }
            selectAllClick = { selectAllClick }
            expandAllClick = { expandAllClick }
            changedFieldsVisibility = { changedFieldsVisibility }
            listFieldsVisible = { listFieldsVisible }
            headerInfo = { headerInfo }
            hideHeader = { hideHeader }
            strictWidth = { strictWidth }
            disableSelectAll = { disableSelectAll }
          />
          <TableBody
            search = { search }
            isTree = { isTree }
            list = { currentList }
            toggleSelectItem = { toggleSelectItem }
            radioSelectItem = { radioSelectItem }
            selectedItems = { selectedItems }
            disabledItems = { disabledItems }
            fieldsConfig = { fields }
            itemsSelectable = { itemsSelectable }
            onToggleTreeExpand={onToggleTreeExpand}
            itemsSelectableRadio = { itemsSelectableRadio }
            clickOnItem = { clickOnItem }
            listFieldsVisible = { listFieldsVisible }
            strictWidth = { strictWidth }
          />
          <EmptyListMessage loadingAttempted={loadingAttempted} count={list.length} />
        </table>
      </div>
    </>
  )
}

const mapStateToProps = state => {
	return { 
    inProgress : state.listInProgress,
    selectedItems : state.listSelected,
    list : state.list,
    sorting : state.listSorting,
    paginator : state.listPaginator,
    listFieldsVisible : state.listFieldsVisible
  };
}

const mapDispatchToProps = {
  sortListAction,
  sortListSetAction,
  setSelectedAction
}

export default connect( mapStateToProps, mapDispatchToProps )(Table);