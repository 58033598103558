/** @format */
import { sortDirection } from '../../ui/constants';
import withTooltip from 'app/components/HOC/TooltipHOC';
import TagComponent from 'app/components/ui/tag';
import { formatDate } from '../../../core/utility/date';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
const Tag = withTooltip(TagComponent);

export default function useCardCertificationBlock({ skziId, versionId, buildId }) {
  const buildColumns = useCertificationBlockConfig({ skziId, versionId, buildId });

  const { sorting, sortListAction } = useTableExtensions({
    defaultSort: { column: 'main', direction: sortDirection.desc },
  });

  return {
    sorting,
    sortListAction,
    buildColumns,
  };
}

function useCertificationBlockConfig({ skziId, versionId, buildId }) {
  const urlPart = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/certificates`;
  return [
    {
      title: 'Тип',
      alias: 'type',
      useSorting: true,
      width: 150,
      format: item => {
        const { type } = item;

        return type.name;
      },
    },
    {
      title: 'Номер',
      alias: 'number',
      useSorting: true,
      width: 300,
      format: item => item.number,
    },
    {
      title: 'Начало действия',
      alias: 'certificateStartDate',
      useSorting: true,
      width: 300,
      format: item => formatDate(item.certificateStartDate, 'dd.mm.yyyy'),
    },
    {
      title: 'Окончание действия',
      alias: 'certificateExpirationDate',
      useSorting: true,
      width: 300,
      format: item =>
        item.certificateExpirationDate ? formatDate(item.certificateExpirationDate, 'dd.mm.yyyy') : 'Бессрочно',
    },
    {
      title: 'Вложения',
      alias: 'documents',
      useSorting: false,
      isCustomRender: true,
      format: rowData => {
        const { files } = rowData;
        return (
          <div className="type-files">
            {files.map(item => {
              return (
                <Tag
                  key={item.guid}
                  text={item.name}
                  tooltip={item.name}
                  link={`${urlPart}/certificate/${rowData.id}/file/${item.guid}`}
                  item={item}
                />
              );
            })}
          </div>
        );
      },
    },
  ];
}
