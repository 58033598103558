import { useEffect } from 'react'
import { connect } from 'react-redux'

import MainCard, { cardMode, objectKey } from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import { cardLines } from './cardLines'
import {dialogType} from 'app/components/dialog/confirmDialog/'
import { arrayToIdHashMap, capitalize, flatten } from 'app/core/utility/common'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'

import * as mainCardAction from 'redux/actions/destructionCertificate/allActions'
import {cardUpdateAction} from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import DestructionObjectSet from './destructionObjectSet'
import { useHistory } from 'react-router-dom'

export const destructionCertObject = (data) => {
  const { 
    number,
    signers=[],
    organization,
    kcFromKdTable = [], 
    kcFromKcTable = [], 
    kdFromKdTable = [], 
    kdFromKcTable = [],
    kdFromEskziTable = [],
    skziAmountList = [],
    eskzis, 
    keyCarriers, 
    keyDocuments,  
  } = data
  const { id } = organization || {}
  const exemplars = eskzis || skziAmountList
  const kc = keyCarriers || [...kcFromKdTable,...kcFromKcTable] 
  const kd = keyDocuments || [...kdFromKdTable,...kdFromKcTable, ...kdFromEskziTable ]

  return { 
    number,
    organizationId: id,
    signers: signers.map(item => item.id),
    eskzis: exemplars.map(item => item.id),
    keyDocuments: kd.map(item => item.id),
    keyCarriers: kc.map(item => item.id)
  }
}

export const getWarningObject = (props) => {
  const { commonDataForm } = props || {}
  const { kdAmount, kcAmount, skziAmount } = commonDataForm || {}
  const messages = [
    'Выбранные элементы будут уничтожены безвозвратно, включая: ',
    `1. Ключевые документы — ${kdAmount || 0}`,
    `2. Ключевые носители — ${kcAmount || 0}`,
    `3. Экземпляры СКЗИ— ${skziAmount || 0}`,
  ]

  return {
      messages: messages,
      type: dialogType.danger,
      header: 'Уничтожить элементы?',
      submitText: 'Уничтожить'
    }
}

export const formUpdate = (key, value, props) => {
  const { 
    commonDataForm, 
    errorByFields, 
    cardUpdateAction, 
    setErrorByKey,
    onOrganizationClear
  } = props

  const { signers } = commonDataForm
  const { organization: signersOrg } = signers[0] || {}
  const { id: currentOrganizationId } = signersOrg || {}

  let newData = { ...commonDataForm, [key]: value }
  if (key === 'organization' && signers.length) {
    const { id: valueOrgId } = value || {}
    newData = !value || valueOrgId !== currentOrganizationId
              ? { ...newData, signers: [] }
              : newData
  }

  const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
  setErrorByKey(filteredErrors)
  cardUpdateAction(newData)
  onOrganizationClear && onOrganizationClear()
}

const updateCounts = (props, list = [], key) => {
  const { 
    commonDataForm, 
    cardUpdateAction, 
  } = props
  const { 
    kcFromKdTable = [], 
    kcFromKcTable = [], 
    kdFromKdTable = [], 
    kdFromKcTable = [],
    kdFromEskziTable = [],
  } = commonDataForm || {}

  let newData, allKeyDocuments, allKeyCarriers, uniqueKd, uniqueKc
  switch (key) { 
    case 'kdAmount':
      const kcFromKdTableTemp = list.map(item => item.children && item.children.length ? { id: item.children[0].id }: {id: 0}).filter(item => !!item.id)
      
      allKeyCarriers = [...kcFromKcTable, ...kcFromKdTableTemp]
      allKeyDocuments = [...list, ...kdFromKcTable, ...kdFromEskziTable]
      uniqueKc = Object.keys(arrayToIdHashMap(allKeyCarriers)).map(item => ({id: item}))
      uniqueKd = Object.keys(arrayToIdHashMap(allKeyDocuments)).map(item => ({id: item}))

      newData = { 
        ...commonDataForm, 
        kdAmount: uniqueKd.length || '0',
        kcAmount: uniqueKc.length || '0',
        kcFromKdTable: kcFromKdTableTemp,
        kdFromKdTable: list,
      }
      break

    case 'kcAmount':
      const kdFromKcTableTemp = flatten(list.map(item => item.children))
      allKeyDocuments = [...kdFromKdTable, ...kdFromEskziTable, ...kdFromKcTableTemp]
      allKeyCarriers = [...list, ...kcFromKdTable ]
      uniqueKc = Object.keys(arrayToIdHashMap(allKeyCarriers)).map(item => ({id: item}))
      uniqueKd = Object.keys(arrayToIdHashMap(allKeyDocuments)).map(item => ({id: item}))

      newData = {
        ...commonDataForm, 
        kdAmount: uniqueKd.length || '0',
        kcAmount: uniqueKc.length || '0',
        kdFromKcTable: kdFromKcTableTemp,
        kcFromKcTable: list,
      }
      break
    case 'skziAmount':
      const kdFromEskziTableTemp = flatten(list.map(item => item.children))
      allKeyDocuments = [...kdFromKdTable, ...kdFromKcTable, ...kdFromEskziTableTemp]
      uniqueKd = Object.keys(arrayToIdHashMap(allKeyDocuments)).map(item => ({id: item}))
    
      newData = { 
        ...commonDataForm, 
        skziAmountList: list,
        skziAmount: list.length || '0',
        kdAmount: uniqueKd.length || '0',
        kdFromEskziTable: kdFromEskziTableTemp,
      }
      break
    default:
      break
  }

  commonDataForm.id && cardUpdateAction({...newData})
}

export const cardConfig = ({ 
    history,
    register,
    breadcrumbsPopAction,
  }) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, destructionCertObject),
  resource: RESOURCES.destructionCertificate,
  signatureHeaderText: 'Создание акта уничтожения',
  signatureServiceName: 'destructionCertificateCreate',
  oldSignatureService: false,
  signatureEntity: 'EskziDestructionCertificates',
  updateAction: register,
  customFormChangeHandler: formUpdate,
  getWarningObject: getWarningObject,
  onCancel: () => {history.push('/acts/destructioncertificate')},
  afterUpdate: (requestResult) => {
    const { payload, type } = requestResult || {}
    const { id } = payload || {}

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction()  
      history.push(`/acts/destructioncertificate/card/${id}`)
    }
  },
  getSignatureData: destructionCertObject,
  pageBreadcrumbTitle: () => null,
})

const CreateCard = (props) => {
  const { 
    initCreate,
    register,
    breadcrumbsPopAction,
    commonDataForm,
    profile
  } = props
  const { organization } = commonDataForm
  const { id = 0 } = organization || {}
  const history = useHistory();

  // to do
  // это просто эпик фейл
  // задание начального состояния карточки должно происходить в <MainCard>, 
  // но т.к. updateCounts используют cardUpdateAction,
  // после initCreate вызываются updateCounts и перетирают 
  // commonDataForm значением, которое они замкнули при маунте 
  // компонента, в результате в сторе карточки лежат данные 
  // предыдущей карточки
  // в итоге надо поднимать стейт из <MainCard> выше
  useEffect(()=> {
    initCreate(0,{profile})
  }, [])

  return (
    <section className='main-card destruction-certificate-card'>
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={'Информация'} 
        onTabClick={null}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              config={cardConfig({
                history,
                register,
                breadcrumbsPopAction,
              })}
              cardMode={cardMode.create}
              editOnOpen={true}
            > 
            </MainCard>

            <ExpandingBlock
              className='sign-info'
              renderHeader= {() => 'Подписание комиссией'}
              disabled={true}
            />
          </ExpandingBlock>
          <DestructionObjectSet
            organizationId={id}
            onKdChanged={list => updateCounts(props, list, 'kdAmount')}
            onKcChanged={list => updateCounts(props, list, 'kcAmount')}
            onEskziChanged={list => updateCounts(props, list, 'skziAmount')}
          />
        </section>
        <div
          tabTitle={'История'}
          disabled={true}
        />
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card, profile: state.profile}),
  { 
    cardUpdateAction,
    ...mainCardAction,
    ...breadcrumbsActions,
  })(CreateCard)