/** @format */

import { formatDate } from 'app/core/utility/date';
import { organizationName } from 'app/components/list/Table/TableBody';
import { OKI_CARD_TYPE, ORG_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';

import { getEskziUsersDisplayedValue } from './helpers';
import { AgentStatus, HostStatus } from './enums';

import styles from './Hosts.module.scss';

const tableSchema = [
  {
    title: 'АРМ',
    alias: 'name',
    useSorting: true,
    visibleOnStart: true,
    visibleAlways: true,
  },
  {
    title: 'Статус АРМ',
    alias: 'status',
    useSorting: true,
    visibleOnStart: true,
    width: 250,
    format: item => {
      const { status } = item;
      return HostStatus[status];
    },
  },
  {
    title: 'Лицевой счет',
    alias: 'eskziUsers',
    useSorting: true,
    visibleOnStart: true,
    width: 250,
    format: item => {
      const { eskziUsers } = item;
      return getEskziUsersDisplayedValue(eskziUsers);
    },
  },
  {
    title: 'Организация',
    alias: 'organization',
    useSorting: true,
    visibleOnStart: true,
    width: 250,
    format: item => {
      const name = organizationName(item.organization);
      const { id, okz } = item.organization || {};
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE;
      return name && id ? <ListLink text={name} targetType={targetType} id={id} /> : name || '';
    },
  },
  {
    title: 'Версия агента',
    alias: 'agentVersion',
    useSorting: true,
    visibleOnStart: true,
    visibleAlways: true,
    width: 250,
  },
  {
    title: 'Статус агента',
    alias: 'agentStatus',
    useSorting: true,
    visibleOnStart: true,
    visibleAlways: true,
    width: 250,
    format: item => {
      const { agentStatus } = item;
      return <span className={`${styles.agentStatus} ${styles[agentStatus]}`}>{AgentStatus[agentStatus]}</span>;
    },
  },
  {
    title: 'Дата подключения',
    alias: 'lastAgentTime',
    useSorting: true,
    visibleOnStart: true,
    width: 200,
    format: item => {
      const { lastAgentTime } = item;
      return formatDate(lastAgentTime, 'hh:MM:ss dd.mm.yyyy');
    },
  },
];

export default tableSchema;
