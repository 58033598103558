import { getClassName } from 'app/core/utility/jsx';

import styles from "./filter.module.scss";

export default function FilterCompact({
  children,
  className,
}) {
  
  return (
    <div className={getClassName([styles.filterCompact, className])}>
      {children}
    </div>
  )
}
