import React, { useCallback, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import {Table} from 'app/components/list/Table/Table';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import Overlay from 'app/components/ui/overlay';
import { useSelector } from 'react-redux';
import service from 'app/services/service';
import { sortDirection } from '../ui/constants';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import { ListActionPanel } from './ListActionPanel';
import { useJournal } from 'app/components/journals/useJournal';
import Pagination from '../ui/pagination/serverSidePagination/pagination';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from "app/components/ui/managePanel";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from "app/components/ui/MainListContainer/MainListContainer";
import Filter, { GlobalOrgType } from "app/components/filter";
import styles from './list.module.scss';
import config from './config'

export default function UserAccountList() {
  const history = useHistory();
  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    filterOpened,
    toggleFilterState,
    onClearFilter,

    // useTableExtensions
    onInProgress,
    sortListAction,
    onRemoveValues,
    onItemUpdate,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,

    // global
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
    clearError,
  } = useJournal({
    defaultSort: {
      column: 'id',
      direction: sortDirection.desc,
    },
    serviceName: 'userAccountService',
  });

  const selectedUA = useMemo(() => {
    if (selectedObjects.length !== 1) {
      return null;
    }

    return selectedObjects[0];
  }, [selectedObjects]);

  const rights = useMemo(
    () => getResourceRights(profile, RESOURCES.userAccount),
    [profile]
  );
  const onCreate = useCallback(() => history.push(`/userAccounts/create`), []);
  const onRowClick = useCallback(item => {
    return `/userAccounts/card/${item.id}`;
  }, []);
  const deleteItems = useCallback(async () => {
    onInProgress(true);
    const response = await service(
      'userAccountService',
      'deleteRange',
      selectedItems
    );
    onInProgress(false);
    return response;
  }, [selectedItems]);

  return (
    <MainListContainer>
    {inProgress && <Overlay />}
    <PageHeader title='Лицевые счета' Img={imgMap.UserAccounts} />
    <ManagePanel borders={Border.All}>

      <ListActionPanel
        rights={rights}
        onCreate={onCreate}
        inProgress={inProgress}
        onInProgress={onInProgress}
        selectedItems={selectedItems}
        items={list}
        onRemoveValues={onRemoveValues}
        deleteItems={deleteItems}
        setSelectedAction={setSelectedAction}
        filterForRequest={filterForRequest}
        sorting={sorting}
        fieldsVisible={fieldsVisible}
        onItemChanged={onItemUpdate}
        currentUA={selectedUA}
        selectedUA={selectedObjects}
        globalOrgId={globalOrgId}
      />
      <Group separate>
        <FilterTriggerPanel
          toggle={toggleFilterState}
          filterFilled={filterFilled}
          onClearFilter={onClearFilter}
          disabledClear={!filterState.length}
        />
      </Group>
      <Group separate>
        <TableFieldSelect
          listFieldsVisible={fieldsVisible}
          setVisibleFieldsAction={setVisibleFieldsAction}
          toggleVisibleFieldAction={toggleVisibleFieldAction}
          headerInfo={true}
          fieldsConfig={config.fieldsConfig}
          changedFieldsVisibility={true}
          settingsAsGear={true}
        />
      </Group>
    </ManagePanel>
    <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.trheeRowFilter}
      >
        <Filter
          className={styles.filter}
          inProgress={inProgress}
          setGlobalOrg={setGlobalOrg}
          globalOrganization={globalOrganization}
          setFilterStateAction={setFilterStateAction}
          setFilterForRequestAction={setFilterForRequestAction}
          setFullFilterStateAction={setFullFilterStateAction}
          filterState={filterState}
          filterFilled={filterFilled}
          profile={profile}
          globalOrgId={globalOrgId}
          filtersConfig={config.tablePageFiltersConfig}
          isOpened={filterOpened}
          toggleFilterState={toggleFilterState}
          globalOrgType={GlobalOrgType.all}
          withGlobalOrg
          globalOrgPosition = {4}
        />
        <Table
          {...{
            totalCount,
            list,
            sortListAction,
            sorting,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedPagination,
            inProgress,
          }}
          listFieldsVisible={fieldsVisible}
          fieldsConfig={config.fieldsConfig}
          itemsSelectable={true}
          changedFieldsVisibility={true}
          onItemClick={onRowClick}
          headerInfo={true}
        />
      </FilterWithTableContainer>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageCount={pageCount}
        perPage={perPage}
      />
    </MainListContainer>
  );
}