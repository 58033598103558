import React, {useEffect, useRef, useState} from "react";

const DropDownPanel = ({initState = false, onClosePanelHandler = null, children}) => {
    const [isActive, setActiveState] = useState(initState);
    const ref = useRef(null);

    const onClickOutHandler = (evt) => {
        if (ref.current && !ref.current.contains(evt.target)) {
            setActiveState(false);
            onClosePanelHandler && onClosePanelHandler();
        }
    };

    useEffect(() => {
        window.addEventListener('mousedown', onClickOutHandler);

        return () => {
            window.removeEventListener('mousedown', onClickOutHandler);
        };
    }, []);

    return (
        <div ref={ref} className={`circle-progressbar-menu ${isActive ? "active" : "inactive"}`}>
            {children}
        </div>
    );
};

export default DropDownPanel

