
import ActionType from "redux/actions/notification";

const initialState = {
    wsConnection: null,
    updatedEvent: null, // надо обновить уведомление не по сокетам
    needRefreshByRest: false, // надо запросить обновление уведомлений по http.
                              // для случая когда SignalR не используется
    lastRefreshedValues: []   // результат последнего опроса апи уведомлений.
                              // для случая когда SignalR не используется
};

const reducer = (state = initialState, action) => {
    const { payload, type } = action
    switch (type) {
        case ActionType.setConnection:
            return {...state, wsConnection: payload};
        case ActionType.resetConnection:
            return {...state, wsConnection: null};
        case ActionType.setUpdatedEvent:
            return {
                ...state,
                updatedEvent: payload 
                                ? {...payload, id: payload.uid}
                                : payload
            }
        case ActionType.setNeedRefreshByRest:
            return {
                ...state,
                needRefreshByRest: payload
            }
        case ActionType.setLastRefreshedValues:
            return {
                ...state,
                lastRefreshedValues: payload
            }
        default:
            return state;
    }
};

export default reducer;