import { decapitalize, capitalize } from 'app/core/utility/common'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {combineReducers} from 'redux'
import errorReducer, {defaultState as defaultErrorState} from '../../../../../redux/reducers/errorReducer'
import createReducer from '../../../../../redux/reducers/card/createCardReducer'
import useReducerWithThunk from 'redux/store/useReducerWithThunk'
import {CARD_GET, CARD_UPDATE} from '../../../../../redux/actions/actionTypes'
import {
  cardAddFieldsetAction,
  cardInProgressAction,
  cardOnCancelAction,
  cardRemoveFieldsetAction,
  cardResetAction,
  getCardAction,
  initCardAction,
  updateCardAction,
  updateCommonFormCardAction,
  updateCommonFormFieldCardAction
} from '../../../../../redux/actions/card'
import {
  clearErrorFormAction,
  dispatchLogic,
  setErrorByKeyFormAction
} from '../../../../../redux/actions/common'
import {serverlikeErrors, usePrevious} from '../../../../core/utility/common'

export const CardState = {
  'edit' : 'edit',
  'view' : 'view'
}

export default function useFunctionalCard(props){

  const {
    parentEditState,
    formData,
    get,
    update,
    updateCallback,
    needUpdateState = false,
    serverFormViewModel = null,
    getObjectKeyFunc = null,
    dataFormEqual = null,
    revalidationConditions: autoValidationConditions = null,
  } = props

  const {validationRules, defaultState} = formData
  const dispatch = useDispatch()

  const rootReducer = combineReducers({ error: errorReducer, card: createReducer(defaultState)})
  const initialState = {error: defaultErrorState, card: defaultState}
  const [store, dispatchStore] = useReducerWithThunk(rootReducer, initialState)

  const { card, error } = store
  const { inProgress, commonDataForm, viewData } = card || {}
  const { fieldsError, errorByFields } = error

  const prevDataForm = usePrevious(commonDataForm)

  useEffect(() => {
    const emptyErrors = Object.keys(validationRules).reduce((acc, current) => {

      return {
        ...acc,
        [capitalize(current)]: null
      }
    }, {})

    onValidation(emptyErrors)

    if (!inProgress && get) {
      dispatchStore(cardInProgressAction(true))
      const getRequest = get()
      getRequest.then(response => {
        const { data, errors, isError } = response;
        dispatchLogic(dispatch, CARD_GET, data, isError, errors)
        
        !isError && dispatchStore({
          type: 'CARD_GET',
          payload: data
        })
        return response
      })
      dispatchStore(getCardAction(getRequest))
    } else {
      dispatchStore(initCardAction(defaultState))
    }
    return function cleanup() {
      dispatchStore(cardResetAction())
      dispatchStore(clearErrorFormAction())
    };

  }, [])
  
  const [autoValidationEnabled, setAutoValidationEnabled] = useState(false);

  useEffect(()=> {
    if (!commonDataForm) {
      return 
    }
    
    const defaultRevalidationConditions = (commonDataForm, prevDataForm) =>
      Object.keys(commonDataForm).some(current =>
        prevDataForm
        && prevDataForm[current]
        && Array.isArray(commonDataForm[current])
        && (commonDataForm[current].length < prevDataForm[current].length));
    const revalidationConditionsFn = autoValidationConditions ?? defaultRevalidationConditions;    
    const shouldValidate = autoValidationEnabled ? revalidationConditionsFn(commonDataForm, prevDataForm) : false;
    
    if(shouldValidate){
      validate()
    }
  }, [commonDataForm])

  const onSubmit = () => {
    setAutoValidationEnabled(true);
    
    if(validate()){
      return false
    }

    dispatchStore(cardInProgressAction(true))
    const updateRequest = update(commonDataForm)
    updateRequest.then(res => {
      const { data, errors, isError } = res;
      dispatchLogic(dispatch, CARD_UPDATE, data, isError, errors)
      dispatchStore(cardInProgressAction(false))
      return updateRequest
    })
    dispatchStore(updateCardAction(updateRequest, updateCallback))
    needUpdateState && dispatchStore(getCardAction(updateRequest))
  }

  const onCancel = () => {
    dispatchStore(setErrorByKeyFormAction([]))
    dispatchStore(cardResetAction())
    dispatchStore(cardOnCancelAction())
  }

  const onFormChange = (key, value, index) => {
    // check if changed fieldset, different logic
    if (typeof key === 'object' && key !== null ){
      const newFieldset = [...commonDataForm[key.key]];
      newFieldset[index] = {
        ...newFieldset[index],
        [key.field]: value
      }
      dispatchStore(updateCommonFormCardAction({
        ...commonDataForm,
        [key.key]: [
          ...newFieldset,
        ]
      }))
      return ;
    }
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    dispatchStore(setErrorByKeyFormAction(filteredErrors))
    dispatchStore(updateCommonFormFieldCardAction({[key]: value }))
  }

  const onValidation = (errors) => {
    dispatchStore(setErrorByKeyFormAction(serverlikeErrors(errors)))
  }

  const onAdd = (name, value) => {
    dispatchStore(cardAddFieldsetAction(name, value))
  }

  const onRemove = (name, index) => {
    dispatchStore(cardRemoveFieldsetAction(name, index))
  }

  const [state, setState] = useState( { editState: parentEditState })

  useEffect(()=>{
    setState({editState: parentEditState})
  }, [parentEditState])

  const { editState } = state
  const editOnly = false
  const cardState = editOnly
    ? CardState.edit
    : editState
      ? CardState.edit : CardState.view

  const validate = () => {
    const errors = Object.keys(validationRules).reduce((acc, current) => {
      // check if changed fieldset, different logic
      if (validationRules[decapitalize(current)] && Array.isArray(validationRules[decapitalize(current)])){
        const validationResult = [];
          commonDataForm[current].forEach(fieldsetValues => {
            const fieldsetErrors = Object.keys(fieldsetValues).reduce((accumulator, fieldName) => {
              const result = validationRules[decapitalize(current)][0][decapitalize(fieldName)]
              && validationRules[decapitalize(current)][0][decapitalize(fieldName)].validation
              && validationRules[decapitalize(current)][0][decapitalize(fieldName)].validation(fieldsetValues[fieldName], serverFormViewModel ? serverFormViewModel[current]: null)
              return {
                ...accumulator,
                [capitalize(fieldName)]: (result && !result.valid) ? result.error : null
              }
            }, {})
            validationResult.push(fieldsetErrors)
          })
        return {
          ...acc,
          [capitalize(current)]: validationResult
        }
      }
      let validationResult = {};

      // to do
      // избавиться от этого, перенести в валидацию где это нужно
      if (current === 'certificateExpirationDate') {
        validationResult =
            validationRules[decapitalize(current)]
            && validationRules[decapitalize(current)].validation
            && validationRules[decapitalize(current)].validation(
                commonDataForm['certificateStartDate'],
                commonDataForm[current],
            )
      } else {
        validationResult =
            validationRules[decapitalize(current)]
            && validationRules[decapitalize(current)].validation
            && validationRules[decapitalize(current)].validation(
                commonDataForm[current],
                serverFormViewModel ? serverFormViewModel[current]: null,
                commonDataForm
            )
      }

      return {
        ...acc,
        [capitalize(current)]: validationResult ? validationResult.error : null
      }
    }, {})

    onValidation(errors)

    return Object.keys(errors).reduce((res, key) => {
      // check if changed fieldset, different logic
      if(Array.isArray(errors[key])) {
        const fieldsetResult = errors[key].reduce((accum, el) => {
          return accum || Object.keys(el).reduce((result, elementKey) => {
            return result || el[elementKey]
          }, false)
        }, false)
        return res || fieldsetResult
      }
      
      const fieldError = errors[key] && { ...errors[key], field: key };
      return res || fieldError;
    }, false)
  }

  const submitEnabled = () => {
    if (!viewData) {
      return true
    }
    if(dataFormEqual) {
      return !dataFormEqual(viewData, commonDataForm)
    }
    return getObjectKeyFunc ? getObjectKeyFunc(viewData) !== getObjectKeyFunc(commonDataForm) : true
  }

  const onCancelForm = () => {
    const emptyErrors = Object.keys(validationRules).reduce((acc, current) => {

      return {
        ...acc,
        [capitalize(current)]: null
      }
    }, {})

    onValidation(emptyErrors)
    setState({ editState : false })
    onCancel && onCancel()
  }

  return {
    cardState,
    editState,
    setState,
    inProgress,
    fieldsError,
    commonDataForm,
    viewData,
    dispatchStore,
    onCancel,
    validate,
    setAutoValidationEnabled,
    onSubmit,
    submitEnabled,
    onCancelForm,
    onFormChange,
    onAdd,
    onRemove
  }

}