/** @format */
import { sortDirection } from 'app/components/ui/constants';
import { formatFIO } from 'app/core/utility/common';

export const defaultSettings = {
  columns: [
    {
      title: 'ФИО',
      alias: 'name',
      format: item => formatFIO(item),
      useSorting: true,
    },
    {
      title: 'Email',
      alias: 'email',
      useSorting: true,
      width: 400,
    },
  ],
  sort: {
    column: 'name',
    direction: sortDirection.asc,
  },
};
