const defaultSetting = { current : 1, itemsPerPage : 5, itemsCount : 0 };

export default (state = defaultSetting, action, prefix = '') => {

  if( action.type === 'LIST_PAGINATOR_SET_CURRENT_PAGE' ){
    return { ...state, ...action.payload }; 
  }

  if( action.type === 'LIST_INIT_PAGINATOR' ){
    return { ...state, ...action.payload }
  }

  if( action.type === 'LIST_SET_FILTER' ){
    return { ...state, current : 1 };
  }

  if( action.type === 'LIST_DROP' ){
    return { ...defaultSetting, itemFrom: 0, itemTo: 9999999 };
  }

  return state;
}