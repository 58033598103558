import React from 'react'
import PasswordField from './PasswordField'
import SubmitButton from './SubmitButton'

export default function MailRecovery(props) {
  const {
    password,
    confirmPassword,
    passwordError,
    confirmPasswordError,
    onPasswordChange,
    onConfirmPasswordChange,
    onSubmit
  } = props

  return (
    <>
      <PasswordField
        id='newPassValue'
        name='newPassValue'
        value={password}
        error={passwordError}
        onChange={onPasswordChange}
        autocomplete={'new-password'}
        labelText='Новый пароль'
      />
      <PasswordField
        id='newPassConfirm'
        name='newPassConfirm'
        isConfirm={true}
        value={confirmPassword}
        error={confirmPasswordError}
        onChange={onConfirmPasswordChange}
        autocomplete={'new-password'}
        labelText='Подтвердить пароль'
        onSubmit={onSubmit}
      />
      <SubmitButton
        label='Сохранить'
        onSubmit={onSubmit}
      />
    </>
  )
}
