import React from 'react';

export default function EmptyListMessage({ loadingAttempted, count }) {
  if( count === 0 && loadingAttempted ){
    return(
      <caption className="table__message">
        <p className="message">Нет элементов для отображения</p>
      </caption>
    );
  }

  return null;
}