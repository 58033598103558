import { AUTHTYPE } from 'app/core/auth/auth'

import axios, { Canceler } from 'axios'; 
import { fetchWithController as fetch, setFetchCancell } from './cancellableFetch'


export interface IJsonResult {
  data?: (object | Array<any>),
  isError: boolean,
}

export interface IFileResult {
  blob?: Blob,
  fileName?: string,
  isError: boolean,
}

export interface IErrorResult {
  name?: string,
  isError: boolean,
  errors?: Array<any>,
}

export interface IResponseResult extends IJsonResult, IFileResult, IErrorResult {

}

const url = 'api/v1'
const contentType = 'application/json'
const cacheMode = 'no-store'
const mode = 'same-origin'
const credentials =  'include'

export const ErrorCodes = {
  AbortError: 'AbortError'
}

let headers = {
  'Content-Type': contentType,
}

const getHeaders = (): object => {
  let currentCSRF = ''

  if (document.cookie) {
    const tokenCookie = document.cookie
                        .split('; ')
                        .find(row => row.startsWith('XSRF-TOKEN'))

    if (tokenCookie) {
      currentCSRF = tokenCookie.split('=')[1];
    }
  }
        
  return {
    ...headers,
    ['X-XSRF-TOKEN']: currentCSRF
  }
}


const headInitObject = (): object => ({
  method: 'HEAD',
  headers: getHeaders(),
  cache: cacheMode,
  credentials: credentials,
  mode: mode
})

const getInitObject = (): object => ({
  method: 'GET',
  headers: getHeaders(),
  cache: cacheMode,
  credentials: credentials,
  mode: mode
})

const postInitObject = (): object => ({
  method: 'POST',
  headers: getHeaders(),
  cache: cacheMode,
  credentials: credentials,
  mode: mode
})

const deleteInitObject = (): object => ({
  method: 'DELETE',
  headers: getHeaders(),
  cache: cacheMode,
  credentials: credentials,
  mode: mode
})

const errorResponse = (err: string): IErrorResult => ({
    isError: true,
    errors: [
      {
        "errorCode": "E_FRONTERROR",
        "errorMessage": err || "Ошибка соединения",
        "isError": true
      }
    ]
})

const fileDownloadHandler = async (response: Response): Promise<IResponseResult> => {
  try {
    const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g
    const cd = response.headers.get('content-disposition')

    if (response.ok) {
      const blob = await response.blob()

      if (cd) {
        const fileNames = cd.match(regex)

        if (fileNames && fileNames[1]){
          const fileName = fileNames[1].replace(/^[filename*=UTF-\d+]+|''|['"]+$/g, '')

          return { 
            blob, 
            fileName: decodeURIComponent(fileName) ,
            isError: false
          }  
        } else {
          return { 
            blob, 
            fileName: 'FileName',
            isError: false
          }
        }
      }
  
      return errorResponse('')
    } else {
      return await response.json()
    }
  } catch (err) {
    return errorResponse((err as any).errorMessage || (err as any).message)
  }
}

const downloadHandler = async (response: Response): Promise<IFileResult> => {
  try {
    return await response.clone().json()
  } catch (error) {
    return await fileDownloadHandler(response)
  }
}

const errorHandler = (error: IErrorResult): IErrorResult => {
  if (error.name === ErrorCodes.AbortError) {
    return {
      isError: true
    }
  }

  return {
    isError: true,
    errors: [
      {
        globalError: true,
        detail: {
          status: 0,
          response: error
        }
      }
    ]
  }
}

const responseHandler = async (response: Response): Promise<IResponseResult> => {
  const status = response.status

  // after poib auth redirect
  if (response.redirected 
      && response.url.includes('/poib/signin/')) {
        
      return {
        isError: true,
        errors: [
          {
            globalError: true,
            detail: {
              status: 401,
              userType: AUTHTYPE.poib,
              response: null
            }
          }
        ]
      }
  } 

  try {
    const respJson = await response.json() as Promise<IResponseResult>;

    if (status >= 401 && status < 600) {
      switch (status) {
        case 500:
        case 401:
          //console.log(response)
        case 403:
        case 404:
          return {
            isError: true,
            errors: [
              {
                globalError: true,
                detail: {
                  status: status,
                  response: respJson
                }
              }
            ]
          }
        // forbiden - business errors
        // need to show modal error message 
        case 406:   
          return respJson
        // warning
        // show yes/no dialog
        case 428:
          return respJson
        default:
          return {
            isError: true,
            errors: [
              {
                globalError: true,
                detail: {
                  status: 500,
                  response
                }
              }
            ]
          }
      }
    }

    return respJson
  } catch (err) {
      return {
        isError: true,
        errors: [
          {
            globalError: true,
            detail: {
              status: status || 0,
              response
            }
          }
        ]
      }
  }
}

export const get = async (urlPart: string, setCancelCallback?: setFetchCancell):Promise<IResponseResult> => {
  return fetch(
    `${url}/${urlPart.replace(/^\//, '')}`,
    { ...getInitObject() },
    setCancelCallback
  )
  .then(responseHandler)
  .catch(errorHandler)
}

export const post = async (urlPart: string, data: any, setCancelCallback?: setFetchCancell):Promise<IResponseResult> => {
  try {
    const req = await fetch(
      `${url}/${urlPart.replace(/^\//, '')}`,
      {
        ...postInitObject(), 
        body: data ? JSON.stringify(data): ''
      },
      setCancelCallback
    )
    return responseHandler(req)
  } catch (err) {
    return errorHandler((err as any))
  }
}

export const head = (urlPart: string, setCancelCallback?: setFetchCancell):Promise<IResponseResult> => {
  return fetch(
    `${url}/${urlPart.replace(/^\//, '')}`,
    { ...headInitObject() },
    setCancelCallback
  )
  .then(responseHandler)
  .catch(errorHandler)
}

export const del = (urlPart: string, setCancelCallback?: setFetchCancell, data?: any):Promise<IResponseResult> => {
  return fetch(
    `${url}/${urlPart.replace(/^\//, '')}`,
    { ... deleteInitObject(), body: JSON.stringify(data) }, 
    setCancelCallback
  )
  .then(responseHandler)
  .catch(errorHandler)
}

export const downloadFilePOST = (urlPart: string, data: any):Promise<IResponseResult> => {
  return fetch(
    `${url}/${urlPart.replace(/^\//, '')}`,
    {...postInitObject(), body: JSON.stringify(data)},
    null
  )
  .then(downloadHandler)
  .catch(errorHandler)
}

export const downloadFileGET = (urlPart: string):Promise<IResponseResult> => {
  return fetch(
    `${url}/${urlPart.replace(/^\//, '')}`,
    {...getInitObject()}
  )
  .then(downloadHandler)
  .catch(errorHandler)
}

export const downloadFileByLink = (urlPart: string): any => {
  const a = document.createElement('a')
  a.setAttribute('download', '')
  a.href = `${url}/${urlPart.replace(/^\//, '')}`
  document.body.appendChild(a)
  a.click()
  a.remove()
}

// to do
// use fetch when it will support file upload progress
export const uploadFile = (
  urlPart: string, 
  formData: any, 
  onProgress: (...args: [number, ProgressEvent<EventTarget>]) => void,
  setCancelCallback: ((arg: Canceler) => void)
): any => {
  
  const CancelToken = axios.CancelToken;

  return axios.request({
    method: "post", 
    url: `${url}/${urlPart.replace(/^\//, '')}`, 
    data: formData, 
    cancelToken: new CancelToken(function (cancel: any) {
      setCancelCallback(cancel)
    }),
    onUploadProgress: (p: any) => {
      onProgress(p.loaded / p.total, p)
    }

  }).catch((error) => {
    return error.response
  })
}

export const getSuppressWarnSuffix = (suppressWarn: boolean) => suppressWarn ? '?suppressWarn=true' : '';

/**
 * Transform error response for particular field to common error response
 * @param {IResponseResult} response backend response
 * @param {Array<string>} fields fields to convert to common error
 * @returns {IResponseResult}
 */
export const convertFieldErrorToCommon = (response: IResponseResult, fields: Array<string>): IResponseResult => {
  const { errors } = response || {}

  if (!response || !fields || !fields.length || !errors || !errors.length) {
    return response
  }

  const fixedErrors = errors.map(error => {
    const { field } = error || {}
    if (fields.includes(field)) {
      delete error['field']
    }
    return error
  })

  return {
    ...response,
    errors: fixedErrors
  }
}

// to do - use fetch after it supports file upload progress
// export const uploadFile = (urlPart, formData) => {
//   const fixedHeader = { ...getHeaders() }
//   delete fixedHeader['Content-Type'];

//   return fetch(
//     `${url}/${urlPart.replace(/^\//, '')}`,
//     {
//       ...postInitObject(),
//       headers: { ...fixedHeader },
//       body: formData
//     }
//   )
//   .then(responseHandler)
//   .catch(responseHandler)
// }