import {
  USERGETALL, 
  USERDELETE,
  USERRESETPASSWORD,
  USERINPROGRESS, 
  USERRESETACTION, 
  USERONDELETE, 
  USERPAGINATIONGET, 
  ERROR ,
  ERRORPAGEERROR,
  USERBLOCKUSER, 
  USERUNBLOCKUSER
} from '../../actions/actionTypes'

import { getPageData } from 'app/components/ui/pagination/pagination'
import config from 'app/core/config'
import { dialogType } from 'app/components/dialog/confirmDialog/'
import { updateCollectionByItemId } from 'app/core/utility/common'

const { paginationPerPage } = config

const defaultState = {
  items: [],
  pageItems: [],
  pagination: { activePage: 1, itemsPerPage: paginationPerPage, dataCount: 0 },
  confirmObject: null,
  resetInfo: null,
  blockInfo: null,
  unblockInfo: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { 
      items,
      pagination, 
  } = state
  const { activePage: stateActivePage, itemsPerPage: statePerPage } = pagination
  const {data, dataCount} = payload || {}

  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case USERGETALL:
      const storeItems = data.map((item, index) => {

        return {
          ...item,
          index: index + 1,
        }
      })

      return {
        ...state,
        items: storeItems,
        pagination: {activePage: 1, itemsPerPage: statePerPage, dataCount: dataCount},
        inProgress: false
      }

    case USERPAGINATIONGET:
      const { activePage, itemsPerPage } = payload

      return {
        ...state,
        pagination: { activePage, itemsPerPage },
        pageItems: getPageData({ items, activePage, itemsPerPage }),
        inProgress: false
      }

    case USERONDELETE:
      const { confirmObject } = payload
      return {
        ...state,
        confirmObject
      }

    case USERRESETPASSWORD:
      return {
        ...state,
        resetInfo: {
          type: dialogType.message,
          title: `Пароль сброшен. Инструкция о восстановлении пароля отправлена пользователю`
        },
        inProgress: false,
      } 
      
    case USERUNBLOCKUSER:
      const unblockedUser = items.find(item => item.id === payload)

      if (unblockedUser) {
        const updatedItems = updateCollectionByItemId(items, {...unblockedUser, status: 'Активен'})

        return {
          ...state,
          pagination: pagination,
          items: updatedItems,
          pageItems: getPageData({
              items: updatedItems,
              activePage: stateActivePage,
              itemsPerPage : statePerPage
          }),
          unblockInfo: {
            type: dialogType.message,
            title: `Пользователь разблокирован`
          },
          inProgress: false,
        }
      }

      return {
        ...state,
        inProgress: false,
      }

    case USERBLOCKUSER:
      const blockedUser = items.find(item => item.id === payload)

      if (blockedUser) {
        const updatedItems = updateCollectionByItemId(items, {...blockedUser, status: 'Заблокирован'})

        return {
          ...state,
          pagination: pagination,
          items: updatedItems,
          pageItems: getPageData({
              items: updatedItems,
              activePage: stateActivePage,
              itemsPerPage : statePerPage
          }),
          blockInfo: {
            type: dialogType.message,
            title: `Пользователь заблокирован`
          },
          inProgress: false,
        }
      }

      return {
        ...state,
        inProgress: false,
      }
 
    case USERDELETE:
      const deletedIndex = items.findIndex( item => item.id === payload)

      if (deletedIndex >= 0){
        const afterDeleteItems = [
            ...items.slice(0, deletedIndex),
            ...items.slice(deletedIndex + 1)
        ].map((el, index) => ({...el, index: index + 1}))

        return {
          ...state,
          items: afterDeleteItems,
          pageItems: getPageData({
              items: afterDeleteItems,
              activePage: stateActivePage,
              itemsPerPage : statePerPage
          }),
          inProgress: false,
          confirmObject: null
        }
      }

      return state

    case ERROR:
      return {
        ...state,
        confirmObject: null,
        inProgress: false
      }

    case USERINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case USERRESETACTION: 
      return { 
        ...state, 
        confirmObject: null,
        resetInfo: null,
        blockInfo: null,
        unblockInfo: null
      }

    default: 
      return state
  }
}