import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  USERACCOUNTCREATE,
  USERACCOUNTCREATEUPDATEFORMDATA,
  USERACCOUNTCREATEINPROGRESS    ,
  USERACCOUNTCREATERESETACTION   ,
  USERACCOUNTCREATECLEARFORM  } from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { formatFIO } from 'app/core/utility/common'

const defaultState = {
  formData : {
    lastName: '',
    firstName: '',
    middleName: '',
    snils: '',
    accountCreateDate: new Date(),
    organizationId: null,
    comments: ''
  },
  registeredInfoObject: null,
  errorByFields: {},
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload } = action
  const { formData } = state
    
  switch (action.type) {

    case USERACCOUNTCREATE:
        return {
            ...state,
            registeredInfoObject: {
              type: dialogType.navigation,
              title: `Лицевой счет «${formatFIO(payload)}» успешно зарегистрирован`
            },
            inProgress: false
        }
    
    case ERROR:
      return {
        ...state,
        inProgress: false
      }

    case FIELDSERROR: 
      return {
        ...state,
        inProgress: false
      }

    case CLEARERROR: 
      return {
        ...defaultState,
        formData,
        inProgress: false
      }

    case USERACCOUNTCREATEUPDATEFORMDATA: 
      const { key, value } = payload

      return {
        ...state,
        formData: {...formData, [key]: value }
      }

    case USERACCOUNTCREATECLEARFORM: 
      return {
        ...defaultState
      }

    case USERACCOUNTCREATEINPROGRESS:
      const { inProgress } = payload

      return {...state, inProgress}

    case USERACCOUNTCREATERESETACTION: 
      return { 
        ...state, 
        inProgress: false,
        registeredInfoObject: null,
        error: null
      }

    default: 
      return state
  }
}