import React from 'react'
import PageItem from "../pageItem"

const _getPages = (activePage, pageCount) => {

  const result = 
    [activePage - 4, activePage - 3, activePage - 2, activePage - 1, activePage, activePage + 1, activePage + 2, activePage + 3]
    .filter(item => item > 0 && item <= pageCount)
  
  return result && result[0] ? result : [1]
}

const _isLastEllipsis = ({currentPage, pages, pageCount}) => {

  return pageCount - currentPage > 3
         && pages[pages.length - 1] !== pageCount - 1
}

export const Pages = ({
  currentPage,
  pageCount,
  onPageClick
}) => {

  const renderPages = _getPages(currentPage, pageCount)
  const renderLastPage = renderPages[renderPages.length - 1] !== pageCount && pageCount !== 0

  return (
    <>
      <PageItem
        key={1} 
        value={1} 
        isActive={currentPage === 1}
        onClick={onPageClick}
      />
      {currentPage > 5 ? (
        <PageItem 
          key={'...left'} 
          value={'...'} 
          isActive={false}
          disabled={true}
        />
      ) : null}
      {renderPages.slice(1).map(item => 
        <PageItem 
          key={item} 
          value={item} 
          isActive={currentPage === item}
          onClick={onPageClick}
      />)}
      {_isLastEllipsis({
        currentPage,
        pages: renderPages,
        pageCount
      }) ? (
        <PageItem 
          key={'...right'} 
          value={'...'} 
          isActive={false}
          disabled={true}
        />
      ) : null}
      {renderLastPage ? (
        <PageItem value={pageCount} onClick={onPageClick}/>
      ) : null}
    </>
  )
}