import { 
  ERROR,
  CLEARERROR,
  FIELDSERROR,
  SIGNATUREREADYTOSIGN,
  USERSKZIADMITTANCESETALLDATA,
  USERSKZIADMITTANCEGETALLDATA,
  USERSKZIADMITTANCEGETADMITTANCE,
  USERSKZIADMITTANCEGETTRAINING,
  USERSKZIADMITTANCEINPROGRESS,
  USERSKZIADMITTANCERESETACTION,
  USERSKZIADMITTANCEONTRAININGSELECT,
  USERSKZIADMITTANCEONADMITTANCESELECT,
  USERSKZIADMITTANCEADDADMITANCE,
  USERSKZIADMITTANCEUPDATEADMITANCE,
  USERSKZIADMITTANCEDELETEADMITANCE,
  USERSKZIADMITTANCEADDTRAINING,
  USERSKZIADMITTANCEDELETETRAINING
 } from '../../actions/actionTypes'

import { dialogType } from 'app/components/dialog/confirmDialog/'
import { excludeByItemId, arrayToIdHashMap, updateCollectionByItemId } from 'app/core/utility/common'


const defaultState = {
  selectedTraining: {
    items: [],
    selectedHashMap: {}
  },
  selectedAdmittance: {
    items: [],
    selectedHashMap: {}
  },
  admittance: [],
  training: [],
  confirmObject: null,
  inProgress: false
}

export default (state = defaultState, action) => {
  const { payload, errors } = action
  const { admittance, training, selectedAdmittance, selectedTraining } = state
    
  switch (action.type) {

    case USERSKZIADMITTANCESETALLDATA:
      const { admittances, prescriptions } = payload

      return {
        ...state,
        admittance: admittances,
        training: prescriptions,
        inProgress: false
      }

    case USERSKZIADMITTANCEGETALLDATA:
    case USERSKZIADMITTANCEGETADMITTANCE:
      return {
        ...state,
        admittance: payload,
        inProgress: false
      }

    case USERSKZIADMITTANCEGETTRAINING:
      return {
        ...state,
        training: payload,
        inProgress: false
      }

    case USERSKZIADMITTANCEDELETEADMITANCE:
      const { items: selectedItems } = selectedAdmittance
      let confirmDialog = null
      const afterDeleteSelected = excludeByItemId(selectedItems, payload.map(item => ({id: item}))) 

      if (errors && errors.length) {
        confirmDialog = {
          header: 'Удаление невозможно',
          type: dialogType.warning,
          messages: errors.map(item => item.errorMessage)
        }
      }

      return {
        ...state,
        selectedAdmittance: {
          items: afterDeleteSelected,
          selectedHashMap: arrayToIdHashMap(afterDeleteSelected)
        },
        admittance: excludeByItemId(admittance, payload.map(item => ({id: item}))),
        confirmObject: confirmDialog,
        inProgress: false
      }

    case USERSKZIADMITTANCEDELETETRAINING:
      const { items: selectedTrainingItems } = selectedTraining
      let confirmTrainingDialog = null
      const afterDeleteSelectedTraining = excludeByItemId(selectedTrainingItems, payload.map(item => ({id: item}))) 

      if (errors && errors.length) {
        confirmDialog = {
          header: 'Удаление невозможно',
          type: dialogType.warning,
          messages: errors.map(item => item.errorMessage)
        }
      }

      return {
        ...state,
        selectedTraining: {
          items: afterDeleteSelectedTraining,
          selectedHashMap: arrayToIdHashMap(afterDeleteSelectedTraining)
        },
        training: excludeByItemId(training, payload.map(item => ({id: item}))),
        confirmObject: confirmTrainingDialog,
        inProgress: false
      }

    case USERSKZIADMITTANCEUPDATEADMITANCE:
      
      return {
          ...state,
          admittance: updateCollectionByItemId(admittance, payload),
          selectedAdmittance: {
            items: [payload],
            selectedHashMap: arrayToIdHashMap([payload])
          },
          inProgress: false
        }
  
    case USERSKZIADMITTANCEONTRAININGSELECT: 
      return {
        ...state,
        selectedTraining: payload || defaultState.selectedTraining
      }

    case USERSKZIADMITTANCEONADMITTANCESELECT: 
      return {
        ...state,
        selectedAdmittance: payload || defaultState.selectedAdmittance
      }

    case USERSKZIADMITTANCEADDADMITANCE: 
      return {
        ...state,
        admittance: [ payload, ...admittance],
        inProgress: false
      }

    case USERSKZIADMITTANCEADDTRAINING: 
      return {
        ...state,
        training: [ payload, ...training],
        inProgress: false
      }

    case USERSKZIADMITTANCEINPROGRESS: 
      return {
        ...state,
        inProgress: payload
      }

    case USERSKZIADMITTANCERESETACTION:
      return {
        ...state,
        confirmObject: null,
        inProgress: false
      }

    case SIGNATUREREADYTOSIGN:
    case ERROR:
    case CLEARERROR:
    case FIELDSERROR:
      return {
        ...state,
        inProgress: false
      }

    default: 
      return state
  }
}