import {
  DISABLEACTIONPANEL,
  CARDRESET,
  CARDSEREVERUPDATE
} from '../../actions/actionTypes'

export default ( state = false, action ) => {

  if( action.type === DISABLEACTIONPANEL ){
    return true;
  }

  if( action.type === CARDRESET ){
    return false;
  }

  if( action.type === CARDSEREVERUPDATE ){
    return false;
  }

  return state;
}