import {
  SIGNATURESETREQUESTDATA,
  SIGNATUREREADYTOSIGN,
  SIGNEDSUCCESSFULLY,
  SIGNATURERESET,

  // all signed actions
  ESKZIREGISTRATION,
  KEYDOCUMENTCREATE,
  SKZIINSTANCERUNEVENT,
  ESKZIEVENTSUPDATETASK,
  KEYDOCUMENTRUNEVENT,
  KEYDOCUMENTSUPDATETASK,
  ESKZICARDRUNEVENT,
  KEYDOCUMENTCARDRUNACTION,
  ESKZICARDSERVICEDELETEACTION,
  ESKZICARDSERVICEUPDATEACTION,
  ESKZICARDSERVICEADDACTION,
  SKZICREATE,
  ERRORPAGEERROR,
  USERSKZIADMITTANCEADDADMITANCE,
  USERSKZIADMITTANCEUPDATEADMITANCE,
  ADMITTANCECARDUPDATE,
  ADMITTANCECREATE,
  LICENSECREATE,
  LICENSECARDUPDATE,
  ESKZICARDADDLICENSES,
  ESKZICARDDELETELICENSES,
  LICENSEUPLOADCOMPLETE,
  MAINTENANCECARDUPDATE,
  MAINTENANCECREATE,
  SELFSERVICEREGISTRYINSTALLSKZI,
  CARDAFTERCREATE,
  CARDSEREVERUPDATE,
} from '../actions/actionTypes'

export const defaultState = {
  requestData: null,
  textForSignature: '',
  signatureResult: ''
}

export default (state = defaultState, action) => {
  const { payload } = action

  switch (action.type) {
    case SIGNATURESETREQUESTDATA:
      return {
        ...state,
        requestData: payload
      }

    case SIGNATUREREADYTOSIGN:
      return {
        ...state,
        textForSignature: payload
      }

    case SIGNEDSUCCESSFULLY:
      return {
        ...state,
        signatureResult: payload
      }

    case ERRORPAGEERROR:
    case ESKZICARDRUNEVENT:
    case KEYDOCUMENTCREATE:
    case KEYDOCUMENTCARDRUNACTION:
    case KEYDOCUMENTRUNEVENT:
    case ESKZIEVENTSUPDATETASK:
    case KEYDOCUMENTSUPDATETASK:
    case SKZIINSTANCERUNEVENT:
    case SIGNATURERESET:
    case ESKZICARDSERVICEDELETEACTION:
    case ESKZICARDSERVICEUPDATEACTION:
    case ESKZICARDSERVICEADDACTION:
    case SKZICREATE:
    case ESKZIREGISTRATION:
    case USERSKZIADMITTANCEADDADMITANCE:
    case USERSKZIADMITTANCEUPDATEADMITANCE:
    case ADMITTANCECARDUPDATE:
    case ADMITTANCECREATE:
    case MAINTENANCECARDUPDATE:
    case MAINTENANCECREATE:
    case SELFSERVICEREGISTRYINSTALLSKZI:
    case LICENSECREATE:
    case LICENSECARDUPDATE:
    case ESKZICARDADDLICENSES:
    case ESKZICARDDELETELICENSES:
    case LICENSEUPLOADCOMPLETE:
    case CARDAFTERCREATE:
    case CARDSEREVERUPDATE:
      return {
        ...defaultState
      }

    default:
      return state
  }
}