import React from 'react'

const TabsHeader = props => {
  const { tabs = [], activeTab, onTabClick } = props

  return (
    <div className={`tabs-header`}>
      {tabs.map(tab => {
        const { props } = tab
        const { tabTitle, disabled } = props || {}

        return (
          <a 
            key={tabTitle}
            className={`tab tab--${activeTab === tabTitle ? 'active' : 'inactive'}  ${disabled ? 'tab--disabled' : ''}`}
            onClick = {(e) => { 
              e.preventDefault()
              onTabClick && onTabClick(tabTitle)
            }}
          >
            {tabTitle}
          </a>
        )
      })}
    </div>
  )
}

export default TabsHeader