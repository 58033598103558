import React, {useEffect, useRef, useState} from 'react';
import Title from "app/components/list/ManagePanel/Title";
import DropDown from "app/components/ui/DropDown";
import AsyncOperationUpload from "app/components/asyncOperationUpload";
import Pagination from "app/components/ui/pagination/pagination";
import isEqual from "lodash.isequal";
import ChipsList from "app/components/ui/chips/chipsList";
import Overlay from "app/components/ui/overlay";
import useDebounce from "app/hooks/useDebounce";
import {useDispatch, useSelector} from "react-redux";
import {sortDirection} from "app/components/ui/constants";
import {ActionCreator} from "redux/actions/uploadProcessing/uploadProcessing";
import {getWSUploads} from "redux/actions/uploadProcessing/selectors";
import DateRangePicker from "app/components/ui/date/DateRangePicker/DateRangePicker";
import SimpleSearch from 'app/components/ui/search/SimpleSearch';
import useReducerTable from "app/components/list/Table/useTableExtensions";
import {Status, Type, GroupStatuses} from "app/components/MassImports/enums";
import service from "app/services/service";
import {getUserHistory} from "redux/actions/uploadProcessing/selectors";
import {Config} from "../MassImports/configs/";
import { formatDate } from 'app/core/utility/date';

const areEqual = (prevProps, nextProps) => {
    return isEqual(prevProps.uploads, nextProps.uploads);
};

export default React.memo(function Imports() {
    const dispatch = useDispatch();
    const parseDefault = useRef({...Config.PAYLOAD.IMPORTS_PAGE});
    const [isLoad, setLoadState] = useState(true);
    const [startDate, setStartDateState] = useState(parseDefault.current.startDate);
    const [endDate, setEndDateState] = useState(parseDefault.current.endDate);
    const [groupStatusChips, setGroupStatusChips] = useState(Config.IMPORTS_CHIPS);
    const [inputValue, setInputValueState] = useState('');
    const [activeFilterType, setActiveFilterType] = useState({title: Type.ALL})
    const debouncedSearchTerm = useDebounce(inputValue, 1000);
    const WSUploads = useSelector(getWSUploads);
    const uploads = useSelector(getUserHistory);

    const {
        onItemUpdate,
        onInitValues,
        currentPage,
        perPage,
        pageCount,
        setCurrentPage,
    } = useReducerTable({
        defaultSort: {
            column: 'id',
            direction: sortDirection.desc
        },
    });
    const updateUserHistory = async () => {
        const {subject} = await service('ImportProgress', 'fileHistory', parseDefault.current)
        const {importProgress} = subject;
        dispatch(ActionCreator.setUserHistory(importProgress));
        onItemUpdate(importProgress);
    };

    const onSearchingHandler = async (value) => {
        parseDefault.current = {...parseDefault.current, names: [value]};
        updateUserHistory();
    };
    
    const onClickChipsHandler = (type, state) => {
        const {current} = parseDefault;

        if (!parseDefault.current.groupStatuses) {
            parseDefault.current.groupStatuses = [];
        }
        
        const shouldShowAll = groupStatusChips.every(c =>
            c.type === 'all' || c.type !== type && !c.isActive || c.type === type && !state);
        const updateChipState = curChipState => type === 'all'
            ? { ...curChipState, isActive: curChipState.type === 'all' }
            : type === curChipState.type
            ? { ...curChipState, isActive: state }
            : curChipState.type === 'all'
            ? { ...curChipState, isActive: shouldShowAll }
            : { ...curChipState };
        
        const newChipsState = groupStatusChips.map(updateChipState);
        setGroupStatusChips(newChipsState);
        
        const chipValues = Object.fromEntries(newChipsState.map(c => [c.type, c.isActive]));
        
        parseDefault.current = {
            ...current,
            groupStatuses: chipValues.all
                ? undefined
                : Object.keys(chipValues).filter(k => chipValues[k]).map(k => +k)
        };

        updateUserHistory();
    };
    
    const onClickMenuElementHandler = async (fileId, fileStatus, fileType, shortOfLicenses) => {
        switch (fileStatus) {
            case Status.LOADING_END:
            case Status.CREATING_END:
                dispatch(ActionCreator.setParsedFileInfo({fileStatus, fileId, fileType}));
                break;
            case Status.LOADING:            
            case 'Загрузка файла': // TODO Remove this fallback line when api/v1/ImportProgress/fileHistory loading status gets fixed
                dispatch(ActionCreator.setFileSingleParsingId(fileId));
                dispatch(ActionCreator.setFileSingleParsing(true));
                break;
            case Status.ERROR:
                if (shortOfLicenses) {
                    dispatch(ActionCreator.setParsedFileInfo({fileStatus: Status.LOADING_END, fileId, fileType}));
                }
                
                break;
            default:
                break;
        }
    };
    const onDateChange = date => {
        const {from, to} = date;
        const formattedFrom = formatDate(from, 'yyyy-mm-dd')
        const formattedTo = formatDate(to, 'yyyy-mm-dd')
        parseDefault.current = {...parseDefault.current, startDate: formattedFrom};
        parseDefault.current = {...parseDefault.current, endDate: formattedTo};
        setStartDateState(formattedFrom);
        setEndDateState(formattedTo);

        updateUserHistory();
    };
    
    const onChangeFilterType = (value) => {        
        const fileImportTypeFilterValues = {
            [Type.ORGANIZATION]: {id: 1, name: Type.ORGANIZATION},
            [Type.LICENSE]: {id: 2, name: Type.LICENSE},
            [Type.USER_ACCOUNT]: {id: 3, name: Type.USER_ACCOUNT},
            [Type.KEY_CARRIER]: {id: 4, name: Type.KEY_CARRIER},
            [Type.OKZ_KEY_DOCUMENT]: {id: 5, name: Type.OKZ_KEY_DOCUMENT},
            [Type.OKI_KEY_DOCUMENT]: {id: 6, name: Type.OKI_KEY_DOCUMENT},
            [Type.OKZ_KEY_ESKZI]: {id: 7, name: Type.OKZ_KEY_ESKZI},
            [Type.OKI_KEY_ESKZI]: {id: 8, name: Type.OKI_KEY_ESKZI},
        };
        
        parseDefault.current = {
            ...parseDefault.current,
            fileImportTypes: value && value.title && fileImportTypeFilterValues[value.title]
                ? [fileImportTypeFilterValues[value.title]]
                : undefined,
        };

        setActiveFilterType(value);
        updateUserHistory();
    };

    useEffect(() => {
        if (debouncedSearchTerm || debouncedSearchTerm === '') {
            onSearchingHandler(inputValue);
        }
    }, [debouncedSearchTerm]);
    useEffect(() => {
        if (uploads.length > 0) {
            setLoadState(false);
        }
    }, [uploads]);
    useEffect(() => {
        updateUserHistory();
        onInitValues([]);
    }, []);
    useEffect(() => {
        if (uploads && WSUploads.length > 0) {
            dispatch(ActionCreator.setUserHistory(
                uploads.map(item => {
                    WSUploads.forEach(el => {
                        if (item.fileId === el.fileId) {
                            item = {...el};
                        }
                    });

                    return item;
                })
            ));
        }
    }, [WSUploads]);

    return (
        <>
            {isLoad && <Overlay />}
            <section className={"import"}>
                <div className={"import__header"}>
                    <div className={"list__header"}>Загрузка и создание записей</div>
                    <Title type={"import"} title={''}/>
                    <div className={"import__panel"}>
                        <ChipsList
                            chips={groupStatusChips}
                            onClickHandler={onClickChipsHandler}
                        />
                    </div>
                    <div className={"circle-progressbar__controls"}>
                        <DateRangePicker
                            isClearable = { false }
                            shouldCloseOnSelect
                            fromDate = { startDate }
                            toDate = { endDate }
                            onChangeFrom = { onDateChange }
                            onChangeTo = { onDateChange }
                            disabled = { false }
                        />
                        <DropDown
                            active={activeFilterType}
                            items={[
                                {title: Type.ALL},
                                {title: Type.ORGANIZATION},
                                {title: Type.LICENSE},
                                {title: Type.USER_ACCOUNT},
                                {title: Type.KEY_CARRIER},
                                {title: Type.OKZ_KEY_DOCUMENT},
                                {title: Type.OKI_KEY_DOCUMENT},
                                {title: Type.OKZ_KEY_ESKZI},
                                {title: Type.OKI_KEY_ESKZI},
                            ]}
                            onSelect={onChangeFilterType}
                        />
                        <SimpleSearch
                            placeholder = { 'Наименование файла' }
                            value={inputValue}
                            onChange = { (value) => setInputValueState(value) }
                            disabled = { false }
                        />
                    </div>
                </div>
                {
                    uploads.length > 0 &&
                    <AsyncOperationUpload
                        uploads={uploads}
                        onClickElementHandler={onClickMenuElementHandler}
                        isFull
                    />
                }
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageCount={pageCount}
                    perPage={perPage}
                />
            </section>
        </>
    );
}, areEqual);
