/** @format */

import { useEffect, useState } from 'react';
import { api } from 'app/services/service';
import { formatDate } from 'app/core/utility/date';
import { pad, split } from 'app/core/utility/arrays';
import Img, { Image } from 'app/components/ui/Img';
import SpinnerLocal from 'app/components/ui/SpinnerLocal';

import productDescription from './productDescription';

import styles from './About.module.scss';

const versionColCount = 4;

const About = () => {
  const [backendVersion, setBackendVersion] = useState();
  const [versionInfo, setVersionInfo] = useState({});
  useEffect(() => {
    const fetchVersionInfo = async () => {
      const coreVersionResult = await api('systemVersion/getShortInfo');
      const { data: { version } = {} } = coreVersionResult;
      setBackendVersion(version);

      const result = await api('systemVersion/getFullInfo');
      const {
        data: { buildDate, systemVersions },
      } = result || {};
      setVersionInfo({ buildDate, systemVersions });
    };

    fetchVersionInfo();
  }, []);

  return (
    <div className={styles.about}>
      <div className={styles.header}>
        <Img img={Image.Question} />
        Система (ядро v.{backendVersion ? backendVersion : '...'})
      </div>
      <div className={styles.content}>
        {productDescription.visible && (
          <div className={styles.description}>
            <div className={styles.descriptionText}>
              {productDescription.paragraphs.map(p => (
                <p className={styles.descriptionPara}>{p}</p>
              ))}
            </div>
            <img className={styles.companyLogo} src={productDescription.companyLogo} />
          </div>
        )}

        {!versionInfo.systemVersions && <SpinnerLocal className={styles.spinner} />}
        {versionInfo.systemVersions && (
          <table className={styles.components}>
            <thead>
              <tr>
                <th colSpan={versionColCount} className={styles.componentsHeaderCell}>
                  Версия компонентов системы {' '}
                  {versionInfo.buildDate && <>(дата {formatDate(new Date(versionInfo.buildDate), 'dd.mm.yyyy')})</>}
                </th>
              </tr>
            </thead>
            <tbody>
              {split(versionInfo.systemVersions, 4).map((r, ri) => (
                <tr key={ri}>
                  {pad(r, versionColCount, {}).map(({ serviceName, version }, ci) => (
                    <td key={ci} className={styles.componentsBodyCell} style={{ width: `${100 / versionColCount}%` }}>
                      {serviceName && (
                        <>
                          {serviceName}: v{version}
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default About;
