/** @format */

/**
 * Creates a function returning inverted result
 * @param {Function} fn Input function
 * @returns {Function}
 */
const not =
  fn =>
  (...args) =>
    !fn(...args);

export { not };
