import { useState, useEffect } from 'react';
import globalConfig from 'app/core/config'
import { arrayToIdHashMap, excludeByItemId, mergeArraysById } from 'app/core/utility/common';

const usePagination = ({
  defaultCurrentPage = 1,
  defaultTotalItems = 0,
  defaultPerPage = globalConfig.paginationPerPage,
}) => {

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage)
  const [perPage, setPerPage] = useState(defaultPerPage)
  const [totalCount, setTotalItems] = useState(defaultTotalItems)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [pageCount, setPageCount] = useState(1)
  const [selectedObjects, setSelectedObjects] = useState([])

  useEffect(() => {
    const count = totalCount === 0
                  ? 1
                  : Math.ceil(totalCount/new Number(perPage))
    setPageCount(count)
  }, [totalCount, perPage])

  const setSelectedPagination = (data) => {
    const { added, removed, updated } = data || {}
    if (added) {
      setSelectedObjects(mergeArraysById(selectedObjects, added))
    } else if (removed) {
      setSelectedObjects(excludeByItemId(selectedObjects, removed))
    } else if (updated) {
      setSelectedObjects(mergeArraysById(selectedObjects, updated))
    }
  }

  const restoreCurrentPage = data => {
    const { currentPage = 1 } = data || {}
    setCurrentPage(currentPage)
  }

  return {
    currentPage,
    pageCount,
    perPage,
    totalCount,
    setPerPage,
    setTotalItems,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    setSelectedObjects,
    needRefresh, 
    setNeedRefresh,
    restoreCurrentPage
  }
}

export default usePagination