/** @format */

import React from 'react';
import InfoWidget from '../infoWidget';

export default function Expiring({ keyDocs, certificates,licenses }) {
  return (
    <InfoWidget
      className="expiring"
      headerText="Истекает срок действия"
      dataRows={[
        { 
          title: 'Ключевые документы', 
          data: keyDocs, 
          // reference: '' 
        },
        { 
          title: 'Сертификаты соответствия', 
          data: certificates, 
          // reference: ''
        },
        {
          title: 'Лицензии СКЗИ', 
          data: licenses, 
          // reference: '' 
        },
      ]}
    />
  );
}
