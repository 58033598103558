import {
  TRAININGCOURSESCREATE   ,           
  TRAININGCOURSESCREATEUPDATEFORMDATA,
  TRAININGCOURSESCREATEINPROGRESS,    
  TRAININGCOURSESCREATERESETACTION ,  
  TRAININGCOURSESCREATECLEARFORM ,    
  } from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const registerCourse = data => dispatch => {
  return service('courseService', 'registerCourse', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, TRAININGCOURSESCREATE, data, isError, errors)
    })
}

export const createCourseUpdateFormData = ({ key, value }) => dispatch => {
  dispatch({
    type: TRAININGCOURSESCREATEUPDATEFORMDATA,
    payload: { 
      key, value
    }
  })
}

export const createCourseInProgressAction = value => dispatch => {
  dispatch({
    type: TRAININGCOURSESCREATEINPROGRESS,
    payload: { inProgress: value }
  })
}

export const createCourseResetAction = () => dispatch => {
  dispatch({
    type: TRAININGCOURSESCREATERESETACTION,
    payload: null
  })
}

export const courseCreateClearForm = () => dispatch => {
  dispatch({
    type: TRAININGCOURSESCREATECLEARFORM,
    payload: { 
    }
  })
}