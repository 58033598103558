import React from 'react'
import Button from 'app/components/ui/button/button'
import withTooltip from 'app/components/HOC/TooltipHOC';
import Img from './Img';
import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'


export default ({ action, disabled, img, text, className, type, isShow, needChevron = false }) => {
  if( isShow === false ) return null;

  const _action = () => {
    action();
  }

  if( text !== undefined ){
     return(
      <Button 
        className = { className || 'create-button' }
        type='primary'
        disabled = { disabled }
        onClick = { action }
      >
        { img && <Img type = { img } className = "button-image--left" /> }
        <span className='button-title'>--{ text }</span>
      </Button>
    )
  }

  if( className ){
    className += ' button-image';
  }else{
    className = 'button-image';
  }

  if(needChevron) {
    className +=' button--select'
  }

  return(
    <Button type = { type || 'image' } onClick = { _action } disabled = { disabled } className = { className }>
      <Img type = { img }  />
      {needChevron && (<ChevronImg className='button-image button-image--right'/>)}
    </Button>
  )
}

export const ButtonWithTooltip = withTooltip( Img );