import {useState} from 'react'
import service from '../../../services/service'
import { defaultRolesState } from 'redux/reducers/user/userCardReducer'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addToast } from '../../../../redux/actions/toast'
import {rolesValidationRules} from '../userCard/userData'


export default function useUserRolesData() {

  const dispatch = useDispatch()
  const [editStateBlock, setEditStateBlock] = useState(false)
  let { id } = useParams()

  const updateCallback = (response) => {
    const {data, errors, isError} = response
    if (data && !errors && !isError) {
      dispatch(addToast('Запись успешно обновлена'))
      setEditStateBlock(false)
    }
    return response
  }

  const formData = {
    validationRules: rolesValidationRules,
    defaultState: defaultRolesState
  }

  const get = () => service('userService', 'getUserRoles', id)

  const update = (data) => service('userService', 'updateRoles', {id, data})

  return {
    formData,
    id,
    editStateBlock,
    setEditStateBlock,
    get,
    update,
    updateCallback
  }
}
