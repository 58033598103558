import React, {useEffect} from 'react'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import Overlay from 'app/components/ui/overlay'
import FunctionalCard from '../../ui/card/FunctionalCard'
import useFunctionalCard from '../../ui/card/FunctionalCard/useFunctionalCard'
import RolesForm from './RolesForm'
import {useSelector} from 'react-redux'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import useUserRolesData from '../hooks/useUserRolesData'

export default function UserRolesBlock({isADUser}){

  const profile  = useSelector(({profile}) => profile)

  const rights = getResourceRights(profile, RESOURCES.user)

  const {
    formData,
    editStateBlock,
    setEditStateBlock,
    get,
    update,
    updateCallback
  } = useUserRolesData()

  const {
    cardState,
    editState,
    setState,
    inProgress,
    fieldsError,
    commonDataForm,
    onAdd,
    onRemove,
    onSubmit,
    submitEnabled,
    onCancelForm,
    onFormChange,
  } = useFunctionalCard({formData, get, update, updateCallback, needUpdateState: true, parentEditState: editStateBlock})

  useEffect(()=> {
    setEditStateBlock(editState)
  }, [editState])

  return (
    <ExpandingBlock
      renderHeader= {() => 'Роли'}
      initialState={true}
    >
      {inProgress ? <Overlay /> : null}
      <FunctionalCard
        showButtons={true}
        actions={['edit']}
        cardState={cardState}
        canEdit={rights.UPDATE && !isADUser}
        editOnly={false}
        onSubmit={onSubmit}
        submitEnabled={submitEnabled}
        submitTitle="Сохранить"
        onCancelForm={onCancelForm}
        setState={setState}
        className="ad-settings-data-card"
        editState={editState}
        cancelEnabled={true}
      >
        <div className="card-material__content">
          <RolesForm
            cardState={cardState}
            formData={commonDataForm}
            serverFormViewModel={null}
            onChange={onFormChange}
            errorByFields={fieldsError}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        </div>
      </FunctionalCard>
    </ExpandingBlock>
  )
}