/** @format */
import { useState } from 'react';
import Group from 'app/components/ui/managePanel/group';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { EventCode, EndpointType, ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/enums';
import { Image } from 'app/components/ui/Img';
import { Delete } from 'app/components/list/TopPanel/Delete';
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';
import { formatDate } from 'app/core/utility/date';
import RecordKDButton from './linkButton/RecordKDButton';
import { Type as MassImportType } from 'app/components/MassImports/enums';
import MassImportButton from 'app/components/MassImports/MassImportButton';
import ExportToExcelButton from 'app/components/exportToExcelButton';
import ImportCertificateButton from 'app/components/certificates/import/importButton';
import ImportCertificateWizard from 'app/components/certificates/import/importWizard';

const okiDeliveryOkData = {
  transmittalLetterDate: formatDate(new Date(), 'yyyy-mm-dd'),
};

export default function BusinessEventPanel(props) {
  const {
    globalOrgId,
    items,
    afterAction,
    filterForRequest,
    sorting,
    fieldsVisible,
    massImportRights,
    setSelectedAction,
    onInProgress,
  } = props;
  const [uploadWizardEnabled, setUploadWizardEnabled] = useState(false);
  const toggleCertificateWizard = () => {
    setUploadWizardEnabled(!uploadWizardEnabled);
  };

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service(
      'keyDocOkiService',
      'deleteRange',
      items.map(i => i.id)
    );
    onInProgress(false);
    return response;
  };

  const allHasChangeUser =
    items.length &&
    items.every(item => {
      const { availableActions = [] } = item;
      return availableActions.some(i => i.code === 'ChangeUser');
    });

  return (
    <>
      {uploadWizardEnabled && (
        <ImportCertificateWizard massImport onCancel={toggleCertificateWizard} onImportComplete={afterAction} oki />
      )}
      <Group>
        <ImportCertificateButton onClick={toggleCertificateWizard} massImport />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturn]}
          tooltip="Вернуть"
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiDeliveryOk]}
          tooltip="Получить экземпляр"
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Delivery}
          needForm={false}
          defaultSignData={okiDeliveryOkData}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiSendingOk]}
          tooltip="Подтвердить получение"
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okzReturnOk]}
          tooltip="Подтвердить возврат"
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        {!allHasChangeUser ? (
          <BusinessEventTrigger
            items={items}
            eventCode={[EventCode.okiDelivery]}
            tooltip="Выдать"
            endpointType={EndpointType.keyDocument}
            entityType={ENTITY_TYPE.keyDocument}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        ) : (
          <BusinessEventTrigger
            items={items}
            eventCode={[EventCode.changeUser]}
            tooltip="Выдать другому пользователю"
            endpointType={EndpointType.keyDocument}
            entityType={ENTITY_TYPE.keyDocument}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        )}
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiWithdrawalKD]}
          tooltip="Изъять"
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.SoftReturn}
        />
      </Group>
      <Group>
        <RecordKDButton onInProgress={onInProgress} items={items} afterAction={afterAction} />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiInstallation]}
          tooltip="Установить"
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.DownloadCircle}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={[EventCode.okiInstallationKeyDocument]}
          tooltip="Ввод в аппаратное СКЗИ"
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Link}
        />
      </Group>
      <Group>
        {massImportRights.IMPORT && (
          <MassImportButton type={MassImportType.OKI_KEY_DOCUMENT} tooltip="Загрузка ключевых документов из файла" />
        )}
        <ExportToExcelButton
          id={globalOrgId}
          listFilterForRequest={filterForRequest}
          sorting={sorting}
          listFieldsVisibility={fieldsVisible}
          selected={items.map(i => i.id)}
          serviceName="keyDocOkiService"
          onStart={() => onInProgress(true)}
          onFinish={() => onInProgress(false)}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={EventCode.destruction}
          tooltip="Уничтожить"
          endpointType={EndpointType.keyDocument}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Extermination}
        />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction={afterAction}
          setSelectedAction={setSelectedAction}
          dialog={data => {
            const arr = ['выбранный ключевой документ', 'выбранные ключевые документы', 'выбранные ключевые документы'];
            return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`;
          }}
        />
      </Group>
    </>
  );
}
