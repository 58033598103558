import React, { Component } from 'react'
import { compose } from 'redux'

import withToggle from 'app/components/HOC/ToggleHOC'
import withDocumentClick from 'app/components/HOC/DocumentClickHOC'

class infoPanel extends Component {
  constructor(props){
    super(props)

    this.panelNode = React.createRef()
    this.state = {
      vericalDirection: 'bottom',
      pagePaddingBottom: 100
    }
  }

  componentDidUpdate () {
    if (!this.panelNode.current) {
      return
    }
    
    const boundaries = this.panelNode.current.getBoundingClientRect()
    const { vericalDirection: stateDirection, pagePaddingBottom } = this.state
    const desiredDirection = 
            boundaries.bottom > document.documentElement.clientHeight - pagePaddingBottom 
              ? 'top' 
              : 'bottom'

    desiredDirection !== 'bottom' && stateDirection !== desiredDirection && this.setState({ 
      vericalDirection: desiredDirection
    })
  }

  _onMouseEnter = () => {
    const { toggleStatus, toggle, onHover = true} = this.props
    
    if (onHover && !toggleStatus) {
      toggle()
    }
  }

  _onMouseLeave = () => {
    const { toggleStatus, toggle, onHover = true } = this.props
    
    if (onHover && toggleStatus) {
      toggle()
    }
  }

  render() {
    const { 
      className, 
      children, 
      toggleStatus, 
      toggle, 
      image, 
      onHover = true, 
      renderTrigger
    } = this.props
    const { vericalDirection } = this.state

    return (
      <div 
        className={`info-panel ${className || ''}`}
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
      >
        {renderTrigger ? (
          <div onClick={onHover ? null : toggle}>{renderTrigger()}</div> 
        ) : (
          <img 
            className='info-panel__trigger' 
            src={image} 
            onClick={onHover ? null : toggle}
          />
        )}
        {toggleStatus ? (
          <div 
            className={`info-panel__panel panel--${toggleStatus ? 'opened' : ''} panel--${vericalDirection}`}
            ref={this.panelNode}
          >
            {children}
          </div>
        ) : null}
      </div>
    )
  }
}

export default compose(withToggle, withDocumentClick)(infoPanel)