/** @format */

import { useState } from 'react';

import { dialogType } from 'app/components/dialog/confirmDialog';
import { excludeByItemId } from 'app/core/utility/common';

const useDelete = ({
  items,
  considerRemovability,
  parentDisabled = false,
  confirmContent,
  deleteItemsAction,
  setSelectedAction = () => {},
  afterAction,
  customConfirm = false,
  onInProgress,
}) => {
  const [confirm, setConfirm] = useState({});

  const hasBizLockedItems = () => items.some(i => i && i.bizLocked);
  const hasNonRemovableItems = () => considerRemovability && items.some(i => i && !i.canBeRemoved);
  const disabled = parentDisabled || !items.length || hasBizLockedItems() || hasNonRemovableItems();

  const confirmDelete = () => {
    const message = confirmContent(items);
    const confirmObject = {
      title: message,
      type: dialogType.confirm,
    };
    setConfirm(confirmObject);
  };

  const removeConfirm = () => {
    const noConfirmObject = { title: '', type: '' };
    setConfirm(noConfirmObject);
  };

  const invokeCustomConfirm = items => {
    removeConfirm();
    customConfirm && customConfirm();
  };

  const invokeConfirm = async items => {
    const ids = items.map(el => el.id || el);
    removeConfirm();
    onInProgress && onInProgress(true);
    const deleteItemsResponse = await deleteItemsAction(ids);
    const { data: deletedArray } = { data: [], ...deleteItemsResponse };
    const remainingSelectedItemIds = excludeByItemId(
      items.map(i => (i.id ? i : { id: i })),
      deletedArray.map(i => ({ id: i }))
    ).map(i => i.id);
    setSelectedAction(remainingSelectedItemIds);
    removeConfirm();
    if (deleteItemsResponse.isError === true) {
      const { errors } = deleteItemsResponse;

      const message = errors.map(el => {
        if (!el.errorMessage && el.detail && el.detail.response) {
          return el.detail.response.errors[0].errorMessage;
        }
        return el.errorMessage || '';
      });

      const confirmObject = {
        title: message,
        type: dialogType.warning,
      };

      setConfirm(confirmObject);
    }

    onInProgress && onInProgress(false);
    deletedArray.length && afterAction && afterAction(deletedArray);
    return deletedArray;
  };

  const onSubmit = customConfirm ? invokeCustomConfirm : invokeConfirm;

  return {
    deleteTriggerLogic: {
      disabled,
      onAct: confirmDelete,
    },
    deleteLogic: {
      items,
      confirm,
      onSubmit,
      onCancel: removeConfirm,
    },
  };
};

export default useDelete;
