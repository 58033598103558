import React, { Component } from 'react'

import Autocomplete, { Services, renderItem, renderInputValue }  from 'app/components/ui/autocomplete/autocomplete'
import Button from 'app/components/ui/button/button'

export default class addTrainingDialog extends Component {
  constructor(props){
    super(props)

    this.state = { skziVersion: null }
  }

  render() {
    const { onCancel, onSubmit } = this.props
    const { skziVersion } = this.state

    return (
      <div className='add-training-dialog'>
        <Autocomplete 
          label = 'СКЗИ'
          className='card-field add-admittance-dialog__skzi'
          onEmptySearch = {true} 
          serviceType={Services.skziVersion} 
          renderItem={renderItem.skziVersion}
          renderInputValue={renderInputValue.skziVersion}
          onSelect={value => this.setState({skziVersion: value})}
          value={skziVersion}
        />
        <div className="modal__footer">
          <Button
            className='footer__item'
            type = 'text'
            onClick = {() => onSubmit(skziVersion)}
            disabled={!skziVersion}
          >
            <span className='button-title'>Добавить</span>
          </Button>
          <Button
            className='footer__item'
            type = 'text'
            onClick = {onCancel}
          >
            <span className='button-title'>Отменить</span>
          </Button>

        </div>
      </div>
    )
  }
}
