import { 
ERROR,
CLEARERROR,
FIELDSERROR,
ERRORPAGEERROR,
USERACCOUNTCARDGET
  } from '../../actions/actionTypes'
  
import config from 'app/core/config'

const { paginationPerPage } = config
const defaultState = {
  commonDataForm : {
    lastName: '',
    firstName: '',
    middleName: '',
    snils: '',
    accountCreateDate: new Date(),
    organizationId: null,
    comments: ''
  },
  userAccountData: {},
  userEskziData: {
    items:[],
    pageItems: [],
    pagination: {activePage: 1, itemsPerPage: paginationPerPage},
  },
  userKeyDocumentData: {
    items:[],
    pageItems: [],
    pagination: {activePage: 1, itemsPerPage: paginationPerPage},
  },
  tabs: { primaryActive: 'Общие данные', secondaryActive: '' },
  confirmDeleteObject: null,
  deletedInfoObject: null,
  closeDialog: false,
  inProgress: false
}
  
export default (state = defaultState, action) => {
  const { payload } = action
  const { tabs, userKeyDocumentData, userEskziData, userAccountData, commonDataForm } = state
    
  switch (action.type) {
    case ERRORPAGEERROR:
      return {
          ...state,
          inProgress: false
      }
    case USERACCOUNTCARDGET:
      const { organization: getOrg } = payload

      return {
          ...state,
          commonDataForm: {
            ...payload, 
            organizationId: getOrg
          },
          userAccountData: {
            ...payload,
            organizationId: getOrg
          },
          closeDialog: false,
          inProgress: false
      }

    case CLEARERROR:
      return {
        ...defaultState,
        tabs,
        userEskziData,
        userKeyDocumentData,
        userAccountData,
        commonDataForm
      }

    case FIELDSERROR:
    case ERROR: 
      return {
        ...state,
        inProgress: false
      }

    default: 
      return state
  }
}