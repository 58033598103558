// TODO
import {
  ERROR,
  FIELDSERROR,
  ERRORPAGEERROR,
  CLEARERROR,
  CARD_UPDATE,
  CARD_GET,
  CARD_UPDATE_FORM_DATA,
  CARD_RESET,
  CARD_ON_CANCEL,
  CARD_SET_ERROR_BY_KEY,
  CARD_IN_PROGRESS,
  CARD_ADD_FIELDSET,
  CARD_REMOVE_FIELDSET,
  CARD_UPDATE_VIEW_DATA, CARD_UPDATE_FORM_FIELD
} from '../../actions/actionTypes'

export default function createReducer(defaultState){
  return (state = defaultState, action) => {
    const { payload, errors } = action
    const { commonDataForm, viewData } = state
    let fieldsetName

    switch (action.type) {

      case ERRORPAGEERROR:
        return {
          ...state,
          inProgress: false
        }
      case CARD_UPDATE:
        return {
          ...state,
          inProgress: false
        }
      case CARD_GET:

        return {
          ...state,
          commonDataForm: {...payload},
          viewData: {...payload},
          inProgress: false
        }
      case CARD_UPDATE_VIEW_DATA:
        const { data } = payload || {}
        return {
          ...state,
          commonDataForm: data,
          viewData: data,
        }
      case CLEARERROR:
        return {
          ...state,
          errorObject: null,
          deletedInfoObject: null,
          confirmObject: null,
          deleteBuildConfirm: null,
          deleteVersionConfirmObject: null,
          addBuildDialog: null,
          editBuildDialog: null,
          addVersionDialog: null,
          inProgress: false
        }

      case FIELDSERROR:
      case ERROR:
        return {
          ...state,
          inProgress: false
        }

      case CARD_UPDATE_FORM_DATA:
        return {
          ...state,
          commonDataForm: {...payload }
        }

      case CARD_UPDATE_FORM_FIELD:
        return {
          ...state,
          commonDataForm: {
            ...commonDataForm,
            ...payload
          }
        }

      case CARD_IN_PROGRESS:
        return {...state, inProgress: payload}

      case CARD_SET_ERROR_BY_KEY:
        const { errorByFields } = state

        return {
          ...state,
          errorByFields: { ...errorByFields, ...payload }
        }

      case CARD_ON_CANCEL:
        return {
          ...state,
          commonDataForm: {...viewData}
        }

      case CARD_RESET:

        return {
          ...defaultState,
          viewData: viewData,
          commonDataForm,
        }

      case CARD_ADD_FIELDSET: {
        const { fieldsetName, value } = payload;
        return {
          ...state,
          commonDataForm: {
            ...commonDataForm,
            [fieldsetName]: [
              ...commonDataForm[fieldsetName],
              value
            ]

          }
        }
      }
      case CARD_REMOVE_FIELDSET: {
        const {fieldsetName, index} = payload;
        const newFieldset = [...commonDataForm[fieldsetName]]
        newFieldset.splice(index, 1)
        return {
          ...state,
          commonDataForm: {
            ...commonDataForm,
            [fieldsetName]: [
              ...newFieldset
            ]

          }
        }
      }
      default:
        return state
    }
  }
}

