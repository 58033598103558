/** @format */

import React, { useState, useEffect } from 'react';

import withSignatureHOC from 'app/components/HOC/withSignatureHOC';
import { api } from 'app/services/service';

import DeleteDialog from './deleteDialog';
import withTooltip from 'app/components/HOC/TooltipHOC';
import ImgMap from 'app/components/ui/Img/imgMap';
import Button, { buttonTypes } from 'app/components/ui/button/button';
const ButtonComponent = withTooltip(Button);
const DeleteDialogWithSignature = withSignatureHOC(DeleteDialog);

export default function DeleteOrganizations({ ids, onInProgress, afterAction }) {
  const deleteOrganizations = async ({ data, signature }) => {
    const { ids } = data;
    onInProgress(true);
    const result = await api('organization/updateOrganizations', { data, signature });
    const { isError } = result;
    !isError && afterAction(ids);
    onInProgress(false);
    return result;
  };
  const [showDeleteOrganizationsDialog, setShowDeleteOrganizationsDialog] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);

  useEffect(() => {
    setDeleteDisabled(!ids?.length);
  }, [ids]);

  return (
    <>
      <ButtonComponent
        type={buttonTypes.image}
        onClick={() => setShowDeleteOrganizationsDialog(true)}
        disabled={deleteDisabled}
        tooltip="Удалить"
      >
        <ImgMap.Delete />
      </ButtonComponent>
      {showDeleteOrganizationsDialog && (
        <DeleteDialogWithSignature
          signType="EskziUserClosing"
          signHeader="Удаление организации"
          ids={ids}
          actionCallback={deleteOrganizations}
          afterAction={() => setShowDeleteOrganizationsDialog(false)}
          onCancel={() => setShowDeleteOrganizationsDialog(false)}
        />
      )}
    </>
  );
}
