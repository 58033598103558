import React from 'react'
import Input from 'app/components/ui/Input'
import DatePicker from 'app/components/ui/date'
import DropDown from 'app/components/ui/DropDown'
import {capitalize} from "../../../../core/utility/common";

export default function EditForm(props){
  const {
    certificationsTypes,
    formData = {},
    serverFormViewModel,
    onChange,
    errorByFields = {},
    disabled = false,
  } = props;

  function getFormDataValue(fieldName){
    const capitalizeKey = capitalize(fieldName)
    const lineViewModel = serverFormViewModel ? serverFormViewModel[capitalizeKey] : null
    return formData[lineViewModel ? capitalizeKey : fieldName]
  }

  function getError(fieldName){
    return errorByFields[capitalize(fieldName)]
  }

  const types = certificationsTypes.map(el => ({...el, title: el.name, value: el.id}))

  return (
    <>
      <DropDown
        id="type"
        disabled={disabled}
        className="card-field card-certificationType"
        items={types}
        active={getFormDataValue('type')}
        onSelect={value => onChange('type', value)}
        label="Тип"
        error={getError('type')}
        isRequired
      />
      <Input
        disabled={disabled}
        id="number"
        className="card-field card-number"
        label="Номер"
        value={getFormDataValue('number')}
        onChange={value => onChange('number', value)}
        error={getError('number')}
        isRequired
      />
      <DatePicker
        disabled={disabled}
        id="certificateStartDate"
        className="card-field card-startDate"
        label="Начало действия"
        error={getError('certificateStartDate')}
        onChange={value => onChange('certificateStartDate', value)}
        value={getFormDataValue('certificateStartDate')}
        isRequired
      />
      <DatePicker
        disabled={disabled}
        id="certificateExpirationDate"
        className="card-field card-endDate"
        label="Окончание действия"
        error={getError('certificateExpirationDate')}
        onChange={value => onChange('certificateExpirationDate', value)}
        value={getFormDataValue('certificateExpirationDate')}
      />
    </>
  )
}