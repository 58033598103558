import {
   ESKZICARDESKZICARDGETEVENTS,
   ESKZIEVENTSUPDATETASK,
   KEYDOCUMENTSUPDATETASK,
   KEYDOCUMENTCARDGETEVENTS
   } from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const skziCardGetEventsAction = id => dispatch => {
  service('skziService', 'getActivities', id)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, ESKZICARDESKZICARDGETEVENTS, data, isError, errors)
    })
}

export const getExternalOrgEventsAction = id => dispatch => {
  service('skziService', 'getExternalOrgEvents', id)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, ESKZICARDESKZICARDGETEVENTS, data, isError, errors)
    })
}

export const keyDocumentCardGetEventsAction = id => dispatch => {
  service('keyDocumentService', 'getEvents', id)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, KEYDOCUMENTCARDGETEVENTS, data, isError, errors)
    })
}

export const skziCardEditTask = (event, data, eskziId) => dispatch => {
  return service('skziService', 'finishedTaskEdit', {event, data, eskziId})
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, ESKZIEVENTSUPDATETASK, data, isError, errors)
    })
}

export const extOrgEkziCardEditEvent = (event, data, eskziId) => dispatch => {
  return service('skziService', 'extOrgEkziCardEditEvent', {event, data, eskziId})
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, ESKZIEVENTSUPDATETASK, data, isError, errors)
    })
}

export const keyDocumentEventEdit = (event, data, eskziId) => dispatch => {
  return service('skziService', 'keyDocumentEventEdit', {event, data, eskziId})
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, KEYDOCUMENTSUPDATETASK, data, isError, errors)
    })
}