import React from 'react'

export default function LoginFormWrapper({headerTittle, children}){
  return (
    <div className="login-form">
      <div className="login-form__header">{headerTittle}</div>
      <div className="login-form__body">
        <div className="login-form__body">
          {children}
        </div>
      </div>
    </div>
  )
}