/** @format */

import Input from 'app/components/ui/Input';
import DropDown from 'app/components/ui/DropDown';
import TextArea from 'app/components/ui/textArea';
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker';
import { Services, SelectType } from 'app/components/ui/picker/picker';
import { CardState } from 'app/components/ui/card/cardMaterial';
import { organizationName } from 'app/components/list/Table/TableBody';

import { getEskziUsersDisplayedValue } from '../helpers';
import { AgentStatus, HostStatus } from '../enums';
import { formatDate } from 'app/core/utility/date';
import { OKI_CARD_TYPE, ORG_CARD_TYPE } from 'app/components/ui/constants';

const hostStatusItems = Object.entries(HostStatus).map(e => ({
  id: e[0],
  title: e[1],
}));

const organizationPickerService = {
  serviceName: 'organizationService',
  serviceMethod: 'getAllPicker',
};

const cardLines = {
  name: {
    validation: value => true,
    renderEdit: props => {
      const { cardState, editValue } = props;
      return (
        <Input
          className="card-field host-card__name"
          label="АРМ"
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit}
        />
      );
    },
  },
  status: {
    validation: value => true,
    renderEdit: props => {
      const { cardState, editValue, onChange } = props;
      return (
        <DropDown
          className="card-field host-card__status"
          items={hostStatusItems}
          active={hostStatusItems.find(hsi => hsi.id === editValue)}
          readOnly={cardState === CardState.view}
          label="Статус АРМ"
          noClearIcon
          onSelect={value => onChange && onChange('status', value?.id ?? null)}
        />
      );
    },
  },
  agentStatus: {
    validation: value => true,
    renderEdit: props => {
      const { cardState, editValue } = props;
      return (
        <Input
          className="card-field host-card__agent-status"
          label="Статус агента"
          value={AgentStatus[editValue]}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit}
        />
      );
    },
  },
  lastAgentTime: {
    validation: value => true,
    renderEdit: props => {
      const { cardState, editValue } = props;
      return (
        <Input
          className="card-field host-card__last-agent-time"
          label="Дата подключения"
          value={formatDate(editValue, 'hh:MM:ss dd.mm.yyyy')}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit}
        />
      );
    },
  },
  eskziUsers: {
    validation: value => true,
    renderEdit: props => {
      const { cardState, editValue } = props;
      const value = getEskziUsersDisplayedValue(editValue);
      return (
        <Input
          className="card-field host-card__eskzi-users"
          label="Лицевой счет"
          value={value}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit}
        />
      );
    },
  },
  organization: {
    validation: value => true,
    renderEdit: props => {
      const { cardState, editValue, onChange } = props;
      const { id, okz } = editValue || {};
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE;
      return (
        <AutocompletePicker
          label="Организация"
          className="card-field card-field--organization"
          serviceType={Services.organizations}
          notStandardPickerService={organizationPickerService}
          selectType={SelectType.single}
          readOnly={cardState === CardState.view}
          value={editValue}
          renderInputValue={organizationName}
          noClearIcon
          onSelectValue={value => {
            onChange && onChange('organization', value);
          }}
          linkTarget={cardState === CardState.view && editValue && editValue.id && { targetType, id }}
        />
      );
    },
  },
  agentVersion: {
    validation: value => true,
    renderEdit: props => {
      const { cardState, editValue } = props;
      return (
        <Input
          className="card-field host-card__agent-version"
          label="Версия агента"
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit}
        />
      );
    },
  },
  comment: {
    validation: value => true,
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props;
      return (
        <TextArea
          className="card-field host-card__comment"
          value={editValue}
          label="Примечание"
          readOnly={cardState === CardState.view}
          error={error}
          onChange={value => onChange && onChange('comment', value)}
        />
      );
    },
  },
};

export default cardLines;
