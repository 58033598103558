import {
  CARDINIT,
  CARDSEREVERUPDATE,
  CARDAFTERCREATE,
  CLEAR_CARD
} from '../actionTypes'
import service from 'app/services/service'
import { dispatchLogic } from '../common'

export const initCatalogEntityCreate = () => dispatch => {
  dispatch({
    type: CARDINIT,
    payload: {
      id: 'Черновик',
      updateDate: new Date(),
      createDate: new Date(),
      name: '',
      description: ''
    }
  })
}

export const createKeyCarrierTypeAction = data => dispatch => {
  return service('keyCarrierTypeService' ,'addType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
   })
}

export const createSkziTypeAction = data => dispatch => {
  return service('skziTypesService' ,'addType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
   })
}

export const createObjectTypeAction = data => dispatch => {
  return service('objectTypesService' ,'addType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
   })
}

export const createExemplarStatusAction = data => dispatch => {
  return service('exemplarStatusesService' ,'addType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDAFTERCREATE, data, isError, errors)
   })
}

export const updateKeyCarrierTypeAction = data => dispatch => {
  return service('keyCarrierTypeService' ,'editType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
   })
}

export const updateSkziTypeAction = data => dispatch => {
  return service('skziTypesService' ,'editType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
   })
}

export const updateObjectTypeAction = data => dispatch => {
  return service('objectTypesService' ,'editType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
   })
}

export const updateExemplarStatusAction = data => dispatch => {
  return service('exemplarStatusesService' ,'editType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
   })
}

export const initKeyCarrierTypeAction = data => dispatch => {
  return service('keyCarrierTypeService' ,'getType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
   })
}

export const initSkziTypeAction = data => dispatch => {
  return service('skziTypesService' ,'getType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
   })
}

export const initObjectTypeAction = data => dispatch => {
  return service('objectTypesService' ,'getType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
   })
}

export const initExemplarStatusAction = data => dispatch => {
  return service('exemplarStatusesService' ,'getType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
   })
}

export const deleteKeyCarrierTypeAction = data => dispatch => {
  return service('keyCarrierTypeService' ,'deleteType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CLEAR_CARD, data, isError, errors)
   })
}

export const deleteSkziTypeAction = data => dispatch => {
  return service('skziTypesService' ,'deleteType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CLEAR_CARD, data, isError, errors)
   })
}

export const deleteObjectTypeAction = data => dispatch => {
  return service('objectTypesService' ,'deleteType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CLEAR_CARD, data, isError, errors)
   })
}

export const deleteExemplarStatusAction = data => dispatch => {
  return service('exemplarStatusesService' ,'deleteType', data)
   .then(response => {
     const { data, errors, isError } = response;
     
     return dispatchLogic(dispatch, CLEAR_CARD, data, isError, errors)
   })
}