import React from 'react'
import { useLocation } from 'react-router-dom'
import Routes from '../navigation/navigation'

const NoBackgroundLinks = ['/dashboard']

const WorkArea = () => {
  const location = useLocation()
  const { pathname } = location || {}
  const removeBackground = NoBackgroundLinks.includes(pathname)

  return (
    <div className={`work-area ${removeBackground ? 'work-area--no-background': ''}`}>
      <Routes></Routes>
    </div>
  );
};

export default WorkArea;
