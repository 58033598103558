import {useDispatch, useSelector} from "react-redux";
import {
  eskziCardEditActionFormAction,
  eskziCardServiceOnEditAction,
  eskziCardServiceResetAction,
} from "redux/actions/skziInstance/eskziCardAction";
import {setErrorByKey} from "redux/actions/common";
import {capitalize, serverlikeErrors} from "app/core/utility/common";
import {formatDate} from "app/core/utility/date";
import {getResourceRights} from "app/core/auth/auth";
import {RESOURCES} from "app/core/auth/resourceByPage";

export default function useEditControl({item}){
  const accessRights = useSelector(({profile}) => profile)
  const rights = getResourceRights( accessRights, RESOURCES.maintenanceRecords );
  const dispatch = useDispatch()
  const {eskziCard, error} = useSelector(({eskziCard, error}) => ({eskziCard, error}))
  const { serviceData } = eskziCard || {}
  const { editActionDialog } = serviceData
  const { initData: editDefaultFormData, formData: editFormData } = editActionDialog || {}
  const { fieldsError, errorByFields } = error || {}

  function onValidation (errors) {
    dispatch(setErrorByKey(serverlikeErrors(errors)))
  }

  function onEditActionFormChange(key, value) {
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))

    dispatch(eskziCardEditActionFormAction({ ...editActionDialog, formData: {
        ...editFormData, [key]: value
      }}))
    dispatch(setErrorByKey(filteredErrors))
  }

  function getActionKey(item) {
    const { name, comments, performerId, actionDate, eskzi } = item
    const performer = performerId && performerId.id || ''
    const eskziId = eskzi && eskzi.id || ''

    return `${name}${comments}${formatDate(actionDate, 'yyyyddmm')}${performer}${eskziId}`
  }

  function onCancel(data){
    dispatch(eskziCardServiceResetAction(data))
  }

  function onEditClick() {
   dispatch(eskziCardServiceOnEditAction(formatItemToForm(item)))
  }


  return {
    rights,
    editFormData,
    fieldsError,
    editActionDialog,
    editDefaultFormData,
    onEditClick,
    onCancel,
    getActionKey,
    onValidation,
    onEditActionFormChange,
  }

}

function formatItemToForm(item){
  return {
    name: item.actionName,
    performerId: item.performer,
    eskzi: item.eskzi,
    comments: item.comments,
    actionDate: new Date(item.actionDate),
    signatures: item.signatures
  }
}