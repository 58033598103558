/** @format */
import Button, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import styles from './modal.module.scss';

export default function Header({ className, title, onCancel }) {
  return (
    <div className={`${styles.header} ${className ?? ''}`}>
      {title}
      <Button type={buttonTypes.image} onClick={onCancel}>
        <Img img={Image.CloseBorderless} />
      </Button>
    </div>
  );
}
